import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class CreatePermission extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header header-elements-inline">
                <h5 className="card-title">Thêm quyền</h5>
                <div className="header-elements">
                  <div className="list-icons">
                    <a className="list-icons-item" data-action="collapse"></a>
                    <a className="list-icons-item" data-action="remove"></a>
                  </div>
                </div>
              </div>
              <div className="card-body ">
                <form action="#">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-4">
                        <label>Code</label>
                        <input type="text" className="form-control" placeholder="add_user" />
                      </div>
                      <div className="col-md-4">
                        <label>Mô tả</label>
                        <input type="text" placeholder="Thêm/sửa/xóa tài khoản nhân viên" className="form-control" />
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>Thuộc Nhóm Quyền/ Nhóm quyền (Tag)</label>
                          <select name="status" className="form-control form-control-select2" data-placeholder="Select status">
                            <option value="0">Là Nhóm quyền</option>
                            <option value="1">Quản Lý Nhân Viên</option>
                            <option value="5">Quản Lý Đối Tác</option>
                            <option value="8">Quyền Mặt Định</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-right">
                    <button className="btn btn-primary">Lưu lại</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    );
  }

  componentDidMount() {
    try {
      /**
       * 
       */
    } catch (error) {
      /**
       * 
       */
    }
  }

  componentWillUnmount() {
    try {
      /**
       * 
       */
    } catch (error) {
      /**
       * 
       */
    }
  }

  componentDidUpdate() {
    try {
      /**
       * 
       */
    } catch (error) {
      /**
       * 
       */
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      userInfo: payload.generalReducer.userInfo,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(CreatePermission)
);
