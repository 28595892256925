import { call, put, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';
import * as responseCode from '../../configs/responseCode.config';
import { types } from '../../redux/wallets';
import { walletAPI } from '../../api';
import { types as apiTypes } from '../../redux/api';

function* doAction(action) {
  try {
    const { code, data } = yield call(walletAPI.postSearchWallet, action.payload);
    switch (code) {
      case responseCode.POST_SEARCH_WALLET_SUCCESS: {
        yield put({ type: types.POST_SEARCH_WALLET_SUCCESS, payload: data });
        if (action.callback) {
          action.callback(true, data);
        }
        break;
      }
      default: {
        yield put({ type: types.POST_SEARCH_WALLET_FAIL });
        if (action.callback) {
          action.callback(false, data);
        }
      }
    }
  } catch (error) {
    // yield put({ type: apiTypes.HIDE_LOADING });
    yield put({ type: types.POST_SEARCH_WALLET_FAIL });
  }
}

export default function* watchAction() {
  yield takeLatest(types.POST_SEARCH_WALLET, doAction);
}

