import React from 'react';
import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select2 from 'react-select2-wrapper';
import Select from 'react-select';

import { DelayInput } from 'react-delay-input';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import moment from 'moment';
import PagingContainer from '../../ultils/paging.container';
import { PAGE_LIMIT } from '../../../configs/constants';

import {
  paging, formatDate2, formatDateFilter, 
  formatDateRange, formatDateFilter2, localStoreFilter, localStorePageNum
} from '../../../helpers';

class PaymentVoucher extends React.Component {
  constructor(props) {
    super(props);
    const orderFilter = localStoreFilter('paymentVoucher', 'getOrders');
    const estimatedAt = _.get(orderFilter, 'filters.estimatedAt');
    let dateRangeTitle = 'Ngày dự kiến có KQ';
    if (estimatedAt) {
      dateRangeTitle = formatDateFilter2(estimatedAt.from, estimatedAt.to);
    }
    
    this.state = {
      cheat: '',
      pageNum: localStorePageNum('paymentVoucher', 'getOrders'),
      showEditBox: false,
      states: [],
      modifyState: {},
      modifyStates: [],
      listParentIdentify: [],
      tagSuggestions: [],
      checkbox: {},
      listService: [],
      status: [
        { value: '', label: 'Chọn trạng thái' },
        { value: 'pending', label: 'Chờ xử lý' },
        { value: 'reject', label: 'Từ chối' },
        { value: 'approve', label: 'Đã chi' },
        { value: 'trash', label: 'Thùng rác' }
      ],
      serviceDefaultValue: 'all',
      dateRangeTitle,
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      },
      searching: orderFilter || {
        filters: {
          profile: {
            fullName: ''
          },
          id: '',
          serviceIdentifyCode: '',
          estimatedAt: {
            from: '',
            to: ''
          }
        },
        paging: {
          start: 0,
          limit: PAGE_LIMIT
        }
      }
    };
    this.onTreeDropdownChange = this.onTreeDropdownChange.bind(this);
    this.handleDatePicker = this.handleDatePicker.bind(this);
    this.renderVoucher = this.renderVoucher.bind(this);
    this.onSearchChangeHandle = this.onSearchChangeHandle.bind(this);
    this.callbackOrder = this.callbackOrder.bind(this);
  }

  handleDatePicker(e, picker) {
    const searching = Object.assign({}, this.state.searching);
    searching.filters.estimatedAt = {
      from: '',
      to: ''
    };
    const pageNum = 1;
    const dateRangeTitle = formatDateFilter(picker.startDate, picker.endDate);
    const date = formatDateRange(picker.startDate, picker.endDate);
    this.setState({ searching, dateRangeTitle, pageNum: 1 });
    searching.filters.estimatedAt.from = date.startDate;
    searching.filters.estimatedAt.to = date.endDate;
    searching.paging = paging(this.state.pageNum, PAGE_LIMIT);
    this.props.getOrders(searching);
  }

  callbackOrder() {
    try {
      const { orders, socketOrder } = this.props;
      if (_.isEmpty(socketOrder) === false) {
        const index = _.findIndex(orders, { id: socketOrder.id });
        if (index >= 0) {
          orders[index] = socketOrder;
        } else {
          orders.unshift(socketOrder);
        }
        this.props.dispatch({
          type: 'POST_SEARCH_ORDER_SUCCESS',
          payload: orders
        });
        /**
         * Cheat
         */
        NotificationManager.warning(
          'Thông báo đơn hàng bị thay đổi',
          'Vừa có thao tác thay đổi đơn hàn',
          1500
        );
        // this.setState({ cheat: true });
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * 
   */

  onChangePage(key) {
    const searching = Object.assign({}, this.state.searching);
    let pageNum = this.state.pageNum;
    switch (key) {
      case 'next': {
        //
        pageNum += 1;
        break;
      }
      default: {
        pageNum -= 1;
        if (pageNum <= 1) {
          pageNum = 1;
        }
        break;
      }
    }
    searching.paging = paging(pageNum, PAGE_LIMIT);
    this.props.getOrders(searching);
    this.setState({ pageNum });
  }

  onTreeDropdownChange(currentNode, selectedNodes) {
    const searching = Object.assign({}, this.state.searching);
    const result = {};
    const states = selectedNodes[0].states;
    searching.filters.serviceIdentifyCode = selectedNodes[0].value;

    if (_.isEmpty(states) === false) {
      const listState = [];
      if (_.isEmpty(states) === false) {
        states.forEach((element) => {
          listState.push({
            label: element,
            value: element
          });
        });
        result.listState = listState;
        result.isDisable = false;
      }
    }
    result.searching = searching;
    this.props.getOrders(searching);
    result.listParentIdentify = this.props.listServiceHandleData(selectedNodes[0].value);
    this.setState(result);
  }

  // handleDatePicker(e, picker) {
  //   const searching = Object.assign({}, this.state.searching);
  //   const dateRangeTitle = formatDateFilter(picker.startDate, picker.endDate);
  //   const date = formatDateRange(picker.startDate, picker.endDate);
  //   this.setState({ searching, dateRangeTitle, pageNum: 1 });
  //   searching.filters.estimatedAt.from = date.startDate;
  //   searching.filters.estimatedAt.to = date.endDate;
  //   searching.paging = paging(this.state.pageNum, PAGE_LIMIT);
  //   this.props.getOrders(searching);
  // }

  onSearchChangeHandle(key, e) {
    try {
      const searching = Object.assign({}, this.state.searching);
      const value = _.get(e, 'target.value', _.get(e, 'value', false));
      const result = {
        checkbox: {},
        pageNum: 1
      };
      switch (key) {
        case 'id': {
          searching.filters.id = value ? value.split(',') : [];
          break;
        }
        case 'apply': {
          searching.filters.apply = value !== 'all' ? { identifyCode: value } : '';
          break;
        }
        case 'fullName': {
          searching.filters.profile.fullName = '';
          if (value.length >= 3) {
            searching.filters.profile.fullName = value;
          }
          break;
        }
        case 'serviceIdentifyCode': {
          searching.filters.serviceIdentifyCode = '';
          if (value !== 'all') {
            searching.filters.serviceIdentifyCode = value;
          }
          break;
        }
        
        default: {
          searching.filters[key] = _.get(e, 'target.value', _.get(e, 'value', ''));
          break;
        }
      }
      searching.paging = paging(result.pageNum, PAGE_LIMIT);
      this.props.getOrders(searching);
      result.searching = searching;
      this.setState(result);
    } catch (error) {
      // console.log(error);
    }
  }

  renderAgencyTitle(agency) {
    if (!agency) {
      return (<span></span>);
    }
    const title = agency.group === 'agency' ? `Đại lý ${agency.title}` : 'Khách lẻ';
    return (<span>{title}</span>);
  }

  renderProcess(process) {
    if (_.isEmpty(process) === true) {
      return (<span></span>);
    }
    return (<span>
      <div className="font-weight-bold bg-grey" style={{ minWidth: 300, maxWidth: '100%', paddingLeft: 10 }}>
        {process.account.title}
      </div>
      <p style={{ marginBottom: 0, whiteSpace: 'pre-line' }}>{process.note}</p>

    </span>);
  }

  renderVoucher() {
    try {
      const { orders } = this.props;
      if (_.isEmpty(orders) === true) {
        return (<tr></tr>);
      }

      const result = [];
      let date = '';
      orders.forEach((element) => {
        const createdAt = formatDate2(element.createdAt);
        if (createdAt !== date) {
          date = createdAt;
          result.push({ createdAt });
        }
        result.push(element);
      });

      return (result.map((item, i) => {
        if (!item.id) {
          return (<tr key={i} className="table-active table-border-double">
            <td colSpan="11" className="font-weight-semibold">Phiếu chi ngày {item.createdAt}</td>
          </tr>);
        }

        const createdAt = formatDate2(item.createdAt);
        const service = this.props.getServiceDetail(item.serviceIdentifyCode);
        const defaultState = _.find(service.stateList, { value: item.state });
        const color = _.get(defaultState, 'color', '#ccc');
        const linkToReceipt = `/payment-voucher-receipt/${item.customer.id}/${item.id}`;
        const linkTo = `/order-modify/${item.customer.id}/${item.id}`;
        const appply = _.get(item, 'apply.title', '');
        let process = item.process ? _.last(item.process) : {};
        _.forEach(item.process, (processItem) => {
          if (processItem.isPin === true) {
            process = processItem;
          }
        });
        return (<tr key={i}>
          <td>{item.id}</td>
          <td>
            <a onClick={() => window.open(linkToReceipt, '_blank')}
              value="redirect"
              className="btn btn-sm "
              style={{
                color: 'black', fontSize: 14, backgroundColor: 'white', marginRight: 5
              }}
              title="In phiếu thu"
            >
              <i className="fa fa-print"></i>
            </a>
            <Link
              className="btn btn-sm "
              style={{
                color: 'black', fontSize: 14, backgroundColor: 'white', marginRight: 5
              }}
              to={linkTo}>
              <i className="fa fa-edit"></i>
            </Link>
            <a
              href="#"
              className="btn btn-sm "
              style={{ color: 'black', fontSize: 14, backgroundColor: 'white' }}
            >
              <i className="fa fa-trash"></i>
            </a>

          </td>
          <td>{createdAt}</td>
          <td></td>
          <td>
            <span style={{ fontWeight: 'bold' }}>
              {item.amount.toLocaleString()}
            </span>
          </td>
          <td>{this.renderProcess(process)}</td>
          <td>{appply}</td>
        </tr>);
      }));
    } catch (error) {
      return (<tr></tr>);
    }
  }

  render() {
    const {
      parentIdentifies, searching, dateRangeTitle, ranges
    } = this.state;

    const filterIds = [];
    if (_.isEmpty(searching.filters.id) === false) {
      filterIds.push(searching.filters.id);
    }
    let apply = { value: 'all', label: 'Lọc theo nhân viên' };
    if (_.isEmpty(searching.filters) === false) {
      const identifyCode = _.get(searching, 'filters.apply.identifyCode', false);
      if (identifyCode) {
        apply = _.find(this.props.listMembers(), { value: searching.filters.apply.identifyCode }) || { value: 'all', label: 'Lọc theo nhân viên' };
      }
    }
    return (
      <div>

        <div className="card">
          <div className="card-header bg-transparent header-elements-inline">
            <h5 className="card-title">Quản lý phiếu chi</h5>
            <Link
              style={{ float: 'right' }}
              className="btn bg-teal-400"
              to="/payment-voucher-create" >
              <i className="icon-add mr-2"></i> Thêm mới
            </Link>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="form-group col-md-3">
                <DelayInput
                  value={filterIds ? filterIds.join(',') : ''}
                  type="text"
                  className="form-control"
                  minLength={0}
                  delayTimeout={300}
                  placeholder="Mã đơn hàng"
                  onChange={this.onSearchChangeHandle.bind(this, 'id')}
                />
              </div>

              <div className="form-group col-md-3">
                <Select2
                  style={{ width: '100%' }}
                  onSelect={this.onSearchChangeHandle.bind(this, 'serviceIdentifyCode')}
                  className="form-control select-search select2-hidden-accessible"
                  value={searching.filters.serviceIdentifyCode}
                  data={parentIdentifies}
                  options={{
                    placeholder: 'Tất cả dịch vụ'
                  }}
                />
              </div>

              <div className="form-group col-md-2">
                <Select
                  value={apply}
                  onChange={this.onSearchChangeHandle.bind(this, 'apply')}
                  options={this.props.listMembers() || []}
                  placeholder='Lọc theo nhân viên'
                />
              </div>

              <div className="form-group col-md-3">
                <DateRangePicker
                  className="btn btn-light daterange-predefined"
                  onApply={this.handleDatePicker}
                  ranges={ranges}
                >
                  <button style={{ padding: '7px 15px' }}>
                    <i className="icon-calendar22 mr-2"></i>
                    <span> {dateRangeTitle} </span>
                  </button>
                </DateRangePicker>

              </div>

            </div>
          </div>
          <div className="table-responsive" style={{ paddingBottom: 200 }}>
            <table className="table table-bordered table-striped text-nowrap">
              <thead>
                <tr>
                  <th>Mã</th>
                  <th width="200">Thực thi</th>
                  <th>Ngày tạo</th>
                  <th>Nội dung đề xuất</th>
                  <th>Tổng tiền</th>
                  <th>Tiến trình</th>
                  <th>Người tạo</th>
                </tr>
              </thead>
              <tbody>
                {this.renderVoucher()}
              </tbody>
            </table>

            <PagingContainer
              count={_.get(this.props, 'orders', []).length}
              pageNum={this.state.pageNum}
              limit={PAGE_LIMIT}
              onClick={this.onChangePage.bind(this)}
            />
          </div>
        </div>
        <NotificationContainer />
      </div>

    );
  }

  componentDidUpdate(oldProps) {
    try {
      /**
       * 
       */
      const newProps = this.props;
      if (oldProps.buildServices !== newProps.buildServices) {
        const parentIdentifies = this.props.listServiceHandleData();
        this.setState({ parentIdentifies: parentIdentifies.list });
      }
      if (oldProps.socketOrder !== newProps.socketOrder) {
        this.callbackOrder();
      }
    } catch (error) {
      // console.log(error);
      /**
       * 
       */
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      socketOrder: payload.orderReducer.socketOrder,
      orders: payload.orderReducer.orders,
      buildServices: payload.serviceReducer.buildServices,
      localStoreFilter: payload.generalReducer.localStoreFilter,
      init: payload.generalReducer.init,
      userInfo: payload.generalReducer.userInfo,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(PaymentVoucher)
);
