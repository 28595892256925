import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ModifyService from './modifyService';
import * as ServiceActions from '../../../redux/services/services.actions';
import { SYSTEM_SERVICE_SCOPE } from '../../../configs/constants';

import {
  checkScope
} from '../../../helpers';

class ModifyServiceContainer extends React.Component {
  constructor(prop) {
    super(prop);

    if (checkScope(SYSTEM_SERVICE_SCOPE, this.props) === false) {
      NotificationManager.error('Chức năng không tồn tại', '', 1500);
      setTimeout(() => {
        this.props.history.push('/dashboard');
      }, 2000);
    }
    
    this.state = {
      serviceInfo: {},
      listService: [],
      listParentIdentify: []
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.callback = this.callback.bind(this);
    this.buildDiscount = this.buildDiscount.bind(this);
    /**
     * 
     */
    this.getDetailService = this.getDetailService.bind(this);
    this.callbackGetDetailService = this.callbackGetDetailService.bind(this);
    /**
     * 
     */
    this.getServices = this.getServices.bind(this);
    /**
     * 
     */
    this.listServiceHandleData = this.listServiceHandleData.bind(this);
    /**
     * 
     */
    const { serviceAct } = this.props;
    const id = this.props.computedMatch.params.id;
    serviceAct.detailService({ 
      id 
    }, this.callbackGetDetailService);
  }

  getDetailService() {
    try {
      const { serviceAct } = this.props;
      const id = this.props.computedMatch.params.id;
      serviceAct.detailService({ id }, this.callbackGetDetailService);
    } catch (error) {
      // console.log('error', error);
    }
  }

  callbackGetDetailService(status, data) {
    try {
      if (status) {
        this.setState({ serviceInfo: data });
      } else {
        NotificationManager.error('Thông tin không hợp lệ', '', 1500);
      } 
    } catch (error) {
      // console.log(error);
    }
  }

  /**
   * 
   */
  getServices() {
    try {
      const { serviceAct } = this.props;
      serviceAct.listService({}, this.callbackGetService);
    } catch (error) {
      // console.log('error', error);
    }
  }

  listServiceHandleData(setlectedItem = false) {
    try {
      const { buildServices } = this.props;
      const result = [{
        label: 'Danh mục chính',
        value: 'root',
        checked: setlectedItem === 'root'
      }];
      if (_.isEmpty(buildServices) === false) {
        buildServices.forEach((item) => {
          if (item.type === 'list') {
            const node = {
              label: item.title,
              value: item.identifyCode,
              checked: setlectedItem === item.identifyCode
            };
            if (_.isEmpty(item.children) === false) {
              node.children = this.listServiceHandleChildren([], item.children, setlectedItem);
            }
            result.push(node);
          }
        });
      }
      return result;
    } catch (error) {
      return false;
    }
  }

  listServiceHandleChildren(parent, data, setlectedItem) {
    try {
      const result = Object.assign([], parent);
      if (_.isEmpty(data) === false) {
        data.forEach((item) => {
          if (item.type === 'list') {
            const node = {
              label: item.title,
              value: item.identifyCode,
              checked: setlectedItem === item.identifyCode
            };
            if (_.isEmpty(item.children) === false) {
              node.children = this.listServiceHandleChildren(parent, item.children, setlectedItem);
            }
            result.push(node);
          }
        });
        
        return result;
      }
      return [];
    } catch (error) {
      return [];
    }
  }

  onSubmit = async (args) => {
    try {
      const { serviceAct } = this.props;
      const query = Object.assign({}, args);
      const payload = {
        id: query.id,
        data: query
      };
      delete payload.data.id;

      serviceAct.modifyService(payload, this.callback);
    } catch (error) {
      // console.log('error', error);
    }
  }

  callback(status, data) {
    if (status) {
      NotificationManager.info('Cập nhật dịch vụ thành công, đang chuyển về trang quản lý', '', 1000);
      setTimeout(() => {
        this.props.history.push('/service');
      }, 1500);
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }

  buildDiscount() {
    const { init } = this.props;
    const listDiscount = [];
    if (init.discount) {
      init.discount.forEach((element) => {
        listDiscount.push({ value: element.key, label: element.title });
      });
    }
    return listDiscount;
  }

  render() {
    const {
      listService, listParentIdentify, serviceInfo 
    } = this.state;

    return (
      <div>
        <ModifyService
          listParentIdentify={listParentIdentify}
          serviceInfo={serviceInfo}
          listServiceHandleData={this.listServiceHandleData}
          onSubmit={this.onSubmit}
          userInfo={this.userInfo}
          buildDiscount={this.buildDiscount}
        />
        <NotificationContainer/>
      </div>
        
    );
  }

  componentWillMount() {
    try {
      /**
       * 
       */
      const { serviceAct } = this.props;
      serviceAct.listService({
      }, this.callbackGetService);
    } catch (error) {
      /**
       * 
       */
    }
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    serviceAct: bindActionCreators(ServiceActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    buildServices: rootState.serviceReducer.buildServices,
    init: rootState.generalReducer.init,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(ModifyServiceContainer);
