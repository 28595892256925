import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';

class PageHeader extends React.Component {
  constructor(props) {
    super(props);
    const { i18nState } = this.props;
  }

  renderBreadcrumb(activeMenu) {
    if (_.isEmpty(activeMenu) === false) {
      return (
        <span className="breadcrumb-item active">{activeMenu.text}</span>
      );
    }
    return ('');
  }

  render() {
    const { activeMenu } = this.props;
    return (
      <div className="page-header page-header-light">
        <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
          <div className="d-flex">
            <div className="breadcrumb">
              <Link to="/" className="breadcrumb-item">
                <i className="icon-home2 mr-2"></i> Trang chủ
              </Link>
              {this.renderBreadcrumb(activeMenu)}
            </div>
            <a href="#" className="header-elements-toggle text-default d-md-none">
              <i className="icon-more"></i></a>
          </div>
          <div className="header-elements d-none">
            <div className="breadcrumb justify-content-center">
                Số điện thoại phản ánh chất lương dịch vụ <a style={{ paddingLeft: 5 }} href={'tel:0932051510'}>  <i className="fa fa-phone-square"></i> 0932.051.510</a> 
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      userInfo: payload.generalReducer.userInfo,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(PageHeader)
);
