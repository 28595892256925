/*  config data */
export const REFRESH_ACCESSTOKEN_TIME = 8; // unit: 1 minutes
export const KEY_RESET_PASS = 'KWJOznJBjlT55roHQKWbfe2';
export const PAGE_SMALL_LIMIT = 20;
export const PAGE_LIMIT = 50;
export const PAGE_MAX_LIMIT = 500;
export const TAG_STYLE = [
  {
    background: '#845EC2', color: 'white', padding: '5px 15px 5px 15px', marginRight: 10, cursor: 'pointer'
  },
  {
    background: '#2C73D2', color: 'white', padding: '5px 15px 5px 15px', marginRight: 10, cursor: 'pointer'
  },
  {
    background: '#0081CF', color: 'white', padding: '5px 15px 5px 15px', marginRight: 10, cursor: 'pointer'
  },
  {
    background: '#0089BA', color: 'white', padding: '5px 15px 5px 15px', marginRight: 10, cursor: 'pointer'
  },
  {
    background: '#008E9B', color: 'white', padding: '5px 15px 5px 15px', marginRight: 10, cursor: 'pointer'
  },
  {
    background: '#008F7A', color: 'white', padding: '5px 15px 5px 15px', marginRight: 10, cursor: 'pointer'
  }
];

export const DISCOUNT_TYPE = [
  { value: 'percent', label: 'Phần trăm' },
  { value: 'money', label: 'VND' },
  { value: 'custom', label: 'Tùy biến' }
];
export const SOCKET_DOMAIN = 'https://stream.vnhub.com';

/**
 * SCOPE
 */

export const WALLET_SCOPE = ['wallet.setCreditLimit', 'wallet.deposit', 'wallet.withdraw'];
export const LIABILITY_SCOPE = ['order.manage', 'order.view'];

export const INVOICE_EXIT_SCOPE = ['invoice.manage', 'invoice.exit'];
export const INVOICE_ARRIVAL_SCOPE = ['invoice.manage', 'invoice.arrival'];
export const INVOICE_ARRIVAL_CREATE_SCOPE = ['invoice.manage', 'invoice.arrival'];
export const INVOICE_ARRIVAL_UPDATE_SCOPE = ['invoice.manage', 'invoice.arrival'];
export const INVOICE_ALL_SCOPE = ['invoice.all'];

// Quản lý đơn hàng
export const ORDER_MANAGE_SCOPE = ['order.manage', 'order.view'];
export const ORDER_CREATE_SCOPE = ['order.manage', 'order.create'];
export const ORDER_UPDATE_SCOPE = ['order.manage', 'order.update'];
export const ORDER_VIEW_UPDATE_SCOPE = ['order.manage', 'order.view'];

// Chăm sóc khách hàng
export const CUSTOMER_SUPPORT_MANAGE_SCOPE = ['cs.manage'];
export const CUSTOMER_SUPPORT_CREATE_SCOPE = ['cs.manage', 'cs.create'];
export const CUSTOMER_SUPPORT_DELETE_SCOPE = ['cs.manage', 'cs.delete'];
export const CUSTOMER_SUPPORT_UPDATE_SCOPE = ['cs.manage', 'cs.update'];

// Quản lý dịch vụ, Quốc tịch nhập cảnh
export const SYSTEM_SERVICE_SCOPE = ['system.service'];
// Quản lý thu chi
export const SYSTEM_COBO_POBO = ['system.cobo-pobo'];
// Xuất báo cáo
export const SYSTEM_EXPORT_DATA = ['system.exportData'];
// Quản lý chi nhánh
export const SYSTEM_ORGANIZATION_SCOPE = ['system.organization']; 
// Quản lý  phòng ban
export const SYSTEM_SERVICE_PAYMENT_VOUCHER_SCOPE = ['system.paymentVoucher'];

// Quản lý khách hàng
export const CUSTOMER_MANAGE_SCOPE = ['customer.manage'];
export const AGENCY_MANAGE_SCOPE = ['customer.manage'];
export const AGENCY_CREATE_SCOPE = ['customer.manage', 'customer.create'];
export const AGENCY_UPDATE_SCOPE = ['customer.manage', 'customer.update'];
export const AGENCY_DELETE_SCOPE = ['customer.manage', 'customer.delete'];
export const CUSTOMER_READ_SCOPE = ['customer.manage', 'customer.read'];
export const CUSTOMER_UPDATE_SCOPE = ['customer.manage', 'customer.update'];
export const CUSTOMER_DELETE_SCOPE = ['customer.manage', 'customer.delete'];

// Quản lý nhân viên
export const STAFF_MANAGE_SCOPE = ['staff.manage'];
export const STAFF_CREATE_SCOPE = ['staff.manage', 'staff.create'];
export const STAFF_READ_SCOPE = ['staff.manage', 'staff.read'];
export const STAFF_UPDATE_SCOPE = ['staff.manage', 'staff.update'];
export const STAFF_DELETE_SCOPE = ['staff.manage', 'staff.delete'];

// Lịch sử cuộc gọi
export const CALLLOG_MANAGE_SCOPE = ['callLog.manage'];
export const CALLLOG_CREATE_SCOPE = ['callLog.manage', 'callLog.create'];
export const CALLLOG_UPDATE_SCOPE = ['callLog.manage', 'callLog.update'];
export const CALLLOG_DELETE_SCOPE = ['callLog.manage', 'callLog.delete'];
