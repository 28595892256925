import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import CreateCallLog from './createCallLog';
import { CALLLOG_CREATE_SCOPE } from '../../../configs/constants';

import * as OrderActions from '../../../redux/orders/orders.actions';
import * as CallLogActions from '../../../redux/callLogs/callLogs.actions';
import * as ServiceActions from '../../../redux/services/services.actions';
import * as GeneralActions from '../../../redux/general/general.actions';
import * as AccountActions from '../../../redux/accounts/accounts.actions';

import {
  serviceDetail, serviceHandleDatas, checkScope, 
  buildListLocation, buildListCustomerType, buildListCustomerSource
} from '../../../helpers';

class CreateCallLogContainer extends React.Component {
  constructor(prop) {
    super(prop);

    this.state = {
      buildServices: []
    };
    //
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitSchedule = this.onSubmitSchedule.bind(this);
    this.callback = this.callback.bind(this);
    this.getCallLogs = this.getCallLogs.bind(this);
    this.callLogDetail = this.callLogDetail.bind(this);
    this.buildLocation = this.buildLocation.bind(this);
    this.buildCustomerSource = this.buildCustomerSource.bind(this);
    this.buildCustomerType = this.buildCustomerType.bind(this);
    this.listServiceHandleData = this.listServiceHandleData.bind(this);
    this.getServiceDetail = this.getServiceDetail.bind(this);
    this.getOrders = this.getOrders.bind(this);
    this.clickToCall = this.clickToCall.bind(this);

    this.getMembers = this.getMembers.bind(this);
    this.callbackMember = this.callbackMember.bind(this);
    //
    this.getSchedule = this.getSchedule.bind(this);
  }

  clickToCall(args) {
    try {
      const { callLogAct } = this.props;
      callLogAct.clickToCall(args, (status, data) => {
        if (status === true) {
          NotificationManager.info('Kết nối cuộc gọi thành công, vui lòng chờ!', '', 5000);
        } else {
          NotificationManager.error(data.message, '', 2000);
        }
      });
    } catch (error) {
      console.log('error', error);
    }
  }

  onSubmit = async (args) => {
    try {
      const { callLogAct } = this.props;
      const query = Object.assign({}, args);
      delete query.information.subType;
      delete query.callerRecordedUrl;
      const payload = {
        id: args.callLogId,
        data: query
      };
      delete payload.data.callLogId;
      return callLogAct.modifyCallLog(payload, this.callback);
    } catch (error) {
      return {};
      // console.log('error', error);
    }
  }

  onSubmitSchedule = async (args) => {
    try {
      console.log(args);
      // return {}; 
    } catch (error) {
      // return {};
    }
  }

  callback(status, data) {
    if (status) {
      NotificationManager.info('Cập nhật hồ sơ thành công', '', 1000);
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }

  /**
   * Call Logs
   */
  getCallLogs(args) {
    try {
      const { callLogAct } = this.props;
      callLogAct.listPickupCallLog(args);
    } catch (error) {
      // console.log('error', error);
    }
  }

  callLogDetail(args) {
    try {
      const { callLogAct } = this.props;
      callLogAct.callLogDetail(args);
    } catch (error) {
      console.log('error', error);
    }
  }

  getSchedule() {
    const result = {
      priority: [],
      remind: [],
      type: []
    };
    try {
      const { init } = this.props;
      if (_.isEmpty(init.schedule) === false) {
        const { schedule } = init;
        _.forEach(schedule, (items, key) => {
          if (_.isEmpty(items) === false) {
            _.forEach(items, (item) => {
              result[key].push({ value: item.key, label: item.value });
            });
          }
        });
      }
      return result;
    } catch (error) {
      return result;
    }
  }

  /**
   * 
   */
  getServiceDetail(serviceIdentifyCode) {
    try {
      const { buildServices } = this.props;
      if (_.isEmpty(buildServices) === false) {
        return serviceDetail(buildServices, serviceIdentifyCode);
      }
      return {};
    } catch (error) {
      return {};
    }
  }

  /**
   * 
   */
  getOrders(args) {
    try {
      const { orderAct } = this.props;
      orderAct.searchSimpleOrder(args);
    } catch (error) {
      // console.log('error', error);
    }
  }

  /**
   * 
   */

  listServiceHandleData() {
    try {
      const result = { list: [], setlectedItem: {} };
      const { buildServices } = this.props;

      if (_.isEmpty(buildServices) === false) {
        return serviceHandleDatas(buildServices);
      }
      return result;
    } catch (error) {
      return false;
    }
  }

  buildLocation() {
    const { init } = this.props;
    if (init.area) {
      return buildListLocation(init);
    }
    return [];
  }

  buildCustomerType() {
    const { init } = this.props;
    if (init.customerType) {
      return buildListCustomerType(init);
    }
    return [];
  }

  buildCustomerSource() {
    const { init } = this.props;
      
    if (init.customerSource) {
      return buildListCustomerSource(init);
    }
    return [];
  }

  /**
   * 
   */

  getMembers(args) {
    try {
      const { accountAct } = this.props;
      accountAct.searchAccount(args, this.callbackMember);
    } catch (error) {
      console.log('error', error);
    }
  }

  callbackMember(status, data) {
    if (status) {
      /**
       * 
       */
      const listMember = [];
      data.forEach((item) => {
        listMember.push({ value: item.identifyCode, label: item.title, scopes: item.scopes });
      });
      
      this.setState({ listMember });
    } 
  }

  render() {
    const {
      buildServices, listMember
    } = this.state;
    if (checkScope(CALLLOG_CREATE_SCOPE, this.props) === true) {
      return (
        <div>
          <CreateCallLog
            listMember={listMember}
            onSubmit={this.onSubmit}
            onSubmitSchedule={this.onSubmitSchedule}
            buildServices={buildServices}
            getCallLogs={this.getCallLogs}
            getOrders={this.getOrders}
            getSchedule={this.getSchedule}
            callLogDetail={this.callLogDetail}
            buildLocation={this.buildLocation}
            buildCustomerSource={this.buildCustomerSource}
            buildCustomerType={this.buildCustomerType}
            listServiceHandleData={this.listServiceHandleData}
            getServiceDetail={this.getServiceDetail}
            clickToCall={this.clickToCall}
          />
          <NotificationContainer />
        </div>
          
      );
    }

    return (
      <div>
        <NotificationContainer />
      </div>
        
    );
  }

  componentWillMount() {
    try {
      const filter = {
        filters: {
          group: ['staff']
        }
      };

      this.getMembers(filter);
    } catch (error) {
      // console.log(error);
    }
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    accountAct: bindActionCreators(AccountActions, dispatch),
    orderAct: bindActionCreators(OrderActions, dispatch),
    generalAct: bindActionCreators(GeneralActions, dispatch),
    callLogAct: bindActionCreators(CallLogActions, dispatch),
    serviceAct: bindActionCreators(ServiceActions, dispatch)
    //
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    buildServices: rootState.serviceReducer.buildServices,
    init: rootState.generalReducer.init,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(CreateCallLogContainer);
