import { createStore, applyMiddleware, compose } from 'redux';
import { autoRehydrate } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducer.config';
import rootSaga from '../sagas';

const configureStore = (initialState) => {
  const sagaMiddleware = createSagaMiddleware();
  let store = {};
  if (process.env.NODE_ENV === 'development') {
    /* eslint-disable no-underscore-dangle */
    store = createStore(
      rootReducer,
      initialState,
      compose(
        autoRehydrate(),
        applyMiddleware(sagaMiddleware),
        // window.devToolsExtension()
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
      )
    );
    /* eslint-enable */
  } else {
    store = createStore(
      rootReducer,
      initialState,
      compose(
        autoRehydrate(),
        applyMiddleware(sagaMiddleware),
      )
    );
  }
  sagaMiddleware.run(rootSaga);
  return store;
};

const store = configureStore();
export default store;
