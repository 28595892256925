import { API_URL } from '../../configs/api.config';
import Request from '../middleware/request';

const departmentAPI = {
  /**
   * 
   */
  postSearchDepartment(args) {
    return Request.callAPI('post', `${API_URL}/Organization/Search`, { ...args });
  },
  postCreateDepartment(args) {
    return Request.callAPI('post', `${API_URL}/Organization`, { ...args });
  },
  putModifyDepartment(args) {
    return Request.callAPI('put', `${API_URL}/Organization/${args.id}`, { ...args.data });
  },
  deleteDepartment(args) {
    return Request.callAPI('delete', `${API_URL}/Organization/${args.id}`);
  }
};

export default departmentAPI;

