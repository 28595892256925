import _ from 'lodash';
import Decimal from 'decimal.js/decimal';

const Private = {
  calcServiceFee(service, profiles, numberOfProfile, serviceFee) {
    if (_.isNumber(serviceFee) === false || serviceFee <= 0) {
      return { amount: 0 };
    }

    return { amount: numberOfProfile * serviceFee };
  },
  calcCountryArrivalFee(service, profiles, countryArrivalFees) {
    if (_.isArray(countryArrivalFees) === true) {
      return { amount: 0 };
    }
    const fees = [];
    let amount = 0;

    const labels = {
      arrival: 'Giá tiền CNVC'
      // visaExtension: 'Gia hạn visa',
      // temporaryResidence: 'Thẻ tạm trú',
      // workPermit: 'Giấy phép LĐ'
    };
    _.forEach(countryArrivalFees, (fee, k) => {
      const label = _.get(labels, k, false);
      if (_.isEmpty(label) === false && fee > 0) {
        if (_.isEmpty(fees[label]) === true) {
          fees[k] = {
            amount: fee,
            label
          };
        }
        amount += fee;
      }
    });
    return {
      fees,
      amount
    };
  },
  calcProcessingTimeFee(service, profiles, numberOfProfile, processingTimeFee) {
    if (_.isNumber(processingTimeFee) === false || processingTimeFee <= 0) {
      return { amount: 0 };
    }

    return { amount: numberOfProfile * processingTimeFee };
  },
  calcExtraServiceFee(service, profiles, numberOfProfile, extraServiceFee) {
    if (_.isNumber(extraServiceFee) === false || extraServiceFee <= 0) {
      return { amount: 0 };
    }
    return { amount: numberOfProfile * extraServiceFee };
  },
  calcAirportArrivalFee(service, numberOfProfile, airportArrivalInfo) {
    const amount = _.get(airportArrivalInfo, 'amount', 0);
    if (
      _.isNumber(amount) === false || amount <= 0
      || _.isEmpty(service) === true
      || _.isEmpty(airportArrivalInfo) === true
    ) {
      return { amount: 0 };
    }

    return { amount: numberOfProfile * amount };
  },
  calcGuaranteeFee(service, profiles, numberOfProfile, calcExtraBusinessFee) {
    if (_.isNumber(calcExtraBusinessFee) === false || calcExtraBusinessFee <= 0) {
      return { amount: 0 };
    }
    return { amount: numberOfProfile * calcExtraBusinessFee };
  }
};
// estimateFee, model, profiles
function immigrationFee(estimateFee, service, profiles, model) {
  const {
    numberOfProfile, serviceFee, countryArrivalFees,
    processingTimeFee, extraServiceFee,
    airportArrivalFee, guaranteeFee
  } = estimateFee;
  const {
    airportArrival
  } = model;

  if (
    _.isNumber(numberOfProfile) === false
    || numberOfProfile <= 0
    || _.isEmpty(service) === true
  ) {
    return false;
  }

  const serviceFeeInfo = Private.calcServiceFee(service, profiles, numberOfProfile, serviceFee);
  const countryArrivalFeeInfo = Private.calcCountryArrivalFee(service, profiles, countryArrivalFees);
  const airportArrivalFeeInfo = Private.calcAirportArrivalFee(service, numberOfProfile, airportArrival);
  const processingTimeFeeInfo = Private.calcProcessingTimeFee(service, profiles, numberOfProfile, processingTimeFee);
  const extraServiceFeeInfo = Private.calcExtraServiceFee(service, profiles, numberOfProfile, extraServiceFee);
  const guaranteeFeeInfo = Private.calcGuaranteeFee(service, profiles, numberOfProfile, guaranteeFee);
  const amount = guaranteeFeeInfo.amount + serviceFeeInfo.amount + countryArrivalFeeInfo.amount + airportArrivalFeeInfo.amount + processingTimeFeeInfo.amount + extraServiceFeeInfo.amount;
  return {
    serviceFeeInfo,
    countryArrivalFeeInfo,
    processingTimeFeeInfo,
    extraServiceFeeInfo,
    airportArrivalFeeInfo,
    guaranteeFeeInfo,
    generalInfo: {
      numberOfProfile,
      serviceFee,
      countryArrivalFees,
      processingTimeFee,
      extraServiceFee,
      airportArrivalFee,
      guaranteeFeeInfo,
      amount
    }
  };
}

// eslint-disable-next-line import/prefer-default-export
export { immigrationFee };

