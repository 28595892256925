import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import CreateImmigrationOrder from './createImmigrationOrder';
import * as AccountActions from '../../../redux/accounts/accounts.actions';
import * as AgencyActions from '../../../redux/agencies/agencies.actions';
import * as SupplierActions from '../../../redux/suppliers/suppliers.actions';
import * as GeneralActions from '../../../redux/general/general.actions';
import * as WalletActions from '../../../redux/wallets/wallets.actions';
import * as ArrivalOrdersActions from '../../../redux/arrivalOrders/arrivalOrders.actions';
import { INVOICE_ARRIVAL_CREATE_SCOPE } from '../../../configs/constants';
import {
  serviceDetail,
  serviceHandleDatas,
  buildListCountry,
  checkScope,
  isStaff
} from '../../../helpers';

export const PAGE_LIMIT = 50;

class CreateImmigrationOrderContainer extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = {
      tagSuggestions: [],
      profiles: [],
      agency: {
        title: '',
        email: '',
        phone: ''
      }
    };

    //
    this.onSubmit = this.onSubmit.bind(this);
    this.callback = this.callback.bind(this);
    this.callbackNew = this.callbackNew.bind(this);
    //
    this.getAgencyInfo = this.getAgencyInfo.bind(this);
    this.getTagSuggest = this.getTagSuggest.bind(this);
    this.buildGetTagSuggest = this.buildGetTagSuggest.bind(this);
    //
    this.buildCountry = this.buildCountry.bind(this);
    this.listServiceHandleData = this.listServiceHandleData.bind(this);

    this.getWallet = this.getWallet.bind(this);
    this.getServiceDetail = this.getServiceDetail.bind(this);

    const hasCreateInvoiceArrivalPermission = checkScope(INVOICE_ARRIVAL_CREATE_SCOPE, this.props);
    if (hasCreateInvoiceArrivalPermission === false) {
      NotificationManager.error('Chức năng không tồn tại', '', 1500);
      setTimeout(() => {
        this.props.history.push('/dashboard');
      }, 2000);
    }
  }

  getSuppliers(args) {
    try {
      const { supplierAct } = this.props;
      supplierAct.searchSupplier(args);
    } catch (error) {
      // console.log('error', error);
    }
  }

  getWallet() {
    try {
      const { walletAct, init } = this.props;
      const isStaffStatus = isStaff(this.props);
      let accountId = this.props.computedMatch.params.id;
      if (isStaffStatus === true && _.isEmpty(accountId) === true) {
        this.props.history.push('/agency');
      }

      if (isStaffStatus === false) {
        accountId = this.props.init.accountInfo.id;
      }

      if (accountId) {
        walletAct.detailWallet({
          filters: {
            accountId: [accountId]
          }
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  onSubmit = async (args, type) => {
    try {
      const { arrivalOrdersAct } = this.props;

      if (type === 'addNew') {
        arrivalOrdersAct.createArrivalOrder(args, this.callbackNew);
      } else {
        arrivalOrdersAct.createArrivalOrder(args, this.callback);
      }
    } catch (error) {
      // console.log('error', error);
    }
  }

  callback(status, data) {
    if (status) {
      NotificationManager.info('Tạo đơn hàng thành công,đang chuyển về trang quản lý', '', 1000);
      if (isStaff(this.props) === false) {
        setTimeout(() => {
          this.props.history.push('/dashboard');
        }, 1500);
      } else {
        setTimeout(() => {
          this.props.history.push('/order');
        }, 1500);
      }
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }

  callbackNew(status, data) {
    if (status) {
      NotificationManager.info('Tạo đơn hàng thành công,đang chuyển về trang tạo mới đơn hàng', '', 1000);
      setTimeout(() => {
        const id = this.props.computedMatch.params.id;
        this.props.history.push(`/order-immigration-create/${id}`);
      }, 1500);
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }

  /**
   * 
   */
  getAgencyInfo(args) {
    try {
      const { agencyAct } = this.props;
      agencyAct.detailAgency(args);
    } catch (error) {
      // console.log('error', error);
    }
  }

  /**
   * getTagSuggest
   */
  getTagSuggest() {
    try {
      const { orderAct } = this.props;
      orderAct.orderTagSearch({});
    } catch (error) {
      // console.log('error', error);
    }
  }

  buildGetTagSuggest() {
    const { orderTags } = this.props;
    try {
      const tagSuggestions = [];
      if (orderTags) {
        orderTags.forEach((element) => {
          tagSuggestions.push({
            label: element,
            value: element
          });
        });
      }
      return tagSuggestions;
    } catch (error) {
      return [];
    }
  }

  /**
   * 
   */
  getServiceDetail(serviceIdentifyCode) {
    try {
      const { buildServices } = this.props;
      if (_.isEmpty(buildServices) === false) {
        return serviceDetail(buildServices, serviceIdentifyCode);
      }
      return {};
    } catch (error) {
      return {};
    }
  }

  /**
   * 
   */

  listServiceHandleData() {
    try {
      const result = { list: [], setlectedItem: {} };
      const { buildServices } = this.props;

      const types = ['arrival'];
      if (_.isEmpty(buildServices) === false) {
        return serviceHandleDatas(buildServices, types);
      }
      return result;
    } catch (error) {
      return false;
    }
  }

  /**
   * 
   */

  render() {
    const {
      buildServices,
      tagSuggestions,
      profiles
    } = this.state;

    return (
      <div>
        <CreateImmigrationOrder
          profiles={profiles}
          tagSuggestions={tagSuggestions}
          buildServices={buildServices}
          buildCountry={this.buildCountry}
          buildGetTagSuggest={this.buildGetTagSuggest}
          userInfo={this.userInfo}
          onSubmit={this.onSubmit}
          getWallet={this.getWallet}
          getDelegateMember={this.getDelegateMember}
          listServiceHandleData={this.listServiceHandleData}
          getServiceDetail={this.getServiceDetail}
        />
        <NotificationContainer />
      </div>

    );
  }

  buildCountry() {
    const { init } = this.props;
    const listCountry = [];
    if (init.country) {
      return buildListCountry(init.country);
    }
    return listCountry;
  }

  componentWillMount() {
    try {
      const { pathname } = this.props.location;
      const match = pathname.match('order', 'i');
      if (_.isEmpty(match) === false && _.isEmpty(this.props.passiveMenuInfo) === false) {
        const orderMenu = _.find(this.props.passiveMenuInfo, { id: 'order' });
        this.props.dispatch({
          type: 'REDUX_SET_ACTIVE_MENU',
          val: orderMenu
        });
      }
      const isStaffStatus = isStaff(this.props);
      let id = this.props.computedMatch.params.id;
      if (isStaffStatus === true && _.isEmpty(id) === true) {
        this.props.history.push('/agency');
      }

      if (isStaffStatus === false) {
        id = this.props.init.accountInfo.id;
      }

      this.getAgencyInfo({
        filters: { id }
      });
      this.getWallet(id);
      this.getTagSuggest({});
      const filterSupplier = {
        filters: {
          group: ['supplier']
        },
        paging: {
          start: 0,
          limit: PAGE_LIMIT
        }
      };
      this.getSuppliers(filterSupplier);
    } catch (error) {
      // console.log(error);
    }
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    agencyAct: bindActionCreators(AgencyActions, dispatch),
    generalAct: bindActionCreators(GeneralActions, dispatch),
    supplierAct: bindActionCreators(SupplierActions, dispatch),
    accountAct: bindActionCreators(AccountActions, dispatch),
    arrivalOrdersAct: bindActionCreators(ArrivalOrdersActions, dispatch),
    walletAct: bindActionCreators(WalletActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    orderTags: rootState.orderReducer.orderTags,
    buildServices: rootState.serviceReducer.buildServices,
    agencies: rootState.agencyReducer.agencies,
    agency: rootState.agencyReducer.agency,
    suppliers: rootState.supplierReducer.suppliers,
    accounts: rootState.accountReducer.accounts,
    init: rootState.generalReducer.init,
    passiveMenuInfo: rootState.generalReducer.passiveMenuInfo,
    activeMenu: rootState.generalReducer.activeMenu,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(CreateImmigrationOrderContainer);
