import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import SearchCallLog from './searchCallLog';
import * as OrderActions from '../../../redux/orders/orders.actions';
import * as CallLogActions from '../../../redux/callLogs/callLogs.actions';
import * as ServiceActions from '../../../redux/services/services.actions';
import { PAGE_LIMIT, CALLLOG_MANAGE_SCOPE } from '../../../configs/constants';
import store from '../../../configs/store.config';
import {
  serviceDetail, serviceHandleDatas, checkScope,
  buildListLocation, buildListCustomerType, buildListCustomerSource, localStoreFilter
} from '../../../helpers';

class SearchCallLogContainer extends React.Component {
  constructor(prop) {
    super(prop);

    if (checkScope(CALLLOG_MANAGE_SCOPE, this.props) === false) {
      NotificationManager.error('Chức năng không tồn tại', '', 1500);
      setTimeout(() => {
        this.props.history.push('/dashboard');
      }, 2000);
    }

    this.state = {
      searching: {},
      orders: [],
      listService: [],
      listAccountState: []
    };
    this.getCallLogs = this.getCallLogs.bind(this);
    this.callLogDetail = this.callLogDetail.bind(this);
    this.buildLocation = this.buildLocation.bind(this);
    this.getServiceDetail = this.getServiceDetail.bind(this);
    this.buildCustomerSource = this.buildCustomerSource.bind(this);
    this.buildCustomerType = this.buildCustomerType.bind(this);
    this.listServiceHandleData = this.listServiceHandleData.bind(this);
    this.getSimpleCallLogs = this.getSimpleCallLogs.bind(this);
    this.getSimpleOrders = this.getSimpleOrders.bind(this);
    this.clickToCall = this.clickToCall.bind(this);
  }

  clickToCall(args) {
    try {
      const { callLogAct } = this.props;
      callLogAct.clickToCall(args, (status, data) => {
        if (status === true) {
          NotificationManager.info('Kết nối cuộc gọi thành công, vui lòng chờ!', '', 5000);
        } else {
          NotificationManager.error(data.message, '', 2000);
        }
      });
    } catch (error) {
      console.log('error', error);
    }
  }
  /**
   * Call Logs
   */

  callLogDetail(args) {
    try {
      const { callLogAct } = this.props;
      callLogAct.callLogDetail(args);
    } catch (error) {
      console.log('error', error);
    }
  }
  
  getSimpleCallLogs(args) {
    try {
      const { callLogAct } = this.props;
      callLogAct.listPickupCallLog(args);
    } catch (error) {
      // console.log('error', error);
    }
  }
   
  getCallLogs(args) {
    try {
      const { callLogAct } = this.props;

      const filters = Object.assign({}, args.filters);
      const query = Object.assign({}, args);

      _.forEach(filters, (item, key) => {
        if (_.isObject(item) === true) {
          _.forEach(item, (subItem, key2) => {
            if (_.isEmpty(subItem) === true) {
              if (_.isNumber(subItem) === false) {
                delete filters[key][key2];
              }
            }
          });
        } else if (_.isEmpty(item) === true) {
          if (_.isNumber(item) === false) {
            delete filters[key];
          }
        }

        if (_.isEmpty(item) === true) {
          if (_.isNumber(item) === false) {
            delete filters[key];
          }
        }
      });
      query.filters = filters;
      callLogAct.listCallLog(query);

      store.dispatch({
        type: 'LOCAL_STORE_FILTER',
        payload: {
          objID: 'searchCallLog',
          objType: 'getCallLogs',
          query
        }
      });
    } catch (error) {
      console.log('error', error);
    }
  }

  getSimpleOrders(args) {
    try {
      const { orderAct } = this.props;
      orderAct.searchSimpleOrder(args);
    } catch (error) {
      console.log('error', error);
    }
  }

  /**
   * getServices
   */
  getServices() {
    try {
      const { serviceAct } = this.props;
      serviceAct.listService({});
    } catch (error) {
      // console.log('error', error);
    }
  }

  /**
   * 
   */
  getServiceDetail(serviceIdentifyCode) {
    try {
      const { buildServices } = this.props;
      if (_.isEmpty(buildServices) === false) {
        return serviceDetail(buildServices, serviceIdentifyCode);
      }
      return {};
    } catch (error) {
      return {};
    }
  }

  listServiceHandleData() {
    try {
      const result = { list: [], setlectedItem: {} };
      const { buildServices } = this.props;

      if (_.isEmpty(buildServices) === false) {
        return serviceHandleDatas(buildServices);
      }
      return result;
    } catch (error) {
      return false;
    }
  }

  buildLocation() {
    const { init } = this.props;
    if (init.area) {
      return buildListLocation(init);
    }
    return [];
  }

  buildCustomerType() {
    const { init } = this.props;
    if (init.customerType) {
      return buildListCustomerType(init);
    }
    return [];
  }

  buildCustomerSource() {
    const { init } = this.props;
      
    if (init.customerSource) {
      return buildListCustomerSource(init);
    }
    return [];
  }

  render() {
    const {
      buildServices 
    } = this.state;

    return (
      <div>
        <SearchCallLog
          buildServices={buildServices}
          getCallLogs={this.getCallLogs}
          callLogDetail={this.callLogDetail}
          buildLocation={this.buildLocation}
          buildCustomerSource={this.buildCustomerSource}
          buildCustomerType={this.buildCustomerType}
          listServiceHandleData={this.listServiceHandleData}
          getServiceDetail={this.getServiceDetail}
          getSimpleCallLogs={this.getSimpleCallLogs}
          getSimpleOrders={this.getSimpleOrders}
          clickToCall={this.clickToCall}
        />
        <NotificationContainer />
      </div>
        
    );
  }

  componentWillMount() {
    try {
      /**
       * 
       */
      //
      this.getServices();
      const callLogsFilter = localStoreFilter('searchCallLog', 'getCallLogs');
      this.getCallLogs(callLogsFilter || {
        filters: {},
        paging: { start: 0, limit: PAGE_LIMIT }
      });
    } catch (error) {
      // console.log(error);
    }
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    orderAct: bindActionCreators(OrderActions, dispatch),
    callLogAct: bindActionCreators(CallLogActions, dispatch),
    serviceAct: bindActionCreators(ServiceActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    buildServices: rootState.serviceReducer.buildServices,
    localStoreFilter: rootState.generalReducer.localStoreFilter,
    init: rootState.generalReducer.init,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(SearchCallLogContainer);
