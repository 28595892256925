import { API_URL } from '../../configs/api.config';
import Request from '../middleware/request';

const accountAPI = {
  /**
   * 
   */
  postCreateAccount(args) {
    return Request.callAPI('post', `${API_URL}/Account`, { ...args });
  },
  putModifyAccount(args) {
    return Request.callAPI('put', `${API_URL}/Account/${args.id}`, { ...args.data });
  },
  putModifyAccountProfile(args) {
    return Request.callAPI('put', `${API_URL}/Account/Information`, { ...args });
  },
  postSearchAccount(args) {
    return Request.callAPI('post', `${API_URL}/Account/Search`, { ...args });
  },
  postSearchByKeywordAccount(args) {
    return Request.callAPI('post', `${API_URL}/Account/SearchByKeyword`, { ...args });
  }
  
};

export default accountAPI;

