import { call, put, takeLatest } from 'redux-saga/effects';
import * as responseCode from '../../configs/responseCode.config';
import { types } from '../../redux/coboPobo';
import { coboPoboAPI } from '../../api';
import { types as apiTypes } from '../../redux/api';

function* doAction(action) {
  try {
    yield put({ type: apiTypes.SHOW_LOADING });
    const { code, data } = yield call(coboPoboAPI.postExport, action.payload);
    yield put({ type: apiTypes.HIDE_LOADING });
    switch (code) {
      case responseCode.SUCCEEDED: {
        yield put({ type: types.COBO_POBO_EXPORT_FILE_SUCCESS, payload: data });
        if (action.callback) {
          action.callback(true, data);
        }
        break;
      }
      default: {
        yield put({ type: types.COBO_POBO_EXPORT_FILE_FAIL });
        if (action.callback) {
          action.callback(false, data);
        }
      }
    }
  } catch (error) {
    yield put({ type: apiTypes.HIDE_LOADING });
    yield put({ type: types.COBO_POBO_EXPORT_FILE_FAIL });
  }
}

export default function* watchAction() {
  yield takeLatest(types.COBO_POBO_EXPORT_FILE, doAction);
}

