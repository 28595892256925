
export const SET_PICKUP = 'SET_PICKUP';
export const SET_HANGUP = 'SET_HANGUP';
export const GET_SOCKET_CALL_LOG = 'GET_SOCKET_CALL_LOG';
export const BUSY_CALL_LOG = 'BUSY_CALL_LOG';
export const PUSH_SOCKET_CALL_LOG = 'PUSH_SOCKET_CALL_LOG';
export const REMOVE_SOCKET_CALL_LOG = 'REMOVE_SOCKET_CALL_LOG';
export const REMOVE_SOCKET_NOTIFICATION = 'REMOVE_SOCKET_NOTIFICATION';

export const POST_SEARCH_PICKUP_CALL_LOG = 'POST_SEARCH_PICKUP_CALL_LOG';
export const POST_SEARCH_PICKUP_CALL_LOG_SUCCESS = 'POST_SEARCH_PICKUP_CALL_LOG_SUCCESS';
export const POST_SEARCH_PICKUP_CALL_LOG_FAIL = 'POST_SEARCH_PICKUP_CALL_LOG_FAIL';

export const POST_SEARCH_CALL_LOG = 'POST_SEARCH_CALL_LOG';
export const POST_SEARCH_CALL_LOG_SUCCESS = 'POST_SEARCH_CALL_LOG_SUCCESS';
export const POST_SEARCH_CALL_LOG_FAIL = 'POST_SEARCH_CALL_LOG_FAIL';

export const POST_SEARCH_CALL_LOG_DETAIL = 'POST_SEARCH_CALL_LOG_DETAIL';
export const POST_SEARCH_CALL_LOG_DETAIL_SUCCESS = 'POST_SEARCH_CALL_LOG_DETAIL_SUCCESS';
export const POST_SEARCH_CALL_LOG_DETAIL_FAIL = 'POST_SEARCH_CALL_LOG_DETAIL_FAIL';

export const PUT_CALL_LOG = 'PUT_CALL_LOG';
export const PUT_CALL_LOG_SUCCESS = 'PUT_CALL_LOG_SUCCESS';
export const PUT_CALL_LOG_FAIL = 'PUT_CALL_LOG_FAIL';
export const PUT_CALL_LOGS_MODAL = 'PUT_CALL_LOGS_MODAL';

export const POST_CLICK_TO_CALL = 'POST_CLICK_TO_CALL';
export const POST_CLICK_TO_CALL_SUCCESS = 'POST_CLICK_TO_CALL_SUCCESS';
export const POST_CLICK_TO_CALL_FAIL = 'POST_CLICK_TO_CALL_FAIL';

export const POST_SEARCH_CUSTOMER_SUPPORT_DETAIL = 'POST_SEARCH_CUSTOMER_SUPPORT_DETAIL';
export const POST_SEARCH_CUSTOMER_SUPPORT_DETAIL_SUCCESS = 'POST_SEARCH_CUSTOMER_SUPPORT_DETAIL_SUCCESS';
export const POST_SEARCH_CUSTOMER_SUPPORT_DETAIL_FAIL = 'POST_SEARCH_CUSTOMER_SUPPORT_DETAIL_FAIL';
