import * as types from './api.types';

const initialState = {
  resetState: false,
  showLoading: false,
  type: null,
  data: {},
  themeSettings: 'default'
};

export default function api(state = initialState, action) {
  switch (action.type) {
    case types.RESET_STATE: {
      const { resetStatus } = action.payload;
      return {
        ...state,
        resetState: resetStatus
      };
    }
    case types.SHOW_LOADING:
      return {
        ...state,
        showLoading: true
      };
    case types.HIDE_LOADING:
      return {
        ...state,
        showLoading: false
      };
    case types.STORE_API_MESSAGE: {
      const {
        type, message, code, isDefault
      } = action.payload;
      return {
        type,
        message,
        code,
        isDefault
      };
    }
    case types.STORE_API_PAYLOAD: {
      const { type, payload } = action.payload;
      return {
        type,
        data: { ...payload }
      };
    }
    case types.CLEAR_API_RESPONSE:
      return {};

    case types.THEME_UPDATE: {
      return {
        ...state,
        themeSettings: action.payload
      };
    }

    case types.SIDEBAR_UPDATE: {
      return {
        ...state,
        sidebar: action.payload
      };
    }
    default:
      return state;
  }
}
