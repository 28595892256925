import * as types from './wallets.types';

const initialState = {
  socketBalance: {},
  wallets: [],
  wallet: {},
  walletHistory: {}
};

export default function accounts(state = initialState, action) {
  switch (action.type) {
    case types.SOCKET_WALLET: {
      return {
        ...state,
        socketBalance: action.payload
      };
    }
    case types.POST_SEARCH_WALLET_DETAIL_SUCCESS: {
      return {
        ...state,
        wallet: action.payload
      };
    }

    case types.POST_WALLET_HISTORY_SUCCESS: {
      return {
        ...state,
        walletHistory: action.payload
      };
    }

    case types.POST_WALLET_HISTORY_CREDIT_SUCCESS: {
      return {
        ...state,
        walletHistoryCredit: action.payload
      };
    }

    case types.POST_SEARCH_WALLET_SUCCESS: {
      return {
        ...state,
        wallets: action.payload
      };
    }
    
    default:
      return state;
  }
}
