import React from 'react';
import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import Select from 'react-select';
import validator from 'validator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DelayInput } from 'react-delay-input';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import ReactAudioPlayer from 'react-audio-player';
import PagingContainer from '../../ultils/paging.container';
import { PAGE_LIMIT, PAGE_MAX_LIMIT } from '../../../configs/constants';
import store from '../../../configs/store.config';

import {
  paging,
  formatHourMinute,
  formatDateFilter,
  formatDateRange,
  formatDate2,
  localStoreFilter,
  localStorePageNum,
  formatDateFilter2
} from '../../../helpers';

class SearchCallLog extends React.Component {
  constructor(props) {
    super(props);

    const callLogsFilter = localStoreFilter('searchCallLog', 'getCallLogs');
    const createdAt = _.get(callLogsFilter, 'filters.createdAt');
    const dateRangeTitle = formatDateFilter2(_.get(createdAt, 'from', false), _.get(createdAt, 'to', false), 'Chọn thời gian');
    this.state = {
      pageNum: localStorePageNum('searchCallLog', 'getCallLogs'),
      typeDefaultValue: { label: 'Chọn chiều gọi', value: 'all' },
      types: [
        { label: 'Chọn chiều gọi', value: 'all' },
        { label: 'Cuộc gọi đến', value: 'INCOMING' },
        { label: 'Cuộc gọi đi', value: 'OUTGOING' },
        { label: 'Cuộc gọi nhỡ', value: 'MISS' }
      ],
      dateRangeTitle,
      ranges: {
        'Clear date': [1, 1],
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      },

      searchingOrder: {
        filters: {
          customer: {
            phone: ''
          }
        },
        paging: {
          start: 0,
          limit: 10
        }
      },

      searchingSimpleCallLog: {
        filters: {
          callerPhone: ''
        },
        paging: {
          start: 0,
          limit: PAGE_MAX_LIMIT
        }
      },
      
      searching: callLogsFilter || {
        filters: {
          callerPhone: '',
          callerName: '',
          type: '',
          createdAt: {
            from: '',
            to: ''
          }
      
        },

        paging: {
          start: 0,
          limit: PAGE_LIMIT
        }
      }
    };
    this.onSearchChangeHandle = this.onSearchChangeHandle.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.handleDatePicker = this.handleDatePicker.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
  }

  handleOpenModal(item, index, e) {
    e.preventDefault();
    const { showCallLogModal } = this.props;
    this.props.callLogDetail({
      filters: { id: [item.id] }
    });

    /**
         * Get History
         */
    const searchingSimpleCallLog = _.clone(this.state.searchingSimpleCallLog);
    const searchingOrder = _.clone(this.state.searchingOrder);

    searchingSimpleCallLog.filters.callerPhone = item.callerPhone;
    searchingOrder.filters.customer.phone = item.callerPhone;
    this.props.getSimpleCallLogs(searchingSimpleCallLog);
    this.props.getSimpleOrders(searchingOrder);
    // nvb edit
    // if (showCallLogModal === true) {
    //   store.dispatch({
    //     type: 'PUT_CALL_LOGS_MODAL',
    //     payload: false
    //   });
    // }

    // setTimeout(() => {
    //   store.dispatch({
    //     type: 'PUT_CALL_LOGS_MODAL',
    //     payload: true
    //   });
    // }, 100);
    store.dispatch({
      type: 'PUT_CALL_LOGS_MODAL',
      payload: true
    });
  }

  handleDatePicker(e, picker) {
    const searching = Object.assign({}, this.state.searching);
    searching.filters.createdAt = {
      from: '',
      to: ''
    };
    if (picker.startDate.unix() > 0) {
      const pageNum = 1;
      const dateRangeTitle = formatDateFilter(picker.startDate, picker.endDate);
      const date = formatDateRange(picker.startDate, picker.endDate);
      this.setState({ searching, dateRangeTitle, pageNum: 1 });
      searching.filters.createdAt.from = date.startDate;
      searching.filters.createdAt.to = date.endDate;
      searching.paging = paging(this.state.pageNum, PAGE_LIMIT);
      this.props.getCallLogs(searching);
    } else {
      this.setState({ searching, dateRangeTitle: 'Chọn thời gian', pageNum: 1 });
      this.props.getCallLogs(searching);
    }
  }

  onSearchChangeHandle(key, e) {
    try {
      const searching = Object.assign({}, this.state.searching);
      const value = _.get(e, 'target.value', _.get(e, 'value', false));
      const result = {
        checkbox: {},
        pageNum: 1
      };
      switch (key) {
        case 'phone': {
          const isNumber = validator.isNumeric(value);
          searching.filters.callerName = '';
          searching.filters.callerPhone = '';
  
          if (isNumber === true) {
            searching.filters.callerPhone = value;
          } else {
            searching.filters.callerName = value;
          }
  
          break;
        }
        case 'type':
        {
          searching.filters.type = '';
          if (value !== 'all') {
            searching.filters.type = _.get(e, 'target.value', _.get(e, 'value', false));
          }
          result.typeDefaultValue = e;

          break;
        }
        default: {
          searching.filters[key] = _.get(e, 'target.value', _.get(e, 'value', ''));
          break;
        }
      }
      searching.paging = paging(result.pageNum, PAGE_LIMIT);
      this.props.getCallLogs(searching);
      result.searching = searching;
      this.setState(result);
    } catch (error) {
      // console.log(error);
    }
  }

  onChangePage(key) {
    const searching = Object.assign({}, this.state.searching);
    let pageNum = this.state.pageNum;
    switch (key) {
      case 'next': {
        //
        pageNum += 1;
        break;
      }
      default: {
        pageNum -= 1;
        if (pageNum <= 1) {
          pageNum = 1;
        }
        break;
      }
    }
    searching.paging = paging(pageNum, PAGE_LIMIT);
    this.props.getCallLogs(searching);
    this.setState({ pageNum });
  }

  clickToCall(phone) {
    const { init } = this.props;
    const extension = _.get(init, 'accountInfo.voip.extension', false);
    this.props.clickToCall({
      extension,
      phone
    });
  }

  renderCallLog() {
    try {
      const { callLogs, init } = this.props;
      
      if (_.isEmpty(callLogs) === true) {
        return (<tr></tr>);
      }
      
      const listCustomerSource = this.props.buildCustomerSource();
      const extension = _.get(init, 'accountInfo.voip.extension', false);
      const result = [];
      let date = '';
      callLogs.forEach((element) => {
        const createdAt = formatDate2(element.createdAt);
        if (createdAt !== date) {
          date = createdAt;
          result.push({ createdAt });
        }
        result.push(element);
      });
      
      return (result.map((item, i) => {
        if (!item.id) {
          return (<tr key={i} className="table-active table-border-double">
            <td colSpan="11" className="font-weight-semibold">
              Lịch sử gọi ngày {item.createdAt}
            </td>
          </tr>);
        }

        const type = _.find(this.state.types, { value: item.type });
        const service = this.props.getServiceDetail(_.get(item, 'information.service', ''));
        const source = _.find(listCustomerSource, { value: _.get(item, 'information.source', '') });
        const minutes = Math.floor(_.get(item, 'duration', 0) / 60);
        const seconds = _.get(item, 'duration', 0) - minutes * 60;

        const currentDate = formatDate2(item.createdAt);
        let callerRecordedUrl = _.get(item, 'callerRecordedUrl', '');
        
        if (formatDate2(new Date()) !== formatDate2(item.createdAt) && callerRecordedUrl) {
          callerRecordedUrl = callerRecordedUrl.replace('.wav', '.ogg');
        }
        let time = `${seconds} giây`;
        if (minutes > 0) {
          time = `${minutes} phút ${seconds} giây`;
        }
        return (<tr key={`renderCallLog_${i}`}>
          <td>{item.id}</td>
          <td>{type.label}
            <br />
            <div className="text-muted font-size-sm">
              {item.trunkId === 1 ? '028.7777.7979' : '028.77777.888'}
            </div></td>
          <td>{formatHourMinute(item.createdAt)}</td>
          <td>
            <a href="#"onClick={this.handleOpenModal.bind(this, item, item.id)}>
              {_.get(item, 'callerPhone', '')}
            </a>

          </td>
          <td>{_.get(item, 'callerName', '')}</td>
          <td>{time}</td>
          <td>{_.get(item, 'account.title', '')}</td>
          <td>
            {/* {extension ? <button
              tyle={{ borderRadius: '50%' }} 
              onClick={this.clickToCall.bind(this, item.callerPhone)} 
              className="btn btn-default">
              <i className="fa fa-phone"></i>
            </button> : ''} */}
          </td>
        </tr>);
      }));
    } catch (error) {
      return (<tr></tr>);
    }
  }

  render() {
    const {
      types, searching, ranges, dateRangeTitle, typeDefaultValue, states
    } = this.state;

    const phone = _.get(searching, 'filters.callerName', '') || _.get(searching, 'filters.callerPhone', '');
    const state = _.find(states, { value: _.get(searching, 'filters.state', 'all') }) || typeDefaultValue;
    return (
      <div>
        <div className="card">
          <div className="card-header bg-transparent header-elements-inline">
            <h5 className="card-title">Lịch sử cuộc gọi</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="form-group col-md-4">
                <DelayInput
                  value={phone}
                  type="text"
                  className="form-control"
                  minLength={0}
                  delayTimeout={300}
                  placeholder="Nhập tên hoặc số điện thoại"
                  onChange={this.onSearchChangeHandle.bind(this, 'phone')}
                />

              </div>
              <div className="form-group col-md-4">
                <Select
                  value={state}
                  onChange={this.onSearchChangeHandle.bind(this, 'type')}
                  options={types || []}
                />
              </div>

              <div className="form-group col-md-3">
                <DateRangePicker
                  className="btn btn-light daterange-predefined"
                  onApply={this.handleDatePicker}
                  ranges={ranges}
                >
                  <button style={{ padding: '7px 15px' }}>
                    <i className="icon-calendar22 mr-2"></i>
                    <span> {dateRangeTitle} </span>
                  </button>
                </DateRangePicker>

              </div>

            </div>
          </div>

          <div className="table-responsive" style={{ paddingBottom: 200 }}>
            <table className="table table-bordered table-striped text-nowrap">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Loại cuộc gọi</th>
                  <th>Giờ thực hiện</th>
                  <th>Số điện thoại</th>
                  <th>Tên khách hàng</th>
                  <th>Thời gian gọi</th>
                  <th>Nhân viên</th>
                  <th>Thực thi</th>
                </tr>
              </thead>
              <tbody>
                {this.renderCallLog()}
              </tbody>
            </table>

            <PagingContainer
              count={_.get(this.props, 'callLogs', []).length}
              pageNum={this.state.pageNum}
              limit={PAGE_LIMIT}
              onClick={this.onChangePage.bind(this)}
            />
          </div>
        </div>
        
      </div>

    );
  }
  
  componentDidUpdate(oldProps) {
    try {
      /**
       * 
       */
      // 
    } catch (error) {
      // console.log(error);
      /**
       * 
       */
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      localStoreFilter: payload.generalReducer.localStoreFilter,
      pickupCallLogs: payload.callLogReducer.pickupCallLogs,
      simpleOrders: payload.orderReducer.simpleOrders,
      showCallLogModal: payload.callLogReducer.showCallLogModal,
      callLogs: payload.callLogReducer.callLogs,
      buildServices: payload.serviceReducer.buildServices,
      init: payload.generalReducer.init,
      userInfo: payload.generalReducer.userInfo,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(SearchCallLog)
);
