import { API_URL } from '../../configs/api.config';
import Request from '../middleware/request';

const generalAPI = {
  /**
   * INIT
   */
  getInit(args) {
    return Request.callAPI('get', `${API_URL}/General/Init`, { ...args });
  },
  postProfileSearch(args) {
    return Request.callAPI('post', `${API_URL}/Profile/Search`, { ...args });
  },
  /**
   * 
   * @param {*} args 
   */
  
  putCountryArrival(args) {
    return Request.callAPI('put', `${API_URL}/General/CountryArrival`, { ...args });
  },
  /**
   * Security
   */
  
  getScope(args) {
    return Request.callAPI('get', `${API_URL}/Security/Scope`, { ...args });
  },
  /**
   * 
   * @param {*} args 
   */
  getAccountMenu(args) {
    return Request.callAPI('get', `${API_URL}/General/Menu`, { ...args });
  },
  postAccountLogin(args) {
    return Request.callAPI('post', `${API_URL}/Account/Authorize`, { ...args });
  },
  postAccountLogout(args) {
    return Request.callAPI('post', `${API_URL}/Account/Logout`, { ...args });
  },
  postAccountChangePass(args) {
    return Request.callAPI('post', `${API_URL}/Account/ChangePass`, { ...args });
  }
};

export default generalAPI;

