import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import CreatePermission from './createPermission';

class CreatePermissionContainer extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = {};
  }

  render() {
    return (
      <div>
        <CreatePermission
          errorMessage={this.state.errorMessage}
          userInfo={this.userInfo}
        />
          
      </div>
        
    );
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    //
  };
}

export default connect((rootState) => {
  const userInfo = _.get(rootState, 'generalReducer.userInfo', {});
  return {
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(CreatePermissionContainer);
