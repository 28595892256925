import { API_URL } from '../../configs/api.config';
import Request from '../middleware/request';

const UtilityAPI = {
  /**
   * 
   */
  postSendSMS(args) {
    return Request.callAPI('post', `${API_URL}/Utility/SendSms`, { ...args });
  },
  postSendEmail(args) {
    return Request.callAPI('post', `${API_URL}/Utility/SendMail`, { ...args });
  }
};

export default UtilityAPI;

