
/**
 * CRUD
 */

export const POST_SEARCH_CUSTOMER_SUPPORT_BY_CUSTOMER_TYPE = 'POST_SEARCH_CUSTOMER_SUPPORT_BY_CUSTOMER_TYPE';
export const POST_SEARCH_CUSTOMER_SUPPORT_BY_CUSTOMER_TYPE_SUCCESS = 'POST_SEARCH_CUSTOMER_SUPPORT_BY_CUSTOMER_TYPE_SUCCESS';
export const POST_SEARCH_CUSTOMER_SUPPORT_BY_CUSTOMER_TYPE_FAIL = 'POST_SEARCH_CUSTOMER_SUPPORT_BY_CUSTOMER_TYPE_FAIL';

export const POST_SEARCH_CUSTOMER_SUPPORT = 'POST_SEARCH_CUSTOMER_SUPPORT';
export const POST_SEARCH_CUSTOMER_SUPPORT_SUCCESS = 'POST_SEARCH_CUSTOMER_SUPPORT_SUCCESS';
export const POST_SEARCH_CUSTOMER_SUPPORT_FAIL = 'POST_SEARCH_CUSTOMER_SUPPORT_FAIL';
export const SOCKET_REMOVE_CUSTOMER_SUPPORTCALL_LOG = 'SOCKET_REMOVE_CUSTOMER_SUPPORTCALL_LOG';

export const POST_SEARCH_CUSTOMER_SUPPORT_DETAIL = 'POST_SEARCH_CUSTOMER_SUPPORT_DETAIL';
export const POST_SEARCH_CUSTOMER_SUPPORT_DETAIL_SUCCESS = 'POST_SEARCH_CUSTOMER_SUPPORT_DETAIL_SUCCESS';
export const POST_SEARCH_CUSTOMER_SUPPORT_DETAIL_FAIL = 'POST_SEARCH_CUSTOMER_SUPPORT_DETAIL_FAIL';

export const POST_CUSTOMER_SUPPORT = 'POST_CUSTOMER_SUPPORT';
export const POST_CUSTOMER_SUPPORT_SUCCESS = 'POST_CUSTOMER_SUPPORT_SUCCESS';
export const POST_CUSTOMER_SUPPORT_FAIL = 'POST_CUSTOMER_SUPPORT_FAIL';

export const PUT_CUSTOMER_SUPPORT = 'PUT_CUSTOMER_SUPPORT';
export const PUT_CUSTOMER_SUPPORT_SUCCESS = 'PUT_CUSTOMER_SUPPORT_SUCCESS';
export const PUT_CUSTOMER_SUPPORT_FAIL = 'PUT_CUSTOMER_SUPPORT_FAIL';

export const DELETE_CUSTOMER_SUPPORT = 'DELETE_CUSTOMER_SUPPORT';
export const DELETE_CUSTOMER_SUPPORT_SUCCESS = 'DELETE_CUSTOMER_SUPPORT_SUCCESS';
export const DELETE_CUSTOMER_SUPPORT_FAIL = 'DELETE_CUSTOMER_SUPPORT_FAIL';
