import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select2 from 'react-select2-wrapper';

class SearchCustomer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listType: [
        {
          text: 'Dịch vụ Visa Nhập cảnh',
          children: [
            { text: 'Visa Việt Nam', id: 'AZ' },
            { text: 'Gia Hạn Visa', id: 'CO' },
            { text: 'Thẻ Tạm Trú', id: 'ID' },
            { text: 'Giấy phép lao động', id: 'WY' }
          ]
        },
        {
          text: 'Visa Xuất cảnh',
          children: [
            { text: 'Đài Loan', id: 'AL' },
            { text: 'Hàn Quốc', id: 'IA' },
            { text: 'Nhật Bản', id: 'KS' },
            { text: 'Hongkong', id: 'KY' }
          ]
        }
        
      ]
    };
  }

  render() {
    const { listType } = this.state;

    return (
      <div>
        <div className="alert alert-info bg-white alert-styled-left alert-arrow-left alert-dismissible">
          <button type="button" className="close" data-dismiss="alert"><span>×</span></button>
          <h6 className="alert-heading font-weight-semibold mb-1">Danh sách khách hàng có nhu cầu khớp với khu vực của bạn</h6>Nhanh chóng gọi tư vấn khách <code>vì khách hàng thường không chờ đợi lâu</code>. Mọi thắc mắc xin vui lòng liên hệ người hướng dẫn của bạn</div>
        <div className="card">
          <div className="card-header header-elements-inline">
            <h5 className="card-title">Khách hàng tiềm năng:</h5>
            <div className="header-elements">
              <div className="list-icons">
                <a className="list-icons-item" data-action="collapse"></a>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="form-group col-md-3">
                <input type="text" className="form-control" placeholder="Nhập tên KH" />
              </div>
              <div className="form-group col-md-3">
                <Select2
                  style={{ width: '100%' }}
                  className="form-control select-search select2-hidden-accessible"
                  data={listType}
                  options={{
                    placeholder: 'Tất cả dịch vụ'
                  }}
          
                />
              </div>
              <div className="form-group col-md-1">
                <Link to="/customer-create" className="btn bg-teal-400" >
                  <i className="icon-add mr-2"></i> Thêm mới
                </Link>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered table-hover datatable-highlight">
              <thead>
                <tr>
                  <th>Mã số</th>
                  <th>Tên Khách hàng</th>
                  <th>Số ĐT</th>
                  <th>Phân Loại KH</th>
                  <th>Nguồn KH</th>
                  <th width="350">Ghi Chú</th>
                  <th width="250">Tiến trình</th>
                  <th>Dịch vụ</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>4</td>
                  <td>
                    <div className="media">
                      <div className="media-body align-self-center"><a href="/admincp/tool/customer-detail" className="font-weight-semibold">Lê Bình Minh</a>
                        <div className="text-muted font-size-sm"><span className="badge badge-mark border-success mr-1"></span> 20/01/2019</div>
                      </div>
                    </div>
                  </td>
                  <td>0987227744</td>
                  <td>Khách mới</td>
                  <td>Gọi Đến</td>
                  <td>3 căn nhà cho thuê, có stk, thăm con trai đi làm va con gái đang du học, đi với bé nhỏ 5t, gọi hỏi giá</td>
                  <td><strong>triphan</strong>: đã tư vấn khách</td>
                  <td>Visa Đài Loan 10 năm</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>
                    <div className="media">
                      <div className="media-body align-self-center"><a href="/admincp/tool/customer-detail" className="font-weight-semibold">Phạm Minh</a>
                        <div className="text-muted font-size-sm"><span className="badge badge-mark border-success mr-1"></span> 25/01/2019</div>
                      </div>
                    </div>
                  </td>
                  <td>0902468121</td>
                  <td>Khách tiềm năng</td>
                  <td>Facebook</td>
                  <td>Có sổ tiết kiêm 500tr</td>
                  <td><strong>dung</strong>: đã gọi khách khách hẹn ngày mai
                    <br /><strong>nhi</strong>: đã gọi lại mai khách lên nộp hs</td>
                  <td>Visa Hàn</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>
                    <div className="media">
                      <div className="media-body align-self-center"><a href="/admincp/tool/customer-detail" className="font-weight-semibold">Lê Bình Minh</a>
                        <div className="text-muted font-size-sm"><span className="badge badge-mark border-success mr-1"></span> 20/01/2019</div>
                      </div>
                    </div>
                  </td>
                  <td>0987227744</td>
                  <td>Khách mới</td>
                  <td>Google</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>
                    <div className="media">
                      <div className="media-body align-self-center"><a href="/admincp/tool/customer-detail" className="font-weight-semibold">Lê Bình Minh</a>
                        <div className="text-muted font-size-sm"><span className="badge badge-mark border-success mr-1"></span> 20/01/2019</div>
                      </div>
                    </div>
                  </td>
                  <td>0987227744</td>
                  <td>Khách mới</td>
                  <td>Google</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>
                    <div className="media">
                      <div className="media-body align-self-center"><a href="/admincp/tool/customer-detail" className="font-weight-semibold">Lê Bình Minh</a>
                        <div className="text-muted font-size-sm"><span className="badge badge-mark border-success mr-1"></span> 20/01/2019</div>
                      </div>
                    </div>
                  </td>
                  <td>0987227744</td>
                  <td>Khách mới</td>
                  <td>Google</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>
                    <div className="media">
                      <div className="media-body align-self-center"><a href="/admincp/tool/customer-detail" className="font-weight-semibold">Lê Bình Minh</a>
                        <div className="text-muted font-size-sm"><span className="badge badge-mark border-success mr-1"></span> 20/01/2019</div>
                      </div>
                    </div>
                  </td>
                  <td>0987227744</td>
                  <td>Khách mới</td>
                  <td>Google</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>
                    <div className="media">
                      <div className="media-body align-self-center"><a href="/admincp/tool/customer-detail" className="font-weight-semibold">Lê Bình Minh</a>
                        <div className="text-muted font-size-sm"><span className="badge badge-mark border-success mr-1"></span> 20/01/2019</div>
                      </div>
                    </div>
                  </td>
                  <td>0987227744</td>
                  <td>Khách mới</td>
                  <td>Google</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>
                    <div className="media">
                      <div className="media-body align-self-center"><a href="/admincp/tool/customer-detail" className="font-weight-semibold">Lê Bình Minh</a>
                        <div className="text-muted font-size-sm"><span className="badge badge-mark border-success mr-1"></span> 20/01/2019</div>
                      </div>
                    </div>
                  </td>
                  <td>0987227744</td>
                  <td>Khách mới</td>
                  <td>Google</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    try {
      /**
       * 
       */
    } catch (error) {
      /**
       * 
       */
    }
  }

  componentWillUnmount() {
    try {
      /**
       * 
       */
    } catch (error) {
      /**
       * 
       */
    }
  }

  componentDidUpdate() {
    try {
      /**
       * 
       */
    } catch (error) {
      /**
       * 
       */
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      userInfo: payload.generalReducer.userInfo,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(SearchCustomer)
);
