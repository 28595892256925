import { API_URL } from '../../configs/api.config';
import Request from '../middleware/request';

const walletAPI = {
  /**
   * 
   */
  postSearchWallet(args) {
    return Request.callAPI('post', `${API_URL}/Wallet/Search`, { ...args });
  },
  postSearchWalletHistory(args) {
    return Request.callAPI('post', `${API_URL}/Wallet/History/${args.id}`, { ...args.data });
  },
  putChangeWallet(args) {
    return Request.callAPI('put', `${API_URL}/Wallet/Change/${args.id}`, { ...args.data });
  }
};

export default walletAPI;

