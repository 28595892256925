import { API_URL } from '../../configs/api.config';
import Request from '../middleware/request';

const serviceAPI = {
  /**
   * 
   */
  getBuildService(args) {
    return Request.callAPI('get', `${API_URL}/Service/Build`, { ...args });
  },
  getService(args) {
    return Request.callAPI('get', `${API_URL}/Service/${args.id}`);
  },
  postCreateService(args) {
    return Request.callAPI('post', `${API_URL}/Service`, { ...args });
  },
  putModifyService(args) {
    return Request.callAPI('put', `${API_URL}/Service/${args.id}`, { ...args.data });
  },
  deleteService(args) {
    return Request.callAPI('delete', `${API_URL}/Service/${args.id}`);
  }
};

export default serviceAPI;

