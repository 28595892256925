import { API_URL } from '../../configs/api.config';
import Request from '../middleware/request';

const linkAPI = {
  /**
   * 
   */
  postDeactiveLink(args) {
    return Request.callAPI('post', `${API_URL}/Link/Deactive`, { ...args });
  },
  postInfoLink(args) {
    return Request.callAPI('post', `${API_URL}/Link/Info`, { ...args });
  }
  
};

export default linkAPI;

