import React from 'react';
import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from 'classnames';
import { bindActionCreators } from 'redux';
import Select2 from 'react-select2-wrapper';
import { DelayInput } from 'react-delay-input';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faTrashAlt, faUpload } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import PagingContainer from '../../ultils/paging.container';
import { PAGE_LIMIT } from '../../../configs/constants';
import UploadFileContainer from '../../ultils/uploadFile.container';

import {
  paging, formatDate2, formatDateFilter, fileUpload,
  formatDateRange, localStoreFilter, localStorePageNum,
  buildListCountryArrival
} from '../../../helpers';

class SearchInvoice extends React.Component {
  constructor(props) {
    super(props);
    const orderFilter = localStoreFilter('searchInvoice', 'getOrders');
    this.state = {
      display: false,
      cheat: '',
      pageNum: localStorePageNum('searchInvoice', 'getOrders'),

      states: [],
      modifyState: {},
      modifyStates: [],
      listParentIdentify: [],
      tagSuggestions: [],
      checkbox: {},
      listService: [],
      serviceDefaultValue: 'all',
      dateRangeTitle: 'Ngày dự kiến có KQ',
      searching: orderFilter || {
        filters: {
          profile: {
            fullName: ''
          },
          id: '',
          serviceIdentifyCode: ''
        },
        paging: {
          start: 0,
          limit: PAGE_LIMIT
        }
      },
      showEditBox: false,
      showDifficultCountryBox: false,
      selectedDifficultCountryFile: [],
      selectedDifficultCountryOrder: {}
    };
    this.onTreeDropdownChange = this.onTreeDropdownChange.bind(this);
    this.handleDatePicker = this.handleDatePicker.bind(this);
    this.renderOrder = this.renderOrder.bind(this);
    this.onSearchChangeHandle = this.onSearchChangeHandle.bind(this);
    this.callbackOrder = this.callbackOrder.bind(this);
    this.setDifficultCountryFile = this.setDifficultCountryFile.bind(this);
    this.handleProfileFile = this.handleProfileFile.bind(this);
    this.checkFinishOrder = this.checkFinishOrder.bind(this);
  }

  checkFinishOrder(data) {
    if (_.isEmpty(data) === true) {
      return true;
    }
    const defaultState = _.get(data, 'state', false);
    if (defaultState.isFinish === true) {
      return true;
    }
    return false;
  }

  handleExpireInvoice(serviceIdentifyCode, invoiceId) {
    try {
      const args = {
        serviceIdentifyCode,
        invoiceId: [invoiceId],
        state: 'CANCEL'
      };
      this.props.onSubmitExpireInvoice(args);
    } catch (error) {
      // 
    }
  }

  handleProfileFile = async (e) => {
    try {
      e.preventDefault();

      const { selectedDifficultCountryOrder, selectedDifficultCountryFile } = this.state;
      const file = await fileUpload(selectedDifficultCountryFile, this.props);
      if (file.code === 1) {
        const profile = _.clone(selectedDifficultCountryOrder.profile);
        const files = _.clone(profile.files);
        const args = {
          serviceIdentifyCode: selectedDifficultCountryOrder.serviceIdentifyCode,
          invoiceId: [selectedDifficultCountryOrder.id],
          state: selectedDifficultCountryOrder.state,
          profile: {
            files: _.union(file.data, files)
          }

        };
        this.props.onSubmitProfileFile(args);

        this.setState({
          selectedDifficultCountryOrder: {},
          selectedDifficultCountryFile: [],
          showDifficultCountryBox: false
        });
      }

      // this.props.onSubmitExpireInvoice(args);
    } catch (error) {
      // 
    }
  }

  setDifficultCountryFile(selectedFile) {
    try {
      if (_.isEmpty(selectedFile) === false) {
        const selectedDifficultCountryFile = selectedFile;
        this.setState({ selectedDifficultCountryFile });
      }
    } catch (error) {
      console.log(error);
    }
  }

  callbackOrder() {
    try {
      const { orders, socketOrder } = this.props;
      if (_.isEmpty(socketOrder) === false) {
        const index = _.findIndex(orders, { id: socketOrder.id });
        if (index >= 0) {
          orders[index] = socketOrder;
        } else {
          orders.unshift(socketOrder);
        }
        this.props.dispatch({
          type: 'POST_SEARCH_ORDER_SUCCESS',
          payload: orders
        });

        this.toggleRow();
      }
    } catch (error) {
      console.log(error);
    }
  }

  onChangePage(key) {
    const searching = Object.assign({}, this.state.searching);
    let pageNum = this.state.pageNum;
    switch (key) {
      case 'next': {
        //
        pageNum += 1;
        break;
      }
      default: {
        pageNum -= 1;
        if (pageNum <= 1) {
          pageNum = 1;
        }
        break;
      }
    }
    searching.paging = paging(pageNum, PAGE_LIMIT);
    this.props.getOrders(searching);
    this.setState({ pageNum });
  }

  onTreeDropdownChange(currentNode, selectedNodes) {
    const searching = Object.assign({}, this.state.searching);
    const result = {};
    const states = selectedNodes[0].states;
    searching.filters.serviceIdentifyCode = selectedNodes[0].value;

    if (_.isEmpty(states) === false) {
      const listState = [];
      if (_.isEmpty(states) === false) {
        states.forEach((element) => {
          listState.push({
            label: element,
            value: element
          });
        });
        result.listState = listState;
        result.isDisable = false;
      }
    }
    result.searching = searching;
    this.props.getOrders(searching);
    result.listParentIdentify = this.props.listServiceHandleData(selectedNodes[0].value);
    this.setState(result);
  }

  handleDatePicker(e, picker) {
    const searching = Object.assign({}, this.state.searching);
    const dateRangeTitle = formatDateFilter(picker.startDate, picker.endDate);
    const date = formatDateRange(picker.startDate, picker.endDate);
    this.setState({ searching, dateRangeTitle, pageNum: 1 });
    searching.filters.estimatedAt.from = date.startDate;
    searching.filters.estimatedAt.to = date.endDate;
    searching.paging = paging(this.state.pageNum, PAGE_LIMIT);
    this.props.getOrders(searching);
  }

  onSearchChangeHandle(key, e) {
    try {
      const searching = Object.assign({}, this.state.searching);
      const value = _.get(e, 'target.value', _.get(e, 'value', false));
      const result = {
        checkbox: {},
        pageNum: 1
      };
      switch (key) {
        case 'id': {
          searching.filters.id = value ? value.split(',') : [];
          break;
        }
        case 'fullName': {
          searching.filters = {
            profile: {
              fullName: ''
            }
          };
          if (value.length >= 3) {
            searching.filters.profile.fullName = value;
          }

          break;
        }
        case 'serviceIdentifyCode': {
          searching.filters.serviceIdentifyCode = '';
          if (value !== 'all') {
            searching.filters.serviceIdentifyCode = value;
          }
          break;
        }
        default: {
          searching.filters[key] = _.get(e, 'target.value', _.get(e, 'value', ''));
          break;
        }
      }
      searching.paging = paging(result.pageNum, PAGE_LIMIT);
      this.props.getOrders(searching);
      result.searching = searching;
      this.setState(result);
    } catch (error) {
      // console.log(error);
    }
  }

  renderAgencyTitle(agency) {
    if (!agency) {
      return (<span></span>);
    }
    const title = agency.group === 'agency' ? `Đại lý ${agency.title}` : 'Khách lẻ';
    return (<span>{title}</span>);
  }

  toggleRow = () => {
    this.setState(prevstate => ({
      display: !prevstate.display
    }));

    setTimeout(() => {
      this.setState(prevstate => ({
        display: !prevstate.display
      }));
    }, 3000);
  };

  renderOrder(showTool = false) {
    try {
      const { init, orders, socketOrder } = this.props;
      if (_.isEmpty(orders) === true) {
        return (<tr></tr>);
      }

      const result = [];
      let date = '';
      orders.forEach((element) => {
        const createdAt = formatDate2(element.createdAt);
        if (createdAt !== date) {
          date = createdAt;
          result.push({ createdAt });
        }
        result.push(element);
      });
      const countryArrivals = buildListCountryArrival(init);
      return (result.map((item, i) => {
        if (!item.id) {
          return (<tr key={i} className="table-active table-border-double">
            <td colSpan="11" className="font-weight-semibold">
              Đơn hàng ngày {item.createdAt}
            </td>
          </tr>);
        }

        const visaResult = _.get(item, 'profile.visa.result', []);
        const estimatedAt = formatDate2(item.estimatedAt);
        const service = this.props.getServiceDetail(item.serviceIdentifyCode);
        const subServiceName = _.get(item, 'addon.subServiceName', false);
        const defaultState = _.find(service.stateList, { value: item.state });
        const color = _.get(defaultState, 'color', '#ccc');
        const countryCode = _.get(item, 'profile.countryArrival', false);
        const countryArrival = countryCode ? _.find(countryArrivals, { value: countryCode }) : false;
        //
        const namespace = _.get(countryArrival, 'namespace', false);
        const isDifficultCountry = namespace === 'extremelyDifficult';
        const isDifficultCountryFile = _.get(item, 'profile.files', []).length > 0;
        const profileFiles = _.get(item, 'profile.files', []);
        const isFinish = this.checkFinishOrder(defaultState);
        //
        let profileExtraDescription = 'Hồ sơ bổ sung kèm vé máy bay khứ hồi, booking khách sạn, địa chỉ lưu trú. Vui lòng upload vào form dưới đây.';
        if (isDifficultCountry) {
          const settings = _.get(service, 'settings', null);
          const strInfor = _.get(settings, 'extremelyDifficult.info', null);
          let infor = {};
          if (strInfor) {
            infor = JSON.parse(strInfor); 
          }
          profileExtraDescription = _.get(infor, 'profileExtraDescription', profileExtraDescription);
        }
        const pathname = _.get(this.props, 'location.pathname', '');
        let link = '';
        if (_.isEmpty(visaResult) === false) {
          link = `${this.props.downloadPath}${visaResult[0]}`;
        }
        const depositAmount = _.sumBy(item.deposit, 'amount');
        let depositText = `Paid : ${depositAmount.toLocaleString()}`;
        if (depositAmount === item.amount) {
          depositText = 'Full Paid';
        }
        return (<tr key={i} className={
          socketOrder.id === item.id
            ? cx('toggler', { 'toggler--row': this.state.display })
            : ''
        }>
          <td>{item.id}</td>
          <td>
            <div className="media">
              <div className="media-body align-self-center">
                <span style={{
                  color: '#2196f3',
                  textDecoration: 'none',
                  backgroundColor: 'transparent'
                }}>
                  {_.get(item, 'profile.fullName', '')}
                </span>
                <div style={{
                  fontSize: 11,
                  textDecoration: 'none',
                  backgroundColor: 'transparent'
                }}>
                  {_.get(service, 'title', '')} {subServiceName ? `- ${subServiceName}` : ''}
                </div>
                <div className="text-muted font-size-sm">
                  <span style={{
                    color,
                    backgroundColor: color
                  }} className="badge badge-mark mr-1"></span>
                  {_.get(defaultState, 'label', '')}
                  {_.isEmpty(visaResult) === false
                    ? <a target="_blank" href={link}>
                      <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faPaperclip} />
                    </a>

                    : ''
                  }

                </div>

              </div>
            </div>
          </td>
          <td>{estimatedAt}</td>
          <td style={{ textAlign: 'right' }}>
            <span style={{ fontWeight: 'bold' }}>
              {item.amount.toLocaleString()}</span>
            <br />
            <div className="text-muted font-size-sm">
              {depositText}
            </div>

          </td>
          <td className="text-center">
            {(
              isDifficultCountry === true
              // && isDifficultCountryFile === false
              && isFinish === false
              && pathname === '/order-process'
            )
              ? <a
                onClick={() => {
                  this.setState({
                    selectedDifficultCountryOrder: item,
                    showDifficultCountryBox: true,
                    profileExtraDescription,
                    profileFiles
                  });
                }}
                value="redirect"
                className="btn btn-sm "
                style={{
                  color: 'brown',
                  fontSize: 14,
                  backgroundColor: 'white'
                }}
                title={profileExtraDescription}
              >
                <FontAwesomeIcon icon={faUpload} /> Bổ sung hồ sơ
              </a> : ''}

          </td>
          {showTool ? <td className="text-center">
            {item.state === 'PENDING' && item.type === 'ARRIVAL'
              ? <button
                title="Hủy đơn hàng - hoàn tiền"
                onClick={this.handleExpireInvoice.bind(this, item.serviceIdentifyCode, item.id)}
                className="btn btn-default">
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
              : ''}

          </td> : ''}

        </tr>);
      }));
    } catch (error) {
      console.log(error);
      return (<tr></tr>);
    }
  }

  renderUploadDifficultCountryFile() {
    const {
      selectedDifficultCountryOrder, showDifficultCountryBox, profileExtraDescription, isDifficultCountryFile, profileFiles
    } = this.state;
    if (showDifficultCountryBox === false) {
      return (<div ></div>);
    }
    return (
      <div className="card">
        <div className="card-header bg-transparent header-elements-inline">
          <h5 className="card-title">
            <span style={{ fontWeight: 'bold' }}>
              Đơn hàng #{selectedDifficultCountryOrder.id} - {_.get(selectedDifficultCountryOrder, 'profile.fullName', '')}
            </span> <br />
            {profileExtraDescription}
          </h5>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-xl-12">
              <form onSubmit={this.handleProfileFile}>
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <UploadFileContainer
                          setFiles={this.setDifficultCountryFile.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {this.renderDownloadFile(profileFiles, 'profileFiles-')}
                <div className="text-center">

                  <button
                    title="Hồ sơ bổ sung kèm vé máy bay khứ hồi, booking khách sạn, địa chỉ lưu trú"
                    className="btn btn-success">
                    <i className="fa fa-save"></i> Lưu lại
                  </button>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDownloadFile(files, prefix = 'renderDownloadFile-') {
    try {
      const style = {
        backgroundColor: 'whitesmoke',
        paddingLeft: 20,
        marginBottom: 10,
        borderLeft: 'solid 3px darkgrey',
        height: '2.25003rem'
      };

      if (_.isEmpty(files) === true) {
        return (<div style={style} className="uniform-uploader" >
          <span className="form-text text-muted">None</span></div>);
      }
      const path = require('path');
      return (files.map((file, i) => {
        const link = `${this.props.downloadPath}${file}`;
        const fileExt = path.extname(link);
        const basename = path.basename(link);
        let fileExtClassName = '';
        switch (fileExt) {
          case '.docx':
          case '.doc':
            fileExtClassName = 'fa fa-file-word-o';
            break;

          default:
            fileExtClassName = 'fa fa-file-pdf-o';
            break;
        }
        return (
          <div className="uniform-uploader"
            key={_.uniqueId(prefix)}
            style={style}>
            <span className="form-text text-muted">
              <a target="_blank" href={link}>
                <i style={{ paddingRight: 10 }} className={fileExtClassName}></i>
                {basename}
              </a>
            </span>
          </div>
        );
      }));
    } catch (error) {
      return (<div></div>);
    }
  }

  render() {
    const {
      parentIdentifies, searching
    } = this.state;

    const filterIds = [];
    if (_.isEmpty(searching.filters.id) === false) {
      filterIds.push(searching.filters.id);
    }
    const fullName = _.get(searching, 'filters.profile.fullName', '');
    const { minimize } = this.props;
    if (minimize === true) {
      return (
        <div className="table-responsive">
          <table className="table table-bordered table-striped text-nowrap">
            <thead>
              <tr>
                <th>Mã ĐH</th>
                <th>Khách hàng</th>
                <th>Ngày dự kiến có KQ</th>
                <th>Tổng tiền đơn hàng</th>
                <th>Ghi chú</th>
                <th>Tài liệu</th>
                <th>Công cụ</th>
              </tr>
            </thead>
            <tbody>
              {this.renderOrder(true)}
            </tbody>
          </table>
        </div>
      );
    }

    return (
      <div>

        <div className="card">
          <div className="card-header bg-transparent header-elements-inline">
            <h5 className="card-title">Tiến độ dịch vụ</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="form-group col-md-4">
                <DelayInput
                  value={filterIds ? filterIds.join(',') : ''}
                  type="text"
                  className="form-control"
                  minLength={0}
                  delayTimeout={300}
                  placeholder="Mã đơn hàng"
                  onChange={this.onSearchChangeHandle.bind(this, 'id')}
                />

              </div>

              <div className="form-group col-md-4">
                <DelayInput
                  value={fullName}
                  type="text"
                  className="form-control"
                  minLength={0}
                  delayTimeout={300}
                  placeholder="Tên khách hàng"
                  onChange={this.onSearchChangeHandle.bind(this, 'fullName')}
                />

              </div>

              <div className="form-group col-md-4">
                <Select2
                  style={{ width: '100%' }}
                  onSelect={this.onSearchChangeHandle.bind(this, 'serviceIdentifyCode')}
                  className="form-control select-search select2-hidden-accessible"
                  value={searching.filters.serviceIdentifyCode}
                  data={parentIdentifies}
                  options={{
                    placeholder: 'Tất cả dịch vụ'
                  }}
                />
              </div>

            </div>
          </div>
          <div className="table-responsive" style={{ paddingBottom: 200 }}>
            <table className="table table-bordered table-striped text-nowrap">
              <thead>
                <tr>
                  <th>Mã ĐH</th>
                  <th>Khách hàng</th>
                  <th>Ngày dự kiến có KQ</th>
                  <th>Tổng tiền đơn hàng</th>
                  <th>Ghi chú</th>
                  <th>Công cụ</th>
                </tr>
              </thead>
              <tbody>
                {this.renderOrder(true)}
              </tbody>
            </table>

            <PagingContainer
              count={_.get(this.props, 'orders', []).length}
              pageNum={this.state.pageNum}
              limit={PAGE_LIMIT}
              onClick={this.onChangePage.bind(this)}
            />
          </div>
        </div>
        <Modal
          onRequestClose={() => {
            this.setState({
              showDifficultCountryBox: false,
              selectedDifficultCountryOrder: {}
            });
          }}
          style={{
            content: {
              top: '25%', height: 270, left: '20%', width: '60%', borderRadius: 8, overflowX: 'scroll'
            },
            overlay: { zIndex: 1000, backgroundColor: 'rgba(0, 0, 0, 0.75)' }
          }}
          ariaHideApp={false}
          isOpen={this.state.showDifficultCountryBox}
          contentLabel="Hồ sơ bổ sung kèm vé máy bay khứ hồi, booking khách sạn, địa chỉ lưu trú"
        >
          {this.renderUploadDifficultCountryFile()}
        </Modal>
        <NotificationContainer />
      </div>

    );
  }

  componentDidUpdate(oldProps) {
    try {
      /**
       * 
       */
      const newProps = this.props;
      if (oldProps.buildServices !== newProps.buildServices) {
        const parentIdentifies = this.props.listServiceHandleData();
        this.setState({ parentIdentifies: parentIdentifies.list });
      }
      if (oldProps.socketOrder !== newProps.socketOrder) {
        this.callbackOrder();
      }
    } catch (error) {
      // console.log(error);
      /**
       * 
       */
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      socketOrder: payload.orderReducer.socketOrder,
      orders: payload.orderReducer.orders,
      buildServices: payload.serviceReducer.buildServices,
      localStoreFilter: payload.generalReducer.localStoreFilter,
      init: payload.generalReducer.init,
      userInfo: payload.generalReducer.userInfo,
      downloadPath: payload.generalReducer.downloadPath,
      uploadPath: payload.generalReducer.uploadPath,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(SearchInvoice)
);
