import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class CreateCustomer extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <div>
        <div className="d-md-flex align-items-md-start">
          <div className="tab-content w-100 overflow-auto order-2 order-md-1">
            <div className="tab-pane fade active show" id="overview">
              <div className="card">
                <div className="card-body pb-0">
                  <div className="media flex-column flex-md-row mb-2">
                    <a href="#" className="align-self-md-center mr-md-3 mb-2 mb-md-0">
                      <img src="http://demo.interface.club/limitless/demo/bs4/Template/global_assets/images/demo/brands/microsoft.png" className="rounded" width="44" height="44" alt="" />

                    </a>
                    <div className="media-body">
                      <h5 className="media-title font-weight-semibold">Thêm Khách Hàng / CTV</h5>
                      <ul className="list-inline list-inline-dotted text-muted mb-0">
                        <li className="list-inline-item">Thuôc quản lý của: Lê Hồng Sơn</li>
                        <li className="list-inline-item">20/01/2019</li>
                      </ul>
                    </div>
                    <div className="align-self-md-center ml-md-3 mt-2 mt-md-0"><a href="#" className="btn bg-teal-400"><i className="icon-checkmark3 mr-2"></i> Lưu lại</a></div>
                  </div>
                </div>
                <form action="#">
                  <fieldset className="card-body">
                    <h6 className="font-weight-semibold mb-3">Thông tin Khách Hàng</h6>
                    <div className="row">
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label>Số điện thoại: <span className="text-danger">*</span></label>
                          <input type="text" name="phone" className="form-control" id="phone" required="" placeholder="Nhập phone" />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label>Họ &amp; Tên:</label>
                          <input type="text" className="form-control" placeholder="Nhập đầy đủ họ tên" />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <label>Phân Loại KH</label>
                        <select className="form-control ">
                          <option>Khách mới</option>
                          <option>Đã Gọi</option>
                          <option value="AZ">Tiềm Năng</option>
                          <option value="AZ">Đã Làm Dịch Vụ</option>
                          <option value="AZ">Khách hàng miền bắc</option>
                          <option value="AZ">Đại Lý</option>
                        </select>
                      </div>
                      <div className="col-md-3">
                        <label>Nguồn KH</label>
                        <select className="form-control ">
                          <option>Khách tự gọi đến</option>
                          <option value="AZ">Zalo</option>
                          <option value="AZ">Facebook</option>
                          <option value="AZ">Cộng tác viên</option>
                          <option value="AZ">Nguồn khác</option>
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>Yêu cầu dịch vụ</label>
                          <select multiple="" data-placeholder="Chọn nhân viên hỗ trợ" className="form-control select-access-multiple-clear select2-hidden-accessible" data-fouc="true" data-select2-id="7" tabIndex="-1" aria-hidden="true">
                            <option value="AK">Chứng minh công việc</option>
                            <option value="CA">Giấy phép lao động</option>
                            <option value="AZ">Thẻ Tạm Trú</option>
                            <option value="CO">Gia Hạn visa</option>
                            <option value="ID">Tour hàn quốc</option>
                            <option value="WY">Tour đài loan</option>
                            <option value="CT">Vé Máy bay</option>
                          </select>
                          <span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id="8" style={{ width: '241.406px' }}>
                            <span className="selection">
                              <span className="select2-selection select2-selection--multiple" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex="-1">
                                <ul className="select2-selection__rendered">
                                  <li className="select2-search select2-search--inline">
                                    <input className="select2-search__field" type="search" tabIndex="0" autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false" role="textbox" aria-autocomplete="list" placeholder="" style={{ width: '0.75em' }} />
                                  </li>
                                </ul>
                              </span>
                            </span>
                            <span className="dropdown-wrapper" aria-hidden="true"></span>
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>Khu vực</label>
                          <select className="form-control select-search select2-hidden-accessible" data-fouc="true" data-select2-id="4" tabIndex="-1" aria-hidden="true">
                            <option value="AZ" data-select2-id="6">Tất cả khu vực</option>
                            <optgroup label="Miền Nam">
                              <option value="AZ">Hồ Chí Minh</option>
                              <option value="CO">Cần Thơ</option>
                              <option value="ID">Tây Ninh</option>
                              <option value="WY">Bạc Liêu</option>
                            </optgroup>
                            <optgroup label="Miền Bắc">
                              <option value="AL">Hà Nội</option>
                              <option value="IA">Quảng Bình</option>
                              <option value="KS">Hà Tĩnh</option>
                              <option value="KY">Thanh Hoá</option>
                            </optgroup>
                          </select>
                          <span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id="5" style={{ width: '241.406px' }}>
                            <span className="selection">
                              <span className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex="0" aria-labelledby="select2-tdx9-container">
                                <span className="select2-selection__rendered" id="select2-tdx9-container" role="textbox" aria-readonly="true" title="Tất cả khu vực">Tất cả khu vực</span>
                                <span className="select2-selection__arrow" role="presentation">
                                  <b role="presentation"></b>
                                </span>
                              </span>
                            </span>
                            <span className="dropdown-wrapper" aria-hidden="true"></span></span>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>Ghi chú</label>
                          <textarea type="text" name="note" className="form-control" id="note" required="" placeholder="Nhập note"></textarea>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="card-body">
                    <button type="submit" className="btn bg-blue">Lưu lại <i className="icon-paperplane ml-2"></i></button>
                    <button type="submit" className="btn btn-light ml-3">Cancel</button>
                  </fieldset>
                </form>
              </div>
            </div>
            <div className="tab-pane fade" id="contract">
              <div className="card">
                <div className="card-header header-elements-inline">
                  <h5 className="card-title">Quản lý hợp đồng và các quyết định khen thưởng</h5>
                  <div className="header-elements">
                    <div className="list-icons">
                      <a className="list-icons-item" data-action="collapse"></a>
                      <a className="list-icons-item" data-action="reload"></a>
                      <a className="list-icons-item" data-action="remove"></a>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <form action="#">
                            
                    <div className="text-right">
                      <button type="submit" className="btn btn-primary">Save changes</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    );
  }

  componentDidMount() {
    try {
      /**
       * 
       */
    } catch (error) {
      /**
       * 
       */
    }
  }

  componentWillUnmount() {
    try {
      /**
       * 
       */
    } catch (error) {
      /**
       * 
       */
    }
  }

  componentDidUpdate() {
    try {
      /**
       * 
       */
    } catch (error) {
      /**
       * 
       */
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      userInfo: payload.generalReducer.userInfo,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(CreateCustomer)
);
