
/**
 * CRUD
 */

export const POST_ORDER_TAG_SEARCH = 'POST_ORDER_TAG_SEARCH';
export const POST_ORDER_TAG_SEARCH_SUCCESS = 'POST_ORDER_TAG_SEARCH_SUCCESS';
export const POST_ORDER_TAG_SEARCH_FAIL = 'POST_ORDER_TAG_SEARCH_FAIL';

export const POST_SEARCH_SIMPLE_ORDER = 'POST_SEARCH_SIMPLE_ORDER';
export const POST_SEARCH_SIMPLE_ORDER_SUCCESS = 'POST_SEARCH_SIMPLE_ORDER_SUCCESS';
export const POST_SEARCH_SIMPLE_ORDER_FAIL = 'POST_SEARCH_SIMPLE_ORDER_FAIL';

export const POST_SEARCH_ORDER = 'POST_SEARCH_ORDER';
export const POST_SEARCH_ORDER_SUCCESS = 'POST_SEARCH_ORDER_SUCCESS';
export const POST_SEARCH_ORDER_FAIL = 'POST_SEARCH_ORDER_FAIL';

export const POST_ORDER_DETAIL = 'POST_ORDER_DETAIL';
export const POST_ORDER_DETAIL_SUCCESS = 'POST_ORDER_DETAIL_SUCCESS';
export const POST_ORDER_DETAIL_FAIL = 'POST_ORDER_DETAIL_FAIL';

export const SOCKET_ORDER = 'SOCKET_ORDER';

export const POST_ORDER = 'POST_ORDER';
export const POST_ORDER_SUCCESS = 'POST_ORDER_SUCCESS';
export const POST_ORDER_FAIL = 'POST_ORDER_FAIL';

export const PUT_ORDER = 'PUT_ORDER';
export const PUT_ORDER_SUCCESS = 'PUT_ORDER_SUCCESS';
export const PUT_ORDER_FAIL = 'PUT_ORDER_FAIL';

export const PUT_ORDER_STATE = 'PUT_ORDER_STATE';
export const PUT_ORDER_STATE_SUCCESS = 'PUT_ORDER_STATE_SUCCESS';
export const PUT_ORDER_STATE_FAIL = 'PUT_ORDER_STATE_FAIL';

export const PUT_ORDER_PROCESS = 'PUT_ORDER_PROCESS';
export const PUT_ORDER_PROCESS_SUCCESS = 'PUT_ORDER_PROCESS_SUCCESS';
export const PUT_ORDER_PROCESS_FAIL = 'PUT_ORDER_PROCESS_FAIL';

export const PUT_ORDER_PIN = 'PUT_ORDER_PIN';
export const PUT_ORDER_PIN_SUCCESS = 'PUT_ORDER_PIN_SUCCESS';
export const PUT_ORDER_PIN_FAIL = 'PUT_ORDER_PIN_FAIL';

export const DELETE_ORDER = 'DELETE_ORDER';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAIL = 'DELETE_ORDER_FAIL';
