import React from 'react';
import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import Select from 'react-select';
import validator from 'validator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DelayInput } from 'react-delay-input';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import ReactAudioPlayer from 'react-audio-player';
import PagingContainer from '../../ultils/paging.container';
import { PAGE_LIMIT, PAGE_MAX_LIMIT } from '../../../configs/constants';
import store from '../../../configs/store.config';

import {
  paging,
  formatHourMinute,
  formatDateFilter,
  formatDateRange,
  formatDate2,
  localStoreFilter,
  localStorePageNum,
  formatDateFilter2
} from '../../../helpers';

class SearchCoboPobo extends React.Component {
  constructor(props) {
    super(props);

    const callLogsFilter = localStoreFilter('searchCoboPobo', 'getSearchCoboPobo');
    const createdAt = _.get(callLogsFilter, 'filters.createdAt');
    const dateRangeTitle = formatDateFilter2(_.get(createdAt, 'from', false), _.get(createdAt, 'to', false), 'Chọn thời gian');
    this.state = {
      pageNum: localStorePageNum('searchCoboPobo', 'getCoboPobos'),
      typeDefaultValue: { label: 'Tất cả', value: 'all' },
      types: [
        { label: 'Tất cả', value: 'all' },
        { label: 'Ghi có', value: '+' },
        { label: 'Ghi nợ', value: '-' }
      ],
      dateRangeTitle,
      ranges: {
        'Clear date': [1, 1],
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      },
      searching: callLogsFilter || {
        filters: {
          // createdById: 604
        },

        paging: {
          start: 0,
          limit: PAGE_LIMIT
        }
      }
    };
    
    this.onSearchChangeHandle = this.onSearchChangeHandle.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.handleDatePicker = this.handleDatePicker.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
  }

  handleOpenModal(item, index, e) {
    e.preventDefault();
    const { showCallLogModal } = this.props;
    this.props.callLogDetail({
      filters: { id: [item.id] }
    });

    /**
         * Get History
         */
    const searchingSimpleCallLog = _.clone(this.state.searchingSimpleCallLog);
    const searchingOrder = _.clone(this.state.searchingOrder);

    searchingSimpleCallLog.filters.callerPhone = item.callerPhone;
    searchingOrder.filters.customer.phone = item.callerPhone;
    this.props.getSimpleCallLogs(searchingSimpleCallLog);
    this.props.getSimpleOrders(searchingOrder);

    // nvb edit
    // if (showCallLogModal === true) {
    //   store.dispatch({
    //     type: 'PUT_CALL_LOGS_MODAL',
    //     payload: false
    //   });
    // }

    // setTimeout(() => {
    //   store.dispatch({
    //     type: 'PUT_CALL_LOGS_MODAL',
    //     payload: true
    //   });
    // }, 100);
    store.dispatch({
      type: 'PUT_CALL_LOGS_MODAL',
      payload: true
    });
  }

  exportFile(e) {
    e.preventDefault();
    const searching = Object.assign({}, this.state.searching);
    this.props.exportFile(searching);
  }

  handleDatePicker(e, picker) {
    const searching = Object.assign({}, this.state.searching);
    searching.filters.issuedAt = {
      from: '',
      to: ''
    };
    if (picker.startDate.unix() > 0) {
      const pageNum = 1;
      const dateRangeTitle = formatDateFilter(picker.startDate, picker.endDate);
      const date = formatDateRange(picker.startDate, picker.endDate);
      this.setState({ searching, dateRangeTitle, pageNum: 1 });
      searching.filters.issuedAt.from = date.startDate;
      searching.filters.issuedAt.to = date.endDate;
      searching.paging = paging(this.state.pageNum, PAGE_LIMIT);
      this.props.getCoboPobos(searching);
    } else {
      this.setState({ searching, dateRangeTitle: 'Chọn thời gian', pageNum: 1 });
      this.props.getCoboPobos(searching);
    }
  }

  onSearchChangeHandle(key, e) {
    try {
      const searching = Object.assign({}, this.state.searching);
      const value = _.get(e, 'target.value', _.get(e, 'value', false));
      const result = {
        checkbox: {},
        pageNum: 1,
        createdById: ''
      };
      switch (key) {
        case 'title': {
          searching.filters.title = '';
          if (value !== 'all') {
            searching.filters.title = _.get(e, 'target.value', _.get(e, 'value', false));
          }
          break;
        }
        case 'createdById': {
          searching.filters.createdById = _.get(e, 'target.value', _.get(e, 'value', false));
          result.createdById = _.find(this.props.listMembers, { value: searching.filters.createdById });
          break;
        }
        case 'type':
        {
          searching.filters.method = '';
          if (value !== 'all') {
            searching.filters.method = _.get(e, 'target.value', _.get(e, 'value', false));
          }
          result.typeDefaultValue = e;

          break;
        }
        default: {
          searching.filters[key] = _.get(e, 'target.value', _.get(e, 'value', ''));
          break;
        }
      }
      searching.paging = paging(result.pageNum, PAGE_LIMIT);
      this.props.getCoboPobos(searching);
      result.searching = searching;
      this.setState(result);
    } catch (error) {
      // console.log(error);
    }
  }

  onChangePage(key) {
    const searching = Object.assign({}, this.state.searching);
    let pageNum = this.state.pageNum;
    switch (key) {
      case 'next': {
        //
        pageNum += 1;
        break;
      }
      default: {
        pageNum -= 1;
        if (pageNum <= 1) {
          pageNum = 1;
        }
        break;
      }
    }
    searching.paging = paging(pageNum, PAGE_LIMIT);
    this.props.getCoboPobos(searching);
    this.setState({ pageNum });
  }

  clickToCall(phone) {
    const { init } = this.props;
    const extension = _.get(init, 'accountInfo.voip.extension', false);
    this.props.clickToCall({
      extension,
      phone
    });
  }

  renderDownloadFile(files) {
    try {
      if (_.isEmpty(files) === true) {
        return (<div></div>);
      }
      const path = require('path');
      return (files.map((file, i) => {
        const link = `${this.props.downloadPath}${file}`;
        const fileExt = path.extname(link);
        const basename = path.basename(link);
        let fileExtClassName = '';
        switch (fileExt) {
          case '.docx':
          case '.doc':
            fileExtClassName = 'fa fa-file-word-o';
            break;

          default:
            fileExtClassName = 'fa fa-file-pdf-o';
            break;
        }
        return (

          <span key={`renderDownloadFile_${i}`} style= {{ paddingLeft: 3 }} >
            <a target="_blank" href={link} title={basename}>
              <i style={{ paddingRight: 10 }} className={fileExtClassName}></i>
                
            </a>
          </span>
        );
      }));
    } catch (error) {
      return (<div></div>);
    }
  }
  
  renderCoboPobos() {
    try {
      const { coboPobos, init } = this.props;

      if (_.isEmpty(coboPobos) === true) {
        return (<tr></tr>);
      }
      
      const coboTypes = _.get(init, 'coboPobo.type.cobo', []);
      const poboTypes = _.get(init, 'coboPobo.type.pobo', []);

      const extension = _.get(init, 'accountInfo.voip.extension', false);
      const result = [];
      let date = '';
      coboPobos.forEach((element) => {
        const createdAt = formatDate2(element.createdAt);
        if (createdAt !== date) {
          date = createdAt;
          result.push({ createdAt });
        }
       
        result.push(element);
      });
      const bankAccount = _.get(init, 'bankAccount', []);
      return (result.map((item, i) => {
        if (!item.id) {
          return (<tr key={i} className="table-active table-border-double">
            <td colSpan="11" className="font-weight-semibold">
              Ngày {item.createdAt}
            </td>
          </tr>);
        }
        return (<tr key={`renderCallLog_${i}`}>
          <td>{item.id}</td>
          <td>{item.accountName ? item.accountName : 'Khách hàng'}</td>
          <td style={{ textAlign: 'right' }}>
            <span style={{ fontWeight: 'bold', color: (item.change === '+') ? '#da2c4c' : '#50ad8d' }}>{(item.change === '-') ? '-' : ''}{item.amount.toLocaleString()}</span>
            {_.get(item, 'referData.bankAccount', '') === '' 
              ? <div className="font-size-sm" style={{ color: '#808678' }}>
                Ví
              </div>
              : <div className="font-size-sm" style={{ color: _.get(_.find(bankAccount, { key: _.get(item, 'referData.bankAccount', '') }), 'color', '#000000') }}>
                {_.get(_.find(bankAccount, { key: _.get(item, 'referData.bankAccount', '') }), 'shortText', 'Ví')}
              </div>
            }
          </td>
          <td style={{ textAlign: 'right' }}>
            <span style={{ fontWeight: 'bold', color: (item.change === '+') ? '#da2c4c' : '#da2c4c' }}>{(item.balance.after.creditLimit - item.balance.after.credit + item.balance.after.cash).toLocaleString()}</span>
            <div className="font-size-sm" >{(item.balance.before.creditLimit - item.balance.before.credit + item.balance.before.cash).toLocaleString()}</div>
          </td>
          <td style={{ textAlign: 'left' }}><div dangerouslySetInnerHTML={{ __html: item.description }} />

          </td>
          
        </tr>);
      }));
    } catch (error) {
      console.log(error);
      return (<tr></tr>);
    }
  }

  downloadFile(e) {
    e.preventDefault();
    window.open(this.props.downloadUrl, '_blank').focus();
  }

  render() {
    const {
      types, searching, ranges, dateRangeTitle, typeDefaultValue, states, createdById
    } = this.state;
    const { downloadUrl } = this.props;
    const phone = _.get(searching, 'filters.callerName', '') || _.get(searching, 'filters.callerPhone', '');
    const state = _.find(states, { value: _.get(searching, 'filters.state', 'all') }) || typeDefaultValue;
    let createdBy = { value: 'all', label: 'Lọc theo nhân viên' };
    if (_.isEmpty(searching.filters) === false) {
      createdBy = _.find(this.props.listMembers(), { value: searching.filters.createdById }) || { value: '', label: 'Lọc theo nhân viên' };
    }
    return (
      <div>
        {downloadUrl !== ''
          ? <div className="text-center" style={{ paddingTop: 20, paddingBottom: 20 }}>
            <button onClick={this.downloadFile} className="btn btn-warning">BẤM VÀO ĐÂY ĐỂ DOWNLOAD</button>
          </div>
          : ''}
        <div className="card">
          <div className="card-header bg-transparent header-elements-inline">
            <h5 className="card-title">Lịch sử ví hệ thống</h5>
            <div>
              <Link
                style={{ float: 'right' }}
                className="btn bg-teal-400"
                onClick={this.exportFile.bind(this)}
                to='#' >
                <i className="icon-add"></i> Xuất file
              </Link>
              <Link
                style={{ float: 'right', marginRight: 5 }}
                className="btn bg-teal-400"
                to="/cobo-pobo/pobo-create" >
                <i className="icon-add"></i> Tạo phiếu chi
              </Link>
              <Link
                style={{ float: 'right', marginRight: 5 }}
                className="btn bg-teal-400"
                to="/cobo-pobo/cobo-create" >
                <i className="icon-add"></i> Tạo phiếu thu
              </Link>
            </div>

          </div>
          <div className="card-body">
            <div className="row">
              <div className="form-group col-md-4">
                <DelayInput
                  value={searching.filters.title}
                  type="text"
                  className="form-control"
                  minLength={0}
                  delayTimeout={300}
                  placeholder="Nhập mô tả cần tìm"
                  onChange={this.onSearchChangeHandle.bind(this, 'title')}
                />
              </div>
              <div className="form-group col-md-2">
                <Select
                  value={state}
                  onChange={this.onSearchChangeHandle.bind(this, 'type')}
                  options={types || []}
                />
              </div>
              <div className="form-group col-md-3">
                <Select
                  value={createdBy}
                  onChange={this.onSearchChangeHandle.bind(this, 'createdById')}
                  options={this.props.listMembers()}
                  placeholder='Lọc theo nhân viên'
                />
              </div>
              <div className="form-group col-md-2">
                <DateRangePicker
                  className="btn btn-light daterange-predefined"
                  onApply={this.handleDatePicker}
                  ranges={ranges}
                >
                  <button style={{ padding: '7px 15px' }}>
                    <i className="icon-calendar22 mr-2"></i>
                    <span> {dateRangeTitle} </span>
                  </button>
                </DateRangePicker>

              </div>

            </div>
          </div>

          <div className="table-responsive" style={{ paddingBottom: 200 }}>
            <table className="table table-bordered table-striped text-nowrap">
              <thead>
                <tr>
                  <th style={{ textAlign: 'center' }}>ID</th>
                  <th style={{ textAlign: 'center' }}>Tài khoản</th>
                  <th style={{ textAlign: 'center' }}>Số tiền</th>
                  <th style={{ textAlign: 'center' }}>Số dư</th>
                  <th style={{ textAlign: 'center' }}>Mô tả</th>
                </tr>
              </thead>
              <tbody>
                {this.renderCoboPobos()}
              </tbody>
            </table>

            <PagingContainer
              count={_.get(this.props, 'coboPobos', []).length}
              pageNum={this.state.pageNum}
              limit={PAGE_LIMIT}
              onClick={this.onChangePage.bind(this)}
            />
          </div>
        </div>
        
      </div>

    );
  }
  
  componentDidUpdate(oldProps) {
    try {
      /**
       * 
       */
      // 
    } catch (error) {
      // console.log(error);
      /**
       * 
       */
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      coboPobos: payload.coboPoboReducer.coboPobos,
      localStoreFilter: payload.generalReducer.localStoreFilter,
      pickupCallLogs: payload.callLogReducer.pickupCallLogs,
      simpleOrders: payload.orderReducer.simpleOrders,
      showCallLogModal: payload.callLogReducer.showCallLogModal,
      buildServices: payload.serviceReducer.buildServices,
      init: payload.generalReducer.init,
      userInfo: payload.generalReducer.userInfo,
      downloadPath: payload.generalReducer.downloadPath,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(SearchCoboPobo)
);
