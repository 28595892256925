import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import SearchCustomer from './searchCustomer';
import store from '../../../configs/store.config';
import {
  localStoreFilter
} from '../../../helpers';

class SearchCustomerContainer extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = {};
  }

  render() {
    return (
      <div>
        <SearchCustomer
          errorMessage={this.state.errorMessage}
          userInfo={this.userInfo}
        />
          
      </div>
        
    );
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    //
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    localStoreFilter: rootState.generalReducer.localStoreFilter,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(SearchCustomerContainer);
