import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import SearchWalletLiabilityDetail from './searchWalletLiabilityDetail';
import * as WalletActions from '../../../redux/wallets/wallets.actions';
import * as AccountActions from '../../../redux/accounts/accounts.actions';
import * as OrderActions from '../../../redux/orders/orders.actions';
import { checkScope } from '../../../helpers';

class SearchWalletLiabilityContainer extends React.Component {
  constructor(prop) {
    super(prop);

    this.state = {
      types: [
        {
          value: 'balance', 
          label: 'Thay đổi hạn mức tín dụng', 
          display: [true, false, false], 
          scope: 'wallet.setCreditLimit' 
        },
        {
          value: 'deposit', 
          label: 'Nạp tiền', 
          display: [false, true, true], 
          scope: 'wallet.deposit' 
        },
        {
          value: 'withdraw', 
          label: 'Rút tiền', 
          display: [false, true, true], 
          scope: 'wallet.withdraw' 
        }
      ]
    };
    this.getOrders = this.getOrders.bind(this);
    this.getWallet = this.getWallet.bind(this);
    this.getHistory = this.getHistory.bind(this);
    this.callbackWallet = this.callbackWallet.bind(this);
    this.getTypes = this.getTypes.bind(this);
    this.getAccount = this.getAccount.bind(this);
  }

  getOrders(args) {
    try {
      const { orderAct } = this.props;
      const query = _.clone(args);
      orderAct.searchOrder(query);
    } catch (error) {
      console.log(error);
      // console.log('error', error);
    }
  }

  /**
   * 
   */

  getWallet() {
    try {
      const { walletAct, init } = this.props;
      const accountId = init.accountInfo.id;
      walletAct.detailWallet({
        filters: {
          accountId: [accountId]
        }
      }, this.callbackWallet);
    } catch (error) {
      console.log('error', error);
    }
  }

  callbackWallet(status, data) {
    try {
      if (status === false || _.isEmpty(data) === true) {
        NotificationManager.error('Thông tin không hợp lệ', '', 1000);
        setTimeout(() => {
          this.props.history.go(-1);
        }, 1500);
      } else {
        this.getAccount();
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * 
   */
  getHistory(args) {
    try {
      const { walletAct, init } = this.props;
      const accountId = init.accountInfo.id;
      walletAct.searchWalletHistory({
        id: accountId,
        data: args
      });
    } catch (error) {
      console.log('error', error);
    }
  }

  /**
   * 
   */
  getAccount() {
    try {
      const { accountAct, init } = this.props;
      const accountId = init.accountInfo.id;
      accountAct.detailAccount({
        filters: {
          id: accountId
        }
      });
    } catch (error) {
      console.log('error', error);
    }
  }

  /**
   * Get types
   */

  getTypes() {
    const data = Object.assign([], this.state.types);
    const result = [];
    if (_.isEmpty(data) === false) {
      _.forEach(data, (item) => {
        const hasPermission = checkScope(item.scope, this.props);
        if (hasPermission === true) {
          delete item.scope;
          result.push({ ...item });
        }
      });
    }
    this.setState({ types: result });
  }
  /**
   * 
   */

  render() {
    return (
      <div>
        <SearchWalletLiabilityDetail
          types={this.state.types}
          getHistory={this.getHistory}
          getAccount={this.getAccount}
          socketIo={this.props.socket}
          getOrders={this.getOrders}
        />
        <NotificationContainer />
      </div>
        
    );
  }

  componentWillMount() {
    try {
      this.getWallet();
      this.getTypes();
    } catch (error) {
      // console.log(error);
    }
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    accountAct: bindActionCreators(AccountActions, dispatch),
    orderAct: bindActionCreators(OrderActions, dispatch),
    walletAct: bindActionCreators(WalletActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    lang: rootState.i18nState.lang,
    init: rootState.generalReducer.init,
    orders: rootState.orderReducer.orders,
    listScope: rootState.generalReducer.listScope
  };
}, mapsDispatchToProp)(SearchWalletLiabilityContainer);
