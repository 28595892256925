import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import SearchAgency from './searchAgency';
import * as AgenciesActions from '../../../redux/agencies/agencies.actions';
import * as AccountActions from '../../../redux/accounts/accounts.actions';
import store from '../../../configs/store.config';
import { PAGE_LIMIT, AGENCY_MANAGE_SCOPE } from '../../../configs/constants';

import {
  buildListLocation, localStoreFilter, checkScope
} from '../../../helpers';

class SearchAgencyContainer extends React.Component {
  constructor(prop) {
    super(prop);

    if (checkScope(AGENCY_MANAGE_SCOPE, this.props) === false) {
      NotificationManager.error('Chức năng không tồn tại', '', 1500);
      setTimeout(() => {
        this.props.history.push('/dashboard');
      }, 2000);
    }

    this.state = {
      listLocation: [],
      listAgency: []
    };
    this.buildListManager = this.buildListManager.bind(this);
    this.buildLocation = this.buildLocation.bind(this);
    this.getAgencies = this.getAgencies.bind(this);
    this.getMembers = this.getMembers.bind(this);
  }

  /**
   * 
   */
  getMembers(args) {
    try {
      const { accountAct } = this.props;
      accountAct.searchAccount(args);
    } catch (error) {
      // console.log('error', error);
    }
  }

  /**
    * 
    */
  getAgencies(args) {
    try {
      const filters = Object.assign({}, args.filters);
      const query = Object.assign({}, args);
      Object.keys(filters).forEach((key) => {
        if (_.isObject(filters[key]) === true) {
          Object.keys(filters[key]).forEach((key2) => {
            if (_.isEmpty(filters[key][key2]) === true) {
              delete filters[key][key2];
            }
          });
        } else if (_.isEmpty(filters[key]) === true) {
          delete filters[key];
        }
        if (_.isEmpty(filters[key]) === true) {
          delete filters[key];
        }
      });
      const { agenciesAct } = this.props;
      query.filters = filters;
      agenciesAct.searchAgency(query);

      store.dispatch({
        type: 'LOCAL_STORE_FILTER',
        payload: {
          objID: 'searchAgency',
          objType: 'getAgencies',
          query: args
        }
      });
    } catch (error) {
      console.log(error);
      // console.log('error', error);
    }
  }

  render() {
    const { listLocation } = this.state;
    return (
      <div>
        <SearchAgency
          listLocation={listLocation}
          errorMessage={this.state.errorMessage}
          userInfo={this.userInfo}
          
          buildListManager={this.buildListManager}
          buildLocation={this.buildLocation}
          
          getAgencies={this.getAgencies}
          getMembers={this.getMembers}
        />
      </div>
    );
  }

  componentWillMount() {
    try {
      const agencyFilter = localStoreFilter('searchAgency', 'getAgencies');
      const filter = agencyFilter || {
        filters: {
          group: ['agency', 'customer', 'collaborator', 'supplier'],
          area: '',
          title: '',
          phone: '',
          createdAt: {
            from: '',
            to: ''
          }
        },
        paging: {
          start: 0,
          limit: PAGE_LIMIT
        }
      };
      
      this.getAgencies(filter);
    } catch (error) {
      // console.log(error);
    }
  }

  buildListManager() {
    const { accounts } = this.props;
    const managers = [
      { text: 'Tất cả quản lý', id: 'all' },
      { text: 'Đại lý thuộc quản lý nhân viên', id: 'root' }
    ];
    if (accounts) {
      accounts.forEach((account) => {
        managers.push({ text: account.title, id: account.identifyCode });
      });
    }
    return managers;
  }

  buildLocation() {
    const { init } = this.props;
    if (init.area) {
      return buildListLocation(init);
    }
    return [];
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    //
    accountAct: bindActionCreators(AccountActions, dispatch),
    agenciesAct: bindActionCreators(AgenciesActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    agencies: rootState.agencyReducer.agencies,
    accounts: rootState.accountReducer.accounts,
    init: rootState.generalReducer.init,
    localStoreFilter: rootState.generalReducer.localStoreFilter,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(SearchAgencyContainer);
