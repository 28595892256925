import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import YouTube from 'react-youtube';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingUsd, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Images } from '../../ui/assets/images';
import { API_URL } from '../../configs/api.config';
import DearchInvoiceContainer from '../invoice/search/searchInvoice.container';
import * as LinkActions from '../../redux/links/link.actions';

class Home extends React.Component {
  constructor(props) {
    super(props);
    
    this.handleLinkInfo = this.handleLinkInfo.bind(this);
    this.handleLink = this.handleLink.bind(this);
  }

  handleLinkInfo() {
    const { linkAct } = this.props;
    linkAct.GetLinkInfo({
      telegram: {}
    });
  }

  handleLink() {
    const { linkInfo } = this.props;
    const linkStatus = _.get(linkInfo, 'telegram.linked', null);
    if (linkStatus === false) {
      const url = _.get(linkInfo, 'telegram.url', null);
      window.open(url, '_blank');
      setTimeout(this.handleLinkInfo, 10000);
    }
  }

  renderTelegram() {
    const { init, linkInfo } = this.props;
    const linkStatus = _.get(linkInfo, 'telegram.linked', null);
    const accountInfo = _.get(init, 'accountInfo', null);
    const style = {
      height: 70
    };
    if (_.isNull(linkStatus) === true) {
      return (<div></div>);
    }
    const phone = _.get(accountInfo, 'phone', false);
    if (linkStatus === false) {
      return (<div key={'telegram'} className="card">
        <div className="header-elements-inline">

          <div className="card-body">
            <div className="media">
              <div className="mr-3">
                <i style={{
                  fontSize: 48, color: 'darkgray' 
                }} className="fa fa-telegram fa-x3"></i> 
              </div>
              <div className="media-body">
                <h6 className="mb-0">
                  <span className="text-muted"> Liên kết telegram để nhận thông báo</span>
                </h6>
              </div>
              <button
                style={{
                  color: 'darkcyan',
                  fontSize: 25
                }}
                onClick={this.handleLink}
                className="btn btn-default">
                <i title="Kết nối telegram" className="fa fa-link" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      
      </div>);
    } 
    return (<div></div>);
  }

  renderReschedule() {
    const { init } = this.props;
    const delegates = _.get(init, 'accountInfo.delegates', false);
    
    if (_.isEmpty(delegates) === true) {
      return (<div></div>);
    }

    return (<div key={'reschedule'} className="card">
      <div className="card-header header-elements-inline">
        <h6 className="card-title">
          <i className="fa fa-calendar"></i> Thông tin lịch hẹn
        </h6>
        <div className="header-elements">
          {/* <span>
            <i className="fa fa-calendar"></i> 08:00 - 18:00
          </span> */}
        </div>
      </div>
      <div className="card-body">
        <div className="media">
          
          <div className="media-body">
            <h6 className="mb-0">
                bbb
            </h6>
            <span className="text-muted">aaa</span><br />
          </div>
        </div>
      </div>
    </div>);
  }

  renderDelegate() {
    const { init } = this.props;
    const accountInfo = _.get(init, 'accountInfo', false);
    const delegates = _.get(init, 'accountInfo.delegates', false);
    
    if (_.isEmpty(delegates) === true) {
      return (<div></div>);
    }
    return (delegates.map((item, i) => {
      return (<div key={`delegates_card_${i}`} className="card">
        <div className="card-header header-elements-inline">
          <h6 className="card-title">{(accountInfo.group !== 'staff') ? 'Chuyên viên hỗ trợ cho bạn' : 'Bạn đang ủy thác cho'}</h6>
          <div className="header-elements">
            <span>
              <i className="icon-history text-warning mr-2"></i> 08:00 - 18:00
            </span>
          </div>
        </div>
        <div className="card-body">
          <div className="media">
            <div className="mr-3">
              <img src={`${API_URL}/Account/Avatar/${item.phone}`} className="rounded-circle" width="42" height="42" alt="" />
            </div>
            <div className="media-body">
              <h6 className="mb-0">
                {item.title} <br /><span className="text-muted"> <a href={`tel:${item.phone}`}><i className="fa fa-phone-square"></i> {item.phone}</a></span>
              </h6>
              <span className="text-muted">{item.description}</span><br />
            </div>
          </div>
        </div>
      </div>);
    }));
  }

  renderParent() {
    const { init } = this.props;
    const parent = _.get(init, 'accountInfo.parent', false);
    console.log(parent);
    const accountInfo = _.get(init, 'accountInfo', false);
    
    if (_.isEmpty(parent) === true) {
      return (<div></div>);
    }
    return (<div className="card">
      <div className="card-header header-elements-inline">
        <h6 className="card-title">{(accountInfo.group !== 'staff') ? 'Chuyên viên hỗ trợ cho bạn' : 'Quản lý của bạn'}</h6>
        <div className="header-elements">
          <span>
            <i className="icon-history text-warning mr-2"></i> 08:00 - 18:00
          </span>
        </div>
      </div>
      <div className="card-body">
        <div className="media">
          <div className="mr-3">
            <img src={`${API_URL}/Account/Avatar/${parent.phone}?t=${_.random(true)}`} className="rounded-circle" width="42" height="42" alt="" />
          </div>
          <div className="media-body">
            <h6 className="mb-0">
              {parent.title} <br /> <span className="text-muted"> <a href={`tel:${parent.phone}`}><i className="fa fa-phone-square"></i> {parent.phone}</a></span>
            </h6>
          </div>
        </div>
      </div>
    </div>);
  }

  render() {
    const opts = {
      height: '390',
      width: '640',
      playerVars: {
        autoplay: 0
      }
    };
    const { init } = this.props;
    const creditLimit = _.get(init, 'walletInfo.detail.creditLimit', 0);
    return (
      <div className="row">
        <div className="col-xl-8">
          
          <div className="row" style={{ marginBottom: 20 }}>

            <div className="col-lg-4">
              <div className="card bg-slate-400" >
                <div className="card-body" style={{ 
                  height: 102, 
                  lineHeight: '76px', 
                  verticalAlign: 'middle', 
                  display: 'inline-block', 
                  width: '100%',
                  paddingRight: 0
                }}>
                  <FontAwesomeIcon style={{
                    marginRight: 5, marginTop: 10, fontSize: 35, float: 'left'
                  }} icon={faHandHoldingUsd} /> 
                  <span style={{ fontSize: 18, fontWeight: '400' }}>
          Nạp tiền thanh toán dịch vụ
                  </span>
                </div>
                
              </div>
            </div>

            <div className="col-lg-4">
              <div className="card bg-blue-400">
                <div className="card-body" style={{ 
                  height: 102, 
                  paddingRight: 0
                }}>
                  <div className="d-flex" style={{ padding: '7px 0px' }}>
                    <span style={{ fontSize: 18, fontWeight: '400' }}>
                      {_.get(init, 'walletInfo.amount', 0).toLocaleString()} đ
                    </span>
                    <div style={{ position: 'absolute', top: 10, right: 10 }}>
                      <FontAwesomeIcon 
                        style={{ fontSize: 16 }} 
                        data-tip="Số Tiền được phép sử dụng bao gồm cả hạn mức" 
                        icon={faQuestionCircle} />
                    </div>
                  </div>
                  <div>
                    <div className="font-size-lg opacity-75">
            Số dư khả dụng
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {creditLimit > 0 ? <div className="col-lg-4">
              <div className="card bg-pink-400" style={{ 
                margin: 0, 
                borderTopRightRadius: '.1875rem',
                borderTopLeftRadius: '.1875rem',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0
              }}>
                <div className="card-body" style={{ padding: '3px 1.25rem', height: 50 }}>
                  <div className="d-flex">
                    <span style={{ fontSize: 16, fontWeight: '400' }}>
                      {_.get(init, 'walletInfo.detail.credit', 0).toLocaleString()} đ
                    </span>

                    <div style={{ position: 'absolute', top: 5, right: 5 }}>
                      <FontAwesomeIcon 
                        style={{ fontSize: 16 }} 
                        data-tip="Số tiền đã sử dụng" 
                        icon={faQuestionCircle} />
                    </div>
                  </div>
                  <div>
                    <div className="font-size-lg opacity-75">
        Công nợ chưa thanh toán
                    </div>
                  </div>
                </div>
              </div>

              <div className="card bg-teal-400" style={{ 
                margin: 0, 
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: '.1875rem',
                borderBottomRightRadius: '.1875rem'
              }}>
                <div className="card-body" style={{ padding: '3px 1.25rem', height: 50 }}>
                  <div className="d-flex">
                    <span style={{ fontSize: 16, fontWeight: '400' }}>
                      {_.get(init, 'walletInfo.detail.creditLimit', 0).toLocaleString()} đ
                    </span>
        
                    <div style={{ position: 'absolute', top: 5, right: 5 }}>
                      <FontAwesomeIcon 
                        style={{ fontSize: 16 }} 
                        data-tip="Được phép sử dụng số tiền này do Vnhub cấp hạn mức" 
                        icon={faQuestionCircle} />
                    </div>
                  </div>
                  <div>
                    <div className="font-size-lg opacity-75">
        Hạn mức công nợ 
                    </div>
                  </div>
                </div>
              </div>
  
            </div> : ''}

          </div>
          
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header bg-transparent header-elements-inline">
                  <h5 className="card-title">Tiến độ đơn hàng</h5>
                </div>
                <div className="card-body">
                  <DearchInvoiceContainer minimize={true} />
                </div>
                
              </div>
              
            </div>
          </div>
        </div>
        
        <div className="col-xl-4">
          {this.renderTelegram()}
          {/* {this.renderReschedule()} */}
          {this.renderDelegate()}
          {this.renderParent()}
          
          <div className="card">
            <div className="card-img-actions mx-1 mt-1">
              <div className="embed-responsive embed-responsive-16by9">
                <YouTube
                  videoId="RoeGAWlqxZg"
                  opts={opts}
                />
              </div>
            </div>

            <div className="card-body text-center">
              <h6 className="font-weight-semibold">Huớng dẫn cơ bản</h6>
              <p className="text-muted">Chào mừng bạn đến với cổng quản lý du lịch. Chúng tôi sẽ hỗ trợ bạn làm quen với hệ thống</p>
              {/* <a href="#" className="btn bg-indigo-400">
                <i className="icon-link mr-2"></i> Khám phá thêm các video khác
              </a> */}
            </div>
          </div>
        </div>
      </div>
     
    );
  }

  componentDidMount() {
    try {
      /**
       * 
       */
      this.handleLinkInfo();
    } catch (error) {
      /**
       * 
       */
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    linkAct: bindActionCreators(LinkActions, dispatch),
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      linkInfo: payload.linkReducer.linkInfo,
      orders: payload.orderReducer.orders,
      init: payload.generalReducer.init,
      userInfo: payload.generalReducer.userInfo,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(Home)
);
