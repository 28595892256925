import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ExportData from './exportData';
import * as ExportDataActions from '../../../redux/exportData/exportData.actions';
import { PAGE_LIMIT, CALLLOG_MANAGE_SCOPE, SYSTEM_EXPORT_DATA } from '../../../configs/constants';
import store from '../../../configs/store.config';
import {
  serviceDetail, serviceHandleDatas, checkScope,
  buildListLocation, buildListCustomerType, buildListCustomerSource, localStoreFilter
} from '../../../helpers';

class ExportDataContainer extends React.Component {
  constructor(prop) {
    super(prop);

    if (checkScope(SYSTEM_EXPORT_DATA, this.props) === false) {
      NotificationManager.error('Chức năng không tồn tại', '', 1500);
      setTimeout(() => {
        this.props.history.push('/dashboard');
      }, 2000);
    }

    this.state = {
      downloadUrl: '',
      summary: {
        amountVnhub: 0,
        amountUser: 0,
        amountCredit: 0,
        amountAll: 0
      }
    };

    this.callback = this.callback.bind(this);
    this.callbackSummary = this.callbackSummary.bind(this);
  }

  onSubmit = async (args, type) => {
    try {
      const { exportDataAct } = this.props;
      const query = Object.assign({}, args);
      this.setState({
        downloadUrl: ''
      });
      exportDataAct.exportData(query, this.callback);
    } catch (error) {
      // console.log('error', error);
    }
  }

  // openLink(url) {
  //   const a = document.createElement('a');
  //   a.href = url;
  //   a.target = '_blank';
  //   a.click();
  // }

  callback(status, data) {
    if (status) {
      this.setState({
        downloadUrl: _.get(data, 'downloadUrl', '')
      });
      // this.openLink('https://vietcombank.nganluong.vn/en/checkout/version_1_0/index/5482922-CO438E5DED9F');
      // // window.open(data.downloadUrl);
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }

  render() {
    // const {
 
    // } = this.state;

    return (
      <div>
        <ExportData
          downloadUrl={this.state.downloadUrl}
          summary={this.state.summary}
          onSubmit={this.onSubmit}
        />
        <NotificationContainer />
      </div>
        
    );
  }

  componentWillMount() {
    const { exportDataAct } = this.props;
    const query = {};
    exportDataAct.exportDataSummary(query, this.callbackSummary);
  }

  callbackSummary(status, data) {
    if (status) {
      this.setState({
        summary: data
      });
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    exportDataAct: bindActionCreators(ExportDataActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(ExportDataContainer);
