import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Home from './home';
import * as OrderActions from '../../redux/orders/orders.actions';

class HomeContainer extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = {};
    this.getOrders = this.getOrders.bind(this);
  }

  getOrders(args) {
    try {
      const { orderAct } = this.props;
      const filters = Object.assign({}, args.filters);
      const query = Object.assign({}, args);
      Object.keys(filters).forEach((key) => {
        if (_.isObject(filters[key]) === true) {
          Object.keys(filters[key]).forEach((key2) => {
            if (_.isEmpty(filters[key][key2]) === true) {
              delete filters[key][key2];
            }
          });
        } else if (_.isEmpty(filters[key]) === true) {
          delete filters[key];
        }
        if (_.isEmpty(filters[key]) === true) {
          delete filters[key];
        }
      });
      query.filters = filters;
      orderAct.searchOrder(query);
    } catch (error) {
      console.log(error);
      // console.log('error', error);
    }
  }

  render() {
    return (
      <div>
        <Home
          errorMessage={this.state.errorMessage}
          userInfo={this.userInfo}
        />
          
      </div>
        
    );
  }

  componentWillMount() {
    try {
      this.getOrders({
        filters: {},
        paging: {
          start: 0,
          limit: 20
        }
      });
    } catch (error) {
      // console.log(error);
    }
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    orderAct: bindActionCreators(OrderActions, dispatch)
    //
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    localStoreFilter: rootState.generalReducer.localStoreFilter,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(HomeContainer);
