import * as types from './accounts.types';

const initialState = {
  accountSearchByKeyword: [],
  accounts: [],
  account: {}
};

export default function accounts(state = initialState, action) {
  switch (action.type) {
    case types.POST_ACCOUNT_DETAIL_SUCCESS: {
      return {
        ...state,
        account: action.payload
      };
    }

    case types.POST_SEARCH_MEMBER_SUCCESS: {
      return {
        ...state,
        members: action.payload
      };
    }

    case types.POST_SEARCH_ACCOUNT_SUCCESS: {
      return {
        ...state,
        accounts: action.payload
      };
    }

    case types.POST_SEARCH_BY_KEYWORD_ACCOUNT_SUCCESS: {
      return {
        ...state,
        accountSearchByKeyword: action.payload
      };
    }
    
    default:
      return state;
  }
}
