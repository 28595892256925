
/**
 * CRUD
 */
export const GET_BUILD_SERVICE = 'GET_BUILD_SERVICE';
export const GET_BUILD_SERVICE_SUCCESS = 'GET_BUILD_SERVICE_SUCCESS';
export const GET_BUILD_SERVICE_FAIL = 'GET_BUILD_SERVICE_FAIL';

export const GET_SERVICE = 'GET_SERVICE';
export const GET_SERVICE_SUCCESS = 'GET_SERVICE_SUCCESS';
export const GET_SERVICE_FAIL = 'GET_SERVICE_FAIL';

export const POST_SERVICE = 'POST_SERVICE';
export const POST_SERVICE_SUCCESS = 'POST_SERVICE_SUCCESS';
export const POST_SERVICE_FAIL = 'POST_SERVICE_FAIL';

export const PUT_SERVICE = 'PUT_SERVICE';
export const PUT_SERVICE_SUCCESS = 'PUT_SERVICE_SUCCESS';
export const PUT_SERVICE_FAIL = 'PUT_SERVICE_FAIL';

export const DELETE_SERVICE = 'DELETE_SERVICE';
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS';
export const DELETE_SERVICE_FAIL = 'DELETE_SERVICE_FAIL';
