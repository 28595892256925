
/**
 * CRUD
 */

export const EXPORT_DATA = 'EXPORT_DATA';
export const EXPORT_DATA_SUMMARY = 'EXPORT_DATA_SUMMARY';

export const EXPORT_DATA_SUCCESS = 'EXPORT_DATA_SUCCESS';
export const EXPORT_DATA_SUMMARY_SUCCESS = 'EXPORT_DATA_SUMMARY_SUCCESS';

export const EXPORT_DATA_FAIL = 'EXPORT_DATA_FAIL';
export const EXPORT_DATA_SUMMARY_FAIL = 'EXPORT_DATA_SUMMARY_FAIL';
 
