import { combineReducers } from 'redux';
import { i18nState } from 'redux-i18n';
import apiReducer from '../redux/api/api.reducers';
import generalReducer from '../redux/general/general.reducers';
import accountReducer from '../redux/accounts/accounts.reducers';
import agencyReducer from '../redux/agencies/agencies.reducers';
import supplierReducer from '../redux/suppliers/suppliers.reducers';
import orderReducer from '../redux/orders/orders.reducers';
import branchReducer from '../redux/branchs/branchs.reducers';
import departmentReducer from '../redux/departments/departments.reducers';
import serviceReducer from '../redux/services/services.reducers';
import walletReducer from '../redux/wallets/wallets.reducers';
import callLogReducer from '../redux/callLogs/callLogs.reducers';
import coboPoboReducer from '../redux/coboPobo/coboPobo.reducers';
import supportReducer from '../redux/supports/supports.reducers';
import utilityReducer from '../redux/utilities/utilities.reducers';
import linkReducer from '../redux/links/link.reducers';

const rootReducer = combineReducers({
  i18nState,
  apiReducer,
  generalReducer,
  accountReducer,
  agencyReducer,
  orderReducer,
  branchReducer,
  departmentReducer,
  walletReducer,
  serviceReducer,
  callLogReducer,
  coboPoboReducer,
  supportReducer,
  utilityReducer,
  linkReducer,
  supplierReducer
});

export default rootReducer;

