import * as types from './suppliers.types';

export function searchByKeywordSupplier(payload, callback) {
  return {
    type: types.POST_SEARCH_BY_KEYWORD_SUPPLIER,
    payload,
    callback
  };
}

export function searchSupplier(payload, callback) {
  return {
    type: types.POST_SEARCH_SUPPLIER,
    payload,
    callback
  };
}

export function detailSupplier(payload, callback) {
  return {
    type: types.POST_SUPPLIER_DETAIL,
    payload,
    callback
  };
}

export function createSupplier(payload, callback) {
  return {
    type: types.POST_SUPPLIER,
    payload,
    callback
  };
}

export function modifySupplier(payload, callback) {
  return {
    type: types.PUT_SUPPLIER,
    payload,
    callback
  };
}
