import { API_URL } from '../../configs/api.config';
import Request from '../middleware/request';

const branchAPI = {
  /**
   * 
   */
  postSearchBranch(args) {
    return Request.callAPI('post', `${API_URL}/Organization/Search`, { ...args });
  },
  postCreateBranch(args) {
    return Request.callAPI('post', `${API_URL}/Organization`, { ...args });
  },
  putModifyBranch(args) {
    return Request.callAPI('put', `${API_URL}/Organization/${args.id}`, { ...args.data });
  },
  deleteBranch(args) {
    return Request.callAPI('delete', `${API_URL}/Organization/${args.id}`);
  }
};

export default branchAPI;

