import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';

class ModifyBranch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listTag: [],
      groupScope: [],
      model: {
        id: '',
        title: '',
        description: '',
        address: '',
        phone: '',
        parentIdentifyCode: 'root',
        type: 'branch',
        scopes: [],
        isActive: true
      }
    };
    
    this.renderPermission = this.renderPermission.bind(this);
    this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
  }

  onSubmit = async (e) => {
    try {
      e.preventDefault();
      const validate = this.validateOnSubmit();
      if (validate === true) {
        const { model, listTag, groupScope } = this.state;

        const code = e.target.value;
        const scopes = [];
        listTag.forEach((tag) => {
          groupScope[tag].forEach((scope, j) => {
            if (scope.checked === true) {
              groupScope[tag][j].checked = true;
              scopes.push(scope.code);
            }
          });
        });
        
        if (_.isEmpty(scopes) === true) {
          NotificationManager.error('Vui lòng chọn phân quyền', '', 1500);
        } else {
          model.scopes = scopes;
          await this.props.onSubmit(model);
        }
      }
    } catch (error) {
      /**
       * 
       */
    }
  }

  validateOnSubmit() {
    const { model, settings } = this.state;
    if (_.isEmpty(model.title) === true) {
      NotificationManager.error('Tên chi nhánh không được để trống', '', 1500);
      return false;
    }
    if (_.isEmpty(model.address) === true) {
      NotificationManager.error('Địa chỉ không được để trống', '', 1500);
      return false;
    }
    if (_.isEmpty(model.phone) === true) {
      NotificationManager.error('Số điện thoại được để trống', '', 1500);
      return false;
    }
    return true;
  }

  onChangeHandle(key, e) {
    const model = Object.assign([], this.state.model);
    model[key] = e.target.value;
    this.setState({ model });
  }

  onChangeCheckBox(e) {
    const model = Object.assign([], this.state.model);
    const { listTag, groupScope } = this.state;
    const code = e.target.value;
    
    listTag.forEach((tag) => {
      groupScope[tag].forEach((scope, j) => {
        if (scope.code === code) {
          groupScope[tag][j].checked = e.target.checked;
        }
      });
    });
    this.setState({ model, groupScope });
  }

  renderPermission() {
    const { listTag, groupScope } = this.state;
    const itemsHtml = [];
    listTag.forEach((tag) => {
      const scopes = groupScope[tag];

      itemsHtml.push({
        level: 0,
        html: this.buildHtml(tag, 0)
      });

      itemsHtml.push({
        level: 1,
        html: this.buildHtml(groupScope[tag], 'child')
      });
    });

    return (itemsHtml.map((item, i) => {
      if (item.level === 0) {
        return (<div key={i} className="form-group">{item.html}</div>);
      }
      return (<div key={i} className="ml-4 mb-2 mt-2">{item.html}</div>);
    }));
  }

  buildHtml(item, level) {
    if (level === 0) {
      return (
        <div key={item} className="form-check">
          <label className="form-check-label">
            <div className="uniform-checker border-primary-600 text-primary-800">
            </div>
            <span className="font-weight-semibold">{item}</span>
          </label>
        </div>);
    }
    if (level === 1) {
      return (
        <div key={item.title} className="form-check form-check-inline">
          <label className="form-check-label" title="Nguồn khách do Marketing và toàn thể NV đem về">
            <div className="uniform-checker">
              <span className={item.checked === true ? 'checked' : ''}>
                <input
                  defaultChecked= {item.checked}
                  value={item.code}
                  onChange={this.onChangeCheckBox}
                  type="checkbox"
                  className="form-check-input-styled"
                  data-fouc="true" />
              </span>
            </div>{item.title}</label>
        </div>
      );
    }
    const childHtml = [];
    item.forEach((children) => {
      childHtml.push(this.buildHtml(children, 1));
    });
    return (childHtml.map((child, i) => {
      return child;
    }));
  }

  render() {
    const { model } = this.state;
    const { branch } = this.props;
    return (
      <div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header header-elements-inline">
                <h5 className="card-title">Thêm chi nhánh</h5>
                <div className="header-elements">
                </div>
              </div>
              <div className="card-body ">
                <form 
                  onSubmit={this.onSubmit}
                >
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>Quyền Được Cấp</label>
                          <div className="card">
                            <div className="card-header header-elements-sm-inline">
                              <h6 className="card-title">Phân quyền</h6>
                            </div>
                            <div className="card-body">
                              {this.renderPermission(branch)}
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Tên chi nhánh</label>
                          <input
                            value={model.title} 
                            onChange={this.onChangeHandle.bind(this, 'title')}
                            type="text"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group">
                          <label>Loại Chiết khấu</label>
                          <select 
                            value={model.key} 
                            // onChange={this.onChangeHandle.bind(this, 'discount')}
                            className="form-control"
                            disabled
                          >
                            <option>Mặt định (dựa vào số lượng HĐ)</option>
                            <option value="AZ">Đại lý Level 1 - 5%</option>
                            <option value="AZ">Đại lý Level 2 - 8%</option>
                            <option value="AZ">Đại lý Level 3 - 12%</option>
                            <option value="AZ">Chi nhánh Level 1 - 20%</option>
                            <option value="AZ">Chi nhánh Level 2 - 30%</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Địa chỉ</label>
                          <input 
                            value={model.address} 
                            onChange={this.onChangeHandle.bind(this, 'address')}
                            type="text" 
                            className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Điện thoại</label>
                          <input 
                            value={model.phone} 
                            onChange={this.onChangeHandle.bind(this, 'phone')}
                            type="text" 
                            placeholder="Nhập số Điện thoại công việc" className="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="text-right">
                    <button className="btn btn-primary">Lưu lại</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <NotificationContainer/>
      </div>
     
    );
  }

  componentDidMount() {
    try {
      /**
       * 
       */
      
    } catch (error) {
      // console.log(error);
      /**
       * 
       */
    }
  }

  componentWillReceiveProps() {
    
  }

  componentWillUnmount() {
    try {
      /**
       * 
       */
    } catch (error) {
      /**
       * 
       */
    }
  }

  componentDidUpdate(oldProps) {
    try {
      /**
       * 
       */
      const newProps = this.props;
      if (oldProps.branch !== newProps.branch) {
        const { branch } = this.props;
        const settings = [];
        const model = {
          id: branch.id,
          title: branch.title,
          description: branch.description,
          address: branch.address,
          phone: branch.phone,
          parentIdentifyCode: branch.parentIdentifyCode,
          type: branch.type,
          scopes: branch.scopes,
          isActive: branch.isActive
        };
        
        this.setState({ model });
        this.props.groupScopeFunc(branch);
      }
      if (oldProps.groupScope !== newProps.groupScope) {
        const { groupScope, listTag } = this.props;
        this.setState({ listTag, groupScope });
      }
    } catch (error) {
      /**
       * 
       */
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      branch: payload.branchReducer.branch,
      userInfo: payload.generalReducer.userInfo,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(ModifyBranch)
);
