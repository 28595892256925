import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import ModifyPaymentVoucher from './modifyPaymentVoucher';
import { SYSTEM_SERVICE_SCOPE } from '../../../configs/constants';
import {
  checkScope
} from '../../../helpers';

class ModifyPaymentVoucherContainer extends React.Component {
  constructor(prop) {
    super(prop);

    if (checkScope(SYSTEM_SERVICE_SCOPE, this.props) === false) {
      NotificationManager.error('Chức năng không tồn tại', '', 1500);
      setTimeout(() => {
        this.props.history.push('/dashboard');
      }, 2000);
    }

    this.state = {
      errorMessage: {}
    };
  }

  render() {
    return (
      <div>
        <ModifyPaymentVoucher
          errorMessage={this.state.errorMessage}
          userInfo={this.userInfo}
        />
          
      </div>
        
    );
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    //
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(ModifyPaymentVoucherContainer);
