import * as types from './coboPobo.types';

const initialState = {
  coboPobo: [],
  socketOrder: {}
};

export default function coboPobo(state = initialState, action) {
  switch (action.type) {
    case types.COBO_POBO_SEARCH_SUCCESS: {
      return {
        ...state,
        coboPobos: action.payload
      };
    }

    case types.COBO_POBO_EXPORT_FILE_SUCCESS: {
      return {
        ...state,
        coboPoboExportFile: action.payload
      };
    }

    case types.COBO_POBO_DETAIL_SUCCESS: {
      return {
        ...state,
        coboPobo: action.payload
      };
    }
    case types.COBO_POBO_CREATE_SUCCESS: {
      return {
        ...state,
        coboPoboCreate: action.payload
      };
    }
    default:
      return state;
  }
}
