import * as types from './suppliers.types';

const initialState = {
  supplierSearchByKeyword: [],
  suppliers: [],
  supplier: {}
};

export default function agentcies(state = initialState, action) {
  switch (action.type) {
    case types.POST_SUPPLIER_DETAIL_SUCCESS: {
      return {
        ...state,
        supplier: action.payload
      };
    }

    case types.POST_SEARCH_SUPPLIER_SUCCESS: {
      return {
        ...state,
        suppliers: action.payload
      };
    }

    case types.POST_SEARCH_BY_KEYWORD_SUPPLIER_SUCCESS: {
      return {
        ...state,
        supplierSearchByKeyword: action.payload
      };
    }
    
    default:
      return state;
  }
}
