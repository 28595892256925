import _ from 'lodash';

const defaultLimit = 100;

function paging(pageNum, limit = defaultLimit) {
  if (!pageNum || pageNum <= 0) {
    return null;
  }
  return {
    start: (pageNum - 1) * limit,
    limit
  };
}

// eslint-disable-next-line import/prefer-default-export
export { paging };

