import _ from 'lodash';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import Select2 from 'react-select2-wrapper';
import InputMask from 'react-input-mask';
import moment from 'moment';
import NumericInput from 'react-numeric-input';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import SelectStateContainer from '../../ultils/selectState.container';
import 'moment-timezone';

import {
  buildListCustomerDenyType, formatDate, checkScope
} from '../../../helpers';

class ModifyCustomerSupport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customerSupportModel: {
        listerPhone: '',
        listerName: '',
        callerRecordedUrl: '',
        priority: 0,
        displayAt: moment(moment().format('MM/DD/YYYY')).format(),
        information: {
          source: '',
          email: '',
          type: '',
          subType: '',
          isBussiness: false,
          service: '',
          area: '',
          note: ''
        }
      },
      displayAtDisplay: formatDate(new Date()),
      showDenyOption: false,
      listerPhone: '',
      listerName: '',
      sourceDefaultValue: false,
      typeDefaultValue: false,
      denyTypeDefaultValue: false,
      contries: [],
      listCustomerDenyType: [],
      parentIdentifies: []
    };

    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.validateOnSubmit = this.validateOnSubmit.bind(this);
  }

  onSubmit = (closeDialog = false, e) => {
    try {
      e.preventDefault();
      // SHOW LOADING
      this.props.dispatch({ type: 'SHOW_LOADING' });
      const validate = this.validateOnSubmit();

      if (validate === true) {
        const listCustomerType = this.props.buildCustomerType();
        const listCustomerSource = this.props.buildCustomerSource();
        const model = Object.assign({}, this.state.customerSupportModel);
        if (_.isEmpty(model.information.source) === true) {
          model.information.source = listCustomerSource[0].value;
        }
        if (_.isEmpty(model.information.type) === true) {
          model.information.type = listCustomerType[0].value;
        }
        if (_.isEmpty(model.information.subType) === false) {
          model.information.type = `${model.information.type}-${model.information.subType}`;
        }
        model.listerPhone = model.listerPhone.trim();
        model.listerName = model.listerName.trim();
        this.props.onSubmit(model);
      }
      // HIDE LOADING
      this.props.dispatch({ type: 'HIDE_LOADING' });
    } catch (error) {
      this.props.dispatch({ type: 'HIDE_LOADING' });
    }
  }

  validateOnSubmit() {
    const model = Object.assign({}, this.state.customerSupportModel);
    if (!model.listerPhone) {
      NotificationManager.error('Số điện thoại không được để trống', '', 1500);
      return false;
    }
    if (!model.listerName) {
      NotificationManager.error('Tên khách hàng không được để trống', '', 1500);
      return false;
    }

    const priority = Number(model.priority);
    if (_.isNumber(priority) === false) {
      NotificationManager.error('Mức độ ưu tiên không được để trống', '', 1500);
      return false;
    }

    const email = _.get(model, 'information.email', false);

    if (email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) === false) {
      NotificationManager.error('Email không hợp lệ', '', 1500);
      return false;
    }
    
    return true;
  }

  onChangeHandle(key, e) {
    try {
      const model = Object.assign({}, this.state.customerSupportModel);
      const result = {};
      switch (key) {
        case 'isBussiness': {
          model.information.isBussiness = _.get(e, 'target.checked', false);
          break;
        }
        case 'email': {
          model.information.email = _.get(e, 'target.value', _.get(e, 'value', false));
          break;
        }
        
        case 'type': {
          const listCustomerType = this.props.buildCustomerType();
          const value = _.get(e, 'target.value', _.get(e, 'value', false));
          const type = _.find(listCustomerType, { value });
          result.showDenyOption = false;
          result.typeDefaultValue = e;
          model.information.type = value;
          model.information.subType = '';

          if (_.isEmpty(type.children) === false) {
            result.showDenyOption = true;

            result.listCustomerDenyType = buildListCustomerDenyType(type.children);
            result.denyTypeDefaultValue = result.listCustomerDenyType[0];
            model.information.subType = result.listCustomerDenyType[0].value;
          }
          break;
        }

        case 'displayAt': {
          const value = _.get(e, 'target.value', _.get(e, 'value', false));
          const dateFormat = 'YYYY/MM/DD';
          const splittedDate = value.split('/');
          if (splittedDate.length === 3) {
            const date = moment(`${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`, dateFormat);
            result.displayAtDisplay = value;
            if (date.isValid() === true) {
              model[key] = date.format();
            }
          }
          break;
        }

        case 'subType': {
          model.information.subType = _.get(e, 'target.value', _.get(e, 'value', false));
          result.denyTypeDefaultValue = e;

          break;
        }
        case 'priority': {
          model.priority = e;
          break;
        }

        case 'source': {
          model.information.source = _.get(e, 'target.value', _.get(e, 'value', false));
          result.sourceDefaultValue = e;

          break;
        }
        case 'listerPhone': {
          model.listerPhone = _.get(e, 'target.value', _.get(e, 'value', ''));
          break;
        }
        case 'listerName': {
          model.listerName = _.get(e, 'target.value', _.get(e, 'value', ''));
          break;
        }
        default: {
          model.information[key] = _.get(e, 'target.value', _.get(e, 'value', false));
          break;
        }
      }
      result.customerSupportModel = model;
      this.setState(result);
    } catch (error) {
      // console.log(error);
    }
  }

  render() {
    const {
      customerSupportModel,
      showDenyOption,
      parentIdentifies,
      sourceDefaultValue,
      typeDefaultValue,
      denyTypeDefaultValue,
      listCustomerDenyType,
      displayAtDisplay

    } = this.state;
    const listCustomerType = this.props.buildCustomerType();
    const listCustomerSource = this.props.buildCustomerSource();
    return (
      <div>
        <div className="page-header page-header-light">
          <div className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="text-center d-lg-none w-100">
              <button type="button" className="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-second">
                <i className="icon-menu7 mr-2"></i>Profile navigation</button>
            </div>
          </div>
          <div className="content">
            <div className="d-flex align-items-start flex-column flex-md-row">
              <div className="tab-content w-100 overflow-auto order-2 order-md-1">

                <div className="card">
                  <div className="card-header header-elements-inline">
                    <h6 className="font-weight-semibold mb-3">Nội dung hỗ trợ</h6>
                    <div className="header-elements">
                    </div>
                  </div>
                  
                  <div className="card-body">
                    <form 
                      onSubmit={this.onSubmit}
                    >
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row" >
                              <div className="col-md-5" >Khách hàng đại lý</div>
                              <div className="col-md-7">
                                <input
                                  style={{ height: '2.25003rem' }}
                                  value={customerSupportModel.information.isBussiness}
                                  onChange={this.onChangeHandle.bind(this, 'isBussiness')}
                                  checked={customerSupportModel.information.isBussiness}
                                  type="checkbox"

                                  required="" />
                              </div>

                            </div>
                          </div>
                          <div className="col-md-6">
                        
                          </div>

                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">

                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-5"> Ngày gọi điện </div>
                              <div className="col-md-7">
                                <InputMask
                                  value={displayAtDisplay}
                                  className="form-control"
                                  mask="99/99/9999"
                                  onChange={this.onChangeHandle.bind(this, 'displayAt')}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-5">Mức độ ưu tiên</div>
                              <div className="col-md-7">
                                <NumericInput 
                                  min={0} 
                                  size="number"
                                  mobile
                                  value={customerSupportModel.priority}
                                  onChange={this.onChangeHandle.bind(this, 'priority')}
                                  className="form-control"
                                  format={(num) => {
                                    return `${num}`;
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                         
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-5">Số điện thoại</div>
                              <div className="col-md-7">
                                <input
                                  value={customerSupportModel.listerPhone}
                                  onChange={this.onChangeHandle.bind(this, 'listerPhone')}
                                  type="text"
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-5">Tên khách hàng</div>
                              <div className="col-md-7">
                                <input
                                  value={customerSupportModel.listerName}
                                  onChange={this.onChangeHandle.bind(this, 'listerName')}
                                  placeholder="Nhập tên khách hàng"
                                  type="text" className="form-control" required="" />
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-5">Nguồn khách hàng</div>
                              <div className="col-md-7">
                                <Select
                                  value={sourceDefaultValue || listCustomerSource[0]}
                                  onChange={this.onChangeHandle.bind(this, 'source')}
                                  options={listCustomerSource || []}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-5">Phân loại khách hàng</div>
                              <div className="col-md-7">
                                <SelectStateContainer
                                  value={typeDefaultValue || listCustomerType[0]}
                                  onChange={this.onChangeHandle.bind(this, 'type')}
                                  options={listCustomerType || []}
                                />
                              </div>
                            </div>
                          </div>

                        </div>

                      </div>

                      <div className="form-group">
                        <div className="row">

                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-5">Email</div>
                              <div className="col-md-7">
                                <input
                                  value={customerSupportModel.email}
                                  onChange={this.onChangeHandle.bind(this, 'email')}
                                  placeholder="Nhập tên email"
                                  type="text" className="form-control" required="" />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6"
                            style={showDenyOption ? { display: 'block' } : { display: 'none' }}
                          >
                            <div className="row">
                              <div className="col-md-5">Lý do từ chối</div>
                              <div className="col-md-7">
                                <SelectStateContainer
                                  value={denyTypeDefaultValue || listCustomerDenyType[0]}
                                  onChange={this.onChangeHandle.bind(this, 'subType')}
                                  options={listCustomerDenyType || []}
                                />
                              </div>
                            </div>
                          </div>

                        </div>

                      </div>

                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-5">Yêu cầu dịch vụ</div>
                              <div className="col-md-7">
                                <Select2
                                  value={customerSupportModel.information.service}
                                  onChange={this.onChangeHandle.bind(this, 'service')}
                                  style={{ width: '100%' }}
                                  className="form-control select-search select2-hidden-accessible"
                                  data={parentIdentifies}
                                  options={{
                                    placeholder: 'Chọn loại dịch vụ'
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-5">Thành phố</div>
                              <div className="col-md-7">
                                <Select2
                                  value={customerSupportModel.information.area}
                                  onChange={this.onChangeHandle.bind(this, 'area')}
                                  style={{ width: '100%' }}
                                  className="form-control select-search select2-hidden-accessible"
                                  data={this.props.buildLocation()}
                                  options={{
                                    placeholder: 'Tất cả khu vực'
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                        </div>

                      </div>

                      <div className="form-group">
                        <div className="row">
                          <textarea
                            value={customerSupportModel.information.note}
                            onChange={this.onChangeHandle.bind(this, 'note')}
                            rows="4"
                            cols="4"
                            placeholder="Nhập ghi chú."
                            className="form-control"
                          ></textarea>
                        </div>
                      </div>
                      <div className="text-right">
                        <button onClick={this.onSubmit.bind(this, false)} className="btn btn-info">
                          <i className="fa fa-save" style={{ marginRight: 5 }}></i> Lưu lại
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                
              </div>

            </div>
          </div>
        </div>
      </div>
     
    );
  }

  componentDidMount() {
    try {
      /**
       * 
       */
      const hasAddPermisstion = checkScope(['cs.manage'], this.props);
      if (hasAddPermisstion === false) {
        this.props.history.push('/sale-cus');
      }
    } catch (error) {
      /**
       * 
       */
    }
  }

  componentDidUpdate(oldProps) {
    try {
      const newProps = this.props;
      if (oldProps.buildServices !== newProps.buildServices) {
        const parentIdentifies = this.props.listServiceHandleData();
        this.setState({ parentIdentifies: parentIdentifies.list });
      }
      if (oldProps.customerSupport !== newProps.customerSupport) {
        const { customerSupport } = this.props;
        let showDenyOption = false;
        const listCustomerType = this.props.buildCustomerType();
        const listCustomerSource = this.props.buildCustomerSource();
        
        const information = _.get(customerSupport, 'information', {});
        const typeDefaultValue = _.find(listCustomerType, { value: information.type }) || false;
        const sourceDefaultValue = _.find(listCustomerSource, { value: information.source }) || false;
        if (_.isEmpty(typeDefaultValue.children) === false) {
          showDenyOption = true;
        }
        const listCustomerDenyType = buildListCustomerDenyType(typeDefaultValue.children) || [];
        const denyTypeDefaultValue = _.find(listCustomerDenyType, { value: information.subType }) || false;
        const model = {
          listerPhone: customerSupport.listerPhone,
          listerName: customerSupport.listerPhone,
          callerRecordedUrl: customerSupport.listerPhone,
          priority: customerSupport.listerPhone,
          displayAt: moment(moment(customerSupport.displayAt).format('MM/DD/YYYY')).format(),
          information: {
            source: information.source || '',
            email: information.email || '',
            type: information.type || '',
            subType: information.subType || '',
            isBussiness: information.isBussiness || false,
            service: information.service || '',
            area: information.area || '',
            note: information.note || ''
          }
        };
        const displayAtDisplay = formatDate(customerSupport.displayAt);
        this.setState({
          customerSupportModel: model,
          displayAtDisplay, 
          typeDefaultValue,
          sourceDefaultValue,
          denyTypeDefaultValue,
          showDenyOption 
        });
      }
    } catch (error) {
      /**
       * 
       */
      console.log(error);
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      customerSupport: payload.supportReducer.customerSupport,
      listScope: payload.generalReducer.listScope,
      userInfo: payload.generalReducer.userInfo,
      buildServices: payload.serviceReducer.buildServices,
      init: payload.generalReducer.init,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(ModifyCustomerSupport)
);
