import React from 'react';
import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import Select from 'react-select';
import validator from 'validator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DelayInput } from 'react-delay-input';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Tabs, Tab } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert'; // Import

import PagingContainer from '../../ultils/paging.container';
import {
  PAGE_LIMIT, PAGE_MAX_LIMIT, CUSTOMER_SUPPORT_DELETE_SCOPE, CUSTOMER_SUPPORT_UPDATE_SCOPE 
} from '../../../configs/constants';
import store from '../../../configs/store.config';

import {
  paging,
  formatDateFilter,
  formatDateRange,
  formatDate2,
  checkScope,
  localStoreFilter,
  localStorePageNum,
  formatDateFilter2
} from '../../../helpers';

class SearchCustomerSupport extends React.Component {
  constructor(props) {
    super(props);

    const customerSupportFilter1 = localStoreFilter('searchCustomerSupport', 'getCustomerSupports_1');
    const customerSupportFilter2 = localStoreFilter('searchCustomerSupport', 'getCustomerSupports_2');
    const customerSupportFilter3 = localStoreFilter('searchCustomerSupport', 'getCustomerSupports_3');
    const customerSupportFilter4 = localStoreFilter('searchCustomerSupport', 'getCustomerSupports_4');
    const customerSupportFilter5 = localStoreFilter('searchCustomerSupport', 'getCustomerSupports_5');

    const createdAt1 = _.get(customerSupportFilter1, 'filters.createdAt');
    const createdAt2 = _.get(customerSupportFilter2, 'filters.createdAt');
    const createdAt3 = _.get(customerSupportFilter3, 'filters.createdAt');
    const createdAt4 = _.get(customerSupportFilter4, 'filters.createdAt');
    const createdAt5 = _.get(customerSupportFilter5, 'filters.createdAt');

    const dateRangeTitle1 = formatDateFilter2(_.get(createdAt1, 'from', false), _.get(createdAt1, 'to', false), 'Ngày dự kiến có KQ');
    const dateRangeTitle2 = formatDateFilter2(_.get(createdAt2, 'from', false), _.get(createdAt2, 'to', false), 'Ngày dự kiến có KQ');
    const dateRangeTitle3 = formatDateFilter2(_.get(createdAt3, 'from', false), _.get(createdAt3, 'to', false), 'Ngày dự kiến có KQ');
    const dateRangeTitle4 = formatDateFilter2(_.get(createdAt4, 'from', false), _.get(createdAt4, 'to', false), 'Ngày dự kiến có KQ');
    const dateRangeTitle5 = formatDateFilter2(_.get(createdAt5, 'from', false), _.get(createdAt5, 'to', false), 'Ngày dự kiến có KQ');

    this.state = {
      customerSupportByType: {
        1: {},
        2: {},
        3: {},
        4: {},
        5: {}
      },

      tabs: [
        { type: 1, label: 'Khách hàng mới' },
        { type: 2, label: 'Tiềm năng' },
        { type: 3, label: 'Chăm sóc' },
        { type: 4, label: 'Khách hàng DV' },
        { type: 5, label: 'Từ chối' }
      ],

      pageNum: {
        1: localStorePageNum('searchCustomerSupport', 'getCustomerSupports_1'),
        2: localStorePageNum('searchCustomerSupport', 'getCustomerSupports_2'),
        3: localStorePageNum('searchCustomerSupport', 'getCustomerSupports_3'),
        4: localStorePageNum('searchCustomerSupport', 'getCustomerSupports_4'),
        5: localStorePageNum('searchCustomerSupport', 'getCustomerSupports_5')
      },

      typeDefaultValue: {
        1: { label: 'Chờ gọi', value: 'PENDING' },
        2: { label: 'Chờ gọi', value: 'PENDING' },
        3: { label: 'Chờ gọi', value: 'PENDING' },
        4: { label: 'Chờ gọi', value: 'PENDING' },
        5: { label: 'Chờ gọi', value: 'PENDING' }
        
      },

      ownerDefaultValue: {
        1: { label: 'Chọn người tạo', value: 'all' },
        2: { label: 'Chọn người tạo', value: 'all' },
        3: { label: 'Chọn người tạo', value: 'all' },
        4: { label: 'Chọn người tạo', value: 'all' },
        5: { label: 'Chọn người tạo', value: 'all' }
        
      },

      states: [
        { label: 'Tất cả trạng thái', value: 'all' },
        { label: 'Chờ gọi', value: 'PENDING' },
        { label: 'Đã gọi', value: 'CALLED' },
        { label: 'Bỏ qua', value: 'SKIPPED' }
      ],

      dateRangeTitle: {
        1: dateRangeTitle1,
        2: dateRangeTitle2,
        3: dateRangeTitle3,
        4: dateRangeTitle4,
        5: dateRangeTitle5
        
      },

      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      },

      searchingOrder: {
        filters: {
          customer: {
            phone: ''
          }
        },
        paging: {
          start: 0,
          limit: 10
        }
      },

      searchingSimpleCallLog: {
        filters: {
          callerPhone: ''
        },
        paging: {
          start: 0,
          limit: PAGE_MAX_LIMIT
        }
      },
      
      searching: {
        1: customerSupportFilter1 || {
          filters: {
            listerPhone: '',
            listerName: '',
            information: {
              type: '1'
            },
            state: 'PENDING',
            createdAt: {
              from: '',
              to: ''
            }
          },
        
          paging: {
            start: 0,
            limit: PAGE_LIMIT
          }
        },
        2: customerSupportFilter2 || {
          filters: {
            listerPhone: '',
            listerName: '',
            information: {
              type: '2'
            },
            state: 'PENDING',
            createdAt: {
              from: '',
              to: ''
            }
          },
        
          paging: {
            start: 0,
            limit: PAGE_LIMIT
          }
        },
        3: customerSupportFilter3 || {
          filters: {
            listerPhone: '',
            listerName: '',
            information: {
              type: '3'
            },
            state: 'PENDING',
            createdAt: {
              from: '',
              to: ''
            }
          },
        
          paging: {
            start: 0,
            limit: PAGE_LIMIT
          }
        },
        4: customerSupportFilter4 || {
          filters: {
            listerPhone: '',
            listerName: '',
            information: {
              type: '4'
            },
            state: 'PENDING',
            createdAt: {
              from: '',
              to: ''
            }
          },
        
          paging: {
            start: 0,
            limit: PAGE_LIMIT
          }
        },
        5: customerSupportFilter5 || {
          filters: {
            listerPhone: '',
            listerName: '',
            information: {
              type: '5'
            },
            state: 'PENDING',
            createdAt: {
              from: '',
              to: ''
            }
          },
        
          paging: {
            start: 0,
            limit: PAGE_LIMIT
          }
        }
      }
    };

    this.onSearchChangeHandle = this.onSearchChangeHandle.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.handleDatePicker = this.handleDatePicker.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.callbackRemoveCustomerSupport = this.callbackRemoveCustomerSupport.bind(this);
    this.removeCustomerSupport = this.removeCustomerSupport.bind(this);
  }

  updateCustomerSupport(supportId) {
    this.props.history.push(`/sale-cus-modify/${supportId}`);
  }

  removeCustomerSupport(supportId) {
    confirmAlert({
      title: 'Xác nhận xóa thông tin hỗ trợ',
      buttons: [
        {
          label: 'Đồng ý',
          onClick: () => {
            const searching = Object.assign({}, this.state.searching);
            this.props.removeCustomerSupport(supportId, searching);
          }
        },
        { label: 'Hủy' }
      ]
    });
  }

  handleOpenModal(item, index, e) {
    e.preventDefault();
    const { showCallLogModal } = this.props;
    
    this.props.callLogDetail({
      filters: { id: [item.id] }
    });

    /**
     * Get History
     */
    const searchingSimpleCallLog = _.clone(this.state.searchingSimpleCallLog);
    const searchingOrder = _.clone(this.state.searchingOrder);

    searchingSimpleCallLog.filters.callerPhone = item.listerPhone;
    searchingOrder.filters.customer.phone = item.listerPhone;
    
    this.props.getSimpleCallLogs(searchingSimpleCallLog);
    this.props.getSimpleOrders(searchingOrder);

    // nvb edit
    // if (showCallLogModal === true) {
    //   store.dispatch({
    //     type: 'PUT_CALL_LOGS_MODAL',
    //     payload: false
    //   });
    // }

    // setTimeout(() => {
    //   store.dispatch({
    //     type: 'PUT_CALL_LOGS_MODAL',
    //     payload: true
    //   });
    // }, 100);
    store.dispatch({
      type: 'PUT_CALL_LOGS_MODAL',
      payload: true
    });
  }

  handleDatePicker(customerType, e, picker) {
    try {
      const searching = Object.assign({}, this.state.searching);
      const dateRangeTitle = Object.assign({}, this.state.dateRangeTitle);
      const pageNum = Object.assign({}, this.state.pageNum);
      const type = customerType.toString();
      searching[type].filters.createdAt = {
        from: '',
        to: ''
      };

      pageNum[type] = 1;
      dateRangeTitle[type] = formatDateFilter(picker.startDate, picker.endDate);
      const date = formatDateRange(picker.startDate, picker.endDate);

      this.setState({ searching, dateRangeTitle, pageNum });
      searching[type].filters.createdAt.from = date.startDate;
      searching[type].filters.createdAt.to = date.endDate;
      searching[type].paging = paging(this.state.pageNum[type], PAGE_LIMIT);
      this.props.getCustomerSupports(searching[type], type);
    } catch (error) {
      console.log(error);
    }
  }

  onSearchChangeHandle(customerType, key, e) {
    try {
      const type = customerType.toString();
      const searching = Object.assign({}, this.state.searching);
      const pageNum = Object.assign({}, this.state.pageNum);
      const value = _.get(e, 'target.value', _.get(e, 'value', false));
      pageNum[type] = 1;
      const result = {
        checkbox: {},
        pageNum
      };
      switch (key) {
        case 'phone': {
          const isNumber = validator.isNumeric(value);
          searching[type].filters.listerName = '';
          searching[type].filters.listerPhone = '';
  
          if (isNumber === true) {
            searching[type].filters.listerPhone = value;
          } else {
            searching[type].filters.listerName = value;
          }  
          break;
        }
        case 'state':
        {
          searching[type].filters.state = '';
          if (value !== 'all') {
            searching[type].filters.state = _.get(e, 'target.value', _.get(e, 'value', false));
          }
          result.typeDefaultValue = e;
          
          break;
        }

        case 'owner':
        {
          searching[type].filters.owner = '';
          if (value !== 'all') {
            searching[type].filters.owner = _.get(e, 'target.value', _.get(e, 'value', false));
          }
          result.ownerDefaultValue = e;
          
          break;
        }

        default: {
          searching[type].filters[key] = _.get(e, 'target.value', _.get(e, 'value', ''));
          break;
        }
      }
      searching[type].paging = paging(result.pageNum[type], PAGE_LIMIT);
      this.props.getCustomerSupports(searching[type], type);
      result.searching = searching;
      this.setState(result);
    } catch (error) {
      console.log(error);
    }
  }

  onChangePage(customerType, key) {
    try {
      const type = customerType.toString();
      const searching = Object.assign({}, this.state.searching);
      const pageNum = Object.assign({}, this.state.pageNum);
      
      switch (key) {
        case 'next': {
          pageNum[type] += 1;
          break;
        }
        default: {
          pageNum[type] -= 1;
          if (pageNum[type] <= 1) {
            pageNum[type] = 1;
          }
          break;
        }
      }
      
      searching[type].paging = paging(pageNum[type], PAGE_LIMIT);
      this.props.getCustomerSupports(searching[type], type);      
      this.setState({ pageNum });
    } catch (error) {
      console.log(error);
    }
  }

  clickToCall(phone) {
    const { init } = this.props;
    const extension = _.get(init, 'accountInfo.voip.extension', false);
    this.props.clickToCall({
      extension,
      phone
    });
  }

  renderCustomerSupport(customerType) {
    try {
      const { init } = this.props;
      const { customerSupportByType } = this.state;
      const type = customerType.toString();

      const supports = _.get(customerSupportByType, type, false);
      
      if (_.isEmpty(supports) === true) {
        return (<tr></tr>);
      }
      
      const listCustomerSource = this.props.buildCustomerSource();
      const extension = _.get(init, 'accountInfo.voip.extension', false);

      const result = [];
      let date = '';
      supports.forEach((element) => {
        const createdAt = formatDate2(element.createdAt);
        if (createdAt !== date) {
          date = createdAt;
          result.push({ createdAt });
        }
        result.push(element);
      });
      
      return (result.map((item, i) => {
        if (!item.id) {
          return (<tr key={i} className="table-active table-border-double">
            <td colSpan="11" className="font-weight-semibold">Lịch sử gọi ngày {item.createdAt}</td>
          </tr>);
        }

        const state = _.find(this.state.states, { value: item.state });
        const service = this.props.getServiceDetail(_.get(item, 'information.service', ''));
        const source = _.find(listCustomerSource, { value: _.get(item, 'information.source', '') });
        const minutes = Math.floor(_.get(item, 'duration', 0) / 60);
        const hasDeleteCustomerSupportPermission = checkScope(CUSTOMER_SUPPORT_DELETE_SCOPE, this.props);
        const hasModifyCustomerSupportPermission = checkScope(CUSTOMER_SUPPORT_UPDATE_SCOPE, this.props);

        const isBussiness = _.get(item, 'information.isBussiness', null);
        let listerRecordedUrl = _.get(item, 'listerRecordedUrl', '');
        
        if (formatDate2(new Date()) !== formatDate2(item.createdAt) && listerRecordedUrl) {
          listerRecordedUrl = listerRecordedUrl.replace('.wav', '.ogg');
        }

        return (<tr key={`renderCallLog_${i}`}>
          <td>{item.id} </td>
          <td>
            {extension ? <button
              onClick={this.clickToCall.bind(this, item.listerPhone)} 
              className="btn btn-default">
              <i className="fa fa-phone"></i>
            </button> : ''}

            {/* {hasModifyCustomerSupportPermission ? <button
              style={{ marginLeft: 5 }} 
              onClick={this.updateCustomerSupport.bind(this, item.id)} 
              className="btn btn-default">
              <i className="fa fa-edit"></i>
            </button> : ''} */}

            {hasDeleteCustomerSupportPermission ? <button
              style={{ marginLeft: 5 }} 
              onClick={this.removeCustomerSupport.bind(this, item.id)} 
              className="btn btn-default">
              <i className="fa fa-remove"></i>
            </button> : ''}
          </td>
          <td>{state.label}
            <br />
            <div className="text-muted font-size-sm">
              {item.trunkId === 1 ? '028.7777.7979' : '028.77777.888'}
            </div>
          </td>
          <td>{isBussiness ? <span style={{ border: 0, backgroundColor: '#1baf73' }} className="badge badge-mark mr-1"></span> : ''}  {_.get(item, 'listerName', '')}</td>
          <td><a 
            href="#"
            onClick={this.handleOpenModal.bind(this, item, item.id)} 
          >
            {_.get(item, 'listerPhone', '')}
          </a></td>
          <td>{_.get(source, 'title', '')}</td>
          <td>{_.get(service, 'label', '')}</td>
          <td>{_.get(item, 'information.note', '')}</td>
          <td>{_.get(item, 'account.title', '-')}</td>
          
        </tr>);
      }));
    } catch (error) {
      return (<tr></tr>);
    }
  }

  renderSearching(customerType) {
    const {
      searching, ranges, dateRangeTitle, typeDefaultValue, ownerDefaultValue, states
    } = this.state;

    if (_.isEmpty(searching) === true) {
      return <div></div>;
    }
    
    const type = customerType.toString();
    const phone = _.get(searching[type], 'filters.listerName', '') || _.get(searching[type], 'filters.listerPhone', '');

    const state = _.find(states, { value: _.get(searching[type], 'filters.state', 'all') }) || typeDefaultValue[type];
    
    return <div className="row">
      <div className="form-group col-md-3">
        <DelayInput
          value={phone}
          type="text"
          className="form-control"
          minLength={0}
          delayTimeout={300}
          placeholder="Nhập tên hoặc số điện thoại"
          onChange={this.onSearchChangeHandle.bind(this, type, 'phone')}
        />

      </div>

      <div className="form-group col-md-3">
        <Select
          value={state}
          onChange={this.onSearchChangeHandle.bind(this, type, 'state')}
          options={states || []}
        />
      </div>

      <div className="form-group col-md-3">
        <Select
          isDisabled
          value={ownerDefaultValue[type]}
          onChange={this.onSearchChangeHandle.bind(this, type, 'owner')}
          options={this.props.listMembers() || []}
        />
      </div>

      <div className="form-group col-md-3">
        <DateRangePicker
          className="btn btn-light daterange-predefined"
          onApply={this.handleDatePicker.bind(this, type)}
          ranges={ranges}
        >
          <button style={{ padding: '7px 15px' }}>
            <i className="icon-calendar22 mr-2"></i>
            <span> {dateRangeTitle[type]} </span>
          </button>
        </DateRangePicker>

      </div>

    </div>;
  }

  renderGrid(customerType) {
    const type = customerType.toString();
    return <div className="table-responsive">
      <table className="table table-bordered table-striped text-nowrap">
        <thead>
          <tr>
            <th>ID</th>
            <th>Thực thi</th>
            <th>Trạng thái</th>
            <th>Tên khách hàng</th>
            <th>Số điện thoại</th>
            <th>Nguồn khách hàng</th>
            <th>Dịch vụ</th>
            <th>Ghi chú</th>
            <th>Người tạo</th>
            
          </tr>
        </thead>
        <tbody>
          {this.renderCustomerSupport(type)}
        </tbody>
      </table>

      <PagingContainer
        count={_.get(this.state, `customerSupportByType.${type}`, []).length}
        pageNum={this.state.pageNum[type]}
        limit={PAGE_LIMIT}
        onClick={this.onChangePage.bind(this, type)}
      />
    </div>;
  }

  renderTabs() {
    const { tabs } = this.state;
    if (_.isEmpty(tabs) === true) {
      return (<Tab eventKey="tab0" title=""></Tab>);
    }
    return (tabs.map((tab) => {
      const tabLength = _.get(this.state, `customerSupportByType.${tab.type}`, []).length;
      const count = tabLength === PAGE_LIMIT 
        ? `${PAGE_LIMIT}+` 
        : _.get(this.state, `customerSupportByType.${tab.type}`, []).length;
      return (
        <Tab key={`tab${tab.type}`} eventKey={`tab${tab.type}`} title={`${tab.label} (${count})`}>
          {this.renderSearching(tab.type)}
          {this.renderGrid(tab.type)}
        </Tab>);
    }));
  }

  render() {
    const hasAddPermisstion = checkScope(['cs.manage'], this.props);
    return (
      <div>
        <div className="card">
          <div className="card-header bg-transparent header-elements-inline">
            <h4 className="card-title">Chăm sóc khách hàng</h4>
            {hasAddPermisstion ? <Link
              style={{ float: 'right' }}
              className="btn bg-teal-400"
              to="/sale-cus-create" >
              <i className="icon-add mr-2"></i> Thêm mới
            </Link> : ''}
          </div>

          <div className="card-body">
            <Tabs defaultActiveKey="tab1" style={{ padding: '20px 10px 0px 10px' }}>
              {this.renderTabs()}
            </Tabs>
          </div>
        </div>
      </div>

    );
  }

  callbackRemoveCustomerSupport() {
    try {
      const { customerSupports, socketRemoveCustomerSupport } = this.props;
      if (_.isEmpty(socketRemoveCustomerSupport) === false) {
        const result = [];
        _.forEach(customerSupports, (customerSupport) => {
          if (customerSupport.listerPhone !== socketRemoveCustomerSupport.phone) {
            result.push(customerSupport); 
          }
        });
        
        this.props.dispatch({
          type: 'POST_SEARCH_CUSTOMER_SUPPORT_SUCCESS',
          payload: result
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  componentDidUpdate(oldProps) {
    try {
      /**
       * 
       */
      // 
      const newProps = this.props;
      if (oldProps.socketRemoveCustomerSupport !== newProps.socketRemoveCustomerSupport) {
        this.callbackRemoveCustomerSupport();
      }

      if (oldProps.customerSupportByType !== newProps.customerSupportByType) {
        const customerSupportByType = Object.assign({}, this.state.customerSupportByType);
        const { type, data } = newProps.customerSupportByType;
        customerSupportByType[type] = data;
        this.setState({ customerSupportByType });
      }
    } catch (error) {
      console.log(error);
      /**
       * 
       */
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      localStoreFilter: payload.generalReducer.localStoreFilter,
      listScope: payload.generalReducer.listScope,
      pickupCallLogs: payload.callLogReducer.pickupCallLogs,
      simpleOrders: payload.orderReducer.simpleOrders,
      showCallLogModal: payload.callLogReducer.showCallLogModal,
      customerSupports: payload.supportReducer.customerSupports,
      customerSupportByType: payload.supportReducer.customerSupportByType,
      socketRemoveCustomerSupport: payload.supportReducer.socketRemoveCustomerSupport,
      buildServices: payload.serviceReducer.buildServices,
      init: payload.generalReducer.init,
      userInfo: payload.generalReducer.userInfo,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(SearchCustomerSupport)
);
