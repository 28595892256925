import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { bindActionCreators } from 'redux';
import CreatePobo from './createPobo';
import * as CoboPoboActions from '../../../redux/coboPobo/coboPobo.actions';
import { SYSTEM_COBO_POBO, SYSTEM_SERVICE_SCOPE } from '../../../configs/constants';
import {
  checkScope
} from '../../../helpers';
import * as AgenciesActions from '../../../redux/agencies/agencies.actions';

class CreateReceiptVoucherContainer extends React.Component {
  constructor(prop) {
    super(prop);
    if (checkScope(SYSTEM_COBO_POBO, this.props) === false) {
      NotificationManager.error('Chức năng không tồn tại', '', 1500);
      setTimeout(() => {
        this.props.history.push('/dashboard');
      }, 2000);
    }

    this.state = {
      errorMessage: {},
      result: []
    };
    this.callback = this.callback.bind(this);
    this.callbackNew = this.callbackNew.bind(this);
    this.listMembers = this.listMembers.bind(this);
  }

  getMembers(args) {
    try {
      const { agenciesAct } = this.props;
      agenciesAct.searchAgency(args);
    } catch (error) {
      console.log('error', error);
    }
  }

  listMembers() {
    const { agencies: accounts } = this.props;
    const listMember = [];
    try {
      if (_.isEmpty(accounts) === false) {
        accounts.forEach((item) => {
          listMember.push({ value: item.id, label: item.title, scopes: item.scopes });
        });
      }
      return listMember;
    } catch (error) {
      return listMember;
    }
  }

  componentWillMount() {
    const filter = {
      filters: {
        group: ['agency', 'collaborator', 'supplier', 'staff', 'customer']
      },
      select: ['id', 'title', 'scopes'],
      paging: { start: 0, limit: 1000 }
    };

    this.getMembers(filter);
  }

  onSubmit = async (args, type) => {
    try {
      const { coboPoboAct } = this.props;
      args.sourceId = args.sourceId.value;
      if (type === 'addNew') {
        coboPoboAct.create(args, this.callbackNew);
      } else {
        coboPoboAct.create(args, this.callback);
      }
    } catch (error) {
      // console.log('error', error);
    }
  }

  callback(status, data) {
    if (status) {
      NotificationManager.info('Tạo phiếu thành công, đang chuyển về trang quản lý', '', 1000);
      if (_.isArray(data.result)) {
        this.setState({
          result: data
        });
      } else {
        setTimeout(() => {
          this.props.history.push('/cobo-pobo');
        }, 1500);
      }
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }

  callbackNew(status, data) {
    if (status) {
      NotificationManager.info('Tạo phiếu thành công, đang chuyển về trang quản lý', '', 1000);
      setTimeout(() => {
        window.location = '/cobo-pobo/pobo-create';
      }, 1500);
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }
  
  render() {
    return (
      <div>
        <CreatePobo
          errorMessage={this.state.errorMessage}
          onSubmit={this.onSubmit}
          userInfo={this.userInfo}
          result={this.state.result}
          listMembers={this.listMembers}
        />
          
      </div>
        
    );
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    coboPoboAct: bindActionCreators(CoboPoboActions, dispatch),
    agenciesAct: bindActionCreators(AgenciesActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    agencies: rootState.agencyReducer.agencies,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(CreateReceiptVoucherContainer);
