import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Login extends React.Component {
  static contextTypes = {
    t: PropTypes.func.isRequired
  }
  
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const Component = this.props.component;
    const clss = 'container-fluid login-page';
    const { route } = this.props;
    return (
      <div className={clss}>
        <Component route={route} {...this.props} />
      </div>
    );
  }

  componentDidMount() {
    const { location, lang } = this.props;
    const langVal = _.get(location, 'search', '') !== '' ? location.search.substring(6) : '';

    if (langVal !== '' && langVal !== lang) {
      if (langVal === 'vi' || langVal === 'en') {
        this.onChangeLanguage(langVal);
      }
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      userInfo: payload.generalReducer.userInfo,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(Login)
);
