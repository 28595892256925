import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ModifyCustomerSupport from './modifyCustomerSupport';

import * as SupportActions from '../../../redux/supports/supports.actions';
import * as CallLogActions from '../../../redux/callLogs/callLogs.actions';
import * as ServiceActions from '../../../redux/services/services.actions';
import * as GeneralActions from '../../../redux/general/general.actions';
import { CUSTOMER_SUPPORT_UPDATE_SCOPE } from '../../../configs/constants';

import {
  serviceDetail, serviceHandleDatas, checkScope, 
  buildListLocation, buildListCustomerType, buildListCustomerSource
} from '../../../helpers';

class ModifyCustomerSupportContainer extends React.Component {
  constructor(prop) {
    super(prop);

    if (checkScope(CUSTOMER_SUPPORT_UPDATE_SCOPE, this.props) === false) {
      NotificationManager.error('Chức năng không tồn tại', '', 1500);
      setTimeout(() => {
        this.props.history.push('/dashboard');
      }, 2000);
    }

    this.state = {
      buildServices: []
    };
    //
    this.onSubmit = this.onSubmit.bind(this);
    this.callback = this.callback.bind(this);
    this.getCallLogs = this.getCallLogs.bind(this);
    this.callLogDetail = this.callLogDetail.bind(this);
    this.buildLocation = this.buildLocation.bind(this);
    this.buildCustomerSource = this.buildCustomerSource.bind(this);
    this.buildCustomerType = this.buildCustomerType.bind(this);
    this.listServiceHandleData = this.listServiceHandleData.bind(this);
    this.getServiceDetail = this.getServiceDetail.bind(this);
    this.getDetailCustomerSupport = this.getDetailCustomerSupport.bind(this);
    this.callbackGetDetailCustomerSupport = this.callbackGetDetailCustomerSupport.bind(this);
  }

  getDetailCustomerSupport() {
    try {
      const { supportAct } = this.props;
      const id = this.props.computedMatch.params.id;
      const args = {
        filters: { id: [id] }
      };
      supportAct.CustomerSupportDetail(args, this.callbackGetDetailCustomerSupport);
    } catch (error) {
      // console.log('error', error);
    }
  }

  callbackGetDetailCustomerSupport(status) {
    try {
      if (!status) {
        NotificationManager.error('Thông tin không hợp lệ', '', 1500);
        setTimeout(() => {
          this.props.history.push('/sale-cus');
        }, 1500);
      } 
    } catch (error) {
      // console.log(error);
    }
  }

  onSubmit = async (args) => {
    try {
      const { supportAct } = this.props;
      const query = Object.assign({}, args);
      delete query.information.subType;
      delete query.callerRecordedUrl;
      return supportAct.modifyCustomerSupport(query, this.callback);
    } catch (error) {
      return {};
      // console.log('error', error);
    }
  }

  callback(status, data) {
    if (status) {
      NotificationManager.info('Cập nhật thông tin hỗ trợ thành công', '', 1000);
      setTimeout(() => {
        this.props.history.push('/sale-cus');
      }, 1500);
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }

  /**
   * Call Logs
   */
  getCallLogs(args) {
    try {
      const { callLogAct } = this.props;
      callLogAct.listPickupCallLog(args);
    } catch (error) {
      // console.log('error', error);
    }
  }

  callLogDetail(args) {
    try {
      const { callLogAct } = this.props;
      callLogAct.callLogDetail(args);
    } catch (error) {
      console.log('error', error);
    }
  }

  /**
   * 
   */
  getServiceDetail(serviceIdentifyCode) {
    try {
      const { buildServices } = this.props;
      if (_.isEmpty(buildServices) === false) {
        return serviceDetail(buildServices, serviceIdentifyCode);
      }
      return {};
    } catch (error) {
      return {};
    }
  }

  /**
   * 
   */

  listServiceHandleData() {
    try {
      const result = { list: [], setlectedItem: {} };
      const { buildServices } = this.props;

      if (_.isEmpty(buildServices) === false) {
        return serviceHandleDatas(buildServices);
      }
      return result;
    } catch (error) {
      return false;
    }
  }

  buildLocation() {
    const { init } = this.props;
    if (init.area) {
      return buildListLocation(init);
    }
    return [];
  }

  buildCustomerType() {
    const { init } = this.props;
    if (init.customerType) {
      return buildListCustomerType(init);
    }
    return [];
  }

  buildCustomerSource() {
    const { init } = this.props;
      
    if (init.customerSource) {
      return buildListCustomerSource(init);
    }
    return [];
  }

  render() {
    const {
      buildServices 
    } = this.state;

    return (
      <div>
        <ModifyCustomerSupport
          onSubmit={this.onSubmit}
          buildServices={buildServices}
          getCallLogs={this.getCallLogs}
          getOrders={this.getOrders}
          callLogDetail={this.callLogDetail}
          buildLocation={this.buildLocation}
          buildCustomerSource={this.buildCustomerSource}
          buildCustomerType={this.buildCustomerType}
          listServiceHandleData={this.listServiceHandleData}
          getServiceDetail={this.getServiceDetail}
          clickToCall={this.clickToCall}
        />
        <NotificationContainer />
      </div>
        
    );
  }

  componentWillMount() {
    try {
      this.getDetailCustomerSupport();
    } catch (error) {
      // console.log(error);
    }
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    supportAct: bindActionCreators(SupportActions, dispatch),
    generalAct: bindActionCreators(GeneralActions, dispatch),
    callLogAct: bindActionCreators(CallLogActions, dispatch),
    serviceAct: bindActionCreators(ServiceActions, dispatch)
    //
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    buildServices: rootState.serviceReducer.buildServices,
    init: rootState.generalReducer.init,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(ModifyCustomerSupportContainer);
