import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as responseCode from '../../configs/responseCode.config';
import { types } from '../../redux/wallets';
import { walletAPI } from '../../api';
import { types as apiTypes } from '../../redux/api';

function* doAction(action) {
  try {
    // yield put({ type: apiTypes.SHOW_LOADING });
    const { code, data } = yield call(walletAPI.postSearchWalletHistory, action.payload);
    // yield put({ type: apiTypes.HIDE_LOADING });
    switch (code) {
      case responseCode.POST_WALLET_HISTORY_CREDIT_SUCCESS: {
        yield put({ type: types.POST_WALLET_HISTORY_CREDIT_SUCCESS, payload: data });
        if (action.callback) {
          action.callback(true, data);
        }
        break;
      }
      default: {
        yield put({ type: types.POST_WALLET_HISTORY_CREDIT_FAIL });
        if (action.callback) {
          action.callback(false, data);
        }
      }
    }
  } catch (error) {
    yield put({ type: apiTypes.HIDE_LOADING });
    yield put({ type: types.POST_WALLET_HISTORY_CREDIT_FAIL });
  }
}

export default function* watchAction() {
  yield takeLatest(types.POST_WALLET_HISTORY_CREDIT, doAction);
}

