import React from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import CreateAgency from './createAgency';
import * as AccountActions from '../../../redux/accounts/accounts.actions';
import * as AgencyActions from '../../../redux/agencies/agencies.actions';
import { AGENCY_CREATE_SCOPE } from '../../../configs/constants';

import {
  checkScope
} from '../../../helpers';

class CreateAgencyContainer extends React.Component {
  constructor(prop) {
    super(prop);

    if (checkScope(AGENCY_CREATE_SCOPE, this.props) === false) {
      NotificationManager.error('Chức năng không tồn tại', '', 1500);
      setTimeout(() => {
        this.props.history.push('/dashboard');
      }, 2000);
    }

    this.state = {
      listMember: [],
      listLocation: [],
      sendSMSInfo: {}
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.callback = this.callback.bind(this);
    //
    this.renderDelegate = this.renderDelegate.bind(this);
    this.buildDiscount = this.buildDiscount.bind(this);
  }

  onSubmit = async (args) => {
    try {
      const { agencyAct } = this.props;

      this.setState({ 
        sendSMSInfo: {
          phone: args.phone,
          password: args.rePassword
          
        } 
      });

      delete args.rePassword;
      agencyAct.createAgency(args, this.callback);
    } catch (error) {
      // console.log('error', error);
    }
  }

  callback(status, data) {
    if (status) {
      NotificationManager.info('Tạo tài khoản khách hàng thành công', '', 1000);

      const { sendSMSInfo } = this.state;
      if (_.isEmpty(sendSMSInfo) === false) {
        this.props.dispatch({
          type: 'SEND_SMS',
          payload: {
            phone: sendSMSInfo.phone,
            password: sendSMSInfo.password,
            type: 'create'
          }
        });
      }

      setTimeout(() => {
        this.props.history.push('/agency');
      }, 1500);
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }

  getDelegates(args) {
    try {
      const { accountAct } = this.props;
      accountAct.searchByKeywordAccount(args);
    } catch (error) {
      // console.log('error', error);
    }
  }

  renderDelegate(data) {
    const result = [];
    try {
      if (_.isEmpty(data) === false) {
        _.forEach(data, (item) => {
          result.push({ value: item.identifyCode, label: item.title });
        });
      }
      return result;
    } catch (error) {
      return result;
      // console.log('error', error);
    }
  }

  /**
   * 
   */
  buildDiscount() {
    const { init } = this.props;
    const listDiscount = [{ value: '', label: 'Mặt định (dựa vào số lượng HĐ)' }];
    if (init.discount) {
      init.discount.forEach((element) => {
        listDiscount.push({ value: element.key, label: element.title });
      });
    }
    return listDiscount;
  }

  /**
   * 
   */

  render() {
    const { listLocation } = this.state;
    return (
      <div>
        <CreateAgency
          onSubmit={this.onSubmit}
          renderDelegate={this.renderDelegate}
          errorMessage={this.state.errorMessage}
          userInfo={this.userInfo}
          listLocation={listLocation}
          buildDiscount={this.buildDiscount}
        />
        <NotificationContainer />
      </div>

    );
  }

  buildLocation(data) {
    if (data) {
      const listLocation = [{ text: 'Tất cả khu vực', id: 'all' }];
      const mapLocations = {};
      data.forEach((element) => {
        if (!mapLocations[element.tag]) {
          mapLocations[element.tag] = [];
        }
        mapLocations[element.tag].push({ text: element.value, id: element.key });
      });

      Object.keys(mapLocations).forEach((key) => {
        const location = {
          text: key,
          children: []
        };
        mapLocations[key].forEach((item) => {
          location.children.push(item);
        });
        listLocation.push(location);
      });
      this.setState({ listLocation });
    }
  }

  componentDidMount() {
    const { init } = this.props;
    this.buildLocation(init.area);
    this.getDelegates({
      filters: {
        group: ['staff']
      }
    });
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    agencyAct: bindActionCreators(AgencyActions, dispatch),
    accountAct: bindActionCreators(AccountActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    showLoading: rootState.apiReducer.showLoading,
    init: rootState.generalReducer.init,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(CreateAgencyContainer);
