import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import Select from 'react-select';
import chroma from 'chroma-js';

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',
  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10
  }
});

const colourStyles = {
  control: styles => ({
    ...styles, backgroundColor: 'white', minWidth: 200, width: '100%'
  }),
  option: (styles, {
    data, isDisabled, isFocused, isSelected
  }) => {
    const color = chroma(data.color);
    let backgroundColor = null;
    let colorStyle = data.color;

    if (isDisabled) {
      backgroundColor = null;
      colorStyle = '#ccc';
    } else if (isSelected) {
      backgroundColor = data.color;
      colorStyle = chroma.contrast(color, 'white') > 2 ? 'white' : 'black';
    } else if (isFocused) {
      backgroundColor = color.alpha(0.1).css();
    }
    return {
      ...styles,
      ...dot(data.color),
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css())
      }
    };
  },
  input: styles => ({ ...styles, ...dot() }),
  placeholder: styles => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) })
};

class SearchServiceContainer extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = {};
  }

  render() {
    if (_.isEmpty(this.props.options) === false) {
      return (
        <div>
          <Select
            styles={colourStyles}
            isDisabled={this.props.isDisabled}
            value={this.props.value}
            onChange={this.props.onChange.bind(this)}
            options={this.props.options}
            placeholder={this.props.placeholder}
          />
        </div>
          
      );
    }
    return (<div>
      <Select
        isDisabled={this.props.isDisabled}
        value={this.props.value}
        onChange={this.props.onChange.bind(this)}
        options={this.props.options}
        placeholder={this.props.placeholder}
      />
    </div>);
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    //
  };
}

export default connect((rootState) => {
  return {
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(SearchServiceContainer);
