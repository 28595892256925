
/**
 * CRUD
 */

export const POST_ARRIVAL_ORDER_TAG_SEARCH = 'POST_ARRIVAL_ORDER_TAG_SEARCH';
export const POST_ARRIVAL_ORDER_TAG_SEARCH_SUCCESS = 'POST_ARRIVAL_ORDER_TAG_SEARCH_SUCCESS';
export const POST_ARRIVAL_ORDER_TAG_SEARCH_FAIL = 'POST_ARRIVAL_ORDER_TAG_SEARCH_FAIL';

export const POST_SEARCH_SIMPLE_ARRIVAL_ORDER = 'POST_SEARCH_SIMPLE_ARRIVAL_ORDER';
export const POST_SEARCH_SIMPLE_ARRIVAL_ORDER_SUCCESS = 'POST_SEARCH_SIMPLE_ARRIVAL_ORDER_SUCCESS';
export const POST_SEARCH_SIMPLE_ARRIVAL_ORDER_FAIL = 'POST_SEARCH_SIMPLE_ARRIVAL_ORDER_FAIL';

export const POST_SEARCH_ARRIVAL_ORDER = 'POST_SEARCH_ARRIVAL_ORDER';
export const POST_SEARCH_ARRIVAL_ORDER_SUCCESS = 'POST_SEARCH_ARRIVAL_ORDER_SUCCESS';
export const POST_SEARCH_ARRIVAL_ORDER_FAIL = 'POST_SEARCH_ARRIVAL_ORDER_FAIL';

export const POST_ARRIVAL_ORDER_DETAIL = 'POST_ARRIVAL_ORDER_DETAIL';
export const POST_ARRIVAL_ORDER_DETAIL_SUCCESS = 'POST_ARRIVAL_ORDER_DETAIL_SUCCESS';
export const POST_ARRIVAL_ORDER_DETAIL_FAIL = 'POST_ARRIVAL_ORDER_DETAIL_FAIL';

export const SOCKET_ARRIVAL_ORDER = 'SOCKET_ARRIVAL_ORDER';

export const POST_ARRIVAL_ORDER = 'POST_ARRIVAL_ORDER';
export const POST_ARRIVAL_ORDER_SUCCESS = 'POST_ARRIVAL_ORDER_SUCCESS';
export const POST_ARRIVAL_ORDER_FAIL = 'POST_ARRIVAL_ORDER_FAIL';

export const PUT_ARRIVAL_ORDER = 'PUT_ARRIVAL_ORDER';
export const PUT_ARRIVAL_ORDER_SUCCESS = 'PUT_ARRIVAL_ORDER_SUCCESS';
export const PUT_ARRIVAL_ORDER_FAIL = 'PUT_ARRIVAL_ORDER_FAIL';

export const PUT_ARRIVAL_ORDER_STATE = 'PUT_ARRIVAL_ORDER_STATE';
export const PUT_ARRIVAL_ORDER_STATE_SUCCESS = 'PUT_ARRIVAL_ORDER_STATE_SUCCESS';
export const PUT_ARRIVAL_ORDER_STATE_FAIL = 'PUT_ARRIVAL_ORDER_STATE_FAIL';

export const PUT_ARRIVAL_ORDER_PROCESS = 'PUT_ARRIVAL_ORDER_PROCESS';
export const PUT_ARRIVAL_ORDER_PROCESS_SUCCESS = 'PUT_ARRIVAL_ORDER_PROCESS_SUCCESS';
export const PUT_ARRIVAL_ORDER_PROCESS_FAIL = 'PUT_ARRIVAL_ORDER_PROCESS_FAIL';

export const PUT_ARRIVAL_ORDER_PIN = 'PUT_ARRIVAL_ORDER_PIN';
export const PUT_ARRIVAL_ORDER_PIN_SUCCESS = 'PUT_ARRIVAL_ORDER_PIN_SUCCESS';
export const PUT_ARRIVAL_ORDER_PIN_FAIL = 'PUT_ARRIVAL_ORDER_PIN_FAIL';

export const DELETE_ARRIVAL_ORDER = 'DELETE_ARRIVAL_ORDER';
export const DELETE_ARRIVAL_ORDER_SUCCESS = 'DELETE_ARRIVAL_ORDER_SUCCESS';
export const DELETE_ARRIVAL_ORDER_FAIL = 'DELETE_ARRIVAL_ORDER_FAIL';
