import _ from 'lodash';
import * as types from './general.types';

const initialState = {
  uploadPath: 'https://upload.vnhub.com',
  downloadPath: 'https://static.vnhub.com',
  localStoreFilter: [],
  passiveMenuInfo: [],
  init: {},
  profiles: [],
  listScope: [],
  userInfo: {},
  activeMenu: {},
  menuInfo: []
};

export default function general(state = initialState, action) {
  switch (action.type) {
    case 'REDUX_SET_ORDER_MENU_INFO': {
      const passiveMenuInfo = initialState.passiveMenuInfo;
      if (!_.find(passiveMenuInfo, { id: action.val.id })) {
        passiveMenuInfo.push(action.val);
      }
      return {
        ...state,
        passiveMenuInfo
      };
    }
    case 'REDUX_SET_ACTIVE_MENU': {
      return {
        ...state,
        activeMenu: action.val
      };
    }
    case types.GET_INIT_SUCCESS: {
      return {
        ...state,
        init: action.payload
      };
    }

    case types.POST_PROFILE_SEARCH_SUCCESS: {
      return {
        ...state,
        profiles: action.payload
      };
    }

    case types.GET_SCOPE_SUCCESS: {
      return {
        ...state,
        listScope: action.payload
      };
    }

    case types.POST_ACCOUNT_LOGIN_SUCCESS: {
      return {
        ...state,
        userInfo: { ...state.userInfo, ...action.payload }
      };
    }
    
    case types.POST_ACCOUNT_UPDATE_INFO_SUCCESS: {
      return {
        ...state,
        userInfo: { ...state.userInfo, ...action.payload }
      };
    }

    case types.POST_ACCOUNT_MENU_SUCCESS: {
      return {
        ...state,
        menuInfo: [...action.payload]
      };
    }

    case types.LOCAL_STORE_FILTER: {
      const payload = _.clone(action.payload);
      // const localStoreFilter = localStorage.getItem('localStoreFilter') || [];

      let localStoreFilter = [];
      const localStoreFilterStr = localStorage.getItem('localStoreFilter');
      if (_.isEmpty(localStoreFilterStr) === false) {
        localStoreFilter = JSON.parse(localStoreFilterStr);
      }

      const filterIndex = _.findIndex(localStoreFilter, { objID: payload.objID, objType: payload.objType });
      if (filterIndex === -1) {
        localStoreFilter.push({
          objID: payload.objID,
          objType: payload.objType,
          query: payload.query
        });
      } else {
        localStoreFilter[filterIndex] = {
          objID: payload.objID,
          objType: payload.objType,
          query: payload.query
        };
      }      
      localStorage.setItem('localStoreFilter', JSON.stringify(localStoreFilter));
      return {
        ...state
      };
    }

    case types.POST_ACCOUNT_LOGOUT_SUCCESS:
    case types.POST_ACCOUNT_INFO_FAIL:
    case types.ERROR_401: {
      localStorage.removeItem('localStoreFilter');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('init');
      return {
        ...initialState
      };
    }

    default:
      return state;
  }
}
