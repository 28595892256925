import { API_URL } from '../../configs/api.config';
import Request from '../middleware/request';

const agencyAPI = {
  /**
   * 
   */
  postCreateAgency(args) {
    return Request.callAPI('post', `${API_URL}/Account`, { ...args });
  },
  putModifyAgency(args) {
    return Request.callAPI('put', `${API_URL}/Account/${args.id}`, { ...args.data });
  },
  postSearchAgency(args) {
    return Request.callAPI('post', `${API_URL}/Account/Search`, { ...args });
  },
  postSearchByKeywordAgency(args) {
    return Request.callAPI('post', `${API_URL}/Account/SearchByKeyword`, { ...args });
  }
};

export default agencyAPI;

