import React from 'react';
import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import Select from 'react-select';
import {
  localStoreFilter
} from '../../../helpers';

class SearchDepartment extends React.Component {
  constructor(props) {
    super(props);
    const departmentFilter = localStoreFilter('SearchDepartment', 'getDepartments');
    this.state = {
      searching: departmentFilter || {
        filter: {
          type: 'department'
        }
      }
    };
    
    this.remove = this.remove.bind(this);
    this.onChangeHandle = this.onChangeHandle.bind(this);
  }

  onChangeHandle(key, e) {
    try {
      const searching = Object.assign([], this.state.searching);
      const value = _.get(e, 'target.value', _.get(e, 'value', false));
      
      if (value) {
        switch (key) {
          case 'branch':
            searching.filter.parentIdentifyCode = value;
            if (value === 'all') {
              searching.filter.parentIdentifyCode = '';
            }
            break;
      
          default:
            break;
        }
      }
      this.setState({ searching });
      this.props.getDepartments(searching);
    } catch (error) {
      // console.log(error);
    }
  }

  remove(id) {
    this.props.remove(id);
  }

  renderDepartment() {
    const { departments, listScope } = this.props;

    if (_.isEmpty(departments) === true) {
      return (<tr></tr>);
    }
    return (departments.map((item, i) => {
      const linkTo = `/department-modify/${item.id}`;
      const scopes = [];
      const parent = _.find(item.parentPath, { identifyCode: item.parentIdentifyCode });
      
      if (_.isEmpty(item.scopes) === false) {
        item.scopes.forEach((scope) => {
          const scopeInfo = _.find(listScope, { code: scope });
          if (_.isEmpty(scopeInfo) === false) {
            scopes.push(scopeInfo.description);
          }
        });
      }
      return (<tr key={i}>
        <td>
          <div className="d-flex align-items-center">
            <div>
              <div className="media-title font-weight-semibold">
                {_.get(parent, 'title', '')}
              </div>
            </div>
          </div>
        </td>
        <td>
          <div className="d-flex align-items-center">
            <div>
              <div className="media-title font-weight-semibold">
                <Link to={linkTo}>{item.title}</Link>
              </div>
            </div>
          </div>
        </td>
        <td>
          <span title={scopes.join(',')} className="text-muted">
            <div className="cut-text">
              {scopes.join(', ')}
            </div>
           
          </span>
        </td>
        <td>{item.description}</td>
        <td>
          <Dropdown>
            <Dropdown.Toggle variant={'default'} >
              <i className="fa fa-list-ul" aria-hidden="true"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={this.remove.bind(this, item.id)} eventKey={item.id} >
                <i className="fa fa-remove" aria-hidden="true"></i> Xóa
              </Dropdown.Item>
              <Dropdown.Item onClick={() => this.props.history.push(linkTo)} >
                <i className="fa fa-edit" aria-hidden="true"></i> Cập nhật
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>);
    }));
  }

  render() {
    const { listBranch } = this.props;
    const { searching } = this.state;

    let branch = { value: 'all', label: 'Tất cả chi nhánh' };
    if (_.isEmpty(searching.filter) === false) {
      const parentIdentifyCode = _.get(searching, 'filter.parentIdentifyCode', '');
      if (parentIdentifyCode) {
        branch = _.find(listBranch, { value: searching.filter.parentIdentifyCode }) || { value: 'all', label: 'Tất cả chi nhánh' };
      }
    }
    return (
      <div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header bg-transparent header-elements-inline">
                <h4 className="card-title">Danh sách Phòng Ban</h4>
                <Link
                  style={{ float: 'right' }}
                  className="btn bg-teal-400"
                  to="/department-create" >
                  <i className="icon-add mr-2"></i> Thêm mới
                </Link>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="form-group col-md-3">
                    <Select
                      onChange={this.onChangeHandle.bind(this, 'branch')}
                      value={branch}
                      options={listBranch}
                      placeholder="Tất cả chi nhánh"
                    />
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-bordered table-hover datatable-highlight">
                  <thead>
                    <tr>
                      <th>Chi nhánh</th>
                      <th>Tên Phòng Ban</th>
                      <th>Quyền Trực Thuộc</th>
                      <th>Mô tả</th>
                      <th>Công cụ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderDepartment()}
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    );
  }

  componentDidMount() {
    try {
      /**
       * 
       */
    } catch (error) {
      /**
       * 
       */
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      listBranch: payload.branchReducer.listBranch,
      branchs: payload.branchReducer.branchs,
      departments: payload.departmentReducer.departments,
      departmentCount: payload.departmentReducer.departmentCount,
      userInfo: payload.generalReducer.userInfo,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(SearchDepartment)
);
