/* eslint-disable */
import React from 'react';
import _ from 'lodash';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import 'moment-timezone';
// import '../../../ui/receipt/css/index.css';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Images } from '../../../ui/assets/images';
import {
  formatDate
} from '../../../helpers';
// 25,36,18
class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageConfig : [
        {
          min: 1,
          max: 10,
          roundPerPage: [10],
          pageType:'singlePage'
        },
        {
          min: 11,
          max: 43,
          roundPerPage: [25, 18],
          pageType: 'doublePage'
        },
        {
          min: 44,
          max: 999,
          roundPerPage: [25, 36, 18],
          pageType: 'multiPage'
        }
      ],
      orderInfos : {}
      
    };
    //
    this.renderSinglePage  = this.renderSinglePage.bind(this);
    this.renderDoublePage  = this.renderDoublePage.bind(this);
    this.renderMultiPage  = this.renderMultiPage.bind(this);
    //
    this.renderHeader  = this.renderHeader.bind(this);
    this.renderOrder  = this.renderOrder.bind(this);
    this.renderAside  = this.renderAside.bind(this);
    //
    this.calPage  = this.calPage.bind(this);
  }

  renderSinglePage(orderInfo) {
    console.log(orderInfo);
    const { agency } = this.props.content;
    if(_.isEmpty(orderInfo) === true){
      return (<div className="print"></div>);
    }
    return (
      <div className="print">
        <style>
            {`@media print {.fa-print{display: none;} @page {size: portrait} .print{padding: 0;margin: 0} .printBody{height: 11in;width: 8.5in;padding: 0.5in 0} }`}
          </style>
        <div className="printBody">
          {this.renderHeader()}
          <article className="printArticle">
            <p className="printArticleP1">
              Khách hàng: <b>{_.get(agency, 'title', '')}</b><br /><br />
              Điện thoại: {_.get(agency, 'phone', '')}
            </p>

            <table className="printArticleInventory">
              <thead style={{ display: 'table-header-group' }}>
                <tr>
                  <th style={{textAlign: 'center'}}>
                    <span >Tên khách hàng</span>
                  </th>
                  <th style={{textAlign: 'center'}}>
                    <span >Dịch vụ</span>
                  </th>
                  <th style={{textAlign: 'center'}}>
                    <span>Số tiền thanh toán</span>
                  </th>
                  <th style={{textAlign: 'center'}}>
                    <span >Ghi chú</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.renderOrder(orderInfo.orders)}
              </tbody>
            </table>

            <table className="printArticleBalance">
              <tbody>
                <tr>
                  <th><span>Tổng tiền</span></th>
                  <td><span>{_.get(orderInfo,'total.amount',0).toLocaleString()}</span></td>
                </tr>
                <tr>
                  <th><span >Đã thanh toán</span></th>
                  <td><span name="money">{_.get(orderInfo,'total.deposit',0).toLocaleString()}</span></td>
                </tr>
                <tr>
                  <th><span >Còn lại</span></th>
                  <td><span name="money">{_.get(orderInfo,'total.remain',0).toLocaleString()}</span></td>
                </tr>
              </tbody>
            </table>

          </article>
          {this.renderAside()}
        </div>
      </div>
    );
  }

  renderDoublePage(orderInfo) {
    return (
      <div className="print">
        <div className="printBody">
          {this.renderHeader()}
          <article className="printArticle">
            <table className="printArticleInventory">
              <thead style={{ display: 'table-header-group' }}>
                <tr>
                  <th>
                    <span >Tên khách hàng</span>
                  </th>
                  <th>
                    <span >Dịch vụ</span>
                  </th>
                  <th>
                    <span >Số tiền</span>
                  </th>
                  <th>
                    <span >Đã thanh toán</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.renderOrder(orderInfo.orders)}
              </tbody>
            </table>
          </article>
        </div>

        <div className="pageBreak"></div>

        <div className="printBody">
          <article className="printArticle">
            <table className="printArticleInventory">
              <tbody>
                {this.renderOrder(orderInfo.orders)}
              </tbody>
            </table>
            <table className="printArticleBalance">
              <tbody>
                <tr>
                  <th><span>Tổng tiền</span></th>
                  <td><span>{_.get(orderInfo,'total.amount',0).toLocaleString()}</span></td>
                </tr>
                <tr>
                  <th><span >Đã thanh toán</span></th>
                  <td><span name="money">{_.get(orderInfo,'total.deposit',0).toLocaleString()}</span></td>
                </tr>
                <tr>
                  <th><span >Còn lại</span></th>
                  <td><span name="money">{_.get(orderInfo,'total.remain',0).toLocaleString()}</span></td>
                </tr>
              </tbody>
            </table>
          </article>
          {this.renderAside()}
        </div>

      </div>
    );
  }

  renderMultiPage() {
    return (<div className="print">
      <div className="printBody">
        {this.renderHeader()}
        <article className="printArticle">
          <table className="printArticleInventory">
            <thead style={{ display: 'table-header-group' }}>
              <tr>
                <th>
                  <span >Tên khách hàng</span>
                </th>
                <th>
                  <span >Dịch vụ</span>
                </th>
                <th>
                  <span >Số tiền</span>
                </th>
                <th>
                  <span >Đã thanh toán</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.renderOrder(orderInfo.orders)}
            </tbody>
          </table>
        </article>
      </div>

      <div className="pageBreak"></div>
      <div className="printBody">
        <article className="printArticle">
          <table className="printArticleInventory">
            <tbody>
              {this.renderOrder(orderInfo.orders)}
            </tbody>
          </table>
        </article>
      </div>

      <div className="pageBreak"></div>

      <div className="printBody">
        <article className="printArticle">
          <table className="printArticleInventory">
            <tbody>
              {this.renderOrder(orderInfo.orders)}
            </tbody>
          </table>
          <table className="printArticleBalance">
            <tbody>
              <tr>
                <th><span>Tổng tiền</span></th>
                <td><span>{_.get(orderInfo,'total.amount',0).toLocaleString()}</span></td>
              </tr>
              <tr>
                <th><span >Đã thanh toán</span></th>
                <td><span name="money">{_.get(orderInfo,'total.deposit',0).toLocaleString()}</span></td>
              </tr>
              <tr>
                <th><span >Còn lại</span></th>
                <td><span name="money">{_.get(orderInfo,'total.remain',0).toLocaleString()}</span></td>
              </tr>
            </tbody>
          </table>
        </article>
        {this.renderAside()}
      </div>

    </div>);
  }

  renderOrder(orders) {
    try {
      const result = [];
      return (orders.map((order) => {
        const service = this.props.content.getServiceDetail(order.serviceIdentifyCode);
        const subServiceName = _.get(order, 'addon.subServiceName', false);
        
        return (order.deposit.map((deposit,key) => {
          return (<tr key={`renderOrder_${order.id}_${key}`} className="list_transaction">
          <td style={{ textAlign: 'center' }}>
            <span className="printArticleInventoryPrefix">#{order.id}</span>
            <span style={{ 
              textAlign: 'center',
              fontSize:'92%',
              position: 'absolute',
              top: 5,
              left: 45
     }}>{_.get(order,'profile.fullName','')}</span>
          </td>
          <td className="printArticleInventoryContentLeft">
            <span></span>
            <span style={{ 
              textAlign: 'left',
              position: 'absolute',
              top: 3,
              left: 10 }}>
                {_.get(service,'title','')} {subServiceName ? `- ${subServiceName}` : ''} 
                </span>
          </td>
          <td>
            <span className="printArticleInventoryPrefix"></span>
            <span className="printArticleInventoryContentRight" name="money">
            {_.get(deposit,'amount',0).toLocaleString()}
            </span>
          </td>
          <td>
            <span className="printArticleInventoryContentRight" name="money">
            {_.get(deposit,'description','')}
            </span><br />
            <div style={{ textAlign: 'right', fontSize:'72%' }} className="text-muted font-size-sm">
            
             {key < (order.deposit.length - 1) ? formatDate(deposit.createdAt) : ' '}
            </div>

          </td>
        </tr>);
        }));
      }));
    } catch (error) {
      return (<tr></tr>);
    }
  }

  renderHeader() {
    try {
      return (
      <div className="printHeader">
        <div className="printHeaderH1">PHIẾU THU</div>
        <img
          className="printHeaderImg"
          src={Images.printLogo}
        />
        <address className="printHeaderAddress" >
          <p className="printHeaderAddressP1" >CÔNG TY TNHH DV DU LỊCH & TM TÂN VĂN LANG</p>
          <p >Địa chỉ: 112/6 Phạm Văn Bạch, phường 15, quận Tân Bình, tp.HCM</p>
          <p >Điện thoại: 028.7777.79.79 | hotline: 0907.874.240 </p>
          <p className="printHeaderAddressP2" >Vnhub.com | Visatop.vn | Tanvanlang.com</p>
        </address>
        <span style={{
            margin: '0 0 1em 1em',
            maxHeight: '25%',
            maxWidth: '60%',
            position: 'relative',
        }}>
        </span>
        
        <div style={{clear:"both"}}> </div>
      </div>);
    } catch (error) {
      // console.log(error);
      return (<div className="printHeader"></div>)
    }

  }

  renderAside() {
    const date = moment().format('DD/MM/YYYY');
    return (<aside className="aside">
      <h1>
        <span >Ghi chú cho đơn hàng </span>
      </h1>
      <div >
        <p style={{ fontStyle: 'italic', fontSize: 14, paddingTop: 10 }}>
          Lưu ý: Phiếu thu này không thay thế cho Hóa đơn GTGT. Phí thu chưa bao gồm 10% VAT.
        </p>
        <p style={{
          fontWeight: 'bold', fontSize: 14, textAlign: 'center', padding: '20px 0px'
        }}>Ngày {date}</p>
        <table className="sign_name">
          <thead>
            <tr>
              <th>Người nộp<br /><span className="sign_span">(ký và ghi rõ họ tên)</span></th>
              <th>Người thu<br /><span className="sign_span">(ký và ghi rõ họ tên)</span></th>
              <th>Kế toán<br /><span className="sign_span">(ký và ghi rõ họ tên)</span></th>
              <th>Giám đốc<br /><span className="sign_span">(ký và ghi rõ họ tên)</span></th>
            </tr>
          </thead>
          <tbody>
          </tbody>
        </table>
      </div>
    </aside>);
  }


  calPage() {
    try {
      const { orders,params } = this.props.content;
      const ids = _.get(params,'id',false);
      const result = {
        orders:[],
        pageType:'',
        count : 0,
        total : {
          amount:0,
          deposit:0,
          remain:0
        },
      }
      if(ids && _.isEmpty(orders) === false){
        const orderIds = ids.split(',');
        _.forEach(orderIds,(orderId) =>{
          const order = _.find(orders,{id:Number(orderId)});
          

          if(_.isEmpty(order) === false){
            const depositAmount = _.sumBy(order.deposit, 'amount');
            order.depositAmount = depositAmount;
            result.orders.push(order);
            result.count += 1;
            result.total.amount += order.amount;
            result.total.deposit += depositAmount;
            result.total.remain += (order.amount - depositAmount);
          }
        });
        _.forEach(this.state.pageConfig, config =>{
          if(result.count >= config.min && result.count <= config.max){
            result.pageType = config.pageType;
          }
        });
      }
      return result;
    } catch (error) {
      // console.log(error);
      return false;
    }
    
  }

  render() {
    

    const calPage = this.calPage();
    switch (calPage.pageType) {
      case 'singlePage':{
        return (<div>
          
          {this.renderSinglePage(calPage)}
        </div>);
        break;
      }
      // case 'doublePage':{
      //   return (<div className="print">
      //     {this.renderDoublePage(calPage)}
      //   </div>);
      //   break;
      // }
      // case 'multiPage':{
      //   return (<div className="print">
      //     {this.renderMultiPage(calPage)}
      //   </div>);
      //   break;
      // }
      default : {
        return (<div></div>);
        break;
      }
    };
  }
}

class SearchOrder extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <div>
        <ReactToPrint
        
          trigger={() => <a href="#"><i style={{
            color:'black',
            fontSize:25,
            position:'absolute',
            top:10,
            left:20
          }} 
          className="fa fa-print"></i></a>}
          content={() => this.componentRef}

        />
        <ComponentToPrint
          content={this.props}
          ref={el => (this.componentRef = el)}
          pageStyle="@page { size: A4 portrait;margin: 0;padding: 0;}"
        />
        {/* <ComponentToPrint /> */}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    // dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      agency: payload.agencyReducer.agency,
      orders: payload.orderReducer.orders,
      init: payload.generalReducer.init,
      userInfo: payload.generalReducer.userInfo,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(SearchOrder)
);

