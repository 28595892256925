import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Images } from '../../ui/assets/images';

class Loading extends Component {
  render() {
    const { payload: { apiReducer } } = this.props;
    if (apiReducer && (!apiReducer.showLoading || apiReducer.showLoading === false)) {
      return false;
    }
    return (
      <div className="e-loading">
        <img src={Images.loading} alt="loading" />
      </div>
    );
  }
}

export default connect((state) => {
  return { 
    payload: state
    
  };
})(Loading);

