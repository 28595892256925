import { call, put, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';
import * as responseCode from '../../configs/responseCode.config';
import { types } from '../../redux/general';
import { generalAPI } from '../../api';
import { types as apiTypes } from '../../redux/api';

function* doAction(action) {
  try {
    // const cache = localStorage.getItem('init');
    // const hours = 0.5; // Reset when storage is more than 0.5 hours
    // const now = new Date().getTime();
    // const setupTime = localStorage.getItem('setupTime');
    
    // if (setupTime == null) {
    //   localStorage.setItem('setupTime', now);
    // } else if (now - setupTime > hours * 60 * 60 * 1000) {
    //   init = yield call(generalAPI.getInit, action.payload);
    //   localStorage.setItem('setupTime', now);
    // }
    
    // let init = cache ? JSON.parse(cache) : {};
    // if (!cache) {
    //   init = yield call(generalAPI.getInit, action.payload);
    // }
    const init = yield call(generalAPI.getInit, action.payload);

    const { code, data } = init;
    switch (code) {
      case responseCode.GET_INIT_SUCCESS: {
        // if (!cache) {
        //   localStorage.setItem('init', JSON.stringify(init));
        // }

        yield put({ type: types.GET_INIT_SUCCESS, payload: data });
        if (action.callback) {
          action.callback(true, data);
        }
        break;
      }
      default: {
        yield put({ type: types.GET_INIT_FAIL, callback: { code, data } });
        if (action.callback) {
          action.callback(false, data);
        }
      }
    }
  } catch (error) {
    console.log(error);
    yield put({ type: types.GET_INIT_FAIL });
  }
}

export default function* watchAction() {
  yield takeLatest(types.GET_INIT, doAction);
}

