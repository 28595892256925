import _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/vi';

moment.locale('vi');

function formatDate(date) {
  if (!date) {
    return null;
  }
  return moment(date).format('DD/MM/YYYY');
}

function formatDateTime(date) {
  if (!date) {
    return null;
  }
  return moment(date).format('DD/MM/YYYY HH:mm');
}

function formatHourMinute(date) {
  if (!date) {
    return null;
  }
  return moment(date).format('HH:mm');
}

function formatDateTimeChatBox(date) {
  if (!date) {
    return null;
  }
  const d = new Date();
  const targetDate = new Date(date);
  const currentSecond = d.getTime() / 1000;
  const targetSecond = targetDate.getTime() / 1000;
  const fullTimeRange = 604800; // second
  const timeRange = Math.round(currentSecond - targetSecond);
  if (timeRange < fullTimeRange) {
    moment.locale('vi');
    return moment(date).fromNow();
  }
  return moment(date).format('DD/MM/YYYY HH:mm');
}

function formatDate2(datetime) {
  if (!datetime) {
    return null;
  }
  return moment(datetime).format('DD/MM/YYYY');
}

function formatDateRange(sdate, edate) {
  if (!sdate || !edate) {
    return null;
  }
  return {
    startDate: sdate.format(),
    endDate: edate.format()
  };
}

function formatDateFilter(sdate, edate) {
  if (!sdate || !edate) {
    return null;
  }
  return `${sdate.format('LL')} - ${edate.format('LL')}`;
}

function formatDateFilter2(sdate, edate, str = null) {
  if (!sdate || !edate) {
    return str;
  }
  return `${moment(sdate).format('LL')} - ${moment(edate).format('LL')}`;
}

export {
  formatDate,
  formatHourMinute,
  formatDateTime,
  formatDateTimeChatBox,
  formatDate2,
  formatDateRange,
  formatDateFilter,
  formatDateFilter2
};

