import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import CreateOrder from './createOrder';
import * as OrderActions from '../../../redux/orders/orders.actions';
import * as AccountActions from '../../../redux/accounts/accounts.actions';
import * as AgencyActions from '../../../redux/agencies/agencies.actions';
import * as SupplierActions from '../../../redux/suppliers/suppliers.actions';
import * as GeneralActions from '../../../redux/general/general.actions';
import * as WalletActions from '../../../redux/wallets/wallets.actions';
import { PAGE_LIMIT, INVOICE_EXIT_SCOPE } from '../../../configs/constants';
import {
  serviceDetail,
  serviceHandleDatas,
  buildListCountry,
  checkScope
} from '../../../helpers';

class CreateOrderContainer extends React.Component {
  constructor(prop) {
    super(prop);
    if (checkScope(INVOICE_EXIT_SCOPE, this.props) === false) {
      NotificationManager.error('Chức năng không tồn tại', '', 1500);
      setTimeout(() => {
        this.props.history.push('/dashboard');
      }, 2000);
    }
    this.state = {
      tagSuggestions: [],
      profiles: [],
      agency: {
        title: '',
        email: '',
        phone: ''
      }
    };

    //
    this.onSubmit = this.onSubmit.bind(this);
    this.callback = this.callback.bind(this);
    this.callbackNew = this.callbackNew.bind(this);
    this.getDelegateMember = this.getDelegateMember.bind(this);
    //
    this.getAgencyInfo = this.getAgencyInfo.bind(this);
    this.callbackGetAgencyInfo = this.callbackGetAgencyInfo.bind(this);
    //
    this.getMembers = this.getMembers.bind(this);
    // this.callbackMember = this.callbackMember.bind(this);
    //
    // this.getServices = this.getServices.bind(this);
    // this.callbackGetService = this.callbackGetService.bind(this);
    //
    this.getTagSuggest = this.getTagSuggest.bind(this);
    this.buildGetTagSuggest = this.buildGetTagSuggest.bind(this);
    //
    this.getProfile = this.getProfile.bind(this);
    // this.callbackGetProfile = this.callbackGetProfile.bind(this);
    //
    this.buildCountry = this.buildCountry.bind(this);
    this.listServiceHandleData = this.listServiceHandleData.bind(this);

    this.getWallet = this.getWallet.bind(this);
    this.getServiceDetail = this.getServiceDetail.bind(this);
    //
    // this.getServices();
    //
  }

  getWallet(id) {
    try {
      const { walletAct } = this.props;
      const accountId = id || this.props.computedMatch.params.id;
      if (accountId) {
        walletAct.detailWallet({
          filters: {
            accountId: [accountId]
          }
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  onSubmit = async (args, type) => {
    try {
      const { orderAct } = this.props;

      if (type === 'addNew') {
        orderAct.createOrder(args, this.callbackNew);
      } else {
        orderAct.createOrder(args, this.callback);
      }
    } catch (error) {
      // console.log('error', error);
    }
  }

  callback(status, data) {
    if (status) {
      NotificationManager.info('Tạo đơn hàng thành công,đang chuyển về trang quản lý', '', 1000);
      setTimeout(() => {
        this.props.history.push('/order');
      }, 1500);
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }

  callbackNew(status, data) {
    if (status) {
      NotificationManager.info('Tạo đơn hàng thành công,đang chuyển về trang tạo mới đơn hàng', '', 1000);
      setTimeout(() => {
        const id = this.props.computedMatch.params.id;
        this.props.history.push(`/order-create/${id}`);
      }, 1500);
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }

  /**
   * 
   */
  getAgencyInfo(args) {
    try {
      const { agencyAct } = this.props;
      agencyAct.detailAgency(args, this.callbackGetAgencyInfo);
    } catch (error) {
      // console.log('error', error);
    }
  }

  callbackGetAgencyInfo(status, data) {
    try {
      if (status === false || _.isEmpty(data) === true) {
        // NotificationManager.error('Thông tin không hợp lệ', '', 1000);

        // setTimeout(() => {
        //   this.props.history.push('/order');
        // }, 1500);
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * getTagSuggest
   */
  getTagSuggest() {
    try {
      const { orderAct } = this.props;
      orderAct.orderTagSearch({});
    } catch (error) {
      // console.log('error', error);
    }
  }

  buildGetTagSuggest() {
    const { orderTags } = this.props;
    try {
      const tagSuggestions = [];
      if (orderTags) {
        orderTags.forEach((element) => {
          tagSuggestions.push({
            label: element,
            value: element
          });
        });
      }
      return tagSuggestions;
    } catch (error) {
      return [];
    }
  }

  /**
   * getServices
   */
  // getServices() {
  //   try {
  //     const { serviceAct } = this.props;
  //     serviceAct.listService({});
  //   } catch (error) {
  //     // console.log('error', error);
  //   }
  // }

  /**
   * getProfile
   */
  getProfile(passportNumber) {
    try {
      if (passportNumber) {
        const { generalAct } = this.props;

        generalAct.postProfileSearch({
          filters: {
            passport: {
              number: passportNumber
            }
          }
        }, this.callbackGetProfile);
      }
    } catch (error) {
      // console.log('error', error);
    }
  }

  callbackGetProfile(status, data) {
    try {
      if (status) {
        this.setState({ profiles: data });
      }
    } catch (error) {
      // console.log(error);
    }
  }

  /**
   * 
   */
  getServiceDetail(serviceIdentifyCode) {
    try {
      const { buildServices } = this.props;
      if (_.isEmpty(buildServices) === false) {
        return serviceDetail(buildServices, serviceIdentifyCode);
      }
      return {};
    } catch (error) {
      return {};
    }
  }

  /**
   * 
   */
  getMembers(args) {
    try {
      const { accountAct } = this.props;
      accountAct.searchAccount(args);
    } catch (error) {
      // console.log('error', error);
    }
  }

  getSuppliers(args) {
    try {
      const { supplierAct } = this.props;
      supplierAct.searchSupplier(args);
    } catch (error) {
      // console.log('error', error);
    }
  }

  getDelegateMember() {
    const { accounts } = this.props;
    const members = [{
      label: 'Không',
      value: 'root'
    }];

    const delegates = [];
    if (accounts) {
      const id = this.props.computedMatch.params.id;
      accounts.forEach((item) => {
        if (item.id.toString() !== id) {
          members.push({ value: item.identifyCode, label: item.title, scopes: item.scopes });
          delegates.push({ value: item.identifyCode, label: item.title, scopes: item.scopes });
        }
      });
    }
    return { members, delegates };
  }
  /**
   * 
   */

  listServiceHandleData() {
    try {
      const result = { list: [], setlectedItem: {} };
      const { buildServices } = this.props;

      if (_.isEmpty(buildServices) === false) {
        return serviceHandleDatas(buildServices);
      }
      return result;
    } catch (error) {
      return false;
    }
  }

  /**
   * 
   */

  render() {
    const {
      buildServices,
      tagSuggestions,
      profiles
    } = this.state;
    return (
      <div>
        <CreateOrder
          profiles={profiles}
          tagSuggestions={tagSuggestions}
          buildServices={buildServices}
          buildCountry={this.buildCountry}
          buildGetTagSuggest={this.buildGetTagSuggest}
          getProfile={this.getProfile}
          userInfo={this.userInfo}
          onSubmit={this.onSubmit}
          getWallet={this.getWallet}
          getDelegateMember={this.getDelegateMember}
          listServiceHandleData={this.listServiceHandleData}
          getServiceDetail={this.getServiceDetail}
        />
        <NotificationContainer />
      </div>

    );
  }

  buildCountry() {
    const { init } = this.props;
    const listCountry = [];
    if (init.country) {
      return buildListCountry(init.country);
    }
    return listCountry;
  }

  componentWillMount() {
    try {
      const { pathname } = this.props.location;
      const match = pathname.match('order', 'i');
      if (_.isEmpty(match) === false && _.isEmpty(this.props.passiveMenuInfo) === false) {
        const orderMenu = _.find(this.props.passiveMenuInfo, { id: 'order' });
        this.props.dispatch({
          type: 'REDUX_SET_ACTIVE_MENU',
          val: orderMenu
        });
      }

      const id = this.props.computedMatch.params.id;
      this.getAgencyInfo({
        filters: {
          id
        }
      });
      //
      const filter = {
        filters: {
          group: ['staff'],
          state: ['working', 'maternityLeave', 'unknown']
        },
        paging: {
          start: 0,
          limit: PAGE_LIMIT
        }
      };

      const filterSupplier = {
        filters: {
          group: ['supplier']
        },
        paging: {
          start: 0,
          limit: PAGE_LIMIT
        }
      };
      this.getSuppliers(filterSupplier);
      this.getMembers(filter);
      this.getWallet();
      this.getTagSuggest({});
    } catch (error) {
      // console.log(error);
    }
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    agencyAct: bindActionCreators(AgencyActions, dispatch),
    supplierAct: bindActionCreators(SupplierActions, dispatch),
    generalAct: bindActionCreators(GeneralActions, dispatch),
    orderAct: bindActionCreators(OrderActions, dispatch),
    accountAct: bindActionCreators(AccountActions, dispatch),
    walletAct: bindActionCreators(WalletActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    orderTags: rootState.orderReducer.orderTags,
    buildServices: rootState.serviceReducer.buildServices,
    agencies: rootState.agencyReducer.agencies,
    agency: rootState.agencyReducer.agency,
    suppliers: rootState.supplierReducer.suppliers,
    accounts: rootState.accountReducer.accounts,
    init: rootState.generalReducer.init,
    passiveMenuInfo: rootState.generalReducer.passiveMenuInfo,
    activeMenu: rootState.generalReducer.activeMenu,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(CreateOrderContainer);
