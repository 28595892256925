
/**
 * CRUD
 */

export const POST_SEARCH_AGENCY = 'POST_SEARCH_AGENCY';
export const POST_SEARCH_AGENCY_SUCCESS = 'POST_SEARCH_AGENCY_SUCCESS';
export const POST_SEARCH_AGENCY_FAIL = 'POST_SEARCH_AGENCY_FAIL';

export const POST_SEARCH_BY_KEYWORD_AGENCY = 'POST_SEARCH_BY_KEYWORD_AGENCY';
export const POST_SEARCH_BY_KEYWORD_AGENCY_SUCCESS = 'POST_SEARCH_BY_KEYWORD_AGENCY_SUCCESS';
export const POST_SEARCH_BY_KEYWORD_AGENCY_FAIL = 'POST_SEARCH_BY_KEYWORD_AGENCY_FAIL';

export const POST_AGENCY_DETAIL = 'POST_AGENCY_DETAIL';
export const POST_AGENCY_DETAIL_SUCCESS = 'POST_AGENCY_DETAIL_SUCCESS';
export const POST_AGENCY_DETAIL_FAIL = 'POST_AGENCY_DETAIL_FAIL';

export const POST_AGENCY = 'POST_AGENCY';
export const POST_AGENCY_SUCCESS = 'POST_AGENCY_SUCCESS';
export const POST_AGENCY_FAIL = 'POST_AGENCY_FAIL';

export const PUT_AGENCY = 'PUT_AGENCY';
export const PUT_AGENCY_SUCCESS = 'PUT_AGENCY_SUCCESS';
export const PUT_AGENCY_FAIL = 'PUT_AGENCY_FAIL';
