import React from 'react';
import _ from 'lodash';
import validator from 'validator';
import { withRouter, Link, browserHistroy } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import { DelayInput } from 'react-delay-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons';
import PagingContainer from '../../ultils/paging.container';
import { API_URL } from '../../../configs/api.config';

import { 
  checkScope, paging, localStoreFilter, localStorePageNum
} from '../../../helpers';
import { STAFF_UPDATE_SCOPE, WALLET_SCOPE, PAGE_LIMIT } from '../../../configs/constants';

class SearchMember extends React.Component {
  constructor(props) {
    super(props);
    const memberFilter = localStoreFilter('searchMember', 'getMembers');
    this.state = {
      pageNum: localStorePageNum('searchMember', 'getMembers'),
      states: [
        { value: 'all', label: 'Tất cả trạng thái' },
        { value: 'working', label: 'Làm việc' },
        { value: 'maternityLeave', label: 'Nghỉ thai sản' },
        { value: 'off', label: 'Đã nghỉ' },
        { value: 'unknown', label: 'Không khác định' }
      ],
      
      searching: memberFilter || {
        filters: {
          group: ['staff'],
          title: '',
          phone: '',
          organizationId: '',
          state: ['working', 'maternityLeave', 'unknown']
        },
        paging: {
          start: 0,
          limit: PAGE_LIMIT
        }
      }
      
    };
    this.onChangePage = this.onChangePage.bind(this);
  }

  onChangePage(key) {
    const searching = Object.assign({}, this.state.searching);
    let pageNum = this.state.pageNum;
    switch (key) {
      case 'next': {
        //
        pageNum += 1;
        break;
      }
      default: {
        pageNum -= 1;
        if (pageNum <= 1) {
          pageNum = 1;
        }
        break;
      }
    }
    searching.paging = paging(pageNum, PAGE_LIMIT);
    this.props.getMembers(searching);
    this.setState({ pageNum });
  }

  onChangeHandle(key, e) {
    try {
      const searching = Object.assign([], this.state.searching);
      const value = _.get(e, 'target.value', _.get(e, 'value', false));
      switch (key) {
        case 'title': {
          const isNumber = validator.isNumeric(value);
          const floatNumber = parseFloat(value);
          if (isNumber === true) {
            searching.filters.title = '';
            searching.filters.phone = value;
          } else {
            searching.filters.title = value;
            searching.filters.phone = '';
          }
          break;
        }
        case 'organizationId': {
          const { listBranch } = this.props;
          const branch = _.find(listBranch, { value });
            
          searching.filters.organizationId = _.get(branch, 'id', value);
          if (value === 'all') {
            searching.filters.organizationId = '';
          }
          break;
        }
        case 'state': {
          searching.filters[key] = [value];
          if (value === 'all') {
            searching.filters.state = ['working', 'maternityLeave', 'unknown'];
          }
          break;
        }
        default: {
          searching.filters[key] = value;
          if (value === 'all') {
            searching.filters[key] = '';
          }
            
          break;
        }
      }
      
      this.setState({ searching });
      this.props.getMembers(searching);
    } catch (error) {
      console.log(error);
    }
  }

  renderMember() {
    const { members } = this.props;
    if (_.isEmpty(members) === true) {
      return (<tr></tr>);
    }

    const hasWalletPermission = checkScope(WALLET_SCOPE, this.props);

    return (members.map((item, i) => {
      const linkTo = `/member-modify/${item.id}`;
      const linkToAccountWallet = `/account-wallet/${item.id}`;
      const delegates = _.get(item, 'delegates', []);
      const backgroundColor = { off: { color: 'gray' } };
      const hasUpdateStaffPermission = checkScope(STAFF_UPDATE_SCOPE, this.props);
      return (<tr key={i}>
        <td>{item.id}</td>
        <td>
          <div className="d-flex align-items-center">
            <div className="mr-3">
              <a href="#">
                <img src={`${API_URL}/Account/Avatar/${_.get(item, 'id', '-1')}`} width="40" height="40" className="rounded-circle" alt="" />
              </a>
            </div>
            <div>
              <div className="media-title">
                {hasUpdateStaffPermission 
                  ? <Link style={item.state === 'off' ? backgroundColor.off : { fontWeight: 'bold' }} to={linkTo}>
                    {item.title}
                  </Link> : ''}
              </div>
              <span className="text-muted">{item.position}</span></div>
          </div>
        </td>
        <td>
          <span className="badge badge-mark border-success mr-1"></span> Online
        </td>
        <td>
          <span className="text-muted">{item.position}</span>
        </td>
        <td>
          {_.get(item, 'voip.extension', '')}
        </td>
        <td>
          <span className="text-success-600">0</span>
        </td>
        <td>
          {this.renderDelegate(delegates)}
        </td>
        <td className="text-center">
          {hasWalletPermission ? <Link
            style={{ color: 'black', fontSize: 14 }}
            className="btn btn-sm "
            to={linkToAccountWallet}
            title="Cập nhật ví"
          >
            <FontAwesomeIcon icon={faMoneyCheckAlt} />
          </Link> : ''}

        </td>

      </tr>);
    }));
  }

  renderDelegate(data) {
    if (_.isEmpty(data) === true) {
      return (<span></span>);
    }
    return (data.map((item, i) => {
      return (<span key={`renderDelegate_${i}`} >
        <span className="badge badge-mark border-primary mr-1"></span>
        {_.get(item, 'title', '')} <br />
      </span>);
    }));
  }

  render() {
    const { states, searching } = this.state;
    const { listBranch } = this.props;
    let branch = { value: 'all', label: 'Tất cả chi nhánh' };
    let state = { value: 'all', label: 'Tất cả trạng thái' };
    if (_.isEmpty(searching.filters) === false) {
      branch = _.find(listBranch, { id: searching.filters.organizationId }) || { value: 'all', label: 'Tất cả chi nhánh' };
      if (searching.filters.state.length === 1) {
        state = _.find(states, { value: searching.filters.state[0] }) || { value: 'all', label: 'Tất cả trạng thái' };
      }
    }
    
    return (
      <div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header bg-transparent header-elements-inline">
                <h4 className="card-title">Danh sách nhân viên</h4>
                <Link
                  style={{ float: 'right' }}
                  className="btn bg-teal-400"
                  to="/member-create" >
                  <i className="icon-add mr-2"></i> Thêm mới
                </Link>
              </div>

              <div className="card-body">
                <div className="row">
                  <div className="form-group col-md-4">
                    <DelayInput
                      value={searching.filters.title || searching.filters.phone} 
                      type="text" 
                      delayTimeout={300}
                      name="title"
                      className="form-control" 
                      placeholder="Nhập tên hoặc số điện thoại nhân viên "
                      onChange={this.onChangeHandle.bind(this, 'title')}
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <Select
                      name="organizationId"
                      value={branch} 
                      onChange={this.onChangeHandle.bind(this, 'organizationId')}
                      options={listBranch}
                      placeholder="Tất cả chi nhánh"
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <Select
                      name="state"
                      onChange={this.onChangeHandle.bind(this, 'state')}
                      value={state}
                      options={states}
                      placeholder="Tất cả trạng thái"
                    />
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table text-nowrap">
                  <thead>
                    <tr>
                      <th>Mã</th>
                      <th>Tên nhân viên</th>
                      <th>Tình trạng</th>
                      <th>Phòng ban</th>
                      <th>Số máy nhánh</th>
                      <th>Tổng hồ sơ</th>
                      <th>Ủy thác cho</th>
                      <th>Công cụ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderMember()}
                  </tbody>
                </table>

                <PagingContainer
                  count={_.get(this.props, 'listMember', []).length}
                  pageNum={this.state.pageNum}
                  limit={PAGE_LIMIT}
                  onClick={this.onChangePage.bind(this)}
                />
                  
              </div>
            </div>
          </div>
        </div>
      </div>
     
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      members: payload.accountReducer.members,
      listBranch: payload.branchReducer.listBranch,
      localStoreFilter: payload.generalReducer.localStoreFilter,
      listScope: payload.generalReducer.listScope,
      userInfo: payload.generalReducer.userInfo,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(SearchMember)
);
