import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import SearchCustomerSupport from './searchCustomerSupport';
import * as OrderActions from '../../../redux/orders/orders.actions';
import * as SupportActions from '../../../redux/supports/supports.actions';
import * as CallLogActions from '../../../redux/callLogs/callLogs.actions';
import * as ServiceActions from '../../../redux/services/services.actions';
import store from '../../../configs/store.config';

import { PAGE_LIMIT, CUSTOMER_SUPPORT_MANAGE_SCOPE } from '../../../configs/constants';
import {
  serviceDetail, serviceHandleDatas, checkScope, 
  buildListLocation, buildListCustomerType, 
  buildListCustomerSource, localStoreFilter
} from '../../../helpers';

class SearchCustomerSupportContainer extends React.Component {
  constructor(prop) {
    super(prop);

    if (checkScope(CUSTOMER_SUPPORT_MANAGE_SCOPE, this.props) === false) {
      NotificationManager.error('Chức năng không tồn tại', '', 1500);
      setTimeout(() => {
        this.props.history.push('/dashboard');
      }, 2000);
    }

    this.state = {
      searching: {},
      orders: [],
      listService: [],
      listAccountState: []
    };
    this.getCustomerSupports = this.getCustomerSupports.bind(this);
    this.callLogDetail = this.callLogDetail.bind(this);
    this.buildLocation = this.buildLocation.bind(this);
    this.getServiceDetail = this.getServiceDetail.bind(this);
    this.buildCustomerSource = this.buildCustomerSource.bind(this);
    this.buildCustomerType = this.buildCustomerType.bind(this);
    this.listServiceHandleData = this.listServiceHandleData.bind(this);
    this.getSimpleCallLogs = this.getSimpleCallLogs.bind(this);
    this.getSimpleOrders = this.getSimpleOrders.bind(this);
    this.clickToCall = this.clickToCall.bind(this);
    this.removeCustomerSupport = this.removeCustomerSupport.bind(this);
    this.callBackRemoveCustomerSupport = this.callBackRemoveCustomerSupport.bind(this);
    //
    this.getMembers = this.getMembers.bind(this);
    this.listMembers = this.listMembers.bind(this);
  }

  removeCustomerSupport(id, searching) {
    try {
      const { supportAct } = this.props;
      this.setState({ searching });      
      supportAct.deleteCustomerSupport({ id }, this.callBackRemoveCustomerSupport);
    } catch (error) {
      console.log('error', error);
    }
  }

  callBackRemoveCustomerSupport(status, data) {
    if (status) {
      NotificationManager.info('Xóa lịch sử thành công', '', 1500);
      const type = _.get(this.state.searching, 'filters.information.type', false);
      this.getCustomerSupports(this.state.searching, type);
      this.getServices();
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }

  clickToCall(args) {
    try {
      const { callLogAct } = this.props;
      callLogAct.clickToCall(args, (status, data) => {
        if (status === true) {
          NotificationManager.info('Kết nối cuộc gọi thành công, vui lòng chờ!', '', 5000);
        } else {
          NotificationManager.error(data.message, '', 2000);
        }
      });
    } catch (error) {
      console.log('error', error);
    }
  }
  /**
   * Call Logs
   */

  callLogDetail(args) {
    try {
      const { supportAct } = this.props;
      supportAct.CustomerSupportDetail(args);
    } catch (error) {
      console.log('error', error);
    }
  }
  
  getSimpleCallLogs(args) {
    try {
      const { callLogAct } = this.props;
      callLogAct.listPickupCallLog(args);
    } catch (error) {
      console.log('error', error);
    }
  }
   
  getCustomerSupports(args, type) {
    try {
      const { supportAct } = this.props;
      if (_.isEmpty(type) === false) {
        const filters = Object.assign({}, args.filters);
        const query = Object.assign({}, args);

        _.forEach(filters, (item, key) => {
          if (_.isObject(item) === true) {
            _.forEach(item, (subItem, key2) => {
              if (_.isEmpty(subItem) === true) {
                if (_.isNumber(subItem) === false) {
                  delete filters[key][key2];
                }
              }
            });
          } else if (_.isEmpty(item) === true) {
            if (_.isNumber(item) === false) {
              delete filters[key];
            }
          }

          if (_.isEmpty(item) === true) {
            if (_.isNumber(item) === false) {
              delete filters[key];
            }
          }
        });
        query.filters = filters;
        supportAct.ListCustomerSupportByCustomerType(query);
      
        store.dispatch({
          type: 'LOCAL_STORE_FILTER',
          payload: {
            objID: 'searchCustomerSupport',
            objType: `getCustomerSupports_${type}`,
            query
          }
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  getSimpleOrders(args) {
    try {
      const { orderAct } = this.props;
      orderAct.searchSimpleOrder(args);
    } catch (error) {
      console.log('error', error);
    }
  }

  /**
   * getServices
   */
  getServices() {
    try {
      const { serviceAct } = this.props;
      serviceAct.listService({});
    } catch (error) {
      // console.log('error', error);
    }
  }

  /**
   * 
   */
  getServiceDetail(serviceIdentifyCode) {
    try {
      const { buildServices } = this.props;
      if (_.isEmpty(buildServices) === false) {
        return serviceDetail(buildServices, serviceIdentifyCode);
      }
      return {};
    } catch (error) {
      return {};
    }
  }

  listServiceHandleData() {
    try {
      const result = { list: [], setlectedItem: {} };
      const { buildServices } = this.props;

      if (_.isEmpty(buildServices) === false) {
        return serviceHandleDatas(buildServices);
      }
      return result;
    } catch (error) {
      return false;
    }
  }

  buildLocation() {
    const { init } = this.props;
    if (init.area) {
      return buildListLocation(init);
    }
    return [];
  }

  buildCustomerType() {
    const { init } = this.props;
    if (init.customerType) {
      return buildListCustomerType(init);
    }
    return [];
  }

  buildCustomerSource() {
    const { init } = this.props;
      
    if (init.customerSource) {
      return buildListCustomerSource(init);
    }
    return [];
  }

  /**
   * 
   */
  getMembers(args) {
    try {
      const { accountAct } = this.props;
      accountAct.searchAccount(args);
    } catch (error) {
      // console.log('error', error);
    }
  }

  listMembers() {
    const { accounts } = this.props;
    const listMember = [{
      label: 'Chọn người tạo',
      value: 'all'
    }];
    try {
      if (_.isEmpty(accounts) === false) {
        accounts.forEach((item) => {
          listMember.push({ value: item.identifyCode, label: item.title, scopes: item.scopes });
        });
      }
      return listMember;
    } catch (error) {
      return listMember;
    }
  }

  render() {
    const {
      buildServices 
    } = this.state;

    return (
      <div>
        <SearchCustomerSupport
          buildServices={buildServices}
          removeCustomerSupport={this.removeCustomerSupport}
          listMembers={this.listMembers}
          getCustomerSupports={this.getCustomerSupports}
          callLogDetail={this.callLogDetail}
          buildLocation={this.buildLocation}
          buildCustomerSource={this.buildCustomerSource}
          buildCustomerType={this.buildCustomerType}
          listServiceHandleData={this.listServiceHandleData}
          getServiceDetail={this.getServiceDetail}
          getSimpleCallLogs={this.getSimpleCallLogs}
          getSimpleOrders={this.getSimpleOrders}
          clickToCall={this.clickToCall}
        />
        <NotificationContainer />
      </div>
        
    );
  }

  componentWillMount() {
    try {
      /**
       * 
       */
      //
      this.getServices();

      const customerSupportFilter1 = localStoreFilter('searchCustomerSupport', 'getCustomerSupports_1');
      const customerSupportFilter2 = localStoreFilter('searchCustomerSupport', 'getCustomerSupports_2');
      const customerSupportFilter3 = localStoreFilter('searchCustomerSupport', 'getCustomerSupports_3');
      const customerSupportFilter4 = localStoreFilter('searchCustomerSupport', 'getCustomerSupports_4');
      const customerSupportFilter5 = localStoreFilter('searchCustomerSupport', 'getCustomerSupports_5');
      this.getCustomerSupports(customerSupportFilter1 || {
        filters: {
          state: 'PENDING',
          information: { type: '1' } 
        },
        paging: { start: 0, limit: PAGE_LIMIT }
      }, '1');
      this.getCustomerSupports(customerSupportFilter2 || {
        filters: {
          state: 'PENDING',
          information: { type: '2' } 
        },
        paging: { start: 0, limit: PAGE_LIMIT }
      }, '2');
  
      this.getCustomerSupports(customerSupportFilter3 || {
        filters: {
          state: 'PENDING',
          information: { type: '3' }
        },
        paging: { start: 0, limit: PAGE_LIMIT }
      }, '3');
  
      this.getCustomerSupports(customerSupportFilter4 || {
        filters: {
          state: 'PENDING',
          information: { type: '4' } 
        },
        paging: { start: 0, limit: PAGE_LIMIT }
      }, '4');
  
      this.getCustomerSupports(customerSupportFilter5 || {
        filters: {
          state: 'PENDING',
          information: { type: '5' } 
        },
        paging: { start: 0, limit: PAGE_LIMIT }
      }, '5');
    } catch (error) {
      // console.log(error);
    }
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    orderAct: bindActionCreators(OrderActions, dispatch),
    supportAct: bindActionCreators(SupportActions, dispatch),
    callLogAct: bindActionCreators(CallLogActions, dispatch),
    serviceAct: bindActionCreators(ServiceActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    buildServices: rootState.serviceReducer.buildServices,
    accounts: rootState.accountReducer.accounts,
    localStoreFilter: rootState.generalReducer.localStoreFilter,
    init: rootState.generalReducer.init,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(SearchCustomerSupportContainer);
