
/**
 * CRUD
 */

export const POST_SEARCH_LIST_BRANCH = 'POST_SEARCH_LIST_BRANCH';
export const POST_SEARCH_LIST_BRANCH_SUCCESS = 'POST_SEARCH_LIST_BRANCH_SUCCESS';
export const POST_SEARCH_LIST_BRANCH_FAIL = 'POST_SEARCH_LIST_BRANCH_FAIL';
 
export const POST_SEARCH_BRANCH = 'POST_SEARCH_BRANCH';
export const POST_SEARCH_BRANCH_SUCCESS = 'POST_SEARCH_BRANCH_SUCCESS';
export const POST_SEARCH_BRANCH_FAIL = 'POST_SEARCH_BRANCH_FAIL';

export const GET_BRANCH = 'GET_BRANCH';
export const GET_BRANCH_SUCCESS = 'GET_BRANCH_SUCCESS';
export const GET_BRANCH_FAIL = 'GET_BRANCH_FAIL';

export const POST_BRANCH = 'POST_BRANCH';
export const POST_BRANCH_SUCCESS = 'POST_BRANCH_SUCCESS';
export const POST_BRANCH_FAIL = 'POST_BRANCH_FAIL';

export const PUT_BRANCH = 'PUT_BRANCH';
export const PUT_BRANCH_SUCCESS = 'PUT_BRANCH_SUCCESS';
export const PUT_BRANCH_FAIL = 'PUT_BRANCH_FAIL';

export const DELETE_BRANCH = 'DELETE_BRANCH';
export const DELETE_BRANCH_SUCCESS = 'DELETE_BRANCH_SUCCESS';
export const DELETE_BRANCH_FAIL = 'DELETE_BRANCH_FAIL';

