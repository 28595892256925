import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import ModifyOrder from './modifyOrder';
import * as OrderActions from '../../../redux/orders/orders.actions';
import * as AccountActions from '../../../redux/accounts/accounts.actions';
import * as AgencyActions from '../../../redux/agencies/agencies.actions';
import * as SupplierActions from '../../../redux/suppliers/suppliers.actions';
import * as ServiceActions from '../../../redux/services/services.actions';
import * as GeneralActions from '../../../redux/general/general.actions';
import * as WalletActions from '../../../redux/wallets/wallets.actions';
import { PAGE_LIMIT, INVOICE_EXIT_SCOPE } from '../../../configs/constants';
import {
  serviceDetail, 
  serviceHandleDatas, 
  checkScope
} from '../../../helpers';

class ModifyOrderContainer extends React.Component {
  constructor(prop) {
    super(prop);

    if (checkScope(INVOICE_EXIT_SCOPE, this.props) === false) {
      NotificationManager.error('Chức năng không tồn tại', '', 1500);
      setTimeout(() => {
        this.props.history.push('/dashboard');
      }, 2000);
    }

    this.state = {
      tagSuggestions: [],
      profiles: [],
      agency: {
        title: '',
        email: '',
        phone: ''
      }
    };
    //
    this.onSendMessage = this.onSendMessage.bind(this);
    this.onSetPin = this.onSetPin.bind(this);
    //
    this.onSubmit = this.onSubmit.bind(this);
    this.callback = this.callback.bind(this);
    this.callbackNew = this.callbackNew.bind(this);
    //
    
    this.getDelegateMember = this.getDelegateMember.bind(this);
    //
    this.getOrderInfo = this.getOrderInfo.bind(this);
    this.callbackGetOrderInfo = this.callbackGetOrderInfo.bind(this);
    //
    this.getMembers = this.getMembers.bind(this);
    //
    // this.getServices = this.getServices.bind(this);
    // this.callbackGetServices = this.callbackGetServices.bind(this);
    //
    this.getTagSuggest = this.getTagSuggest.bind(this);
    this.buildGetTagSuggest = this.buildGetTagSuggest.bind(this);
    //
    this.getProfile = this.getProfile.bind(this);
    //
    this.buildCountry = this.buildCountry.bind(this);
    this.listServiceHandleData = this.listServiceHandleData.bind(this);
    //
    this.getServiceDetail = this.getServiceDetail.bind(this);
    this.getWallet = this.getWallet.bind(this);
    //
    this.props.dispatch({ type: 'SHOW_LOADING' });
      
    const { pathname } = this.props.location;
    const match = pathname.match('order', 'i');
    if (_.isEmpty(match) === false && _.isEmpty(this.props.passiveMenuInfo) === false) {
      const orderMenu = _.find(this.props.passiveMenuInfo, { id: 'order' });
      this.props.dispatch({
        type: 'REDUX_SET_ACTIVE_MENU',
        val: orderMenu
      });
    }
      
    const filter = {
      filters: {
        group: ['staff'],
        state: ['working', 'maternityLeave', 'unknown']
      },
      paging: {
        start: 0,
        limit: PAGE_LIMIT
      }
    };

    const filterSupplier = {
      filters: {
        group: ['supplier']
      },
      paging: {
        start: 0,
        limit: PAGE_LIMIT
      }
    };
    this.getMembers(filter);
    this.getSuppliers(filterSupplier);
    this.getTagSuggest();
  }

  onSetPin = async (invoiceId, processId, isPin = true) => {
    try {
      const { orderAct } = this.props;
      const payload = {
        invoiceId,
        processId,
        data: { isPin }
      };
      orderAct.orderModifyPin(payload);
    } catch (error) {
      // console.log('error', error);
    }
  }

  onSendMessage = async (message) => {
    try {
      const { orderAct } = this.props;
      const payload = {
        id: this.props.computedMatch.params.id,
        data: {
          note: message
        }
      };
      delete payload.data.id;
      orderAct.orderModifyProcess(payload);
    } catch (error) {
      // console.log('error', error);
    }
  }

  onSubmit = async (args, type) => {
    try {
      const { orderAct } = this.props;
      const query = Object.assign({}, args);
      const payload = {
        id: query.id,
        data: query
      };
      delete payload.data.id;
      delete payload.data.isPaidSupplier;
      if (type === 'addNew') {
        orderAct.modifyOrder(payload, this.callbackNew);
      } else {
        orderAct.modifyOrder(payload, this.callback);
      }
    } catch (error) {
      // console.log('error', error);
    }
  }

  callback(status, data) {
    if (status) {
      NotificationManager.info('Cập nhật đơn hàng thành công,đang chuyển về trang quản lý', '', 1000);
      setTimeout(() => {
        this.props.history.push('/order');
      }, 1500);
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }

  callbackNew(status, data) {
    if (status) {
      NotificationManager.info('Cập nhật đơn hàng thành công,đang chuyển về trang tạo mới đơn hàng', '', 1000);
      setTimeout(() => {
        const customerId = this.props.computedMatch.params.customerId;
        this.props.history.push(`/order-create/${customerId}`);
      }, 1500);
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }

  /**
   * 
   */
  getWallet() {
    try {
      const { walletAct, order } = this.props;
      if (_.isEmpty(order) === false) {
        walletAct.detailWallet({
          filters: {
            accountId: [order.customer.id]
          }
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  /**
   * 
   */

  getOrderInfo(args) {
    try {
      const { orderAct } = this.props;
      
      orderAct.detailOrder(args, this.callbackGetOrderInfo);
    } catch (error) {
      console.log('error', error);
    }
  }

  callbackGetOrderInfo(status, data) {
    try {
      if (status === false || _.isEmpty(data) === true) {
        NotificationManager.error('Thông tin không hợp lệ', '', 1000);
        setTimeout(() => {
          this.props.history.push('/order');
        }, 1500);
      } else {
        this.getWallet();
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * getTagSuggest
   */
  getTagSuggest(args = {}) {
    try {
      const { orderAct } = this.props;
      orderAct.orderTagSearch(args);
    } catch (error) {
      console.log('error', error);
    }
  }

  buildGetTagSuggest() {
    const { orderTags } = this.props;
    try {
      const tagSuggestions = [];
      if (orderTags) {
        orderTags.forEach((element) => {
          tagSuggestions.push({
            label: element,
            value: element
          });
        });
      }
      return tagSuggestions;
    } catch (error) {
      return [];
    }
  }

  /**
   * getProfile
   */
  getProfile(passportNumber) {
    try {
      if (passportNumber) {
        const { generalAct } = this.props;

        generalAct.postProfileSearch({
          filters: {
            passport: {
              number: passportNumber
            }
          }
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  /**
   * 
   */
  getServiceDetail(serviceIdentifyCode) {
    try {
      const { buildServices } = this.props;
      if (_.isEmpty(buildServices) === false) {
        return serviceDetail(buildServices, serviceIdentifyCode);
      }
      return {};
    } catch (error) {
      return {};
    }
  }

  /**
   * 
   */
  getMembers(args = {}) {
    try {
      const { accountAct } = this.props;
      accountAct.searchAccount(args);
    } catch (error) {
      // console.log('error', error);
    }
  }

  getSuppliers(args) {
    try {
      const { supplierAct } = this.props;
      supplierAct.searchSupplier(args);
    } catch (error) {
      // console.log('error', error);
    }
  }

  getDelegateMember() {
    const { accounts } = this.props;
    const members = [{
      label: 'Không',
      value: 'root'
    }];
    const delegates = [];
    if (accounts) {
      const id = this.props.computedMatch.params.id;
      accounts.forEach((item) => {
        if (item.id.toString() !== id) {
          members.push({ value: item.identifyCode, label: item.title, scopes: item.scopes });
          delegates.push({ value: item.identifyCode, label: item.title, scopes: item.scopes });
        }
      });
    }
    return { members, delegates };
  }
  /**
   * 
   */

  listServiceHandleData() {
    try {
      const result = { list: [], setlectedItem: {} };
      const { buildServices } = this.props;

      if (_.isEmpty(buildServices) === false) {
        return serviceHandleDatas(buildServices);
      }
      return result;
    } catch (error) {
      return false;
    }
  }

  /**
   * 
   */

  render() {
    const {
      tagSuggestions,
      profiles
    } = this.state;
    return (
      <div>
        <ModifyOrder
          profiles={profiles}
          tagSuggestions={tagSuggestions}
          buildCountry={this.buildCountry}
          buildGetTagSuggest={this.buildGetTagSuggest}
          onSendMessage={this.onSendMessage}
          getProfile={this.getProfile}
          userInfo={this.userInfo}
          onSubmit={this.onSubmit}
          onSetPin={this.onSetPin}
          getDelegateMember={this.getDelegateMember}
          listServiceHandleData={this.listServiceHandleData}
          getServiceDetail={this.getServiceDetail}
        />
        <NotificationContainer />
      </div>

    );
  }

  buildCountry() {
    const { init } = this.props;
    const listCountry = [];
    if (init.country) {
      init.country.forEach((element) => {
        listCountry.push({ value: element.value, label: element.value });
      });
    }
    return listCountry;
  }

  componentDidMount() {
    this.props.dispatch({ type: 'HIDE_LOADING' });
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.buildServices !== newProps.buildServices) {
      const id = this.props.computedMatch.params.id;
      this.getOrderInfo({ filters: { id: [id] } });
    }
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    supplierAct: bindActionCreators(SupplierActions, dispatch),
    agencyAct: bindActionCreators(AgencyActions, dispatch),
    generalAct: bindActionCreators(GeneralActions, dispatch),
    serviceAct: bindActionCreators(ServiceActions, dispatch),
    orderAct: bindActionCreators(OrderActions, dispatch),
    accountAct: bindActionCreators(AccountActions, dispatch),
    walletAct: bindActionCreators(WalletActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    order: rootState.orderReducer.order,
    orderTags: rootState.orderReducer.orderTags,
    buildServices: rootState.serviceReducer.buildServices,
    agencies: rootState.agencyReducer.agencies,
    agency: rootState.agencyReducer.agency,
    accounts: rootState.accountReducer.accounts,
    init: rootState.generalReducer.init,
    suppliers: rootState.supplierReducer.suppliers,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(ModifyOrderContainer);
