import React from 'react';
import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { Images } from '../../../ui/assets/images';

class SearchBranch extends React.Component {
  constructor(props) {
    super(props);
   
    this.state = {};
    this.remove = this.remove.bind(this);
  }

  remove(id) {
    this.props.remove(id);
  }

  renderBranch() {
    const { branchs, listScope } = this.props;

    if (_.isEmpty(branchs) === true) {
      return (<tr></tr>);
    }
    return (branchs.map((item, i) => {
      const linkTo = `/branch-modify/${item.id}`;
      const scopes = [];
      
      if (_.isEmpty(item.scopes) === false) {
        item.scopes.forEach((scope) => {
          const scopeInfo = _.find(listScope, { code: scope });
          if (_.isEmpty(scopeInfo) === false) {
            scopes.push(scopeInfo.description);
          }
        });
      }
      return (<tr key={i}>
        <td>
          <div className="d-flex align-items-center">
            <div className="mr-3">
              <Link to={linkTo}>
                <img src={Images.profile} width="40" height="40" className="rounded-circle" alt="" />
              </Link>
            </div>
            <div>
              <div className="media-title font-weight-semibold">
                <Link to={linkTo}>{item.title}</Link>
              </div>
              <span className="text-muted">{item.address}</span>
            </div>
          </div>
        </td>
        <td> {item.phone}</td>
        <td>
          <span title={scopes.join(',')} className="text-muted">
            <div className="cut-text" style={{ width: 300 }}>
              {scopes.join(', ')}
            </div>
          </span>
        </td>
        <td>{item.statistic.member}</td>
        <td><span className="text-success-600">{item.statistic.order}</span></td>
        <td >
          <Dropdown>
            <Dropdown.Toggle 
              variant={'default'}
            >
              <i className="fa fa-list-ul" aria-hidden="true"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={this.remove.bind(this, item.id)} eventKey={item.id} >
                <i className="fa fa-remove" aria-hidden="true"></i> Xóa
              </Dropdown.Item>
            
              <Dropdown.Item onClick={() => this.props.history.push(linkTo)} >
                <i className="fa fa-edit" aria-hidden="true"></i> Cập nhật
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>);
    }));
  }

  render() {
    const { branchCount } = this.props;
    
    return (
      <div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header bg-transparent header-elements-inline">
                <h4 className="card-title">Danh sách chi nhánh</h4>
                <Link
                  style={{ float: 'right' }}
                  className="btn bg-teal-400"
                  to="/branch-create" >
                  <i className="icon-add mr-2"></i> Thêm mới
                </Link>
              </div>
              <div className="table-responsive">
                <table className="table text-nowrap">
                  <thead>
                    <tr>
                      <th>Tên chi nhánh (Chiết khấu)</th>
                      <th>Số điện thoại</th>
                      <th>Quyền Được Cấp</th>
                      <th>Số nhân viên</th>
                      <th>Tổng hồ sơ</th>
                      <th style={{ width: 100 }}>Công cụ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderBranch()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      branchs: payload.branchReducer.branchs,
      listScope: payload.generalReducer.listScope,
      userInfo: payload.generalReducer.userInfo,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(SearchBranch)
);
