import * as types from './agencies.types';

const initialState = {
  agencySearchByKeyword: [],
  agencies: [],
  agency: {}
};

export default function agentcies(state = initialState, action) {
  switch (action.type) {
    case types.POST_AGENCY_DETAIL_SUCCESS: {
      return {
        ...state,
        agency: action.payload
      };
    }

    case types.POST_SEARCH_AGENCY_SUCCESS: {
      return {
        ...state,
        agencies: action.payload
      };
    }

    case types.POST_SEARCH_BY_KEYWORD_AGENCY_SUCCESS: {
      return {
        ...state,
        agencySearchByKeyword: action.payload
      };
    }
    
    default:
      return state;
  }
}
