import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select2 from 'react-select2-wrapper';
import Select from 'react-select';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { sha256 } from 'js-sha256';

import moment from 'moment';
import InputMask from 'react-input-mask';

import axios from 'axios';
import { Images } from '../../../ui/assets/images';
import UploadFileContainer from '../../ultils/uploadFile.container';

import {
  formatDate, fileUpload
} from '../../../helpers';

const AsyncForEach = require('await-async-foreach');

class ModifyAgency extends React.Component {
  constructor(props) {
    super(props);
    const { userInfo } = this.props;
    this.state = {
      countFile: 0,
      tabActive: 0,
      delegates: [],
      delegatesDefaultValue: [],
      referralDefaultValue: {},
      selectedFile: {},
      birthdayDisplay: 'DD/MM/YYYY',
      listManager: [],
      listSource: [
        { label: 'website', value: 'website' },
        { label: 'Google', value: 'google' },
        { label: 'Facebook', value: 'facebook' },
        { label: 'Khách cũ giới thiệu', value: 'customer' },
        { label: 'Khác', value: 'other' }
      ],
      locations: [],
      model: {
        id: '',
        password: '',
        rePassword: '',
        address: '',
        email: '',
        phone: '',
        area: '',
        isVerify: {
          phone: false,
          email: false
        },
        title: '',
        birthday: '',
        discountLevel: '',
        parentIdentifyCode: 'root',
        group: 'agency',
        description: '',
        isActive: true,
        files: {},
        allowIp: [],
        scopes: [],
        referral: {},
        contract: [],
        delegates: []

      },
      unLinkFile: {}
    };

    this.onUploadFile = this.onUploadFile.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.onGroupChanged = this.onGroupChanged.bind(this);
  }

  /**
   * Submit
   */
  onSubmit = async (e) => {
    try {
      e.preventDefault();
      this.props.dispatch({ type: 'SHOW_LOADING' });
      const { accountSearchByKeyword } = this.props;
      const model = Object.assign({}, this.state.model);
      const validate = this.validateOnSubmit(model);
      if (validate === true) {
        const delegates = [];
        if (_.isEmpty(model.delegates) === false) {
          model.delegates.forEach((identifyCode) => {
            const member = _.find(accountSearchByKeyword, { identifyCode });
            if (_.isEmpty(member) === false) {
              delegates.push({
                identifyCode,
                description: ''
              });
            }
          });

          model.delegates = delegates;
        }

        const identityCard = await fileUpload(this.state.selectedFile.identityCard, this.props);
        if (identityCard.code === 1) {
          model.files.identityCard = _.get(model.files, 'identityCard', []);
          _.forEach(identityCard.data, (item) => {
            model.files.identityCard.push({
              file: item,
              isVerify: false
            });
          });
        }
        //
        const contract = await fileUpload(this.state.selectedFile.contract, this.props);
        if (contract.code === 1) {
          model.files.contract = _.get(model.files, 'contract', []);
          _.forEach(contract.data, (item) => {
            model.files.contract.push({
              file: item,
              isVerify: false
            });
          });
        }
        //
        const document = await fileUpload(this.state.selectedFile.document, this.props);
        if (document.code === 1) {
          model.files.document = _.get(model.files, 'document', []);
          _.forEach(document.data, (item) => {
            model.files.document.push({
              file: item,
              isVerify: false
            });
          });
        }
        this.props.onSubmit(model);
      }
      this.props.dispatch({ type: 'HIDE_LOADING' });
    } catch (error) {
      this.props.dispatch({ type: 'HIDE_LOADING' });
      // console.log(error);
      /**
       * 
       */
    }
  }

  validateOnSubmit(model) {
    if (_.isEmpty(model.rePassword) === false || _.isEmpty(model.password) === false) {
      if (model.password !== model.rePassword) {
        NotificationManager.error('Mật khẩu và xác nhận mật khẩu không giống nhau', '', 1500);
        return false;
      }
    }

    if (_.isEmpty(model.email) === false && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(model.email) === false) {
      NotificationManager.error('Email không hợp lệ', '', 1500);
      return false;
    }

    if (_.isEmpty(model.title) === true) {
      NotificationManager.error('Họ & tên không được để trống', '', 1500);
      return false;
    }

    if (_.isEmpty(model.group) === true) {
      NotificationManager.error('Vui lòng chọn phân loại', '', 1500);
      return false;
    }
    return true;
  }

  /**
   * Upload
   */

  onUploadFile(type, e) {
    try {
      const files = _.get(e, 'target.files', false);
      if (_.isEmpty(files) === false) {
        const selectedFile = Object.assign({}, this.state.selectedFile);
        const filename = files[0].name;
        selectedFile[type] = {
          file: files[0],
          filename,
          type
        };
        this.setState({ selectedFile });
      }
    } catch (error) {
      // console.log(error);
    }
  }

  fileUpload = async () => {
    const { init, userInfo } = this.props;
    const { selectedFile } = this.state;
    if (_.isEmpty(selectedFile) === false) {
      // SHOW LOADING

      this.props.dispatch({ type: 'SHOW_LOADING' });

      const model = Object.assign({}, this.state.model);
      await AsyncForEach(selectedFile, async (element, k) => {
        if (element.file) {
          const data = new FormData();
          data.append('file', element.file);
          const config = {
            headers: {
              'content-type': 'multipart/form-data',
              Authorization: userInfo.accessToken,
              accessToken: userInfo.accessToken
            }
          };

          await axios.post(`${this.props.uploadPath}/upload-contract`, data, config).then((res) => {
            const code = _.get(res, 'data.code', false);
            if (code === 100) {
              if (element.type === 'document') {
                model.contract[0] = res.data.data.message.pathname;
              } else {
                model.files[element.type] = {
                  file: res.data.data.message.pathname,
                  isVerify: false
                };
              }
            } else {
              const message = _.get(res, 'data.data.message.message', 'File không hợp lệ');
              NotificationManager.error(message, '', 3000);
            }
          });
        }
      });
      this.setState({ model });
      this.props.dispatch({ type: 'HIDE_LOADING' });
      // HIDE LOADING
    }
  }

  /**
   * Order Function
   */
  changeTab(index) {
    this.setState({ tabActive: index });
  }

  onChangeHandleSelect2(key, e) {
    try {
      const value = _.get(e, 'target.value', false);
      if (value !== false) {
        const model = Object.assign([], this.state.model);
        model[key] = value;
        this.setState({ model });
      }
    } catch (error) {
      // console.log(error);
    }
  }

  onChangeHandle(key, e) {
    try {
      const { isDisable } = this.state;
      const model = Object.assign([], this.state.model);
      const result = {};

      if (_.isArray(e) === false) {
        switch (key) {
          case 'birthday': {
            const value = _.get(e, 'target.value', _.get(e, 'value', false));
            const dateFormat = 'YYYY/MM/DD';
            const splittedDate = value.split('/');
            if (splittedDate.length === 3) {
              const date = moment(`${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`, dateFormat);
              result.birthdayDisplay = value;
              if (date.isValid() === true) {
                model[key] = date.format();
              }
            }
            break;
          }
          case 'referral': {
            let referral = '';
            model.referral.source = _.get(e, 'target.value', _.get(e, 'value', false));
            referral = _.get(e, 'target.value', _.get(e, 'value', false));
            result.referral = referral;
            result.referralDefaultValue = e;
            break;
          }
          case 'email': {
            const value = _.get(e, 'target.value', _.get(e, 'value', false));
            model[key] = value.toLowerCase();
            break;
          }
          case 'title': {
            const value = _.get(e, 'target.value', _.get(e, 'value', false));
            model[key] = value.toUpperCase();
            break;
          }
          default:
            model[key] = _.get(e, 'target.value', _.get(e, 'value', false));
            break;
        }
      } else {
        switch (key) {
          case 'delegates': {
            const { accountSearchByKeyword } = this.props;
            const temp = [];
            const delegatesDefaultValue = [];
            e.forEach((element) => {
              const delegateInfo = _.find(accountSearchByKeyword, { identifyCode: element.value });
              temp.push(delegateInfo.identifyCode);
              delegatesDefaultValue.push({
                label: element.label,
                value: element.value
              });
            });
            result.delegatesDefaultValue = delegatesDefaultValue;
            model[key] = temp;

            break;
          }
          default: {
            const temp = [];
            e.forEach((element) => {
              temp.push(_.get(element, 'target.value', _.get(element, 'value', false)));
            });
            model[key] = temp;
            break;
          }
        }
      }
      result.model = model;

      this.setState(result);
    } catch (error) {
      // console.log(error);
    }
  }

  /**
   * 
   */

  renderDownloadFile(key) {
    const { model } = this.state;
    const files = _.get(model, `files.${key}`, false);

    const style = {
      backgroundColor: 'whitesmoke',
      paddingLeft: 20,
      marginBottom: 10,
      borderLeft: 'solid 3px darkgrey',
      height: '2.25003rem'
    };

    if (_.isEmpty(files) === true) {
      return (<div style={style} className="uniform-uploader" >
        <span className="form-text text-muted">None</span></div>);
    }

    const path = require('path');
    return (files.map((file, i) => {
      const link = `${this.props.downloadPath}${file.file}`;
      const fileExt = path.extname(link);
      const basename = path.basename(link);
      let fileExtClassName = 'icon-file-text2';
      switch (fileExt) {
        case '.docx':
        case '.doc':
          fileExtClassName = 'fa fa-file-word-o';
          break;

        default:
          fileExtClassName = 'fa fa-file-pdf-o';
          break;
      }
      return (
        <div className="uniform-uploader"
          key={`renderDownloadFile_${i}`}
          style={style}>
          <span className="form-text text-muted">
            <a href={link}>
              <i style={{ paddingRight: 10 }} className={fileExtClassName}></i>
              {basename}
            </a>
          </span>
          <i
            onClick={this.removeUploadedFile.bind(this, key, i)}
            style={{
              position: 'absolute', right: 20, top: 10, cursor: 'pointer'
            }}
            className="fa fa-remove"></i>
        </div>
      );
    }));
  }

  onGroupChanged(e) {
    const model = Object.assign({}, this.state.model);
    model.group = e.currentTarget.value;
    this.setState({ model });
  }

  removeUploadedFile(type, index, e) {
    try {
      const model = Object.assign({}, this.state.model);
      const files = _.get(model, `files.${type}`, false);
      if (files !== false) {
        model.files[type].splice(model.files[type], 1);
        this.setState({ model });
      } else {
        NotificationManager.error('Thông tin không hợp lệ', '', 3000);
      }
    } catch (error) {
      // console.log(error);
    }
  }

  setFiles(file, field) {
    try {
      if (_.isEmpty(file) === false) {
        const selectedFile = Object.assign({}, this.state.selectedFile);
        selectedFile[field] = file;
        this.setState({ selectedFile });
      }
    } catch (error) {
      // console.log(error);
    }
  }

  render() {
    const {
      tabActive,
      model,
      locations,
      listSource,
      birthdayDisplay,
      selectedFile,
      delegates,
      delegatesDefaultValue,
      referralDefaultValue
    } = this.state;
    const { accountSearchByKeyword, agency } = this.props;
    const parent = _.last(_.get(agency, 'parentPath', []));

    const tabClass = [];
    for (let index = 0; index <= 2; index += 1) {
      if (index === tabActive) {
        tabClass.push({
          header: 'navbar-nav-link active show',
          content: 'tab-pane fade active show'
        });
      }
      tabClass.push({
        header: 'navbar-nav-link',
        content: 'tab-pane fade'
      });
    }
    const discountList = this.props.buildDiscount();
    return (
      <div>
        <div className="page-header page-header-light">
          <div className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="text-center d-lg-none w-100">
              <button type="button" className="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-second">
                <i className="icon-menu7 mr-2"></i>Profile navigation</button>
            </div>
            <div className="navbar-collapse collapse" id="navbar-second">
              <ul className="nav navbar-nav">
                <li className="nav-item">
                  <a
                    onClick={this.changeTab.bind(this, 0)}
                    className={tabClass[0].header}
                    data-toggle="tab"
                  >
                    <i className="icon-menu7 mr-2"></i>
                    Thông tin cơ bản</a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={this.changeTab.bind(this, 1)}
                    className={tabClass[1].header}
                    data-toggle="tab">
                    <i className="icon-cog3 mr-2"></i>
                    Hợp đồng và văn bản</a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={this.changeTab.bind(this, 2)}
                    className={tabClass[2].header}
                    data-toggle="tab">
                    <i className="icon-file-text2 mr-2"></i>
                    Tài liệu đính kèm ({this.state.countFile})</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="content">
            <div className="d-md-flex align-items-md-start">
              <div className="tab-content w-100 overflow-auto order-2 order-md-1">
                <div className={tabClass[0].content} id="overview">
                  <div className="card">
                    <div className="card-body pb-0">
                      <div className="media flex-column flex-md-row mb-2">
                        <a href="#" className="align-self-md-center mr-md-3 mb-2 mb-md-0">
                          <img src={Images.microsoft} className="rounded" width="44" height="44" alt="" />
                        </a>
                        <div className="media-body">
                          <h5 className="media-title font-weight-semibold">Cập nhật khách hàng / CTV</h5>
                          <ul className="list-inline list-inline-dotted text-muted mb-0">
                            <li className="list-inline-item">Thuôc quản lý của: {_.get(parent, 'title', '')}</li>
                            <li className="list-inline-item">{formatDate(_.get(this.props, 'agency.createdAt'))}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <form onSubmit={this.onSubmit}>
                      <fieldset className="card-body">
                        <h6 className="font-weight-semibold mb-3">Thông tin khách hàng / CTV</h6>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Họ &amp; Tên: <span className="text-danger">*</span></label>
                              <input
                                value={model.title}
                                onChange={this.onChangeHandle.bind(this, 'title')}
                                type="text"
                                required
                                className="form-control"
                                placeholder="Nhập đầy đủ họ tên"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Số điện thoại: <span className="text-danger">*</span></label>
                              <input
                                value={model.phone}
                                onChange={this.onChangeHandle.bind(this, 'phone')}
                                type="text"
                                name="digits"
                                className="form-control"
                                required
                                placeholder="Nhập số điện thoại" />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Mật khẩu:</label>
                              <input
                                value={model.password}
                                onChange={this.onChangeHandle.bind(this, 'password')}
                                type="password"
                                name="password"
                                id="password"
                                autoComplete="off"
                                className="form-control"
                                placeholder="Nhập Mật khẩu" />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Nhập lại Mật khẩu:</label>
                              <input
                                value={model.rePassword}
                                onChange={this.onChangeHandle.bind(this, 'rePassword')}
                                type="password"
                                autoComplete="off"
                                name="repeat_password"
                                className="form-control"
                                placeholder="Nhập lại Mật khẩu"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>Khu vực</label>
                              <Select2
                                value={model.area}
                                onChange={this.onChangeHandle.bind(this, 'area')}
                                style={{ width: '100%' }}
                                className="form-control select-search select2-hidden-accessible"
                                data={locations}
                                options={{
                                  placeholder: 'Tất cả khu vực'
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label>Cấp Chiết khấu</label>
                            <Select
                              value={_.find(discountList, { value: model.discountLevel })}
                              onChange={this.onChangeHandle.bind(this, 'discountLevel')}
                              options={discountList}
                            />
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>Chia sẻ quản lý</label>
                              <Select
                                onChange={this.onChangeHandle.bind(this, 'delegates')}
                                options={this.props.renderDelegate(accountSearchByKeyword)}
                                value={delegatesDefaultValue}
                                isMulti
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">

                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>Email:</label>
                              <input
                                value={model.email}
                                onChange={this.onChangeHandle.bind(this, 'email')}
                                type="email"
                                className="form-control"
                                placeholder="Nhập email" />
                            </div>
                          </div>

                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>Sinh nhật:</label><br />
                              <InputMask
                                value={birthdayDisplay}
                                className="form-control"
                                mask="99/99/9999"
                                onChange={this.onChangeHandle.bind(this, 'birthday')}
                              />

                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>Địa chỉ :</label>
                              <input
                                value={model.address}
                                onChange={this.onChangeHandle.bind(this, 'address')}
                                type="text"
                                className="form-control"
                                required=""
                                placeholder="Nhập địa chỉ"
                              />
                            </div>
                          </div>
                        </div>

                      </fieldset>
                      <fieldset className="card-body">
                        <h6 className="font-weight-semibold mb-3">Upload thông tin khách hàng</h6>
                        <div className="form-group">
                          <label>Upload chứng minh nhân dân:</label>
                          <UploadFileContainer
                            setFiles={this.setFiles.bind(this)}
                            name="identityCard"
                          />
                          <span className="form-text text-muted">Accepted formats: doc, docx, pdf, bmp, png, gif, jpg, jpeg</span>
                          {this.renderDownloadFile('identityCard')}
                        </div>
                        <div className="form-group">
                          <label>Upload hợp đồng: </label>
                          <UploadFileContainer
                            setFiles={this.setFiles.bind(this)}
                            name="contract"
                          />
                          <span className="form-text text-muted">Accepted formats: doc, docx, pdf, bmp, png, gif, jpg, jpeg</span>
                          {this.renderDownloadFile('contract')}
                        </div>
                      </fieldset>
                      <fieldset className="card-body">
                        <h6 className="font-weight-semibold mt-1 mb-3">Thông tin thêm</h6>
                        <div className="form-group">
                          <label>Cách khách hàng biết đến VNHUB ?</label>
                          <Select
                            onChange={this.onChangeHandle.bind(this, 'referral')}
                            options={listSource}
                            value={referralDefaultValue}
                          />
                        </div>
                        <div className="form-group">
                          <label>Phân loại:</label>

                          <div className="form-check">
                            <label className="form-check-label">
                              <div className="uniform-choice">
                                <span className={model.group === 'customer' ? 'checked' : ''}>

                                  <input
                                    checked={model.availability === 'customer'}
                                    onChange={this.onGroupChanged}
                                    value="customer"
                                    type="radio"
                                    name="availability"
                                    className="form-input-styled"
                                    data-fouc="true"
                                  />
                                </span>
                              </div>Khách lẻ
                            </label>
                          </div>

                          <div className="form-check mt-2">
                            <label className="form-check-label">
                              <div className="uniform-choice">
                                <span className={model.group === 'agency' ? 'checked' : ''}>
                                  <input
                                    checked={model.group === 'agency'}
                                    onChange={this.onGroupChanged}
                                    value="agency"
                                    type="radio"
                                    name="availability"
                                    className="form-input-styled"
                                    data-fouc="true"
                                  />
                                </span>
                              </div>Đại lý
                            </label>
                          </div>

                          <div className="form-check">
                            <label className="form-check-label">
                              <div className="uniform-choice">
                                <span className={model.group === 'collaborator' ? 'checked' : ''}>

                                  <input
                                    checked={model.availability === 'collaborator'}
                                    onChange={this.onGroupChanged}
                                    value="collaborator"
                                    type="radio"
                                    name="availability"
                                    className="form-input-styled"
                                    data-fouc="true"
                                  />
                                </span>
                              </div>Cộng tác viên
                            </label>
                          </div>

                          <div className="form-check">
                            <label className="form-check-label">
                              <div className="uniform-choice">
                                <span className={model.group === 'supplier' ? 'checked' : ''}>

                                  <input
                                    checked={model.availability === 'supplier'}
                                    onChange={this.onGroupChanged}
                                    value="supplier"
                                    type="radio"
                                    name="availability"
                                    className="form-input-styled"
                                    data-fouc="true"
                                  />
                                </span>
                              </div>Nhà cung cấp
                            </label>
                          </div>
                        </div>
                        
                        <div className="form-group mb-0">
                          <label>Ghi chú:</label>
                          <textarea
                            onChange={this.onChangeHandle.bind(this, 'description')}
                            value={model.description}
                            name="additional-info"
                            rows="4"
                            cols="4"
                            placeholder="Nhập các thông tin cần lưu ý về khách hàng."
                            className="form-control"
                          ></textarea>
                        </div>
                      </fieldset>
                      <fieldset className="card-body">
                        <button type="submit" className="btn bg-blue">Lưu lại <i className="icon-paperplane ml-2"></i></button>
                        <button type="submit" className="btn btn-light ml-3">Cancel</button>
                      </fieldset>
                    </form>
                  </div>
                </div>
                <div className={tabClass[1].content} id="contract">
                  <div className="card">
                    <div className="card-header header-elements-inline">
                      <h5 className="card-title">Quản lý hợp đồng và các quyết định khen thưởng</h5>
                    </div>
                    <div className="card-body">
                      <form
                        onSubmit={this.onSubmit}
                      >
                        <fieldset className="card-body">
                          <div className="form-group">
                            <label>Upload văn bản liên quan đến nhân sự này:<span className="text-danger">*</span></label>
                            <UploadFileContainer
                              setFiles={this.setFiles.bind(this)}
                              name="document"
                            />
                            <span className="form-text text-muted">Accepted formats: doc, docx, pdf, bmp, png, gif, jpg, jpeg</span>
                            {this.renderDownloadFile('document')}
                          </div>
                        </fieldset>

                        <div className="text-right">

                          <button type="submit" className="btn bg-blue">Lưu lại <i className="icon-paperplane ml-2"></i></button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <div className={tabClass[2].content} id="file">
                  <div className="card">
                    <div className="card-header header-elements-inline">
                      <h5 className="card-title">
                        Quản lý hợp đồng và các quyết định khen thưởng
                      </h5>
                    </div>
                    <div className="card-body">
                      <form onSubmit={this.onSubmit}>
                        <fieldset className="card-body">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <label>Chứng minh nhân dân</label>
                                  {this.renderDownloadFile('identityCard')}

                                </div>
                              </div>

                              <div className="col-sm-4">
                                <div className="form-group">
                                  <label>Hợp đồng</label>
                                  {this.renderDownloadFile('contract')}

                                </div>
                              </div>

                              <div className="col-sm-4">
                                <div className="form-group">
                                  <label>Văn bản liên quan</label>
                                  {this.renderDownloadFile('document')}
                                </div>
                              </div>

                            </div>
                          </div>
                        </fieldset>

                        <div className="text-right">
                          <button type="submit" className="btn bg-blue">Lưu lại <i className="icon-paperplane ml-2"></i></button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotificationContainer />
      </div>

    );
  }

  componentDidMount() {
    try {
      /**
       * 
       */
      const locations = this.props.buildLocation();
      this.setState({ locations });
    } catch (error) {
      // console.log(error);
      /**
       * 
       */
    }
  }

  componentDidUpdate(oldProps) {
    try {
      /**
       * 
       */
      const newProps = this.props;

      // if (oldProps.accounts !== newProps.accounts) {
      //   const delegates = this.props.getDelegateMember();
      //   this.setState({ ...delegates });
      // }

      if (oldProps.agency !== newProps.agency) {
        const {
          agency, userInfo
        } = this.props;
        const { listSource } = this.state;
        const model = {
          id: _.get(agency, 'id', ''),
          password: '',
          rePassword: '',
          address: _.get(agency, 'address', ''),
          email: _.get(agency, 'email', ''),
          phone: _.get(agency, 'phone', ''),
          area: _.get(agency, 'area', ''),

          isVerify: {
            phone: _.get(agency, 'isVerify.phone', false),
            email: _.get(agency, 'isVerify.email', false)
          },
          discountLevel: _.get(agency, 'discountLevel', ''),
          title: _.get(agency, 'title', ''),
          birthday: _.get(agency, 'birthday', ''),
          parentIdentifyCode: _.get(agency, 'parentIdentifyCode', 'root'),
          group: _.get(agency, 'group', ''),
          description: _.get(agency, 'description', ''),
          isActive: _.get(agency, 'isActive', true),
          files: _.get(agency, 'files', {}),
          referral: _.get(agency, 'referral', {}),
          contract: _.get(agency, 'contract', []),
          allowIp: [],
          delegates: []
        };
        /**
         * 
         */
        let referralDefaultValue = {};
        const referralValue = _.get(agency, 'referral.source', false);
        if (referralValue) {
          referralDefaultValue = _.find(listSource, { value: referralValue });
        }
        let birthdayDisplay = 'DD/MM/YYYY';
        if (agency.birthday) {
          const date = new Date(agency.birthday);
          birthdayDisplay = formatDate(agency.birthday);
        }
        const delegatesDefaultValue = [];
        const delegates = _.get(agency, 'delegates', []);
        if (_.isEmpty(delegates) === false) {
          delegates.forEach((element) => {
            model.delegates.push(element.identifyCode);
            delegatesDefaultValue.push({
              label: element.title,
              scopes: element.scope,
              value: element.identifyCode
            });
          });
        }

        /**
         * contract
         */
        const selectedFile = {};
        const unLinkFile = {};

        if (_.isEmpty(model.contract) === false) {
          const fileArr = model.contract[0].split('/');
          selectedFile.document = {
            path: model.contract[0],
            filename: fileArr[fileArr.length - 1],
            type: 'document'
          };
        } else {
          unLinkFile.document = true;
        }
        /**
         * files
         */
        let count = 0;
        if (_.isEmpty(model.files) === false) {
          _.forEach(model.files, (files) => {
            count += files.length;
          });
        }

        this.setState({
          model,
          unLinkFile,
          selectedFile,
          countFile: count,
          delegatesDefaultValue,
          birthdayDisplay,
          referralDefaultValue
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      accountSearchByKeyword: payload.accountReducer.accountSearchByKeyword,
      accounts: payload.accountReducer.accounts,
      agency: payload.agencyReducer.agency,
      userInfo: payload.generalReducer.userInfo,
      uploadPath: payload.generalReducer.uploadPath,
      downloadPath: payload.generalReducer.downloadPath,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(ModifyAgency)
);
