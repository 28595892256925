import React from 'react';
import { Tooltip } from 'reactstrap';
import _, { split } from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import Select2 from 'react-select2-wrapper';
import { Tabs, Tab } from 'react-bootstrap';
import cx from 'classnames';

import Autocomplete from '@celebryts/react-autocomplete-tags';
import 'moment-timezone';
import Cleave from 'cleave.js/react';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import

import InputMask from 'react-input-mask';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { DelayInput } from 'react-delay-input';
import SelectStateContainer from '../../ultils/selectState.container';
import UploadFileContainer from '../../ultils/uploadFile.container';
import { TAG_STYLE, WALLET_SCOPE } from '../../../configs/constants';

import {
  formatDate, fileUpload,
  buildSetting,
  buildListCountryArrival,
  buildListAirportArrival,
  buildListProcessingTimeArrival,
  buildListPurposeArrival,
  buildListExtraServiceArrival,
  immigrationFee,
  isStaff, checkScope,
  isWorkingDay, addWorkingDay
} from '../../../helpers';

class CreateImmigrationOrder extends React.Component {
  constructor(props) {
    super(props);
    this.input = {};
    this.state = {
      service: {},
      deposit: [0],
      forceAmount: 0,
      documentCount: 0,
      processingTimeFeeIndex: 0,
      isAllowUpload: 0,
      isAllowUploadDescription: '',
      typeOfSubmit: false,
      tooltipOpen: false,
      expireAtWarning: false,
      discountMessage: false,
      nationalDefaultValue: { value: 'all', label: 'Chọn quốc tịch' },
      stateDefaultValue: { label: 'Đang chờ xử lý', value: 'PENDING', color: '#F3E0EC' },
      sexDefaultValue: { value: 'all', label: 'Chọn giới tính' },
      supplierDefaultValue: { value: 0, label: 'Không' },
      value: '',
      predictions: [],
      showContextMenu: {
        display: false,
        style: {}
      },
      selectedFile: [],
      selectedProfileFile: {},
      selectedExtraInfoFile: {},
      isDisable: true,
      dateDisplay: {
        arrivalAt: 'DD/MM/YYYY',
        issuedAt: 'DD/MM/YYYY',
        supplierSendAt: 'DD/MM/YYYY'
      },
      estimateFee: {
        numberOfProfile: 1,
        serviceFee: 0,
        countryArrivalFees: [],
        processingTimeFee: 0,
        airportArrivalFee: 0,
        extraServiceFee: 0,
        guaranteeFee: 0
      },

      model: {
        supplierId: 0,
        supplierAmount: 0,
        paidSupplierAmount: 0,
        vat: '',
        supplierSendAt: '',
        customizeField: {},
        customerIdentifyCode: '',
        tag: [],
        serviceIdentifyCode: '',
        description: '',
        state: 'PENDING',
        amount: 0,
        purpose: '',
        airport: '',
        arrivalAt: '',
        processingTime: '',
        extraService: [],
        profile: [],
        processingTimeArrival: {}
      },
      genders: [
        { value: 'male', label: 'Nam' },
        { value: 'female', label: 'Nữ' }
      ],
      contries: [],
      states: [{ label: 'Đang chờ xử lý', value: 'PENDING', color: '#F3E0EC' }],
      parentIdentifies: [],
      profiles: {
        firstName: '',
        lastName: '',
        fullName: '',
        sex: {},
        birthday: '',
        countryArrival: 'VN',
        passport: {
          number: ''
        },
        files: [],
        description: '',
        isDifficult: false,
        namespace: 'default',
        note: '',
        extraInfo: {}
      },
      discount: {},
      isWorkingDayStatus: isWorkingDay(),
      // isWorkingDayStatus: true,
      isBussinessCase: false,
      listPurposeArrival: [{ value: 'all', label: 'Chọn thời gian lưu trú' }],
      listAirportArrival: [{ value: 'all', label: 'Chọn sân bay' }],
      processingTimeArrivals: [],
      guarantee: {},
      settings: {},
      visitingExtraInfo: { value: 1, label: 'Vợ' },
      suppliers: [],
      customizeField: [],
      guaranteeList: [
        { value: 1, label: 'Vợ' },
        { value: 2, label: 'Chồng' },
        { value: 3, label: 'Cha' },
        { value: 4, label: 'Mẹ' },
        { value: 5, label: 'Con' }
      ]

    };
    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.onChangeServiceCodeHandle = this.onChangeServiceCodeHandle.bind(this);
    this.onChangeArrivalAtHandle = this.onChangeArrivalAtHandle.bind(this);
    this.onUploadFile = this.onUploadFile.bind(this);
    this.toggleTips = this.toggleTips.bind(this);
    this.setFiles = this.setFiles.bind(this);
    this.callbackWallet = this.callbackWallet.bind(this);
  }

  callbackWallet() {
    try {
      const { wallet, socketBalance } = this.props;
      if (_.isEmpty(socketBalance) === false) {
        if (wallet.accountId === socketBalance.accountId && wallet !== socketBalance) {
          this.props.dispatch({
            type: 'POST_SEARCH_WALLET_DETAIL_SUCCESS',
            payload: socketBalance
          });
          this.toggleWallet();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async validateCustomizeFieldOnSubmit(model) {
    return new Promise((resolve, reject) => {
      const customizeField = this.state.customizeField;
      let flag = true;
      customizeField.map((field) => {
        if (field.require === true) {
          if (_.isArray(model.customizeField[field.key])) {
            console.log(model.customizeField[field.key]);
            if (_.get(model.customizeField[field.key], '1', '') === '') {
              NotificationManager.error(`${field.title} không được để trống`, '', 1500);
              flag = false;
            }
          } else if (!model.customizeField[field.key] || _.isEmpty(model.customizeField[field.key])) {
            NotificationManager.error(`${field.title} không được để trống`, '', 1500);
            flag = false;
          }
        }
        return true;
      });
      resolve(flag);
    });
  }

  toggleWallet = () => {
    this.setState(prevstate => ({
      displayWallet: !prevstate.displayWallet
    }));

    setTimeout(() => {
      this.setState(prevstate => ({
        displayWallet: !prevstate.displayWallet
      }));
    }, 3000);
  };

  checkBalance(amount = 0) {
    const { init, wallet, agency } = this.props;
    let agencyInfo = _.clone(agency);
    const isStaffStatus = isStaff(this.props);
    if (isStaffStatus === false) {
      agencyInfo = _.get(init, 'accountInfo', {});
    }
    if (_.isEmpty(wallet) === true) {
      return false;
    }
    if (_.isNumber(amount) === false || amount < 0) {
      return false;
    }
    if (wallet.amount < amount) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <h2>Số dư không đủ</h2>
              <p>
                Tài khoản của <span style={{ fontWeight: 'bold' }}>{agencyInfo.title}</span> không đủ số dư để tạo đơn hàng.<br />
                  Vui lòng nạp thêm tiền vào tài khoản.
                <button className="btn btn-info" type="button"
                  onClick={() => {
                    if (isStaffStatus === true) {
                      window.open(`/account-wallet/${agencyInfo.id}`, '_blank');
                    } else {
                      window.open('/liability', '_blank');
                    }
                  }}
                  style={{ padding: '0px 5px' }}
                >Click vào đây để nạp</button>
              </p>
              <button
                onClick={() => { onClose(); }}
                className="btn btn-secondary"
                type="button"
                style={{ marginRight: 20 }}
              >Tôi đã nạp
              </button>

            </div>
          );
        },
        closeOnClickOutside: false

      });
    }
    return true;
  }

  async validateInputOnSubmit() {
    return new Promise((resolve, reject) => {
      const input = this.input;
      let flag = true;
      _.forEach(input, (field) => {
        if (flag === false) return false;
        if (field.isActive === true) {
          if (field.required === true) {
            if (_.isUndefined(field.e.value) === false) {
              if (_.get(field, 'e.value', '') === '' || _.get(field, 'e.value', '') === '__/__/____') {
                NotificationManager.error(`${field.title} không được để trống!`, '', 1500);
                field.e.focus();
                flag = false;
              } 
            }
            if (_.isUndefined(field.e.state) === false && !_.isNull(field.e.state)) {
              if (_.get(field, 'e.state.value.value', '') === '') {
                NotificationManager.error(`${field.title} không được để trống!`, '', 1500);
                field.e.focus();
                flag = false;
              } 
            }
            if (_.get(field, 'e.constructor.name', null) === 'Select2') {
              if (_.get(field, 'e.props.value', '') === '') {
                NotificationManager.error(`${field.title} không được để trống!`, '', 1500);
                field.e.focus();
                flag = false;
              } 
            }
          } 
        }
        return true;
      });
      resolve(flag);
    });
  }

  onSubmit = async (e) => {
    try {
      e.preventDefault();
      // SHOW LOADING
      this.props.dispatch({ type: 'SHOW_LOADING' });
      const { init, agency } = this.props;
      const model = _.clone(this.state.model);
      const profileState = _.clone(this.state.profiles);
      const validateInputOnSubmit = await this.validateInputOnSubmit();
      if (validateInputOnSubmit === false) return;
      const validate = this.validateOnSubmit();
      const validateCustomizeField = await this.validateCustomizeFieldOnSubmit(model);
      if (validateCustomizeField === true && validate === true) {
        const profiles = [];
        let error = false;

        let agencyInfo = _.clone(agency);
        if (isStaff(this.props) === false) {
          agencyInfo = _.get(init, 'accountInfo', {});
        }

        model.customerIdentifyCode = agencyInfo.identifyCode;
        if (error === false) {
          const fullname = _.trim(_.get(profileState, 'fullName', '').replace(/\s+/g, ' '));
          const fullnameArr = fullname.split(' ');
          const lastName = _.clone(fullnameArr[0]);
          delete fullnameArr[0];
          if (fullnameArr.lenght <= 1) {
            NotificationManager.error('Vui lòng nhập đầy đủ họ tên', '', 1500);
            error = true;
          }
          const profile = {
            firstName: fullnameArr.join(' ').trim(),
            lastName,
            fullName: profileState.fullName,
            sex: _.get(profileState, 'sex.value'),
            birthday: profileState.birthday,
            countryArrival: _.get(profileState, 'countryArrival.value', ''),
            passport: profileState.passport,
            countryNamespace: profileState.namespace,
            files: [],
            description: _.clone(model.description),
            extraInfo: profileState.extraInfo
          };

          //
          const files = await fileUpload(this.state.selectedProfileFile, this.props);

          if (files.code === 1) {
            profile.files = files.data;
          } 

          const extraInfoFiles = await fileUpload(this.state.selectedExtraInfoFile, this.props);

          if (extraInfoFiles.code === 1) {
            profile.extraInfo.files = extraInfoFiles.data;
          }

          profiles.push(profile);
        }

        const airport = _.get(model, 'airportArrival.value', false);
        const extraServiceArrival = _.get(model, 'extraService', false);
        const processingTime = _.get(model, 'processingTimeArrival.value', false);
        const purpose = _.get(model, 'purposeArrival.value', false);

        const extraService = [];
        if (_.isEmpty(extraServiceArrival) === false) {
          _.forEach(extraServiceArrival, (item) => {
            extraService.push(item.value);
          });
        }
        if (
          _.isNumber(airport) === false
          || _.isNumber(processingTime) === false
          || _.isNumber(purpose) === false) {
          error = true;
        }

        if (error === false) {
          const args = {
            customizeField: model.customizeField,
            supplierAmount: model.supplierAmount,
            paidSupplierAmount: model.paidSupplierAmount,
            vat: model.vat,
            supplierId: model.supplierId,
            supplierSendAt: model.supplierSendAt,
            customerIdentifyCode: model.customerIdentifyCode,
            serviceIdentifyCode: model.serviceIdentifyCode,
            description: model.description,
            state: model.state,
            amount: model.amount,
            stay: purpose,
            airport,
            processingTime,
            extraService,
            arrivalAt: model.arrivalAt,
            profile: profiles
          };
          if (profiles.length > 1) {
            delete args.amount;
          }
          this.props.onSubmit(args, this.state.typeOfSubmit);
        }
      }
      // HIDE LOADING
      this.props.dispatch({ type: 'HIDE_LOADING' });
    } catch (error) {
      // HIDE LOADING
      this.props.dispatch({ type: 'HIDE_LOADING' });
    } finally {
      this.props.dispatch({ type: 'HIDE_LOADING' });
    }
  }

  submit(key, e) {
    this.setState({ typeOfSubmit: key });
  }

  validateOnSubmit() {
    try {
      const {
        settings, model,
        profiles, selectedProfileFile,
        isBussinessCase, selectedExtraInfoFile,
        service, processingTimeArrivals
      } = this.state;
      if (!model.serviceIdentifyCode) {
        NotificationManager.error('Vui lòng chọn mục đích nhập cảnh', '', 3000);
        return false;
      }
      if (_.isEmpty(processingTimeArrivals)) {
        NotificationManager.error('Dịch vụ này không làm việc ngày cuối tuần & ngày lễ', '', 15000);
        return false;
      }
      if (model.arrivalAt) {
        const diff = moment().diff(model.arrivalAt, 'days');
        if (diff > 0) {
          NotificationManager.error('Ngày nhập cảnh nhỏ hơn ngày hiện tại ', '', 1500);
          return false;
        }

        const purposeArrival = model.processingTimeArrival;
        const arrivalAt = addWorkingDay(purposeArrival.days, purposeArrival.hours, purposeArrival.minutes);
        const validDiff = moment(arrivalAt).diff(model.arrivalAt, 'days');
        if (validDiff > 0) {
          const formatedArrivalAt = arrivalAt.format();

          NotificationManager.error(`Ngày nhập cảnh tối thiểu từ : ${formatDate(formatedArrivalAt)}`, '', 1500);
          return false;
        }
      } else {
        NotificationManager.error('Chưa chọn ngày nhập cảnh', '', 1500);
        return false;
      }

      const serviceSettings = buildSetting(_.get(service, 'settings', '{}'), profiles, service.identifyCode);
      const settingProcessingTime = _.get(serviceSettings, 'processingTime', {});
      const settingStay = _.get(serviceSettings, 'stay', {});
      const airport = _.get(model, 'airportArrival.value', false);
      const processingTime = _.get(model, 'processingTimeArrival.value', false);
      const purpose = _.get(model, 'purposeArrival.value', false);
      const fullname = _.trim(_.get(profiles, 'fullName', '').replace(/\s+/g, ' '));
      if (!fullname) {
        NotificationManager.error('Họ tên không được để trống', '', 1500);
        return false;
      }

      const fullnameArr = fullname.split(' ');
      if (fullnameArr.length <= 1) {
        NotificationManager.error('Vui lòng nhập đầy đủ họ tên', '', 1500);
        return false;
      }

      if (!model.serviceIdentifyCode) {
        NotificationManager.error('Loại dịch vụ không được để trống', '', 1500);
        return false;
      }

      if (_.isNumber(airport) === false) {
        NotificationManager.error('Chưa chọn sân bay', '', 1500);
        return false;
      }
      
      if (
        _.isEmpty(settingProcessingTime) === false && _.isNumber(processingTime) === false) {
        NotificationManager.error('Chưa chọn thời gian xử lý', '', 1500);
        return false;
      }

      if (_.isEmpty(settingStay) === false && _.isNumber(purpose) === false) {
        NotificationManager.error('Chưa chọn mục thời gian lưu trú', '', 1500);
        return false;
      }

      // if (_.get(settings, 'info.type', 'NORMAL') === 'EXTREMELY_DIFFICULT' && _.isEmpty(selectedProfileFile) === true) {
      //   NotificationManager.error('Vui lòng bổ sung hồ sơ', '', 1500);
      //   return false;
      // }

      if (isBussinessCase === true) {
        const type = _.get(profiles, 'extraInfo.type', false);
        const address = _.get(profiles, 'extraInfo.address', '');
        if (type === 1 && (_.isEmpty(address) === true || _.isEmpty(selectedExtraInfoFile) === true)) {
          NotificationManager.error('Vui lòng bổ sung thông tin công tác', '', 1500);
          return false;
        }
      }

      let error = false;
      if (
        _.isEmpty(profiles.fullName) === true
        || _.isEmpty(profiles.sex) === true
        || _.isEmpty(profiles.birthday) === true
        || _.isEmpty(profiles.countryArrival) === true
        || _.isEmpty(_.get(profiles, 'passport.number', false)) === true
      ) {
        error = true;
      }

      if (error === true) {
        NotificationManager.error('Vui lòng nhập thông tin hồ sơ', '', 1500);
        return false;
      }
      return true;
    } catch (error) {
      NotificationManager.error('Thông tin không hợp lệ', '', 1500);
      return false;
    }
  }

  toggleTips() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  /**
   * File & Upload
   */

  setProfileFiles(selectedFile) {
    try {
      if (_.isEmpty(selectedFile) === false) {
        this.setState({ selectedProfileFile: selectedFile });
      }
    } catch (error) {
      console.log(error);
    }
  }

  setExtraInfoFile(selectedFile) {
    try {
      if (_.isEmpty(selectedFile) === false) {
        this.setState({ selectedExtraInfoFile: selectedFile });
      }
    } catch (error) {
      console.log(error);
    }
  }

  setFiles(selectedFile) {
    try {
      this.setState({ selectedFile });
    } catch (error) {
      console.log(error);
    }
  }

  onUploadFile(key, e) {
    try {
      const files = _.get(e, 'target.files', false);
      if (_.isEmpty(files) === false) {
        this.setState({ selectedFile: files });
        this.fileUpload(files, key);
      }
    } catch (error) {
      // console.log(error);
    }
  }

  fileUpload(files, type) {
    try {
      const { userInfo } = this.props;
      const data = new FormData();
      data.append('file', files[0]);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: userInfo.accessToken,
          accessToken: userInfo.accessToken
        }
      };

      axios.post(`${this.props.uploadPath}/upload-contract`, data, config).then((res) => {
        const code = _.get(res, 'data.code', false);
        if (code === 100) {
          const model = Object.assign({}, this.state.model);
          model.profile.files[0] = res.data.data.message.pathname;
          const selectedFileName = res.data.data.message.filename;
          this.setState({ model, selectedFileName });
        } else {
          const message = _.get(res, 'data.data.message.message', 'File không hợp lệ');
          NotificationManager.error(message, '', 3000);
        }
      });
    } catch (error) {
      // console.log(error);
    }
  }

  /**
   * Handle event
   */

  estimateHandle(estimateFee) {
    const { service, model, profiles } = this.state;
    const immigrationFeeInfo = immigrationFee(estimateFee, service, profiles, model);
    this.checkBalance(_.get(immigrationFeeInfo, 'generalInfo.amount', 0));
    return immigrationFeeInfo || {};
  }

  onCheckCustomizeFieldHandle(key, type, value) {
    const model = Object.assign({}, this.state.model);
    if (type === 'CHECKBOX') {
      const currentValue = _.get(model, `customizeField.${key}`, []);
      if (_.includes(currentValue, value)) {
        return true;
      }
      return false;
    }
    if (type === 'RADIO') {
      const currentValue = _.get(model, `customizeField.${key}`, '');
      if (currentValue === value) {
        return true;
      }
    }
    if (type === 'LISTBOX') {
      const currentValue = _.get(model, `customizeField.${key}`, '');
      const control = _.find(this.state.customizeField, { key });
      if (_.isUndefined(control)) return false;
      const items = this.onConvertDataSettingListbox(control.items);
      const defaultValue = _.find(items, { value: currentValue });
      if (_.isUndefined(defaultValue)) return null;
      return defaultValue;
    }
    return false;
  }

  onChangeCustomizeFieldHandle(key, type, e) {
    const model = Object.assign({}, this.state.model);
    const customizeField = this.state.customizeField;
    if (type === 'RADIO') {
      const code = e.target.value;
      model.customizeField[key] = code;
    }
    if (type === 'CHECKBOX') {
      const code = e.target.value;
      let currentValue = _.get(model, `customizeField.${key}`, []);
      if (e.target.checked === true) {
        currentValue.push(code);
        currentValue = _.uniq(currentValue);
      } else {
        currentValue = _.remove(currentValue, v => (v !== code));
      }
      model.customizeField[key] = currentValue;
    }
    if (type === 'INPUT_TEXT' || type === 'INPUT_DATE') {
      const code = e.target.value;
      model.customizeField[key] = code;
    }
    if (type === 'LISTBOX') {
      const value = _.get(e, 'target.value', _.get(e, 'value', false));
      model.customizeField[key] = value;
    }
    const result = {};
    result.model = model;
    this.setState(result);
  }

  onChangeProfileHandle(key, e) {
    try {
      const result = {};
      const profiles = _.clone(this.state.profiles);
      const estimateFee = _.clone(this.state.estimateFee);
      const model = _.clone(this.state.model);
      if (_.isArray(e) === false) {
        switch (key) {
          case 'countryArrival': {
            profiles.countryArrival = e;
            estimateFee.countryArrivalFees = {
              arrival: _.get(e, 'amount.arrival', 0),
              workPermit: _.get(e, 'amount.workPermit', 0),
              visaExtension: _.get(e, 'amount.visaExtension', 0),
              temporaryResidence: _.get(e, 'amount.temporaryResidence', 0),
              label: e.label
            };
            estimateFee.processingTimeFee = 0;
            profiles.namespace = e.namespace || 'default';
            profiles.isDifficult = e.isDifficult;
            profiles.note = e.note;
            result.listPurposeArrival = [{ value: 'all', label: 'Chọn thời gian lưu trú' }];
            result.listAirportArrival = [{ value: 'all', label: 'Chọn sân bay' }];
            result.processingTimeArrivals = [];
            model.serviceIdentifyCode = '';
            model.purposeArrival = '';
            model.airportArrival = '';
            model.processingTime = '';
            model.processingTimeArrival = {};
            model.selectedProfileFile = {};

            break;
          }
          case 'birthday': {
            const value = _.get(e, 'target.value', _.get(e, 'value', false));
            const dateFormat = 'YYYY/MM/DD';
            const splittedDate = value.split('/');

            if (splittedDate.length === 3 && splittedDate[2].length === 4) {
              const date = moment(`${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`, dateFormat);
              const dateDisplay = Object.assign({}, this.state.dateDisplay);
              dateDisplay[key] = value;
              result.dateDisplay = dateDisplay;
              if (date.isValid() === true) {
                profiles[key] = date.format();
              }
            }
            break;
          }

          case 'identifyCard': {
            profiles.passport = {
              number: _.get(e, 'target.value', _.get(e, 'value', false))

            };
            break;
          }
          case 'sex': {
            profiles[key] = e;
            break;
          }
          default:
            profiles[key] = _.get(e, 'target.value', _.get(e, 'value', false));
            break;
        }
      }
      const immigrationFeeInfo = this.estimateHandle(estimateFee);
      model.amount = _.get(immigrationFeeInfo, 'generalInfo.amount', 0);
      result.forceAmount = 0;
      result.model = model;
      result.profiles = profiles;
      result.estimateFee = estimateFee;
      this.setState(result);
    } catch (error) {
      // 
      console.log(error);
    }
  }

  onChangeServiceCodeHandle(e) {
    try {
      const { profiles, model } = this.state;
      let value = _.get(e, 'target.value', _.get(e, 'value', 0));
      if (_.isEmpty(value) === true) {
        value = model.serviceIdentifyCode || '';
      }
      const data = this.serviceHandle(this.props, this.state, value);
      if (_.isEmpty(data) === false) {
        this.setState({
          ...data.result,
          model: data.model,
          estimateFee: data.estimateFee
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  serviceHandle(props, state, value) {
    try {
      const { init, agency } = props;
      const {
        parentIdentifies, profiles,
        estimateFee, isWorkingDayStatus, dateDisplay
      } = state;
      const model = _.clone(state.model);
      model.extraService = [];
      let agencyInfo = _.clone(agency);
      if (isStaff(props) === false) {
        agencyInfo = _.get(init, 'accountInfo', {});
      }
      const result = {};
      const discountLevel = _.get(agencyInfo, 'discountLevel', '');
      const service = this.props.getServiceDetail(value);
      const documentCount = _.get(service, 'document', []).length;
      /**
       * Nếu dịch vụ là công tác
       * Xóa file Business nếu đổi loại công tác
       */
      result.isBussinessCase = false;
      profiles.extraInfo = {};
      result.selectedExtraInfoFile = {};
      const countryArrival = _.get(profiles, 'countryArrival', false);
      if (service.identifyCode === 'kJmjrkXg8' && _.isEmpty(countryArrival) === false) { // Du lịch
        const arrivalFee = _.get(countryArrival, 'amount.arrival', 0);
        let note = _.get(countryArrival, 'note', '');
        // if (arrivalFee > 0 && _.isEmpty(note) === false) {
        if (_.isEmpty(note) === false) {
          note = _.replace(note, '{{money}}', arrivalFee.toLocaleString());
          note = _.replace(note, '{{country}}', countryArrival.label);
          confirmAlert({
            title: `Phí xử lý quốc tịch khó cho ${countryArrival.label}`,
            message: note,
            buttons: [{ label: 'Tôi đã hiểu và đồng ý' }]
          });
        }
      }
      
      if (service.identifyCode === '__aQsPSGF') { // Công tác
        estimateFee.guaranteeFee = 0;
        profiles.extraInfo = { type: 1, address: '' };
        result.isBussinessCase = true;
      }
      /**
       * Nếu dịch vụ là thăm thân
       * 
       */
      result.isVisitingCase = false;
      result.visitingExtraInfo = { value: 1, label: 'Vợ' };
      if (service.identifyCode === 'YhIeL9LMP') { // Thăm thân
        estimateFee.guaranteeFee = 0;
        profiles.extraInfo = {
          type: 1
        };
        result.isVisitingCase = true;
      }

      const states = [];
      const discount = {
        isDiscount: false,
        info: {},
        amount: 0
      };
      result.discountMessage = false;
      result.documentCount = documentCount;
      result.service = {};
      const data = _.clone(parentIdentifies);

      data.forEach((items) => {
        const item = _.find(items.children, { id: value });

        if (_.isEmpty(item) === false) {
          result.service = _.clone(item);
          const discountInfo = _.find(item.discount, { level: discountLevel });

          if (_.isEmpty(discountInfo) === false) {
            discount.isDiscount = true;
            discount.info = discountInfo;
            if (discountInfo.type === 'custom') {
              const discountValue = _.isObject(discountInfo.value) === false ? JSON.parse(discountInfo.value) : discountInfo.value;
              discount.info.value = discountValue;
            }
          }

          item.states.forEach((element) => {
            if (element.isOnCreate === true) {
              states.push({
                label: element.title,
                value: element.state,
                color: element.color
              });
            }
          });
          const settings = buildSetting(_.get(item, 'settings', '{}'), profiles, service.identifyCode);
          let customizeField = _.get(item, 'settings.customizeField', []);
          if (!_.isEmpty(customizeField)) {
            customizeField = JSON.parse(customizeField);
            customizeField.map((v) => {
              if (!model.customizeField[v.key] || _.isEmpty(model.customizeField[v.key])) {
                model.customizeField[v.key] = v.defaultValue;
              }
              return true;
            });
          }
          result.customizeField = customizeField;
          const processingTime = _.get(settings, 'processingTime', {});
          const allowUpload = _.get(settings, 'allowUpload', {});
          const airport = _.get(settings, 'airport', {});
          const stay = _.get(settings, 'stay', {});
          const guarantee = _.get(settings, 'guarantee', {});
          const extraService = _.get(settings, 'extraService', {});
          
          result.settings = settings;

          let listAirportArrival = [{ value: 'all', label: 'Chọn sân bay' }];
          if (_.isEmpty(airport) === false) {
            listAirportArrival = buildListAirportArrival(airport, profiles);
          }

          // let processingTimeArrivals = [];
          // if (_.isEmpty(processingTime) === false) {
          //   processingTimeArrivals = buildListProcessingTimeArrival(processingTime, isWorkingDayStatus, false);
          // }
          // if (_.isEmpty(processingTimeArrivals)) {
          //   NotificationManager.error('Dịch vụ này không làm việc ngày cuối tuần & ngày lễ', '', 1500);
          // }
          // let listPurposeArrival = [{ value: 'all', label: 'Chọn thời gian lưu trú' }];
          // if (_.isEmpty(stay) === false) {
          //   listPurposeArrival = buildListPurposeArrival(stay, processingTimeArrivals[0]);
          // }

          let extraServiceArrivals = [];
          if (_.isEmpty(extraService) === false) {
            extraServiceArrivals = buildListExtraServiceArrival(extraService);
          }

          estimateFee.processingTimeFee = 0;
          model.processingTimeArrival = {};
          result.processingTimeFeeIndex = null;

          model.arrivalAt = null;
          dateDisplay.arrivalAt = 'DD/MM/YYYY';

          // if (_.isEmpty(processingTimeArrivals) === false) {
          //   const purposeArrival = processingTimeArrivals[0];
          //   model.processingTimeArrival = purposeArrival;
          //   result.processingTimeFeeIndex = 0;
          //   estimateFee.processingTimeFee = purposeArrival.amount;
          //   // const defaultArrivalAt = addWorkingDay(purposeArrival.days, purposeArrival.hours, purposeArrival.minutes);
          //   // model.arrivalAt = defaultArrivalAt.format();
          //   // dateDisplay.arrivalAt = formatDate(model.arrivalAt);
          //   result.dateDisplay = dateDisplay;
          // }
          /**
           * 
           */
          // model.purposeArrival = listPurposeArrival[0];
          // result.listPurposeArrival = listPurposeArrival;
          result.listAirportArrival = listAirportArrival;
          // result.processingTimeArrivals = processingTimeArrivals;
          result.processingTimeArrivals = [];
          result.extraServiceArrivals = extraServiceArrivals;
          result.guarantee = guarantee;
          result.discountMessage = _.get(item, 'settings.note', false);
          result.isAllowUpload = _.get(allowUpload, 'active', false);
          result.isAllowUploadDescription = _.get(allowUpload, 'description', '');
          // TODO: nhập cảnh không cần cái này
          // if (this.state.model.serviceIdentifyCode !== value && _.isEmpty(states) === false) {
          //   model.amount += _.get(item, 'amount', 0);
          //   console.log(model.amount);
          //   model.state = states[0].value;
          //   result.stateDefaultValue = states[0];

          //   let discountAmount = 0;
          //   if (discount.type !== 'custom') {
          //     discountAmount = discount.info.value || 0;
          //     if (discount.isDiscount === true && discount.info.type === 'percent') {
          //       discountAmount = model.amount * discount.info.value / 100;
          //     }
          //     if (discountAmount > model.amount) {
          //       discountAmount = model.amount;
          //     }
          //     if (discountAmount < 0) {
          //       discountAmount = 0;
          //     }
          //     // console.log(discountAmount);
          //     model.amount -= discountAmount;
          //   }
          // }
        }
      });

      result.states = states;
      result.discount = discount;
      result.isDisable = !isStaff(this.props);
      estimateFee.serviceFee = model.amount || 0;
      model.state = 'PENDING';
      result.stateDefaultValue = states[0];
      model.serviceIdentifyCode = value;
      return {
        estimateFee,
        result,
        model
      };
    } catch (error) {
      return false;
    }
  }

  onChangeArrivalAtHandle(e) {
    try {
      const {
        estimateFee, isWorkingDayStatus, profiles 
      } = this.state;
      const model = _.clone(this.state.model);
      const value = _.get(e, 'target.value', _.get(e, 'value', false));
      const dateFormat = 'YYYY/MM/DD';
      const splittedDate = value.split('/');

      const result = {};
      const date = moment(`${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`, dateFormat);
      const dateDisplay = Object.assign({}, this.state.dateDisplay);
      dateDisplay.arrivalAt = value;
      result.dateDisplay = dateDisplay;
      if (splittedDate.length === 3 && splittedDate[2].length === 4 && splittedDate[0].indexOf('_') === -1 && splittedDate[1].indexOf('_') === -1 && splittedDate[2].indexOf('_') === -1) {
        const service = this.props.getServiceDetail(model.serviceIdentifyCode);
        if (date.isValid() === true) {
          const diff = moment().diff(date, 'days');
          if (diff > 0) {
            NotificationManager.error('Ngày nhập cảnh không được nhỏ hơn ngày hiện tại ', '', 1500);
            return;
          }
          result.isWorkingDayStatus = true;
          model.arrivalAt = date.format();
          if (
            date.format(dateFormat) === moment().format(dateFormat)
            && isWorkingDay() === false
          ) {
            result.isWorkingDayStatus = false;
          }
          if (_.isEmpty(service) === false) {
            const settings = buildSetting(_.get(service, 'settings', '{}'), profiles, service.identifyCode);
            const processingTime = _.get(settings, 'processingTime', {});
            let processingTimeArrivals = [];
            if (_.isEmpty(processingTime) === false) {
              let flag = isWorkingDay(moment(value, 'DD/MM/YYYY'));
              if (flag === false) {
                if (!moment(value, 'DD/MM/YYYY').isSame(moment(), 'day')) {
                  flag = true;
                }
              }
              processingTimeArrivals = buildListProcessingTimeArrival(processingTime, flag);
              const tProcessingTime = [];
              const arrivalAt = moment(`${value} 17:00:00`, 'DD/MM/YYYY HH:mm:ss');
              
              processingTimeArrivals.map((v) => {
                const tDay = moment().businessAdd(v.days, 'days');
                if (tDay >= arrivalAt) {
                  return false;
                }
                const tHour = moment().add(1, 'hours');
                // console.log(arrivalAt.format('YYYY/MM/DD HH:mm:ss'));
                // console.log(tHour.format('YYYY/MM/DD HH:mm:ss'));
                if (tHour >= arrivalAt) {
                  return false;
                }
                const tMinute = moment().add(v.minutes, 'minutes');
                if (tMinute >= arrivalAt) {
                  return false;
                }
                tProcessingTime.push(v);
                return true;
              });
              processingTimeArrivals = tProcessingTime;
              
              if (_.isEmpty(processingTimeArrivals)) {
                result.processingTimeArrival = [];
                this.setState(result);
                confirmAlert({
                  title: 'Thông báo',
                  message: 'Không tạo được đơn hàng vì trường hợp đặc biệt vui lòng gọi số 0907.874.240 để được hỗ trợ ngay',
                  buttons: [{ label: 'Tôi đã hiểu và đồng ý' }]
                });
                return;
              }
              let supportReceiver = {};
              processingTimeArrivals.map((v) => {
                v.supportReceiver.map((s) => {
                  supportReceiver[s] = true;
                  return true;
                });
                return true;
              });
              supportReceiver = _.keys(supportReceiver);
              const airport = _.get(settings, 'airport', {});
              let listAirportArrival = [{ value: 'all', label: 'Chọn sân bay' }];
              if (_.isEmpty(airport) === false) {
                listAirportArrival = buildListAirportArrival(airport, profiles);
              }
              const tListAirportArrival = [{ value: 'all', label: 'Chọn sân bay' }];
              listAirportArrival.map((v) => {
                if (_.includes(supportReceiver, v.type)) {
                  tListAirportArrival.push(v);
                }
                return true;
              });
              result.listAirportArrival = tListAirportArrival;
            }
            if (_.isEmpty(processingTimeArrivals) === false) {
              model.processingTimeArrival = processingTimeArrivals[0];
              result.processingTimeFeeIndex = 0;
              result.processingTimeArrivals = processingTimeArrivals;
              estimateFee.processingTimeFee = processingTimeArrivals[0].amount;
            } else {
              result.processingTimeFeeIndex = '';
              estimateFee.processingTimeFee = 0;
              model.processingTimeArrival = '';
              result.processingTimeArrivals = [];
              // NotificationManager.error('Dịch vụ này không làm việc ngày cuối tuần & ngày lễ', '', 1500);
            }
            const stay = _.get(settings, 'stay', {});
            let listPurposeArrival = [{ value: 'all', label: 'Chọn thời gian lưu trú' }];
            if (_.isEmpty(stay) === false) {
              listPurposeArrival = buildListPurposeArrival(stay, processingTimeArrivals[0]);
              result.listPurposeArrival = listPurposeArrival;
            }
          }
        }
      } else {
        result.processingTimeArrivals = [];
      }
      result.model = model;
      result.estimateFee = estimateFee;
      this.setState(result);
    } catch (error) {
      console.log(error);
    }
  }

  onChangeHandle(key, e) {
    try {
      const {
        estimateFee, isWorkingDayStatus, profiles,
        extraServiceArrivals, dateDisplay, suppliers
      } = this.state;
      const model = _.clone(this.state.model);

      const result = {};
      let editAmount = false;
      switch (key) {
        case 'tag': {
          const value = _.get(e, 'target.value', _.get(e, 'value', false));
          model[key] = this.tags.state.tags;
          if (value) { model[key].push(value); }
          model[key] = _.uniq(model[key]);
          break;
        }

        case 'state': {
          model[key] = 'PENDING';
          if (isStaff(this.props) === true) {
            const warning = _.get(e, 'state.warning', false);
            if (warning) {
              confirmAlert({
                title: 'Xác nhật cập nhật trạng thái',
                message: warning,
                buttons: [{ label: 'Đóng' }]
              });
            }
            model[key] = _.get(e, 'target.value', _.get(e, 'value', false));
            result.stateDefaultValue = e;
          }

          break;
        }

        case 'purposeArrival': {
          const { listPurposeArrival, discount } = this.state;
          const purposeArrival = _.find(listPurposeArrival, { value: Number(e.value) });

          let discountAmount = 0;
          if (_.isEmpty(purposeArrival) === false) {
            const discountValue = _.get(discount, 'info.value', []);
            const discountInfo = _.find(discountValue, { key: purposeArrival.value });

            if (_.isEmpty(discountInfo) === false) {
              discountAmount = discountInfo.amount;

              if (discount.isDiscount === true && discountInfo.type === 'percent') {
                discountAmount = purposeArrival.amount * discountInfo.amount / 100;
              }

              if (discountAmount > purposeArrival.amount) {
                discountAmount = purposeArrival.amount;
              }

              if (discountAmount < 0) {
                discountAmount = 0;
              }
            }
            // model.processingTimeArrival = '';
            model.purposeArrival = purposeArrival;

            estimateFee.serviceFee = purposeArrival.amount - discountAmount;
            result.discountInfo = discountInfo;
          }
          editAmount = false;
          break;
        }

        case 'supplierId': {
          const value = _.get(e, 'target.value', _.get(e, 'value', false));
          model.supplierId = value;
          if (model.supplierId === 0) {
            model.supplierAmount = 0;
            model.paidSupplierAmount = 0;
            model.supplierSendAt = null;
            this.state.dateDisplay.supplierSendAt = 'DD/MM/YYYY';
          }
          const supplierDefaultValue = _.find(suppliers, { value });
          result.supplierDefaultValue = supplierDefaultValue;
          break;
        }
        case 'supplierAmount': {
          const amount = _.get(e, 'target.rawValue', _.get(e, 'value', 0));
          model[key] = Number(amount);
          break;
        }

        case 'paidSupplierAmount': {
          const amount = _.get(e, 'target.rawValue', _.get(e, 'value', 0));
          model[key] = Number(amount);
          break;
        }

        case 'airportArrival': {
          const type = _.get(e, 'type', '');
          // model.processingTimeArrival = '';
          model.airportArrival = e;

          // const service = this.props.getServiceDetail(model.serviceIdentifyCode);
          // const settings = buildSetting(_.get(service, 'settings', '{}'), profiles, service.identifyCode);
          // const processingTime = _.get(settings, 'processingTime', {});
          // let processingTimeArrivals = [];
          // if (_.isEmpty(processingTime) === false) {
          //   processingTimeArrivals = buildListProcessingTimeArrival(processingTime, isWorkingDayStatus, [type]);
          // }

          // if (_.isEmpty(processingTimeArrivals) === false) {
          //   model.processingTimeArrival = processingTimeArrivals[0];
          //   result.processingTimeFeeIndex = 0;
          //   estimateFee.processingTimeFee = processingTimeArrivals[0].amount;
          // }
          // result.processingTimeArrivals = processingTimeArrivals;
          break;
        }

        case 'processingTimeArrival': {
          if (_.isEmpty(model.serviceIdentifyCode) === true) {
            model.processingTimeArrival = '';
            result.processingTimeFeeIndex = '';
            estimateFee.processingTimeFee = 0;
          } else {
            const service = this.props.getServiceDetail(model.serviceIdentifyCode);
            const settings = buildSetting(_.get(service, 'settings', '{}'), profiles, service.identifyCode);
            const processingTime = _.get(settings, 'processingTime', {});
            const stay = _.get(settings, 'stay', {});

            let processingTimeArrivals = [];
            if (_.isEmpty(processingTime) === false) {
              processingTimeArrivals = buildListProcessingTimeArrival(processingTime, isWorkingDayStatus);
            }
            // TODO: trung
            processingTimeArrivals = this.state.processingTimeArrivals;
            const processingTimeIndex = _.findIndex(processingTimeArrivals, { value: Number(e.target.value) });
            console.log(processingTimeIndex);
            if (_.isEmpty(processingTime) === false) {
              const processingTimeArrival = _.find(processingTimeArrivals, { value: Number(e.target.value) });
              const availableStays = _.get(processingTimeArrival, 'availableStay', false);
              let listPurposeArrival = [{ value: 'all', label: 'Chọn thời gian lưu trú' }];
              const purposeArrival = _.get(model, 'purposeArrival', listPurposeArrival[0]);
              if (_.isEmpty(stay) === false && _.isEmpty(availableStays) === false) {
                listPurposeArrival = buildListPurposeArrival(stay, processingTimeArrival);
                const isExistPurposeArrival = _.find(listPurposeArrival, { value: purposeArrival.value });
                result.listPurposeArrival = listPurposeArrival;
                if (_.isEmpty(isExistPurposeArrival) === true) {
                  model.purposeArrival = listPurposeArrival[0];
                }
              }

              // const defaultArrivalAt = addWorkingDay(processingTimeArrival.days, processingTimeArrival.hours, processingTimeArrival.minutes);
              // model.arrivalAt = defaultArrivalAt.format();
              // dateDisplay.arrivalAt = formatDate(model.arrivalAt);
              // result.dateDisplay = dateDisplay;
              // }

              model.processingTimeArrival = processingTimeArrival;
              result.processingTimeFeeIndex = processingTimeIndex;
              estimateFee.processingTimeFee = processingTimeArrival.amount;
            }
            editAmount = false;
          }

          break;
        }

        case 'extraInfo': {
          const service = this.props.getServiceDetail(model.serviceIdentifyCode);
          const settings = buildSetting(_.get(service, 'settings', '{}'), profiles, service.identifyCode);
          const guarantee = _.get(settings, 'guarantee', {});
          const value = Number(e.target.value);
          profiles.extraInfo.type = value;
          estimateFee.guaranteeFee = 0;
          if (value === 0) {
            estimateFee.guaranteeFee = _.get(guarantee, 'amount', 0);
          }
          editAmount = false;
          result.profiles = profiles;
          break;
        }
        case 'visitingExtraInfo': {
          result.visitingExtraInfo = e;
          profiles.extraInfo.type = e.value;
          result.profiles = profiles;
          break;
        }
        case 'extraInfoAddress': {
          const value = _.get(e, 'target.value', _.get(e, 'value', false));
          profiles.extraInfo.address = value;
          break;
        }

        case 'extraServiceArrival': {
          if (_.isEmpty(extraServiceArrivals) === false) {
            const extraServices = _.clone(model.extraService);
            const serviceIndex = _.findIndex(extraServiceArrivals, { value: Number(e.target.value) });

            if (serviceIndex >= 0) {
              const modelIndex = _.findIndex(extraServices, { value: Number(e.target.value) });
              if (modelIndex >= 0) {
                extraServices.splice(modelIndex, 1);
              }

              if (e.target.checked === true) {
                extraServices.push(extraServiceArrivals[serviceIndex]);
              }

              model.extraService = extraServices;
              estimateFee.extraServiceFee = 0;

              extraServices.forEach((element) => {
                estimateFee.extraServiceFee += element.amount;
              });
            }
            editAmount = false;
          }

          break;
        }

        case 'issuedAt': {
          const value = _.get(e, 'target.value', _.get(e, 'value', false));
          const dateFormat = 'YYYY/MM/DD';
          const splittedDate = value.split('/');

          if (splittedDate.length === 3 && splittedDate[2].length === 4) {
            const date = moment(`${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`, dateFormat);
            dateDisplay[key] = value;
            result.dateDisplay = dateDisplay;
            if (date.isValid() === true) {
              model.issuedAt = date.format();
            }
          }
          break;
        }
        
        case 'supplierSendAt': {
          const value = _.get(e, 'target.value', _.get(e, 'value', false));
          const dateFormat = 'YYYY/MM/DD';
          const splittedDate = value.split('/');

          if (splittedDate.length === 3 && splittedDate[2].length === 4) {
            const date = moment(`${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`, dateFormat);
            dateDisplay[key] = value;
            result.dateDisplay = dateDisplay;
            if (date.isValid() === true) {
              model.supplierSendAt = date.format();
            }
          }
          break;
        }

        case 'amount': {
          editAmount = true;
          const amount = _.get(e, 'target.rawValue', _.get(e, 'value', 0));
          result.forceAmount = Number(amount);
          model[key] = Number(amount);
          break;
        }

        default:
          model[key] = _.get(e, 'target.value', _.get(e, 'value', false));
          break;
      }
      const immigrationFeeInfo = this.estimateHandle(estimateFee);
      if (editAmount === false) {
        model.amount = _.get(immigrationFeeInfo, 'generalInfo.amount', 0);
      }

      result.model = model;
      result.estimateFee = estimateFee;

      this.setState(result);
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Render
   */

  setStag(tag) {
    if (tag) {
      const model = Object.assign({}, this.state.model);
      model.tag = this.tags.state.tags;
      const isExist = model.tag.indexOf(tag);
      if (tag && isExist === -1) { model.tag.push(tag); }
      this.setState({ model });
    }
  }

  renderTagItem() {
    const { orderTags } = this.props;
    if (_.isEmpty(orderTags) === false) {
      return (orderTags.map((item, i) => {
        return (
          <span
            onClick={this.setStag.bind(this, item)}
            key={`orderTags_${i}`}
            style={TAG_STYLE[i]}
            className="badge badge-pill">{item}</span>);
      }));
    }
    return (<span></span>);
  }

  renderDocument() {
    const { model } = this.state;
    if (_.isEmpty(model.serviceIdentifyCode) === true) {
      return (<div></div>);
    }
    const service = this.props.getServiceDetail(model.serviceIdentifyCode);
    if (_.isEmpty(service.document) === true) {
      return (<div></div>);
    }
    return (service.document.map((item, i) => {
      return (<div style={{ float: 'left' }} key={`renderDocument_${i}`} className="col-md-6">
        <div style={{ padding: 10 }} className="media-title font-weight-semibold">
          <a target="_blank" href={item.value}>
            <i className="fa fa-link"></i> {item.key}
          </a>
        </div>
      </div>);
    }));
  }

  renderAccountInfo() {
    const { model } = this.state;
    const { init, agency } = this.props;
    let agencyInfo = _.clone(agency);
    if (isStaff(this.props) === false) {
      agencyInfo = _.get(init, 'accountInfo', {});
    }
    return (
      <div className="card-body ">
        <form onSubmit={this.onSubmit}>
          <div className="form-group">
            <div className="row">

              <div className="col-md-4">
                <label>Họ tên</label>
                <input
                  value={_.get(agencyInfo, 'title', '')}
                  type="text"
                  className="form-control"
                  disabled
                />
              </div>
              <div className="col-md-4">
                <label>Số điện thoại</label>
                <input
                  value={_.get(agencyInfo, 'phone', '')}
                  type="text"
                  className="form-control"
                  disabled
                />
              </div>
              <div className="col-md-4">
                <label>Email</label>
                <input
                  value={_.get(agencyInfo, 'email', '')}
                  type="text"
                  className="form-control"
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-md-12">
                <label>Nhãn đơn hàng</label>
                <Autocomplete
                  delay={500}
                  tags={model.tag}
                  ref={(e) => { this.tags = e; }}
                  onAdd={this.onChangeHandle.bind(this, 'tag')}
                />
                <div style={{ marginTop: 10, paddingLeft: 10 }}>
                  {this.renderTagItem()}

                </div>

              </div>
            </div>
          </div>

        </form>
      </div>
    );
  }

  renderExtraServiceFee(estimateFee) {
    const extraServices = _.clone(this.state.model.extraService);

    if (_.isEmpty(extraServices) === true) {
      return (<tr style={{ border: 0 }}>
        <td key={'renderExtraServiceFee_0'} colSpan={2} style={{ border: 0, textAlign: 'right' }}>
          0 VND X {estimateFee.numberOfProfile.toLocaleString() || 0} khách = 0 VND
        </td>
      </tr>);
    }
    return _.map(extraServices, (item, i) => {
      if (_.isEmpty(item.label) === false && item.amount > 0) {
        return (<tr key={`renderExtraServiceFee_${i}`} style={{ border: 0 }}>
          <td colSpan={2} style={{ border: 0, textAlign: 'right' }}>
            {item.label} : {item.amount.toLocaleString() || 0} VND
          </td>
        </tr>);
      }
      return (<tr key={`renderExtraServiceFee_${i}`}></tr>);
    });
  }

  renderFees(data) {
    const fees = _.get(data, 'fees', false);
    if (fees === false) {
      return (<tr style={{ border: 0 }}></tr>);
    }

    return _.map(data.fees, (item, i) => {
      if (_.isEmpty(item.label) === false && item.amount > 0) {
        return (<tr key={`renderFees_${i}`} >
          <td colSpan={2} style={{ border: 0, textAlign: 'right' }}>
            {item.label} ({item.count}) : {item.amount.toLocaleString()} VND
          </td>
        </tr>);
      }
      return (<tr key={`renderFees_${i}`}></tr>);
    });
  }

  renderEstimate() {
    const {
      estimateFee, model, profiles, service, forceAmount
    } = this.state;

    const {
      airportArrival,
      processingTimeArrival,
      purposeArrival
    } = model;
    const immigrationFeeInfo = immigrationFee(estimateFee, service, profiles, model);
    const stypeLeftTD = { width: 165, verticalAlign: 'top', fontWeight: 'bold' };
    const stypeRightTD = { textAlign: 'right' };

    return (<div className="col-xl-5">
      <div className="card" style={{ height: 'calc(100% - 20px)' }}>
        <div className="card-header header-elements-inline">
          <h5 className="card-title">Tạm tính</h5>
        </div>
        <div className="card-body ">
          <div>
            <table
              className="table table-striped"
              style={{ padding: 0 }}
            >
              <tbody >
                <tr>
                  <td style={stypeLeftTD}>TỔNG PHÍ</td>
                  <td className="text-danger" style={{ fontWeight: 600, textAlign: 'right' }}>
                    {forceAmount > 0
                      ? <span>{forceAmount.toLocaleString()} VND</span>
                      : <span>{_.get(immigrationFeeInfo, 'generalInfo.amount', 0).toLocaleString()} VND</span>
                    }
                  </td>
                </tr>

                <tr>
                  <td style={stypeLeftTD}>Mục đích nhập cảnh</td>
                  <td style={stypeRightTD}>
                    {_.get(purposeArrival, 'value', '') !== 'all'
                      ? _.get(model, 'purposeArrival.label', '')
                      : ''}
                  </td>
                </tr>

                <tr>
                  <td style={stypeLeftTD}> Ngày nhập cảnh </td>
                  <td style={stypeRightTD}>{formatDate(model.arrivalAt)}</td>
                </tr>

                <tr>
                  <td style={stypeLeftTD}>Phí sân bay</td>
                  <td style={stypeRightTD}>
                    {_.get(airportArrival, 'value', '') !== 'all'
                      ? _.get(model, 'airportArrival.label', '')
                      : ''}
                  </td>
                </tr>
                <tr >
                  <td colSpan={2} style={{ border: 0, textAlign: 'right' }}>
                    {_.get(immigrationFeeInfo, 'airportArrivalFeeInfo.amount', 0).toLocaleString()} VND
                  </td>
                </tr>
                <tr>
                  <td style={stypeLeftTD}> Phí bảo lãnh </td>
                  <td style={stypeRightTD}></td>
                </tr>
                <tr>
                  <td colSpan={2} style={{ border: 0, textAlign: 'right' }}>
                    {_.get(immigrationFeeInfo, 'guaranteeFeeInfo.amount', 0).toLocaleString()} VND
                  </td>
                </tr>
                <tr>
                  <td style={stypeLeftTD}> Phí xử lý hồ sơ quốc gia khó </td>
                  <td style={stypeRightTD}>
                    {_.get(immigrationFeeInfo, 'countryArrivalFeeInfo.amount', 0) > 0
                      ? <p>{profiles.countryArrival.title}</p>
                      : <span></span>}
                  </td>
                </tr>

                <tr>
                  <td colSpan={2} style={{ border: 0, textAlign: 'right' }}>
                    {_.get(immigrationFeeInfo, 'countryArrivalFeeInfo.amount', 0).toLocaleString()} VND
                  </td>
                </tr>
                <tr>
                  <td style={stypeLeftTD}> Phí dịch vụ </td>
                  <td style={stypeRightTD}>{_.get(this.state, 'service.text', '')}</td>
                </tr>

                <tr >
                  <td colSpan={2} style={{ border: 0, textAlign: 'right' }}>
                    {estimateFee.serviceFee.toLocaleString() || 0}  VND
                  </td>
                </tr>
                <tr>
                  <td style={stypeLeftTD}> Dịch vụ kèm theo </td>
                  <td style={stypeRightTD}></td>
                </tr>
                {this.renderExtraServiceFee(estimateFee)}
                <tr>
                  <td style={stypeLeftTD}> Thời gian xử lý </td>
                  <td style={stypeRightTD}>
                    {_.get(processingTimeArrival, 'value', '') !== 'all'
                      ? _.get(model, 'processingTimeArrival.label', '')
                      : ''}
                  </td>
                </tr>
                <tr >
                  <td colSpan={2} style={{ border: 0, textAlign: 'right' }}>
                    {_.get(immigrationFeeInfo, 'processingTimeFeeInfo.amount', 0).toLocaleString()} VND
                  </td>
                </tr>
                {_.isEmpty(_.get(immigrationFeeInfo, 'countryArrivalFeeInfo.fees', [])) === false
                  ? <tr>
                    <td style={stypeLeftTD}> Trường hợp khó </td>
                    <td style={stypeRightTD}></td>
                  </tr>
                  : <tr style={{ border: 0 }}></tr>}
                {this.renderFees(immigrationFeeInfo.countryArrivalFeeInfo)}

              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>);
  }

  renderProfiles() {
    const {
      genders,
      sexDefaultValue,
      nationalDefaultValue,
      profiles,
      isWorkingDayStatus
    } = this.state;
    const { init } = this.props;

    const countryArrivals = buildListCountryArrival(init, true);
    if (_.isEmpty(profiles) === true) {
      return (<div key={'renderProfiles'} className="row"></div>);
    }

    return (<div key={'renderProfiles'} className="row" style={{ paddingBottom: 10 }} >
      <div className="col-md-2">
        <label>Họ & tên <span className="text-danger">*</span></label>
        <div className="form-group">
          <input
            value={profiles.fullName || ''}
            ref={(input) => {
              this.input.fullname = {
                name: 'fullname', title: 'Họ & tên', isActive: true, required: true, e: input
              }; 
            }} 
            onChange={this.onChangeProfileHandle.bind(this, 'fullName')}
            type="text"
            className="form-control"
            placeholder="Họ & tên khách hàng"
          />
        </div>
      </div>

      <div className="col-md-2">
        <label>Giới tính <span className="text-danger">*</span></label>
        <div className="form-group">
          <Select
            value={profiles.sex || sexDefaultValue}
            ref={(input) => {
              this.input.sex = {
                name: 'sex', title: 'Giới tính', isActive: true, required: true, e: input
              }; 
            }} 
            onChange={this.onChangeProfileHandle.bind(this, 'sex')}
            options={genders}
          />
        </div>
      </div>

      <div className="col-sm-3">
        <div className="form-group">
          <label>Ngày sinh <span className="text-danger">*</span></label><br />
          <div className="form-group">
            <div className="input-group">
              <span className="input-group-prepend">
                <span className="input-group-text">
                  <i className="icon-calendar"></i>
                </span>
              </span>
              <div className="multiselect-native-select">
                <InputMask
                  ref={(input) => {
                    this.input.birthday = {
                      name: 'birthday', title: 'Ngày sinh', isActive: true, required: true, e: input
                    }; 
                  }} 
                  value={profiles.displayBirthday}
                  className="form-control"
                  mask="99/99/9999"
                  onChange={this.onChangeProfileHandle.bind(this, 'birthday')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-2">
        <label>Số hộ chiếu <span className="text-danger">*</span></label>
        <div className="form-group">
          <input
            ref={(input) => {
              this.input.passportNumber = {
                name: 'passportNumber', title: 'Số hộ chiếu', isActive: true, required: true, e: input
              }; 
            }} 
            value={profiles.passport.number || ''}
            onChange={this.onChangeProfileHandle.bind(this, 'identifyCard')}
            type="text"
            className="form-control"
            placeholder="Nhập số căn cước"
          />

        </div>
      </div>

      <div className="col-sm-3">
        <label>Quốc tịch <span className="text-danger">*</span></label>
        <div className="form-group">
          <Select
            ref={(input) => {
              this.input.country = {
                name: 'country', title: 'Quốc tịch', isActive: true, required: true, e: input
              }; 
            }} 
            value={profiles.countryArrival || nationalDefaultValue}
            onChange={this.onChangeProfileHandle.bind(this, 'countryArrival')}
            options={countryArrivals}
          />
        </div>
      </div>

    </div>
    );
  }

  renderProcessingTime() {
    const { processingTimeArrivals, processingTimeFeeIndex } = this.state;
    if (_.isEmpty(processingTimeArrivals) === true) {
      return (<div></div>);
    }

    return _.map(processingTimeArrivals, (processingTimeArrival, i) => {
      return (<div key={`renderprocessingTime_${i}`} className="form-group">
        <div className="row">
          <div className="col-md-12">
            <div className="form-check" style={{ paddingLeft: 0 }}>
              <div className="radio">
                <label>
                  <input
                    name="processingTime"
                    type="radio"
                    className="form-input-styled"
                    checked={processingTimeFeeIndex === i}
                    value={processingTimeArrival.value}
                    onChange={this.onChangeHandle.bind(this, 'processingTimeArrival')}
                  /> {processingTimeArrival.label}
                </label>
              </div>
            </div>
          </div>
        </div>
        {processingTimeArrival.description && processingTimeFeeIndex === i ? <div className="row">
          <div className="col-md-12">
            <div className="processing-note">
              {processingTimeArrival.description}
            </div>
          </div>
        </div> : ''}
      </div>);
    });
  }

  renderVisiting() {
    const {
      isVisitingCase, visitingExtraInfo, guaranteeList
    } = this.state;
    if (isVisitingCase === false) {
      return (<div></div>);
    }
    return (<div>

      <div className="row">
        <div className="col-md-12">
          <div className="processing-note">
            <div className="row">
              <div className="col-md-12" style={{ marginTop: 5 }}>
                <div className="form-group">
                  <label>Người bảo lãnh <span className="text-danger">*</span></label>
                  <Select
                    value={visitingExtraInfo}
                    options={guaranteeList}
                    onChange={this.onChangeHandle.bind(this, 'visitingExtraInfo')}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12" style={{ marginTop: 5 }}>
                <div className="form-group">
                  <label>Hồ sơ đính kèm <span className="text-danger">*</span></label>
                  <UploadFileContainer
                    setFiles={this.setExtraInfoFile.bind(this)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>);
  }

  renderBusinessType() {
    const { isBussinessCase, profiles, guarantee } = this.state;
    if (isBussinessCase === false) {
      return (<div></div>);
    }
    const type = _.get(profiles, 'extraInfo.type', false);
    const address = _.get(profiles, 'extraInfo.address', '');
    return (<div>
      <div key={'renderBusinessType_1'} className="form-group">
        <div className="row">
          <div className="col-md-12">
            <div className="form-check" style={{ paddingLeft: 0 }}>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    className="form-input-style"
                    value="1"
                    checked={type === 1}
                    onChange={this.onChangeHandle.bind(this, 'extraInfo')}
                  /> Có công ty bảo lãnh
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="processing-note">
              {guarantee.description} (<a
                target="_blank"
                href={guarantee.document}> Xem chi tiết </a>)

              <div className="row">
                <div className="col-md-12" style={{ marginTop: 5 }}>
                  <div className="form-group">
                    <label>Địa chỉ công ty bảo lãnh <span className="text-danger">*</span></label>
                    <input
                      value={address || ''}
                      onChange={this.onChangeHandle.bind(this, 'extraInfoAddress')}
                      type="text"
                      className="form-control"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12" style={{ marginTop: 5 }}>
                  <div className="form-group">
                    <label>Hồ sơ đính kèm <span className="text-danger">*</span></label>
                    <UploadFileContainer
                      setFiles={this.setExtraInfoFile.bind(this)}
                    />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div key={'renderBusinessType_2'} className="form-group">
        <div className="row">
          <div className="col-md-12">
            <div className="form-check" style={{ paddingLeft: 0 }}>
              <div className="radio">
                <label>
                  <input
                    name="businessType"
                    type="radio"
                    className="form-input-style"
                    value="0"
                    checked={type === 0}
                    onChange={this.onChangeHandle.bind(this, 'extraInfo')}
                  /> Không có công ty bảo lãnh
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>);
  }

  renderExtraService() {
    const { init } = this.props;
    const { extraServiceArrivals } = this.state;

    if (_.isEmpty(extraServiceArrivals) === true) {
      return (<div></div>);
    }
    const extraServices = _.clone(this.state.model.extraService);
    return _.map(extraServiceArrivals, (extraServiceArrival, i) => {
      const exist = _.find(extraServices, { value: extraServiceArrival.value });
      return (<div key={`renderExtraService_${i}`} className="form-group">
        <div className="row">
          <div className="col-md-12">
            <div className="form-check">
              <label className="form-check-label">
                <div className="uniform-checker">
                  <span className={_.isEmpty(exist) === false ? 'checked' : ''} >
                    <DelayInput
                      type="checkbox"
                      className="form-input-styled"
                      delayTimeout={50}
                      value={extraServiceArrival.value}
                      onChange={this.onChangeHandle.bind(this, 'extraServiceArrival')}
                    />
                  </span>
                </div>{extraServiceArrival.label}</label>
            </div>
          </div>
        </div>
        {extraServiceArrival.description ? <div className="row">
          <div className="col-md-12">
            <div className="processing-note">
              {extraServiceArrival.description}
            </div>
          </div>
        </div> : ''}

      </div>);
    });
  }

  renderOrderInfo() {
    const { init, agency } = this.props;
    const {
      listAirportArrival, suppliers,
      supplierDefaultValue 
    } = this.state;
    let agencyInfo = _.clone(agency);

    const isStaffStatus = isStaff(this.props);
    let disabled = false;
    if (isStaffStatus === false) {
      agencyInfo = _.get(init, 'accountInfo', {});
      disabled = true;
    }

    const {
      model, dateDisplay, parentIdentifies, states, stateDefaultValue,
      listPurposeArrival, processingTimeArrivals, extraServiceArrivals,
      isBussinessCase, settings, isAllowUploadDescription, isVisitingCase
    } = this.state;

    const hasWalletPermission = checkScope(WALLET_SCOPE, this.props);
    return (<div className="col-xl-7">
      <div className="card" style={{ height: 'calc(100% - 20px)' }}>
        <div className="card-header header-elements-inline">
          <h5 className="card-title">Tiến trình đơn hàng</h5>
        </div>
        <div className="card-body ">
          <Tabs defaultActiveKey="tab1">
            <Tab eventKey="tab1" title="Tiến trình">
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-6">
                      <label>Mục đích nhập cảnh <span className="text-danger">*</span></label>

                      <Select2
                        ref={(input) => {
                          this.input.serviceIdentifyCode = {
                            name: 'serviceIdentifyCode', title: 'Mục đích nhập cảnh', isActive: true, required: true, e: input
                          }; 
                        }} 
                        value={model.serviceIdentifyCode}
                        style={{ width: '100%' }}
                        name="area"
                        onSelect={this.onChangeServiceCodeHandle.bind(this)}
                        className="form-control select-search select2-hidden-accessible"
                        data={parentIdentifies}
                        options={{
                          placeholder: 'Chọn loại dịch vụ'
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <label>Ngày nhập cảnh <span className="text-danger">*</span></label>
                      <InputMask
                        ref={(input) => {
                          this.input.arrivalAt = {
                            name: 'arrivalAt', title: 'Ngày nhập cảnh', isActive: true, required: true, e: input
                          }; 
                        }} 
                        value={dateDisplay.arrivalAt}
                        className="form-control"
                        mask="99/99/9999"
                        onChange={this.onChangeArrivalAtHandle.bind(this)}
                      />
                    </div>

                  </div>
                </div>
                <div className="form-group">

                  <div className="row">
                    <div className={isStaff(this.props) === true ? 'col-md-6' : 'col-md-12'}>
                      <label>Thời gian lưu trú <span className="text-danger">*</span></label>
                      <Select
                        ref={(input) => {
                          this.input.purposeArrival = {
                            name: 'purposeArrival', title: 'Thời gian lưu trú', isActive: true, required: true, e: input
                          }; 
                        }} 
                        value={model.purposeArrival || listPurposeArrival[0]}
                        options={listPurposeArrival}
                        onChange={this.onChangeHandle.bind(this, 'purposeArrival')}
                      />
                    </div>
                    {isStaff(this.props) === true ? <div className="col-md-6">
                      <label>Trạng thái <span className="text-danger">*</span></label>
                      <SelectStateContainer
                        value={stateDefaultValue}
                        onChange={this.onChangeHandle.bind(this, 'state')}
                        options={states}
                      />
                    </div> : ''}
                    
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-6">
                      <label>Đến tại sân bay <span className="text-danger">*</span></label>
                      <Select
                        ref={(input) => {
                          this.input.airportArrival = {
                            name: 'airportArrival', title: 'Đến tại sân bay', isActive: true, required: true, e: input
                          }; 
                        }} 
                        value={model.airportArrival || listAirportArrival[0]}
                        options={listAirportArrival}
                        onChange={this.onChangeHandle.bind(this, 'airportArrival')}
                      />
                    </div>

                    <div className="col-md-6">
                      <label>Tổng tiền đơn hàng
                        <span className="text-danger"> </span>

                      </label>

                      <label style={{ float: 'right', fontStyle: 'italic' }}>
                        <i
                          style={{ marginLeft: 10 }}
                          href="#"
                          id="TooltipExample"
                          className="fa fa-info-circle"></i>
                      </label>
                      {hasWalletPermission
                        ? <a
                          style={{ paddingLeft: 5 }}
                          target="_blank"
                          href={`/account-wallet/${agencyInfo.id}`}>
                          <i className="fa fa-plus-circle"></i>
                        </a> : ''}
                      <span className="float-right text-info">
                        {this.state.discountMessage || ''}
                      </span>

                      <div className="form-group">
                        <div className="input-group">
                          <span className="input-group-prepend">
                            <span className="input-group-text">VND</span>
                          </span>
                          <div className="multiselect-native-select">
                            <Cleave
                              disabled={disabled}
                              value={model.amount}
                              onChange={this.onChangeHandle.bind(this, 'amount')}
                              className="form-control input-numeral"
                              placeholder="Số tiền"
                              options={{
                                numeral: true,
                                numeralThousandsGroupStyle: 'thousand',
                                numeralDecimalScale: 0
                              }}
                            />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                {isStaff(this.props) === true
                  ? <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <label>VAT</label>
                        <div className="form-group">
                          <div className="input-group">
                            <div className="multiselect-native-select">
                              <input
                                value={model.vat}
                                onChange={this.onChangeHandle.bind(this, 'vat')}
                                type="text"
                                className="form-control"
                                placeholder="VAT"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                        
                  </div>
                  : ''}
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-12">

                    </div>
                  </div>
                </div>
                      
                {_.get(settings, 'info.uploadTravelDocument', false) === true
                  ? <div className="form-group">
                    <div className="row">
                      <div className="col-md-12">
                        <label>Hồ sơ bổ sung</label>
                        <UploadFileContainer
                          setFiles={this.setProfileFiles.bind(this)}
                        />
                        <span className="form-text text-muted">
                          {_.get(settings, 'info.profileExtraDescription', isAllowUploadDescription || '')}
                        </span>
                      </div>
                    </div>
                  </div> : ''}

                {isBussinessCase === true ? <div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-12"
                        style={{ borderBottom: '1px solid #CCC', paddingTop: 1 }}></div>
                    </div>
                  </div>
                  <div className="form-group" style={{ marginBottom: 0 }}>
                    <div className="row">
                      <div className="col-md-12">
                        <h5 className="card-title">
                          Thông tin công tác <span className="text-danger">*</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  {this.renderBusinessType()}

                </div> : ''}

                {isVisitingCase === true ? <div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-12"
                        style={{ borderBottom: '1px solid #CCC', paddingTop: 1 }}></div>
                    </div>
                  </div>
                  <div className="form-group" style={{ marginBottom: 0 }}>
                    <div className="row">
                      <div className="col-md-12">
                        <h5 className="card-title">
                          Thông tin người bảo lãnh <span className="text-danger">*</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  {this.renderVisiting()}
                </div> : ''}

                <div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-12"
                        style={{ borderBottom: '1px solid #CCC', paddingTop: 1 }}></div>
                    </div>
                  </div>
                  <div className="form-group" style={{ marginBottom: 0 }}>
                    <div className="row">
                      <div className="col-md-12">
                        <h5 className="card-title">
                          Thời gian xử lý <span className="text-danger">*</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  {_.isEmpty(processingTimeArrivals) === true ? <div className="form-group"><div className="row">
                    <div className="col-md-12">
                      <div className="processing-note">
                        Chưa có kết quả xử lý phù hợp
                      </div>
                    </div>
                  </div>
                  </div> : ''}
                  {this.renderProcessingTime()}
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-md-12"
                      style={{ borderBottom: '1px solid #CCC', paddingTop: 1 }}></div>
                  </div>
                </div>
                {_.isEmpty(extraServiceArrivals) === false ? <div className="form-group" style={{ marginBottom: 0 }}>
                  <div className="row">
                    <div className="col-md-12">
                      <h5 className="card-title">Dịch vụ kèm theo</h5>
                    </div>
                  </div>
                </div> : ''}
                {this.renderExtraService()}
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-12">
                      <label>Ghi chú đơn hàng</label>
                      <textarea
                        onChange={this.onChangeHandle.bind(this, 'description')}
                        name="additional-info"
                        rows="4"
                        cols="4"
                        placeholder=""
                        className="form-control"
                      ></textarea>
                    </div>
                  </div>

                </div>
              </form>
            </Tab>
            <Tab eventKey="tab2" title={`Tài liệu tham khảo (${this.state.documentCount})`}>
              {this.renderDocument()}
            </Tab>
            {(isStaff(this.props) === true && suppliers.length > 1) 
              ? <Tab eventKey="tab3" title="Đối tác xử lý">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-6">
                      <label>Đối tác xử lý</label>
                      <div className="form-group">
                        <Select
                          value={supplierDefaultValue}
                          onChange={this.onChangeHandle.bind(this, 'supplierId')}
                          options={suppliers}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Giá đối tác thu</label>
                      <div className="form-group">
                        <div className="input-group">
                          <span className="input-group-prepend">
                            <span className="input-group-text">VND</span>
                          </span>
                          <div className="multiselect-native-select">
                            <Cleave
                              value={model.supplierAmount}
                              disabled={supplierDefaultValue.value === 0 ? 'disabled' : ''}
                              onChange={this.onChangeHandle.bind(this, 'supplierAmount')}
                              className="form-control input-numeral"
                              placeholder="Số tiền"
                              options={{
                                numeral: true,
                                numeralThousandsGroupStyle: 'thousand',
                                numeralDecimalScale: 0
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-md-6">
                      <label>Ngày gửi đối tác</label>
                      <div className="form-group">
                        <div className="input-group">
                          <span className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="icon-calendar"></i>
                            </span>
                          </span>
                          <div className="multiselect-native-select">
                            <InputMask
                              disabled={supplierDefaultValue.value === 0 ? 'disabled' : ''}
                              value={dateDisplay.supplierSendAt}
                              className="form-control"
                              mask="99/99/9999"
                              onChange={this.onChangeHandle.bind(this, 'supplierSendAt')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </Tab>
              : '' }
          </Tabs>
        </div>
      </div>
    </div>
    );
  }

  render() {
    const {
      model,
      customizeField
    } = this.state;
    return (
      <div className={
        this.state.displayForm === true
          ? cx('toggler', { 'toggler--form': this.state.displayForm })
          : cx('toggler', { 'toggler--wallet': this.state.displayWallet })
      }>

        <Tooltip
          placement="right"
          isOpen={this.state.tooltipOpen}
          target="TooltipExample"
          toggle={this.toggleTips}>
          <table border={0} style={{ width: 180 }}>
            <tbody>
              <tr>
                <td style={{ width: 65, textAlign: 'left' }}>Khả dụng <span style={{ float: 'right' }}>:</span></td>
                <td style={{ textAlign: 'right' }}>{_.get(this.props.wallet, 'amount', 0).toLocaleString()}</td>
              </tr>
              <tr>
                <td style={{ width: 65, textAlign: 'left' }}>Tiền mặt <span style={{ float: 'right' }}>:</span></td>
                <td style={{ textAlign: 'right' }}>{_.get(this.props.wallet, 'detail.cash', 0).toLocaleString()}</td>
              </tr>

              <tr>
                <td style={{ width: 65, textAlign: 'left' }}>Tín dụng <span style={{ float: 'right' }}>:</span></td>
                <td style={{ textAlign: 'right' }}>{_.get(this.props.wallet, 'detail.credit', 0).toLocaleString()}</td>
              </tr>

              <tr>
                <td style={{ width: 65, textAlign: 'left' }}>Hạn mức <span style={{ float: 'right' }}>:</span></td>
                <td style={{ textAlign: 'right' }}> {_.get(this.props.wallet, 'detail.creditLimit', 0).toLocaleString()}</td>
              </tr>
            </tbody>
          </table>
        </Tooltip>

        <div className="row">
          <div className="col-xl-12">
            <div className="card ">
              <div className="card-header header-elements-inline">
                <h5 className="card-title">Thông tin Khách hàng</h5>
                <div className="header-elements"></div>
              </div>
              {this.renderAccountInfo()}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card ">
              <div className="card-header header-elements-inline">
                <h5 className="card-title">Thông tin hồ sơ</h5>
              </div>
              <div className="card-body">
                <div className="form-group">
                  {this.renderProfiles()}
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="row">
          {this.renderOrderInfo()}
          {this.renderEstimate()}
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card ">
              <div className="card-header header-elements-inline">
                <h5 className="card-title">Thông tin bổ sung</h5>
                <div className="header-elements"></div>
              </div>
              <div className="card-body ">
                <em>{(_.isEmpty(customizeField) ? 'Không có thông tin cần bổ sung thêm' : '')}</em>
                {customizeField.map((v, k) => {
                  switch (v.type) {
                    case 'INPUT_TEXT':
                      return (
                        <div key={`customize_${v.key}`} className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-12">
                                  <label>{v.title} <span className="text-danger">{(v.require === true) ? '*' : ''}</span></label>
                                  <div className="form-group">
                                    <div className="input-group">
                                      <input
                                        ref={(input) => {
                                          this.input[v.key] = {
                                            name: v.key, title: v.title, isActive: true, required: v.require, e: input
                                          }; 
                                        }} 
                                        value={model.customizeField[v.key]}
                                        type="text"
                                        onChange={this.onChangeCustomizeFieldHandle.bind(this, v.key, v.type)}
                                        className="form-control"
                                        placeholder={v.placeHolder ? v.placeHolder : ''}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>);
                        
                    case 'INPUT_DATE':
                      return (
                        <div key={`customize_${v.key}`} className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-12">
                                  <label>{v.title} <span className="text-danger">{(v.require === true) ? '*' : ''}</span></label>
                                  <div className="form-group">
                                    <div className="input-group">
                                      <span className="input-group-prepend">
                                        <span className="input-group-text">
                                          <i className="icon-calendar"></i>
                                        </span>
                                      </span>
                                      <div className="multiselect-native-select">
                                        <InputMask
                                          ref={(input) => {
                                            this.input[v.key] = {
                                              name: v.key, title: v.title, isActive: true, required: v.require, e: input
                                            }; 
                                          }} 
                                          value={model.customizeField[v.key] || 'DD/MM/YYYY'}
                                          className="form-control"
                                          mask="99/99/9999"
                                          onChange={this.onChangeCustomizeFieldHandle.bind(this, v.key, v.type)}
                                        />

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );

                    case 'CHECKBOX':
                      return (
                        <div key={`customize_${v.key}`} className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-12">
                                  <label>{v.title} <span className="text-danger">{(v.require === true) ? '*' : ''}</span></label>
                                  <div className="form-group">
                                    {v.items.map((sv, sk) => {
                                      return (<div key={`customize_subcheck_${v.key}_${sk}`} className="form-check form-check-inline">
                                        <label className="form-check-label" title={v.title}>
                                          <div className="uniform-checker">
                                            {/* <span className={model.checkTest === true ? 'checked' : ''}> */}
                                            <span className={this.onCheckCustomizeFieldHandle(v.key, v.type, sv.value, v.defaultValue) === true ? 'checked' : ''}>
                                              <input
                                                value={sv.value}
                                                checked={this.onCheckCustomizeFieldHandle(v.key, v.type, sv.value, v.defaultValue) === true ? 'checked' : ''}
                                                onChange={this.onChangeCustomizeFieldHandle.bind(this, v.key, v.type)}
                                                type="checkbox"
                                                className="form-check-input-styled"
                                                data-fouc="true" />
                                            </span>
                                          </div>{sv.title}</label>
                                      </div>);
                                    })
                                    }
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );

                    case 'RADIO': 
                      return (
                        <div key={`customize_${v.key}`} className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-12">
                                  <label>{v.title} <span className="text-danger">{(v.require === true) ? '*' : ''}</span></label>
                                  <div className="form-group">
                                    {v.items.map((sv, sk) => {
                                      return (<div key={`customize_subradio_${v.key}_${sk}`} className="form-check">
                                        <label className="form-check-label">
                                          <div className="uniform-choice">
                                            <span className={this.onCheckCustomizeFieldHandle(v.key, v.type, sv.value) ? 'checked' : ''}>
                                              <input
                                                // checked={model.availability === 'customer'}
                                                onChange={this.onChangeCustomizeFieldHandle.bind(this, v.key, v.type)}
                                                value={sv.value}
                                                type="radio"
                                                name="availability"
                                                className="form-input-styled"
                                                data-fouc="true"
                                              />
                                            </span>
                                          </div>{sv.title}
                                        </label>
                                      </div>);
                                    })
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>);

                    case 'LISTBOX':
                      return (<div key={`customize_${v.key}`} className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-12">
                                <label>{v.title} <span className="text-danger">{(_.isEmpty(v.require) ? '*' : '')}</span></label>
                                <div className="form-group">
                                  <Select
                                    value={this.onCheckCustomizeFieldHandle(v.key, v.type, null, v.defaultValue)}
                                    onChange={this.onChangeCustomizeFieldHandle.bind(this, v.key, v.type)}
                                    options={this.onConvertDataSettingListbox(v.items)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>);
                    default:
                      break;
                  }
                  return true;
                })}
                
              </div>
            </div>
          </div>
        </div>
        
        <div className="row">
          <form
            style={{ width: '100%' }}
            onSubmit={this.onSubmit}>
            <div style={{ textAlign: 'center', width: '100%' }}>
              <button
                onClick={this.submit.bind(this, 'add')}
                className="btn btn-primary"
                style={{
                  marginTop: 28,
                  height: 35,
                  width: 200,
                  fontWeight: 'bolder'
                }}
              >
                <i className="fa fa-save"></i> Tạo đơn hàng
              </button>

              <button
                onClick={this.submit.bind(this, 'addNew')}
                className="btn btn-success"
                style={{
                  marginLeft: 20,
                  marginTop: 28,
                  height: 35,
                  width: 200,
                  fontWeight: 'bolder'
                }}
              >
                <i className="fa fa-save"></i> Lưu & tạo mới
              </button>

            </div>
          </form>
        </div>

      </div>

    );
  }

  componentDidUpdate(oldProps) {
    try {
      const newProps = this.props;
      if (oldProps.buildServices !== newProps.buildServices) {
        const parentIdentifies = this.props.listServiceHandleData();
        const model = _.clone(this.state.model);
        this.setState({ parentIdentifies: parentIdentifies.list, model });
      }
      if (oldProps.suppliers !== newProps.suppliers) {
        const objSupplier = [];
        objSupplier.push(
          { value: 0, label: 'Không' },
        );
        newProps.suppliers.map((v) => {
          objSupplier.push(
            { value: v.id, label: v.title }
          );
          return true;
        });
        this.setState({
          suppliers: objSupplier
        });
      }
      if (oldProps.socketBalance !== newProps.socketBalance) {
        this.callbackWallet();
      }
    } catch (error) {
      console.log(error);
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      init: payload.generalReducer.init,
      socketBalance: payload.walletReducer.socketBalance,
      wallet: payload.walletReducer.wallet,
      orderTags: payload.orderReducer.orderTags,
      buildServices: payload.serviceReducer.buildServices,
      agencies: payload.agencyReducer.agencies,
      suppliers: payload.supplierReducer.suppliers,
      agency: payload.agencyReducer.agency,
      accounts: payload.accountReducer.accounts,
      profiles: payload.generalReducer.profiles,
      userInfo: payload.generalReducer.userInfo,
      uploadPath: payload.generalReducer.uploadPath,
      downloadPath: payload.generalReducer.downloadPath,
      listScope: payload.generalReducer.listScope,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(CreateImmigrationOrder)
);
