import * as types from './branchs.types';

/**
 * 
 * @param {*} payload 
 * @param {*} callback 
 */
export function searchBranchList(payload, callback) {
  return {
    type: types.POST_SEARCH_LIST_BRANCH,
    payload,
    callback
  };
}

export function searchBranch(payload, callback) {
  return {
    type: types.POST_SEARCH_BRANCH,
    payload,
    callback
  };
}

export function detailBranch(payload, callback) {
  return {
    type: types.GET_BRANCH,
    payload,
    callback
  };
}

export function createBranch(payload, callback) {
  return {
    type: types.POST_BRANCH,
    payload,
    callback
  };
}

export function modifyBranch(payload, callback) {
  return {
    type: types.PUT_BRANCH,
    payload,
    callback
  };
}
export function deleteBranch(payload, callback) {
  return {
    type: types.DELETE_BRANCH,
    payload,
    callback
  };
}
