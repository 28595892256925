import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import { NotificationContainer, NotificationManager } from 'react-notifications';

class CreateDepartment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      branchList: [],
      branchScope: [],
      isDisable: false,
      defaultBranch: {},
      defaultBranchPermission: [],
      model: {
        title: '',
        description: '',
        address: '',
        phone: '',
        parentIdentifyCode: 'root',
        type: 'department',
        scopes: [],
        isActive: true
      }
      
    };
    
    this.onChangeHandle = this.onChangeHandle.bind(this);
  }
  
  onSubmit = async (e) => {
    try {
      e.preventDefault();
      const { model } = this.state;
      const validate = this.validateOnSubmit(model);
      if (validate === true) {
        await this.props.onSubmit(model);
      }
    } catch (error) {
      /**
       * 
       */
    }
  }

  validateOnSubmit(model) {
    if (_.isEmpty(model.title) === true) {
      NotificationManager.error('Tên phòng ban không được để trống', '', 1500);
      return false;
    }

    if (_.isEmpty(model.description) === true) {
      NotificationManager.error('Mô tả không được để trống', '', 1500);
      return false;
    }

    if (_.isEmpty(model.parentIdentifyCode) === true) {
      NotificationManager.error('Chi nhánh không được để trống', '', 1500);
      return false;
    }

    if (_.isEmpty(model.scopes) === true) {
      NotificationManager.error('Nhóm Quyền Trực Thuộc không được để trống', '', 1500);
      return false;
    }
    return true;
  }

  onChangeHandle(key, e) {
    const model = Object.assign({}, this.state.model);
    const result = {};
    switch (key) {
      case 'parentIdentifyCode': {
        const { listBranch, listScope } = this.props;
        const defaultBranchPermission = [];
        const branch = _.find(listBranch, { value: e.value });
        const branchScope = [];
        result.isDisable = true;
        if (_.isEmpty(branch.scopes) === false) {
          branch.scopes.forEach((scope) => {
            const label = _.find(listScope, { code: scope });
            branchScope.push({ value: scope, label: _.get(label, 'description', '') });
          });
          result.isDisable = false;
        }
        model[key] = e.value;
        result.defaultBranch = e;
        result.branchScope = branchScope;
        result.defaultBranchPermission = defaultBranchPermission;
        break;
      }
      case 'scopes': {
        const scopes = [];
        e.forEach((element) => {
          scopes.push(_.get(element, 'target.value', _.get(element, 'value', false)));
        });
        model[key] = scopes;
        result.defaultBranchPermission = e;

        break;
      }
      default:
        model[key] = _.get(e, 'target.value', _.get(e, 'value', false));
        break;
    }
    result.model = model;
    this.setState(result);
  }

  render() {
    const {
      model, isDisable, defaultBranchPermission, defaultBranch, branchScope
    } = this.state;
    
    const { listBranch } = this.props;
    
    return (
      <div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card ">
              <div className="card-header header-elements-inline">
                <h5 className="card-title">Cập nhật Phòng Ban</h5>
                <div className="header-elements">
                </div>
              </div>
              <div className="card-body ">
                <form 
                  onSubmit={this.onSubmit}
                >
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <label>Tên Phòng Ban</label>
                        <input 
                          value={model.title} 
                          onChange={this.onChangeHandle.bind(this, 'title')}
                          type="text" 
                          className="form-control" 
                          required="" />
                      </div>
                      <div className="col-md-6">
                        <label>Mô tả</label>
                        <input 
                          value={model.description} 
                          onChange={this.onChangeHandle.bind(this, 'description')}
                          type="text" 
                          className="form-control"
                          required="" />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <label>Chi nhánh</label>
                        <Select
                          value={defaultBranch} 
                          onChange={this.onChangeHandle.bind(this, 'parentIdentifyCode')}
                          options={listBranch}
                        />
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Nhóm Quyền Trực Thuộc</label>
                          <Select
                            value={defaultBranchPermission} 
                            onChange={this.onChangeHandle.bind(this, 'scopes')}
                            options={branchScope}
                            isDisabled={isDisable}
                            isMulti
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-right">
                    <button className="btn btn-primary">Lưu lại</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <NotificationContainer />
      </div>
     
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      listBranch: payload.branchReducer.listBranch,
      department: payload.departmentReducer.department,
      listScope: payload.generalReducer.listScope,
      userInfo: payload.generalReducer.userInfo,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(CreateDepartment)
);
