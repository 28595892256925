import React from 'react';

class NotFoundContainer extends React.Component {
  render() {
    return (
      <div>404 Page - File Not Found</div>
    );
  }
}

export default NotFoundContainer;

