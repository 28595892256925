import * as types from './departments.types';

/**
 * 
 * @param {*} payload 
 * @param {*} callback 
 */
export function listDepartment(payload, callback) {
  return {
    type: types.POST_SEARCH_DEPARTMENT,
    payload,
    callback
  };
}

export function detailDepartment(payload, callback) {
  return {
    type: types.GET_DEPARTMENT,
    payload,
    callback
  };
}

export function createDepartment(payload, callback) {
  return {
    type: types.POST_DEPARTMENT,
    payload,
    callback
  };
}

export function modifyDepartment(payload, callback) {
  return {
    type: types.PUT_DEPARTMENT,
    payload,
    callback
  };
}
export function deleteDepartment(payload, callback) {
  return {
    type: types.DELETE_DEPARTMENT,
    payload,
    callback
  };
}
