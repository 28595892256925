import * as types from './orders.types';

/**
 * 
 * @param {*} payload 
 * @param {*} callback 
 */

export function orderTagSearch(payload, callback) {
  return {
    type: types.POST_ORDER_TAG_SEARCH,
    payload,
    callback
  };
}

export function searchSimpleOrder(payload, callback) {
  return {
    type: types.POST_SEARCH_SIMPLE_ORDER,
    payload,
    callback
  };
}

export function searchOrder(payload, callback) {
  return {
    type: types.POST_SEARCH_ORDER,
    payload,
    callback
  };
}

export function detailOrder(payload, callback) {
  return {
    type: types.POST_ORDER_DETAIL,
    payload,
    callback
  };
}

export function createOrder(payload, callback) {
  return {
    type: types.POST_ORDER,
    payload,
    callback
  };
}

export function modifyOrder(payload, callback) {
  return {
    type: types.PUT_ORDER,
    payload,
    callback
  };
}

export function modifyOrderState(payload, callback) {
  return {
    type: types.PUT_ORDER_STATE,
    payload,
    callback
  };
}

export function orderModifyProcess(payload, callback) {
  return {
    type: types.PUT_ORDER_PROCESS,
    payload,
    callback
  };
}

export function orderModifyPin(payload, callback) {
  return {
    type: types.PUT_ORDER_PIN,
    payload,
    callback
  };
}

export function deleteOrder(payload, callback) {
  return {
    type: types.DELETE_ORDER,
    payload,
    callback
  };
}
