import * as types from './orders.types';

const initialState = {
  orderTags: [],
  orders: [],
  simpleOrders: [],
  order: {},
  socketOrder: {}
};

export default function orders(state = initialState, action) {
  switch (action.type) {
    case types.SOCKET_ORDER: {
      return {
        ...state,
        socketOrder: action.payload
      };
    }
    
    case types.POST_SEARCH_SIMPLE_ORDER_SUCCESS: {
      return {
        ...state,
        simpleOrders: action.payload
      };
    }

    case types.POST_SEARCH_ORDER_SUCCESS: {
      return {
        ...state,
        orders: action.payload
      };
    }
    
    case types.POST_ORDER_TAG_SEARCH_SUCCESS: {
      return {
        ...state,
        orderTags: action.payload
      };
    }
    
    case types.POST_ORDER_DETAIL_SUCCESS: {
      return {
        ...state,
        order: action.payload
      };
    }
    default:
      return state;
  }
}
