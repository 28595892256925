import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import SearchService from './searchService';
import * as ServiceActions from '../../../redux/services/services.actions';
import { SYSTEM_SERVICE_SCOPE } from '../../../configs/constants';

import {
  checkScope
} from '../../../helpers';

class SearchServiceContainer extends React.Component {
  constructor(prop) {
    super(prop);

    if (checkScope(SYSTEM_SERVICE_SCOPE, this.props) === false) {
      NotificationManager.error('Chức năng không tồn tại', '', 1500);
      setTimeout(() => {
        this.props.history.push('/dashboard');
      }, 2000);
    }

    this.state = {
      listService: [],
      listParentIdentify: []
    };

    this.removeService = this.removeService.bind(this);
    this.callBackRemoveService = this.callBackRemoveService.bind(this);

    this.getServices = this.getServices.bind(this);
    this.callbackGetService = this.callbackGetService.bind(this);
  }

  removeService(id) {
    try {
      const { serviceAct } = this.props;
      serviceAct.deleteService({ id }, this.callBackRemoveService);
    } catch (error) {
      // console.log('error', error);
    }
  }

  callBackRemoveService(status, data) {
    if (status) {
      NotificationManager.info('Xóa dịch vụ thành công', '', 1500);
      this.getServices();
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }

  getServices() {
    try {
      const { serviceAct } = this.props;
      serviceAct.listService({}, this.callbackGetService);
    } catch (error) {
      // console.log('error', error);
    }
  }
  
  callbackGetService(status, data) {
    try {
      if (status) {
        this.setState({ listService: data });
      } 
    } catch (error) {
      // console.log(error);
    }
  }

  render() {
    const { listService } = this.state;
    return (
      <div>
        <SearchService
          listService={listService}
          errorMessage={this.state.errorMessage}
          userInfo={this.userInfo}
          removeService={this.removeService}
        />
        <NotificationContainer />
      </div>
        
    );
  }

  componentDidMount() {
    try {
      /**
       * 
       */
      this.getServices();
    } catch (error) {
      /**
       * 
       */
    }
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    //
    serviceAct: bindActionCreators(ServiceActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    localStoreFilter: rootState.generalReducer.localStoreFilter,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(SearchServiceContainer);
