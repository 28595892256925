import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationManager } from 'react-notifications';
import SearchInvoice from './searchInvoice';
import * as OrderActions from '../../../redux/orders/orders.actions';
import * as ServiceActions from '../../../redux/services/services.actions';
import * as AgenciesActions from '../../../redux/agencies/agencies.actions';
import * as GeneralActions from '../../../redux/general/general.actions';

import store from '../../../configs/store.config';
import { PAGE_LIMIT } from '../../../configs/constants';

import {
  serviceDetail, serviceHandleDatas, localStoreFilter
} from '../../../helpers';

const defaultSearch = {
  filters: {
    id: '',
    serviceIdentifyCode: '',
    profile: {
      fullName: ''
    }
  },
  paging: {
    start: 0,
    limit: PAGE_LIMIT
  }
};

class SearchInvoiceContainer extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = {
      searching: {},
      orders: [],
      listService: [],
      listAccountState: []
    };

    this.getOrders = this.getOrders.bind(this);
    //
    this.onSubmitExpireInvoice = this.onSubmitExpireInvoice.bind(this);
    this.callbackExpireInvoice = this.callbackExpireInvoice.bind(this);
    //
    this.onSubmitProfileFile = this.onSubmitProfileFile.bind(this);
    this.callbackProfileFile = this.callbackProfileFile.bind(this);
    //
    this.listServiceHandleData = this.listServiceHandleData.bind(this);
    this.getServiceDetail = this.getServiceDetail.bind(this);
    this.getInit = this.getInit.bind(this);
  }

  onSubmitExpireInvoice = async (args) => {
    try {
      const { orderAct } = this.props;
      const query = Object.assign({}, args);
      if (query.description === '') {
        delete query.description;
      }
      orderAct.modifyOrderState(query, this.callbackExpireInvoice);
    } catch (error) {
      // console.log('error', error);
    }
  }

  onSubmitProfileFile = async (args) => {
    try {
      const { orderAct } = this.props;
      const query = Object.assign({}, args);
      if (query.description === '') {
        delete query.description;
      }
      orderAct.modifyOrderState(query, this.callbackProfileFile);
    } catch (error) {
      // console.log('error', error);
    }
  }

  getInit() {
    try {
      const accessToken = _.get(this.props, 'userInfo.accessToken', false);
      if (_.isEmpty(accessToken) === false) {
        const { generalAct } = this.props;
        generalAct.getInit({ accessToken });
      }
    } catch (error) {
      // console.log('error', error);
    }
  }

  callbackExpireInvoice(status, data) {
    if (status) {
      const orderFilter = localStoreFilter('searchInvoice', 'getOrders');
      this.getOrders(orderFilter || defaultSearch);

      const fails = _.get(data, 'fails', []);
      if (_.isEmpty(fails) === true) {
        NotificationManager.info('Hủy đơn hàng thành công', '', 1500);
        this.getInit();
      } else {
        const orderIds = [];
        _.forEach(fails, (item) => {
          orderIds.push(`#${item.invoiceId}`);
        });
        NotificationManager.warning(`Đơn hàng ${orderIds.join(',')} xảy ra lỗi`, '', 3000);
      }
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }

  callbackProfileFile(status, data) {
    if (status) {
      const orderFilter = localStoreFilter('searchInvoice', 'getOrders');
      this.getOrders(orderFilter || defaultSearch);

      const fails = _.get(data, 'fails', []);
      if (_.isEmpty(fails) === true) {
        NotificationManager.info('Bổ sung hồ sơ thành công', '', 1500);
        this.getInit();
      } else {
        const orderIds = [];
        _.forEach(fails, (item) => {
          orderIds.push(`#${item.invoiceId}`);
        });
        NotificationManager.warning(`Đơn hàng ${orderIds.join(',')} xảy ra lỗi`, '', 3000);
      }
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }

  getOrders(args) {
    try {
      const { orderAct } = this.props;
      const filters = Object.assign({}, args.filters);
      const query = Object.assign({}, args);
      Object.keys(filters).forEach((key) => {
        if (_.isObject(filters[key]) === true) {
          Object.keys(filters[key]).forEach((key2) => {
            if (_.isEmpty(filters[key][key2]) === true) {
              delete filters[key][key2];
            }
          });
        } else if (_.isEmpty(filters[key]) === true) {
          delete filters[key];
        }
        if (_.isEmpty(filters[key]) === true) {
          delete filters[key];
        }
      });
      query.filters = filters;
      orderAct.searchOrder(query);

      store.dispatch({
        type: 'LOCAL_STORE_FILTER',
        payload: {
          objID: 'searchInvoice',
          objType: 'getOrders',
          query
        }
      });
    } catch (error) {
      console.log(error);
      // console.log('error', error);
    }
  }

  /**
   * 
   */
  getServiceDetail(serviceIdentifyCode) {
    try {
      const { buildServices } = this.props;
      if (_.isEmpty(buildServices) === false) {
        return serviceDetail(buildServices, serviceIdentifyCode);
      }
      return {};
    } catch (error) {
      return {};
    }
  }

  listServiceHandleData() {
    try {
      const result = { list: [], setlectedItem: {} };
      const { buildServices } = this.props;

      if (_.isEmpty(buildServices) === false) {
        return serviceHandleDatas(buildServices);
      }
      return result;
    } catch (error) {
      return false;
    }
  }

  render() {
    return (
      <div>
        <SearchInvoice
          listParentIdentify={this.state.listParentIdentify}
          errorMessage={this.state.errorMessage}
          getServiceDetail={this.getServiceDetail}
          getOrders={this.getOrders}
          onSubmitExpireInvoice={this.onSubmitExpireInvoice}
          onSubmitProfileFile={this.onSubmitProfileFile}
          listServiceHandleData={this.listServiceHandleData}
          userInfo={this.userInfo}
          minimize={this.props.minimize || false}
        />
      </div>
        
    );
  }

  componentWillMount() {
    try {
      const { serviceAct } = this.props;
      serviceAct.listService({});
      
      const { agenciesAct } = this.props;
      agenciesAct.searchAgency({
        filters: {
          group: ['customer', 'agency', 'collaborator']
        }
      });

      const orderFilter = localStoreFilter('searchInvoice', 'getOrders');
      this.getOrders(orderFilter || defaultSearch);
    } catch (error) {
      // console.log(error);
    }
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    //
    serviceAct: bindActionCreators(ServiceActions, dispatch),
    orderAct: bindActionCreators(OrderActions, dispatch),
    generalAct: bindActionCreators(GeneralActions, dispatch),
    agenciesAct: bindActionCreators(AgenciesActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    agencies: rootState.agencyReducer.agencies,
    orderTags: rootState.orderReducer.orderTags,
    orders: rootState.orderReducer.orders,
    buildServices: rootState.serviceReducer.buildServices,
    localStoreFilter: rootState.generalReducer.localStoreFilter,
    init: rootState.generalReducer.init,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(SearchInvoiceContainer);
