import { all, fork } from 'redux-saga/effects';
import * as General from './general';
import * as Service from './services';
import * as Branch from './branchs';
import * as Department from './departments';
import * as Account from './accounts';
import * as Agency from './agencies';
import * as Supplier from './suppliers';
import * as Order from './orders';
import * as CoboPobo from './coboPobos';
import * as ExportData from './exportData';
import * as ArrivalOrder from './arrivalOrders';
import * as Wallet from './wallets';
import * as CallLog from './callLogs';
import * as Support from './supports';
import * as Utility from './utilities';
import * as Link from './links';

function* rootSagas() {
  yield all([
    /**
     * General
     */
    fork(General.postProfileSearch),
    fork(General.postAccountMenu),
    fork(General.postAccountChangePass),
    fork(General.postAccountInfo),
    fork(General.postAccountLogin),
    fork(General.postAccountUpdateInfo),
    fork(General.postAccountlogout),
    fork(General.getScope),
    fork(General.getAccountInit),
    fork(General.putCountryArrival),
    /**
     * Service
     */
    fork(Service.getBuildService),
    fork(Service.getService),
    fork(Service.postService),
    fork(Service.putService),
    fork(Service.deleteService),

    /**
     * Branch
     */
    fork(Branch.postSearchListBranch),
    fork(Branch.postSearchBranch),
    fork(Branch.postBranchDetail),
    fork(Branch.postBranch),
    fork(Branch.putBranch),
    fork(Branch.deleteBranch),
    /**
     * Department
     */
    fork(Department.postSearchDepartment),
    fork(Department.postDepartmentDetail),
    fork(Department.postDepartment),
    fork(Department.putDepartment),
    fork(Department.deleteDepartment),
    /**
     * Account
     */
    fork(Account.postAccount),
    fork(Account.postSearchAccount),
    fork(Account.postSearchMember),
    fork(Account.postSearchByKeywordAccount),
    fork(Account.postAccountDetail),
    fork(Account.putAccount),
    fork(Account.putAccountProfile),
    /**
     * Supplier
     */
    fork(Supplier.postSupplier),
    fork(Supplier.postSearchSupplier),
    fork(Supplier.postSearchByKeywordSupplier),
    fork(Supplier.postSupplierDetail),
    fork(Supplier.putSupplier),
    /**
     * Agency
     */
    fork(Agency.postAgency),
    fork(Agency.postSearchAgency),
    fork(Agency.postSearchByKeywordAgency),
    fork(Agency.postAgencyDetail),
    fork(Agency.putAgency),
    /**
     * Order
     */
    fork(Order.postOrderDetail),
    fork(Order.postOrderDetail),
    fork(Order.postOrderTagSearch),
    fork(Order.postOrder),
    fork(Order.putOrder),
    fork(Order.putOrderState),
    fork(Order.putOrderProcess),
    fork(Order.putOrderPin),
    fork(Order.postSearchOrder),
    fork(Order.postSearchSimpleOrder),
    /**
     * CoboPobo
     */
    fork(CoboPobo.postCreateCoboPobo),
    fork(CoboPobo.postSearchCoboPobo),
    fork(CoboPobo.postExportCoboPobo),
    fork(CoboPobo.postDetailCoboPobo),
    fork(CoboPobo.putCoboPobo),
    /**
     * Export Data
     */
    fork(ExportData.postExportData),
    fork(ExportData.postExportDataSummary),
    /**
     * 
     */
    fork(ArrivalOrder.postArrivalOrder),
    fork(ArrivalOrder.putArrivalOrder),
    /**
     * Wallet
     */
    fork(Wallet.postHistoryWallet),
    fork(Wallet.postHistoryCreditWallet),
    fork(Wallet.postSearchWallet),
    fork(Wallet.postSearchWalletDetail),
    fork(Wallet.putChangeWallet),
    /**
     * 
     */
    fork(CallLog.PostPickupCallLogs),
    fork(CallLog.PostCallLogDetail),
    fork(CallLog.PostCallLogs),
    fork(CallLog.PutCallLogs),
    fork(CallLog.postCallLogClickToCall),
    /**
     * Support
     */
    fork(Support.PostSearchCustomerSupportByCustomerType),
    fork(Support.PostSearchCustomerSupport),
    fork(Support.PostCustomerSupportDetail),
    fork(Support.PostCustomerSupport),
    fork(Support.PutCustomerSupport),
    fork(Support.DeleteCustomerSupport),
    /**
     * Utility
     */
    fork(Utility.PostSendMail),
    fork(Utility.PostSendSMS),
    /**
     * Link
     */
    fork(Link.PostDeactiveLink),
    fork(Link.PostInfoLink)
  ]);
}

export default rootSagas;

