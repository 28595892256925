import _ from 'lodash';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import Cleave from 'cleave.js/react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import UploadFileContainer from '../../ultils/uploadFile.container';
import {
  fileUpload
} from '../../../helpers';

class CreateReceiptVoucher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledDepartment: true,
      selectedFile: [],
      departmentTitle: '',
      branchTitle: '',
      birthdayDisplay: '',
      joinedDisplay: '',
      uploading: false,
      allowance: [{ key: '', value: '' }],
      model: {},
      discounts: []
    };

    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.addDiscountHandle = this.addDiscountHandle.bind(this);
    this.setFiles = this.setFiles.bind(this);
  }

  onChangeHandle(key, e) {
    try {
      //
    } catch (error) {
      //
    }
  }

  setFiles(file, field) {
    try {
      if (_.isEmpty(file) === false) {
        const selectedFile = Object.assign({}, this.state.selectedFile);
        selectedFile[field] = file;
        this.setState({ selectedFile });
      }
    } catch (error) {
      // console.log(error);
    }
  }

  /**
   * 
   */
  onDiscountChangeHandle(key, index, e) {
    try {
      const discounts = Object.assign([], this.state.discounts);
      let checkExistLevel = false;
      if (key === 'level') {
        checkExistLevel = !!_.find(discounts, { level: e.value });
      }
      console.log({ checkExistLevel });

      if (checkExistLevel === false) {
        switch (key) {
          case 'level':
          case 'type':
            discounts[index][key] = e.value;
            break;
          case 'value': {
            discounts[index][key] = Number(_.get(e, 'target.rawValue', _.get(e, 'value', 0)));
            break;
          }
          default:
            discounts[index][key] = e.target.value;
            break;
        }
        this.setState({ discounts });
      } else {
        NotificationManager.error('Cấp chiết khấu đã tồn tại', '', 1500);
      }
    } catch (error) {
      console.log(error);
    }
  }

  addDiscountHandle() {
    const discounts = Object.assign([], this.state.discounts);
    console.log(discounts);
    discounts.push({ level: '', type: '', value: '' });
    this.setState({ discounts });
  }

  removeDiscountHandle(index) {
    if (_.isNumber(index) === true) {
      const discounts = Object.assign([], this.state.discounts);
      delete discounts[index];
      this.setState({ discounts });
    }
  }

  /**
   * 
   */

  receiptRender() {
    const { discounts } = this.state;
    if (_.isEmpty(discounts) === false) {
      return (discounts.map((item, i) => {
        return (<tr key={`discountRender_${i}`}>
          <td></td>
          <td>
            <input
              value={item.key}
              onChange={this.onDiscountChangeHandle.bind(this, 'key', i)}
              type="text"
              className="form-control "
              placeholder="Nội dung thanh toán" />
          </td>
          <td>
            <Cleave
              onChange={this.onDiscountChangeHandle.bind(this, 'value', i)}
              className="form-control input-numeral"
              // placeholder="Nhập giá trị giảm"
              options={{
                numeral: true,
                numeralThousandsGroupStyle: 'thousand',
                numeralDecimalScale: 0
              }}
            />
          </td>
          <td>
            <Cleave
              
              onChange={this.onDiscountChangeHandle.bind(this, 'value', i)}
              className="form-control input-numeral"
              // placeholder="Nhập giá trị giảm"
              options={{
                numeral: true,
                numeralThousandsGroupStyle: 'thousand',
                numeralDecimalScale: 0
              }}
            />
          </td>
          <td>
            <Cleave
              disabled
              onChange={this.onDiscountChangeHandle.bind(this, 'value', i)}
              className="form-control input-numeral"
              // placeholder="Nhập giá trị giảm"
              options={{
                numeral: true,
                numeralThousandsGroupStyle: 'thousand',
                numeralDecimalScale: 0
              }}
            />
          </td>
          <td>
            <a href="#" onClick={this.removeDiscountHandle.bind(this, i)} >
              <i className="icon-cross3 red" />
            </a>
          </td>
        </tr>);
      }));
    }
    return (false);
  }

  render() {
    const {
      model,
      accountState,
      listVoip,
      accountSearchByKeyword,
      listMember

    } = this.state;

    return (
      <div>
        <div className="page-header page-header-light">
          <div className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="text-center d-lg-none w-100">
              <button type="button" className="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-second">
                <i className="icon-menu7 mr-2"></i>Profile navigation</button>
            </div>
          </div>
          <div className="content">
            <div className="d-flex align-items-start flex-column flex-md-row">
              <div className="tab-content w-100 overflow-auto order-2 order-md-1">

                <div className="card">
                  <div className="card-header header-elements-inline">
                    <h5 className="card-title">Thêm phiếu chi</h5>
                    <div className="header-elements">
                    </div>
                  </div>
                  <div className="card-body">
                    <form onSubmit={this.onSubmit} >
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">
                            <label>Nhân viên tạo <span className="text-danger">*</span></label>
                            <input
                              value={model.title}
                              onChange={this.onChangeHandle.bind(this, 'title')}
                              type="text"
                              className="form-control" />
                          </div>
                          <div className="col-md-6">
                            <label>
                              Thuộc nhóm dịch vụ <span className="text-danger">*</span>
                            </label>
                            <input
                              value={model.email}
                              onChange={this.onChangeHandle.bind(this, 'email')}
                              type="text"
                              placeholder="Nhập email"
                              className="form-control" />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          
                          <div className="col-md-6">
                            <label>Loại phiếu chi <span className="text-danger">*</span></label>
                            <input
                              value={model.phone}
                              onChange={this.onChangeHandle.bind(this, 'phone')}
                              type="text"
                              className="form-control" />
                          </div>

                          <div className="col-md-6">
                            <label>Tổng tiền <span className="text-danger">*</span></label>
                            <input
                              value={model.phone}
                              onChange={this.onChangeHandle.bind(this, 'phone')}
                              type="text"
                              className="form-control" />
                          </div>

                        </div>
                      </div>

                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">
                            <label>Nội dung <span className="text-danger">*</span></label>
                            <input
                              value={model.title}
                              onChange={this.onChangeHandle.bind(this, 'title')}
                              type="text"
                              className="form-control" />
                          </div>
                          <div className="col-md-6">
                            <label>Upload văn bản liên quan đến nhân sự này:<span className="text-danger">*</span></label>
                            <UploadFileContainer
                              setFiles={this.setFiles.bind(this)}
                              name="document"
                            />
                            <span className="form-text text-muted">Accepted formats: doc, docx, pdf, bmp, png, gif, jpg, jpeg</span>
                          </div>
                          
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="col-md-12">
                          <label>Diễn giải <span className="text-danger">*</span></label>
                          <textarea 
                            // value={model.salary.description} 
                            onChange={this.onChangeHandle.bind(this, 'description')} 
                            rows="4" 
                            cols="4"
                            placeholder="Nhập ghi chú." 
                            className="form-control"
                          ></textarea>
                          
                        </div>
                      </div>

                    </form>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header header-elements-inline">
                    <h5 className="card-title">Nội dung thanh toán</h5>
                    <div className="header-elements">
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="col-md-12">
                      <div className="form-group">
                        <table className="table_service table table-bordered">
                          <tbody>
                            <tr className="bg-gray-light tr_add">
                              <th width="30">
                                <button
                                  type="button"
                                  onClick={this.addDiscountHandle}
                                  className="addDocument" style={{ marginRight: 10 }}>+
                                </button>
                              </th>
                              <th>Chi tiết </th>
                              <th>Số Lượng</th>
                              <th>Đơn Giá </th>
                              <th>Thành Tiền </th>
                              <th width="30"></th>
                            </tr>
                            {this.receiptRender()}

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <NotificationContainer />
      </div>
     
    );
  }

  componentDidMount() {
    try {
      /**
       * 
       */
    } catch (error) {
      /**
       * 
       */
    }
  }

  componentWillUnmount() {
    try {
      /**
       * 
       */
    } catch (error) {
      /**
       * 
       */
    }
  }

  componentDidUpdate() {
    try {
      /**
       * 
       */
    } catch (error) {
      /**
       * 
       */
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      userInfo: payload.generalReducer.userInfo,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(CreateReceiptVoucher)
);
