import React from 'react';
import _ from 'lodash';
import validator from 'validator';

import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select2 from 'react-select2-wrapper';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { DelayInput } from 'react-delay-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPassport, faFileImport, faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons';
import PagingContainer from '../../ultils/paging.container';

import {
  formatDate, formatDateFilter, 
  formatDateRange, checkScope, 
  paging, localStoreFilter, localStorePageNum, formatDateFilter2
} from '../../../helpers';

import { 
  WALLET_SCOPE, 
  INVOICE_ARRIVAL_SCOPE, 
  INVOICE_EXIT_SCOPE,
  AGENCY_UPDATE_SCOPE,
  PAGE_LIMIT, 
  PAGE_MAX_LIMIT 
} from '../../../configs/constants';

class SearchAgency extends React.Component {
  constructor(props) {
    super(props);
    const agencyFilter = localStoreFilter('searchAgency', 'getAgencies');
    const createdAt = _.get(agencyFilter, 'filters.createdAt');
    let dateRangeTitle = 'Ngày dự kiến có KQ';
    if (createdAt) {
      dateRangeTitle = formatDateFilter2(createdAt.from, createdAt.to);
    }

    this.state = {
      pageNum: localStorePageNum('searchAgency', 'getAgencies'),
      dateRangeTitle,
      locationDefaultValue: 'all',
      groupDefaultValue: 'all',
      managerDefaultValue: 'all',
      ranges: {
        'Clear date': [1, 1],
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      },
      locations: [],
      groups: [
        { text: 'Tất cả nguồn', id: 'all' },
        { text: 'Đại lý', id: 'agency' },
        { text: 'Khách lẻ', id: 'customer' },
        { text: 'Cộng tác viên', id: 'collaborator' },
        { text: 'Nhà cung cấp', id: 'supplier' }
      ],
      managers: [],
      searching: agencyFilter || {
        filters: {
          group: ['agency', 'customer', 'collaborator', 'supplier'],
          area: '',
          title: '',
          phone: '',
          createdAt: {
            from: '',
            to: ''
          }
        },
        paging: {
          start: 0,
          limit: PAGE_LIMIT
        }
      }
    };
    this.handleDatePicker = this.handleDatePicker.bind(this);
    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
  }

  onChangePage(key) {
    const searching = Object.assign({}, this.state.searching);
    let pageNum = this.state.pageNum;
    switch (key) {
      case 'next': {
        //
        pageNum += 1;
        break;
      }
      default: {
        pageNum -= 1;
        if (pageNum <= 1) {
          pageNum = 1;
        }
        break;
      }
    }
    searching.paging = paging(pageNum, PAGE_LIMIT);
    this.props.getAgencies(searching);
    this.setState({ pageNum });
  }

  onChangeHandle(e) {
    const searching = Object.assign({}, this.state.searching);
    const result = {};
    const key = e.target.name;
    const value = e.target.value;

    switch (key) {
      case 'title': {
        const isNumber = validator.isNumeric(value);
        if (isNumber === true) {
          searching.filters.title = '';
          if (value.length > 7) {
            searching.filters.phone = value;
          } else if (value.length > 1) {
            searching.filters.id = value;
          } else {
            searching.filters.id = '';
            searching.filters.phone = '';
          }
        } else {
          searching.filters.title = value;
          searching.filters.phone = '';
          searching.filters.id = '';
        }
        result.searching = searching;
        break;
      }
      case 'group':
        if (value === 'all') {
          searching.filters.group = ['agency', 'customer', 'collaborator', 'supplier'];
        } else {
          searching.filters.group = [value];
        }
        result.groupDefaultValue = value;
        break;
      case 'location': {
        if (value === 'Tất cả khu vực') {
          searching.filters.area = '';
        } else {
          searching.filters.area = value;
        }
        result.locationDefaultValue = value;
        break;
      }
      case 'manager': {
        if (value === 'all') {
          searching.filters.parentIdentifyCode = '';
        } else {
          searching.filters.parentIdentifyCode = value;
        }
        result.managerDefaultValue = value;
        break;
      }
      default:
        searching[key] = value;
        break;
    }

    this.setState(result);
    this.props.getAgencies(searching);
  }

  handleDatePicker(e, picker) {
    const searching = Object.assign({}, this.state.searching);
    searching.filters.createdAt = {
      from: '',
      to: ''
    };
    if (picker.startDate.unix() > 0) {
      const pageNum = 1;
      const dateRangeTitle = formatDateFilter(picker.startDate, picker.endDate);
      const date = formatDateRange(picker.startDate, picker.endDate);
      this.setState({ searching, dateRangeTitle, pageNum: 1 });
      searching.filters.createdAt.from = date.startDate;
      searching.filters.createdAt.to = date.endDate;
      searching.paging = paging(this.state.pageNum, PAGE_LIMIT);
      this.props.getAgencies(searching);
    } else {
      this.setState({ searching, dateRangeTitle: 'Chọn thời gian', pageNum: 1 });
      this.props.getAgencies(searching);
    }
  }

  onSubmit = async (e) => {
    try {
      e.preventDefault();
      const { searching } = this.state;
      await this.props.onSubmit(searching);
    } catch (error) {
      /**
       * 
       */
    }
  }

  renderAgency() {
    const { agencies, init } = this.props;
    if (_.isEmpty(agencies) === true) {
      return (<tr></tr>);
    }

    const hasWalletPermission = checkScope(WALLET_SCOPE, this.props);
    const hasUpdateCustomerPermission = checkScope(AGENCY_UPDATE_SCOPE, this.props);
    const hasCreateInvoiceArrivalPermission = checkScope(INVOICE_ARRIVAL_SCOPE, this.props);
    const hasCreateInvoiceExitPermission = checkScope(INVOICE_EXIT_SCOPE, this.props);

    return (agencies.map((item, i) => {
      const linkTo = `/agency-modify/${item.id}`;
      const linkToCreateImmigrationOrder = `/order-immigration-create/${item.id}`;
      const linkToCreateOrder = `/order-create/${item.id}`;
      const linkToAccountWallet = `/account-wallet/${item.id}`;
      
      const createdAtDisplay = formatDate(item.createdAt);
      const lastAccessAt = formatDate(_.get(item, 'lastAccessAt', false));
      const lastCreatedInvoiceAt = formatDate(item.lastCreatedInvoiceAt);
      
      const area = _.find(init.area, { key: parseInt(item.area, 10) });
      const parent = _.find(item.parentPath, { identifyCode: item.parentIdentifyCode });
      const delegates = _.get(item, 'delegates', []);
      const discountLevel = _.get(item, 'discountLevel', false);
      const level = _.find(init.discount, { key: discountLevel });
      return (<tr key={i}>
        <td>{item.id}</td>
        <td>
          <div className="media">
            <div className="media-body align-self-center">
              {hasUpdateCustomerPermission ? <Link className="font-weight-semibold" to={linkTo} >
                {item.title}
              </Link> : ''}
              
              <div className="text-muted font-size-sm">
                <span className="badge badge-mark border-primary mr-1"></span>
                {_.get(area, 'value', '')}
              </div>
            </div>
          </div>
        </td>
        <td>{lastCreatedInvoiceAt || '-'}</td>
        <td>{_.get(item, 'statistic.order', '-')}</td>
        <td>{_.get(level, 'title', '-')}</td>
        <td>{createdAtDisplay}</td>
        <td>{parent.title}</td>
        
        <td>
          {this.renderDelegate(delegates)}
        </td>
        <td>{lastAccessAt}</td>
        <td className="text-center">
          <div className="list-icons">

            {hasCreateInvoiceArrivalPermission ? <Link
              style={{
                float: 'right', 
                color: 'black',
                fontSize: 14,
                backgroundColor: 'white'
              }}
              className="btn btn-sm "
              to={linkToCreateImmigrationOrder}
              title="Thêm đơn hàng nhập cảnh"
            >
              <FontAwesomeIcon icon={faFileImport} />
            </Link> : ''}
            {hasCreateInvoiceExitPermission ? <Link
              style={{
                float: 'right',
                color: 'black', 
                fontSize: 14, 
                backgroundColor: 'white'
              }}
              className="btn btn-sm "
              to={linkToCreateOrder}
              title="Thêm đơn hàng xuất cảnh"
            >
              <FontAwesomeIcon icon={faPassport} />
            </Link> : '' }

            {hasWalletPermission ? <Link
              style={{
                float: 'right', color: 'black', fontSize: 14, backgroundColor: 'white'
              }}
              className="btn btn-sm "
              to={linkToAccountWallet}
              title="Cập nhật ví"
            >
              
              <FontAwesomeIcon icon={faMoneyCheckAlt} />
            </Link> : ''}

          </div>
        </td>
        
      </tr>);
    }));
  }

  renderDelegate(data) {
    if (_.isEmpty(data) === true) {
      return (<span></span>);
    }
    return (data.map((item, i) => {
      return (<span key={`renderDelegate_${i}`} >
        <span className="badge badge-mark border-primary mr-1"></span>
        {_.get(item, 'title', '')} <br />
      </span>);
    }));
  }

  render() {
    const {
      locations,
      groups,
      managers,
      ranges,
      dateRangeTitle,
      locationDefaultValue,
      searching,
      managerDefaultValue
    } = this.state;

    let groupDefaultValue = searching.filters.group;
    if (_.isArray(searching.filters.group) === true) {
      if (searching.filters.group.length === 4) {
        groupDefaultValue = 'all';
      }
    }
    
    return (
      <div >
        <div
          style={{ borderColor: 'goldenrod' }}
          className="alert alert-info bg-white alert-styled-left alert-arrow-left alert-dismissible">
          <h6 className="alert-heading font-weight-semibold mb-1">
            Ghi chú
          </h6>
          <code>Vui lòng tìm khách hàng để tạo đơn hàng</code>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header bg-transparent header-elements-inline">
                <h4 className="card-title">Quản lý đại lý / Cộng tác viên</h4>
                <Link
                  style={{ float: 'right' }}
                  className="btn bg-teal-400"
                  to="/agency-create"
                >
                  <i className="icon-add mr-2"></i> Thêm mới
                </Link>
              </div>

              <div className="card-body">
                <div className="row">
                  <div className="form-group col-md-2">
                    <DelayInput
                      value={searching.filters.title || searching.filters.phone || searching.filters.id}
                      type="text"
                      className="form-control"
                      name="title"
                      delayTimeout={300}
                      placeholder="Nhập tên hoặc số điện thoại khách hàng "
                      onChange={this.onChangeHandle}
                    />
                  </div>
                  <div className="form-group col-md-2">
                    <Select2
                      style={{ width: '100%' }}
                      className="form-control select-search select2-hidden-accessible"
                      value={searching.filters.area || 'all'}
                      data={locations}
                      name="location"
                      onSelect={this.onChangeHandle}
                      options={{
                        placeholder: 'Tất cả khu vực'
                      }}

                    />
                  </div>
                  <div className="form-group col-md-2">
                    <Select2
                      style={{ width: '100%' }}
                      className="form-control select-search select2-hidden-accessible"
                      data={groups}
                      value={groupDefaultValue}
                      name="group"
                      onSelect={this.onChangeHandle}
                      options={{
                        placeholder: 'Tất cả các nguồn'
                      }}
                    />
                  </div>
                  <div className="form-group col-md-2">
                    <Select2
                      style={{ width: '100%' }}
                      className="form-control select-search select2-hidden-accessible"
                      data={managers}
                      value={searching.filters.parentIdentifyCode || 'all'}
                      name="manager"
                      onSelect={this.onChangeHandle}
                      options={{
                        placeholder: 'Đại lý thuộc quản lý nhân viên'
                      }}
                    />
                  </div>
                  <div className="form-group  col-md-2">
                    <DateRangePicker
                      className="btn btn-light daterange-predefined"
                      onApply={this.handleDatePicker}
                      ranges={ranges}
                    >
                      <button style={{ padding: '7px 15px' }}>
                        <i className="icon-calendar22 mr-2"></i>
                        <span> {dateRangeTitle} </span>
                      </button>
                    </DateRangePicker>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-bordered table-hover datatable-highlight">
                  <thead>
                    <tr>
                      <th>Mã Đại lý</th>
                      <th>Họ &amp; Tên</th>
                      <th>Lần cuối gửi hồ sơ</th>
                      <th>Tổng hồ sơ</th>
                      <th>Cấp Chiết khấu</th>
                      <th>Ngày tạo</th>
                      <th>Nhân viên tạo</th>
                      <th>Ủy thác cho</th>
                      <th>Hoạt động</th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderAgency()}
                  </tbody>
                </table>
                <PagingContainer
                  count={_.get(this.props, 'agencies', []).length}
                  pageNum={this.state.pageNum}
                  limit={PAGE_LIMIT}
                  onClick={this.onChangePage.bind(this)}
                />
              </div>

            </div>
          </div>
        </div>
      </div>

    );
  }

  componentDidMount() {
    try {
      /**
       * 
       */
      const locations = this.props.buildLocation();
      this.setState({ locations });

      this.props.getMembers({
        filters: {
          group: ['staff']
        },
        paging: {
          start: 0,
          limit: PAGE_MAX_LIMIT
        }
      });
    } catch (error) {
      // console.log(error);
      /**
       * 
       */
    }
  }

  componentDidUpdate(oldProps) {
    try {
      /**
       * 
       */
      const newProps = this.props;
      if (oldProps.accounts !== newProps.accounts) {
        const managers = this.props.buildListManager();
        this.setState({ managers });
      }
    } catch (error) {
      // console.log(error);
      /**
       * 
       */
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      localStoreFilter: payload.generalReducer.localStoreFilter,
      agencies: payload.agencyReducer.agencies,
      accounts: payload.accountReducer.accounts,
      init: payload.generalReducer.init,
      listScope: payload.generalReducer.listScope,
      userInfo: payload.generalReducer.userInfo,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(SearchAgency)
);
