import axios from 'axios';

const Request = {
  callAPI(method, url, args) {
    return new Promise(async (resolve, reject) => {
      try {
        let accessToken = localStorage.getItem('accessToken') || null;
        let lang = 'en';
        if (args && args.accessToken) {
          accessToken = `${args.accessToken}`;
          delete args.accessToken;
        }
        if (args && args.lang) {
          lang = `${args.lang}`;
          delete args.lang;
        }
        const response = await axios({
          method,
          url,
          data: args,
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: accessToken,
            Language: lang
          }
        });
        resolve(response.data);
      } catch (error) {
        if (error) {
          reject(error);
        }
      }
    });
  },
  callAccountAPI(method, url, args) {
    return new Promise(async (resolve, reject) => {
      try {
        let accessToken = '';
        let lang = 'en';
        if (args && args.accessToken) {
          accessToken = `${args.accessToken}`;
          delete args.accessToken;
        }
        if (args && args.lang) {
          lang = `${args.lang}`;
          // delete args.lang
        }
        const response = await axios({
          method,
          url,
          data: args,
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: accessToken,
            Language: lang
          }
        });
        resolve(response.data);
      } catch (error) {
        if (error) {
          // console.log(error);
          reject(error);
        }
      }
    });
  }
};

export default Request;
