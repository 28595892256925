import React from 'react';
import { Tooltip } from 'reactstrap';
import _, { sumBy } from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import Select2 from 'react-select2-wrapper';
import { Tabs, Tab } from 'react-bootstrap';
import cx from 'classnames';

import { DelayInput } from 'react-delay-input';
import Autocomplete from '@celebryts/react-autocomplete-tags';
import Autosuggest from 'react-autosuggest';
import Moment from 'react-moment';
import 'moment-timezone';
import Cleave from 'cleave.js/react';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import

import InputMask from 'react-input-mask';

// import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';

// import DropdownTreeSelect from 'react-dropdown-tree-select';
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { array } from 'prop-types';
import SelectStateContainer from '../../ultils/selectState.container';
import UploadFileContainer from '../../ultils/uploadFile.container';
import { TAG_STYLE, WALLET_SCOPE } from '../../../configs/constants';

import {
  formatDate, checkScope, fileUpload, subscribe, isStaff
} from '../../../helpers';

const diffMonthDuration = '9'; // 9 month

class CreateOrder extends React.Component {
  constructor(props) {
    super(props);
    this.input = {};
    this.state = {
      deposit: [0],
      documentCount: 0,
      typeOfSubmit: false,
      tooltipOpen: false,
      expireAtWarning: false,
      discountMessage: false,
      nationalDefaultValue: { value: 1, label: 'Việt nam' },
      stateDefaultValue: {},
      sexDefaultValue: {},
      typeProfileDefaultValue: { value: 'full', label: 'Đầy đủ' },
      supplierDefaultValue: { value: 0, label: 'Không' },
      value: '',
      typeProfileValue: 'full',
      predictions: [],
      profiles: [],
      showContextMenu: {
        display: false,
        style: {}
      },
      selectedFile: [],
      isDisable: true,
      dateDisplay: {
        birthday: 'DD/MM/YYYY',
        issuedAt: 'DD/MM/YYYY',
        expiredAt: 'DD/MM/YYYY',
        estimatedAt: 'DD/MM/YYYY',
        supplierSendAt: 'DD/MM/YYYY'
      },
      model: {
        customizeField: {},
        supplierId: 0,
        supplierAmount: 0,
        paidSupplierAmount: 0,
        vat: '',
        supplierSendAt: '',
        customerIdentifyCode: '',
        tag: [],
        serviceIdentifyCode: '',
        description: '',
        state: '',
        amount: 0,
        deposit: {
          amount: 0,
          description: ''
        },
        estimatedAt: '',
        profile: {
          firstName: '',
          lastName: '',
          fullName: '',
          sex: '',
          birthday: '',
          national: 'Việt nam',
          placeOfBirth: '',
          identifyCard: '',
          passport: {
            type: 'regular',
            number: '',
            placeOfIssue: 'Immigration Department',
            issuedAt: '',
            expiredAt: ''
          },
          address: '',
          phone: '',
          email: '',
          description: '',
          files: []
        },
        delegates: []
      },
      genders: [
        { value: 'male', label: 'Nam' },
        { value: 'female', label: 'Nữ' },
        { value: 'other', label: 'Khác' }
      ],
      typeProfiles: [
        { value: 'full', label: 'Đầy đủ' },
        { value: 'simple', label: 'Ngắn gọn' }
      ],
      suppliers: [],
      contries: [],
      states: [],
      parentIdentifies: [],
      discount: {},
      customizeField: []
    };

    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.onUploadFile = this.onUploadFile.bind(this);
    this.toggleTips = this.toggleTips.bind(this);

    this.addItemHandle = this.addItemHandle.bind(this);
    this.removeItemHandle = this.removeItemHandle.bind(this);
    this.setFiles = this.setFiles.bind(this);
    this.callbackWallet = this.callbackWallet.bind(this);
    this.onGroupChanged = this.onGroupChanged.bind(this);
    this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
  }

  callbackWallet() {
    try {
      const { wallet, socketBalance } = this.props;
      if (_.isEmpty(socketBalance) === false) {
        if (wallet.accountId === socketBalance.accountId && wallet !== socketBalance) {
          this.props.dispatch({
            type: 'POST_SEARCH_WALLET_DETAIL_SUCCESS',
            payload: socketBalance
          });
          this.toggleWallet();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async validateInputOnSubmit() {
    return new Promise((resolve, reject) => {
      const input = this.input;
      const { typeProfileValue } = this.state;
      let flag = true;
      _.forEach(input, (field) => {
        if (flag === false) return false;
        if (field.isActive === true) {
          if (field.required === true && (typeProfileValue !== 'simple' && field.title === 'Họ & Tên')) {
            if (_.get(field, 'e.value', '') === '' || _.get(field, 'e.value', '') === '__/__/____') {
              NotificationManager.error(`${field.title} không được để trống!`, '', 1500);
              field.e.focus();
              flag = false;
            }
          }
        }
        return true;
      });
      resolve(flag);
    });
  }

  onSubmit = async (e) => {
    try {
      e.preventDefault();
      // SHOW LOADING
      this.props.dispatch({ type: 'SHOW_LOADING' });
      const model = Object.assign({}, this.state.model);
      const { agency } = this.props;
      const validateInputOnSubmit = await this.validateInputOnSubmit();
      if (validateInputOnSubmit === false) return;
      const validateCustomizeField = await this.validateCustomizeFieldOnSubmit(model);
      const validate = this.validateOnSubmit(model);
      if (validateCustomizeField === true && validate === true) {
        let error = false;
        const fullname = _.get(model, 'profile.fullName', false);
        const fullnameArr = fullname.split(' ');
        if (fullnameArr.lenght <= 1) {
          NotificationManager.error('Vui lòng nhập đầy đủ họ tên', '', 1500);
          error = true;
        }
        //
        const files = await fileUpload(this.state.selectedFile, this.props);
        if (files.code === 1) {
          model.profile.files = files.data;
        }
        //
        model.customerIdentifyCode = agency.identifyCode;
        model.profile.lastName = fullnameArr[0];
        delete fullnameArr[0];
        model.profile.firstName = fullnameArr.join(' ');
        if (error === false) {
          this.props.onSubmit(model, this.state.typeOfSubmit);
        }
      }
      // HIDE LOADING
      // this.props.dispatch({ type: 'HIDE_LOADING' });
    } catch (error) {
      // HIDE LOADING
      this.props.dispatch({ type: 'HIDE_LOADING' });
    } finally {
      // this.props.dispatch({ type: 'HIDE_LOADING' });
    }
  }

  submit(key, e) {
    this.setState({ typeOfSubmit: key });
  }

  validateOnSubmit(model) {
    if (!model.serviceIdentifyCode) {
      NotificationManager.error('Loại dịch vụ không được để trống', '', 1500);
      return false;
    }

    const fullname = _.get(model, 'profile.fullName', false);
    if (!fullname) {
      NotificationManager.error('Họ tên không được để trống', '', 1500);
      return false;
    }

    const sex = _.get(model, 'profile.sex', false);
    if (!sex) {
      NotificationManager.error('Giới tính không được để trống', '', 1500);
      return false;
    }

    const email = _.get(model, 'profile.email', false);

    if (email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) === false) {
      NotificationManager.error('Email không hợp lệ', '', 1500);
      return false;
    }

    if (!model.state) {
      NotificationManager.error('Trạng thái không được để trống', '', 1500);
      return false;
    }

    if (!model.amount) {
      NotificationManager.error('Số tiền không được để trống', '', 1500);
      return false;
    }

    return true;
  }

  async validateCustomizeFieldOnSubmit(model) {
    return new Promise((resolve, reject) => {
      const customizeField = this.state.customizeField;
      let flag = true;
      customizeField.map((field) => {
        if (field.require === true) {
          if (_.isArray(model.customizeField[field.key])) {
            console.log(model.customizeField[field.key]);
            if (_.get(model.customizeField[field.key], '1', '') === '') {
              NotificationManager.error(`${field.title} không được để trống`, '', 1500);
              flag = false;
            }
          } else if (!model.customizeField[field.key] || _.isEmpty(model.customizeField[field.key])) {
            NotificationManager.error(`${field.title} không được để trống`, '', 1500);
            flag = false;
          }
        }
        return true;
      });
      resolve(flag);
    });
  }

  toggleWallet = () => {
    this.setState(prevstate => ({
      displayWallet: !prevstate.displayWallet
    }));

    setTimeout(() => {
      this.setState(prevstate => ({
        displayWallet: !prevstate.displayWallet
      }));
    }, 3000);
  };

  toggleTips() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  setFiles(selectedFile) {
    try {
      this.setState({ selectedFile });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Upload
   */

  onUploadFile(key, e) {
    try {
      const files = _.get(e, 'target.files', false);
      if (_.isEmpty(files) === false) {
        this.setState({ selectedFile: files });
        this.fileUpload(files, key);
      }
    } catch (error) {
      // console.log(error);
    }
  }

  fileUpload(files, type) {
    try {
      const { init, userInfo } = this.props;
      const data = new FormData();
      data.append('file', files[0]);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: userInfo.accessToken,
          accessToken: userInfo.accessToken
        }
      };

      axios.post(`${this.props.uploadPath}/upload-contract`, data, config).then((res) => {
        const code = _.get(res, 'data.code', false);
        if (code === 100) {
          const model = Object.assign({}, this.state.model);
          model.profile.files[0] = res.data.data.message.pathname;
          const selectedFileName = res.data.data.message.filename;
          this.setState({ model, selectedFileName });
        } else {
          const message = _.get(res, 'data.data.message.message', 'File không hợp lệ');
          NotificationManager.error(message, '', 3000);
        }
      });
    } catch (error) {
      // console.log(error);
    }
  }

  /**
   * 
   */
  setProfile(data) {
    if (data) {
      const { genders, typeProfiles } = this.state;
      const model = Object.assign({}, this.state.model);
      const dateDisplay = Object.assign({}, this.state.dateDisplay);
      model.profile.address = data.address;
      model.profile.birthday = data.birthday;
      model.profile.email = data.email;
      model.profile.firstName = data.firstName;
      model.profile.lastName = data.lastName;
      model.profile.fullName = data.fullName;
      model.profile.identifyCard = data.identifyCard;
      model.profile.national = data.national;
      model.profile.passport = data.passport;
      model.profile.phone = data.phone;
      model.profile.placeOfBirth = data.placeOfBirth;
      model.profile.sex = data.sex;

      const sexDefaultValue = _.find(genders, { value: data.sex });
      const nationalDefaultValue = _.find(this.props.buildCountry(), { value: data.national });

      dateDisplay.birthday = formatDate(data.birthday);
      dateDisplay.expiredAt = formatDate(data.passport.expiredAt);
      dateDisplay.issuedAt = formatDate(data.passport.issuedAt);

      const showContextMenu = { display: false };
      this.setState({
        showContextMenu, model, dateDisplay, sexDefaultValue, nationalDefaultValue
      });
    }
  }

  /**
   * 
   */
  onConvertDataSettingListbox(items) {
    if (_.isArray(items) === false) {
      return {
        label: items.title,
        value: items.value
      };
    }
    const result = [];
    items.map((v) => {
      result.push({
        label: v.title,
        value: v.value
      });
      return true;
    });
    return result;
  }

  onCheckCustomizeFieldHandle(key, type, value) {
    const model = Object.assign({}, this.state.model);
    if (type === 'CHECKBOX') {
      const currentValue = _.get(model, `customizeField.${key}`, []);
      if (_.includes(currentValue, value)) {
        return true;
      }
      return false;
    }
    if (type === 'RADIO') {
      const currentValue = _.get(model, `customizeField.${key}`, '');
      if (currentValue === value) {
        return true;
      }
    }
    if (type === 'LISTBOX') {
      const currentValue = _.get(model, `customizeField.${key}`, '');
      const control = _.find(this.state.customizeField, { key });
      if (_.isUndefined(control)) return false;
      const items = this.onConvertDataSettingListbox(control.items);
      const defaultValue = _.find(items, { value: currentValue });
      if (_.isUndefined(defaultValue)) return null;
      return defaultValue;
    }
    return false;
  }

  onChangeCustomizeFieldHandle(key, type, e) {
    const model = Object.assign({}, this.state.model);
    const customizeField = this.state.customizeField;
    if (type === 'RADIO') {
      const code = e.target.value;
      model.customizeField[key] = code;
    }
    if (type === 'CHECKBOX') {
      const code = e.target.value;
      let currentValue = _.get(model, `customizeField.${key}`, []);
      if (e.target.checked === true) {
        currentValue.push(code);
        currentValue = _.uniq(currentValue);
      } else {
        currentValue = _.remove(currentValue, v => (v !== code));
      }
      model.customizeField[key] = currentValue;
    }
    if (type === 'INPUT_TEXT' || type === 'INPUT_DATE') {
      const code = e.target.value;
      model.customizeField[key] = code;
    }
    if (type === 'LISTBOX') {
      const value = _.get(e, 'target.value', _.get(e, 'value', false));
      model.customizeField[key] = value;
    }
    const result = {};
    result.model = model;
    this.setState(result);
  }
  
  onChangeHandle(key, e) {
    try {
      const { buildServices, wallet, agency } = this.props;
      const {
        isDisable, genders, typeProfiles, parentIdentifies, delegates, suppliers
      } = this.state;

      const model = Object.assign({}, this.state.model);
      const result = {};

      if (_.isArray(e) === true) {
        switch (key) {
          case 'delegates': {
            const temps = [];
            e.forEach((element) => {
              const delegateInfo = _.find(delegates, { value: element.value });
              temps.push({
                identifyCode: delegateInfo.value,
                description: ''
              });
            });
            model[key] = temps;
            break;
          }

          default: {
            const temp = [];
            e.forEach((element) => {
              temp.push(_.get(element, 'target.value', _.get(element, 'value', false)));
            });
            model[key] = temp;
            break;
          }
        }
      }
      if (_.isArray(e) === false) {
        switch (key) {
          case 'supplierId': {
            const value = _.get(e, 'target.value', _.get(e, 'value', false));
            model.supplierId = value;
            if (model.supplierId === 0) {
              model.supplierAmount = 0;
              model.paidSupplierAmount = 0;
              model.supplierSendAt = null;
              this.state.dateDisplay.supplierSendAt = 'DD/MM/YYYY';
            }
            const supplierDefaultValue = _.find(suppliers, { value });
            result.supplierDefaultValue = supplierDefaultValue;
            break;
          }
          case 'supplierAmount': {
            const amount = _.get(e, 'target.rawValue', _.get(e, 'value', 0));
            model[key] = Number(amount);
            break;
          }
          case 'paidSupplierAmount': {
            const amount = _.get(e, 'target.rawValue', _.get(e, 'value', 0));
            model[key] = Number(amount);
            break;
          }
          case 'state': {
            const warning = _.get(e, 'state.warning', false);
            if (warning) {
              confirmAlert({
                title: 'Xác nhật cập nhật trạng thái',
                message: warning,
                buttons: [{ label: 'Đóng' }]
              });
            }
            model[key] = _.get(e, 'target.value', _.get(e, 'value', false));
            result.stateDefaultValue = e;
            break;
          }
          case 'tag': {
            const value = _.get(e, 'target.value', _.get(e, 'value', false));
            model[key] = this.tags.state.tags;
            if (value) { model[key].push(value); }
            model[key] = _.uniq(model[key]);
            break;
          }
          case 'amount': {
            const amount = _.get(e, 'target.rawValue', _.get(e, 'value', 0));
            model[key] = Number(amount);
            break;
          }
          case 'deposit': {
            const deposit = Number(_.get(e, 'target.rawValue', _.get(e, 'value', 0)));
            model.deposit.amount = deposit;

            if (deposit > model.amount) {
              model.deposit.amount = model.amount;
              NotificationManager.error('Số tiền cọc không được lớn hơn tổng tiền đơn hàng', '', 1500);
            }

            if (deposit > wallet.amount) {
              model.deposit.amount = wallet.amount;
              NotificationManager.error('Số tiền cọc không được lớn hơn số dư khả dụng', '', 1500);
            }

            break;
          }
          case 'depositDescription': {
            const deposit = _.get(e, 'target.value', _.get(e, 'value', 0));
            model.deposit.description = (deposit);
            break;
          }

          case 'sex': {
            const value = _.get(e, 'target.value', _.get(e, 'value', false));
            const sexDefaultValue = _.find(genders, { value });
            model.profile[key] = value;
            result.sexDefaultValue = sexDefaultValue;
            break;
          }

          case 'typeProfile': {
            let value = _.get(e, 'target.checked', _.get(e, 'checked', false));
            if (value === true) value = 'simple';
            if (value === false) value = 'full';
            const typeProfileDefaultValue = _.find(typeProfiles, { value });
            result.typeProfileDefaultValue = typeProfileDefaultValue;
            result.typeProfileValue = value;
            if (value === 'simple') {
              const profile = {
                sex: 'male',
                birthday: '08/12/1988',
                national: 'Việt nam',
                placeOfBirth: 'VN',
                identifyCard: '0000000000',
                passport: {
                  type: 'regular',
                  number: 'B000000',
                  placeOfIssue: 'Immigration Department',
                  issuedAt: new Date(),
                  expiredAt: new Date()
                },
                address: 'Á châu',
                phone: '0909000000',
                email: 'achau@gmail.com',
                description: 'null',
                files: []
              };
              model.profile = profile;
            } else {
              const profile = {
                firstName: '',
                lastName: '',
                fullName: '',
                sex: '',
                birthday: '',
                national: 'Việt nam',
                placeOfBirth: '',
                identifyCard: '',
                passport: {
                  type: 'regular',
                  number: '',
                  placeOfIssue: 'Immigration Department',
                  issuedAt: '',
                  expiredAt: ''
                },
                address: '',
                phone: '',
                email: '',
                description: '',
                files: []
              };
              model.profile = profile;
            }

            break;
          }
          
          case 'serviceIdentifyCode': {
            const discountLevel = _.get(agency, 'discountLevel', '');
            const value = _.get(e, 'target.value', _.get(e, 'value', 0));
            const service = this.props.getServiceDetail(value);
            const documentCount = _.get(service, 'document', []).length;

            const states = [];
            const discount = {
              isDiscount: false,
              info: {},
              amount: 0
            };
            result.discountMessage = false;
            result.documentCount = documentCount;

            parentIdentifies.forEach((items) => {
              const item = _.find(items.children, { id: value });
              if (_.isEmpty(item) === false) {
                const discountInfo = _.find(item.discount, { level: discountLevel });
                if (_.isEmpty(discountInfo) === false) {
                  discount.isDiscount = true;
                  discount.info = discountInfo;
                }
                item.states.forEach((element) => {
                  if (element.isOnCreate === true) {
                    states.push({
                      label: element.title,
                      value: element.state,
                      color: element.color
                    });
                  }
                });
                let customizeField = _.get(item, 'settings.customizeField', []);
                if (!_.isEmpty(customizeField)) {
                  customizeField = JSON.parse(customizeField);
                  customizeField.map((v) => {
                    if (!model.customizeField[v.key] || _.isEmpty(model.customizeField[v.key])) {
                      model.customizeField[v.key] = v.defaultValue;
                    }
                    return true;
                  });
                }
                result.customizeField = customizeField;
                result.discountMessage = _.get(item, 'settings.note', false);
                if (this.state.model.serviceIdentifyCode !== value && _.isEmpty(states) === false) {
                  model.amount = _.get(item, 'amount', 0);
                  model.state = states[0].value;
                  result.stateDefaultValue = states[0];

                  let discountAmount = discount.info.value || 0;
                  if (discount.isDiscount === true && discount.info.type === 'percent') {
                    discountAmount = model.amount * discount.info.value / 100;
                  }
                  if (discountAmount > model.amount) {
                    discountAmount = model.amount;
                  }
                  if (discountAmount < 0) {
                    discountAmount = 0;
                  }
                  model.amount -= discountAmount;
                }
              }
            });
            
            result.states = states;
            result.discount = discount;
            result.isDisable = false;
            model[key] = value;
            break;
          }
          case 'national': {
            const value = _.get(e, 'target.value', _.get(e, 'value', false));
            result.nationalDefaultValue = _.find(this.props.buildCountry(), { value });
            model.profile[key] = value;
            break;
          }
          case 'fullName': {
            const fullName = _.get(e, 'target.value', _.get(e, 'value', false));
            model.profile[key] = fullName.toUpperCase();
            break;
          }
          case 'placeOfBirth':
          case 'identifyCard':
          case 'address':
          case 'phone':
          case 'email': {
            model.profile[key] = _.get(e, 'target.value', _.get(e, 'value', false));
            break;
          }
          case 'profileDescription': {
            model.profile.description = _.get(e, 'target.value', _.get(e, 'value', false));
            break;
          }
          case 'description': {
            model[key] = _.get(e, 'target.value', _.get(e, 'value', false));
            break;
          }
          case 'type':
          case 'placeOfIssue': {
            model.profile.passport.placeOfIssue = _.get(e, 'target.value', _.get(e, 'value', false));
            break;
          }

          case 'birthday':
          case 'estimatedAt':
          case 'supplierSendAt':
          case 'expiredAt':
          case 'issuedAt': {
            const value = _.get(e, 'target.value', _.get(e, 'value', false));
            const dateFormat = 'YYYY/MM/DD';
            const splittedDate = value.split('/');

            if (splittedDate.length === 3 && splittedDate[2].length === 4) {
              const date = moment(`${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`, dateFormat);
              const dateDisplay = Object.assign({}, this.state.dateDisplay);
              dateDisplay[key] = value;
              result.dateDisplay = dateDisplay;
              if (date.isValid() === true) {
                if (key === 'estimatedAt') {
                  model.estimatedAt = date.format();
                }
                if (key === 'supplierSendAt') {
                  model.supplierSendAt = date.format();
                }
                if (key === 'birthday') {
                  model.profile.birthday = date.format();
                }
                if (key === 'issuedAt' || key === 'expiredAt') {
                  model.profile.passport[key] = date.format();
                }
                /**
                 * Kiểm tra ngày hết hạn
                 */
                const yearNumber = Number(splittedDate[2]);
                if (key === 'expiredAt' && _.isNaN(yearNumber) === false) {
                  const previousMonth = date.subtract(diffMonthDuration, 'months');
                  const monthDuration = moment().diff(previousMonth, 'months');
                  result.expireAtWarning = false;
                  if (monthDuration >= diffMonthDuration) {
                    result.expireAtWarning = 'Hộ chiếu đã hết hạn';
                  } else if (monthDuration > 0) {
                    result.expireAtWarning = 'Hộ chiếu gần hết hạn';
                  }
                }
              }
            }

            break;
          }

          case 'passportNumber': {
            const value = _.get(e, 'target.value', _.get(e, 'value', false));
            this.props.getProfile(value);
            model.profile.passport.number = value;
            break;
          }
          default:
            model[key] = _.get(e, 'target.value', _.get(e, 'value', false));
            break;
        }
      }
      result.model = model;

      this.setState(result);
    } catch (error) {
      console.log(error);
    }
  }

  setStag(tag) {
    if (tag) {
      const model = Object.assign({}, this.state.model);
      model.tag = this.tags.state.tags;
      const isExist = model.tag.indexOf(tag);
      if (tag && isExist === -1) { model.tag.push(tag); }
      this.setState({ model });
    }
  }

  renderTagItem() {
    const { orderTags } = this.props;
    if (_.isEmpty(orderTags) === false) {
      return (orderTags.map((item, i) => {
        return (
          <span
            onClick={this.setStag.bind(this, item)}
            key={`orderTags_${i}`}
            style={TAG_STYLE[i]}
            className="badge badge-pill">{item}</span>);
      }));
    }
    return (<span></span>);
  }

  onChangeDepositHandle(i, e) {
    const deposit = Object.assign([], this.state.deposit);
    const value = _.get(e, 'target.rawValue', _.get(e, 'value', false));
    deposit[i] = value;
    this.setState({ deposit });
  }

  addItemHandle() {
    const deposit = Object.assign([], this.state.deposit);
    deposit.push(0);
    this.setState({ deposit });
  }

  removeItemHandle(index) {
    if (_.isNumber(index) === true) {
      const deposit = Object.assign([], this.state.deposit);
      delete deposit[index];
      this.setState({ deposit });
    }
  }

  depositRender() {
    const { deposit } = this.state;
    const btnStyle = {
      height: 28,
      lineHeight: 0,
      width: 28,
      paddingLeft: 9,
      fontWeight: 'bold'
    };
    if (_.isEmpty(deposit) === false) {
      return (deposit.map((item, i) => {
        return (
          <div key={`depositRender_${i}`} className="col-md-12" style={{ marginBottom: 10 }}>
            <div className="input-group">
              <Cleave
                onChange={this.onChangeDepositHandle.bind(this, i)}
                className="form-control input-numeral"
                placeholder="Số tiền"
                value={item}
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: 'thousand',
                  numeralDecimalScale: 0
                }}
              />
              <div className="input-group-append">
                <span
                  onClick={this.removeItemHandle.bind(this, i)}
                  style={{ color: 'gray', cursor: 'pointer' }}
                  className="input-group-text"> <i className="fa fa-minus"></i> </span>
              </div>
            </div>
          </div>

        );
      }));
    }
    return (false);
  }

  renderDocument() {
    const { model } = this.state;
    if (_.isEmpty(model.serviceIdentifyCode) === true) {
      return (<div></div>);
    }
    const service = this.props.getServiceDetail(model.serviceIdentifyCode);
    if (_.isEmpty(service.document) === true) {
      return (<div></div>);
    }
    return (service.document.map((item, i) => {
      return (<div style={{ float: 'left' }} key={`renderDocument_${i}`} className="col-md-6">
        <div style={{ padding: 10 }} className="media-title font-weight-semibold">
          <a target="_blank" href={item.value}>
            <i className="fa fa-link"></i> {item.key}
          </a>
        </div>
      </div>);
    }));
  }

  onGroupChanged(e) {
    const model = Object.assign({}, this.state.model);
    model.group = e.currentTarget.value;
    this.setState({ model });
  }

  onChangeCheckBox(e) {
    const model = Object.assign({}, this.state.model);
    const code = e.target.value;
    console.log(code);
    model.checkTest = e.target.checked;
    this.setState({ model });
  }

  render() {
    const {
      model,
      dateDisplay,
      isDisable,
      genders, typeProfiles,
      stateDefaultValue,
      parentIdentifies,
      showContextMenu, typeProfileValue,
      sexDefaultValue,
      nationalDefaultValue,
      delegates,
      states,
      suppliers,
      supplierDefaultValue, typeProfileDefaultValue,
      customizeField
    } = this.state;
    const { agency } = this.props;
    const hasWalletPermission = checkScope(WALLET_SCOPE, this.props);
    return (
      <div className={
        this.state.displayForm === true
          ? cx('toggler', { 'toggler--form': this.state.displayForm }) 
          : cx('toggler', { 'toggler--wallet': this.state.displayWallet })
      }>

        <Tooltip
          placement="right"
          isOpen={this.state.tooltipOpen}
          target="TooltipExample"
          toggle={this.toggleTips}>

          <table border={0} style={{ width: 180 }}>
            <tbody>
              <tr>
                <td style={{ width: 65, textAlign: 'left' }}>Khả dụng <span style={{ float: 'right' }}>:</span></td>
                <td style={{ textAlign: 'right' }}>{_.get(this.props.wallet, 'amount', 0).toLocaleString()}</td>
              </tr>
              <tr>
                <td style={{ width: 65, textAlign: 'left' }}>Tiền mặt <span style={{ float: 'right' }}>:</span></td>
                <td style={{ textAlign: 'right' }}>{_.get(this.props.wallet, 'detail.cash', 0).toLocaleString()}</td>
              </tr>

              <tr>
                <td style={{ width: 65, textAlign: 'left' }}>Dư nợ <span style={{ float: 'right' }}>:</span></td>
                <td style={{ textAlign: 'right' }}>{_.get(this.props.wallet, 'detail.credit', 0).toLocaleString()}</td>
              </tr>

              <tr>
                <td style={{ width: 65, textAlign: 'left' }}>Hạn mức tín dụng<span style={{ float: 'right' }}>:</span></td>
                <td style={{ textAlign: 'right' }}> {_.get(this.props.wallet, 'detail.creditLimit', 0).toLocaleString()}</td>
              </tr>
            </tbody>
          </table>
        </Tooltip>
        <div className="row">
          <div className="col-xl-12">
            <div className="card ">
              <div className="card-header header-elements-inline">
                <h5 className="card-title">Thông tin Khách hàng</h5>
                <div className="header-elements">

                </div>
              </div>
              <div className="card-body ">
                <form onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <div className="row">

                      <div className="col-md-4">
                        <label>Họ tên</label>
                        <input
                          value={_.get(agency, 'title', '')}
                          type="text"
                          className="form-control"
                          disabled
                        />
                      </div>
                      <div className="col-md-4">
                        <label>Số điện thoại</label>
                        <input
                          value={_.get(agency, 'phone', '')}
                          type="text"
                          className="form-control"
                          disabled
                        />
                      </div>
                      <div className="col-md-4">
                        <label>Email</label>
                        <input
                          value={_.get(agency, 'email', '')}
                          type="text"
                          className="form-control"
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-12">
                        <label>Nhãn đơn hàng</label>
                        <Autocomplete
                          delay={500}
                          tags={model.tag}
                          ref={(e) => { this.tags = e; }}
                          // onDelete={this.onChangeHandle.bind(this, 'tag')}
                          onAdd={this.onChangeHandle.bind(this, 'tag')}
                        />
                        <div style={{ marginTop: 10, paddingLeft: 10 }}>
                          {this.renderTagItem()}

                        </div>

                      </div>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>

        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card ">
              <div className="card-header header-elements-inline">
                <h5 className="card-title">Tiến trình đơn hàng</h5>
              </div>
              <div className="card-body ">
                <Tabs defaultActiveKey="tab1">
                  <Tab eventKey="tab1" title="Tiến trình">
                    <form onSubmit={this.onSubmit}>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-4">
                            <label>
                          Loại dịch vụ <span className="text-danger">*</span>
                            </label>

                            <Select2
                              value={model.serviceIdentifyCode}
                              style={{ width: '100%' }}
                              name="area"
                              onChange={this.onChangeHandle.bind(this, 'serviceIdentifyCode')}
                              className="form-control select-search select2-hidden-accessible"
                              data={parentIdentifies}
                              options={{
                                placeholder: 'Chọn loại dịch vụ'
                              }}
                            />

                          </div>

                          <div className="col-md-4">
                            <label>Trạng thái <span className="text-danger">*</span></label>
                            <SelectStateContainer
                              isDisabled={isDisable}
                              value={stateDefaultValue}
                              onChange={this.onChangeHandle.bind(this, 'state')}
                              options={states}
                            />
                          </div>
                          <div className="col-md-4">
                            <label>Ngày dự kiến</label>
                            <div className="form-group">
                              <div className="input-group">
                                <span className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="icon-calendar"></i>
                                  </span>
                                </span>
                                <div className="multiselect-native-select">
                                  <InputMask
                                    value={dateDisplay.estimatedAt}
                                    className="form-control"
                                    mask="99/99/9999"
                                    onChange={this.onChangeHandle.bind(this, 'estimatedAt')}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-4">
                            <label>Tổng tiền đơn hàng
                              <span className="text-danger"> * </span>

                            </label>

                            <label style={{ float: 'right', fontStyle: 'italic' }}>
                              <i
                                style={{ marginLeft: 10 }}
                                href="#"
                                id="TooltipExample"
                                className="fa fa-info-circle"></i>
                            </label>

                            <span className="float-right text-info">
                              {this.state.discountMessage || ''}
                            </span>

                            <div className="form-group">
                              <div className="input-group">
                                <span className="input-group-prepend">
                                  <span className="input-group-text">
                                VND
                                  </span>
                                </span>
                                <div className="multiselect-native-select">
                                  <Cleave
                                    value={model.amount}
                                    onChange={this.onChangeHandle.bind(this, 'amount')}
                                    className="form-control input-numeral"
                                    placeholder="Số tiền"
                                    options={{
                                      numeral: true,
                                      numeralThousandsGroupStyle: 'thousand',
                                      numeralDecimalScale: 0
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <label>Đã thanh toán </label>
                            <input
                              value={0}
                              type="text"
                              className="form-control"
                              disabled
                            />
                          </div>

                          <div className="col-md-4">
                            <label>Còn lại </label>
                            <input
                              value={0}
                              type="text"
                              className="form-control"
                              disabled
                            />
                          </div>

                        </div>
                      </div>

                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">
                            <label>Thanh toán</label>
                            <label className="changeColor" style={{ float: 'right', fontStyle: 'italic', padding: '0px 20px' }}>
                              Số dư khả dụng:
                              <span style={{ fontWeight: 'bold', paddingLeft: 5 }} className="text-danger">
                                {_.get(this.props.wallet, 'amount', 0).toLocaleString()} đ
                              </span>
                              {hasWalletPermission 
                                ? <a 
                                  style={{ paddingLeft: 5 }}
                                  target="_blank" 
                                  href={`/account-wallet/${agency.id}`}>
                                  <i className="fa fa-plus-circle"></i>
                                </a>
                                : ''}

                            </label>
                            <div className="form-group">
                              <div className="input-group">
                                <span className="input-group-prepend">
                                  <span className="input-group-text">VND</span>
                                </span>
                                <div className="multiselect-native-select">
                                  <Cleave
                                    value={model.deposit.amount}
                                    onChange={this.onChangeHandle.bind(this, 'deposit')}
                                    className="form-control input-numeral"
                                    placeholder="Số tiền"
                                    options={{
                                      numeral: true,
                                      numeralThousandsGroupStyle: 'thousand',
                                      numeralDecimalScale: 0
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label>Nội dung thanh toán</label>
                            <div className="input-group">
                              <input
                                type="text"
                                onChange={this.onChangeHandle.bind(this, 'depositDescription')}
                                className="form-control"
                                placeholder="Nhập ghi chú thanh toán"
                              />
                            </div>
                          </div>
                        </div>
                        
                      </div>

                      {isStaff(this.props) === true
                        ? <div className="form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label>VAT</label>
                              <div className="form-group">
                                <div className="input-group">
                                  <div className="multiselect-native-select">
                                    <input
                                      value={model.vat}
                                      onChange={this.onChangeHandle.bind(this, 'vat')}
                                      type="text"
                                      className="form-control"
                                      placeholder="VD: 10%"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        : '' }

                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-12">

                          </div>
                        </div>
                      </div>
                    </form>
                  </Tab>
                  <Tab eventKey="tab2" title={`Tài liệu tham khảo (${this.state.documentCount})`}>
                    {this.renderDocument()}
                  </Tab>
                  {(suppliers.length > 1)
                    ? <Tab eventKey="tab3" title="Đối tác xử lý">
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">
                            <label>Đối tác xử lý</label>
                            <div className="form-group">
                              <Select
                                value={supplierDefaultValue}
                                onChange={this.onChangeHandle.bind(this, 'supplierId')}
                                options={suppliers}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label>Giá đối tác thu</label>
                            <div className="form-group">
                              <div className="input-group">
                                <span className="input-group-prepend">
                                  <span className="input-group-text">VND</span>
                                </span>
                                <div className="multiselect-native-select">
                                  <Cleave
                                    value={model.supplierAmount}
                                    disabled={supplierDefaultValue.value === 0 ? 'disabled' : ''}
                                    onChange={this.onChangeHandle.bind(this, 'supplierAmount')}
                                    className="form-control input-numeral"
                                    placeholder="Số tiền"
                                    options={{
                                      numeral: true,
                                      numeralThousandsGroupStyle: 'thousand',
                                      numeralDecimalScale: 0
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">
                            <label>Ngày gửi đối tác</label>
                            <div className="form-group">
                              <div className="input-group">
                                <span className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="icon-calendar"></i>
                                  </span>
                                </span>
                                <div className="multiselect-native-select">
                                  <InputMask
                                    disabled={supplierDefaultValue.value === 0 ? 'disabled' : ''}
                                    value={dateDisplay.supplierSendAt}
                                    className="form-control"
                                    mask="99/99/9999"
                                    onChange={this.onChangeHandle.bind(this, 'supplierSendAt')}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </Tab>
                    : ''}
                </Tabs>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card ">
              <div className="card-header header-elements-inline">
                <h5 className="card-title">Thông tin hồ sơ </h5><div style={{ float: 'right' }}><input onChange={this.onChangeHandle.bind(this, 'typeProfile')} type='checkbox' /> Chế độ nhập đơn giản</div>
              </div>
              <div className="card-body ">
                <form onSubmit={this.onSubmit} >
                  {typeProfileValue === 'simple'
                    ? <div> <div className="form-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Họ & tên <span className="text-danger">*</span></label>
                          <div className="form-group">
                            <input
                              ref={(input) => {
                                this.input.fullName = {
                                  title: 'Họ & tên', isActive: true, required: true, e: input
                                }; 
                              }} 
                              value={model.profile.fullName}
                              onChange={this.onChangeHandle.bind(this, 'fullName')}
                              type="text"
                              className="form-control"
                              placeholder="Họ khách hàng"
                            />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label>Email</label>
                            <input
                              value={model.profile.email}
                              onChange={this.onChangeHandle.bind(this, 'email')}
                              type="text"
                              className="form-control"
                              placeholder="Nhập email"
                            />
                          </div>
                        </div>

                        <div className="col-sm-4">
                          <div className="form-group">
                            <label>Số điện thoại</label>
                            <input
                              value={model.profile.phone}
                              onChange={this.onChangeHandle.bind(this, 'phone')}
                              type="text"
                              className="form-control"
                              placeholder="Nhập số điện thoại"
                            />
                          </div>
                        </div>
                      </div> 
                      
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label>Địa chỉ</label>
                            <input
                              value={model.profile.address}
                              onChange={this.onChangeHandle.bind(this, 'address')}
                              type="text"
                              className="form-control"
                              placeholder="Nhập nơi sinh"
                            />
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className="form-group">
                            <label>Tải file đi kèm</label>
                            <UploadFileContainer
                              setFiles={this.setFiles.bind(this)}
                            />
                            <span className="form-text text-muted">Accepted formats: doc, docx, pdf, bmp, png, gif, jpg, jpeg</span>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-12">
                          <label>Ghi chú hồ sơ</label>
                          <textarea
                            onChange={this.onChangeHandle.bind(this, 'profileDescription')}
                            name="additional-info"
                            rows="4"
                            cols="4"
                            placeholder=""
                            className="form-control"
                          ></textarea>
                        </div>

                      </div>
                    </div></div> : ''}

                  {typeProfileValue === 'full' ? <span>
                    <div className="form-group">
                      <div className="row">

                        <div className="col-md-4">

                          <label>Số hộ chiếu <span className="text-danger">*</span></label>
                          <div className="form-group">
                            <DelayInput

                              value={model.profile.passport.number}
                              type="text"
                              className="form-control"
                              minLength={3}
                              delayTimeout={500}
                              placeholder="Nhập số hộ chiếu"
                              onChange={this.onChangeHandle.bind(this, 'passportNumber')}
                            />
                            <div className={showContextMenu.display ? 'dropdown-menu show' : 'dropdown-menu'} style={{ left: 10, width: 'calc(100% - 20px)' }} >
                              {
                                this.state.profiles.map((item, index) => (
                                  <a
                                    onClick={this.setProfile.bind(this, item)}
                                    key={`profiles${index}`}
                                    className="dropdown-item">
                                    <div
                                      className="media-title font-weight-semibold">
                                      {item.fullName}
                                      <span
                                        style={{ paddingLeft: 10 }}
                                        className="text-muted">
                                      (<Moment format="DD/MM/YYYY">{item.birthday}</Moment>)
                                      </span>
                                    </div>

                                  </a>
                                ))
                              }
                            </div>

                          </div>
                        </div>

                        <div className="col-md-4">
                          <label>Họ & tên <span className="text-danger">*</span></label>
                          <div className="form-group">
                            <input
                              ref={(input) => {
                                this.input.fullName = {
                                  title: 'Họ & tên', isActive: true, required: true, e: input
                                }; 
                              }} 
                              value={model.profile.fullName}
                              onChange={this.onChangeHandle.bind(this, 'fullName')}
                              type="text"
                              className="form-control"
                              placeholder="Họ khách hàng"
                            />
                          </div>
                        </div>

                        <div className="col-sm-4">
                          <label>Quốc tịch <span className="text-danger">*</span></label>
                          <div className="form-group">
                            <Select
                              value={nationalDefaultValue}
                              onChange={this.onChangeHandle.bind(this, 'national')}
                              options={this.props.buildCountry()}
                            />
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">

                        <div className="col-sm-4">
                          <label>Nơi cấp  <span className="text-danger">*</span></label>
                          <div className="form-group">
                            <input
                              value={model.profile.passport.placeOfIssue}
                              onChange={this.onChangeHandle.bind(this, 'placeOfIssue')}
                              type="text"
                              className="form-control"
                              placeholder="Nhập nơi cấp"
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <label>Ngày cấp <span className="text-danger">*</span></label>
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="icon-calendar"></i>
                                </span>
                              </span>
                              <div className="multiselect-native-select">
                                <InputMask
                                  value={dateDisplay.issuedAt}
                                  className="form-control"
                                  mask="99/99/9999"
                                  onChange={this.onChangeHandle.bind(this, 'issuedAt')}
                                />

                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <label className="float-left"> Ngày hết hạn <span className="text-danger">*</span></label>
                          <span className="float-right text-warning">{this.state.expireAtWarning || ''}</span>
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="icon-calendar"></i>
                                </span>
                              </span>
                              <div className="multiselect-native-select">
                                <InputMask
                                  ref={(input) => {
                                    this.input.expiredAt = {
                                      title: 'Ngày hết hạn', isActive: true, required: true, e: input
                                    }; 
                                  }} 
                                  value={dateDisplay.expiredAt}
                                  className={this.state.expireAtWarning ? 'form-control border-warning' : 'form-control'}
                                  mask="99/99/9999"
                                  onChange={this.onChangeHandle.bind(this, 'expiredAt')}
                                />

                              </div>

                            </div>
                          </div>

                        </div>

                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label>Ngày sinh <span className="text-danger">*</span></label><br />
                            <div className="form-group">
                              <div className="input-group">
                                <span className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="icon-calendar"></i>
                                  </span>
                                </span>
                                <div className="multiselect-native-select">
                                  <InputMask
                                    value={dateDisplay.birthday}
                                    className="form-control"
                                    mask="99/99/9999"
                                    onChange={this.onChangeHandle.bind(this, 'birthday')}
                                  />

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label>Nơi sinh</label>
                            <input
                              value={model.profile.placeOfBirth}
                              onChange={this.onChangeHandle.bind(this, 'placeOfBirth')}
                              type="text"
                              className="form-control"
                              placeholder="Nhập nơi sinh"
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <label>Giới tính <span className="text-danger">*</span> </label>
                          <Select
                            value={sexDefaultValue}
                            onChange={this.onChangeHandle.bind(this, 'sex')}
                            options={genders}
                          />
                        </div>

                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-sm-4">
                          <label>Số căn cước </label>
                          <div className="form-group">
                            <input
                              value={model.profile.identifyCard}
                              onChange={this.onChangeHandle.bind(this, 'identifyCard')}
                              type="text"
                              className="form-control"
                              placeholder="Nhập số căn cước"
                            />
                          </div>
                        </div>

                        <div className="col-sm-4">
                          <div className="form-group">
                            <label>Email</label>
                            <input
                              value={model.profile.email}
                              onChange={this.onChangeHandle.bind(this, 'email')}
                              type="text"
                              className="form-control"
                              placeholder="Nhập email"
                            />
                          </div>
                        </div>

                        <div className="col-sm-4">
                          <div className="form-group">
                            <label>Số điện thoại</label>
                            <input
                              value={model.profile.phone}
                              onChange={this.onChangeHandle.bind(this, 'phone')}
                              type="text"
                              className="form-control"
                              placeholder="Nhập số điện thoại"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label>Địa chỉ</label>
                            <input
                              value={model.profile.address}
                              onChange={this.onChangeHandle.bind(this, 'address')}
                              type="text"
                              className="form-control"
                              placeholder="Nhập nơi sinh"
                            />
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className="form-group">
                            <label>Tải file đi kèm</label>
                            <UploadFileContainer
                              setFiles={this.setFiles.bind(this)}
                            />
                            <span className="form-text text-muted">Accepted formats: doc, docx, pdf, bmp, png, gif, jpg, jpeg</span>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-12">
                          <label>Ghi chú hồ sơ</label>
                          <textarea
                            onChange={this.onChangeHandle.bind(this, 'profileDescription')}
                            name="additional-info"
                            rows="4"
                            cols="4"
                            placeholder=""
                            className="form-control"
                          ></textarea>
                        </div>

                      </div>
                    </div>
                  </span> : '' }
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card ">
              <div className="card-header header-elements-inline">
                <h5 className="card-title">Thông tin bổ sung</h5>
                <div className="header-elements"></div>
              </div>
              <div className="card-body ">
                <em>{(_.isEmpty(customizeField) ? 'Không có thông tin cần bổ sung thêm' : '')}</em>
                {customizeField.map((v, k) => {
                  switch (v.type) {
                    case 'INPUT_TEXT':
                      return (
                        <div key={`customize_${v.key}`} className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-12">
                                  <label>{v.title} <span className="text-danger">{(v.require === true) ? '*' : ''}</span></label>
                                  <div className="form-group">
                                    <div className="input-group">
                                      <input
                                        ref={(input) => {
                                          this.input[v.key] = {
                                            name: v.key, title: v.title, isActive: true, required: v.require, e: input
                                          }; 
                                        }} 
                                        value={model.customizeField[v.key]}
                                        type="text"
                                        onChange={this.onChangeCustomizeFieldHandle.bind(this, v.key, v.type)}
                                        className="form-control"
                                        placeholder={v.placeHolder ? v.placeHolder : ''}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>);
                        
                    case 'INPUT_DATE':
                      return (
                        <div key={`customize_${v.key}`} className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-12">
                                  <label>{v.title} <span className="text-danger">{(v.require === true) ? '*' : ''}</span></label>
                                  <div className="form-group">
                                    <div className="input-group">
                                      <span className="input-group-prepend">
                                        <span className="input-group-text">
                                          <i className="icon-calendar"></i>
                                        </span>
                                      </span>
                                      <div className="multiselect-native-select">
                                        <InputMask
                                          ref={(input) => {
                                            this.input[v.key] = {
                                              name: v.key, title: v.title, isActive: true, required: v.require, e: input
                                            }; 
                                          }} 
                                          value={model.customizeField[v.key] || 'DD/MM/YYYY'}
                                          className="form-control"
                                          mask="99/99/9999"
                                          onChange={this.onChangeCustomizeFieldHandle.bind(this, v.key, v.type)}
                                        />

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );

                    case 'CHECKBOX':
                      return (
                        <div key={`customize_${v.key}`} className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-12">
                                  <label>{v.title} <span className="text-danger">{(v.require === true) ? '*' : ''}</span></label>
                                  <div className="form-group">
                                    {v.items.map((sv, sk) => {
                                      return (<div key={`customize_subcheck_${v.key}_${sk}`} className="form-check form-check-inline">
                                        <label className="form-check-label" title={v.title}>
                                          <div className="uniform-checker">
                                            {/* <span className={model.checkTest === true ? 'checked' : ''}> */}
                                            <span className={this.onCheckCustomizeFieldHandle(v.key, v.type, sv.value, v.defaultValue) === true ? 'checked' : ''}>
                                              <input
                                                value={sv.value}
                                                checked={this.onCheckCustomizeFieldHandle(v.key, v.type, sv.value, v.defaultValue) === true ? 'checked' : ''}
                                                onChange={this.onChangeCustomizeFieldHandle.bind(this, v.key, v.type)}
                                                type="checkbox"
                                                className="form-check-input-styled"
                                                data-fouc="true" />
                                            </span>
                                          </div>{sv.title}</label>
                                      </div>);
                                    })
                                    }
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );

                    case 'RADIO': 
                      return (
                        <div key={`customize_${v.key}`} className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-12">
                                  <label>{v.title} <span className="text-danger">{(v.require === true) ? '*' : ''}</span></label>
                                  <div className="form-group">
                                    {v.items.map((sv, sk) => {
                                      return (<div key={`customize_subradio_${v.key}_${sk}`} className="form-check">
                                        <label className="form-check-label">
                                          <div className="uniform-choice">
                                            <span className={this.onCheckCustomizeFieldHandle(v.key, v.type, sv.value) ? 'checked' : ''}>
                                              <input
                                                // checked={model.availability === 'customer'}
                                                onChange={this.onChangeCustomizeFieldHandle.bind(this, v.key, v.type)}
                                                value={sv.value}
                                                type="radio"
                                                name="availability"
                                                className="form-input-styled"
                                                data-fouc="true"
                                              />
                                            </span>
                                          </div>{sv.title}
                                        </label>
                                      </div>);
                                    })
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>);

                    case 'LISTBOX':
                      return (<div key={`customize_${v.key}`} className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-12">
                                <label>{v.title} <span className="text-danger">{(_.isEmpty(v.require) ? '*' : '')}</span></label>
                                <div className="form-group">
                                  <Select
                                    value={this.onCheckCustomizeFieldHandle(v.key, v.type, null, v.defaultValue)}
                                    onChange={this.onChangeCustomizeFieldHandle.bind(this, v.key, v.type)}
                                    options={this.onConvertDataSettingListbox(v.items)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>);
                    default:
                      break;
                  }
                  return true;
                })}
                
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card ">
              <div className="card-header header-elements-inline">
                <h5 className="card-title">Thông tin khác</h5>
                <div className="header-elements"></div>
              </div>
              <div className="card-body ">
                <form onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-12">
                        <label>Người ủy thác</label>
                        <Select
                          onChange={this.onChangeHandle.bind(this, 'delegates')}
                          options={delegates}
                          isMulti={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text-right">
                    <button onClick={this.submit.bind(this, 'add')} className="btn btn-primary">
                      <i className="fa fa-save"></i> Lưu lại
                    </button>

                    <button onClick={this.submit.bind(this, 'addNew')} value="addNew" className="btn btn-success" style={{ marginLeft: 20 }}>
                      <i className="fa fa-save"></i> Lưu & tạo mới
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <NotificationContainer />
      </div>

    );
  }

  componentDidUpdate(oldProps) {
    try {
      /**
       * 
       */

      const newProps = this.props;
      if (oldProps.accounts !== newProps.accounts) {
        const delegates = this.props.getDelegateMember();
        this.setState({ ...delegates });
      }

      if (oldProps.suppliers !== newProps.suppliers) {
        const objSupplier = [];
        objSupplier.push(
          { value: 0, label: 'Không' },
        );
        newProps.suppliers.map((v) => {
          objSupplier.push(
            { value: v.id, label: v.title }
          );
          return true;
        });
        this.setState({
          suppliers: objSupplier
        });
      }

      if (oldProps.buildServices !== newProps.buildServices) {
        const parentIdentifies = this.props.listServiceHandleData();
        this.setState({ parentIdentifies: parentIdentifies.list });
      }

      if (oldProps.profiles !== newProps.profiles) {
        const showContextMenu = { display: false };
        if (_.isEmpty(this.props.profiles) === false) {
          showContextMenu.display = true;
        }
        this.setState({ showContextMenu, profiles: this.props.profiles });
      }

      if (oldProps.socketBalance !== newProps.socketBalance) {
        this.callbackWallet();
      }
    } catch (error) {
      console.log(error);
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      init: payload.generalReducer.init,
      socketBalance: payload.walletReducer.socketBalance,
      wallet: payload.walletReducer.wallet,
      orderTags: payload.orderReducer.orderTags,
      buildServices: payload.serviceReducer.buildServices,
      agencies: payload.agencyReducer.agencies,
      suppliers: payload.supplierReducer.suppliers,
      agency: payload.agencyReducer.agency,
      accounts: payload.accountReducer.accounts,
      profiles: payload.generalReducer.profiles,
      userInfo: payload.generalReducer.userInfo,
      uploadPath: payload.generalReducer.uploadPath,
      downloadPath: payload.generalReducer.downloadPath,
      listScope: payload.generalReducer.listScope,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(CreateOrder)
);
