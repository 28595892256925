import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

class UploadFileContainer extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = {
      selectedFileName: ''
    };
  }

  onClear() {
    this.props.setFiles([]);
    this.setState({ selectedFileName: '' });
  }

  onUploadFile(e) {
    try {
      const files = _.get(e, 'target.files', false);
      const field = _.get(e, 'target.name', false);
      if (_.isEmpty(files) === false) {
        const selectedFile = [];
        const selectedFileName = [];
        _.forEach(files, (file) => {
          const filename = file.name;
          selectedFile.push({
            file,
            filename: file.name
          });
          selectedFileName.push(file.name);
        });
        this.props.setFiles(selectedFile, field);
        this.setState({ selectedFileName: selectedFileName.join(', '), fieldName: field });
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  /**
   * Upload
   */

  render() {
    return (
      <div>
        <div className="uniform-uploader" style={{ zIndex: 0 }}>
          <input
            onChange={this.onUploadFile.bind(this)}
            name={this.props.name}
            type="file"
            multiple={this.props.multiple || true}
            className="form-input-styled"
            data-fouc="true"
            accept={this.props.accept || '.doc,.docx,.pdf,.bmp,.png,.gif,.jpg,.jpeg,.zip,.rar,.xlsx,.xls,.pdf'}
          />
          <span className="filename" style={{ userSelect: 'none' }}>{this.state.selectedFileName || 'No file selected'}</span>
          <span className="action btn bg-blue" style={{ userSelect: 'none' }}>Choose File</span>
        </div>
        <div style={{ float: 'right' }}>
          <i onClick={this.onClear.bind(this)} className="fa fa-trash" aria-hidden="true" hidden={ !this.state.selectedFileName} style={{ cursor: 'pointer' }}></i>
        </div>
      </div>
      
    );
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;

    if (oldProps.resetForm !== newProps.resetForm) {
      this.setState({ selectedFileName: '' });
    }
  }
}

function mapsDispatchToProp(dispatch) {
  return {};
}

export default connect((rootState) => {
  return {
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(UploadFileContainer);
