import React from 'react';
import { Tooltip } from 'reactstrap';
import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import Select2 from 'react-select2-wrapper';
import { Tabs, Tab } from 'react-bootstrap';
import cx from 'classnames';

import Autocomplete from '@celebryts/react-autocomplete-tags';

import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/vi';

import Cleave from 'cleave.js/react';

import { confirmAlert } from 'react-confirm-alert'; // Import

import InputMask from 'react-input-mask';
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faTrash } from '@fortawesome/free-solid-svg-icons';
import { DelayInput } from 'react-delay-input';
import SelectStateContainer from '../../ultils/selectState.container';
import UploadFileContainer from '../../ultils/uploadFile.container';
import {
  formatDate, fileUpload,
  buildSetting,
  buildListCountryArrival,
  buildListAirportArrival,
  buildListProcessingTimeArrival,
  buildListPurposeArrival,
  buildListExtraServiceArrival,
  immigrationFee,
  isStaff, checkScope,
  formatDateTimeChatBox,
  formatDateTime,
  isWorkingDay
} from '../../../helpers';

moment.locale('vi');
const diffMonthDuration = '9'; // 9 month
class ModifyImmigrationOrder extends React.Component {
  constructor(props) {
    super(props);
    this.input = {};
    this.state = {
      supplierDefaultValue: { value: 0, label: 'Không' },
      service: {},
      deposit: [0],
      documentCount: 0,
      processingTimeFeeIndex: 0,
      isAllowUpload: 0,
      isAllowUploadDescription: '',
      typeOfSubmit: false,
      tooltipOpen: false,
      expireAtWarning: false,
      discountMessage: false,
      isBussinessCase: false,
      isVisitingCase: false,
      isWorkingDayStatus: isWorkingDay(),
      nationalDefaultValue: { value: 'all', label: 'Chọn quốc tịch' },
      stateDefaultValue: { label: 'Đang chờ xử lý', value: 'PENDING', color: '#F3E0EC' },
      sexDefaultValue: { value: 'all', label: 'Chọn giới tính' },
      value: '',
      predictions: [],
      showContextMenu: {
        display: false,
        style: {}
      },
      selectedFile: [],
      selectedProfileFile: [],
      selectedExtraInfoFile: [],
      isDisable: true,
      dateDisplay: {
        arrivalAt: 'DD/MM/YYYY',
        issuedAt: 'DD/MM/YYYY',
        supplierSendAt: 'DD/MM/YYYY',
        birthday: 'DD/MM/YYYY'
      },
      visaDateDisplay: 'DD/MM/YYYY',
      estimateFee: {
        numberOfProfile: 1,
        serviceFee: 0,
        countryArrivalFees: [],
        processingTimeFee: 0,
        extraServiceFee: 0
      },

      model: {
        supplierId: 0,
        supplierAmount: 0,
        vat: '',
        paidSupplierAmount: 0,
        supplierSendAt: '',
        customizeField: {},
        serviceIdentifyCode: '',
        invoiceId: [],
        state: '',
        estimatedAt: '',
        description: '',
        profile: {
          firstName: '',
          lastName: '',
          fullName: '',
          sex: '',
          birthday: '',
          passport: {
            number: ''
          },
          visa: {
            result: [],
            expiredAt: ''
          }
        }
      },
      genders: [
        { value: 'male', label: 'Nam' },
        { value: 'female', label: 'Nữ' }
      ],
      suppliers: [],
      customizeField: [],
      contries: [],
      states: [{ label: 'Đang chờ xử lý', value: 'PENDING', color: '#F3E0EC' }],
      parentIdentifies: [],
      profiles: [{
        firstName: '',
        lastName: '',
        fullName: '',
        sex: '',
        birthday: '',
        countryArrival: 'VN',
        passport: {
          number: ''
        },
        files: [],
        description: '',
        isDifficult: false,
        note: ''
      }],

      discount: {},
      listPurposeArrival: [{ value: 'all', label: 'Chọn thời gian lưu trú' }],
      process: [],
      guarantee: {},
      settings: {},
      visitingExtraInfo: { value: 1, label: 'Vợ' },
      guaranteeList: [
        { value: 1, label: 'Vợ' },
        { value: 2, label: 'Chồng' },
        { value: 3, label: 'Cha' },
        { value: 4, label: 'Mẹ' },
        { value: 5, label: 'Con' }
      ]
    };
    this.onModifyChangeHandle = this.onModifyChangeHandle.bind(this);
    this.toggleTips = this.toggleTips.bind(this);
    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.setFiles = this.setFiles.bind(this);
    this.setPin = this.setPin.bind(this);
    this.onUploadFile = this.onUploadFile.bind(this);
    this.setModelOrder = this.setModelOrder.bind(this);
    //
    this.handleSendMessage = this.handleSendMessage.bind(this);
    this.handleBtnSendMessage = this.handleBtnSendMessage.bind(this);
  }

  handleSendMessage(e) {
    e.preventDefault();
    const message = _.get(e, 'target.value', _.get(e, 'value', false));
    if (message) {
      this.setState({ message });
    }
  }

  handleBtnSendMessage(e) {
    e.preventDefault();
    const { model, message, process } = this.state;
    const { init } = this.props;
    if (message) {
      process.push({
        account: {
          id: init.accountInfo.id,
          title: init.accountInfo.title
        },
        note: message,
        createdAt: new Date().toISOString()
      });

      this.setState({ message: '', process });
      this.props.onSendMessage(message, model.serviceIdentifyCode);
    }
  }

  async validateCustomizeFieldOnSubmit(model) {
    return new Promise((resolve, reject) => {
      const customizeField = this.state.customizeField;
      let flag = true;
      customizeField.map((field) => {
        if (field.require === true) {
          if (_.isArray(model.customizeField[field.key])) {
            console.log(model.customizeField[field.key]);
            if (_.get(model.customizeField[field.key], '1', '') === '') {
              NotificationManager.error(`${field.title} không được để trống`, '', 1500);
              flag = false;
            }
          } else if (!model.customizeField[field.key] || _.isEmpty(model.customizeField[field.key])) {
            NotificationManager.error(`${field.title} không được để trống`, '', 1500);
            flag = false;
          }
        }
        return true;
      });
      resolve(flag);
    });
  }

  async validateRequiredOnSubmit(model) {
    return new Promise((resolve, reject) => {
      const required = this.required;
      let flag = true;
      _.forEach(required, (field) => {
        if (flag === false) return false;
        if (_.get(field, 'target.value', '') === '') {
          field.focus();
          flag = false;
        }
        return true;
      });
      resolve(flag);
    });
  }

  callbackWallet() {
    try {
      const { wallet, socketBalance } = this.props;
      if (_.isEmpty(socketBalance) === false) {
        if (wallet.accountId === socketBalance.accountId && wallet !== socketBalance) {
          this.props.dispatch({
            type: 'POST_SEARCH_WALLET_DETAIL_SUCCESS',
            payload: socketBalance
          });
          this.toggleWallet();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  toggleWallet = () => {
    this.setState(prevstate => ({
      displayWallet: !prevstate.displayWallet
    }));

    setTimeout(() => {
      this.setState(prevstate => ({
        displayWallet: !prevstate.displayWallet
      }));
    }, 3000);
  };

  checkBalance(amount = 0) {
    const { init, wallet, agency } = this.props;
    let agencyInfo = _.clone(agency);
    const isStaffStatus = isStaff(this.props);
    if (isStaffStatus === false) {
      agencyInfo = _.get(init, 'accountInfo', {});
    }
    if (_.isEmpty(wallet) === true) {
      return false;
    }
    if (_.isNumber(amount) === false || amount < 0) {
      return false;
    }
    if (wallet.amount < amount) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <h2>Số dư không đủ</h2>
              <p>
                Tài khoản của <span style={{ fontWeight: 'bold' }}>{agencyInfo.title}</span> không đủ số dư để tạo đơn hàng.<br />
                  Vui lòng nạp thêm tiền vào tài khoản.
                <button className="btn btn-info" type="button"
                  onClick={() => {
                    if (isStaffStatus === true) {
                      window.open(`/account-wallet/${agencyInfo.id}`, '_blank');
                    } else {
                      window.open('/liability', '_blank');
                    }
                  }}
                  style={{ padding: '0px 5px' }}
                >Click vào đây để nạp</button>
              </p>
              <button
                onClick={() => { onClose(); }}
                className="btn btn-secondary"
                type="button"
                style={{ marginRight: 20 }}
              >Tôi đã nạp
              </button>

            </div>
          );
        },
        closeOnClickOutside: false

      });
    }
    return true;
  }

  onConvertDataSettingListbox(items) {
    if (_.isArray(items) === false) {
      return {
        label: items.title,
        value: items.value
      };
    }
    const result = [];
    items.map((v) => {
      result.push({
        label: v.title,
        value: v.value
      });
      return true;
    });
    return result;
  }

  onCheckCustomizeFieldHandle(key, type, value) {
    const model = Object.assign({}, this.state.model);
    if (type === 'CHECKBOX') {
      const currentValue = _.get(model, `customizeField.${key}`, []);
      if (_.includes(currentValue, value)) {
        return true;
      }
      return false;
    }
    if (type === 'RADIO') {
      const currentValue = _.get(model, `customizeField.${key}`, '');
      if (currentValue === value) {
        return true;
      }
    }
    if (type === 'LISTBOX') {
      const currentValue = _.get(model, `customizeField.${key}`, '');
      const control = _.find(this.state.customizeField, { key });
      if (_.isUndefined(control)) return false;
      const items = this.onConvertDataSettingListbox(control.items);
      const defaultValue = _.find(items, { value: currentValue });
      if (_.isUndefined(defaultValue)) return null;
      return defaultValue;
    }
    return false;
  }

  onChangeCustomizeFieldHandle(key, type, e) {
    const model = Object.assign({}, this.state.model);
    const customizeField = this.state.customizeField;
    if (type === 'RADIO') {
      const code = e.target.value;
      model.customizeField[key] = code;
    }
    if (type === 'CHECKBOX') {
      const code = e.target.value;
      let currentValue = _.get(model, `customizeField.${key}`, []);
      if (e.target.checked === true) {
        currentValue.push(code);
        currentValue = _.uniq(currentValue);
      } else {
        currentValue = _.remove(currentValue, v => (v !== code));
      }
      model.customizeField[key] = currentValue;
    }
    if (type === 'INPUT_TEXT' || type === 'INPUT_DATE') {
      const code = e.target.value;
      model.customizeField[key] = code;
    }
    if (type === 'LISTBOX') {
      const value = _.get(e, 'target.value', _.get(e, 'value', false));
      model.customizeField[key] = value;
    }
    const result = {};
    result.model = model;
    this.setState(result);
  }

  async validateInputOnSubmit() {
    return new Promise((resolve, reject) => {
      const input = this.input;
      let flag = true;
      _.forEach(input, (field) => {
        if (flag === false) return false;
        if (field.isActive === true) {
          if (field.required === true) {
            if (_.get(field, 'e.value', '') === '') {
              NotificationManager.error(`${field.title} không được để trống!`, '', 1500);
              field.e.focus();
              flag = false;
            }
          }
        }
        return true;
      });
      resolve(flag);
    });
  }

  onSubmit = async (e) => {
    try {
      e.preventDefault();
      // SHOW LOADING
      this.props.dispatch({ type: 'SHOW_LOADING' });
      const model = _.clone(this.state.model);
      const validateInputOnSubmit = await this.validateInputOnSubmit();
      if (validateInputOnSubmit === false) return;
      const validateCustomizeField = await this.validateCustomizeFieldOnSubmit(model);
      const validate = this.validateOnSubmit();

      if (validateInputOnSubmit === true && validateCustomizeField === true && validate === true) {
        if (_.isEmpty(this.state.selectedFile) === false) {
          const files = await fileUpload(this.state.selectedFile, this.props);
          if (files.code === 1) {
            files.data.map((v) => {
              model.profile.visa.result.push(v);
              return true;
            });
          } else {
            NotificationManager.error(files.message, '', 1500);
            return;
          }
        }
        if (_.isEmpty(this.state.selectedProfileFile) === false) {
          const profileFiles = await fileUpload(this.state.selectedProfileFile, this.props);
          if (profileFiles.code === 1) {
            const modelProfileFiles = model.profile.files ? _.clone(model.profile.files) : [];
            // const modelProfileFiles = [];
            _.forEach(profileFiles.data, (file) => {
              modelProfileFiles.push(file);
            });
            model.profile.files = modelProfileFiles;
          }
        }

        const payload = {
          id: model.invoiceId[0],
          data: _.pick(model, ['serviceIdentifyCode', 'state', 'profile', 'description', 'supplierId', 'supplierAmount', 'paidSupplierAmount', 'vat', 'supplierSendAt', 'customizeField'])
        };
        console.log('ok');
        this.props.onSubmit(payload);
      }

      this.props.dispatch({ type: 'HIDE_LOADING' });
    } catch (error) {
      console.log(error);
    } finally {
      this.props.dispatch({ type: 'HIDE_LOADING' });
    }
  }

  submit(key, e) {
    this.setState({ typeOfSubmit: key });
  }

  validateOnSubmit() {
    const { selectedFile, model, service } = this.state;
    const stateIsFinish = _.get(_.find(this.state.service.states, { state: model.state }), 'isFinish', false);
    if (stateIsFinish === true) {
      if (_.isEmpty(this.state.selectedFile) && _.isEmpty(_.get(model, 'profile.visa.result', []))) {
        NotificationManager.error('Chưa tải Kết quả visa lên', '', 1500);
        return false;
      }
      const expiredAt = _.get(model, 'profile.visa.expiredAt', false);
      if (!expiredAt) {
        NotificationManager.error('Chưa nhập ngày hết hạn visa', '', 1500);
        return false;
      }
    } 
    return true;
  }

  toggleTips() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  /**
   * File & Upload
   */

  setProfileFiles(selectedFile) {
    try {
      if (_.isEmpty(selectedFile) === false) {
        this.setState({ selectedProfileFile: selectedFile });
      }
    } catch (error) {
      console.log(error);
    }
  }

  setExtraInfoFile(selectedFile) {
    try {
      if (_.isEmpty(selectedFile) === false) {
        this.setState({ selectedExtraInfoFile: selectedFile });
      }
    } catch (error) {
      console.log(error);
    }
  }

  setFiles(selectedFile) {
    try {
      this.setState({ selectedFile });
    } catch (error) {
      console.log(error);
    }
  }

  setPin(orderId, index, status, e) {
    if (index >= 0) {
      const process = Object.assign([], this.state.process);
      process[index].isPin = !status;
      this.props.onSetPin(orderId, index, !status);
      this.setState({ process });
    }
  }

  onUploadFile(key, e) {
    try {
      const files = _.get(e, 'target.files', false);
      if (_.isEmpty(files) === false) {
        this.setState({ selectedFile: files });
        this.fileUpload(files, key);
      }
    } catch (error) {
      // console.log(error);
    }
  }

  fileUpload(files, type) {
    try {
      const { init, userInfo } = this.props;
      const data = new FormData();
      data.append('file', files[0]);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: userInfo.accessToken,
          accessToken: userInfo.accessToken
        }
      };

      axios.post(`${this.props.uploadPath}/upload-contract`, data, config).then((res) => {
        const code = _.get(res, 'data.code', false);
        if (code === 100) {
          const model = Object.assign({}, this.state.model);
          model.profile.files[0] = res.data.data.message.pathname;
          const selectedFileName = res.data.data.message.filename;
          this.setState({ model, selectedFileName });
        } else {
          const message = _.get(res, 'data.data.message.message', 'File không hợp lệ');
          console.log(message);
          NotificationManager.error(message, '', 3000);
        }
      });
    } catch (error) {
      // console.log(error);
    }
  }

  /**
   * Handle event
   */

  estimateHandle(estimateFee) {
    const { service, model, profiles } = this.state;
    const immigrationFeeInfo = immigrationFee(estimateFee, service, profiles, model);
    this.checkBalance(_.get(immigrationFeeInfo, 'generalInfo.amount', 0));
    return immigrationFeeInfo || {};
  }

  onChangeProfileHandle(key, e) {
    try {
      const result = {};
      const profiles = _.clone(this.state.profiles);
      const model = _.clone(this.state.model);
      if (_.isArray(e) === false) {
        switch (key) {
          case 'birthday': {
            const value = _.get(e, 'target.value', _.get(e, 'value', false));
            const dateFormat = 'YYYY/MM/DD';
            const splittedDate = value.split('/');

            if (splittedDate.length === 3 && splittedDate[2].length === 4) {
              const date = moment(`${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`, dateFormat);
              const dateDisplay = Object.assign({}, this.state.dateDisplay);
              dateDisplay[key] = value;
              result.dateDisplay = dateDisplay;
              if (date.isValid() === true) {
                profiles[key] = date.format();
                model.profile.birthday = profiles[key];
              }
            }
            break;
          }

          case 'identifyCard': {
            profiles.passport = {
              number: _.get(e, 'target.value', _.get(e, 'value', false))
            };
            model.profile.passport.number = _.get(e, 'target.value', _.get(e, 'value', false));
            break;
          }
          case 'sex': {
            profiles[key] = e;
            model.profile.sex = e.value;
            break;
          }
          case 'fullName': {
            profiles[key] = _.get(e, 'target.value', _.get(e, 'value', false));
            model.profile.fullName = profiles[key];
            const fullname = profiles[key];
            const fullnameArr = fullname.split(' ');
            const lastName = _.clone(fullnameArr[0]);
            model.firstName = fullnameArr.join(' ').trim(); 
            model.lastName = lastName;
            break;
          }
          default:
            profiles[key] = _.get(e, 'target.value', _.get(e, 'value', false));
            model.profile[key] = profiles[key];
            break;
        }
      }
      result.model = model;
      result.profiles = profiles;
      this.setState(result);
    } catch (error) {
      // 
      console.log(error);
    }
  }

  onChangeHandle(key, e) {
    try {
      const { init } = this.props;
      const {
        estimateFee, isWorkingDayStatus, isDifficult, suppliers 
      } = this.state;
      const model = _.clone(this.state.model);

      const result = {};
      let editAmount = false;
      switch (key) {
        case 'tag': {
          const value = _.get(e, 'target.value', _.get(e, 'value', false));
          model[key] = this.tags.state.tags;
          if (value) { model[key].push(value); }
          model[key] = _.uniq(model[key]);
          break;
        }

        case 'state': {
          model[key] = 'PENDING';
          if (isStaff(this.props) === true) {
            const warning = _.get(e, 'state.warning', false);
            if (warning) {
              confirmAlert({
                title: 'Xác nhật cập nhật trạng thái',
                message: warning,
                buttons: [{ label: 'Đóng' }]
              });
            }
            model[key] = _.get(e, 'target.value', _.get(e, 'value', false));
            result.stateDefaultValue = e;
          }

          break;
        }

        case 'purposeArrival': {
          const { listPurposeArrival, discount } = this.state;
          const purposeArrival = _.find(listPurposeArrival, { value: Number(e.value) });
          let discountAmount = 0;
          if (_.isEmpty(purposeArrival) === false) {
            const discountValue = _.get(discount, 'info.value', []);
            const discountInfo = _.find(discountValue, { key: purposeArrival.value });

            if (_.isEmpty(discountInfo) === false) {
              discountAmount = discountInfo.amount;

              if (discount.isDiscount === true && discountInfo.type === 'percent') {
                discountAmount = purposeArrival.amount * discountInfo.amount / 100;
              }

              if (discountAmount > purposeArrival.amount) {
                discountAmount = purposeArrival.amount;
              }

              if (discountAmount < 0) {
                discountAmount = 0;
              }
            }
            model.purposeArrival = purposeArrival;
            estimateFee.serviceFee = purposeArrival.amount - discountAmount;
            result.discountInfo = discountInfo;
          }
          editAmount = false;
          break;
        }

        case 'airportArrival': {
          model.airportArrival = e;
          break;
        }

        case 'arrivalAt': {
          break;
        }

        case 'processingTimeArrival': {
          if (_.isEmpty(model.serviceIdentifyCode) === true) {
            model.processingTimeArrival = '';
            result.processingTimeFeeIndex = '';
            estimateFee.processingTimeFee = 0;
          } else {
            const service = this.props.getServiceDetail(model.serviceIdentifyCode);
            const settings = _.get(service, 'settings', '{}');
            const processingTime = JSON.parse(_.get(settings, 'default.processingTime', '{}'));

            let processingTimeArrivals = [];
            if (_.isEmpty(processingTime) === false) {
              processingTimeArrivals = buildListProcessingTimeArrival(processingTime, isWorkingDayStatus);
            }

            const processingTimeIndex = _.findIndex(processingTimeArrivals, { value: Number(e.target.value) });
            if (_.isEmpty(processingTime) === false) {
              const processingTimeArrival = _.find(processingTimeArrivals, { value: Number(e.target.value) });
              result.processingTimeFeeIndex = processingTimeIndex;
              model.processingTimeArrival = processingTimeArrival;
              estimateFee.processingTimeFee = processingTimeArrival.amount;
            }
            editAmount = false;
          }

          break;
        }

        case 'extraInfo': {
          model.extraInfo = e;
          editAmount = false;
          break;
        }

        case 'extraServiceArrival': {
          const extraServices = _.clone(model.extraService);
          const extraServiceArrivals = buildListExtraServiceArrival(init);
          const serviceIndex = _.findIndex(extraServiceArrivals, { value: Number(e.target.value) });

          if (serviceIndex >= 0) {
            const modelIndex = _.findIndex(extraServices, { value: Number(e.target.value) });

            if (modelIndex >= 0) {
              extraServices.splice(modelIndex, 1);
            }

            if (e.target.checked === true) {
              extraServices.push(extraServiceArrivals[serviceIndex]);
            }

            model.extraService = extraServices;
            estimateFee.extraServiceFee = 0;

            extraServices.forEach((element) => {
              estimateFee.extraServiceFee += element.amount;
            });
          }
          editAmount = false;
          break;
        }

        case 'supplierId': {
          const value = _.get(e, 'target.value', _.get(e, 'value', false));
          model.supplierId = value;
          if (model.supplierId === 0) {
            model.supplierAmount = 0;
            model.paidSupplierAmount = 0;
            model.supplierSendAt = null;
            this.state.dateDisplay.supplierSendAt = 'DD/MM/YYYY';
          }
          const supplierDefaultValue = _.find(suppliers, { value });
          result.supplierDefaultValue = supplierDefaultValue;
          break;
        }
        case 'supplierAmount': {
          const amount = _.get(e, 'target.rawValue', _.get(e, 'value', 0));
          model[key] = Number(amount);
          break;
        }

        case 'paidSupplierAmount': {
          const amount = _.get(e, 'target.rawValue', _.get(e, 'value', 0));
          model[key] = Number(amount);
          break;
        }

        case 'supplierSendAt':
        case 'issuedAt': {
          const value = _.get(e, 'target.value', _.get(e, 'value', false));
          const dateFormat = 'YYYY/MM/DD';
          const splittedDate = value.split('/');

          if (splittedDate.length === 3 && splittedDate[2].length === 4) {
            const date = moment(`${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`, dateFormat);
            const dateDisplay = Object.assign({}, this.state.dateDisplay);
            dateDisplay[key] = value;
            result.dateDisplay = dateDisplay;
            if (date.isValid() === true) {
              if (key === 'issuedAt') {
                model.issuedAt = date.format();
              }
              if (key === 'supplierSendAt') {
                model.supplierSendAt = date.format();
              }
            }
          }
          break;
        }

        case 'amount': {
          editAmount = true;
          const amount = _.get(e, 'target.rawValue', _.get(e, 'value', 0));
          model[key] = Number(amount);
          break;
        }

        default:
          model[key] = _.get(e, 'target.value', _.get(e, 'value', false));
          break;
      }
      const immigrationFeeInfo = this.estimateHandle(estimateFee);
      if (editAmount === false) {
        model.amount = _.get(immigrationFeeInfo, 'generalInfo.amount', 0);
      }
      result.model = model;
      result.estimateFee = estimateFee;

      this.setState(result);
    } catch (error) {
      console.log(error);
    }
  }

  onDeleteFile(index, type) {
    try {
      const { model, profiles } = this.state;

      switch (type) {
        case 'profile': {
          const files = _.get(model, 'profile.files', []);
          if (_.isEmpty(files) === false) {
            files.splice(index, 1);
            model.profile.files = files;
            // this.setState(model);
          }
          break;
        }

        case 'visa': {
          const files = _.get(model, 'profile.visa.result', []);
          if (_.isEmpty(files) === false) {
            files.splice(index, 1);
            model.profile.visa.result = files;
            // this.setState(model);
          }
          break;
        }

        case 'extraInfo': {
          const files = _.get(profiles, 'extraInfo.files', '');

          if (_.isEmpty(files) === false) {
            files.splice(index, 1);
            profiles.extraInfo.files = files;
            // this.setState(profiles);
          }
          break;
        }

        default:
          break;
      }
      const result = {};
      result.model = model;
      this.setState(result);
    } catch (error) {
      console.log(error);
    }
  }
  /**
   * Render
   */

  renderDocument() {
    const { model } = this.state;
    if (_.isEmpty(model.serviceIdentifyCode) === true) {
      return (<div></div>);
    }
    const service = this.props.getServiceDetail(model.serviceIdentifyCode);
    if (_.isEmpty(service.document) === true) {
      return (<div></div>);
    }
    return (service.document.map((item, i) => {
      return (<div style={{ float: 'left' }} key={`renderDocument_${i}`} className="col-md-6">
        <div style={{ padding: 10 }} className="media-title font-weight-semibold">
          <a target="_blank" href={item.value}>
            <i className="fa fa-link"></i> {item.key}
          </a>
        </div>
      </div>);
    }));
  }

  renderExtraServiceFee(estimateFee) {
    const { init, order } = this.props;
    const { extraServiceArrivals } = this.state;
    const orderExtraServices = _.clone(order.extraService);
    if (_.isEmpty(orderExtraServices) === true) {
      return (<tr style={{ border: 0 }}>
        <td key={'renderExtraServiceFee_0'} colSpan={2} style={{ border: 0, textAlign: 'right' }}>
          0 VND X {estimateFee.numberOfProfile.toLocaleString() || 0} khách = 0 VND
        </td>
      </tr>);
    }
    const extraServices = [];
    _.forEach(orderExtraServices, (item) => {
      const extraServiceArrival = _.find(extraServiceArrivals, { value: Number(item) });
      if (_.isEmpty(extraServiceArrival) === false) {
        extraServices.push(extraServiceArrival);
      }
    });
    return _.map(extraServices, (item, i) => {
      if (_.isEmpty(item.label) === false && item.amount > 0) {
        return (<tr key={`renderExtraServiceFee_${i}`} style={{ border: 0 }}>
          <td colSpan={2} style={{ border: 0, textAlign: 'right' }}>
            {item.label} : {item.amount.toLocaleString() || 0} VND X {estimateFee.numberOfProfile.toLocaleString() || 0} khách = {(item.amount * estimateFee.numberOfProfile).toLocaleString()} VND
          </td>
        </tr>);
      }
      return (<tr key={`renderExtraServiceFee_${i}`}></tr>);
    });
  }

  renderFees(data) {
    const fees = _.get(data, 'fees', false);
    if (fees === false) {
      return (<tr style={{ border: 0 }}></tr>);
    }

    return _.map(data.fees, (item, i) => {
      if (_.isEmpty(item.label) === false && item.amount > 0) {
        return (<tr key={`renderFees_${i}`} >
          <td colSpan={2} style={{ border: 0, textAlign: 'right' }}>
            {item.label} ({item.count}) : {item.amount.toLocaleString()} VND
          </td>
        </tr>);
      }
      return (<tr key={`renderFees_${i}`}></tr>);
    });
  }

  renderEstimate() {
    const {
      estimateFee,
      airportArrivalDefaultValue,
      purposeArrivalDefaultValue,
      processingTimeDefaultValue,
      countryArrivalFeeDefaultValue,
      countryArrival,
      service,
      profiles,
      model
    } = this.state;
    const { order } = this.props;
    const immigrationFeeInfo = immigrationFee(estimateFee, service, profiles, model);
    const stypeLeftTD = { width: 165, verticalAlign: 'top', fontWeight: 'bold' };
    const stypeRightTD = { textAlign: 'right' };

    return (<div className="col-xl-5">
      <div className="card" style={{ height: 'calc(100% - 20px)' }}>
        <div className="card-header header-elements-inline">
          <h5 className="card-title">Chi phí </h5>
        </div>
        <div className="card-body ">
          <div>
            <table className="table table-striped" style={{ padding: 0 }} >
              <tbody >
                <tr>
                  <td style={stypeLeftTD}>TỔNG PHÍ</td>
                  <td className="text-danger" style={{ fontWeight: 600, textAlign: 'right' }}>
                    {_.get(order, 'amount', 0).toLocaleString()} VND
                  </td>
                </tr>

                <tr>
                  <td style={stypeLeftTD}>Mục đích nhập cảnh</td>
                  <td style={stypeRightTD}>
                    {_.get(purposeArrivalDefaultValue, 'label', '')}
                  </td>
                </tr>

                <tr>
                  <td style={stypeLeftTD}> Ngày nhập cảnh </td>
                  <td style={stypeRightTD}>{formatDate(order.arrivalAt)}</td>
                </tr>
                <tr>
                  <td style={stypeLeftTD}>Phí sân bay</td>
                  <td style={stypeRightTD}>
                    {_.get(airportArrivalDefaultValue, 'label', '')}
                  </td>
                </tr>
                <tr >
                  <td colSpan={2} style={{ border: 0, textAlign: 'right' }}>
                    {_.get(immigrationFeeInfo, 'airportArrivalFeeInfo.amount', 0).toLocaleString()} VND
                  </td>
                </tr>

                <tr>
                  <td style={stypeLeftTD}> Phí bảo lãnh </td>
                  <td style={stypeRightTD}></td>
                </tr>
                <tr>
                  <td colSpan={2} style={{ border: 0, textAlign: 'right' }}>
                    {_.get(immigrationFeeInfo, 'guaranteeFeeInfo.amount', 0).toLocaleString()} VND
                  </td>
                </tr>
                <tr>
                  <td style={stypeLeftTD}> Phí xử lý hồ sơ quốc gia khó </td>
                  <td style={stypeRightTD}>
                    {countryArrivalFeeDefaultValue > 0
                      ? <p>{countryArrival.label}</p>
                      : <span></span>}
                  </td>
                </tr>

                <tr>
                  <td colSpan={2} style={{ border: 0, textAlign: 'right' }}>
                    {countryArrivalFeeDefaultValue > 0
                      ? `${countryArrivalFeeDefaultValue.toLocaleString()} VND`
                      : ''}
                  </td>
                </tr>

                <tr>
                  <td style={stypeLeftTD}> Phí dịch vụ </td>
                  <td style={stypeRightTD}>{_.get(this.state, 'service.title', '')}</td>
                </tr>
                <tr >
                  <td colSpan={2} style={{ border: 0, textAlign: 'right' }}>
                    {estimateFee.serviceFee.toLocaleString() || 0} X {estimateFee.numberOfProfile.toLocaleString() || 0} khách = {estimateFee.serviceFee.toLocaleString() || 0} VND
                  </td>
                </tr>
                <tr>
                  <td style={stypeLeftTD}> Dịch vụ kèm theo </td>
                  <td style={stypeRightTD}></td>
                </tr>
                {this.renderExtraServiceFee(estimateFee)}
                <tr>
                  <td style={stypeLeftTD}> Thời gian lưu trú </td>
                  <td style={stypeRightTD}>
                    {_.get(processingTimeDefaultValue, 'label', '')}
                  </td>
                </tr>
                <tr >
                  <td colSpan={2} style={{ border: 0, textAlign: 'right' }}>
                    {estimateFee.processingTimeFee.toLocaleString() || 0} VND X {estimateFee.numberOfProfile.toLocaleString() || 0} khách = {estimateFee.processingTimeFee.toLocaleString() || 0} VND
                  </td>
                </tr>
                {_.isEmpty(_.get(immigrationFeeInfo, 'countryArrivalFeeInfo.fees', [])) === false
                  ? <tr>
                    <td style={stypeLeftTD}> Trường hợp khó </td>
                    <td style={stypeRightTD}></td>
                  </tr>
                  : <tr style={{ border: 0 }}></tr>}
                {this.renderFees(immigrationFeeInfo.countryArrivalFeeInfo)}

              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>);
  }

  renderProfiles() {
    const { genders, profiles, dateDisplay } = this.state;
    const { init, order } = this.props;
    const countryArrivals = buildListCountryArrival(init);
    if (_.isEmpty(order) === true) {
      return (<div key={'renderProfiles'} className="row"></div>);
    }
    const profile = _.clone(order.profile);
    return (<div className="row" style={{ paddingBottom: 20 }}>
      <div className="col-md-2">
        <label>Họ & tên <span className="text-danger">*</span></label>
        <div className="form-group">
          <input
            value={profiles.fullName || ''}
            type="text"
            onChange={this.onChangeProfileHandle.bind(this, 'fullName')}
            className="form-control"
            placeholder="Họ khách hàng"
          />
        </div>
      </div>

      <div className="col-md-2">
        <label>Giới tính <span className="text-danger">*</span></label>
        <div className="form-group">
          <Select
            value={_.find(genders, { value: profiles.sex })}
            onChange={this.onChangeProfileHandle.bind(this, 'sex')}
            options={genders}
          />
        </div>
      </div>

      <div className="col-sm-3">
        <div className="form-group">
          <label>Ngày sinh <span className="text-danger">*</span></label><br />
          <div className="form-group">
            <div className="input-group">
              <span className="input-group-prepend">
                <span className="input-group-text">
                  <i className="icon-calendar"></i>
                </span>
              </span>
              <div className="multiselect-native-select">
                <InputMask
                  value={_.get(dateDisplay, 'birthday', '')}
                  className="form-control"
                  mask="99/99/9999"
                  onChange={this.onChangeProfileHandle.bind(this, 'birthday')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-2">
        <label>Số hộ chiếu <span className="text-danger">*</span></label>
        <div className="form-group">
          <input
            value={_.get(profiles, 'passport.number', '')}
            onChange={this.onChangeProfileHandle.bind(this, 'identifyCard')}
            type="text"
            className="form-control"
            placeholder="Nhập số căn cước"
          />

        </div>
      </div>

      <div className="col-sm-3">
        <label>Quốc tịch <span className="text-danger">*</span></label>
        <div className="form-group">
          <Select
            isDisabled={true}
            value={_.find(countryArrivals, { value: profile.countryArrival })}
            options={countryArrivals}
          />
        </div>
      </div>

    </div>
    );
  }

  renderFooterProfiles() {
    const {
      dateDisplay
    } = this.state;
    return (
      <div className="form-group">
        <div className="form-group"></div>
      </div>
    );
  }

  renderImmigrationStateMofidy() {
    const { order } = this.props;
    const {
      stateDefaultValue,
      states,
      visaDateDisplay,
      model
    } = this.state;

    // const files = _.get(order, 'profile.visa.result', []);
    const files = _.get(model, 'profile.visa.result', []);
    return (
      <div className="row">
        <div className="col-xl-12">
          <form onSubmit={this.onSubmit}>
            <div className="form-group">
              <div className="row">

                <div className="col-md-6">
                  <label>Trạng thái đơn hàng</label>

                  <SelectStateContainer
                    value={stateDefaultValue}
                    onChange={this.onChangeHandle.bind(this, 'state')}
                    options={states}
                  />

                </div>
                <div className="col-md-6">
                  <label>Ngày hết hạn visa</label>
                  <InputMask
                    value={visaDateDisplay}
                    className="form-control"
                    mask="99/99/9999"
                    onChange={this.onModifyChangeHandle.bind(this, 'visaExpiredAt')}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-md-12">
                  <label>Kết quả visa</label>
                  <UploadFileContainer
                    setFiles={this.setFiles.bind(this)}
                  />
                  <span className="form-text text-muted">Tải file kết quả visa lên PDF</span>
                </div>

                {files ? <fieldset className="card-body">
                  <div className="form-group">
                    <label>Tải file đi kèm</label>
                    {this.renderDownloadFile(files, 'visa', model)}
                  </div>

                </fieldset> : ''}

              </div>
            </div>

          </form>
        </div>

      </div>
    );
  }

  renderProcessingTime() {
    const { order } = this.props;
    const { processingTimeArrivals } = this.state;
    if (_.isEmpty(processingTimeArrivals) === true) {
      return (<div></div>);
    }

    return _.map(processingTimeArrivals, (processingTimeArrival, i) => {
      return (<div key={`renderprocessingTime_${i}`} className="form-group">
        <div className="row">
          <div className="col-md-12">
            <div className="form-check" style={{ paddingLeft: 0 }}>
              <div className="radio">
                <label>
                  <input
                    disabled={true}
                    name="processingTime"
                    type="radio"
                    className="form-input-styled"
                    checked={order.processingTime === processingTimeArrival.value}
                    value={processingTimeArrival.value}
                  /> {processingTimeArrival.label}
                </label>
              </div>
            </div>
          </div>
        </div>
        {processingTimeArrival.description && order.processingTime === processingTimeArrival.value ? <div className="row">
          <div className="col-md-12">
            <div className="processing-note">
              {processingTimeArrival.description}
            </div>
          </div>

        </div> : ''}

      </div>);
    });
  }

  renderExtraService() {
    const { order } = this.props;
    const { extraServiceArrivals } = this.state;
    if (_.isEmpty(extraServiceArrivals) === true) {
      return (<div></div>);
    }
    const extraServices = _.get(order, 'extraService', []);
    return _.map(extraServiceArrivals, (extraServiceArrival, i) => {
      const exist = extraServices.indexOf(extraServiceArrival.value.toString());
      return (<div key={`renderExtraService_${i}`} className="form-group">
        <div className="row">
          <div className="col-md-12">
            <div className="form-check">
              <label className="form-check-label">
                <div className="uniform-checker">
                  <span
                    className={exist >= 0 ? 'checked' : ''}
                  >

                    <DelayInput
                      disabled={true}
                      type="checkbox"
                      className="form-input-styled"
                      delayTimeout={50}
                      value={extraServiceArrival.value}
                    />

                  </span>
                </div>{extraServiceArrival.label}</label>
            </div>
          </div>
        </div>
        {extraServiceArrival.description ? <div className="row">
          <div className="col-md-12">
            <div className="processing-note">
              {extraServiceArrival.description}
            </div>
          </div>

        </div> : ''}

      </div>);
    });
  }

  /**
   * 
   * @param {*} estimateFee 
   */

  onModifyChangeHandle(key, e) {
    const model = Object.assign({}, this.state.model);
    const value = _.get(e, 'target.value', _.get(e, 'value', false));
    const result = {};
    switch (key) {
      case 'modifyState': {
        const warning = _.get(e, 'state.warning', false);
        if (warning) {
          confirmAlert({
            title: 'Xác nhật cập nhật trạng thái',
            message: warning,
            buttons: [
              {
                label: 'Đồng ý',
                onClick: () => {
                  model.state = value;
                  result.modifyState = e;
                  result.model = model;
                  this.setState(result);
                }
              },
              {
                label: 'Hủy'
              }
            ]
          });
        } else {
          result.modifyState = e;
          model.state = value;
        }

        break;
      }
      case 'estimatedAt': {
        const dateFormat = 'YYYY/MM/DD';
        const splittedDate = value.split('/');
        if (splittedDate.length === 3) {
          const date = moment(`${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`, dateFormat);
          result.dateDisplay = value;
          if (date.isValid() === true) {
            model.estimatedAt = date.format();
          }
        }
        break;
      }

      case 'visaExpiredAt': {
        const dateFormat = 'YYYY/MM/DD';
        const splittedDate = value.split('/');
        if (splittedDate.length === 3) {
          const date = moment(`${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`, dateFormat);
          result.visaDateDisplay = value;
          if (date.isValid() === true) {
            model.profile.visa.expiredAt = date.format();
          }
        } else {
          model.profile.visa.expiredAt = null;
        }
        break;
      }

      default:
        model[key] = _.get(e, 'target.value', _.get(e, 'value', false));
        break;
    }
    result.model = model;
    this.setState(result);
  }

  renderVisiting() {
    const {
      isVisitingCase, profiles, guaranteeList, model
    } = this.state;
    if (isVisitingCase === false) {
      return (<div></div>);
    }
    const type = _.get(profiles, 'extraInfo.type', false);
    const files = _.get(profiles, 'extraInfo.files', '');
    const visitingExtraInfo = _.find(guaranteeList, { value: type });
    return (<div>

      <div className="row">
        <div className="col-md-12">
          <div className="processing-note">
            <div className="row">
              <div className="col-md-12" style={{ marginTop: 5 }}>
                <div className="form-group">
                  <label>Người bảo lãnh <span className="text-danger">*</span></label>
                  <Select
                    value={visitingExtraInfo}
                    options={guaranteeList}
                    isDisabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12" style={{ marginTop: 5 }}>
                <div className="form-group">
                  <label>Hồ sơ đính kèm <span className="text-danger">*</span></label>
                  {this.renderDownloadFile(files, 'extraInfo', model)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>);
  }

  renderBusinessType() {
    const {
      isBussinessCase, profiles, guarantee, model
    } = this.state;
    if (isBussinessCase === false) {
      return (<div></div>);
    }
    const type = _.get(profiles, 'extraInfo.type', false);
    const address = _.get(profiles, 'extraInfo.address', '');
    const files = _.get(profiles, 'extraInfo.files', '');
    return (<div>
      <div key={'renderBusinessType_1'} className="form-group">
        <div className="row">
          <div className="col-md-12">
            <div className="form-check" style={{ paddingLeft: 0 }}>
              <div className="radio">
                <label>
                  <input
                    disabled={true}
                    name="businessType"
                    type="radio"
                    className="form-input-style"
                    value="1"
                    checked={type === 1}
                    onChange={this.onChangeHandle.bind(this, 'extraInfo')}
                  /> Có công ty bảo lãnh
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="processing-note">
              <a
                style={{ paddingRight: 5, fontSize: 15 }}
                target="_blank"
                href={guarantee.document}>
                <FontAwesomeIcon icon={faFilePdf} />
              </a> {guarantee.description}

              <div className="row">
                <div className="col-md-12" style={{ marginTop: 5 }}>
                  <div className="form-group">
                    <label>Địa chỉ công ty bảo lãnh <span className="text-danger">*</span></label>
                    <input
                      disabled={true}
                      value={address || ''}
                      type="text"
                      className="form-control"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12" style={{ marginTop: 5 }}>
                  <div className="form-group">
                    <label>Hồ sơ đính kèm <span className="text-danger">*</span></label>
                    {this.renderDownloadFile(files, 'extraInfo', model)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div key={'renderBusinessType_2'} className="form-group">
        <div className="row">
          <div className="col-md-12">
            <div className="form-check" style={{ paddingLeft: 0 }}>
              <div className="radio">
                <label>
                  <input
                    name="businessType"
                    type="radio"
                    className="form-input-style"
                    value="0"
                    checked={type === 0}
                    onChange={this.onChangeHandle.bind(this, 'extraInfo')}
                  /> Không có công ty bảo lãnh
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>);
  }

  renderOrderInfo() {
    const { order } = this.props;
    const {
      model,
      dateDisplay,
      parentIdentifies,
      states,
      stateDefaultValue,
      listAirportArrival,
      listPurposeArrival,
      airportArrivalDefaultValue,
      purposeArrivalDefaultValue,
      isBussinessCase,
      isVisitingCase,
      settings,
      isAllowUploadDescription
    } = this.state;
    const files = _.get(order, 'profile.files', []);

    return (<div className="col-xl-7">
      <div className="card" style={{ height: 'calc(100% - 20px)' }}>
        <div className="card-header header-elements-inline">
          <h5 className="card-title">Tiến trình đơn hàng</h5>
        </div>
        <div className="card-body ">
          <Tabs defaultActiveKey="tab1">
            <Tab eventKey="tab1" title="Tiến trình">
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-6">
                      <label>Mục đích nhập cảnh <span className="text-danger">*</span></label>

                      <Select2
                        disabled={true}
                        value={model.serviceIdentifyCode}
                        style={{ width: '100%' }}
                        name="area"
                        className="form-control select-search select2-hidden-accessible"
                        data={parentIdentifies}
                        options={{
                          placeholder: 'Chọn mục đích nhập cảnh'
                        }}
                      />
                    </div>

                    <div className="col-md-6">
                      <label>Thời gian lưu trú <span className="text-danger">*</span></label>
                      <Select
                        isDisabled={true}
                        value={purposeArrivalDefaultValue}
                        options={listPurposeArrival}
                      />
                    </div>

                  </div>
                </div>
                <div className="form-group">
                  <div className="row">

                    {isStaff(this.props) === true ? <div className="col-md-6">
                      <label>Trạng thái <span className="text-danger">*</span></label>
                      <Select
                        isDisabled={true}
                        value={stateDefaultValue}
                        options={states}
                      />

                    </div> : ''}

                    <div className="col-md-6">
                      <label>Đến tại sân bay <span className="text-danger">*</span></label>
                      <Select
                        isDisabled={true}
                        value={airportArrivalDefaultValue}
                        options={listAirportArrival}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-6">
                      <label>Ngày nhập cảnh <span className="text-danger">*</span></label>
                      <InputMask
                        disabled={true}
                        value={dateDisplay.arrivalAt}
                        className="form-control"
                        mask="99/99/9999"
                      />
                    </div>

                    <div className="col-md-6">
                      <label>Tổng tiền đơn hàng
                        <span className="text-danger"> </span>
                      </label>
                      <label style={{ float: 'right', fontStyle: 'italic' }}>
                        <i
                          style={{ marginLeft: 10 }}
                          href="#"
                          id="TooltipExample"
                          className="fa fa-info-circle"></i>
                      </label>

                      <span className="float-right text-info">{this.state.discountMessage || ''}</span>

                      <div className="form-group">
                        <div className="input-group">
                          <span className="input-group-prepend">
                            <span className="input-group-text">VND</span>
                          </span>
                          <div className="multiselect-native-select">
                            <Cleave
                              disabled
                              value={model.amount}
                              className="form-control input-numeral"
                              placeholder="Số tiền"
                              options={{
                                numeral: true,
                                numeralThousandsGroupStyle: 'thousand',
                                numeralDecimalScale: 0
                              }}
                            />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                {files // && _.get(settings, 'info.type') === 'EXTREMELY_DIFFICULT'
                  ? <div className="form-group">
                    <div className="row">
                      <div className="col-md-12">
                        <label>Hồ sơ bổ sung</label>

                        <UploadFileContainer
                          setFiles={this.setProfileFiles.bind(this)}
                        />
                        <span className="form-text text-muted">
                          {_.get(settings, 'info.profileExtraDescription', isAllowUploadDescription || '')}
                        </span>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        {this.renderDownloadFile(files, 'profile', model)}
                      </div>
                    </div>

                  </div>
                  : ''}

                {isBussinessCase === true ? <div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-12"
                        style={{ borderBottom: '1px solid #CCC', paddingTop: 1 }}></div>
                    </div>
                  </div>
                  <div className="form-group" style={{ marginBottom: 0 }}>
                    <div className="row">
                      <div className="col-md-12">
                        <h5 className="card-title">
                          Thông tin công tác <span className="text-danger">*</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  {this.renderBusinessType()}
                </div> : ''}

                {isVisitingCase === true ? <div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-12"
                        style={{ borderBottom: '1px solid #CCC', paddingTop: 1 }}></div>
                    </div>
                  </div>
                  <div className="form-group" style={{ marginBottom: 0 }}>
                    <div className="row">
                      <div className="col-md-12">
                        <h5 className="card-title">
                          Thông tin công tác <span className="text-danger">*</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  {this.renderVisiting()}
                </div> : ''}

                <div className="form-group">
                  <div className="row">
                    <div className="col-md-12"
                      style={{ borderBottom: '1px solid #CCC', paddingTop: 1 }}></div>
                  </div>
                </div>

                <div className="form-group" style={{ marginBottom: 0 }}>
                  <div className="row">
                    <div className="col-md-12">
                      <h5 className="card-title">
                        Thời gian lưu trú <span className="text-danger">*</span>
                      </h5>
                    </div>
                  </div>
                </div>
                {this.renderProcessingTime()}
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-12"
                      style={{ borderBottom: '1px solid #CCC', paddingTop: 1 }}></div>
                  </div>
                </div>
                <div className="form-group" style={{ marginBottom: 0 }}>
                  <div className="row">
                    <div className="col-md-12">
                      <h5 className="card-title">Dịch vụ kèm theo</h5>
                    </div>
                  </div>
                </div>
                {this.renderExtraService()}
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-12">
                      <label>Ghi chú đơn hàng</label>
                      <textarea
                        disabled={true}
                        value={_.get(order, 'profile.description', '')}
                        rows="4"
                        cols="4"
                        placeholder=""
                        className="form-control"
                      ></textarea>
                    </div>
                  </div>

                </div>

              </form>
            </Tab>
            <Tab eventKey="tab2" title={`Tài liệu tham khảo (${this.state.documentCount})`}>
              {this.renderDocument()}
            </Tab>
            <Tab eventKey="tab3" title="Lịch sử thay đổi">
              {this.renderChatMessageHistory(order.history, 'history')}
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
    );
  }

  renderDownloadFile(files, type, model = {}) {
    try {
      const style = {
        backgroundColor: 'whitesmoke',
        paddingLeft: 10,
        marginBottom: 10,
        borderLeft: 'solid 3px darkgrey',
        height: '2.25003rem',
        cursor: 'pointer'
      };

      if (_.isEmpty(files) === true) {
        return (<div style={style} className="uniform-uploader" >
          <span className="form-text text-muted">None</span></div>);
      }
      const path = require('path');
      return (files.map((file, i) => {
        const link = `${this.props.downloadPath}${file}`;
        const fileExt = path.extname(link);
        const basename = path.basename(link);
        let fileExtClassName = '';
        switch (fileExt) {
          case '.docx':
          case '.doc':
            fileExtClassName = 'fa fa-file-word-o';
            break;

          default:
            fileExtClassName = 'fa fa-file-pdf-o';
            break;
        }
        return (
          <div className="uniform-uploader"
            key={`renderDownloadFile_${i}`}
            style={style}>
            <span className="form-text">
              <a onClick={() => window.open(link, '_blank')} >
                <i style={{ paddingRight: 10 }} className={fileExtClassName}></i>
                {basename}
              </a>

              {model.state === 'PENDING'
                ? <FontAwesomeIcon
                  onClick={this.onDeleteFile.bind(this, i, type)}
                  style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    right: 10,
                    top: 10
                  }}
                  icon={faTrash}
                /> : ''}

            </span>
          </div>
        );
      }));
    } catch (error) {
      return (<div></div>);
    }
  }

  renderChatMessageHistory(data, type, noMargin = false) {
    if (_.isEmpty(data) === true) {
      return (<div></div>);
    }
    const { order } = this.props;
    const ulStyles = {
      listStyle: 'none',
      margin: 0,
      padding: 0,
      maxHeight: 520,
      overflow: 'scroll'
    };
    const blackStar = {
      fontSize: 14, color: 'dimgrey', paddingRight: 10, cursor: 'pointer'
    };
    const yellowStar = {
      fontSize: 14, color: 'yellow', paddingRight: 10, cursor: 'pointer'
    };
    const titleSpan = { fontWeight: 'bold', color: 'dimgrey', paddingRight: 10 };
    const timeSpan = { fontStyle: 'italic', fontSize: 9, color: 'lightslategray' };
    const windowStyles = {};
    if (noMargin === false) {
      windowStyles.margin = '1rem auto';
    }

    if (type === 'process') {
      return (<div style={windowStyles}>
        <ul style={ulStyles}>

          {data.map((item, index) => {
            const liStyles = {
              backgroundColor: (index % 2 === 1) ? '#ddd' : '#efefef',
              padding: '0.6rem',
              borderBottom: '1px solid #ddd'
            };
            const isPin = _.get(item, 'isPin', false);
            return (<li key={`history_${index}`} style={liStyles}>
              <p style={{ marginBottom: 0, whiteSpace: 'pre-line' }}>
                <span
                  style={isPin ? yellowStar : blackStar}
                  onClick={this.setPin.bind(this, order.id, index, isPin)}
                >
                  <i className="fa fa-star" aria-hidden="true"></i>
                </span>
                <span style={titleSpan}>{item.account.title}</span>
                <span style={timeSpan}>{formatDateTimeChatBox(item.createdAt)}</span>
                <br />
                {item.note}
              </p>
            </li>);
          })}
        </ul>
      </div>);
    }

    if (type === 'history') {
      return (<div style={windowStyles}>
        <ul style={ulStyles}>
          {data.map((item, index) => {
            const liStyles = {
              backgroundColor: (index % 2 === 1) ? '#ddd' : '#efefef',
              padding: '0.6rem',
              borderBottom: '1px solid #ddd'
            };

            return (<li key={`history_${index}`} style={liStyles}>
              <p style={{ marginBottom: 0, whiteSpace: 'pre-line' }}>
                <span style={titleSpan}>{item.changedBy.title} </span>
                <span style={timeSpan}>{formatDateTimeChatBox(item.createdAt)}</span>
                <br />
                {item.description}

              </p>
            </li>);
          })}
        </ul>
      </div>);
    }

    if (type === 'deposit') {
      return (<div style={windowStyles}>
        <ul style={ulStyles}>
          {data.map((item, index) => {
            const liStyles = {
              backgroundColor: (index % 2 === 1) ? '#ddd' : '#efefef',
              padding: '0.6rem',
              borderBottom: '1px solid #ddd'
            };
            const isMinus = item.amount.toString().search('-') >= 0;
            return (<li key={`history_${index}`} style={liStyles}>
              <p style={{ marginBottom: 0, whiteSpace: 'pre-line' }}>
                <span style={titleSpan}>
                  {_.get(order, 'customer.title', '')}
                </span>
                <span style={timeSpan}>{formatDateTimeChatBox(item.createdAt)}</span>
                <br />
                {isMinus ? 'Hoàn cọc' : 'Đặt cọc'} : <span style={{ fontWeight: 'bold' }} className={isMinus ? 'text-danger' : 'text-success'}>{item.amount.toLocaleString()}</span>
                <br />{item.description}
                {item.changedBy ? <span style={{ ...timeSpan, paddingTop: 10 }}> Người thực hiện: {item.changedBy.title} </span> : ''}
              </p>
            </li>);
          })}
        </ul>
      </div>);
    }

    return (<div></div>);
  }

  renderProcess() {
    const { order } = this.props;
    return (<div className="form-group">
      <div className="row">
        <div className="col-md-12">
          {this.renderChatMessageHistory(order.process, 'process')}
          <textarea
            value={this.state.message}
            onChange={this.handleSendMessage}
            className="form-control"
            style={{ width: 'calc(100% - 100px)', float: 'left' }}
            type="text"
          ></textarea>
          <button
            onClick={this.handleBtnSendMessage}
            className="btn btn-info"
            style={{
              textTransform: 'uppercase',
              letterSpacing: '0.05em',
              fontWeight: 'bold',
              fontSize: '0.8em',
              width: 100,
              height: '3.8783rem'
            }}>Send</button>
        </div>
      </div>
    </div>);
  }

  render() {
    const { order } = this.props;
    const {
      model,
      supplierDefaultValue,
      dateDisplay,
      suppliers,
      customizeField
    } = this.state;
    const timeSpan = {
      paddingLeft: 10, fontSize: 11, color: 'lightslategray'
    };
    let customerName = _.get(order, 'profile.fullName', '');
    customerName = _.toUpper(customerName);
    return (
      <div className={
        this.state.displayForm === true
          ? cx('toggler', { 'toggler--form': this.state.displayForm })
          : cx('toggler', { 'toggler--wallet': this.state.displayWallet })
      }>

        <Tooltip
          placement="right"
          isOpen={this.state.tooltipOpen}
          target="TooltipExample"
          toggle={this.toggleTips}>
          <table border={0} style={{ width: 180 }}>
            <tbody>
              <tr>
                <td style={{ width: 65, textAlign: 'left' }}>Khả dụng <span style={{ float: 'right' }}>:</span></td>
                <td style={{ textAlign: 'right' }}>{_.get(this.props.wallet, 'amount', 0).toLocaleString()}</td>
              </tr>
              <tr>
                <td style={{ width: 65, textAlign: 'left' }}>Tiền mặt <span style={{ float: 'right' }}>:</span></td>
                <td style={{ textAlign: 'right' }}>{_.get(this.props.wallet, 'detail.cash', 0).toLocaleString()}</td>
              </tr>

              <tr>
                <td style={{ width: 65, textAlign: 'left' }}>Tín dụng <span style={{ float: 'right' }}>:</span></td>
                <td style={{ textAlign: 'right' }}>{_.get(this.props.wallet, 'detail.credit', 0).toLocaleString()}</td>
              </tr>

              <tr>
                <td style={{ width: 65, textAlign: 'left' }}>Hạn mức <span style={{ float: 'right' }}>:</span></td>
                <td style={{ textAlign: 'right' }}> {_.get(this.props.wallet, 'detail.creditLimit', 0).toLocaleString()}</td>
              </tr>
            </tbody>
          </table>
        </Tooltip>

        <div className="row">
          <div className="col-xl-12">
            <div
              style={{ borderColor: '#dfdfdf' }}
              className="alert bg-white alert-styled-left alert-arrow-left alert-dismissible">
              <h6 style={{ fontSize: 12 }} className="alert-heading mb-1">
            Khách hàng
              </h6>
              <div className="font-weight-semibold ">{customerName} ( { _.get(order, 'customer.title', '')} )</div>
            </div>
          </div>
        </div>
        <div className="row">
          {this.renderOrderInfo()}
          {this.renderEstimate()}
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card ">
              <div className="card-header header-elements-inline">
                <h5 className="card-title">
                  Thông tin hồ sơ
                  <span style={timeSpan}>({formatDateTime(order.createdAt)})</span>
                </h5>

              </div>
              <div className="card-body">
                <div className="form-group">
                  {this.renderProfiles()}
                  {this.renderImmigrationStateMofidy()}

                </div>
              </div>

            </div>

          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card ">
              <div className="card-header header-elements-inline">
                <h5 className="card-title">Tiến trình</h5>
              </div>
              <div className="card-body">
                {this.renderProcess()}

              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card ">
              <div className="card-header header-elements-inline">
                <h5 className="card-title">Thông tin bổ sung</h5>
                <div className="header-elements"></div>
              </div>
              <div className="card-body ">
                <em>{(_.isEmpty(customizeField) ? 'Không có thông tin cần bổ sung thêm' : '')}</em>
                {customizeField.map((v, k) => {
                  switch (v.type) {
                    case 'INPUT_TEXT':
                      return (
                        <div key={`customize_${v.key}`} className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-12">
                                  <label>{v.title} <span className="text-danger">{(v.require === true) ? '*' : ''}</span></label>
                                  <div className="form-group">
                                    <div className="input-group">
                                      <input
                                        ref={(input) => {
                                          this.input[v.key] = {
                                            name: v.key, title: v.title, isActive: true, required: v.require, e: input
                                          }; 
                                        }} 
                                        value={model.customizeField[v.key]}
                                        type="text"
                                        onChange={this.onChangeCustomizeFieldHandle.bind(this, v.key, v.type)}
                                        className="form-control"
                                        placeholder={v.placeHolder ? v.placeHolder : ''}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>);
                        
                    case 'INPUT_DATE':
                      return (
                        <div key={`customize_${v.key}`} className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-12">
                                  <label>{v.title} <span className="text-danger">{(v.require === true) ? '*' : ''}</span></label>
                                  <div className="form-group">
                                    <div className="input-group">
                                      <span className="input-group-prepend">
                                        <span className="input-group-text">
                                          <i className="icon-calendar"></i>
                                        </span>
                                      </span>
                                      <div className="multiselect-native-select">
                                        <InputMask
                                          ref={(input) => {
                                            this.input[v.key] = {
                                              name: v.key, title: v.title, isActive: true, required: v.require, e: input
                                            }; 
                                          }} 
                                          value={model.customizeField[v.key] || 'DD/MM/YYYY'}
                                          className="form-control"
                                          mask="99/99/9999"
                                          onChange={this.onChangeCustomizeFieldHandle.bind(this, v.key, v.type)}
                                        />

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );

                    case 'CHECKBOX':
                      return (
                        <div key={`customize_${v.key}`} className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-12">
                                  <label>{v.title} <span className="text-danger">{(v.require === true) ? '*' : ''}</span></label>
                                  <div className="form-group">
                                    {v.items.map((sv, sk) => {
                                      return (<div key={`customize_subcheck_${v.key}_${sk}`} className="form-check form-check-inline">
                                        <label className="form-check-label" title={v.title}>
                                          <div className="uniform-checker">
                                            {/* <span className={model.checkTest === true ? 'checked' : ''}> */}
                                            <span className={this.onCheckCustomizeFieldHandle(v.key, v.type, sv.value, v.defaultValue) === true ? 'checked' : ''}>
                                              <input
                                                value={sv.value}
                                                checked={this.onCheckCustomizeFieldHandle(v.key, v.type, sv.value, v.defaultValue) === true ? 'checked' : ''}
                                                onChange={this.onChangeCustomizeFieldHandle.bind(this, v.key, v.type)}
                                                type="checkbox"
                                                className="form-check-input-styled"
                                                data-fouc="true" />
                                            </span>
                                          </div>{sv.title}</label>
                                      </div>);
                                    })
                                    }
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );

                    case 'RADIO': 
                      return (
                        <div key={`customize_${v.key}`} className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-12">
                                  <label>{v.title} <span className="text-danger">{(v.require === true) ? '*' : ''}</span></label>
                                  <div className="form-group">
                                    {v.items.map((sv, sk) => {
                                      return (<div key={`customize_subradio_${v.key}_${sk}`} className="form-check">
                                        <label className="form-check-label">
                                          <div className="uniform-choice">
                                            <span className={this.onCheckCustomizeFieldHandle(v.key, v.type, sv.value) ? 'checked' : ''}>
                                              <input
                                                // checked={model.availability === 'customer'}
                                                onChange={this.onChangeCustomizeFieldHandle.bind(this, v.key, v.type)}
                                                value={sv.value}
                                                type="radio"
                                                name="availability"
                                                className="form-input-styled"
                                                data-fouc="true"
                                              />
                                            </span>
                                          </div>{sv.title}
                                        </label>
                                      </div>);
                                    })
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>);

                    case 'LISTBOX':
                      return (<div key={`customize_${v.key}`} className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-12">
                                <label>{v.title} <span className="text-danger">{(_.isEmpty(v.require) ? '*' : '')}</span></label>
                                <div className="form-group">
                                  <Select
                                    value={this.onCheckCustomizeFieldHandle(v.key, v.type, null, v.defaultValue)}
                                    onChange={this.onChangeCustomizeFieldHandle.bind(this, v.key, v.type)}
                                    options={this.onConvertDataSettingListbox(v.items)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>);
                    default:
                      break;
                  }
                  return true;
                })}
                
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card ">
              <div className="card-header header-elements-inline">
                <h5 className="card-title">Đơn hàng</h5>
              </div>
              <div className="card-body">
                {console.log(supplierDefaultValue, suppliers)}
                {(suppliers.length > 1 && _.includes(suppliers, supplierDefaultValue))
                  ? <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label>Đối tác xử lý</label>
                        <div className="form-group">
                          <Select
                            value={supplierDefaultValue}
                            onChange={this.onChangeHandle.bind(this, 'supplierId')}
                            options={suppliers}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <label>Giá đối tác thu</label>
                        <div className="form-group">
                          <div className="input-group">
                            <span className="input-group-prepend">
                              <span className="input-group-text">VND</span>
                            </span>
                            <div className="multiselect-native-select">
                              <Cleave
                                value={model.supplierAmount}
                                disabled={supplierDefaultValue.value === 0 ? 'disabled' : ''}
                                onChange={this.onChangeHandle.bind(this, 'supplierAmount')}
                                className="form-control input-numeral"
                                placeholder="Số tiền"
                                options={{
                                  numeral: true,
                                  numeralThousandsGroupStyle: 'thousand',
                                  numeralDecimalScale: 0
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div className="col-md-3">
                        <label>Ngày gửi đối tác</label>
                        <div className="form-group">
                          <div className="input-group">
                            <span className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="icon-calendar"></i>
                              </span>
                            </span>
                            <div className="multiselect-native-select">
                              <InputMask
                                disabled={supplierDefaultValue.value === 0 ? 'disabled' : ''}
                                value={dateDisplay.supplierSendAt}
                                className="form-control"
                                mask="99/99/9999"
                                onChange={this.onChangeHandle.bind(this, 'supplierSendAt')}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <label>Trạng thái thanh toán cho đối tác</label>
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              value={model.isPaidSupplier ? 'Đã thanh toán' : 'Chưa thanh toán'}
                              disabled
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                        
                  </div>
                  : '' }
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-3">
                      <label>VAT</label>
                      <div className="form-group">
                        <div className="input-group">
                          <div className="multiselect-native-select">
                            <input
                              value={model.vat}
                              onChange={this.onChangeHandle.bind(this, 'vat')}
                              type="text"
                              className="form-control"
                              placeholder="VAT"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>  
                </div>

                <form onSubmit={this.onSubmit}>
                  <div style={{ textAlign: 'center' }}>
                    <button
                      onClick={this.submit.bind(this, 'modify')}
                      className="btn btn-primary"
                      style={{
                        marginTop: 28,
                        height: 35,
                        width: 200,
                        fontWeight: 'bolder'
                      }}
                    >
                      <i className="fa fa-save"></i> Cập nhật đơn hàng
                    </button>

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <NotificationContainer />
      </div>

    );
  }

  componentDidUpdate(oldProps) {
    try {
      const newProps = this.props;
      if (oldProps.buildServices !== newProps.buildServices) {
        const parentIdentifies = this.props.listServiceHandleData();
        this.setState({ parentIdentifies: parentIdentifies.list });
      }
      if (oldProps.order !== newProps.order) {
        this.setModelOrder();
      }
      if (oldProps.suppliers !== newProps.suppliers) {
        const objSupplier = [];
        objSupplier.push(
          { value: 0, label: 'Không' },
        );
        newProps.suppliers.map((v) => {
          objSupplier.push(
            { value: v.id, label: v.title },
          );
          return true;
        });
        this.setState({
          suppliers: objSupplier
        });
      }
      if (oldProps.socketOrder !== newProps.socketOrder) {
        if (_.isFunction(this.callbackOrder)) {
          this.callbackOrder();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // callbackOrder() {
  //   try {
  //     const { order, socketOrder } = this.props;
  //     if (_.isEmpty(socketOrder) === false) {
  //       if (order.id === socketOrder.id) {
  //         this.setModelOrder(socketOrder);
  //         NotificationManager.warning('Đơn hàng vừa được cập nhật', '', 1500);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  setModelOrder(socketOrder) {
    try {
      const { buildServices, init } = this.props;
      if (_.isEmpty(buildServices) === false) {
        let { order } = this.props;

        if (socketOrder) {
          order = socketOrder;
        }

        const { suppliers } = this.state;
        const model = {
          supplierId: order.supplierId,
          isPaidSupplier: order.isPaidSupplier,
          supplierAmount: order.supplierAmount,
          paidSupplierAmount: order.paidSupplierAmount,
          vat: order.vat,
          supplierSendAt: order.supplierSendAt,
          customizeField: _.get(order, 'customizeField', {}),
          serviceIdentifyCode: order.serviceIdentifyCode,
          invoiceId: [order.id],
          state: order.state,
          amount: order.amount,
          description: '',
          profile: {
            fullName: _.get(order, 'profile.fullName', ''),
            firstName: _.get(order, 'profile.firstName', ''),
            lastName: _.get(order, 'profile.lastName', ''),
            birthday: _.get(order, 'profile.birthday', ''),
            sex: _.get(order, 'profile.sex', ''),
            passport: {
              number: _.get(order, 'profile.passport.number', '')
            },
            visa: {
              result: _.get(order, 'profile.visa.result', []),
              expiredAt: _.get(order, 'profile.visa.expiredAt', '')
            },
            extraInfo: _.get(order, 'profile.extraInfo', {}),
            files: _.get(order, 'profile.files', [])
          }
        };
        let supplierDefaultValue = _.find(suppliers, { value: order.supplierId });
        if (_.isUndefined(supplierDefaultValue) === true) {
          supplierDefaultValue = this.state.supplierDefaultValue;
        }

        const issuedAt = _.get(order, 'profile.passport.issuedAt', '');
        const visa = _.get(order, 'profile.visa', {});
        const dateDisplay = {
          arrivalAt: order.arrivalAt ? formatDate(order.arrivalAt) : 'DD/MM/YYYY',
          supplierSendAt: order.supplierSendAt ? formatDate(order.supplierSendAt) : 'DD/MM/YYYY',
          issuedAt: issuedAt !== '' ? formatDate(order.profile.passport.issuedAt) : 'DD/MM/YYYY',
          birthday: order.profile.birthday !== '' ? formatDate(order.profile.birthday) : 'DD/MM/YYYY'
        };

        let visaDateDisplay = 'DD/MM/YYYY';
        if (_.isEmpty(visa) === false) {
          visaDateDisplay = visa.expiredAt ? formatDate(visa.expiredAt) : 'DD/MM/YYYY';
        }

        /**
         * 
         */

        const parentIdentifies = this.props.listServiceHandleData();
        const service = this.props.getServiceDetail(order.serviceIdentifyCode);
        let customizeField = _.get(service, 'settings.customizeField', []);
        if (!_.isEmpty(customizeField)) {
          customizeField = JSON.parse(customizeField);
          customizeField.map((v) => {
            if (!model.customizeField[v.key] || _.isEmpty(model.customizeField[v.key])) {
              model.customizeField[v.key] = v.defaultValue;
            }
            return true;
          });
        }

        const countryArrivals = buildListCountryArrival(init);

        const countryArrival = _.find(countryArrivals, { value: _.get(order, 'profile.countryArrival') });
        const settings = buildSetting(_.get(service, 'settings', '{}'), { countryArrival }, service.identifyCode);

        const processingTime = _.get(settings, 'processingTime', {});
        const airport = _.get(settings, 'airport', {});
        const stay = _.get(settings, 'stay', {});
        const extraService = _.get(settings, 'extraService', {});
        const guarantee = _.get(settings, 'guarantee', {});
        const allowUpload = _.get(settings, 'allowUpload', {});

        let listPurposeArrival = [{ value: 'all', label: 'Chọn thời gian lưu trú' }];
        if (_.isEmpty(stay) === false) {
          listPurposeArrival = buildListPurposeArrival(stay, order.profile);
        }

        let listAirportArrival = [{ value: 'all', label: 'Chọn sân bay' }];
        if (_.isEmpty(airport) === false) {
          listAirportArrival = buildListAirportArrival(airport, order.profile);
        }

        let processingTimeArrivals = [];
        if (_.isEmpty(processingTime) === false) {
          processingTimeArrivals = buildListProcessingTimeArrival(processingTime);
        }

        let extraServiceArrivals = [];
        if (_.isEmpty(extraService) === false) {
          extraServiceArrivals = buildListExtraServiceArrival(extraService);
        }

        let isBussinessCase = false;
        if (service.identifyCode === '__aQsPSGF') {
          isBussinessCase = true;
        }

        let isVisitingCase = false;
        if (service.identifyCode === 'YhIeL9LMP') {
          isVisitingCase = true;
        }

        const states = [];

        service.states.forEach((element) => {
          states.push({
            label: element.title,
            value: element.state,
            color: element.color
          });
        });

        const processingTimeDefaultValue = _.find(processingTimeArrivals, { value: order.processingTime });
        const airportArrivalDefaultValue = _.find(listAirportArrival, { value: order.airport });
        const purposeArrivalDefaultValue = _.find(listPurposeArrival, { value: order.stay });
        const countryArrivalFeeDefaultValue = _.get(countryArrival, 'amount.arrival', 0);

        const isAllowUpload = _.get(allowUpload, 'active', false);
        const isAllowUploadDescription = _.get(allowUpload, 'description', '');
        const stateDefaultValue = _.find(service.stateList, { value: order.state });
        const documentCount = _.get(service, 'document', []).length;

        const estimateFee = {
          numberOfProfile: 1,
          serviceFee: purposeArrivalDefaultValue.amount,
          countryArrivalFees: _.get(countryArrival, 'amount.arrival', 0),
          processingTimeFee: processingTimeDefaultValue.amount,
          extraServiceFee: 0
        };

        if (_.isEmpty(order.extraService) === false) {
          _.forEach(order.extraService, (item) => {
            const extraServiceArrival = _.find(extraServiceArrivals, { value: Number(item) });
            estimateFee.extraServiceFee += (extraServiceArrival.amount || 0);
          });
        }
        this.setState({
          parentIdentifies: parentIdentifies.list,
          documentCount,
          model,
          dateDisplay,
          customizeField,
          states,
          stateDefaultValue,
          supplierDefaultValue,
          processingTimeDefaultValue,
          airportArrivalDefaultValue,
          purposeArrivalDefaultValue,
          countryArrivalFeeDefaultValue,
          countryArrival,
          extraServiceArrivals,
          processingTimeArrivals,
          listAirportArrival,
          listPurposeArrival,
          service,
          settings,
          estimateFee,
          visaDateDisplay,
          isBussinessCase,
          isVisitingCase,
          guarantee,
          isAllowUpload,
          isAllowUploadDescription,
          orderStatus: stateDefaultValue,
          profiles: order.profile,
          process: order.process
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      init: payload.generalReducer.init,
      order: payload.orderReducer.order,
      socketBalance: payload.walletReducer.socketBalance,
      wallet: payload.walletReducer.wallet,
      orderTags: payload.orderReducer.orderTags,
      buildServices: payload.serviceReducer.buildServices,
      agencies: payload.agencyReducer.agencies,
      agency: payload.agencyReducer.agency,
      accounts: payload.accountReducer.accounts,
      profiles: payload.generalReducer.profiles,
      userInfo: payload.generalReducer.userInfo,
      uploadPath: payload.generalReducer.uploadPath,
      downloadPath: payload.generalReducer.downloadPath,
      listScope: payload.generalReducer.listScope,
      socketOrder: payload.orderReducer.socketOrder,
      suppliers: payload.supplierReducer.suppliers,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(ModifyImmigrationOrder),
);
