
/**
 * CRUD
 */
export const POST_SEARCH_DEPARTMENT = 'POST_SEARCH_DEPARTMENT';
export const POST_SEARCH_DEPARTMENT_SUCCESS = 'POST_SEARCH_DEPARTMENT_SUCCESS';
export const POST_SEARCH_DEPARTMENT_FAIL = 'POST_SEARCH_DEPARTMENT_FAIL';

export const GET_DEPARTMENT = 'GET_DEPARTMENT';
export const GET_DEPARTMENT_SUCCESS = 'GET_DEPARTMENT_SUCCESS';
export const GET_DEPARTMENT_FAIL = 'GET_DEPARTMENT_FAIL';

export const POST_DEPARTMENT = 'POST_DEPARTMENT';
export const POST_DEPARTMENT_SUCCESS = 'POST_DEPARTMENT_SUCCESS';
export const POST_DEPARTMENT_FAIL = 'POST_DEPARTMENT_FAIL';

export const PUT_DEPARTMENT = 'PUT_DEPARTMENT';
export const PUT_DEPARTMENT_SUCCESS = 'PUT_DEPARTMENT_SUCCESS';
export const PUT_DEPARTMENT_FAIL = 'PUT_DEPARTMENT_FAIL';

export const DELETE_DEPARTMENT = 'DELETE_DEPARTMENT';
export const DELETE_DEPARTMENT_SUCCESS = 'DELETE_DEPARTMENT_SUCCESS';
export const DELETE_DEPARTMENT_FAIL = 'DELETE_DEPARTMENT_FAIL';

