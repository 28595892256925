/**
 * GENERAL
 */
export const POST_PROFILE_SEARCH_SUCCESS = 1000;
export const POST_ACCOUNT_MENU_SUCCESS = 1000;
export const POST_ACCOUNT_CHANGE_PASS_SUCCESS = 1000;
export const POST_ACCOUNT_INFO_SUCCESS = 1000;
export const POST_ACCOUNT_LOGIN_SUCCESS = 1000;
export const POST_ACCOUNT_LOGOUT_SUCCESS = 1000;
export const POST_ACCOUNT_UPDATE_INFO_SUCCESS = 1000;
export const GET_SCOPE_SUCCESS = 1000;
export const GET_INIT_SUCCESS = 1000;
export const PUT_COUNTRY_ARRIVAL_SUCCESS = 1000;

/**
 * SERVICE
 */
export const GET_BUILD_SERVICE_SUCCESS = 1000;
export const GET_SERVICE_SUCCESS = 1000;
export const POST_SERVICE_SUCCESS = 1000;
export const PUT_SERVICE_SUCCESS = 1000;
export const DELETE_SERVICE_SUCCESS = 1000;

/**
 * BRANCH
 */
export const POST_SEARCH_LIST_BRANCH_SUCCESS = 1000;
export const POST_SEARCH_BRANCH_SUCCESS = 1000;
export const POST_BRANCH_DETAIL_SUCCESS = 1000;
export const GET_BRANCH_SUCCESS = 1000;
export const POST_BRANCH_SUCCESS = 1000;
export const PUT_BRANCH_SUCCESS = 1000;
export const DELETE_BRANCH_SUCCESS = 1000;

/**
 * DEPARTMENT
 */
export const POST_SEARCH_LIST_DEPARTMENT_SUCCESS = 1000;
export const POST_SEARCH_DEPARTMENT_SUCCESS = 1000;
export const POST_DEPARTMENT_DETAIL_SUCCESS = 1000;
export const GET_DEPARTMENT_SUCCESS = 1000;
export const POST_DEPARTMENT_SUCCESS = 1000;
export const PUT_DEPARTMENT_SUCCESS = 1000;
export const DELETE_DEPARTMENT_SUCCESS = 1000;

/**
 * ACCOUNT
 */
export const POST_SEARCH_MEMBER_SUCCESS = 1000;
export const POST_SEARCH_ACCOUNT_SUCCESS = 1000;
export const POST_SEARCH_BY_KEYWORD_ACCOUNT_SUCCESS = 1000;
export const POST_ACCOUNT_DETAIL_SUCCESS = 1000;
export const POST_ACCOUNT_SUCCESS = 1000;
export const PUT_ACCOUNT_SUCCESS = 1000;
export const PUT_ACCOUNT_PROFILE_SUCCESS = 1000;
/**
 * ORDER
 */
export const POST_ORDER_TAG_SEARCH_SUCCESS = 1000;
export const POST_SEARCH_SIMPLE_ORDER_SUCCESS = 1000;
export const POST_SEARCH_ORDER_SUCCESS = 1000;
export const POST_ORDER_DETAIL_SUCCESS = 1000;
export const POST_ORDER_SUCCESS = 1000;
export const PUT_ORDER_SUCCESS = 1000;
export const PUT_ORDER_STATE_SUCCESS = 1000;
export const PUT_ORDER_PROCESS_SUCCESS = 1000;
export const PUT_ORDER_PIN_SUCCESS = 1000;
/**
 * ARRIVAL_ORDER
 */
export const POST_ARRIVAL_ORDER_SUCCESS = 1000;
export const PUT_ARRIVAL_ORDER_SUCCESS = 1000;

/**
 * AGENCY
 */
export const POST_SEARCH_AGENCY_SUCCESS = 1000;
export const POST_SEARCH_BY_KEYWORD_AGENCY_SUCCESS = 1000;
export const POST_AGENCY_DETAIL_SUCCESS = 1000;
export const POST_AGENCY_SUCCESS = 1000;
export const PUT_AGENCY_SUCCESS = 1000;

/**
 * SUPPLIER
 */
export const POST_SEARCH_SUPPLIER_SUCCESS = 1000;
export const POST_SEARCH_BY_KEYWORD_SUPPLIER_SUCCESS = 1000;
export const POST_SUPPLIER_DETAIL_SUCCESS = 1000;
export const POST_SUPPLIER_SUCCESS = 1000;
export const PUT_SUPPLIER_SUCCESS = 1000;

/**
 * 
 */
export const POST_SEARCH_WALLET_DETAIL_SUCCESS = 1000;
export const POST_SEARCH_WALLET_SUCCESS = 1000;
export const POST_WALLET_HISTORY_SUCCESS = 1000;
export const POST_WALLET_HISTORY_CREDIT_SUCCESS = 1000;
export const PUT_WALLET_CHANGE_SUCCESS = 1000;

/**
 * CALLLOGS
 */

export const POST_SEARCH_PICKUP_CALL_LOG_SUCCESS = 1000;
export const POST_SEARCH_CALL_LOG_DETAIL_SUCCESS = 1000;
export const POST_SEARCH_CALL_LOG_SUCCESS = 1000;
export const POST_CLICK_TO_CALL_SUCCESS = 1000;
export const PUT_CALL_LOG_SUCCESS = 1000;

/**
 * SUPPORT
 */
export const POST_SEARCH_CUSTOMER_SUPPORT_BY_CUSTOMER_TYPE_SUCCESS = 1000;
export const POST_SEARCH_CUSTOMER_SUPPORT_SUCCESS = 1000;
export const POST_SEARCH_CUSTOMER_SUPPORT_DETAIL_SUCCESS = 1000;
export const POST_CUSTOMER_SUPPORT_SUCCESS = 1000;
export const PUT_CUSTOMER_SUPPORT_SUCCESS = 1000;
export const DELETE_CUSTOMER_SUPPORT_SUCCESS = 1000;

/**
 * Utility
 */
export const POST_UTILITY_SEND_SMS_SUCCESS = 1000;
export const POST_UTILITY_SEND_MAIL_SUCCESS = 1000;

/**
 * Link
 */
export const POST_LINK_DEACTIVE_SUCCESS = 1000;
export const POST_LINK_GETINFO_SUCCESS = 1000;

/* Error 401 */
export const VALID_ACCESS_TOKEN = 401;

export const SUCCEEDED = 1000;
