/* eslint-disable react/jsx-no-undef */
import React from 'react';
import { Tooltip } from 'reactstrap';
import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import Select2 from 'react-select2-wrapper';

import { DelayInput } from 'react-delay-input';
import Autocomplete from '@celebryts/react-autocomplete-tags';
import cx from 'classnames';

import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/vi';

import InputMask from 'react-input-mask';

import { Tabs, Tab } from 'react-bootstrap';

import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Cleave from 'cleave.js/react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import SelectStateContainer from '../../ultils/selectState.container';
import { TAG_STYLE, WALLET_SCOPE } from '../../../configs/constants';
import UploadFileContainer from '../../ultils/uploadFile.container';

import {
  formatDate, formatDateTimeChatBox,
  checkScope, fileUpload, subscribe, isStaff
} from '../../../helpers';

const diff = require('deep-object-diff').diff;

moment.locale('vi');
const diffMonthDuration = '9'; // 9 month

class ModifyOrder extends React.Component {
  constructor(props) {
    super(props);
    this.input = {};
    this.state = {
      displayWallet: false,
      displayForm: false,
      deposit: [0],
      documentCount: 0,
      typeOfSubmit: false,
      tooltipOpen: false,
      expireAtWarning: false,
      orderStatus: false,
      selectedFile: [],
      nationalDefaultValue: {},
      stateDefaultValue: {},
      sexDefaultValue: {},
      supplierDefaultValue: { value: 0, label: 'Không' },
      amountTypeDefaultValue: { value: 'deposit', label: 'Thanh toán' },
      value: '',
      predictions: [],
      delegatesDefaultValue: [],
      //
      profiles: [],
      showContextMenu: {
        display: false,
        style: {}
      },
      isDisable: true,
      dateDisplay: {
        birthday: 'DD/MM/YYYY',
        issuedAt: 'DD/MM/YYYY',
        expiredAt: 'DD/MM/YYYY',
        estimatedAt: 'DD/MM/YYYY',
        supplierSendAt: 'DD/MM/YYYY'
      },
      orderInfo: {},
      model: {
        customizeField: {},
        supplierId: null,
        supplierAmount: 0,
        paidSupplierAmount: 0,
        vat: '',
        supplierSendAt: '',
        id: '',
        tag: [],
        serviceIdentifyCode: '',
        description: '',
        state: '',
        estimatedAt: '',
        depositHistory: [],
        amount: 0,
        deposit: {
          amount: 0,
          description: '',
          type: 'deposit'
        },
        profile: {
          firstName: '',
          lastName: '',
          fullName: '',
          sex: '',
          birthday: '',
          national: '',
          placeOfBirth: '',
          identifyCard: '',
          passport: {
            type: '',
            number: '',
            placeOfIssue: '',
            issuedAt: '',
            expiredAt: ''
          },

          address: '',
          phone: '',
          email: '',
          description: '',
          files: []
        },
        delegates: []
      },

      genders: [
        { value: 'male', label: 'Nam' },
        { value: 'female', label: 'Nữ' },
        { value: 'other', label: 'Khác' }
      ],
      amountTypes: [
        { value: 'deposit', label: 'Thanh toán' },
        { value: 'refund', label: 'Hoàn tiền' }
      ],

      contries: [],
      states: [],
      parentIdentifies: [],
      tagSuggestions: [],
      unLinkFile: [],
      suppliers: [],
      message: '',
      customizeField: [],
      process: []
    };
    this.removeUploadedFile = this.removeUploadedFile.bind(this);
    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.onUploadFile = this.onUploadFile.bind(this);
    //
    this.handleBtnSendMessage = this.handleBtnSendMessage.bind(this);
    this.handleSendMessage = this.handleSendMessage.bind(this);
    this.toggleTips = this.toggleTips.bind(this);
    this.checkActiveOrder = this.checkActiveOrder.bind(this);
    //
    this.setFiles = this.setFiles.bind(this);
    this.callbackWallet = this.callbackWallet.bind(this);
    this.callbackOrder = this.callbackOrder.bind(this);
    //
    this.updateOrder = this.updateOrder.bind(this);
    this.setModelOrder = this.setModelOrder.bind(this);
    //
  }

  callbackWallet() {
    try {
      const { wallet, socketBalance } = this.props;
      if (_.isEmpty(socketBalance) === false) {
        if (wallet.accountId === socketBalance.accountId && wallet !== socketBalance) {
          this.props.dispatch({
            type: 'POST_SEARCH_WALLET_DETAIL_SUCCESS',
            payload: socketBalance
          });
          this.toggleWallet();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  callbackOrder() {
    try {
      const { order, socketOrder } = this.props;
      if (_.isEmpty(socketOrder) === false) {
        if (order.id === socketOrder.id) {
          this.setModelOrder(socketOrder);
          NotificationManager.warning('Đơn hàng vừa được cập nhật', '', 1500);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  updateOrder(data) {
    if (_.isEmpty(data) === false) {
      this.props.dispatch({
        type: 'POST_ORDER_DETAIL_SUCCESS',
        payload: data
      });
    }
  }

  setFiles(selectedFile) {
    try {
      this.setState({ selectedFile });
    } catch (error) {
      console.log(error);
    }
  }

  setPin(orderId, index, status, e) {
    if (index >= 0) {
      const process = Object.assign([], this.state.process);
      process[index].isPin = !status;
      this.props.onSetPin(orderId, index, !status);
      this.setState({ process });
    }
  }

  toggleTips() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  handleBtnSendMessage(e) {
    e.preventDefault();
    const { model, message, process } = this.state;
    const { init } = this.props;
    if (message) {
      process.push({
        account: {
          id: init.accountInfo.id,
          title: init.accountInfo.title
        },
        note: message,
        createdAt: new Date().toISOString()
      });

      this.setState({ message: '', process });
      this.props.onSendMessage(message, model.serviceIdentifyCode);
    }
  }

  handleSendMessage(e) {
    e.preventDefault();
    const message = _.get(e, 'target.value', _.get(e, 'value', false));
    if (message) {
      this.setState({ message });
    }
  }

  async validateCustomizeFieldOnSubmit(model) {
    return new Promise((resolve, reject) => {
      const customizeField = this.state.customizeField;
      let flag = true;
      customizeField.map((field) => {
        if (field.require === true) {
          if (_.isArray(model.customizeField[field.key])) {
            console.log(model.customizeField[field.key]);
            if (_.get(model.customizeField[field.key], '1', '') === '') {
              NotificationManager.error(`${field.title} không được để trống`, '', 1500);
              flag = false;
            }
          } else if (!model.customizeField[field.key] || _.isEmpty(model.customizeField[field.key])) {
            NotificationManager.error(`${field.title} không được để trống`, '', 1500);
            flag = false;
          }
        }
        return true;
      });
      resolve(flag);
    });
  }

  async validateInputOnSubmit() {
    return new Promise((resolve, reject) => {
      const input = this.input;
      let flag = true;
      _.forEach(input, (field) => {
        if (flag === false) return false;
        if (field.isActive === true) {
          if (field.required === true) {
            if (_.get(field, 'e.value', '') === '') {
              NotificationManager.error(`${field.title} không được để trống!`, '', 1500);
              field.e.focus();
              flag = false;
            }
          }
        }
        return true;
      });
      resolve(flag);
    });
  }

  onSubmit = async (e) => {
    try {
      e.preventDefault();
      // SHOW LOADING
      this.props.dispatch({ type: 'SHOW_LOADING' });

      const { model } = this.state;
      const validateInputOnSubmit = await this.validateInputOnSubmit();
      if (validateInputOnSubmit === false) return;
      const validateCustomizeField = await this.validateCustomizeFieldOnSubmit(model);
      const validate = this.validateOnSubmit(model);
      if (validateCustomizeField === true && validate === true) {
        const error = false;
        const fullname = _.get(model, 'profile.fullName', false);
        const fullnameArr = fullname.split(' ');
        if (fullnameArr.lenght <= 1) {
          NotificationManager.error('Vui lòng nhập đầy đủ họ tên', '', 1500);
        }

        //
        const files = await fileUpload(this.state.selectedFile, this.props);
        if (files.code === 1) {
          _.forEach(files.data, (file) => {
            model.profile.files.push(file);
          });
        }

        model.profile.lastName = fullnameArr[0];
        delete fullnameArr[0];
        model.profile.firstName = fullnameArr.join(' ');
        if (error === false) {
          this.props.onSubmit(model, this.state.typeOfSubmit);
        }
      }
      // HIDE LOADING
      // this.props.dispatch({ type: 'HIDE_LOADING' });
    } catch (error) {
      // HIDE LOADING
      this.props.dispatch({ type: 'HIDE_LOADING' });
      /**
       * 
       */
    } finally {
      // this.props.dispatch({ type: 'HIDE_LOADING' });
    }
  }

  submit(key, e) {
    this.setState({ typeOfSubmit: key });
  }

  validateOnSubmit() {
    const { model } = this.state;
    const { order } = this.props;

    if (this.checkActiveOrder() === false) {
      NotificationManager.error('Thông tin không hợp lệ, đơn hàng đã giải quyết trước đó', '', 1500);
      return false;
    }

    if (!model.serviceIdentifyCode) {
      NotificationManager.error('Loại dịch vụ không được để trống', '', 1500);
      return false;
    }

    const fullname = _.get(model, 'profile.fullName', false);
    if (!fullname) {
      NotificationManager.error('Họ tên không được để trống', '', 1500);
      return false;
    }

    const email = _.get(model, 'profile.email', false);

    if (email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) === false) {
      NotificationManager.error('Email không hợp lệ', '', 1500);
      return false;
    }

    if (!model.state) {
      NotificationManager.error('Trạng thái không được để trống', '', 1500);
      return false;
    }

    return true;
  }

  checkActiveOrder() {
    const { order } = this.props;
    const { orderStatus } = this.state;
    if (_.isEmpty(orderStatus) === true) {
      return false;
    }
    const defaultState = _.get(orderStatus, 'state', false);
    if (defaultState.isFinish === true) {
      return false;
    }
    return true;
  }

  /**
   * Remove files
   */

  removeUploadedFile(index, e) {
    try {
      const model = Object.assign({}, this.state.model);
      const files = _.get(model, 'profile.files', false);
      if (files !== false) {
        // delete model.contract[index];
        model.profile.files.splice(model.profile.files, 1);
        this.setState({ model });
      } else {
        NotificationManager.error('Thông tin không hợp lệ', '', 3000);
      }
    } catch (error) {
      // console.log(error);
    }
  }

  /**
   * Upload
   */

  onUploadFile(key, e) {
    try {
      const files = _.get(e, 'target.files', false);
      console.log(files);
      if (_.isEmpty(files) === false) {
        console.log(files);
        this.setState({ selectedFile: files });
        this.fileUpload(files, key);
      }
    } catch (error) {
      console.log(error);
    }
  }

  fileUpload(files, type) {
    try {
      const { init, userInfo } = this.props;
      const data = new FormData();
      data.append('file', files[0]);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: userInfo.accessToken,
          accessToken: userInfo.accessToken
        }
      };

      axios.post(`${this.props.uploadPath}/upload-contract`, data, config).then((res) => {
        const code = _.get(res, 'data.code', false);
        if (code === 100) {
          const model = Object.assign({}, this.state.model);
          model.profile.files[0] = res.data.data.message.pathname;
          const selectedFileName = res.data.data.message.filename;
          this.setState({ model, selectedFileName });
        } else {
          const message = _.get(res, 'data.data.message.message', 'File không hợp lệ');
          NotificationManager.error(message, '', 3000);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * 
   */
  setProfile(data) {
    if (data) {
      const { genders } = this.state;
      const model = Object.assign({}, this.state.model);
      const dateDisplay = Object.assign({}, this.state.dateDisplay);
      model.profile.address = data.address;
      model.profile.birthday = data.birthday;
      model.profile.email = data.email;
      model.profile.firstName = data.firstName;
      model.profile.lastName = data.lastName;
      model.profile.fullName = data.fullName;
      model.profile.identifyCard = data.identifyCard;
      model.profile.national = data.national;
      model.profile.passport = data.passport;
      model.profile.phone = data.phone;
      model.profile.placeOfBirth = data.placeOfBirth;
      model.profile.sex = data.sex;

      const sexDefaultValue = _.find(genders, { value: data.sex });
      const nationalDefaultValue = _.find(this.props.buildCountry(), { value: data.national });

      dateDisplay.birthday = formatDate(data.birthday);
      dateDisplay.expiredAt = formatDate(data.passport.expiredAt);
      dateDisplay.issuedAt = formatDate(data.passport.issuedAt);

      const showContextMenu = { display: false };
      this.setState({
        showContextMenu, model, dateDisplay, sexDefaultValue, nationalDefaultValue
      });
    }
  }

  /**
   * 
   */
  onConvertDataSettingListbox(items) {
    if (_.isArray(items) === false) {
      return {
        label: items.title,
        value: items.value
      };
    }
    const result = [];
    items.map((v) => {
      result.push({
        label: v.title,
        value: v.value
      });
      return true;
    });
    return result;
  }

  onCheckCustomizeFieldHandle(key, type, value) {
    const model = Object.assign({}, this.state.model);
    if (type === 'CHECKBOX') {
      const currentValue = _.get(model, `customizeField.${key}`, []);
      if (_.includes(currentValue, value)) {
        return true;
      }
      return false;
    }
    if (type === 'RADIO') {
      const currentValue = _.get(model, `customizeField.${key}`, '');
      if (currentValue === value) {
        return true;
      }
    }
    if (type === 'LISTBOX') {
      const currentValue = _.get(model, `customizeField.${key}`, '');
      const control = _.find(this.state.customizeField, { key });
      if (_.isUndefined(control)) return false;
      const items = this.onConvertDataSettingListbox(control.items);
      const defaultValue = _.find(items, { value: currentValue });
      if (_.isUndefined(defaultValue)) return null;
      return defaultValue;
    }
    return false;
  }

  onChangeCustomizeFieldHandle(key, type, e) {
    const model = Object.assign({}, this.state.model);
    const customizeField = this.state.customizeField;
    if (type === 'RADIO') {
      const code = e.target.value;
      model.customizeField[key] = code;
    }
    if (type === 'CHECKBOX') {
      const code = e.target.value;
      let currentValue = _.get(model, `customizeField.${key}`, []);
      if (e.target.checked === true) {
        currentValue.push(code);
        currentValue = _.uniq(currentValue);
      } else {
        currentValue = _.remove(currentValue, v => (v !== code));
      }
      model.customizeField[key] = currentValue;
    }
    if (type === 'INPUT_TEXT' || type === 'INPUT_DATE') {
      const code = e.target.value;
      model.customizeField[key] = code;
    }
    if (type === 'LISTBOX') {
      const value = _.get(e, 'target.value', _.get(e, 'value', false));
      model.customizeField[key] = value;
    }
    const result = {};
    result.model = model;
    this.setState(result);
  }

  onChangeHandle(key, e) {
    try {
      const isActive = this.checkActiveOrder();
      if (isActive === true) {
        const {
          branchScopes, buildServices, wallet, order
        } = this.props;
        const {
          isDisable, genders, delegates, parentIdentifies, suppliers, amountTypes, amountTypeDefaultValue
        } = this.state;

        const model = Object.assign({}, this.state.model);
        const result = {};

        if (_.isArray(e) === true) {
          switch (key) {
            case 'delegates': {
              const temps = [];
              const delegatesDefaultValue = [];
              e.forEach((element) => {
                const delegateInfo = _.find(delegates, { value: element.value });
                temps.push({
                  identifyCode: delegateInfo.value,
                  description: ''
                });
                delegatesDefaultValue.push(delegateInfo);
              });

              result.delegatesDefaultValue = delegatesDefaultValue;
              model[key] = temps;
              break;
            }

            default: {
              const temp = [];
              e.forEach((element) => {
                temp.push(_.get(element, 'target.value', _.get(element, 'value', false)));
              });
              model[key] = temp;
              break;
            }
          }
        }
        if (_.isArray(e) === false) {
          switch (key) {
            case 'supplierId': {
              const value = _.get(e, 'target.value', _.get(e, 'value', false));
              model.supplierId = value;
              if (model.supplierId === 0) {
                model.supplierAmount = 0;
                model.paidSupplierAmount = 0;
                model.supplierSendAt = null;
                this.state.dateDisplay.supplierSendAt = 'DD/MM/YYYY';
              }
              const supplierDefaultValue = _.find(suppliers, { value });
              result.supplierDefaultValue = supplierDefaultValue;
              break;
            }
            case 'supplierAmount': {
              const amount = _.get(e, 'target.rawValue', _.get(e, 'value', 0));
              model[key] = Number(amount);
              break;
            }
            case 'paidSupplierAmount': {
              const amount = _.get(e, 'target.rawValue', _.get(e, 'value', 0));
              model[key] = Number(amount);
              break;
            }
            case 'tag': {
              const tag = _.get(e, 'label', false);
              model[key] = this.tags.state.tags;
              if (tag) { model[key].push(tag); }
              model[key] = _.uniq(model[key]);
              break;
            }
            case 'state': {
              const warning = _.get(e, 'state.warning', false);
              if (warning) {
                confirmAlert({
                  title: 'Xác nhật cập nhật trạng thái',
                  message: warning,
                  buttons: [{ label: 'Đóng' }]
                });
              }
              model[key] = _.get(e, 'target.value', _.get(e, 'value', false));
              result.stateDefaultValue = e;
              break;
            }
            case 'amount': {
              const amount = _.get(e, 'target.rawValue', _.get(e, 'value', 0));
              model[key] = Number(amount);
              break;
            }
            case 'deposit': {
              const deposit = Number(_.get(e, 'target.rawValue', _.get(e, 'value', 0)));
              model.deposit.amount = deposit;

              if (amountTypeDefaultValue.value === 'deposit') {
                if (deposit > wallet.amount) {
                  model.deposit.amount = wallet.amount;
                  NotificationManager.error('Số tiền cọc không được lớn hơn số dư khả dụng', '', 1500);
                }
              } else {
                const totalDeposit = _.sumBy(_.get(order, 'deposit', 0), 'amount');
                if (deposit > totalDeposit) {
                  model.deposit.amount = totalDeposit;
                  NotificationManager.error('Số tiền hoàn không được lớn hơn số tiền đã thanh toán', '', 1500);
                }
              }

              break;
            }
            case 'depositDescription': {
              const deposit = _.get(e, 'target.value', _.get(e, 'value', 0));
              model.deposit.description = (deposit);
              break;
            }
            case 'sex': {
              const value = _.get(e, 'target.value', _.get(e, 'value', false));
              const sexDefaultValue = _.find(genders, { value });
              model.profile[key] = value;
              result.sexDefaultValue = sexDefaultValue;
              break;
            }
            case 'depositType': {
              const value = _.get(e, 'target.value', _.get(e, 'value', false));
              model.deposit.type = value;
              result.amountTypeDefaultValue = _.find(amountTypes, { value });
              break;
            }
            case 'serviceIdentifyCode': {
              const value = _.get(e, 'target.value', _.get(e, 'value', 0));
              const service = this.props.getServiceDetail(value);
              const documentCount = _.get(service, 'document', []).length;
              const states = [];
              parentIdentifies.forEach((items) => {
                const item = _.find(items.children, { id: value });
                const stateIndex = _.get(this.state.stateDefaultValue, 'state.index', 0);
                const hasOrderFinish = checkScope('order.finish', this.props);
                if (_.isEmpty(item) === false) {
                  item.states.forEach((element) => {
                    if (element.index >= stateIndex) {
                      if (element.state === 'DONE') {
                        if (hasOrderFinish === true) {
                          states.push({
                            label: element.title,
                            value: element.state,
                            state: element,
                            color: element.color
                          });
                        }
                      } else {
                        states.push({
                          label: element.title,
                          value: element.state,
                          state: element,
                          color: element.color
                        });
                      }
                    }
                  });
                  let customizeField = _.get(item, 'settings.customizeField', []);
                  if (!_.isEmpty(customizeField)) {
                    customizeField = JSON.parse(customizeField);
                    customizeField.map((v) => {
                      if (!model.customizeField[v.key] || _.isEmpty(model.customizeField[v.key])) {
                        model.customizeField[v.key] = v.defaultValue;
                      }
                      return true;
                    });
                  }
                  result.customizeField = customizeField;
                }
              });

              if (this.state.model.serviceIdentifyCode !== value) {
                result.stateDefaultValue = states[0];
              }

              result.documentCount = documentCount;
              result.states = states;
              result.isDisable = false;
              model[key] = value;
              break;
            }
            case 'fullName': {
              const fullName = _.get(e, 'target.value', _.get(e, 'value', false));
              model.profile[key] = fullName.toUpperCase();
              break;
            }
            case 'national':
            case 'placeOfBirth':
            case 'identifyCard':
            case 'address':
            case 'phone':
            case 'email': {
              model.profile[key] = _.get(e, 'target.value', _.get(e, 'value', false));
              break;
            }
            case 'profileDescription': {
              model.profile.description = _.get(e, 'target.value', _.get(e, 'value', false));
              break;
            }
            case 'description': {
              model[key] = _.get(e, 'target.value', _.get(e, 'value', false));
              break;
            }
            case 'type':
            case 'placeOfIssue': {
              model.profile.passport.placeOfIssue = _.get(e, 'target.value', _.get(e, 'value', false));
              break;
            }
            case 'birthday':
            case 'estimatedAt':
            case 'expiredAt':
            case 'supplierSendAt':
            case 'issuedAt': {
              const value = _.get(e, 'target.value', _.get(e, 'value', false));
              const dateFormat = 'YYYY/MM/DD';
              const splittedDate = value.split('/');

              if (splittedDate.length === 3) {
                const date = moment(`${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`, dateFormat);
                const dateDisplay = Object.assign({}, this.state.dateDisplay);
                dateDisplay[key] = value;
                result.dateDisplay = dateDisplay;
                if (date.isValid() === true) {
                  if (key === 'estimatedAt') {
                    model.estimatedAt = date.format();
                  }
                  if (key === 'supplierSendAt') {
                    model.supplierSendAt = date.format();
                  }
                  if (key === 'birthday') {
                    model.profile.birthday = date.format();
                  }
                  if (key === 'issuedAt' || key === 'expiredAt') {
                    model.profile.passport[key] = date.format();
                  }

                  const yearNumber = Number(splittedDate[2]);
                  if (key === 'expiredAt' && _.isNaN(yearNumber) === false) {
                    const previousMonth = date.subtract(diffMonthDuration, 'months');
                    const monthDuration = moment().diff(previousMonth, 'months');
                    result.expireAtWarning = false;
                    if (monthDuration >= diffMonthDuration) {
                      result.expireAtWarning = 'Hộ chiếu đã hết hạn';
                    } else if (monthDuration > 0) {
                      result.expireAtWarning = 'Hộ chiếu gần hết hạn';
                    }
                  }
                }
              }

              break;
            }

            case 'passportNumber': {
              const value = _.get(e, 'target.value', _.get(e, 'value', false));
              this.props.getProfile(value);
              model.profile.passport.number = value;
              break;
            }
            default:
              model[key] = _.get(e, 'target.value', _.get(e, 'value', false));
              break;
          }
        }
        result.model = model;
        this.setState(result);
      }
    } catch (error) {
      // console.log(error);
    }
  }

  renderDownloadFile() {
    try {
      const { model } = this.state;
      const files = _.get(model, 'profile.files', false);

      const style = {
        backgroundColor: 'whitesmoke',
        paddingLeft: 20,
        marginBottom: 10,
        borderLeft: 'solid 3px darkgrey',
        height: '2.25003rem'
      };

      if (_.isEmpty(files) === true) {
        return (<div style={style} className="uniform-uploader" >
          <span className="form-text text-muted">None</span></div>);
      }
      const path = require('path');
      return (files.map((file, i) => {
        const link = `${this.props.downloadPath}${file}`;
        const fileExt = path.extname(link);
        const basename = path.basename(link);
        let fileExtClassName = '';
        switch (fileExt) {
          case '.docx':
          case '.doc':
            fileExtClassName = 'fa fa-file-word-o';
            break;

          default:
            fileExtClassName = 'fa fa-file-pdf-o';
            break;
        }
        return (
          <div className="uniform-uploader"
            key={`renderDownloadFile_${i}`}
            style={style}>
            <span className="form-text text-muted">
              <a target="_blank" href={link}>
                <i style={{ paddingRight: 10 }} className={fileExtClassName}></i>
                {basename}
              </a>
            </span>
            <i
              onClick={this.removeUploadedFile.bind(this, i)}
              style={{
                position: 'absolute', right: 20, top: 10, cursor: 'pointer'
              }}
              className="fa fa-remove"></i>
          </div>
        );
      }));
    } catch (error) {
      return (<div></div>);
    }
  }

  renderChatMessage() {
    return (
      <p style={{ marginBottom: 0 }}>
        {this.props.message}<br />
        <small>{this.props.timestamp}</small>
      </p>
    );
  }

  renderChatMessageHistory(data, type, noMargin = false) {
    if (_.isEmpty(data) === true) {
      return (<div></div>);
    }
    const { order } = this.props;
    const ulStyles = {
      listStyle: 'none',
      margin: 0,
      padding: 0,
      maxHeight: 520,
      overflow: 'scroll'
    };
    const blackStar = {
      fontSize: 14, color: 'dimgrey', paddingRight: 10, cursor: 'pointer'
    };
    const yellowStar = {
      fontSize: 14, color: 'yellow', paddingRight: 10, cursor: 'pointer'
    };
    const titleSpan = { fontWeight: 'bold', color: 'dimgrey', paddingRight: 10 };
    const timeSpan = { fontStyle: 'italic', fontSize: 9, color: 'lightslategray' };
    const windowStyles = {};
    if (noMargin === false) {
      windowStyles.margin = '1rem auto';
    }

    if (type === 'process') {
      return (<div style={windowStyles}>
        <ul style={ulStyles}>

          {data.map((item, index) => {
            const liStyles = {
              backgroundColor: (index % 2 === 1) ? '#ddd' : '#efefef',
              padding: '0.6rem',
              borderBottom: '1px solid #ddd'
            };
            const isPin = _.get(item, 'isPin', false);
            return (<li key={`history_${index}`} style={liStyles}>
              <p style={{ marginBottom: 0, whiteSpace: 'pre-line' }}>
                <span
                  style={isPin ? yellowStar : blackStar}
                  onClick={this.setPin.bind(this, order.id, index, isPin)}
                >
                  <i className="fa fa-star" aria-hidden="true"></i>
                </span>
                <span style={titleSpan}>{item.account.title}</span>
                <span style={timeSpan}>{formatDateTimeChatBox(item.createdAt)}</span>
                <br />
                {item.note}
              </p>
            </li>);
          })}
        </ul>
      </div>);
    }

    if (type === 'history') {
      return (<div style={windowStyles}>
        <ul style={ulStyles}>
          {data.map((item, index) => {
            const liStyles = {
              backgroundColor: (index % 2 === 1) ? '#ddd' : '#efefef',
              padding: '0.6rem',
              borderBottom: '1px solid #ddd'
            };

            return (<li key={`history_${index}`} style={liStyles}>
              <p style={{ marginBottom: 0, whiteSpace: 'pre-line' }}>
                <span style={titleSpan}>{item.changedBy.title} </span>
                <span style={timeSpan}>{formatDateTimeChatBox(item.createdAt)}</span>
                <br />
                {item.description}

              </p>
            </li>);
          })}
        </ul>
      </div>);
    }

    if (type === 'deposit') {
      return (<div style={windowStyles}>
        <ul style={ulStyles}>
          {data.map((item, index) => {
            const liStyles = {
              backgroundColor: (index % 2 === 1) ? '#ddd' : '#efefef',
              padding: '0.6rem',
              borderBottom: '1px solid #ddd'
            };
            const isMinus = item.amount.toString().search('-') >= 0;
            return (<li key={`history_${index}`} style={liStyles}>
              <p style={{ marginBottom: 0, whiteSpace: 'pre-line' }}>
                <span style={titleSpan}>
                  {_.get(order, 'customer.title', '')}
                </span>
                <span style={timeSpan}>{formatDateTimeChatBox(item.createdAt)}</span>
                <br />
                {isMinus ? 'Hoàn cọc' : 'Đặt cọc'} : <span style={{ fontWeight: 'bold' }} className={isMinus ? 'text-danger' : 'text-success'}>{item.amount.toLocaleString()}</span>
                <br />{item.description}
                {item.changedBy ? <span style={{ ...timeSpan, paddingTop: 10 }}> Người thực hiện: {item.changedBy.title} </span> : ''}
              </p>
            </li>);
          })}
        </ul>
      </div>);
    }

    return (<div></div>);
  }

  renderDocument() {
    const { model } = this.state;
    if (_.isEmpty(model.serviceIdentifyCode) === true) {
      return (<div></div>);
    }
    const service = this.props.getServiceDetail(model.serviceIdentifyCode);
    if (_.isEmpty(service.document) === true) {
      return (<div></div>);
    }
    return (service.document.map((item, i) => {
      return (<div style={{ float: 'left' }} key={`renderDocument_${i}`} className="col-md-6">
        <div style={{ padding: 10 }} className="media-title font-weight-semibold">
          <a target="_blank" href={item.value}>
            <i className="fa fa-link"></i> {item.key}
          </a>
        </div>
      </div>);
    }));
  }

  setStag(tag) {
    if (tag) {
      const model = Object.assign({}, this.state.model);
      model.tag = this.tags.state.tags;
      const isExist = model.tag.indexOf(tag);
      if (tag && isExist === -1) { model.tag.push(tag); }
      this.setState({ model });
    }
  }

  renderTagItem() {
    const { orderTags } = this.props;
    if (_.isEmpty(orderTags) === false) {
      return (orderTags.map((item, i) => {
        return (
          <span
            onClick={this.setStag.bind(this, item)}
            key={`orderTags_${i}`}
            style={TAG_STYLE[i]}
            className="badge badge-pill">{item}</span>);
      }));
    }
    return (<span></span>);
  }

  toggleWallet = () => {
    this.setState(prevstate => ({
      displayWallet: !prevstate.displayWallet
    }));

    setTimeout(() => {
      this.setState(prevstate => ({
        displayWallet: !prevstate.displayWallet
      }));
    }, 3000);
  };

  highLightForm(state, obj1, obj2) {
    const checkDiff = diff(obj1, obj2);
    _.forEach(state, (item, key) => {
      if (_.isObject() === true) {
        _.forEach(item, (subItem, subKey) => {
          if (checkDiff[key][subKey]) {
            state[key][subKey] = !state[key][subKey];
          }
        });
      }
      if (checkDiff[key]) {
        state[key] = !state[key];
      }
    });
    return state;
  }

  render() {
    const {
      model,
      dateDisplay,
      isDisable,
      genders,
      showContextMenu,
      sexDefaultValue,
      amountTypeDefaultValue,
      amountTypes,
      stateDefaultValue,
      delegatesDefaultValue,
      nationalDefaultValue,
      states,
      delegates,
      supplierDefaultValue,
      suppliers,
      customizeField
    } = this.state;
    const {
      order
    } = this.props;
    const isActive = this.checkActiveOrder();
    const deposit = _.sumBy(_.get(order, 'deposit', 0), 'amount');
    const hasWalletPermission = checkScope(WALLET_SCOPE, this.props);
    const files = _.get(model, 'profile.files', false);
    const linkToReceipt = `/order-receipt/${_.get(order, 'customer.id', '')}/${_.get(order, 'id', '')}`;

    return (
      <div className={
        this.state.displayForm === true
          ? cx('toggler', { 'toggler--form': this.state.displayForm })
          : cx('toggler', { 'toggler--wallet': this.state.displayWallet })
      }>
        <Tooltip
          placement="right"
          isOpen={this.state.tooltipOpen}
          target="TooltipExample"
          toggle={this.toggleTips}>

          <table border={0} style={{ width: 180 }}>
            <tbody>
              <tr>
                <td style={{ width: 65, textAlign: 'left' }}>Khả dụng <span style={{ float: 'right' }}>:</span></td>
                <td style={{ textAlign: 'right' }}>{_.get(this.props.wallet, 'amount', 0).toLocaleString()}</td>
              </tr>
              <tr>
                <td style={{ width: 65, textAlign: 'left' }}>Tiền mặt <span style={{ float: 'right' }}>:</span></td>
                <td style={{ textAlign: 'right' }}>{_.get(this.props.wallet, 'detail.cash', 0).toLocaleString()}</td>
              </tr>

              <tr>
                <td style={{ width: 65, textAlign: 'left' }}>Dư nợ <span style={{ float: 'right' }}>:</span></td>
                <td style={{ textAlign: 'right' }}>{_.get(this.props.wallet, 'detail.credit', 0).toLocaleString()}</td>
              </tr>

              <tr>
                <td style={{ width: 65, textAlign: 'left' }}>Hạn mức tín dụng <span style={{ float: 'right' }}>:</span></td>
                <td style={{ textAlign: 'right' }}> {_.get(this.props.wallet, 'detail.creditLimit', 0).toLocaleString()}</td>
              </tr>
            </tbody>
          </table>
        </Tooltip>

        <div className="row">
          <div className="col-xl-12">
            <div className="card ">
              <div className="card-header header-elements-inline">
                <h5 className="card-title">Thông tin Khách hàng</h5>
                <div className="header-elements">

                </div>
              </div>
              <div className="card-body ">
                <form onSubmit={this.onSubmit} ref="form">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-4">
                        <label>Họ tên</label>
                        <input
                          value={_.get(order, 'customer.title', '')}
                          type="text"
                          className="form-control"
                          disabled
                        />
                      </div>
                      <div className="col-md-4">
                        <label>Số điện thoại</label>
                        <input
                          value={_.get(order, 'customer.phone', '')}
                          type="text"
                          className="form-control"
                          disabled
                        />
                      </div>
                      <div className="col-md-4">
                        <label>Email</label>
                        <input
                          value={_.get(order, 'customer.email', '')}
                          type="text"
                          className="form-control"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-12">
                        <label>Nhãn đơn hàng</label>
                        <Autocomplete
                          delay={500}
                          tags={model.tag}
                          ref={(e) => { this.tags = e; }}
                          onAdd={this.onChangeHandle.bind(this, 'tag')}
                        />
                        <div style={{ marginTop: 10, paddingLeft: 10 }}>
                          {this.renderTagItem()}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card ">
              <div className="card-header header-elements-inline">
                <h5 className="card-title">Đơn hàng</h5>
              </div>
              <div className="card-body ">
                <Tabs defaultActiveKey="tab1" id="uncontrolled-tab-example">
                  <Tab eventKey="tab1" title="Tiến trình">
                    <form onSubmit={this.onSubmit}>
                      <fieldset disabled={!isActive}>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-4 changeColorSelect">
                              <label>
                                Loại dịch vụ <span className="text-danger">*</span>
                              </label>

                              <Select2
                                disabled={!isActive}
                                defaultValue={'all'}
                                value={model.serviceIdentifyCode || ''}
                                style={{ width: '100%' }}
                                name="area"
                                onChange={this.onChangeHandle.bind(this, 'serviceIdentifyCode')}
                                className="form-control select-search select2-hidden-accessible"
                                data={this.props.listServiceHandleData().list}
                                options={{
                                  placeholder: 'Chọn loại dịch vụ'
                                }}
                              />

                            </div>

                            <div className="col-md-4 changeColorSelect">
                              <label>Trạng thái <span className="text-danger">*</span></label>
                              <SelectStateContainer
                                isDisabled={isDisable}
                                value={stateDefaultValue}
                                onChange={this.onChangeHandle.bind(this, 'state')}
                                options={states}
                              />

                            </div>
                            <div className="col-md-4">
                              <label>Ngày dự kiến</label>
                              <div className="form-group">
                                <div className="input-group">
                                  <span className="input-group-prepend">
                                    <span className="input-group-text">
                                      <i className="icon-calendar"></i>
                                    </span>
                                  </span>
                                  <div className="multiselect-native-select">
                                    <InputMask
                                      value={dateDisplay.estimatedAt}
                                      className="form-control"
                                      mask="99/99/9999"
                                      onChange={this.onChangeHandle.bind(this, 'estimatedAt')}
                                      placeholder="Nhập ngày dự kiến" />
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">

                            <div className="col-md-4">
                              <label>Tổng tiền đơn hàng <span className="text-danger">*</span></label>
                              <label style={{ float: 'right', fontStyle: 'italic' }}>
                                <i
                                  style={{ marginLeft: 10 }}
                                  href="#"
                                  id="TooltipExample"
                                  className="fa fa-info-circle"></i>
                              </label>
                              <div className="form-group">
                                <div className="input-group">
                                  <span className="input-group-prepend">
                                    <span className="input-group-text">
                                      VND
                                    </span>
                                  </span>
                                  <div className="multiselect-native-select">
                                    <Cleave
                                      value={_.get(model, 'amount', 0).toLocaleString()}
                                      onChange={this.onChangeHandle.bind(this, 'amount')}
                                      disabled={(deposit > 0) ? 1 : ''}
                                      className="form-control input-numeral"
                                      placeholder="Số tiền"
                                      options={{
                                        numeral: true,
                                        numeralThousandsGroupStyle: 'thousand',
                                        numeralDecimalScale: 0
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <label>Đã thanh toán </label>
                              <input
                                value={(deposit || 0).toLocaleString()}
                                type="text"
                                className="form-control"
                                disabled
                              />
                            </div>

                            <div className="col-md-4">
                              <label>Còn lại </label>
                              <input
                                value={(_.get(order, 'amount', 0) - deposit).toLocaleString()}
                                type="text"
                                className="form-control"
                                disabled
                              />
                            </div>

                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-4">
                              <label>Loại thanh toán <span className="text-danger">*</span> </label>
                              <Select
                                value={amountTypeDefaultValue}
                                onChange={this.onChangeHandle.bind(this, 'depositType')}
                                options={amountTypes}
                              />
                            </div>
                            <div className="col-md-4">
                              <label>{amountTypeDefaultValue.value === 'deposit' ? 'Thanh toán' : 'Hoàn tiền'}</label>
                              <label className="changeColor" style={{ float: 'right', fontStyle: 'italic', padding: '0px 20px' }}>
                                Số dư khả dụng:
                                <span style={{ fontWeight: 'bold', paddingLeft: 5 }} className="text-danger">
                                  {_.get(this.props.wallet, 'amount', 0).toLocaleString()} đ
                                </span>
                                {hasWalletPermission ? <Link
                                  style={{ paddingLeft: 5 }}
                                  to={`/account-wallet/${_.get(this.props.wallet, 'accountId', false)}`}
                                  title="Cập nhật ví"
                                >
                                  <i className="fa fa-plus-circle"></i>
                                </Link> : ''}
                              </label>
                              <div className="form-group">
                                <div className="input-group">
                                  <span className="input-group-prepend">
                                    <span className="input-group-text">VND</span>
                                  </span>
                                  <div className="multiselect-native-select">
                                    <Cleave
                                      value={model.deposit.amount}
                                      onChange={this.onChangeHandle.bind(this, 'deposit')}
                                      className="form-control input-numeral"
                                      placeholder="Số tiền"
                                      options={{
                                        numeral: true,
                                        numeralThousandsGroupStyle: 'thousand',
                                        numeralDecimalScale: 0
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <label>Nội dung {amountTypeDefaultValue.value === 'deposit' ? 'thanh toán' : 'hoàn tiền'}</label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  onChange={this.onChangeHandle.bind(this, 'depositDescription')}
                                  className="form-control"
                                  placeholder="Nhập thông tin"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {isStaff(this.props) === true
                          ? <div className="form-group">
                            <div className="row">
                              <div className="col-md-6">
                                <label>VAT</label>
                                <div className="form-group">
                                  <div className="input-group">
                                    <div className="multiselect-native-select">
                                      <input
                                        value={model.vat}
                                        onChange={this.onChangeHandle.bind(this, 'vat')}
                                        type="text"
                                        className="form-control"
                                        placeholder="VD: 10%"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          : ''}
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-12">
                              <label>Tiến trình</label>
                              {this.renderChatMessageHistory(order.process, 'process')}
                              <textarea
                                value={this.state.message}
                                onChange={this.handleSendMessage}
                                className="form-control"
                                style={{ width: 'calc(100% - 100px)', float: 'left' }}
                                type="text"
                              ></textarea>
                              <button
                                onClick={this.handleBtnSendMessage}
                                className="btn btn-info"
                                style={{
                                  textTransform: 'uppercase',
                                  letterSpacing: '0.05em',
                                  fontWeight: 'bold',
                                  fontSize: '0.8em',
                                  width: 100,
                                  height: '3.8783rem'
                                }}>Send</button>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </form>

                  </Tab>
                  <Tab eventKey="tab2" title="Lịch sử thay đổi">
                    {this.renderChatMessageHistory(order.history, 'history')}
                  </Tab>
                  <Tab eventKey="tab3" title="Lịch sử đặt cọc">
                    {this.renderChatMessageHistory(order.deposit, 'deposit', true)}
                  </Tab>
                  <Tab eventKey="tab4" title={`Tài liệu tham khảo (${this.state.documentCount})`}>
                    {this.renderDocument()}
                  </Tab>
                  {(suppliers.length > 1 && _.find(suppliers, (o) => { return o.value === supplierDefaultValue.value; }))
                    ? <Tab eventKey="tab5" title="Đối tác xử lý">
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">
                            <label>Đối tác xử lý</label>
                            <div className="form-group">
                              <Select
                                value={supplierDefaultValue}
                                onChange={this.onChangeHandle.bind(this, 'supplierId')}
                                options={suppliers}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label>Giá đối tác thu</label>
                            <div className="form-group">
                              <div className="input-group">
                                <span className="input-group-prepend">
                                  <span className="input-group-text">VND</span>
                                </span>
                                <div className="multiselect-native-select">
                                  <Cleave
                                    value={model.supplierAmount}
                                    disabled={supplierDefaultValue.value === 0 ? 'disabled' : ''}
                                    onChange={this.onChangeHandle.bind(this, 'supplierAmount')}
                                    className="form-control input-numeral"
                                    placeholder="Số tiền"
                                    options={{
                                      numeral: true,
                                      numeralThousandsGroupStyle: 'thousand',
                                      numeralDecimalScale: 0
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">
                            <label>Ngày gửi đối tác</label>
                            <div className="form-group">
                              <div className="input-group">
                                <span className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="icon-calendar"></i>
                                  </span>
                                </span>
                                <div className="multiselect-native-select">
                                  <InputMask
                                    disabled={supplierDefaultValue.value === 0 ? 'disabled' : ''}
                                    value={dateDisplay.supplierSendAt}
                                    className="form-control"
                                    mask="99/99/9999"
                                    onChange={this.onChangeHandle.bind(this, 'supplierSendAt')}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label>Trạng thái thanh toán cho đối tác</label>
                            <div className="form-group">
                              <div className="input-group">
                                <input
                                  value={model.isPaidSupplier ? 'Đã thanh toán' : 'Chưa thanh toán'}
                                  disabled
                                  type="text"
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    : ''}
                </Tabs>

              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card ">
              <div className="card-header header-elements-inline">
                <h5 className="card-title">Thông tin hồ sơ</h5>
              </div>
              <div className="card-body ">
                <form onSubmit={this.onSubmit} >
                  <fieldset disabled={!isActive}>

                    {model.profile.passport.number === 'B000000'
                      ? <div> <div className="form-group">
                        <div className="row">
                          <div className="col-md-4">
                            <label>Họ & tên <span className="text-danger">*</span></label>
                            <div className="form-group">
                              <input
                                ref={(input) => {
                                  this.input.fullName = {
                                    title: 'Họ & tên', isActive: true, required: true, e: input
                                  };
                                }}
                                value={model.profile.fullName}
                                onChange={this.onChangeHandle.bind(this, 'fullName')}
                                type="text"
                                className="form-control"
                                placeholder="Họ khách hàng"
                              />
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>Email</label>
                              <input
                                value={_.replace(model.profile.email, 'achau@gmail.com', '')}
                                onChange={this.onChangeHandle.bind(this, 'email')}
                                type="text"
                                className="form-control"
                                placeholder="Nhập email"
                              />
                            </div>
                          </div>

                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>Số điện thoại</label>
                              <input
                                value={_.replace(model.profile.phone, '0909000000', '')}
                                onChange={this.onChangeHandle.bind(this, 'phone')}
                                type="text"
                                className="form-control"
                                placeholder="Nhập số điện thoại"
                              />
                            </div>
                          </div>
                        </div>

                      </div>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label>Địa chỉ</label>
                                <input
                                  value={model.profile.address}
                                  onChange={this.onChangeHandle.bind(this, 'address')}
                                  type="text"
                                  className="form-control"
                                  placeholder="Nhập nơi sinh"
                                />
                              </div>
                            </div>
                            <div className="col-md-8">
                              <fieldset className="card-body">
                                <div className="form-group">
                                  <label>Upload văn bản liên quan đến nhân sự này: <span className="text-danger">*</span></label>
                                  <UploadFileContainer
                                    setFiles={this.setFiles.bind(this)}
                                  />
                                  <span className="form-text text-muted">Accepted formats: doc, docx, pdf, bmp, png, gif, jpg, jpeg</span>
                                </div>
                              </fieldset>
                              {files ? <fieldset className="card-body">
                                <div className="form-group">
                                  <label>Tải file đi kèm</label>
                                  {this.renderDownloadFile()}
                                </div>

                              </fieldset> : ''}

                            </div>

                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-12">
                              <label>Ghi chú hồ sơ</label>
                              <textarea
                                value={_.get(model, 'profile.description', '').replace('null', '')}
                                onChange={this.onChangeHandle.bind(this, 'profileDescription')}
                                name="additional-info"
                                rows="4"
                                cols="4"
                                placeholder=""
                                className="form-control"
                              ></textarea>
                            </div>

                          </div>
                        </div></div> : ''}

                    <div className="form-group">
                      <div className="row">

                        {model.profile.passport.number !== 'B000000'
                          ? <div className="col-md-4">

                            <label>Số hộ chiếu
                              <span className="text-danger"> *</span>
                            </label>
                            <div className="form-group">
                              <DelayInput
                                value={model.profile.passport.number}
                                type="text"
                                className="form-control"
                                minLength={3}
                                delayTimeout={300}
                                placeholder="Nhập số hộ chiếu"
                                onChange={this.onChangeHandle.bind(this, 'passportNumber')}
                              />
                              <div className={showContextMenu.display ? 'dropdown-menu show' : 'dropdown-menu'} style={{ left: 10, width: 'calc(100% - 20px)' }} >
                                {
                                  this.state.profiles.map((item, index) => (
                                    <a
                                      onClick={this.setProfile.bind(this, item)}
                                      key={`profiles${index}`}
                                      className="dropdown-item">
                                      <div
                                        className="media-title font-weight-semibold">
                                        {item.fullName}
                                        <span
                                          style={{ paddingLeft: 10 }}
                                          className="text-muted">
                                          (<Moment format="DD/MM/YYYY">{item.birthday}</Moment>)
                                        </span>
                                      </div>

                                    </a>
                                  ))
                                }
                              </div>

                            </div>
                          </div>
                          : ''}
                        {model.profile.passport.number !== 'B000000'
                          ? <div className="col-md-4">
                            <label>Họ & tên <span className="text-danger">*</span></label>
                            <div className="form-group">
                              <input

                                value={model.profile.fullName}
                                onChange={this.onChangeHandle.bind(this, 'fullName')}
                                type="text"
                                className="form-control"
                                placeholder="Họ khách hàng"
                              />
                            </div>
                          </div> : ''}
                        {model.profile.passport.number !== 'B000000'
                          ? <div className="col-sm-4">
                            <label>Quốc tịch <span className="text-danger">*</span></label>
                            <div className="form-group">
                              <Select
                                value={nationalDefaultValue}
                                onChange={this.onChangeHandle.bind(this, 'national')}
                                options={this.props.buildCountry()}
                              />
                            </div>
                          </div>
                          : ''}
                      </div>
                    </div>

                    {model.profile.passport.number !== 'B000000' ? <span>

                      <div className="form-group">
                        <div className="row">

                          <div className="col-sm-4">
                            <label>Nơi cấp  <span className="text-danger">*</span></label>
                            <div className="form-group">
                              <input
                                value={model.profile.passport.placeOfIssue}
                                onChange={this.onChangeHandle.bind(this, 'placeOfIssue')}
                                type="text"
                                className="form-control"
                                placeholder="Nhập nơi cấp"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <label>Ngày cấp <span className="text-danger">*</span></label>
                            <div className="form-group">
                              <div className="input-group">
                                <span className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="icon-calendar"></i>
                                  </span>
                                </span>
                                <div className="multiselect-native-select">
                                  <InputMask
                                    value={dateDisplay.issuedAt}
                                    className="form-control"
                                    mask="99/99/9999"
                                    onChange={this.onChangeHandle.bind(this, 'issuedAt')}
                                  />

                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <label className="float-left"> Ngày hết hạn <span className="text-danger">*</span></label>
                            <span className="float-right text-warning">{this.state.expireAtWarning || ''}</span>
                            <div className="form-group">
                              <div className="input-group">
                                <span className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="icon-calendar"></i>
                                  </span>
                                </span>
                                <div className="multiselect-native-select">
                                  <InputMask
                                    value={dateDisplay.expiredAt}
                                    className={this.state.expireAtWarning ? 'form-control border-warning' : 'form-control'}
                                    mask="99/99/9999"
                                    onChange={this.onChangeHandle.bind(this, 'expiredAt')}
                                  />
                                </div>

                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>Ngày sinh <span className="text-danger">*</span></label><br />
                              <div className="form-group">
                                <div className="input-group">
                                  <span className="input-group-prepend">
                                    <span className="input-group-text">
                                      <i className="icon-calendar"></i>
                                    </span>
                                  </span>
                                  <div className="multiselect-native-select">
                                    <InputMask
                                      value={dateDisplay.birthday}
                                      className="form-control"
                                      mask="99/99/9999"
                                      onChange={this.onChangeHandle.bind(this, 'birthday')}
                                    />
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>Nơi sinh</label>
                              <input
                                value={model.profile.placeOfBirth}
                                onChange={this.onChangeHandle.bind(this, 'placeOfBirth')}
                                type="text"
                                className="form-control"
                                placeholder="Nhập nơi sinh"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <label>Giới tính <span className="text-danger">*</span> </label>
                            <Select
                              value={sexDefaultValue}
                              onChange={this.onChangeHandle.bind(this, 'sex')}
                              options={genders}
                            />
                          </div>

                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-sm-4">

                            <label>Số căn cước</label>
                            <div className="form-group">
                              <input
                                value={model.profile.identifyCard}
                                onChange={this.onChangeHandle.bind(this, 'identifyCard')}
                                type="text"
                                className="form-control"
                                placeholder="Nhập số căn cước"
                              />
                            </div>
                          </div>

                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>Email</label>
                              <input
                                value={model.profile.email}
                                onChange={this.onChangeHandle.bind(this, 'email')}
                                type="text"
                                className="form-control"
                                placeholder="Nhập email"
                              />
                            </div>
                          </div>

                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>Số điện thoại</label>
                              <input
                                value={model.profile.phone}
                                onChange={this.onChangeHandle.bind(this, 'phone')}
                                type="text"
                                className="form-control"
                                placeholder="Nhập số điện thoại"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>Địa chỉ</label>
                              <input
                                value={model.profile.address}
                                onChange={this.onChangeHandle.bind(this, 'address')}
                                type="text"
                                className="form-control"
                                placeholder="Nhập nơi sinh"
                              />
                            </div>
                          </div>
                          <div className="col-md-8">
                            <fieldset className="card-body">
                              <div className="form-group">
                                <label>Upload văn bản liên quan đến nhân sự này: <span className="text-danger">*</span></label>
                                <UploadFileContainer
                                  setFiles={this.setFiles.bind(this)}
                                />
                                <span className="form-text text-muted">Accepted formats: doc, docx, pdf, bmp, png, gif, jpg, jpeg</span>
                              </div>
                            </fieldset>
                            {files ? <fieldset className="card-body">
                              <div className="form-group">
                                <label>Tải file đi kèm</label>
                                {this.renderDownloadFile()}
                              </div>

                            </fieldset> : ''}

                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-12">
                            <label>Ghi chú hồ sơ</label>
                            <textarea
                              value={model.profile.description}
                              onChange={this.onChangeHandle.bind(this, 'profileDescription')}
                              name="additional-info"
                              rows="4"
                              cols="4"
                              placeholder=""
                              className="form-control"
                            ></textarea>
                          </div>

                        </div>
                      </div>

                    </span> : ''}

                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card ">
              <div className="card-header header-elements-inline">
                <h5 className="card-title">Thông tin bổ sung</h5>
                <div className="header-elements"></div>
              </div>
              <div className="card-body ">
                <em>{(_.isEmpty(customizeField) ? 'Không có thông tin cần bổ sung thêm' : '')}</em>
                {customizeField.map((v, k) => {
                  switch (v.type) {
                    case 'INPUT_TEXT':
                      return (
                        <div key={`customize_${v.key}`} className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-12">
                                  <label>{v.title} <span className="text-danger">{(v.require === true) ? '*' : ''}</span></label>
                                  <div className="form-group">
                                    <div className="input-group">
                                      <input
                                        ref={(input) => {
                                          this.input[v.key] = {
                                            name: v.key, title: v.title, isActive: true, required: v.require, e: input
                                          };
                                        }}
                                        value={model.customizeField[v.key]}
                                        type="text"
                                        onChange={this.onChangeCustomizeFieldHandle.bind(this, v.key, v.type)}
                                        className="form-control"
                                        placeholder={v.placeHolder ? v.placeHolder : ''}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>);

                    case 'INPUT_DATE':
                      return (
                        <div key={`customize_${v.key}`} className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-12">
                                  <label>{v.title} <span className="text-danger">{(v.require === true) ? '*' : ''}</span></label>
                                  <div className="form-group">
                                    <div className="input-group">
                                      <span className="input-group-prepend">
                                        <span className="input-group-text">
                                          <i className="icon-calendar"></i>
                                        </span>
                                      </span>
                                      <div className="multiselect-native-select">
                                        <InputMask
                                          ref={(input) => {
                                            this.input[v.key] = {
                                              name: v.key, title: v.title, isActive: true, required: v.require, e: input
                                            };
                                          }}
                                          value={model.customizeField[v.key] || 'DD/MM/YYYY'}
                                          className="form-control"
                                          mask="99/99/9999"
                                          onChange={this.onChangeCustomizeFieldHandle.bind(this, v.key, v.type)}
                                        />

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );

                    case 'CHECKBOX':
                      return (
                        <div key={`customize_${v.key}`} className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-12">
                                  <label>{v.title} <span className="text-danger">{(v.require === true) ? '*' : ''}</span></label>
                                  <div className="form-group">
                                    {v.items.map((sv, sk) => {
                                      return (<div key={`customize_subcheck_${v.key}_${sk}`} className="form-check form-check-inline">
                                        <label className="form-check-label" title={v.title}>
                                          <div className="uniform-checker">
                                            {/* <span className={model.checkTest === true ? 'checked' : ''}> */}
                                            <span className={this.onCheckCustomizeFieldHandle(v.key, v.type, sv.value, v.defaultValue) === true ? 'checked' : ''}>
                                              <input
                                                value={sv.value}
                                                checked={this.onCheckCustomizeFieldHandle(v.key, v.type, sv.value, v.defaultValue) === true ? 'checked' : ''}
                                                onChange={this.onChangeCustomizeFieldHandle.bind(this, v.key, v.type)}
                                                type="checkbox"
                                                className="form-check-input-styled"
                                                data-fouc="true" />
                                            </span>
                                          </div>{sv.title}</label>
                                      </div>);
                                    })
                                    }

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );

                    case 'RADIO':
                      return (
                        <div key={`customize_${v.key}`} className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-12">
                                  <label>{v.title} <span className="text-danger">{(v.require === true) ? '*' : ''}</span></label>
                                  <div className="form-group">
                                    {v.items.map((sv, sk) => {
                                      return (<div key={`customize_subradio_${v.key}_${sk}`} className="form-check">
                                        <label className="form-check-label">
                                          <div className="uniform-choice">
                                            <span className={this.onCheckCustomizeFieldHandle(v.key, v.type, sv.value) ? 'checked' : ''}>
                                              <input
                                                // checked={model.availability === 'customer'}
                                                onChange={this.onChangeCustomizeFieldHandle.bind(this, v.key, v.type)}
                                                value={sv.value}
                                                type="radio"
                                                name="availability"
                                                className="form-input-styled"
                                                data-fouc="true"
                                              />
                                            </span>
                                          </div>{sv.title}
                                        </label>
                                      </div>);
                                    })
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>);

                    case 'LISTBOX':
                      return (<div key={`customize_${v.key}`} className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-12">
                                <label>{v.title} <span className="text-danger">{(_.isEmpty(v.require) ? '*' : '')}</span></label>
                                <div className="form-group">
                                  <Select
                                    value={this.onCheckCustomizeFieldHandle(v.key, v.type, null, v.defaultValue)}
                                    onChange={this.onChangeCustomizeFieldHandle.bind(this, v.key, v.type)}
                                    options={this.onConvertDataSettingListbox(v.items)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>);
                    default:
                      break;
                  }
                  return true;
                })}

              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card ">
              <div className="card-header header-elements-inline">
                <h5 className="card-title">Thông tin khác</h5>
                <div className="header-elements"></div>
              </div>
              <div className="card-body ">
                <form onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-12 changeColorSelect">
                        <label>Người ủy thác</label>
                        <Select
                          isDisabled={!isActive}
                          value={delegatesDefaultValue}
                          onChange={this.onChangeHandle.bind(this, 'delegates')}
                          options={delegates}
                          isMulti={true}
                        />
                      </div>
                    </div>
                  </div>
                  {isActive === true ? <div className="text-right">
                    <a onClick={() => window.open(linkToReceipt, '_blank')} value="redirect" className="btn btn-info" style={{ color: 'white' }}>
                      <i className="fa fa-print"></i> In phiếu thu
                    </a>

                    <button onClick={this.submit.bind(this, 'add')} value="add" className="btn btn-primary" style={{ marginLeft: 20 }}>
                      <i className="fa fa-save"></i> Lưu lại
                    </button>

                    <button onClick={this.submit.bind(this, 'addNew')} value="addNew" className="btn btn-success" style={{ marginLeft: 20 }}>
                      <i className="fa fa-save"></i> Lưu & tạo mới
                    </button>

                  </div> : ''}

                </form>
              </div>
            </div>
          </div>
        </div>
        <NotificationContainer />
      </div>

    );
  }

  componentDidUpdate(oldProps) {
    try {
      /**
       * 
       */

      const newProps = this.props;
      if (oldProps.order !== newProps.order) {
        this.setModelOrder();
      }

      if (oldProps.countries !== newProps.countries) {
        this.setState({ countries: this.props.countries });
      }
      if (oldProps.accounts !== newProps.accounts) {
        const delegates = this.props.getDelegateMember();
        this.setState({ ...delegates });
      }

      if (oldProps.suppliers !== newProps.suppliers) {
        const objSupplier = [];
        objSupplier.push(
          { value: 0, label: 'Không' },
        );
        newProps.suppliers.map((v) => {
          objSupplier.push(
            { value: v.id, label: v.title }
          );
          return true;
        });

        let supplierDefaultValue = _.find(objSupplier, { value: newProps.order.supplierId });
        if (_.isUndefined(supplierDefaultValue) === true) {
          supplierDefaultValue = this.state.supplierDefaultValue;
        }
        this.setState({
          suppliers: objSupplier,
          supplierDefaultValue
        });
      }

      if (oldProps.orderTags !== newProps.orderTags) {
        const tagSuggestions = this.props.buildGetTagSuggest();
        this.setState({ tagSuggestions });
      }

      if (oldProps.profiles !== newProps.profiles) {
        const showContextMenu = { display: false };
        if (_.isEmpty(this.props.profiles) === false) {
          showContextMenu.display = true;
        }
        this.setState({ showContextMenu, profiles: this.props.profiles });
      }

      if (oldProps.socketBalance !== newProps.socketBalance) {
        this.callbackWallet();
      }

      if (oldProps.socketOrder !== newProps.socketOrder) {
        this.callbackOrder();
      }
    } catch (error) {
      // console.log(error);
      /**
       * 
       */
    }
  }

  setModelOrder(socketOrder) {
    try {
      const { buildServices } = this.props;
      if (_.isEmpty(buildServices) === false) {
        const { genders, suppliers } = this.state;
        console.log('suppliers', suppliers.length);
        let { order } = this.props;

        if (socketOrder) {
          order = socketOrder;
        }

        const model = {
          id: order.id,
          tag: _.get(order, 'tag', []),
          customizeField: _.get(order, 'customizeField', {}),
          supplierId: order.supplierId,
          supplierAmount: order.supplierAmount,
          paidSupplierAmount: order.paidSupplierAmount,
          vat: order.vat,
          supplierSendAt: order.supplierSendAt,
          serviceIdentifyCode: order.serviceIdentifyCode,
          description: order.description,
          state: order.state,
          amount: order.amount,
          estimatedAt: order.estimatedAt,
          profile: order.profile,
          isPaidSupplier: order.isPaidSupplier,
          deposit: {
            amount: 0,
            description: '',
            type: 'deposit'
          },
          delegates: []
        };
        const sexDefaultValue = _.find(genders, { value: order.profile.sex });

        let supplierDefaultValue = _.find(suppliers, { value: order.supplierId });
        if (_.isUndefined(supplierDefaultValue) === true) {
          supplierDefaultValue = this.state.supplierDefaultValue;
        }
        const dateDisplay = {
          birthday: order.profile.birthday ? formatDate(order.profile.birthday) : 'DD/MM/YYYY',
          supplierSendAt: order.supplierSendAt ? formatDate(order.supplierSendAt) : 'DD/MM/YYYY',
          issuedAt: order.profile.passport.issuedAt ? formatDate(order.profile.passport.issuedAt) : 'DD/MM/YYYY',
          expiredAt: order.profile.passport.expiredAt ? formatDate(order.profile.passport.expiredAt) : 'DD/MM/YYYY',
          estimatedAt: order.estimatedAt ? formatDate(order.estimatedAt) : 'DD/MM/YYYY'
        };

        const delegatesDefaultValue = [];
        const delegates = _.get(order, 'delegates', []);
        if (_.isEmpty(delegates) === false) {
          delegates.forEach((element) => {
            model.delegates.push({
              identifyCode: element.identifyCode,
              description: ''
            });
            delegatesDefaultValue.push({
              label: element.title,
              value: element.identifyCode
            });
          });
        }
        /**
           * 
           */
        const nationalDefaultValue = { value: order.profile.national, label: order.profile.national };
        const parentIdentifies = this.props.listServiceHandleData();
        const service = this.props.getServiceDetail(order.serviceIdentifyCode);

        const stateDefaultValue = _.find(service.stateList, { value: order.state });
        const documentCount = _.get(service, 'document', []).length;
        this.setState({
          parentIdentifies: parentIdentifies.list,
          documentCount,
          model,
          dateDisplay,
          sexDefaultValue,
          stateDefaultValue,
          orderStatus: stateDefaultValue,
          delegatesDefaultValue,
          supplierDefaultValue,
          nationalDefaultValue,
          process: order.process
        });
      }
    } catch (error) {
      // console.log(error);
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      socketBalance: payload.walletReducer.socketBalance,
      wallet: payload.walletReducer.wallet,
      orderTags: payload.orderReducer.orderTags,
      buildServices: payload.serviceReducer.buildServices,
      agencies: payload.agencyReducer.agencies,
      suppliers: payload.supplierReducer.suppliers,
      agency: payload.agencyReducer.agency,
      socketOrder: payload.orderReducer.socketOrder,
      order: payload.orderReducer.order,
      accounts: payload.accountReducer.accounts,
      init: payload.generalReducer.init,
      profiles: payload.generalReducer.profiles,
      userInfo: payload.generalReducer.userInfo,
      uploadPath: payload.generalReducer.uploadPath,
      downloadPath: payload.generalReducer.downloadPath,
      listScope: payload.generalReducer.listScope,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(ModifyOrder)
);
