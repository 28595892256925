import LoginContainer from '../components/account/login/login.container';
import ProfileContainer from '../components/account/profile/profile.container';
//
import HomeContainer from '../components/home/home.container';
//
import CreateAgencyContainer from '../components/agency/create/createAgency.container';
import ModifyAgencyContainer from '../components/agency/modify/modifyAgency.container';
import SearchAgencyContainer from '../components/agency/search/searchAgency.container';
//
import CreateBranchContainer from '../components/branch/create/createBranch.container';
import ModifyBranchContainer from '../components/branch/modify/modifyBranch.container';
import SearchBranchContainer from '../components/branch/search/searchBranch.container';

//
import CreateCustomerContainer from '../components/customer/create/createCustomer.container';
import SearchCustomerContainer from '../components/customer/search/searchCustomer.container';
//
import CreateDepartmentContainer from '../components/department/create/createDepartment.container';
import ModifyDepartmentContainer from '../components/department/modify/modifyDepartment.container';
import SearchDepartmentContainer from '../components/department/search/searchDepartment.container';
//
import CreateDiscountContainer from '../components/discount/create/createDiscount.container';
import ModifyDiscountContainer from '../components/discount/modify/modifyDiscount.container';
import SearchDiscountContainer from '../components/discount/search/searchDiscount.container';

//
import CreateMemberContainer from '../components/member/create/createMember.container';
import ModifyMemberContainer from '../components/member/modify/modifyMember.container';
import SearchMemberContainer from '../components/member/search/searchMember.container';
//
import CreateImmigrationOrderContainer from '../components/order/createImmigration/createImmigrationOrder.container';
import ModifyImmigrationOrderContainer from '../components/order/modifyImmigration/modifyImmigrationOrder.container';
import CreateOrderContainer from '../components/order/create/createOrder.container';
import ModifyOrderContainer from '../components/order/modify/modifyOrder.container';
import SearchOrderContainer from '../components/order/search/searchOrder.container';
import ReceiptOrderContainer from '../components/order/receipt/receiptOrder.container';
//
import SearchInvoiceContainer from '../components/invoice/search/searchInvoice.container';
//
import SearchCallLogContainer from '../components/callLog/search/searchCallLog.container';
//
import SearchPaymentVoucherContainer from '../components/paymentVoucher/search/paymentVoucher.container';
import ReceiptPaymentVoucherContainer from '../components/paymentVoucher/receipt/receiptVoucher.container';
import ModifyPaymentVoucherContainer from '../components/paymentVoucher/modify/modifyPaymentVoucher.container';
import CreatePaymentVoucherContainer from '../components/paymentVoucher/create/createPaymentVoucher.container';
//

import SearchCoboPobo from '../components/cobo-pobo/search/searchCoboPobo.container';
import CreateCobo from '../components/cobo-pobo/create/createCobo.container';
import CreatePobo from '../components/cobo-pobo/createPobo/createPobo.container';
import ModifyCobo from '../components/cobo-pobo/modify/modifyCobo.container';
import ModifyPobo from '../components/cobo-pobo/modifyPobo/modifyPobo.container';
//
import ExportData from '../components/exportData/general/exportData.container';

import CreatePermissionContainer from '../components/permission/create/createPermission.container';
import ModifyPermissionContainer from '../components/permission/modify/modifyPermission.container';
import SearchPermissionContainer from '../components/permission/search/searchPermission.container';

//

import CreateServiceContainer from '../components/service/create/createService.container';
import ModifyServiceContainer from '../components/service/modify/modifyService.container';
import SearchServiceContainer from '../components/service/search/searchService.container';
//
import SearchWalletDetailContainer from '../components/wallet/detail/searchWalletDetail.container';
import SearchWalletLiabilityDetailContainer from '../components/wallet/liability/searchWalletLiabilityDetail.container';
//
import SearchCustomerSupportContainer from '../components/customerSupport/search/searchCustomerSupport.container';
import CreateCustomerSupportContainer from '../components/customerSupport/create/createCustomerSupport.container';
import ModifyCustomerSupportContainer from '../components/customerSupport/modify/modifyCustomerSupport.container';
//
import ArrivalVisaPriceContainer from '../components/arrivalVisa/price/arrivalVisaPrice.container';

import NotFound from '../components/notfound/NotFound.container';

import * as Layout from '../layouts';

const routes = [
  {
    layout: Layout.Login,
    path: '/login',
    exact: true,
    component: LoginContainer,
    private: false
  },

  {
    layout: Layout.Content, 
    path: '/',
    exact: true,
    component: HomeContainer,
    private: true
  },

  {
    layout: Layout.Content, 
    path: '/profile',
    exact: true,
    component: ProfileContainer,
    private: true
  },

  /**
   * 
   */
  {
    layout: Layout.Content,
    path: '/dashboard',
    exact: true,
    component: HomeContainer,
    private: true
  },
  /**
   * 
   */
  { 
    layout: Layout.Content,
    path: '/customer',
    exact: true,
    component: SearchCustomerContainer,
    private: true 
  },
  { 
    layout: Layout.Content,
    path: '/customer-create',
    exact: true,
    component: CreateCustomerContainer,
    private: true 
  },
  
  /**
   * 
   */
  
  {
    layout: Layout.Receipt,
    layoutName: 'Receipt',
    path: '/order-receipt/:customerId/:id',
    exact: true,
    independent: true,
    component: ReceiptOrderContainer,
    private: true
  },
  {
    layout: Layout.Content,
    path: '/order',
    exact: true,
    component: SearchOrderContainer,
    private: true
  },
  { 
    layout: Layout.Content,
    path: '/order-create/:id',
    exact: true,
    component: CreateOrderContainer,
    private: true
  },
  { 
    layout: Layout.Content,
    path: '/order-modify/:customerId/:id',
    exact: true,
    component: ModifyOrderContainer,
    private: true
  },
  
  { 
    layout: Layout.Content,
    path: '/order-immigration-create/:id',
    exact: true,
    component: CreateImmigrationOrderContainer,
    private: true
  },
  { 
    layout: Layout.Content,
    path: '/order-immigration-create',
    exact: true,
    component: CreateImmigrationOrderContainer,
    private: true
  },
  { 
    layout: Layout.Content,
    path: '/order-immigration-modify/:customerId/:id',
    exact: true,
    component: ModifyImmigrationOrderContainer,
    private: true
  },
  /**
   * 
   */
  {
    layout: Layout.Content,
    path: '/order-process',
    exact: true,
    component: SearchInvoiceContainer,
    private: true
  },
  /**
   * 
   */
  {
    layout: Layout.Content,
    path: '/agency',
    exact: true,
    component: SearchAgencyContainer,
    private: true 
  },
  {
    layout: Layout.Content,
    path: '/agency-create',
    exact: true,
    component: CreateAgencyContainer,
    private: true 
  },
  {
    layout: Layout.Content,
    path: '/agency-modify/:id',
    exact: true,
    component: ModifyAgencyContainer,
    private: true 
  },
  /**
   * 
   */
  { 
    layout: Layout.Content,
    path: '/member',
    exact: true,
    component: SearchMemberContainer,
    private: true
  },
  { 
    layout: Layout.Content,
    path: '/member-create',
    exact: true,
    component: CreateMemberContainer,
    private: true 
  },
  {
    layout: Layout.Content,
    path: '/member-modify/:id',
    exact: true,
    component: ModifyMemberContainer,
    private: true 
  },
  /**
   * 
   */
  {
    layout: Layout.Content,
    path: '/department',
    exact: true,
    component: SearchDepartmentContainer,
    private: true 
  },
  {
    layout: Layout.Content,
    path: '/department-create',
    exact: true,
    component: CreateDepartmentContainer,
    private: true
  },
  { 
    layout: Layout.Content,
    path: '/department-modify/:id',
    exact: true,
    component: ModifyDepartmentContainer,
    private: true
  },
  /**
   * 
   */
  {
    layout: Layout.Content,
    path: '/branch',
    exact: true,
    component: SearchBranchContainer,
    private: true 
  },
  {
    layout: Layout.Content,
    path: '/branch-create',
    exact: true,
    component: CreateBranchContainer,
    private: true
  },
  { 
    layout: Layout.Content,
    path: '/branch-modify/:id',
    exact: true,
    component: ModifyBranchContainer,
    private: true 
  },
  /**
   * 
   */
  {
    layout: Layout.Content,
    path: '/service',
    exact: true,
    component: SearchServiceContainer,
    private: true
  },
  {
    layout: Layout.Content,
    path: '/service-create',
    exact: true,
    component: CreateServiceContainer,
    private: true 
  },
  {
    layout: Layout.Content,
    path: '/service-modify/:id',
    exact: true,
    component: ModifyServiceContainer,
    private: true
  },
  /**
   * 
   */
  {
    layout: Layout.Content,
    path: '/permission',
    exact: true,
    component: SearchPermissionContainer,
    private: true 
  },
  { 
    layout: Layout.Content,
    path: '/permission-create',
    exact: true,
    component: CreatePermissionContainer,
    private: true 
  },
  {
    layout: Layout.Content,
    path: '/permission-modify/:id',
    exact: true,
    component: ModifyPermissionContainer,
    private: true 
  },
  /**
   * 
   */
  {
    layout: Layout.Content,
    path: '/discount-price',
    exact: true,
    component: SearchDiscountContainer,
    private: true 
  },
  { 
    layout: Layout.Content,
    path: '/discount-price-create',
    exact: true,
    component: CreateDiscountContainer,
    private: true 
  },
  { 
    layout: Layout.Content,
    path: '/discount-price-modify/:id',
    exact: true,
    component: ModifyDiscountContainer,
    private: true
  },

  /**
   * 
   */
  {
    layout: Layout.Content,
    path: '/cobo-pobo',
    exact: true,
    component: SearchCoboPobo,
    private: true
  },
  {
    layout: Layout.Content,
    path: '/cobo-pobo/cobo-create',
    exact: true,
    component: CreateCobo,
    private: true
  },
  {
    layout: Layout.Content,
    path: '/cobo-pobo/pobo-create',
    exact: true,
    component: CreatePobo,
    private: true
  },
  {
    layout: Layout.Content,
    path: '/cobo-pobo/cobo-modify/:id',
    exact: true,
    component: ModifyCobo,
    private: true
  },
  {
    layout: Layout.Content,
    path: '/cobo-pobo/pobo-modify/:id',
    exact: true,
    component: ModifyPobo,
    private: true
  },
  {
    layout: Layout.Content,
    path: '/export-data/general',
    exact: true,
    component: ExportData,
    private: true
  },
  /**
   * 
   */
  {
    layout: Layout.Content,
    path: '/payment-voucher',
    exact: true,
    component: SearchPaymentVoucherContainer,
    private: true
  },
  {
    layout: Layout.Receipt,
    layoutName: 'Receipt',
    path: '/payment-voucher-receipt/:customerId/:id',
    exact: true,
    independent: true,
    component: ReceiptPaymentVoucherContainer,
    private: true
  },
  { 
    layout: Layout.Content,
    path: '/payment-voucher-create',
    exact: true,
    component: CreatePaymentVoucherContainer,
    private: true 
  },
  { 
    layout: Layout.Content,
    path: '/payment-voucher-modify/:id',
    exact: true,
    component: ModifyPaymentVoucherContainer,
    private: true
  },

  /**
   * 
   */
  {
    layout: Layout.Content,
    path: '/account-wallet/:accountId',
    exact: true,
    component: SearchWalletDetailContainer,
    private: true
  },
  {
    layout: Layout.Content,
    path: '/liability',
    exact: true,
    component: SearchWalletLiabilityDetailContainer,
    private: true
  },

  /**
   * 
   */
  {
    layout: Layout.Content,
    path: '/call-log',
    exact: true,
    component: SearchCallLogContainer,
    private: true
  },
  /**
   * 
   */
  {
    layout: Layout.Content,
    path: '/sale-cus',
    exact: true,
    component: SearchCustomerSupportContainer,
    private: true
  },
  {
    layout: Layout.Content,
    path: '/sale-cus-create',
    exact: true,
    component: CreateCustomerSupportContainer,
    private: true
  },
  {
    layout: Layout.Content,
    path: '/sale-cus-modify/:id',
    exact: true,
    component: ModifyCustomerSupportContainer,
    private: true
  },
  {
    layout: Layout.Content,
    path: '/arrival-visa-price',
    exact: true,
    component: ArrivalVisaPriceContainer,
    private: true
  },
  
  /**
   * 
   */
  {
    layout: Layout.Content,
    component: NotFound,
    private: false
  }
  
];

export default routes;

