import * as types from './link.types';

export function GetLinkInfo(payload, callback) {
  return {
    type: types.POST_LINK_GETINFO,
    payload,
    callback
  };
}

export function DeactiveLink(payload, callback) {
  return {
    type: types.POST_LINK_DEACTIVE,
    payload,
    callback
  };
}

/**
 * 
 * @param {*} payload 
 * @param {*} callback 
 */
