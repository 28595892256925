const Images = {
  incoming: '/assets/img/incoming.png',
  outgoing: '/assets/img/outgoing.png',
  background: '/assets/img/background.jpg',
  profile: '/assets/img/profiles/face17.jpg',
  microsoft: '/assets/img/microsoft.png',
  bhxh: '/assets/img/bhxh.jpg',
  printLogo: '/assets/img/logo-1-png1.png',
  logo: '/assets/img/logo_light.png',
  loading: '/assets/img/loading.gif',
  loading2: '/assets/img/loading.svg',
  flagEn: '/assets/img/flag_en.png',
  flagVi: '/assets/img/flag_vn.png',
  app1: '/assets/img/ico/app1.png',
  app2: '/assets/img/ico/app2.png',
  app3: '/assets/img/ico/app3.png',
  app4: '/assets/img/ico/app4.png',
  app5: '/assets/img/ico/app5.png'
};
// eslint-disable-next-line import/prefer-default-export
export { Images };
