import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import SearchBranch from './searchBranch';

import * as BranchActions from '../../../redux/branchs/branchs.actions';

class SearchBranchContainer extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = {
      listBranch: [],
      branchCount: 0
    };
    this.getBranchs = this.getBranchs.bind(this);
    this.remove = this.remove.bind(this);
    this.callBackRemove = this.callBackRemove.bind(this);
  }

  remove(id) {
    try {
      const { branchAct } = this.props;
      
      branchAct.deleteOrganization({ id }, this.callBackRemove);
    } catch (error) {
      // console.log('error', error);
    }
  }

  callBackRemove(status, data) {
    if (status) {
      NotificationManager.info('Xóa chi nhánh thành công', '', 1500);
      this.getBranchs();
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }

  getBranchs() {
    try {
      const { branchAct } = this.props;
      branchAct.searchBranch({
        filter: {
          type: 'branch'
        }
      });
    } catch (error) {
      // console.log(error);
      // console.log('error', error);
    }
  }

  render() {
    const { listScope } = this.props;
    return (
      <div>
        <SearchBranch
          listScope={listScope}
          errorMessage={this.state.errorMessage}
          userInfo={this.userInfo}
          remove={this.remove}
        />
        <NotificationContainer />
          
      </div>
        
    );
  }

  componentWillMount() {
    try {
      /**
       * 
       */
      this.getBranchs();
    } catch (error) {
      /**
       * 
       */
    }
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    branchAct: bindActionCreators(BranchActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    localStoreFilter: rootState.generalReducer.localStoreFilter,
    listScope: rootState.generalReducer.listScope,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(SearchBranchContainer);
