import * as types from './coboPobo.types';

/**
 * 
 * @param {*} payload 
 * @param {*} callback 
 */

export function search(payload, callback) {
  return {
    type: types.COBO_POBO_SEARCH,
    payload,
    callback
  };
}

export function exportFile(payload, callback) {
  return {
    type: types.COBO_POBO_EXPORT_FILE,
    payload,
    callback
  };
}

export function create(payload, callback) {
  return {
    type: types.COBO_POBO_CREATE,
    payload,
    callback
  };
}

export function detail(payload, callback) {
  return {
    type: types.COBO_POBO_DETAIL,
    payload,
    callback
  };
}

export function modify(payload, callback) {
  return {
    type: types.COBO_POBO_MODIFY,
    payload,
    callback
  };
}
