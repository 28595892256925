import _ from 'lodash';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import Select2 from 'react-select2-wrapper';
import InputMask from 'react-input-mask';
import Cleave from 'cleave.js/react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Moment from 'moment';
import UploadFileContainer from '../../ultils/uploadFile.container';
import SelectStateContainer from '../../ultils/selectState.container';
import {
  fileUpload, formatDate
} from '../../../helpers';

class CreateCobo extends React.Component {
  constructor(props) {
    super(props);
    this.input = {};
    this.state = {
      disabledDepartment: true,
      selectedFile: [],
      departmentTitle: '',
      typeOfSubmit: false,
      branchTitle: '',
      birthdayDisplay: '',
      bankAccountDefaultValue: {},
      listBankAccount: [],
      joinedDisplay: '',
      uploading: false,
      allowance: [{ key: '', value: '' }],
      displayIssuedAt: formatDate(new Date()),
      model: {
        method: 'COBO',
        type: '',
        title: '',
        amount: '',
        bankAccount: '',
        description: '',
        issuedAt: new Date(),
        documents: []
      },
      discounts: []
    };

    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.setListBankAccount = this.setListBankAccount.bind(this);
    this.setFiles = this.setFiles.bind(this);
  }

  componentWillMount() {
    this.setListBankAccount();
  }

  setListBankAccount() {
    const { init } = this.props;
    const bankAccounts = _.get(init, 'bankAccount', []);
    const listBankAccount = [];

    if (_.isEmpty(bankAccounts) === false) {
      _.forEach(bankAccounts, (item) => {
        listBankAccount.push({
          value: item.key,
          label: item.text,
          color: item.color
        });
      });
      const bankAccountDefaultValue = listBankAccount[0];
      this.setState({ listBankAccount, bankAccountDefaultValue });
    }
  }

  submit(key, e) {
    this.setState({ typeOfSubmit: key });
  }

  validateOnSubmit(model) {
    if (!model.type) {
      NotificationManager.error('Danh mục không được để trống', '', 1500);
      return false;
    }
    if (!model.amount || model.amount < 1) {
      NotificationManager.error('Số tiền không được để trống và phải lớn hơn 0', '', 1500);
      return false;
    }
    if (!model.bankAccount) {
      NotificationManager.error('Tài khoản nhận không được để trống', '', 1500);
      return false;
    }
    if (!model.sourceId) {
      NotificationManager.error('Ví nạp tiền không được để trống', '', 1500);
      return false;
    }
    return true;
  }

  onChangeHandle(key, e) {
    try {
      const { buildServices, wallet, agency } = this.props;
      const {
        isDisable, genders, parentIdentifies, delegates, suppliers
      } = this.state;

      const model = Object.assign({}, this.state.model);
      const result = {};

      if (_.isArray(e) === true) {
        // switch (key) {
        // }
      }
      if (_.isArray(e) === false) {
        switch (key) {
          case 'issuedAt': {
            const value = _.get(e, 'target.value', _.get(e, 'value', false));
            const dateFormat = 'YYYY/MM/DD HH:mm:ss';
            const splittedDate = value.split('/');

            if (splittedDate.length === 3 && splittedDate[2].length === 4) {
              const date = Moment(`${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]} 12:00:00`, dateFormat);
              result.displayIssuedAt = value;
              if (date.isValid() === true) {
                if (key === 'issuedAt') {
                  model.issuedAt = date.format();
                }
              }
            }

            break;
          }
          case 'amount': {
            const amount = _.get(e, 'target.rawValue', _.get(e, 'value', 0));
            model[key] = Number(amount);
            break;
          }

          case 'sourceId': {
            model[key] = _.find(this.props.listMembers(), { value: _.get(e, 'target.value', _.get(e, 'value', false)) });
            break;
          }

          default:
            model[key] = _.get(e, 'target.value', _.get(e, 'value', false));
            break;
        }
      }
      result.model = model;

      this.setState(result);
    } catch (error) {
      console.log(error);
    }
  }

  async validateInputOnSubmit() {
    return new Promise((resolve, reject) => {
      const input = this.input;
      let flag = true;
      _.forEach(input, (field) => {
        if (flag === false) return false;
        if (field.isActive === true) {
          if (field.required === true) {
            if (_.get(field, 'e.value', '') === '' || _.get(field, 'e.value', '') === '__/__/____') {
              NotificationManager.error(`${field.title} không được để trống!`, '', 1500);
              field.e.focus();
              flag = false;
            }
          }
        }
        return true;
      });
      resolve(flag);
    });
  }

  onSubmit = async (e) => {
    try {
      e.preventDefault();
      const model = Object.assign({}, this.state.model);
      const validateInputOnSubmit = await this.validateInputOnSubmit();
      if (validateInputOnSubmit === false) return;
      const validate = this.validateOnSubmit(model);
      if (validate === false) return;
      // SHOW LOADING
      this.props.dispatch({ type: 'SHOW_LOADING' });
      if (this.state.selectedFile) {
        const files = await fileUpload(this.state.selectedFile.documents, this.props);
        if (files.code === 1) {
          model.documents = files.data;
        }
      }

      this.props.onSubmit(model, this.state.typeOfSubmit);
      this.props.dispatch({ type: 'HIDE_LOADING' });
    } catch (error) {
      console.log(error);
      // HIDE LOADING
      this.props.dispatch({ type: 'HIDE_LOADING' });
    } finally {
      this.props.dispatch({ type: 'HIDE_LOADING' });
    }
  }

  setFiles(file, field) {
    try {
      if (_.isEmpty(file) === false) {
        const selectedFile = Object.assign({}, this.state.selectedFile);
        selectedFile[field] = file;
        this.setState({ selectedFile });
      }
    } catch (error) {
      // console.log(error);
    }
  }

  render() {
    const {
      model,
      listBankAccount,
      displayIssuedAt
    } = this.state;
    const { init } = this.props;
    const coboType = _.get(init, 'coboPobo.type.cobo', []);
    const bankAccount = _.get(init, 'bankAccount', []);
    const createdBy = { value: '', label: 'Chọn ví nạp tiền' };
    return (
      <div>
        <div className="page-header page-header-light">
          <div className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="text-center d-lg-none w-100">
              <button type="button" className="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-second">
                <i className="icon-menu7 mr-2"></i>Profile navigation</button>
            </div>
          </div>
          <div className="content">
            <div className="d-flex align-items-start flex-column flex-md-row">
              <div className="tab-content w-100 overflow-auto order-2 order-md-1">

                <div className="card">
                  <div className="card-header header-elements-inline">
                    <h5 className="card-title">Phiếu Thu Tiền</h5>
                    <div className="header-elements">
                    </div>
                  </div>
                  <div className="card-body">
                    <form onSubmit={this.onSubmit} >
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">
                            <label>Danh mục <span className="text-danger">*</span></label>
                            
                            <Select2
                              value={model.type}
                              style={{ width: '100%' }}
                              name="type"
                              onChange={this.onChangeHandle.bind(this, 'type')}
                              data={coboType}
                              className="form-control select-search select2-hidden-accessible"
                              options={{
                                placeholder: 'Chọn danh mục thu'
                              }}
                            />
                            
                          </div>
                          <div className="col-md-6">
                            <label>Tiêu đề <span className="text-danger">*</span></label>
                            <input
                              value={model.title}
                              ref={(input) => {
                                this.input.fullName = {
                                  title: 'Tiêu đề', isActive: true, required: true, e: input
                                }; 
                              }} 
                              onChange={this.onChangeHandle.bind(this, 'title')}
                              type="text"
                              className="form-control" />
                          </div>
                          
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          
                          <div className="col-md-6">
                            <label>Số tiền
                              <span className="text-danger"> * </span>

                            </label>

                            <div className="form-group">
                              <div className="input-group">
                                <span className="input-group-prepend">
                                  <span className="input-group-text">
                                VND
                                  </span>
                                </span>
                                <div className="multiselect-native-select">
                                  <Cleave
                                    value={model.amount}
                                    onChange={this.onChangeHandle.bind(this, 'amount')}
                                    className="form-control input-numeral"
                                    placeholder="Số tiền"
                                    options={{
                                      numeral: true,
                                      numeralThousandsGroupStyle: 'thousand',
                                      numeralDecimalScale: 0
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <label>Tài khoản nhận <span className="text-danger">*</span></label>
                            <SelectStateContainer
                              onChange={this.onChangeHandle.bind(this, 'bankAccount')}
                              options={listBankAccount}
                              placeholder="Chọn tài khoản nhận"
                            />
                          </div>

                        </div>
                      </div>

                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">
                            <label>Nạp tiền vào ví  <span className="text-danger">*</span></label>
                            <div className="form-group">
                              <Select
                                value={model.sourceId || createdBy}
                                onChange={this.onChangeHandle.bind(this, 'sourceId')}
                                options={this.props.listMembers()}
                                placeholder='Chọn ví thu tiền'
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label>Tài liệu đính kèm</label>
                            <UploadFileContainer
                              setFiles={this.setFiles.bind(this)}
                              name="documents"
                            />
                            <span className="form-text text-muted">Accepted formats: doc, docx, pdf, bmp, png, gif, jpg, jpeg, zip, rar, xls, xlxs</span>
                          </div>
                          
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-12">
                            <label>Nội dung</label>
                            <textarea 
                            // value={model.salary.description} 
                              onChange={this.onChangeHandle.bind(this, 'description')} 
                              rows="4" 
                              cols="4"
                              placeholder="Nhập nội dung thu tiền" 
                              className="form-control"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="text-right">
                        <button onClick={this.submit.bind(this, 'add')} className="btn btn-primary">
                          <i className="fa fa-save"></i> Thêm phiếu thu
                        </button>

                        <button onClick={this.submit.bind(this, 'addNew')} value="addNew" className="btn btn-success" style={{ marginLeft: 20 }}>
                          <i className="fa fa-save"></i> Lưu & tạo mới
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <NotificationContainer />
      </div>
     
    );
  }

  componentDidMount() {
    try {
      /**
       * 
       */
    } catch (error) {
      /**
       * 
       */
    }
  }

  componentWillUnmount() {
    try {
      /**
       * 
       */
    } catch (error) {
      /**
       * 
       */
    }
  }

  componentDidUpdate() {
    try {
      /**
       * 
       */
    } catch (error) {
      /**
       * 
       */
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      init: payload.generalReducer.init,
      userInfo: payload.generalReducer.userInfo,
      lang: payload.i18nState.lang,
      uploadPath: payload.generalReducer.uploadPath
    };
  }, mapDispatchToProps)(CreateCobo)
);
