import * as types from './wallets.types';

/**
 * 
 */

export function searchWalletHistory(payload, callback) {
  return {
    type: types.POST_WALLET_HISTORY,
    payload,
    callback
  };
}

export function searchWalletHistoryCredit(payload, callback) {
  return {
    type: types.POST_WALLET_HISTORY_CREDIT,
    payload,
    callback
  };
}

export function searchWallet(payload, callback) {
  return {
    type: types.POST_SEARCH_WALLET,
    payload,
    callback
  };
}

export function detailWallet(payload, callback) {
  return {
    type: types.POST_SEARCH_WALLET_DETAIL,
    payload,
    callback
  };
}

export function changeWallet(payload, callback) {
  return {
    type: types.PUT_WALLET_CHANGE,
    payload,
    callback
  };
}
