
/**
 * CRUD
 */

export const POST_SEARCH_MEMBER = 'POST_SEARCH_MEMBER';
export const POST_SEARCH_MEMBER_SUCCESS = 'POST_SEARCH_MEMBER_SUCCESS';
export const POST_SEARCH_MEMBER_FAIL = 'POST_SEARCH_MEMBER_FAIL';

export const POST_SEARCH_ACCOUNT = 'POST_SEARCH_ACCOUNT';
export const POST_SEARCH_ACCOUNT_SUCCESS = 'POST_SEARCH_ACCOUNT_SUCCESS';
export const POST_SEARCH_ACCOUNT_FAIL = 'POST_SEARCH_ACCOUNT_FAIL';

export const POST_SEARCH_BY_KEYWORD_ACCOUNT = 'POST_SEARCH_BY_KEYWORD_ACCOUNT';
export const POST_SEARCH_BY_KEYWORD_ACCOUNT_SUCCESS = 'POST_SEARCH_BY_KEYWORD_ACCOUNT_SUCCESS';
export const POST_SEARCH_BY_KEYWORD_ACCOUNT_FAIL = 'POST_SEARCH_BY_KEYWORD_ACCOUNT_FAIL';

export const POST_ACCOUNT_DETAIL = 'POST_ACCOUNT_DETAIL';
export const POST_ACCOUNT_DETAIL_SUCCESS = 'POST_ACCOUNT_DETAIL_SUCCESS';
export const POST_ACCOUNT_DETAIL_FAIL = 'POST_ACCOUNT_DETAIL_FAIL';

export const POST_ACCOUNT = 'POST_ACCOUNT';
export const POST_ACCOUNT_SUCCESS = 'POST_ACCOUNT_SUCCESS';
export const POST_ACCOUNT_FAIL = 'POST_ACCOUNT_FAIL';

export const PUT_ACCOUNT = 'PUT_ACCOUNT';
export const PUT_ACCOUNT_SUCCESS = 'PUT_ACCOUNT_SUCCESS';
export const PUT_ACCOUNT_FAIL = 'PUT_ACCOUNT_FAIL';

export const PUT_ACCOUNT_PROFILE = 'PUT_ACCOUNT_PROFILE';
export const PUT_ACCOUNT_PROFILE_SUCCESS = 'PUT_ACCOUNT_PROFILE_SUCCESS';
export const PUT_ACCOUNT_PROFILE_FAIL = 'PUT_ACCOUNT_PROFILE_FAIL';
