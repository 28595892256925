import React from 'react';
import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DropdownButton, Dropdown } from 'react-bootstrap';

class SearchService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.removeServiceHandle = this.removeServiceHandle.bind(this);
  }

  removeServiceHandle(serviceId) {
    this.props.removeService(serviceId);
  }

  renderServiceData(listService) {
    try {
      let result = [];
      const level = 0;
      if (_.isEmpty(listService) === false) {
        listService.forEach((item) => {
          result.push({
            id: item.id,
            title: item.title,
            type: item.type,
            description: item.description,
            level
              
          });
          if (_.isEmpty(item.children) === false) {
            result = this.listServiceHandleChildren(result, item.children, level);
          }
        });
      }
      if (_.isEmpty(result) === false) {
        return (result.map((item, i) => {
          const linkTo = `/service-modify/${item.id}`;
          if (item.level === 0) {
            return (<tr key={i}>
              <td>
                <div className="d-flex align-items-center">
                  <div>
                    <div className="media-title font-weight-semibold">
                      <span className="badge badge-mark border-primary mr-1"></span>
                      <Link to={linkTo} >{item.title}</Link>
                    </div><span className="text-muted">{item.description}</span></div>
                </div>
              </td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle variant={'default'} id={i}>
                    <i className="fa fa-list-ul" aria-hidden="true"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => this.props.history.push(linkTo)} >
                      <i className="fa fa-edit" aria-hidden="true"></i> Cập nhật
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.removeServiceHandle.bind(this, item.id)}>
                      <i className="fa fa-remove" aria-hidden="true"></i> Xóa
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>);
          }
          const padding = 30 * item.level;
          return (<tr key={i}>
            <td>
              <div className="d-flex align-items-center">
                <div>
                  <div style={{ paddingLeft: padding }} className="media-title font-weight-semibold">
                    {item.type === 'list' ? <span className="badge badge-mark border-primary mr-1"></span> : ''}
                    <Link to={linkTo} className="c-dark"> {item.title}</Link>
                  </div>
                  <span style={{ paddingLeft: padding }} className="text-muted">{item.description}</span></div>
              </div>
            </td>
            <td>
              <Dropdown>
                <Dropdown.Toggle variant={'default'} id={i}>
                  <i className="fa fa-list-ul" aria-hidden="true"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => this.props.history.push(linkTo)} >
                    <i className="fa fa-edit" aria-hidden="true"></i> Cập nhật
                  </Dropdown.Item>
                  <Dropdown.Item onClick={this.removeServiceHandle.bind(this, item.id)}>
                    <i className="fa fa-remove" aria-hidden="true"></i> Xóa
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
          );
        }));
      }
      return (<tr></tr>
      
      );
    } catch (error) {
      // console.log(error);
      return false;
    }
  }

  listServiceHandleChildren(parent, data, level) {
    try {
      const childLevel = level + 1;
      let result = Object.assign([], parent);
      
      if (_.isEmpty(data) === false) {
        data.forEach((item) => {
          result.push({
            id: item.id,
            title: item.title,
            type: item.type,
            description: item.description,
            level: childLevel
              
          });
          if (_.isEmpty(item.children) === false) {
            result = this.listServiceHandleChildren(result, item.children, childLevel);
          }
        });
        
        return result;
      }
      return [];
    } catch (error) {
      return [];
    }
  }

  render() {
    const { listService } = this.props;
    return (
      <div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header header-elements-sm-inline">
                <h6 className="card-title">Danh sách các quyền quản lý hệ thống</h6>
                <div className="header-elements">
                  <div className="header-elements">
                    <div className="align-self-md-center ml-md-3 mt-2 mt-md-0">
                      <Link to="/service-create" className="btn bg-teal-400">
                        <i className="icon-add mr-2"></i> Thêm dịch vụ
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table text-nowrap">
                  <thead>
                    <tr>
                      <th>Tên Dịch Vụ</th>
                      <th style={{ width: 100 }} >Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderServiceData(listService)}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    );
  }

  componentDidMount() {
    try {
      /**
       * 
       */
      
    } catch (error) {
      /**
       * 
       */
    }
  }

  componentWillUnmount() {
    try {
      /**
       * 
       */
    } catch (error) {
      /**
       * 
       */
    }
  }

  componentDidUpdate() {
    try {
      /**
       * 
       */
    } catch (error) {
      /**
       * 
       */
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      userInfo: payload.generalReducer.userInfo,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(SearchService)
);
