import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import SearchOrder from './searchOrder';
import * as OrderActions from '../../../redux/orders/orders.actions';
import * as ServiceActions from '../../../redux/services/services.actions';
import * as AgenciesActions from '../../../redux/agencies/agencies.actions';
import * as AccountActions from '../../../redux/accounts/accounts.actions';
import { PAGE_MAX_LIMIT, PAGE_LIMIT, ORDER_MANAGE_SCOPE } from '../../../configs/constants';
import store from '../../../configs/store.config';
import * as SupplierActions from '../../../redux/suppliers/suppliers.actions';

import {
  serviceDetail, 
  serviceHandleDatas, 
  localStoreFilter,
  checkScope
} from '../../../helpers';

class SearchOrderContainer extends React.Component {
  constructor(prop) {
    super(prop);

    if (checkScope(ORDER_MANAGE_SCOPE, this.props) === false) {
      NotificationManager.error('Chức năng không tồn tại', '', 1500);
      setTimeout(() => {
        this.props.history.push('/dashboard');
      }, 2000);
    }

    this.state = {
      listMember: [],
      searching: {},
      callbackInvoice: [],
      orders: [],
      listService: [],
      listAgency: [],
      listAccountState: []
    };

    this.getOrders = this.getOrders.bind(this);
    //
    this.listServiceHandleData = this.listServiceHandleData.bind(this);
    this.getServiceDetail = this.getServiceDetail.bind(this);

    this.onSubmit = this.onSubmit.bind(this);
    this.callback = this.callback.bind(this);
    //
    this.getTagSuggest = this.getTagSuggest.bind(this);
    this.buildGetTagSuggest = this.buildGetTagSuggest.bind(this);
    //
    this.getMembers = this.getMembers.bind(this);
    this.listMembers = this.listMembers.bind(this);
    //
    this.searchAgency = this.searchAgency.bind(this);
    this.callbackSearchAgency = this.callbackSearchAgency.bind(this);

    const filterSupplier = {
      filters: {
        group: ['supplier']
      },
      paging: {
        start: 0,
        limit: PAGE_LIMIT
      }
    };
    this.getSuppliers(filterSupplier);

    /**
     * 
     */
    this.getTagSuggest();
  }

  getSuppliers(args) {
    try {
      const { supplierAct } = this.props;
      supplierAct.searchSupplier(args);
    } catch (error) {
      // console.log('error', error);
    }
  }

  onSubmit = async (args) => {
    try {
      const { orderAct } = this.props;
      const query = Object.assign({}, args);
      if (query.description === '') {
        delete query.description;
      }
      orderAct.modifyOrderState(query, this.callback);

      const ordersFilter = localStoreFilter('searchOrder', 'getOrders');
      // TODO: neverbestboy remove
      // this.getOrders(ordersFilter || {
      //   filters: {
      //     profile: {
      //       fullName: '',
      //       phone: ''
      //     },
      //     customer: {
      //       id: '',
      //       title: ''
      //     },
      //     apply: {
      //       identifyCode: ''
      //     },

      //     tag: [],
      //     serviceIdentifyCode: '',
      //     estimatedAt: {
      //       from: '',
      //       to: ''
      //     }
      //   },
      //   paging: {
      //     start: 0,
      //     limit: PAGE_LIMIT
      //   }
      // });
    } catch (error) {
      // console.log('error', error);
    }
  }

  callback(status, data) {
    if (status) {
      this.setState({ callbackInvoice: data });

      const fails = _.get(data, 'fails', []);
      if (_.isEmpty(fails) === true) {
        NotificationManager.info('Cập nhật trạng thái thành công', '', 1500);
      } else {
        const orderIds = [];
        _.forEach(fails, (item) => {
          orderIds.push(`#${item.invoiceId}`);
        });
        NotificationManager.warning(`Đơn hàng ${orderIds.join(',')} xảy ra lỗi`, '', 3000);
      }
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }

  /**
   * 
   */
  searchAgency(args) {
    try {
      const { agenciesAct } = this.props;
      const query = Object.assign({}, args);
      query.filters.group = ['customer', 'agency', 'collaborator'];
      
      agenciesAct.searchAgency(query, this.callbackSearchAgency);
    } catch (error) {
      console.log('error', error);
    }
  }

  callbackSearchAgency(status, data) {
    if (status) {
      const listAgency = [];
      if (_.isEmpty(data) === false) {
        data.forEach((item) => {
          listAgency.push({ value: item.identifyCode, label: item.title });
        });
      }
      this.setState({ listAgency });
    }
  }

  /**
   * 
   */
  getMembers(args) {
    try {
      const { accountAct } = this.props;
      accountAct.searchAccount(args);
    } catch (error) {
      // console.log('error', error);
    }
  }

  listMembers() {
    const { accounts } = this.props;
    const listMember = [{
      label: 'Tất cả',
      value: 'all'
    }];
    try {
      if (_.isEmpty(accounts) === false) {
        accounts.forEach((item) => {
          listMember.push({ value: item.identifyCode, label: item.title, scopes: item.scopes });
        });
      }
      return listMember;
    } catch (error) {
      return listMember;
    }
  }
  /**
   * 
   */

  getOrders(args) {
    try {
      const { orderAct } = this.props;
      const filters = Object.assign({}, args.filters);
      const query = Object.assign({}, args);

      _.forEach(filters, (item, key) => {
        if (_.isObject(item) === true) {
          _.forEach(item, (subItem, key2) => {
            if (_.isEmpty(subItem) === true) {
              if (_.isNumber(subItem) === false) {
                delete filters[key][key2];
              }
            }
          });
        } else if (_.isEmpty(item) === true) {
          if (_.isNumber(item) === false) {
            delete filters[key];
          }
        }

        if (_.isEmpty(item) === true) {
          if (_.isNumber(item) === false) {
            delete filters[key];
          }
        }
      });
      query.filters = filters;
      orderAct.searchOrder(query, (status, data) => {
        console.log(status, data);
      });
      store.dispatch({
        type: 'LOCAL_STORE_FILTER',
        payload: {
          objID: 'searchOrder',
          objType: 'getOrders',
          query: args
        }
      });
    } catch (error) {
      // console.log('error', error);
    }
  }

  getServiceDetail(serviceIdentifyCode) {
    try {
      const { buildServices } = this.props;
      if (_.isEmpty(buildServices) === false) {
        return serviceDetail(buildServices, serviceIdentifyCode);
      }
      return {};
    } catch (error) {
      return {};
    }
  }

  listServiceHandleData() {
    try {
      const result = { list: [], setlectedItem: {} };
      const { buildServices } = this.props;

      if (_.isEmpty(buildServices) === false) {
        return serviceHandleDatas(buildServices);
      }
      return result;
    } catch (error) {
      return false;
    }
  }

  getTagSuggest(args = {}) {
    try {
      const { orderAct } = this.props;

      orderAct.orderTagSearch(args);
    } catch (error) {
      // console.log('error', error);
    }
  }

  buildGetTagSuggest() {
    const { orderTags } = this.props;
    try {
      const tagSuggestions = [];
      if (orderTags) {
        orderTags.forEach((element) => {
          tagSuggestions.push({
            label: element,
            value: element
          });
        });
      }
      return tagSuggestions;
    } catch (error) {
      return [];
    }
  }

  render() {
    return (
      <div>
        <SearchOrder
          listParentIdentify={this.state.listParentIdentify}
          callbackInvoice={this.state.callbackInvoice}
          listAgency={this.state.listAgency}
          errorMessage={this.state.errorMessage}
          getServiceDetail={this.getServiceDetail}
          getOrders={this.getOrders}
          listMembers={this.listMembers}
          onSubmit={this.onSubmit}
          listServiceHandleData={this.listServiceHandleData}
          getTagSuggest={this.getTagSuggest}
          buildGetTagSuggest={this.buildGetTagSuggest}
          searchAgency={this.searchAgency}
          userInfo={this.userInfo}
          dispatch={this.props.dispatch}
        />
      </div>

    );
  }

  componentWillMount() {
    try {
      const { serviceAct } = this.props;
      serviceAct.listService({});

      const { agenciesAct } = this.props;
      agenciesAct.searchAgency({
        filters: {
          group: ['agency', 'collaborator', 'supplier']
          // id: 1
        },
        paging: {
          start: 0,
          limit: 1000
        }
      });

      this.getMembers({
        filters: {
          group: ['staff']
        },
        paging: {
          start: 0,
          limit: PAGE_MAX_LIMIT
        }

      });

      const ordersFilter = localStoreFilter('searchOrder', 'getOrders');
      this.getOrders(ordersFilter || {
        filters: {
          profile: {
            fullName: '',
            phone: ''
          },
          customer: {
            id: '',
            title: ''
          },
          apply: {
            identifyCode: ''
          },

          tag: [],
          serviceIdentifyCode: '',
          estimatedAt: {
            from: '',
            to: ''
          }
        },
        paging: {
          start: 0,
          limit: PAGE_LIMIT
        }
      });
    } catch (error) {
      // console.log(error);
    }
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    //
    dispatch: bindActionCreators(dispatch, dispatch),
    serviceAct: bindActionCreators(ServiceActions, dispatch),
    orderAct: bindActionCreators(OrderActions, dispatch),
    agenciesAct: bindActionCreators(AgenciesActions, dispatch),
    accountAct: bindActionCreators(AccountActions, dispatch),
    supplierAct: bindActionCreators(SupplierActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    accounts: rootState.accountReducer.accounts,
    agencies: rootState.agencyReducer.agencies,
    orderTags: rootState.orderReducer.orderTags,
    orders: rootState.orderReducer.orders,
    buildServices: rootState.serviceReducer.buildServices,
    init: rootState.generalReducer.init,
    userInfo: rootState.generalReducer.userInfo,
    localStoreFilter: rootState.generalReducer.localStoreFilter,
    suppliers: rootState.supplierReducer.suppliers,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(SearchOrderContainer);
