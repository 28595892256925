import _ from 'lodash';
import * as types from './utilities.types';

const initialState = {
  sendSmsInfo: {},
  pushSMS: false
};

export default function utilities(state = initialState, action) {
  switch (action.type) {
    case types.SEND_SMS: {
      return {
        ...state,
        sendSmsInfo: action.payload
      };
    }

    default:
      return state;
  }
}
