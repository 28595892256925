import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { bindActionCreators } from 'redux';
import ModifyCobo from './modifyCobo';
import * as CoboPoboActions from '../../../redux/coboPobo/coboPobo.actions';
import { SYSTEM_SERVICE_SCOPE } from '../../../configs/constants';
import {
  checkScope
} from '../../../helpers';

class ModifyCoboContainer extends React.Component {
  constructor(prop) {
    super(prop);
    if (checkScope(SYSTEM_SERVICE_SCOPE, this.props) === false) {
      NotificationManager.error('Chức năng không tồn tại', '', 1500);
      setTimeout(() => {
        this.props.history.push('/dashboard');
      }, 2000);
    }

    this.state = {
      errorMessage: {}
    };
    this.callback = this.callback.bind(this);
    // this.callbackNew = this.callbackNew.bind(this);    
    this.getCoboPoboInfo = this.getCoboPoboInfo.bind(this);
    this.callbackGetCoboPoboInfo = this.callbackGetCoboPoboInfo.bind(this);
  }

  onSubmit = async (args, type) => {
    try {
      const { coboPoboAct } = this.props;
      const query = Object.assign({}, args);
      const payload = {
        id: query.id,
        data: query
      };
      delete payload.data.id;
      delete payload.data.amount;
      delete payload.data.method;
      delete payload.data.createdBy;
      coboPoboAct.modify(payload, this.callback);
    } catch (error) {
      // console.log('error', error);
    }
  }

  callback(status, data) {
    if (status) {
      NotificationManager.info('Cập nhật phiếu thành công, đang chuyển về trang quản lý', '', 1000);
      setTimeout(() => {
        this.props.history.push('/cobo-pobo');
      }, 1500);
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }

  getCoboPoboInfo(args) {
    try {
      const { coboPoboAct } = this.props;
      
      coboPoboAct.detail(args, this.callbackGetCoboPoboInfo);
    } catch (error) {
      console.log('error', error);
    }
  }

  callbackGetCoboPoboInfo(status, data) {
    try {
      if (status === false || _.isEmpty(data) === true) {
        NotificationManager.error('Thông tin không hợp lệ', '', 1000);
        setTimeout(() => {
          this.props.history.push('/cobo-pobo');
        }, 1500);
      } 
    } catch (error) {
      console.log(error);
    }
  }

  componentDidUpdate(oldProps) {
    const id = this.props.computedMatch.params.id;
    this.getCoboPoboInfo({ filters: { id: [id] } });
  }

  render() {
    return (
      <div>
        <ModifyCobo
          errorMessage={this.state.errorMessage}
          onSubmit={this.onSubmit}
          userInfo={this.userInfo}
        />
          
      </div>
        
    );
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    coboPoboAct: bindActionCreators(CoboPoboActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(ModifyCoboContainer);
