import _ from 'lodash';
import * as types from './supports.types';

const initialState = {
  customerSupport: {},
  customerSupports: [],
  customerSupportByType: {},
  socketRemoveCustomerSupport: {}
};

export default function supports(state = initialState, action) {
  switch (action.type) {
    case types.SOCKET_REMOVE_CUSTOMER_SUPPORTCALL_LOG: {
      return {
        ...state,
        socketRemoveCustomerSupport: action.payload
      };
    }
    case types.POST_SEARCH_CUSTOMER_SUPPORT_BY_CUSTOMER_TYPE_SUCCESS: {
      const { customerSupportByType } = initialState;
      const type = _.get(action, 'query.filters.information.type', '1');
      return {
        ...state,
        customerSupportByType: {
          type,
          data: action.payload
        }
      };
    }

    case types.POST_SEARCH_CUSTOMER_SUPPORT_SUCCESS: {
      return {
        ...state,
        customerSupports: action.payload
      };
    }
    case types.POST_SEARCH_CUSTOMER_SUPPORT_DETAIL_SUCCESS: {
      return {
        ...state,
        customerSupport: action.payload
      };
    }
    default:
      return state;
  }
}
