import * as types from './general.types';

/**
 * 
 * @param {*} payload 
 * @param {*} callback 
 */

export function getInit(payload, callback) {
  return {
    type: types.GET_INIT,
    payload,
    callback
  };
}

export function postProfileSearch(payload, callback) {
  return {
    type: types.POST_PROFILE_SEARCH,
    payload,
    callback
  };
}

export function getScopes(payload, callback) {
  return {
    type: types.GET_SCOPE,
    payload,
    callback
  };
}

export function postAccountMenu(payload, callback) {
  return {
    type: types.POST_ACCOUNT_MENU,
    payload,
    callback
  };
}

export function postAccountInfo(payload, callback) {
  return {
    type: types.POST_ACCOUNT_INFO,
    payload,
    callback
  };
}

export function postUserChangePass(payload, callback) {
  return {
    type: types.POST_ACCOUNT_CHANGE_PASS,
    payload,
    callback
  };
}

export function postUserLogin(payload, callback) {
  return {
    type: types.POST_ACCOUNT_LOGIN,
    payload,
    callback
  };
}

export function postUserLogout(payload, callback) {
  return {
    type: types.POST_ACCOUNT_LOGOUT,
    payload,
    callback
  };
}

export function postUserUpdateInfo(payload, callback) {
  return {
    type: types.POST_ACCOUNT_UPDATE_INFO,
    payload,
    callback
  };
}

export function putCountryArrival(payload, callback) {
  return {
    type: types.PUT_COUNTRY_ARRIVAL,
    payload,
    callback
  };
}
