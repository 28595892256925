import { API_URL } from '../../configs/api.config';
import Request from '../middleware/request';

const callLogAPI = {
  /**
   * 
   */
  postSearchCustomerSupport(args) {
    return Request.callAPI('post', `${API_URL}/CustomerSupport/Search`, { ...args });
  },

  /**
   * 
   */
  postCreateCustomerSupport(args) {
    return Request.callAPI('post', `${API_URL}/CustomerSupport`, { ...args });
  },
  putModifyCustomerSupport(args) {
    return Request.callAPI('post', `${API_URL}/CustomerSupport/${args.id}`, { ...args.data });
  },
  deleteCustomerSupport(args) {
    return Request.callAPI('delete', `${API_URL}/CustomerSupport/${args.id}`);
  }
};

export default callLogAPI;

