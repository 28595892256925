import _ from 'lodash';
import * as types from './branchs.types';

const initialState = {
  listBranch: [],
  branchs: [],
  branch: {}
};

export default function branchs(state = initialState, action) {
  switch (action.type) {
    case types.POST_SEARCH_LIST_BRANCH_SUCCESS: {
      const listBranch = [{ value: 'all', label: 'Tất cả chi nhánh' }];
      if (_.isEmpty(action.payload) === false) {
        action.payload.forEach((element) => {
          listBranch.push({
            id: element.id, value: element.identifyCode, label: element.title, scopes: element.scopes 
          });
        });
      }
      return {
        ...state,
        listBranch
      };
    }
    case types.POST_SEARCH_BRANCH_SUCCESS: {
      return {
        ...state,
        branchs: action.payload
      };
    }
    case types.GET_BRANCH_SUCCESS: {
      return {
        ...state,
        branch: action.payload
      };
    }

    default:
      return state;
  }
}
