import React, { PureComponent } from 'react';

import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import I18n from 'redux-i18n';

import { connect, Provider } from 'react-redux';
import { persistStore, createTransform } from 'redux-persist';
import CryptoJS from 'crypto-js';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import * as io from 'socket.io-client';
import * as serviceWorker from './serviceWorker';
import { onConnect } from './helpers';

import history from './history';

import translations from './translations';
import store from './configs/store.config';
import AppRoutes from './routers/routers';
import * as GeneralActions from './redux/general/general.actions';

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showCallLogModal: false,
      loading: true
    };
  }

  componentDidMount() {
    const encryptTransform = createTransform(
      (inboundState) => {
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(inboundState), 'OBAASH7HOQCFOYYD');
        return encrypted.toString();
      },
      (outboundState) => {
        const decrypted = CryptoJS.AES.decrypt(outboundState, 'OBAASH7HOQCFOYYD');
        return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
      },
      { whitelist: ['i18nState', 'apiResponse', 'generalReducer', 'accountsReducer'] }

    );

    persistStore(store, {
      transforms: [encryptTransform],
      whitelist: ['i18nState', 'apiResponse', 'generalReducer', 'accountsReducer']
    }, () => {
      // store.dispatch({ type: 'GET_INIT' });
      store.dispatch({ type: 'POST_ACCOUNT_MENU' });
      store.dispatch({ type: 'GET_SOCKET_CALL_LOG' });
      this.setState({ loading: false });
    });

    // 
    try {
      const socket = onConnect();
      if (_.isEmpty(socket) === false) {
        socket.on('message', (body) => {
          if (_.isEmpty(body) === false) {
            switch (body.event) {
              case 'NotifyBalance': {
                store.dispatch({
                  type: 'SOCKET_WALLET',
                  payload: body.data
                });
                break;
              }
  
              case 'NotifyInvoice': {
                store.dispatch({
                  type: 'SOCKET_ORDER',
                  payload: body.data
                });
                break;
              }
              
              case 'NotifyVoip': {
                store.dispatch({
                  type: 'PUSH_SOCKET_CALL_LOG',
                  payload: body.data
                });
                break;
              }
  
              case 'NotifyCustomerSupport': {
                store.dispatch({
                  type: 'SOCKET_REMOVE_CUSTOMER_SUPPORTCALL_LOG',
                  payload: body.data
                });
                break;
              }
              
              default:
                break;
            }
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    if (this.state.loading === true) {
      return <div style={{ textAlign: 'center' }}>Khởi tạo ứng dụng...</div>;
    }
    const language = store.getState();
    
    return (
      <Provider store={store}>
        <I18n translations={translations} initialLang={language.i18nState.lang}>
          <Router history={history}>
            <AppRoutes />            
          </Router>
        </I18n>
      </Provider>
    );
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    generalAct: bindActionCreators(GeneralActions, dispatch)
  };
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
