import React from 'react';
import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import Select from 'react-select';
import validator from 'validator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DelayInput } from 'react-delay-input';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import ReactAudioPlayer from 'react-audio-player';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import PagingContainer from '../../ultils/paging.container';
import { PAGE_LIMIT, PAGE_MAX_LIMIT } from '../../../configs/constants';
import store from '../../../configs/store.config';
import {
  paging,
  formatHourMinute,
  formatDateFilter,
  formatDateRange,
  formatDate2,
  localStoreFilter,
  localStorePageNum,
  formatDateFilter2
} from '../../../helpers';

class SearchCoboPobo extends React.Component {
  constructor(props) {
    super(props);

    const dateRangeTitle = 'Chọn thời gian';
    this.state = {
      dateRangeTitle,
      ranges: {
        'Clear date': [1, 1],
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      },
      type: [{
        value: 1,
        label: 'Số dư ví đối tác xử lý'
      },
      {
        value: 7,
        label: 'Số dư ví tổng'
      },
      {
        value: 2,
        label: 'Công nợ khách hàng'
      }, {
        value: 3,
        label: 'Doanh thu nhân viên'
      }, {
        value: 4,
        label: 'Lợi nhuận tạm tính HT'
      }, {
        value: 5,
        label: 'Lợi nhuận tạm tính chưa HT'
      }
      // , {
      //   value: 6,
      //   label: 'Thu chi'
      // }
      ],
      model: {
        finishedAt: {
          from: '',
          to: ''
        }
      }
    };
    this.onSearchChangeHandle = this.onSearchChangeHandle.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleDatePicker(e, picker) {
    e.preventDefault();
    const model = Object.assign({}, this.state.model);
    if (picker.startDate.unix() > 0) {
      const pageNum = 1;
      const dateRangeTitle = formatDateFilter(picker.startDate, picker.endDate);
      const date = formatDateRange(picker.startDate, picker.endDate);
      model.finishedAt.from = date.startDate;
      model.finishedAt.to = date.endDate;
      this.setState({ model, dateRangeTitle });
    } else {
      this.setState({ model });
    }
  }

  onSearchChangeHandle(key, e) {
    try {
      const { model } = this.state;
      switch (key) {
        case 'type': {
          model.type = _.get(e, 'target.value', _.get(e, 'value', false));
          break;
        }
        default: {
          model[key] = _.get(e, 'target.value', _.get(e, 'value', ''));
          break;
        }
      }
      this.setState({
        model
      });
    } catch (error) {
      // console.log(error);
    }
  }

  downloadFile(e) {
    e.preventDefault();
    window.open(this.props.downloadUrl, '_blank').focus();
  }

  onSubmit(e) {
    try {
      e.preventDefault();
      const model = Object.assign({}, this.state.model);
      if (!model.type) {
        NotificationManager.error('Loại xuất báo cáo không được để trống', '', 1500);
        return false;
      }
      if (model.type > 2 && model.type < 7) {
        if (!model.finishedAt.from) {
          NotificationManager.error('Thời gian bắt đầu không được để trống', '', 1500);
          return false;
        }
        if (!model.finishedAt.to) {
          NotificationManager.error('Thời gian kết thúc không được để trống', '', 1500);
          return false;
        }
      }

      this.props.dispatch({ type: 'SHOW_LOADING' });
      this.props.onSubmit(model, this.state.typeOfSubmit);
    } catch (error) {
      console.log(error);
      // HIDE LOADING
      this.props.dispatch({ type: 'HIDE_LOADING' });
    } 
    return true;
  }

  render() {
    const {
      types, searching, ranges, dateRangeTitle, typeDefaultValue, states, createdById, model
    } = this.state;
    const { downloadUrl, summary } = this.props;
    console.log(summary);
    return (
      <div>
        <div className="row">
          <div className="col-lg-3">
            <div className="card bg-slate-400">
              <div className="card-body" style={{ 
                height: 102, 
                paddingRight: 0
              }}>
                <div className="d-flex" style={{ padding: '7px 0px' }}>
                  <span style={{ fontSize: 18, fontWeight: '400' }}>
                    {summary.amountVnhub} đ
                  </span>
                  {/* <div style={{ position: 'absolute', top: 10, right: 10 }}>
                      <FontAwesomeIcon 
                        style={{ fontSize: 16 }} 
                        data-tip="Số Tiền được phép sử dụng bao gồm cả hạn mức" 
                        icon={faQuestionCircle} />
                    </div> */}
                </div>
                <div>
                  <div className="font-size-lg opacity-75">
            Lợi nhuận tạm tính
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="card bg-blue-400">
              <div className="card-body" style={{ 
                height: 102, 
                paddingRight: 0
              }}>
                <div className="d-flex" style={{ padding: '7px 0px' }}>
                  <span style={{ fontSize: 18, fontWeight: '400' }}>
                    {summary.amountAll} đ
                  </span>
                  {/* <div style={{ position: 'absolute', top: 10, right: 10 }}>
                      <FontAwesomeIcon 
                        style={{ fontSize: 16 }} 
                        data-tip="Số Tiền được phép sử dụng bao gồm cả hạn mức" 
                        icon={faQuestionCircle} />
                    </div> */}
                </div>
                <div>
                  <div className="font-size-lg opacity-75">
            Tổng tiền công ty đang giữ
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="card bg-pink-400">
              <div className="card-body" style={{ 
                height: 102, 
                paddingRight: 0
              }}>
                <div className="d-flex" style={{ padding: '7px 0px' }}>
                  <span style={{ fontSize: 18, fontWeight: '400' }}>
                    {summary.amountUser} đ
                  </span>
                  {/* <div style={{ position: 'absolute', top: 10, right: 10 }}>
                      <FontAwesomeIcon 
                        style={{ fontSize: 16 }} 
                        data-tip="Số Tiền được phép sử dụng bao gồm cả hạn mức" 
                        icon={faQuestionCircle} />
                    </div> */}
                </div>
                <div>
                  <div className="font-size-lg opacity-75">
            Tiền đang giữ của khách hàng
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="card bg-teal-400">
              <div className="card-body" style={{ 
                height: 102, 
                paddingRight: 0
              }}>
                <div className="d-flex" style={{ padding: '7px 0px' }}>
                  <span style={{ fontSize: 18, fontWeight: '400' }}>
                    {summary.amountCredit} đ
                  </span>
                  {/* <div style={{ position: 'absolute', top: 10, right: 10 }}>
                      <FontAwesomeIcon 
                        style={{ fontSize: 16 }} 
                        data-tip="Số Tiền được phép sử dụng bao gồm cả hạn mức" 
                        icon={faQuestionCircle} />
                    </div> */}
                </div>
                <div>
                  <div className="font-size-lg opacity-75">
            Khách hàng đang nợ công ty
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>        
        <div className="card">
          <div className="card-header bg-transparent header-elements-inline">
            <h5 className="card-title">Xuất báo cáo</h5>

          </div>
          <form onSubmit={this.onSubmit} >
            <div className="card-body">
              <div className="row">
                <div className="form-group col-md-3">
                  <Select
                    onChange={this.onSearchChangeHandle.bind(this, 'type')}
                    options={this.state.type}
                    placeholder='Chọn loại cần xuất báo cáo'
                  />
                </div>
                {model.type > 2 && model.type < 7
                  ? <div className="form-group col-md-6">
                    <DateRangePicker
                      className="btn btn-light daterange-predefined"
                      onApply={this.handleDatePicker.bind(this)}
                      ranges={ranges}
                    >
                      <button onClick={(e) => { e.preventDefault(); }} style={{ padding: '7px 15px' }}>
                        <i className="icon-calendar22 mr-2"></i>
                        <span> {dateRangeTitle} </span>
                      </button>
                    </DateRangePicker>

                  </div>
                  : ''}
              </div>
              <div className="text-left">
                <button className="btn btn-primary">
                  <i className="fa fa-save"></i> Xuất dữ liệu
                </button>
              </div>
              {downloadUrl !== ''
                ? <div className="text-center" style={{ paddingTop: 20, paddingBottom: 20 }}>
                  <button onClick={this.downloadFile} className="btn btn-warning">BẤM VÀO ĐÂY ĐỂ DOWNLOAD</button>
                </div>
                : ''}
            </div>
          </form>
        </div>
        
      </div>

    );
  }
  
  componentDidUpdate(oldProps) {
    try {
      /**
       * 
       */
      // 
    } catch (error) {
      // console.log(error);
      /**
       * 
       */
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(SearchCoboPobo)
);
