import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { sha256 } from 'js-sha256';
import Profile from './profile';

import * as AccountActions from '../../../redux/accounts/accounts.actions';
import * as DepartmentActions from '../../../redux/departments/departments.actions';
import * as BranchActions from '../../../redux/branchs/branchs.actions';
import { PAGE_MAX_LIMIT } from '../../../configs/constants';

class ProfileContainer extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = {
      listMember: [],
      listDelegate: [],
      listDepartment: [],
      listBranch: [],
      listTag: [],
      groupScope: {},
      departmentInfo: {},
      disabledDepartment: true
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.callback = this.callback.bind(this);
    //
    this.groupScope = this.groupScope.bind(this);
    //
    //
    this.getDelegates = this.getDelegates.bind(this);
    this.renderDelegate = this.renderDelegate.bind(this);
    //
    this.getMembers = this.getMembers.bind(this);
    this.getMember = this.getMember.bind(this);

    setTimeout(() => {
      this.getMembers({
        filters: {
          // group: ['customer']
        }, 
        paging: {
          start: 0,
          limit: PAGE_MAX_LIMIT
        }
      });
    }, 500);
  }

  onSubmit = async (args) => {
    try {
      const { accountAct } = this.props;
      const query = Object.assign({}, args);
      delete query.rePassword;
      
      if (query.password) {
        query.password = sha256(query.password);
      } else {
        delete query.password;
      }

      accountAct.modifyAccountProfile(query, this.callback);
    } catch (error) {
      console.log('error', error);
    }
  }

  callback(status, data) {
    if (status) {
      NotificationManager.info('Cập nhật tài khoản nhân viên thành công', '', 1000);
      setTimeout(() => {
        this.props.history.push('/dashboard');
      }, 1500);
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }

  /**
   * 
   */
  getDelegates(args) {
    try {
      const { accountAct } = this.props;
      accountAct.searchByKeywordAccount(args);
    } catch (error) {
      console.log('error', error);
    }
  }

  renderDelegate(data) {
    const result = [];
    try {
      if (_.isEmpty(data) === false) {
        _.forEach(data, (item) => {
          result.push({ value: item.identifyCode, label: item.title });
        });
      }
      return result;
    } catch (error) {
      return result;
      // console.log('error', error);
    }
  }

  /**
   * 
   */

  getMembers(args) {
    try {
      const { accountAct } = this.props;
      accountAct.searchAccount(args);
    } catch (error) {
      // console.log('error', error);
    }
  }

  getMember() {
    try {
      const { accounts, init } = this.props;
      if (_.isEmpty(accounts) === false) {
        const listMember = [{
          label: 'Không',
          value: 'root'
        }];
        const listDelegate = [];
        let memberInfo = {};
        
        const id = _.get(init, 'accountInfo.id', '').toString();
        accounts.forEach((item) => {
          if (item.id.toString() !== id) {
            listMember.push({ value: item.identifyCode, label: item.title, scopes: item.scopes });
            listDelegate.push({ value: item.identifyCode, label: item.title, scopes: item.scopes });
          } else {
            memberInfo = item;
          }
        });
        return { memberInfo, listMember, listDelegate };
      } 
      
      return { };
    } catch (error) {
      console.log(error);
      return {};
    }
  }

  groupScope(memberInfo) {
    const { listScope } = this.props;
    const selectedScopes = memberInfo.scopes;
    const groupScope = {};
    const listTag = {};
    listScope.forEach((element) => {
      element.tag.forEach((tag) => {
        if (_.isEmpty(groupScope[tag]) === true) {
          groupScope[tag] = [];
          listTag[tag] = true;
        }
        groupScope[tag].push({
          code: element.code,
          title: element.description,
          checked: selectedScopes.indexOf(element.code) >= 0
        });
      });
    });
    this.setState({
      listTag: Object.keys(listTag),
      groupScope
    });
  }

  render() {
    const {
      memberInfo, listMember, groupScope, listTag 
    } = this.state;
    return (
      <div>
        <Profile
          memberInfo={memberInfo}
          listMember={listMember}
          groupScope={groupScope}
          listTag={listTag}
          renderDelegate={this.renderDelegate}
          getMember={this.getMember}
          getDepartments={this.getDepartments}
          onSubmit={this.onSubmit}
          userInfo={this.userInfo}
          groupScopeFunc={this.groupScope}
        />
        <NotificationContainer />
      </div>
        
    );
  }
  
  componentWillMount() {
    try {
      this.getDelegates({
        filters: {
          // group: ['staff']
        }
      });
    } catch (error) {
      // console.log(error);
    }
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    //
    accountAct: bindActionCreators(AccountActions, dispatch),
    departmentAct: bindActionCreators(DepartmentActions, dispatch),
    branchAct: bindActionCreators(BranchActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    accounts: rootState.accountReducer.accounts,
    init: rootState.generalReducer.init,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(ProfileContainer);
