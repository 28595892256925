import _ from 'lodash';
import * as types from './link.types';

const initialState = {
  linkInfo: {}
};

export default function general(state = initialState, action) {
  switch (action.type) {
    case 'POST_LINK_TELEGRAM_SUCCESS': {
      return {
        ...state,
        linkInfo: action.payload
      };
    }
    case 'POST_LINK_GETINFO_SUCCESS': {
      return {
        ...state,
        linkInfo: action.payload
      };
    }

    default:
      return state;
  }
}
