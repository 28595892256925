import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SearchMember from './searchMember';
import * as AccountActions from '../../../redux/accounts/accounts.actions';
import * as BranchActions from '../../../redux/branchs/branchs.actions';
import { PAGE_LIMIT } from '../../../configs/constants';
import store from '../../../configs/store.config';
import {
  localStoreFilter
} from '../../../helpers';

class SearchMemberContainer extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = {
      listMember: []
    };
    this.getMembers = this.getMembers.bind(this);
  }

  getMembers(args) {
    try {
      const { accountAct } = this.props;
      const filters = Object.assign({}, args.filters);
      const query = Object.assign({}, args);
      Object.keys(filters).forEach((key) => {
        if (!filters[key]) {
          delete filters[key];
        }
      });
      query.filters = filters;
      accountAct.searchMember(query);

      store.dispatch({
        type: 'LOCAL_STORE_FILTER',
        payload: {
          objID: 'searchMember',
          objType: 'getMembers',
          query
        }
      });
    } catch (error) {
      // console.log('error', error);
    }
  }

  getListBranch(filter) {
    try {
      const { branchAct } = this.props;
      branchAct.searchBranchList(filter);
    } catch (error) {
      // console.log('error', error);
    }
  }

  render() {
    const { listMember } = this.state;
    return (
      <div>
        <SearchMember
          listMember={listMember}
          errorMessage={this.state.errorMessage}
          userInfo={this.userInfo}
          getMembers={this.getMembers}
        />
      </div>
    );
  }

  componentWillMount() {
    try {
      const memberFilter = localStoreFilter('searchMember', 'getMembers');
      
      const filter = memberFilter || {
        filters: {
          group: ['staff'],
          state: ['working', 'maternityLeave', 'unknown']
        },
        paging: {
          start: 0,
          limit: PAGE_LIMIT
        }
      };
      
      this.getMembers(filter);
      this.getListBranch({
        filter: {
          type: 'branch'
        }
      });
    } catch (error) {
      // console.log(error);
    }
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    accountAct: bindActionCreators(AccountActions, dispatch),
    branchAct: bindActionCreators(BranchActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    localStoreFilter: rootState.generalReducer.localStoreFilter,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(SearchMemberContainer);
