import _ from 'lodash';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import Select2 from 'react-select2-wrapper';
import InputMask from 'react-input-mask';
import Cleave from 'cleave.js/react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Moment from 'moment';
import { Tabs, Tab } from 'react-bootstrap';
import { mode } from 'crypto-js';
import UploadFileContainer from '../../ultils/uploadFile.container';
import SelectStateContainer from '../../ultils/selectState.container';
import {
  fileUpload, formatDate, formatDateTimeChatBox
} from '../../../helpers';

class ModifyPobo extends React.Component {
  constructor(props) {
    super(props);
    this.input = {};
    this.state = {
      disabledDepartment: true,
      selectedFile: [],
      departmentTitle: '',
      typeOfSubmit: false,
      branchTitle: '',
      birthdayDisplay: '',
      bankAccountDefaultValue: {},
      listBankAccount: [],
      joinedDisplay: '',
      uploading: false,
      allowance: [{ key: '', value: '' }],
      displayIssuedAt: formatDate(new Date()),
      model: {
        id: '',
        method: 'POBO',
        type: '',
        title: '',
        amount: '',
        bankAccount: '',
        description: '',
        issuedAt: new Date(),
        documents: [],
        createdBy: {
          id: 0
        }
      },
      discounts: []
    };

    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.setListBankAccount = this.setListBankAccount.bind(this);
    this.removeUploadedFile = this.removeUploadedFile.bind(this);
    this.setFiles = this.setFiles.bind(this);
  }

  componentWillMount() {
    this.setListBankAccount();
  }

  removeUploadedFile(index, e) {
    try {
      const model = Object.assign({}, this.state.model);
      const files = _.get(model, 'documents', false);
      if (files !== false) {
        model.documents.splice(index, 1);
        this.setState({ model });
      } else {
        NotificationManager.error('Thông tin không hợp lệ', '', 3000);
      }
    } catch (error) {
      // console.log(error);
    }
  }

  setListBankAccount() {
    const { init } = this.props;
    const bankAccounts = _.get(init, 'bankAccount', []);
    const listBankAccount = [];
    const { model } = this.state;

    if (_.isEmpty(bankAccounts) === false) {
      _.forEach(bankAccounts, (item) => {
        listBankAccount.push({
          value: item.key,
          label: item.text,
          color: item.color
        });
      });
      this.setState({ listBankAccount });
    }
  }

  validateOnSubmit(model) {
    if (!model.type) {
      NotificationManager.error('Danh mục không được để trống', '', 1500);
      return false;
    }
    if (!model.amount || model.amount < 1) {
      NotificationManager.error('Số tiền không được để trống và phải lớn hơn 0', '', 1500);
      return false;
    }
    if (!model.bankAccount) {
      NotificationManager.error('Tài khoản nhận không được để trống', '', 1500);
      return false;
    }
    if (!model.issuedAt) {
      NotificationManager.error('Ngày tạo nhận không được để trống', '', 1500);
      return false;
    }
    return true;
  }

  renderDownloadFile() {
    try {
      const { model } = this.state;
      const files = _.get(model, 'documents', false);

      const style = {
        backgroundColor: 'whitesmoke',
        paddingLeft: 20,
        marginBottom: 10,
        borderLeft: 'solid 3px darkgrey',
        height: '2.25003rem'
      };

      if (_.isEmpty(files) === true) {
        return (<div style={style} className="uniform-uploader" >
          <span className="form-text text-muted">None</span></div>);
      }
      const path = require('path');
      return (files.map((file, i) => {
        const link = `${this.props.downloadPath}${file}`;
        const fileExt = path.extname(link);
        const basename = path.basename(link);
        let fileExtClassName = '';
        switch (fileExt) {
          case '.docx':
          case '.doc':
            fileExtClassName = 'fa fa-file-word-o';
            break;

          default:
            fileExtClassName = 'fa fa-file-pdf-o';
            break;
        }
        return (
          <div className="uniform-uploader"
            key={`renderDownloadFile_${i}`}
            style={style}>
            <span className="form-text text-muted">
              <a target="_blank" href={link}>
                <i style={{ paddingRight: 10 }} className={fileExtClassName}></i>
                {basename}
              </a>
            </span>
            { (_.get(model, 'createdBy.id', 0)) === 0 ? ''
            
              : <i
                onClick={this.removeUploadedFile.bind(this, i)}
                style={{
                  position: 'absolute', right: 20, top: 10, cursor: 'pointer'
                }}
                className="fa fa-remove"></i>
            }

          </div>
        );
      }));
    } catch (error) {
      console.log(error);
      return (<div></div>);
    }
  }
  
  onChangeHandle(key, e) {
    try {
      const { buildServices, wallet, agency } = this.props;
      const {
        listBankAccount
      } = this.state;

      const model = Object.assign({}, this.state.model);
      const result = {};

      if (_.isArray(e) === true) {
        // switch (key) {
        // }
      }
      if (_.isArray(e) === false) {
        switch (key) {
          case 'issuedAt': {
            const value = _.get(e, 'target.value', _.get(e, 'value', false));
            const dateFormat = 'YYYY/MM/DD HH:mm:ss';
            const splittedDate = value.split('/');

            if (splittedDate.length === 3 && splittedDate[2].length === 4) {
              const date = Moment(`${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]} 12:00:00`, dateFormat);
              result.displayIssuedAt = value;
              if (date.isValid() === true) {
                if (key === 'issuedAt') {
                  model.issuedAt = date.format();
                }
              }
            }

            break;
          }
          case 'amount': {
            const amount = _.get(e, 'target.rawValue', _.get(e, 'value', 0));
            model[key] = Number(amount);
            break;
          }
          case 'bankAccount':
            
            model[key] = _.get(e, 'target.value', _.get(e, 'value', false));
            result.bankAccountDefaultValue = _.find(listBankAccount, { value: model[key] });
            break;

          default:
            model[key] = _.get(e, 'target.value', _.get(e, 'value', false));
            break;
        }
      }
      result.model = model;

      this.setState(result);
    } catch (error) {
      console.log(error);
    }
  }

  async validateInputOnSubmit() {
    return new Promise((resolve, reject) => {
      const input = this.input;
      let flag = true;
      _.forEach(input, (field) => {
        if (flag === false) return false;
        if (field.isActive === true) {
          if (field.required === true) {
            if (_.get(field, 'e.value', '') === '' || _.get(field, 'e.value', '') === '__/__/____') {
              NotificationManager.error(`${field.title} không được để trống!`, '', 1500);
              field.e.focus();
              flag = false;
            }
          }
        }
        return true;
      });
      resolve(flag);
    });
  }

  renderChatMessageHistory(data, type, noMargin = false) {
    if (_.isEmpty(data) === true) {
      return (<div></div>);
    }
    const { order } = this.props;
    const ulStyles = {
      listStyle: 'none',
      margin: 0,
      padding: 0,
      maxHeight: 520,
      overflow: 'scroll'
    };
    const blackStar = {
      fontSize: 14, color: 'dimgrey', paddingRight: 10, cursor: 'pointer'
    };
    const yellowStar = {
      fontSize: 14, color: 'yellow', paddingRight: 10, cursor: 'pointer'
    };
    const titleSpan = { fontWeight: 'bold', color: 'dimgrey', paddingRight: 10 };
    const timeSpan = { fontStyle: 'italic', fontSize: 9, color: 'lightslategray' };
    const windowStyles = {};
    if (noMargin === false) {
      windowStyles.margin = '1rem auto';
    }

    if (type === 'history') {
      return (<div style={windowStyles}>
        <ul style={ulStyles}>
          {data.map((item, index) => {
            const liStyles = {
              backgroundColor: (index % 2 === 1) ? '#ddd' : '#efefef',
              padding: '0.6rem',
              borderBottom: '1px solid #ddd'
            };

            return (<li key={`history_${index}`} style={liStyles}>
              <p style={{ marginBottom: 0, whiteSpace: 'pre-line' }}>
                <span style={titleSpan}>{item.changedBy.title} </span>
                <span style={timeSpan}>{formatDateTimeChatBox(item.createdAt)}</span>
                <br />
                <div dangerouslySetInnerHTML={{ __html: item.description }} />

              </p>
            </li>);
          })}
        </ul>
      </div>);
    }

    if (type === 'deposit') {
      return (<div style={windowStyles}>
        <ul style={ulStyles}>
          {data.map((item, index) => {
            const liStyles = {
              backgroundColor: (index % 2 === 1) ? '#ddd' : '#efefef',
              padding: '0.6rem',
              borderBottom: '1px solid #ddd'
            };
            const isMinus = item.amount.toString().search('-') >= 0;
            return (<li key={`history_${index}`} style={liStyles}>
              <p style={{ marginBottom: 0, whiteSpace: 'pre-line' }}>
                <span style={titleSpan}>
                  {_.get(order, 'customer.title', '')}
                </span>
                <span style={timeSpan}>{formatDateTimeChatBox(item.createdAt)}</span>
                <br />
                {isMinus ? 'Hoàn cọc' : 'Đặt cọc'} : <span style={{ fontWeight: 'bold' }} className={isMinus ? 'text-danger' : 'text-success'}>{item.amount.toLocaleString()}</span>
                <br />{item.description}
                {item.changedBy ? <span style={{ ...timeSpan, paddingTop: 10 }}> Người thực hiện: {item.changedBy.title} </span> : ''}
              </p>
            </li>);
          })}
        </ul>
      </div>);
    }

    return (<div></div>);
  }

  onSubmit = async (e) => {
    try {
      e.preventDefault();
      const model = Object.assign({}, this.state.model);
      const validateInputOnSubmit = await this.validateInputOnSubmit();
      if (validateInputOnSubmit === false) return;
      const validate = this.validateOnSubmit(model);
      if (validate === false) return;
      // SHOW LOADING
      this.props.dispatch({ type: 'SHOW_LOADING' });
      if (this.state.selectedFile) {
        const files = await fileUpload(this.state.selectedFile.documents, this.props);
        if (files.code === 1) {
          _.forEach(files.data, (file) => {
            model.documents.push(file);
          });
        }
      }

      this.props.onSubmit(model, this.state.typeOfSubmit);
      this.props.dispatch({ type: 'HIDE_LOADING' });
    } catch (error) {
      console.log(error);
      // HIDE LOADING
      this.props.dispatch({ type: 'HIDE_LOADING' });
    } finally {
      this.props.dispatch({ type: 'HIDE_LOADING' });
    }
  }

  setFiles(file, field) {
    try {
      if (_.isEmpty(file) === false) {
        const selectedFile = Object.assign({}, this.state.selectedFile);
        selectedFile[field] = file;
        this.setState({ selectedFile });
      }
    } catch (error) {
      // console.log(error);
    }
  }

  render() {
    const {
      model,
      listBankAccount,
      bankAccountDefaultValue,
      displayIssuedAt
    } = this.state;
    const { init } = this.props;
    const coboType = _.get(init, 'coboPobo.type.pobo', []);
    const bankAccount = _.get(init, 'bankAccount', []);
    const files = _.get(model, 'documents', false);

    return (
      <div>
        <div className="page-header page-header-light">
          <div className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="text-center d-lg-none w-100">
              <button type="button" className="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-second">
                <i className="icon-menu7 mr-2"></i>Profile navigation</button>
            </div>
          </div>
          <div className="content">
            <div className="d-flex align-items-start flex-column flex-md-row">
              <div className="tab-content w-100 overflow-auto order-2 order-md-1">

                <div className="card">
                  <div className="card-header header-elements-inline">
                    <h5 className="card-title">Cập nhật phiếu chi</h5>
                    <div className="header-elements">
                    </div>
                  </div>
                  <div className="card-body">
                    <Tabs defaultActiveKey="tab1">
                      <Tab eventKey="tab1" title="Thông tin">
                        <form onSubmit={this.onSubmit} >
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-6">
                                <label>Danh mục <span className="text-danger">*</span></label>
                            
                                <Select2
                                  value={model.type}
                                  style={{ width: '100%' }}
                                  name="type"
                                  disabled = { (_.get(model, 'createdBy.id', 0)) === 0 ? '1' : ''}
                                  onChange={this.onChangeHandle.bind(this, 'type')}
                                  data={coboType}
                                  className="form-control select-search select2-hidden-accessible"
                                  options={{
                                    placeholder: 'Chọn danh mục chi'
                                  }}
                                />
                            
                              </div>
                              <div className="col-md-6">
                                <label>Tiêu đề <span className="text-danger">*</span></label>
                                <input
                                  value={model.title}
                                  ref={(input) => {
                                    this.input.fullName = {
                                      title: 'Tiêu đề', isActive: true, required: true, e: input
                                    }; 
                                  }} 
                                  disabled = { (_.get(model, 'createdBy.id', 0)) === 0 ? '1' : ''}
                                  onChange={this.onChangeHandle.bind(this, 'title')}
                                  type="text"
                                  className="form-control" />
                              </div>
                          
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="row">
                          
                              <div className="col-md-6">
                                <label>Số tiền
                                  <span className="text-danger"> * </span>

                                </label>

                                <div className="form-group">
                                  <div className="input-group">
                                    <span className="input-group-prepend">
                                      <span className="input-group-text">
                                VND
                                      </span>
                                    </span>
                                    <div className="multiselect-native-select">
                                      <Cleave
                                        value={model.amount}
                                        onChange={this.onChangeHandle.bind(this, 'amount')}
                                        className="form-control input-numeral"
                                        disabled
                                        placeholder="Số tiền"
                                        options={{
                                          numeral: true,
                                          numeralThousandsGroupStyle: 'thousand',
                                          numeralDecimalScale: 0
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <label>Tài khoản chi <span className="text-danger">*</span></label>
                                <SelectStateContainer
                                  isDisabled = { (_.get(model, 'createdBy.id', 0)) === 0 ? '1' : ''}
                                  value={bankAccountDefaultValue}
                                  onChange={this.onChangeHandle.bind(this, 'bankAccount')}
                                  options={listBankAccount}
                                  placeholder="Chọn tài khoản chi"
                                />
                              </div>

                            </div>
                          </div>

                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-6">
                                <label>Ngày tạo  <span className="text-danger">*</span></label>
                                <div className="form-group">
                                  <div className="input-group">
                                    <span className="input-group-prepend">
                                      <span className="input-group-text">
                                        <i className="icon-calendar"></i>
                                      </span>
                                    </span>
                                    <div className="multiselect-native-select">
                                      <InputMask
                                        value={displayIssuedAt}
                                        disabled = { (_.get(model, 'createdBy.id', 0)) === 0 ? '1' : ''}
                                        className="form-control"
                                        mask="99/99/9999"
                                        onChange={this.onChangeHandle.bind(this, 'issuedAt')}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <label>Tài liệu đính kèm</label>
                                { (_.get(model, 'createdBy.id', 0)) === 0 
                                  ? <input
                                    value=""
                                    type="text"
                                    disabled = { (_.get(model, 'createdBy.id', 0)) === 0 ? '1' : ''}
                                    className="form-control" />
                                  : <span>
                                    <UploadFileContainer
                                      setFiles={this.setFiles.bind(this)}
                                      name="documents"
                                    />
                                    <span className="form-text text-muted">Accepted formats: doc, docx, pdf, bmp, png, gif, jpg, jpeg, zip, rar, xls, xlxs</span>
                                  </span>
                                }

                                {files ? <fieldset className="card-body">
                                  <div className="form-group">
                                    {this.renderDownloadFile()}
                                  </div>

                                </fieldset> : ''}
                              </div>
                          
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-12">
                                <label>Mô tả chi tiết</label>
                                { (_.get(model, 'createdBy.id', 0)) === 0 
                                  ? <div dangerouslySetInnerHTML={{ __html: model.description }} />
                                  : <textarea 
                                    value={model.description} 
                                    onChange={this.onChangeHandle.bind(this, 'description')} 
                                    rows="4" 
                                    cols="4"
                                    placeholder="Nhập mô tả." 
                                    className="form-control"
                                  ></textarea>
                                 
                                }
                                
                              </div>
                            </div>
                          </div>
                          <div className="text-right">
                            <button className="btn btn-primary">
                              <i className="fa fa-save"></i> Cập nhật
                            </button>
                          </div>
                        </form>
                      </Tab>
                      <Tab eventKey="tab2" title="Lịch sử thay đổi">
                        {this.renderChatMessageHistory(this.props.coboPobo.history, 'history')}
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <NotificationContainer />
      </div>
     
    );
  }

  componentDidUpdate(oldProps) {
    try {
      /**
       * 
       */

      const newProps = this.props;
      if (oldProps.coboPobo !== newProps.coboPobo) {
        this.setModelCoboPobo();
      }
    } catch (error) {
      // console.log(error);
      /**
       * 
       */
    }
  }

  setModelCoboPobo(socketOrder) {
    try {
      const { buildServices } = this.props;
      let { coboPobo } = this.props;

      const { listBankAccount } = this.state;
      if (socketOrder) {
        coboPobo = socketOrder;
      }

      const model = {
        method: coboPobo.method,
        id: coboPobo.id,
        type: coboPobo.type,
        title: coboPobo.title,
        amount: coboPobo.amount,
        bankAccount: coboPobo.bankAccount,
        description: coboPobo.description,
        issuedAt: coboPobo.issuedAt,
        documents: coboPobo.documents,
        createdBy: coboPobo.createdBy
      };
       
      const bankAccountDefaultValue = _.find(listBankAccount, { value: coboPobo.bankAccount });
      this.setState({
        model,
        bankAccountDefaultValue,
        displayIssuedAt: formatDate(model.issuedAt)
      });
    } catch (error) {
      // console.log(error);
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      init: payload.generalReducer.init,
      userInfo: payload.generalReducer.userInfo,
      lang: payload.i18nState.lang,
      uploadPath: payload.generalReducer.uploadPath,
      downloadPath: payload.generalReducer.downloadPath,
      coboPobo: payload.coboPoboReducer.coboPobo
    };
  }, mapDispatchToProps)(ModifyPobo)
);
