import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ModifyDepartment from './modifyDepartment';
import * as DepartmentActions from '../../../redux/departments/departments.actions';
import * as BranchActions from '../../../redux/branchs/branchs.actions';

class ModifyDepartmentContainer extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = {
      departmentInfo: {},
      listBranch: [],
      branchScope: {},
      branchCount: 0
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.callback = this.callback.bind(this);
    //
    // this.makeRemoteRequest = this.makeRemoteRequest.bind(this);
    //
    this.getBranchList = this.getBranchList.bind(this);

    this.getDepartmentDetail = this.getDepartmentDetail.bind(this);
    this.callbackGetDetail = this.callbackGetDetail.bind(this);

    this.getDepartmentDetail();
  }

  getDepartmentDetail() {
    try {
      const { departmentAct } = this.props;
      const id = this.props.computedMatch.params.id;
      departmentAct.detailDepartment({
        filter: { id }
      }, this.callbackGetDetail);
    } catch (error) {
      console.log('error', error);
    }
  }

  callbackGetDetail(status, data) {
    try {
      this.getBranchList();
      if (!status || !data) {
        NotificationManager.error('Thông tin không hợp lệ', '', 1500);
        setTimeout(() => {
          this.props.history.push('/department');
        }, 1500);
      } 
    } catch (error) {
      // console.log(error);
    }
  }

  onSubmit = async (args) => {
    try {
      const { departmentAct } = this.props;
      const query = Object.assign({}, args);
      const payload = {
        id: query.id,
        data: query
      };
      delete payload.data.id;
      departmentAct.modifyDepartment(payload, this.callback);
    } catch (error) {
      // console.log(error);
      // console.log('error', error);
    }
  }

  callback(status, data) {
    if (status) {
      NotificationManager.info('Cập nhật phòng ban thành công,đang chuyển về trang quản lý', '', 1000);
      setTimeout(() => {
        this.props.history.push('/department');
      }, 1500);
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }

  getBranchList() {
    try {
      const { branchAct } = this.props;
      branchAct.searchBranchList({
        filter: {
          type: 'branch'
        }
      });
    } catch (error) {
      // console.log('error', error);
    }
  }

  render() {
    return (
      <div>
        <ModifyDepartment
          onSubmit={this.onSubmit}
          errorMessage={this.state.errorMessage}
          userInfo={this.userInfo}
        />
        <NotificationContainer />
      </div>
        
    );
  }

  // componentWillMount = async () => {
  //   await this.getDepartmentDetail();
  // }
}

function mapsDispatchToProp(dispatch) {
  return {
    departmentAct: bindActionCreators(DepartmentActions, dispatch),
    branchAct: bindActionCreators(BranchActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    branchs: rootState.branchReducer.branchs,
    listScope: rootState.generalReducer.listScope,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(ModifyDepartmentContainer);
