import React from 'react';
import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { DelayInput } from 'react-delay-input';

class ArrivalVisaPrice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 500,
      styles: {},
      model: _.get(this.props, 'init.countryArrival', [])
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.validateOnSubmit = this.validateOnSubmit.bind(this);
    this.onChangeCountryHandle = this.onChangeCountryHandle.bind(this);
  }

  onSubmit = (e) => {
    try {
      e.preventDefault();
      this.props.dispatch({ type: 'SHOW_LOADING' });
      const model = Object.assign([], this.state.model);
      const validate = this.validateOnSubmit(model);
      if (validate === true) {
        this.props.onSubmit(model);
        this.props.dispatch({ type: 'HIDE_LOADING' });
      }
    } catch (error) {
      this.props.dispatch({ type: 'HIDE_LOADING' });
    }
  }

  validateOnSubmit() {
    const model = Object.assign({}, this.state.model);

    return true;
  }

  onChangeCountryHandle(key, pos, e) {
    try {
      const { init } = this.props;
      const styles = _.clone(this.state.styles);
      const countryArrivals = _.get(init, 'countryArrival', []);

      if (_.isEmpty(styles[pos]) === true) {
        styles[pos] = {
          code: false,
          title: false,
          note: false,
          isDifficult: false,
          isHidden: false,
          amount: {
            arrival: false,
            temporaryResidence: false,
            visaExtension: false,
            workPermit: false
          }
        };
      }
      switch (key) {
        case 'arrival':
        case 'temporaryResidence':
        case 'visaExtension':
        case 'workPermit': {
          styles[pos].amount[key] = true;
          countryArrivals[pos].amount[key] = Number(_.get(e, 'target.value', _.get(e, 'value', 0)));
          break;
        }
        case 'isDifficult':
        case 'isHidden': {
          styles[pos][key] = true;
          countryArrivals[pos][key] = e.target.checked;
          break;
        }
        case 'namespace': {
          styles[pos][key] = true;
          countryArrivals[pos].namespace = _.get(e, 'target.value', _.get(e, 'value', false));
          break;
        }
        default: {
          styles[pos][key] = true;
          countryArrivals[pos][key] = _.get(e, 'target.value', _.get(e, 'value', false));
          break;
        }
      }

      this.setState({ model: countryArrivals, styles });
    } catch (error) {
      console.log(error);
    }
  }

  renderCountries() {
    const { init } = this.props;
    const { styles } = this.state;
    const countryArrivals = _.get(init, 'countryArrival', []);
    if (_.isEmpty(countryArrivals) === true) {
      return (<tr></tr>);
    }
    const TDStyle = {
      padding: '5px 5px',
      textAlign: 'center'

    };
    const NumberStyle = {
      background: 'papayawhip',
      fontWeight: 'bold'
    };

    const CheckboxStyle = {
      height: '2.25003rem'
    };
    return _.map(countryArrivals, (countryArrival, i) => {
      const NO = i + 1;
      const status = _.get(styles, `[${i}]`, {});

      return (<tr key={`renderCountries_${countryArrival.code}`}>
        <td>{NO}</td>
        <td style={TDStyle}>
          <DelayInput
            type="text"
            className="form-control"
            minLength={0}
            delayTimeout={50}
            value={_.get(countryArrival, 'title', '')}
            onChange={this.onChangeCountryHandle.bind(this, 'title', i)}
            style={_.get(status, 'title', false) === true ? NumberStyle : {}}
          />

        </td>
        <td style={TDStyle}>
          <DelayInput
            value={_.get(countryArrival, 'amount.arrival', '')}
            type="number"
            className="form-control"
            minLength={0}
            delayTimeout={50}
            placeholder="Giá tiền CNVC"
            onChange={this.onChangeCountryHandle.bind(this, 'arrival', i)}
            style={_.get(status, 'amount.arrival', false) === true ? NumberStyle : {}}
          />

        </td>
        <td style={TDStyle}>
          <DelayInput
            type="number"
            className="form-control"
            minLength={0}
            delayTimeout={50}
            placeholder="Gia hạn visa"
            value={_.get(countryArrival, 'amount.visaExtension', '')}
            onChange={this.onChangeCountryHandle.bind(this, 'visaExtension', i)}
            style={_.get(status, 'amount.visaExtension', false) === true ? NumberStyle : {}}
          />

        </td>
        <td style={TDStyle}>
          <DelayInput
            type="number"
            className="form-control"
            minLength={0}
            delayTimeout={50}
            placeholder="Thẻ tạm trú"
            value={_.get(countryArrival, 'amount.temporaryResidence', '')}
            onChange={this.onChangeCountryHandle.bind(this, 'temporaryResidence', i)}
            style={_.get(status, 'amount.temporaryResidence', false) === true ? NumberStyle : {}}
          />
        </td>
        <td style={TDStyle}>
          <DelayInput
            type="number"
            className="form-control"
            minLength={0}
            delayTimeout={50}
            placeholder="Giấy phép LĐ"
            value={_.get(countryArrival, 'amount.workPermit', '')}
            onChange={this.onChangeCountryHandle.bind(this, 'workPermit', i)}
            style={_.get(status, 'amount.workPermit', false) === true ? NumberStyle : {}}
          />
        </td>
        <td style={TDStyle}>
          <DelayInput
            type="text"
            className="form-control"
            minLength={0}
            delayTimeout={50}
            placeholder="Ghi chú"
            value={_.get(countryArrival, 'note', '')}
            onChange={this.onChangeCountryHandle.bind(this, 'note', i)}
            style={_.get(status, 'note', false) === true ? NumberStyle : {}}
          />

        </td>
        <td style={TDStyle}>
          <DelayInput
            type="text"
            className="form-control"
            minLength={0}
            delayTimeout={50}
            placeholder="namespace"
            value={_.get(countryArrival, 'namespace', '')}
            onChange={this.onChangeCountryHandle.bind(this, 'namespace', i)}
            style={_.get(status, 'namespace', false) === true ? NumberStyle : {}}
          />

        </td>
        {/* <td style={TDStyle}>
          <input
            style={CheckboxStyle}
            checked={_.get(countryArrival, 'isDifficult', false)}
            onChange={this.onChangeCountryHandle.bind(this, 'isDifficult', i)}
            type="checkbox"
          />
        </td> */}
        <td style={TDStyle}>
          <input
            style={CheckboxStyle}
            checked={_.get(countryArrival, 'isHidden', false)}
            onChange={this.onChangeCountryHandle.bind(this, 'isHidden', i)}
            type="checkbox"
          />
        </td>
      </tr>);
    });
  }

  render() {
    return (
      <div>
        <div className="alert alert-info bg-white alert-styled-left alert-arrow-left alert-dismissible"
          style={{ borderColor: 'goldenrod' }}>
          <h6 className="alert-heading font-weight-semibold mb-1">Lưu ý</h6>
          <code>Giá tiền bạn nhập liệu vào là giá cộng thêm ( VD: 150,000 ) Nghĩa là 17,000 (Mặc định) + 150,000 = 162,000. Giá tiền này chỉ nhập đối vói các quốc gia khó cần phải xử lý thêm.</code>
        </div>

        <div className="card">
          <div className="card-header bg-transparent header-elements-inline">
            <h4 className="card-title">Quản lý giá tiền công văn nhập cảnh</h4>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div style={{ border: 'none' }} className="card">
                <div className="table-responsive">
                  <form onSubmit={this.onSubmit}>

                    <div className="text-right">
                      <button
                        style={{ margin: 10 }}
                        type="submit"
                        className="btn btn-primary">
                        <i className="fa fa-save" style={{ marginRight: 5 }}></i> Lưu lại
                      </button>
                    </div>
                    <table className="table text-nowrap">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Quốc gia</th>
                          <th>Giá tiền CNVC</th>
                          <th>Gia hạn visa</th>
                          <th>Thẻ tạm trú</th>
                          <th>Giấy phép LĐ</th>
                          <th>Ghi chú</th>
                          <th>Namespace</th>
                          {/* <th>TH khó</th> */}
                          <th>Ẩn</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.renderCountries()}
                      </tbody>
                    </table>

                    <div className="text-right">
                      <button
                        style={{ margin: 10 }}
                        type="submit"
                        className="btn btn-primary">
                        <i className="fa fa-save" style={{ marginRight: 5 }}></i> Lưu lại
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

        </div>

        <NotificationContainer />
      </div>

    );
  }

  componentDidMount() {
    const { innerWidth: width, innerHeight: height } = window;
    this.setState({ height });
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      init: payload.generalReducer.init,
      userInfo: payload.generalReducer.userInfo,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(ArrivalVisaPrice)
);
