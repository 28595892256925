
/**
 * CRUD
 */

export const POST_SEARCH_SUPPLIER = 'POST_SEARCH_SUPPLIER';
export const POST_SEARCH_SUPPLIER_SUCCESS = 'POST_SEARCH_SUPPLIER_SUCCESS';
export const POST_SEARCH_SUPPLIER_FAIL = 'POST_SEARCH_SUPPLIER_FAIL';

export const POST_SEARCH_BY_KEYWORD_SUPPLIER = 'POST_SEARCH_BY_KEYWORD_SUPPLIER';
export const POST_SEARCH_BY_KEYWORD_SUPPLIER_SUCCESS = 'POST_SEARCH_BY_KEYWORD_SUPPLIER_SUCCESS';
export const POST_SEARCH_BY_KEYWORD_SUPPLIER_FAIL = 'POST_SEARCH_BY_KEYWORD_SUPPLIER_FAIL';

export const POST_SUPPLIER_DETAIL = 'POST_SUPPLIER_DETAIL';
export const POST_SUPPLIER_DETAIL_SUCCESS = 'POST_SUPPLIER_DETAIL_SUCCESS';
export const POST_SUPPLIER_DETAIL_FAIL = 'POST_SUPPLIER_DETAIL_FAIL';

export const POST_SUPPLIER = 'POST_SUPPLIER';
export const POST_SUPPLIER_SUCCESS = 'POST_SUPPLIER_SUCCESS';
export const POST_SUPPLIER_FAIL = 'POST_SUPPLIER_FAIL';

export const PUT_SUPPLIER = 'PUT_SUPPLIER';
export const PUT_SUPPLIER_SUCCESS = 'PUT_SUPPLIER_SUCCESS';
export const PUT_SUPPLIER_FAIL = 'PUT_SUPPLIER_FAIL';
