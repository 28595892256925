import React from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { sha256 } from 'js-sha256';
import ModifyAgency from './modifyAgency';
import * as AccountActions from '../../../redux/accounts/accounts.actions';
import * as AgencyActions from '../../../redux/agencies/agencies.actions';
import { AGENCY_UPDATE_SCOPE } from '../../../configs/constants';

import {
  buildListLocation, checkScope
} from '../../../helpers';

class ModifyAgencyContainer extends React.Component {
  constructor(prop) {
    super(prop);

    if (checkScope(AGENCY_UPDATE_SCOPE, this.props) === false) {
      NotificationManager.error('Chức năng không tồn tại', '', 1500);
      setTimeout(() => {
        this.props.history.push('/dashboard');
      }, 2000);
    }

    this.state = {
      locations: [],
      sendSMSInfo: {}
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.callback = this.callback.bind(this);
    //
    this.buildLocation = this.buildLocation.bind(this);
    this.buildDiscount = this.buildDiscount.bind(this);
    //
    this.renderDelegate = this.renderDelegate.bind(this);
    this.getAgencyInfo = this.getAgencyInfo.bind(this);
    this.callbackGetAgencyInfo = this.callbackGetAgencyInfo.bind(this);

    const id = this.props.computedMatch.params.id;
    this.getAgencyInfo({
      filters: { id }
    });

    const { init } = this.props;
    this.getDelegates({
      filters: {
        group: ['staff']
      }
    });
  }
  
  onSubmit = async (args) => {
    try {
      const { agencyAct } = this.props;
      delete args.rePassword;
      const query = Object.assign({}, args);

      delete query.rePassword;
      
      if (query.password) {
        this.setState({ 
          sendSMSInfo: {
            phone: args.phone,
            password: query.password
          } 
        });

        query.password = sha256(query.password);
      } else {
        this.setState({ 
          sendSMSInfo: {} 
        });

        delete query.password;
      }
      
      const payload = {
        id: query.id,
        data: query
      };
      delete payload.data.id;
      agencyAct.modifyAgency(payload, this.callback);
    } catch (error) {
      // console.log('error', error);
    }
  }

  callback(status, data) {
    if (status) {
      NotificationManager.info('Cập nhật khách hàng thành công', '', 1000);
      const { sendSMSInfo } = this.state;
      if (_.isEmpty(sendSMSInfo) === false) {
        this.props.dispatch({
          type: 'SEND_SMS',
          payload: {
            phone: sendSMSInfo.phone,
            password: sendSMSInfo.password,
            type: 'modify'
          }
        });
      }

      setTimeout(() => {
        this.props.history.push('/agency');
      }, 1500);
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }

  /**
   * 
   */
  getAgencyInfo(args) {
    try {
      const { agencyAct } = this.props;
      agencyAct.detailAgency(args, this.callbackGetAgencyInfo);
    } catch (error) {
      // console.log('error', error);
    }
  }

  callbackGetAgencyInfo(status, data) {
    try {
      if (!status) {
        NotificationManager.error('Thông tin không hợp lệ', '', 1000);
        setTimeout(() => {
          this.props.history.push('/agency');
        }, 1500);
      }
    } catch (error) {
      // console.log(error);
    }
  }
  /**
   * 
   */

  getDelegates(args) {
    try {
      const { accountAct } = this.props;
      accountAct.searchByKeywordAccount(args);
    } catch (error) {
      // console.log('error', error);
    }
  }

  renderDelegate(data) {
    const result = [];
    try {
      if (_.isEmpty(data) === false) {
        _.forEach(data, (item) => {
          result.push({ value: item.identifyCode, label: item.title });
        });
      }
      return result;
    } catch (error) {
      return result;
      // console.log('error', error);
    }
  }

  /**
   * 
   */
  buildDiscount() {
    const { init } = this.props;
    const listDiscount = [{ value: '', label: 'Mặt định (dựa vào số lượng HĐ)' }];
    if (init.discount) {
      init.discount.forEach((element) => {
        listDiscount.push({ value: element.key, label: element.title });
      });
    }
    return listDiscount;
  }
  
  /**
   * 
   */

  render() {
    return (
      <div>
        <ModifyAgency
          onSubmit={this.onSubmit}
          errorMessage={this.state.errorMessage}
          userInfo={this.userInfo}
          renderDelegate={this.renderDelegate}
          buildLocation={this.buildLocation}
          buildDiscount={this.buildDiscount}
        />
        <NotificationContainer />
      </div>
        
    );
  }

  buildLocation() {
    const { init } = this.props;
    if (init.area) {
      return buildListLocation(init);
    }
    return [];
  }

  // componentWillMount = async () => {
  //   const { init } = this.props;
  //   this.getDelegates({
  //     filters: {
  //       group: ['staff']
  //     }
  //   });
  // }
}

function mapsDispatchToProp(dispatch) {
  return {
    agencyAct: bindActionCreators(AgencyActions, dispatch),
    accountAct: bindActionCreators(AccountActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    accounts: rootState.accountReducer.accounts,
    init: rootState.generalReducer.init,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(ModifyAgencyContainer);
