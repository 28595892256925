import * as types from './departments.types';

const initialState = {
  departments: [],
  department: {},
  departmentCount: 0
};

export default function departments(state = initialState, action) {
  switch (action.type) {
    case types.POST_SEARCH_DEPARTMENT_SUCCESS: {
      return {
        ...state,
        departments: action.payload.departments,
        departmentCount: action.payload.departmentCount
      };
    }
    case types.GET_DEPARTMENT_SUCCESS: {
      return {
        ...state,
        department: action.payload
      };
    }

    default:
      return state;
  }
}
