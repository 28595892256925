import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Receipt extends React.Component {
  static contextTypes = {
    t: PropTypes.func.isRequired
  }
  
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const Component = this.props.component;
    const route = this.props.route  //eslint-disable-line
    return (
      <div>
        <Component route={route} {...this.props} />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      userInfo: payload.generalReducer.userInfo,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(Receipt)
);
