import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { sha256 } from 'js-sha256';
import TagsInput from 'react-tagsinput';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { DelayInput } from 'react-delay-input';
import Cleave from 'cleave.js/react';

import moment from 'moment';
import InputMask from 'react-input-mask';
import axios from 'axios';
import { Images } from '../../../ui/assets/images';
import UploadFileContainer from '../../ultils/uploadFile.container';
import {
  fileUpload
} from '../../../helpers';

class CreateMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listTag: this.props.listTag,
      groupScope: this.props.groupScope,
      disabledDepartment: true,
      selectedFile: [],
      listMember: [],
      departmentTitle: '',
      branchTitle: '',
      birthdayDisplay: '',
      joinedDisplay: '',
      uploading: false,
      allowance: [{ key: '', value: '' }],
      model: {
        password: '',
        rePassword: '',
        email: '',
        address: '',
        phone: '',
        telephone: '',
        taxCode: '',
        joinedAt: '',
        state: '',
        isVerify: {
          phone: false,
          email: false
        },
        paymentVoucherLimit: 0,
        bank: {},
        area: '',
        // level: '',
        allowIp: ['*'],
        title: '',
        position: '',
        birthday: '',
        voip: {
          trunkId: '',
          extension: ''
        },
        salary: {
          basic: 0,
          allowance: [],
          isSocialInsurance: false,
          description: ''
        },
        organizationId: '',
        workingTimeId: '',
        parentIdentifyCode: 'root',
        group: 'staff',
        scopes: [],
        delegates: [],
        isActive: true,
        contract: []
      },

      listVoip: [{ value: 1, label: '02877777979' }, { value: 2, label: '02877777888' }],

      tabActive: 0
    };
    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.selectPermission = this.selectPermission.bind(this);
    //
    this.onUploadFile = this.onUploadFile.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
    //
    this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.onChangePermission = this.onChangePermission.bind(this);
    this.onChangeSocialInsurance = this.onChangeSocialInsurance.bind(this);
    //
    this.addItemHandle = this.addItemHandle.bind(this);
    this.removeItemHandle = this.removeItemHandle.bind(this);
    //
    this.getAccountState = this.getAccountState.bind(this);
    this.setFiles = this.setFiles.bind(this);
  }

  onSubmit = async (e) => {
    try {
      e.preventDefault();
      const validate = this.validateOnSubmit();
      if (validate === true) {
        const {
          listTag, groupScope, allowance
        } = this.state;
        const model = Object.assign({}, this.state.model);
        const { accountSearchByKeyword } = this.props;
        const { listMember } = this.state;
        const code = e.target.value;
        const scopes = [];
        const delegates = [];
        listTag.forEach((tag) => {
          groupScope[tag].forEach((scope, j) => {
            if (scope.checked === true) {
              groupScope[tag][j].checked = true;
              scopes.push(scope.code);
            }
          });
        });

        if (_.isEmpty(model.delegates) === false) {
          model.delegates.forEach((identifyCode) => {
            const member = _.find(accountSearchByKeyword, { identifyCode });
            if (_.isEmpty(member) === false) {
              delegates.push({
                identifyCode,
                description: ''
              });
            }
          });
          model.delegates = delegates;
        }
        /**
         * 
         */
        model.salary.allowance = [];
        if (_.isEmpty(allowance) === false) {
          allowance.forEach((item) => {
            if (item.key && item.value) {
              model.salary.allowance.push(item);
            }
          });
        }

        const files = await fileUpload(this.state.selectedFile, this.props);
        if (files.code === 1) {
          model.contract = files.data;
        }

        if (_.isEmpty(scopes) === true) {
          NotificationManager.error('Vui lòng chọn phân quyền', '', 1500);
        } else {
          model.scopes = scopes;
          model.password = sha256(model.password);
          this.props.onSubmit(model);
          this.selectPermission(model.organizationId);
        }
      }
    } catch (error) {
      // console.log(error);
      /**
       * 
       */
    }
  }

  setFiles(selectedFile) {
    try {
      this.setState({ selectedFile });
    } catch (error) {
      // console.log(error);
    }
  }
  
  validateOnSubmit() {
    const { model, allowance } = this.state;

    if (_.isEmpty(model.password) === true) {
      NotificationManager.error('Mật khẩu không được để trống', '', 1500);
      return false;
    }
    if (_.isEmpty(model.rePassword) === true) {
      NotificationManager.error('Xác nhận mật khẩu không được để trống', '', 1500);
      return false;
    }

    if (model.password !== model.rePassword) {
      NotificationManager.error('Mật khẩu và xác nhận mật khẩu không giống nhau', '', 1500);
      return false;
    }

    if (_.isEmpty(model.email) === true) {
      NotificationManager.error('Vui lòng nhập email', '', 1500);
      return false;
    }

    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(model.email) === false) {
      NotificationManager.error('Email không hợp lệ', '', 1500);
      return false;
    }

    if (_.isEmpty(model.title) === true) {
      NotificationManager.error('Họ & tên không được để trống', '', 1500);
      return false;
    }

    if (_.isEmpty(model.phone) === true) {
      NotificationManager.error('Số điện thoại đăng nhập không được để trống', '', 1500);
      return false;
    }

    if (_.isEmpty(model.salary) === true || _.isNumber(model.salary.basic) === false) {
      NotificationManager.error('Thông tin lương không được để trống', '', 1500);
      return false;
    }

    if (_.isEmpty(allowance) === true) {
      NotificationManager.error('Thông tin trợ cấp không được để trống', '', 1500);
      return false;
    }

    if (_.isEmpty(allowance) === false) {
      const isValidSetting = true;
      let isDuplicate = false;
      const exist = [];
      allowance.forEach((item, index) => {
        if (item.key && item.value) {
          const isExist = _.find(exist, { key: item.key });
          if (_.isEmpty(isExist) === false) {
            isDuplicate = true;
          }
          exist.push(item);
        }
      });

      if (isDuplicate === true) {
        NotificationManager.error('Mô tả trợ cấp đã tồn tại', '', 1500);
        return false;
      }

      if (isValidSetting === false) {
        NotificationManager.error('Phụ cấp không hợp lệ', '', 1500);
        return false;
      }
    }

    if (_.isNumber(model.organizationId) === false) {
      NotificationManager.error('Chưa chọn phòng ban', '', 1500);
      return false;
    }

    if (_.isEmpty(model.parentIdentifyCode) === true) {
      NotificationManager.error('Chưa chọn người phụ trách', '', 1500);
      return false;
    }

    return true;
  }

  /**
   * 
   * @param {*} id 
   */

  selectBranch(id) {
    const { branchs } = this.props;

    const branch = _.find(branchs, { id });
    if (_.isEmpty(branch) === false) {
      const branchTitle = branch.title;
      this.props.getDepartments(branch.identifyCode);
      this.setState({ organizationId: id, branchTitle });
    }
    return false;
  }

  selectPermission(id) {
    const { departments } = this.props;
    const department = _.find(departments, { id });

    const model = Object.assign({}, this.state.model);
    model.organizationId = id;

    const { listTag, groupScope } = this.state;
    if (_.isEmpty(department) === false) {
      const departmentTitle = department.title;
      const scopes = department.scopes;
      listTag.forEach((tag) => {
        groupScope[tag].forEach((scope, j) => {
          groupScope[tag][j].checked = false;
          if (_.indexOf(scopes, scope.code) >= 0) {
            groupScope[tag][j].checked = true;
          }
        });
      });
      this.setState({ model, groupScope, departmentTitle });
    } else {
      listTag.forEach((tag) => {
        groupScope[tag].forEach((scope, j) => {
          groupScope[tag][j].checked = false;
        });
      });
      this.setState({ model, groupScope, departmentTitle: 'Không thuộc phòng ban' });
    }

    return false;
  }

  onChangeCheckBox(e) {
    const model = Object.assign({}, this.state.model);
    const { listTag, groupScope } = this.state;
    const code = e.target.value;

    listTag.forEach((tag) => {
      groupScope[tag].forEach((scope, j) => {
        if (scope.code === code) {
          groupScope[tag][j].checked = e.target.checked;
        }
      });
    });
    this.setState({ model, groupScope });
  }

  changeTab(index) {
    this.setState({ tabActive: index });
  }

  onChangeSocialInsurance(e) {
    const model = Object.assign([], this.state.model);
    model.salary.isSocialInsurance = e.target.checked;
    this.setState({ model });
  }

  onChangePermission(e) {
    const items = Object.assign([], this.state.items);
    const code = e.target.value;
    items.forEach((item, i) => {
      if (item.code === code) {
        items[i].checked = e.target.checked;
      }
      if (_.isEmpty(item.children) === false) {
        item.children.forEach((children, j) => {
          if (children.code === code) {
            item.children[j].checked = e.target.checked;
          }
        });
      }
    });
    this.setState({ items });
  }

  onChangeHandle(key, e) {
    try {
      const { isDisable, listVoip } = this.state;
      const model = Object.assign({}, this.state.model);
      const result = {};
      if (_.isArray(e) === false) {
        switch (key) {
          case 'trunkId':
            model.voip.trunkId = _.get(e, 'target.value', _.get(e, 'value', false));
            result.trunkDefaultValue = _.find(listVoip, { value: model.voip.trunkId });
            break;
          case 'extension':
            model.voip.extension = _.get(e, 'target.value', _.get(e, 'value', false));
            break;

          case 'basic':
          case 'allowance': {
            const salary = _.get(e, 'target.rawValue', _.get(e, 'value', 0));
            model.salary[key] = parseInt(salary, 10);
            break;
          }

          case 'paymentVoucherLimit': {
            const value = _.get(e, 'target.rawValue', _.get(e, 'value', 0));
            model.paymentVoucherLimit = Number(value);
            break;
          }

          case 'isSocialInsurance':
          case 'description': {
            model.salary[key] = _.get(e, 'target.value', _.get(e, 'value', false));
            break;
          }
          case 'fullname': {
            const fullname = _.get(e, 'target.value', _.get(e, 'value', false));
            model.bank[key] = fullname.toUpperCase();
            break;
          }
          case 'number':
          case 'branch':
          case 'code':
            model.bank[key] = _.get(e, 'target.value', _.get(e, 'value', false));
            break;
          case 'birthday': {
            const value = _.get(e, 'target.value', _.get(e, 'value', false));
            const dateFormat = 'YYYY/MM/DD';
            const splittedDate = value.split('/');
            if (splittedDate.length === 3) {
              const date = moment(`${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`, dateFormat);
              result.birthdayDisplay = value;
              if (date.isValid() === true) {
                model[key] = date.format();
              }
            }
            break;
          }

          case 'joinedAt': {
            const value = _.get(e, 'target.value', _.get(e, 'value', false));
            const dateFormat = 'YYYY/MM/DD';
            const splittedDate = value.split('/');
            if (splittedDate.length === 3) {
              const date = moment(`${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`, dateFormat);
              result.joinedDisplay = value;
              if (date.isValid() === true) {
                model[key] = date.format();
              }
            }
            break;
          }
          case 'title': {
            const value = _.get(e, 'target.value', _.get(e, 'value', false));
            model[key] = value.toUpperCase();
            break;
          }

          default:
            model[key] = _.get(e, 'target.value', _.get(e, 'value', false));
            break;
        }
      } else {
        switch (key) {
          case 'allowIp': {
            model[key] = e;
            break;
          }
          case 'delegates': {
            const { accountSearchByKeyword } = this.props;
            const delegates = [];
            e.forEach((element) => {
              const delegateInfo = _.find(accountSearchByKeyword, { identifyCode: element.value });
              delegates.push(delegateInfo.identifyCode);
            });
            model[key] = delegates;
            break;
          }
          default: {
            const temp = [];
            e.forEach((element) => {
              temp.push(_.get(element, 'target.value', _.get(element, 'value', false)));
            });
            model[key] = temp;
            break;
          }
        }
      }
      result.model = model;
      this.setState(result);
    } catch (error) {
      // console.log(error);
    }
  }

  onItemChangeHandle(key, index, e) {
    const allowance = Object.assign([], this.state.allowance);
    allowance[index][key] = e.target.value;
    this.setState({ allowance });
  }

  onUploadFile(e) {
    try {
      const files = _.get(e, 'target.files', false);
      if (_.isEmpty(files) === false) {
        this.setState({ selectedFile: files });
        this.fileUpload(files);
      }
    } catch (error) {
      // console.log(error);
    }
  }

  fileUpload(files) {
    try {
      const { init, userInfo } = this.props;
      const data = new FormData();
      data.append('file', files[0]);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          accessToken: userInfo.accessToken
        }
      };
      axios.post(`${this.props.uploadPath}/upload-contract`, data, config).then((res) => {
        const code = _.get(res, 'data.code', false);
        if (code === 100) {
          const model = Object.assign({}, this.state.model);
          model.contract[0] = res.data.data.message.pathname;
          this.setState({ model, selectedFileName: res.data.data.message.filename });
        } else {
          const message = _.get(res, 'data.data.message.message', 'File không hợp lệ');
          NotificationManager.error(message, '', 3000);
        }
      });
    } catch (error) {
      // console.log(error);
    }
  }

  renderPermission(selected) {
    try {
      const { listTag, groupScope } = this.props;
      const itemsHtml = [];
      listTag.forEach((tag) => {
        const scopes = groupScope[tag];

        itemsHtml.push({
          level: 0,
          html: this.buildHtml(tag, 0)
        });

        itemsHtml.push({
          level: 1,
          html: this.buildHtml(groupScope[tag], 'child', selected)
        });
      });
      return (itemsHtml.map((item, i) => {
        if (item.level === 0) {
          return (<div key={i} className="form-group">{item.html}</div>);
        }
        return (<div key={i} className="ml-4 mb-2 mt-2">{item.html}</div>);
      }));
    } catch (error) {
      // console.log(error);
      return (<div></div>);
    }
  }

  buildHtml(item, level, selected) {
    if (level === 0) {
      return (
        <div key={item} className="form-check">
          <label className="form-check-label">
            <div className="uniform-checker border-primary-600 text-primary-800">
            </div>
            <span className="font-weight-semibold">{item}</span>
          </label>
        </div>);
    }
    if (level === 1) {
      return (
        <div key={item.title} className="form-check form-check-inline">
          <label className="form-check-label" title="Nguồn khách do Marketing và toàn thể NV đem về">
            <div className="uniform-checker">
              <span className={item.checked === true ? 'checked' : ''}>
                <input
                  value={item.code}
                  onChange={this.onChangeCheckBox}
                  type="checkbox"
                  className="form-check-input-styled"
                  data-fouc="true" />
              </span>
            </div>{item.title}</label>
        </div>
      );
    }
    const childHtml = [];
    item.forEach((children) => {
      childHtml.push(this.buildHtml(children, 1));
    });
    return (childHtml.map((child, i) => {
      return child;
    }));
  }

  /**
   * 
   */

  renderDepartmentBtn() {
    const { departments } = this.props;
    const { departmentTitle, organizationId } = this.state;
    const { disabledDepartment } = this.props;
    return (
      <Dropdown >
        <Dropdown.Toggle disabled={disabledDepartment} variant="secondary" >
          {departmentTitle || 'Phòng ban'}
        </Dropdown.Toggle>
        <Dropdown.Menu >
          <Dropdown.Item
            eventKey="Không thuộc phòng ban"
            onClick={this.selectPermission.bind(this, organizationId)}>Không thuộc phòng ban
          </Dropdown.Item>
          {this.buildDepartmentDropDown(departments)}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  buildDepartmentDropDown(items) {
    return (items.map((item, i) => {
      return (
        <Dropdown.Item
          eventKey={item.title}
          key={`dropdown_${item.id}`}
          onClick={this.selectPermission.bind(this, item.id)}>
          {item.title}
        </Dropdown.Item>
      );
    }));
  }

  /**
   * 
   */

  renderBranchBtn() {
    const { branchs } = this.props;
    const { branchTitle } = this.state;
    return (
      <Dropdown style={{ marginRight: 10 }}>
        <Dropdown.Toggle
          variant="secondary"
        >{branchTitle || 'Chi nhánh'}</Dropdown.Toggle>
        <Dropdown.Menu >
          {this.buildBranchDropDown(branchs)}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  buildBranchDropDown(items) {
    return (items.map((item) => {
      return (
        <Dropdown.Item
          eventKey={item.title}
          key={`dropdown_${item.id}`}
          onClick={this.selectBranch.bind(this, item.id, item.identifyCode)}>
          {item.title}
        </Dropdown.Item>
      );
    }));
  }

  /**
   * 
   */

  onChangeAllowanceHandle(i, key, e) {
    const allowance = Object.assign([], this.state.allowance);
    let value = '';
    switch (key) {
      case 'key':
        value = _.get(e, 'target.value', _.get(e, 'value', false));
        break;

      default:
        value = _.get(e, 'target.rawValue', _.get(e, 'value', false));
        break;
    }
    if (value) {
      allowance[i][key] = value;
      this.setState({ allowance });
    }
  }

  addItemHandle() {
    const allowance = Object.assign([], this.state.allowance);
    allowance.push({ key: '', value: '' });
    this.setState({ allowance });
  }

  removeItemHandle(index) {
    if (_.isNumber(index) === true) {
      const allowance = Object.assign([], this.state.allowance);
      delete allowance[index];
      this.setState({ allowance });
    }
  }

  allowanceRender() {
    const { allowance } = this.state;
    if (_.isEmpty(allowance) === false) {
      return (allowance.map((item, i) => {
        return (<div key={`allowanceRender_${i}`} className="form-group">
          <div className="row">
            <div className="col-md-5">
              <DelayInput
                delayTimeout={300}
                onChange={this.onChangeAllowanceHandle.bind(this, i, 'key')}
                type="text"
                placeholder="Mô tả"
                className="form-control" />
            </div>
            <div className="col-md-5">
              <Cleave

                onChange={this.onChangeAllowanceHandle.bind(this, i, 'value')}
                className="form-control input-numeral"
                placeholder="Số tiền"
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: 'thousand',
                  numeralDecimalScale: 0
                }}
              />
            </div>
            <div className="col-md-2">
              <button
                type="button"
                onClick={this.removeItemHandle.bind(this, i)}
                className="btn btn-warning" style={{
                  height: '2.25003rem',
                  width: '2.25003rem',
                  fontWeight: 'bold'
                }}> -
              </button>
            </div>

          </div>
        </div>);
      }));
    }
    return (false);
  }

  getAccountState() {
    try {
      const { accountState } = this.props.init;
      if (_.isEmpty(accountState) === true) {
        return [];
      }
      const result = [];
      _.forEach(accountState, (item) => {
        result.push({ value: item.key, label: item.value });
      });
      return result;
    } catch (error) {
      return [];
    }
  }

  render() {
    const {
      tabActive,
      model,
      listVoip,
      selectedFileName
    } = this.state;
    const { accountSearchByKeyword } = this.props;
    const { listMember, trunkDefaultValue } = this.state;
    const tabClass = [];
    for (let index = 0; index <= 2; index += 1) {
      if (index === tabActive) {
        tabClass.push({
          header: 'navbar-nav-link active show',
          content: 'tab-pane fade active show'
        });
      }
      tabClass.push({
        header: 'navbar-nav-link',
        content: 'tab-pane fade'
      });
    }
    const accountState = this.getAccountState();
    return (
      <div>
        <div className="page-header page-header-light">
          <div className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="text-center d-lg-none w-100">
              <button type="button" className="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-second">
                <i className="icon-menu7 mr-2"></i>Profile navigation</button>
            </div>
            <div className="navbar-collapse collapse" id="navbar-second">
              <ul className="nav navbar-nav">
                <li className="nav-item">
                  <a
                    onClick={this.changeTab.bind(this, 0)}
                    className={tabClass[0].header}
                    data-toggle="tab"
                  >
                    <i className="icon-menu7 mr-2"></i>Thông tin cơ bản</a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={this.changeTab.bind(this, 1)}
                    className={tabClass[1].header}
                    data-toggle="tab"
                  >
                    <i className="icon-calendar3 mr-2"></i>
                    Lương + Phụ Cấp
                    <span className="badge badge-pill bg-success position-static ml-auto ml-lg-2"></span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={this.changeTab.bind(this, 2)}
                    className={tabClass[2].header}
                    data-toggle="tab"
                  >
                    <i className="icon-cog3 mr-2"></i>Hợp đồng và văn bản</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="content">
            <div className="d-flex align-items-start flex-column flex-md-row">
              <div className="tab-content w-100 ]order-md-1">
                <div className={tabClass[0].content} id="activity">
                  <div className="card">
                    <div className="card-header header-elements-inline">
                      <h5 className="card-title">Thông tin cá nhân</h5>
                      <div className="header-elements">
                      </div>
                    </div>
                    <div className="card-body">
                      <form onSubmit={this.onSubmit} >
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-6">
                              <label>Họ &amp; Tên <span className="text-danger">*</span></label>
                              <input
                                value={model.title}
                                onChange={this.onChangeHandle.bind(this, 'title')}
                                type="text"
                                className="form-control" />
                            </div>
                            <div className="col-md-6">
                              <label>Trạng thái <span className="text-danger">*</span></label>
                              <Select
                                onChange={this.onChangeHandle.bind(this, 'state')}
                                options={accountState}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-6">
                              <label>Email <span className="text-danger">*</span></label>
                              <input
                                value={model.email}
                                onChange={this.onChangeHandle.bind(this, 'email')}
                                type="text"
                                placeholder="Nhập email"
                                className="form-control" />
                            </div>
                            <div className="col-md-6">
                              <label>Số điện thoại đăng nhập <span className="text-danger">*</span></label>
                              <input
                                value={model.phone}
                                onChange={this.onChangeHandle.bind(this, 'phone')}
                                type="text"
                                className="form-control" />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-6">
                              <label>Địa chỉ</label>
                              <input
                                value={model.address}
                                onChange={this.onChangeHandle.bind(this, 'address')}
                                type="text"
                                autoComplete="off"
                                placeholder="Nhập địa chỉ"
                                className="form-control" />
                            </div>
                            <div className="col-md-6">
                              <label>Số điện thoại công việc</label>
                              <input
                                value={model.telephone}
                                onChange={this.onChangeHandle.bind(this, 'telephone')}
                                type="text"
                                className="form-control" />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-6">
                              <label>Mật khẩu <span className="text-danger">*</span></label>
                              <input
                                value={model.password}
                                onChange={this.onChangeHandle.bind(this, 'password')}
                                type="password"
                                autoComplete="off"
                                placeholder="Mật khẩu"
                                className="form-control" />
                            </div>
                            <div className="col-md-6">
                              <label>Xác nhận lại Mật khẩu <span className="text-danger">*</span></label>
                              <input
                                value={model.rePassword}
                                onChange={this.onChangeHandle.bind(this, 'rePassword')}
                                type="password"
                                autoComplete="off"
                                placeholder="Xác nhận Mật khẩu"
                                className="form-control" />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-6">
                              <label>Chức vụ</label>
                              <input
                                value={model.position}
                                onChange={this.onChangeHandle.bind(this, 'position')}
                                type="text"
                                placeholder="Nhập chức danh công việc"
                                className="form-control" />
                            </div>
                            <div className="col-md-6">
                              <label>Sinh nhật</label>

                              <InputMask
                                className="form-control"
                                mask="99/99/9999"
                                onChange={this.onChangeHandle.bind(this, 'birthday')}
                              />

                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-6">
                              <label>Mã số thuế</label>
                              <input
                                value={model.taxCode}
                                onChange={this.onChangeHandle.bind(this, 'taxCode')}
                                type="text"
                                placeholder="Nhập mã số thuế"
                                className="form-control" />
                            </div>
                            <div className="col-md-6">
                              <label>Ngày vào công ty</label>

                              <InputMask
                                className="form-control"
                                mask="99/99/9999"
                                onChange={this.onChangeHandle.bind(this, 'joinedAt')}
                              />

                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-6">
                              <label>Số tổng đài</label>
                              <Select
                                onChange={this.onChangeHandle.bind(this, 'trunkId')}
                                value={trunkDefaultValue}
                                options={listVoip}
                              />
                            </div>
                            <div className="col-md-6">
                              <label>Số máy nhánh</label>
                              <input
                                value={model.voip.extension}
                                onChange={this.onChangeHandle.bind(this, 'extension')}
                                type="text"
                                placeholder="Số máy nhánh tổng đài 5000"
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">

                            <div className="col-md-6">
                              <label>Đăng nhập từ xa</label>
                              <TagsInput
                                inputProps={{
                                  className: 'react-tagsinput-input'
                                }}
                                value={model.allowIp}
                                onChange={this.onChangeHandle.bind(this, 'allowIp')} />
                            </div>

                            <div className="col-md-6">
                              <label>Giới hạn chi</label>
                              <Cleave
                                onChange={this.onChangeHandle.bind(this, 'paymentVoucherLimit')}
                                className="form-control input-numeral"
                                placeholder="Nhập giới hạn chi"
                                value={model.paymentVoucherLimit}
                                options={{
                                  numeral: true,
                                  numeralThousandsGroupStyle: 'thousand',
                                  numeralDecimalScale: 0
                                }}
                              />
                            </div>

                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-6">
                              <label>Người ủy thác</label>
                              <Select
                                onChange={this.onChangeHandle.bind(this, 'delegates')}
                                options={this.props.renderDelegate(accountSearchByKeyword)}
                                isMulti
                              />
                            </div>
                            <div className="col-md-6">
                              <label>Người quản lý <span className="text-danger">*</span></label>
                              <Select
                                onChange={this.onChangeHandle.bind(this, 'parentIdentifyCode')}
                                options={listMember}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header header-elements-inline">
                      <h5 className="card-title">Tài khoản ngân hàng</h5>
                      <div className="header-elements">
                      </div>
                    </div>
                    <div className="card-body">
                      <form onSubmit={this.onSubmit} >
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-6">
                              <label>Tên người nhận</label>
                              <input
                                value={_.get(model, 'bank.fullname', '')}
                                onChange={this.onChangeHandle.bind(this, 'fullname')}
                                placeholder="Nhập tên người nhận"
                                type="text"
                                className="form-control" />
                            </div>
                            <div className="col-md-6">
                              <label>Số tài khoản</label>
                              <input
                                value={_.get(model, 'bank.number', '')}
                                onChange={this.onChangeHandle.bind(this, 'number')}
                                type="text"
                                placeholder="Nhập số tài khoản ngân hàng của bạn"
                                className="form-control" />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-6">
                              <label>Ngân hàng</label>
                              <input
                                value={_.get(model, 'bank.code', '')}
                                onChange={this.onChangeHandle.bind(this, 'code')}
                                type="text"
                                placeholder="Nhập tên ngân hàng bạn muốn nhận tiền"
                                className="form-control" />
                            </div>
                            <div className="col-md-6">
                              <label>Chi Nhánh</label>
                              <input
                                value={_.get(model, 'bank.branch', '')}
                                onChange={this.onChangeHandle.bind(this, 'branch')}
                                type="text"
                                placeholder="Nhập chi nhánh của ngân hàng"
                                className="form-control" />
                            </div>
                          </div>
                        </div>
                        <div className="text-right">
                          <button type="submit" className="btn btn-primary">Lưu lại</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div
                  className={tabClass[1].content}
                  id="schedule">
                  <div className="card">
                    <div className="card-header header-elements-inline">
                      <h5 className="card-title">Lương + Phụ Cấp</h5>
                      <div className="header-elements">
                      </div>
                    </div>
                    <div className="card-body">
                      <form onSubmit={this.onSubmit} >
                        <div className="form-group">
                          <div className="form-check">
                            <label className="form-check-label">
                              <div className="uniform-checker">
                                <span
                                  className={model.salary.isSocialInsurance === true ? 'checked' : ''}
                                >
                                  <input
                                    value={model.salary.isSocialInsurance}
                                    onChange={this.onChangeSocialInsurance}
                                    type="checkbox"
                                    className="form-input-styled"
                                    data-fouc="true" />
                                </span>
                              </div>Đóng Bảo Hiểm Xã Hôi</label>
                          </div>
                          <img src={Images.bhxh} /></div>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-12">
                              <label>Lương căn bản</label>
                              <Cleave

                                value={model.salary.basic}
                                onChange={this.onChangeHandle.bind(this, 'basic')}
                                className="form-control input-numeral"
                                placeholder="Số tiền"
                                options={{
                                  numeral: true,
                                  numeralThousandsGroupStyle: 'thousand',
                                  numeralDecimalScale: 0
                                }}
                              />

                            </div>

                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <label>Phụ Cấp</label>

                          </div>
                          <div className="col-md-6" style={{ textAlign: 'right' }}>
                            <button
                              type="button"
                              onClick={this.addItemHandle}
                              className="btn btn-success">+
                            </button>

                          </div>

                        </div>
                        {this.allowanceRender()}
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-12">
                              <label>Ghi chú</label>
                              <textarea
                                onChange={this.onChangeHandle.bind(this, 'description')}
                                rows="4"
                                cols="4"
                                placeholder="Nhập ghi chú."
                                className="form-control"
                              ></textarea>
                            </div>

                          </div>
                        </div>
                        <div className="text-right">
                          <button type="submit" className="btn btn-primary">Lưu lại</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div
                  className={tabClass[2].content}
                  id="settings">
                  <div className="card">
                    <div className="card-header header-elements-inline">
                      <h5 className="card-title">Quản lý hợp đồng và các quyết định khen thưởng</h5>
                      <div className="header-elements">
                      </div>
                    </div>
                    <div className="card-body">
                      <form onSubmit={this.onSubmit} >
                        <fieldset className="card-body">
                          <div className="form-group">
                            <label>Upload văn bản liên quan đến nhân sự này: <span className="text-danger">*</span></label>
                            <UploadFileContainer
                              setFiles={this.setFiles.bind(this)}
                            />
                            <span className="form-text text-muted">Accepted formats: doc, docx, pdf, bmp, png, gif, jpg, jpeg</span>
                          </div>
                        </fieldset>
                        <div className="text-right">
                          <button type="submit" className="btn btn-primary">Save changes</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ marginLeft: 20 }} className="sidebar-component sidebar-component-right wmin-500 border-0 shadow-0 order-1 order-md-2 sidebar-expand-md">
                <div className="card">
                  <div className="card-header header-elements-sm-inline">
                    <h6 className="card-title">Phân quyền</h6>
                    <div className="header-elements">
                      {this.renderBranchBtn()} {this.renderDepartmentBtn()}
                    </div>
                  </div>
                  <div className="card-body">
                    {this.renderPermission()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotificationContainer />
      </div>

    );
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.accounts !== newProps.accounts) {
      const { listMember } = this.props.getMember();
      this.setState({ listMember });
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      accountSearchByKeyword: payload.accountReducer.accountSearchByKeyword,
      departments: payload.departmentReducer.departments,
      branchs: payload.branchReducer.branchs,
      init: payload.generalReducer.init,
      accounts: payload.accountReducer.accounts,
      userInfo: payload.generalReducer.userInfo,
      uploadPath: payload.generalReducer.uploadPath,
      downloadPath: payload.generalReducer.downloadPath,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(CreateMember)
);
