import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

class PagingContainer extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = {};
  }

  renderPrev() {
    if (this.props.pageNum > 1) {
      return (<a onClick={this.props.onClick.bind(this, 'prev')} 
        className="paginate_button previous" 
        tabIndex="0"> ← Trang trước</a>);
    }
    return (<span></span>);
  }

  renderNext() {
    if (this.props.count >= this.props.limit) {
      return (<a 
        onClick={this.props.onClick.bind(this, 'next')} 
        className="paginate_button next"
        tabIndex="0"> Trang sau →</a>);
    }
    return (<span></span>);
  }

  render() {
    return (
      <div style={{ border: 'none' }} className="datatable-footer" >
        <div style={{ float: 'left' }} className="dataTables_paginate paging_simple">
          {this.renderPrev()}
          {this.renderNext()}
        </div>
      </div>
      
    );
  }
}

function mapsDispatchToProp(dispatch) {
  return {};
}

export default connect((rootState) => {
  return {
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(PagingContainer);
