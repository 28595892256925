import _ from 'lodash';
import axios from 'axios';
import * as io from 'socket.io-client';

import { NotificationContainer, NotificationManager } from 'react-notifications';

const AsyncForEach = require('await-async-foreach');
const moment = require('moment-business-days');

const Private = {
  getServiceChildren(buildServices, parentStates = [], serviceIdentifyCode, serviceType = '') {
    let result = {};
    if (_.isEmpty(buildServices) === false) {
      buildServices.forEach((item) => {
        let service = {};

        let states = _.clone(parentStates);
        const stateSettings = JSON.parse(_.get(item, 'settings.state', '[]'));
        const childServiceType = _.get(item, 'settings.state', '') || serviceType;
        if (_.isEmpty(stateSettings) === false) {
          states = stateSettings;
          // _.forEach(stateSettings, (element) => {
          //   if (states.indexOf(element) === -1) {
          //     // states.push(element);
          //   }
          // });
        }

        if (item.identifyCode === serviceIdentifyCode) {
          item.serviceType = childServiceType;
          service = item;
        } else if (_.isEmpty(item.children) === false) {
          service = Private.getServiceChildren(item.children, states, false, childServiceType);
        }

        if (_.isEmpty(service) === false) {
          if (_.get(service, 'states', false) === false) {
            const stateList = [];
            _.forEach(states, (state) => {
              stateList.push({
                value: state.state,
                label: state.title,
                color: state.color,
                serviceType: childServiceType,
                state,
                service
              });
            });
            service.stateList = stateList;
            service.states = states;
          }
          result = service;
        }
      });
    }
    return result;
  },

  listServiceHandleChildren(parent, data, parentStates, types) {
    try {
      const result = Object.assign([], parent);
      if (_.isEmpty(data) === false) {
        data.forEach((item) => {
          let states = _.clone(parentStates);
          const amount = Number(_.get(item, 'settings.amount', 0));
          const type = _.get(item, 'settings.type', '');
          if (_.isEmpty(type) === true || _.isEmpty(types) === true) {
            const node = {
              text: item.title,
              amount,
              states,
              discount: item.discount,
              document: item.document,
              id: item.identifyCode,
              settings: item.settings
            };
            const stateSettings = JSON.parse(_.get(item, 'settings.state', '[]'));
            if (_.isEmpty(stateSettings) === false) {
              states = stateSettings;
              // _.forEach(stateSettings, (element) => {
              //   if (states.indexOf(element) === -1) {
              //     // states.push(element);
              //   }
              // });
            }
            node.states = states;
            if (_.isEmpty(item.children) === false) {
              node.children = Private.listServiceHandleChildren(parent, item.children, states, types);
            }
            result.push(node);
          } else if (_.indexOf(types, type) >= 0) {
            const node = {
              text: item.title,
              amount,
              states,
              discount: item.discount,
              document: item.document,
              id: item.identifyCode,
              settings: item.settings
            };
            const stateSettings = JSON.parse(_.get(item, 'settings.state', '[]'));
            if (_.isEmpty(stateSettings) === false) {
              _.forEach(stateSettings, (element) => {
                if (states.indexOf(element) === -1) {
                  states.push(element);
                }
              });
            }
            node.states = states;
            if (_.isEmpty(item.children) === false) {
              node.children = Private.listServiceHandleChildren(parent, item.children, states, types);
            }
            result.push(node);
          }
        });

        return result;
      }
      return [];
    } catch (error) {
      return [];
    }
  }
};

function checkScope(scopeName, props) {
  try {
    /**
     * Root
     */
    const { listScope } = props;
    if (_.find(listScope, { code: 'system.root' })) {
      return true;
    }
    /**
     * Array Scope
     */
    if (_.isArray(scopeName) === true) {
      let result = false;
      _.forEach(scopeName, (item) => {
        if (_.find(listScope, { code: item }) && result === false) {
          result = true;
        }
      });
      return result;
    }
    /**
     * String Scope
     */
    if (_.find(listScope, { code: scopeName })) {
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
}

async function fileUpload(selectedFile, props) {
  const result = {
    code: 0,
    data: [],
    message: 'Upload file thất bại'
  };

  try {
    if (_.isEmpty(selectedFile) === false) {
      const { userInfo } = props;

      const file = [];
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: userInfo.accessToken,
          accessToken: userInfo.accessToken
        }
      };
      let flagFail = false;
      await AsyncForEach(selectedFile, async (element, k) => {
        if (flagFail === true) return;
        const data = new FormData();
        data.append('file', element.file);
        await axios.post(`${props.uploadPath}/upload-contract`, data, config).then((res) => {
          const code = _.get(res, 'data.code', false);
          if (code === 100) {
            result.code = 1;
            result.data.push(res.data.data.message.pathname);
            result.message = '';
          } else {
            console.log(element.file);
            flagFail = true;
            result.message = _.get(res, 'data.data.message.message', 'File không hợp lệ');
            NotificationManager.error(`Tải tập tin ${element.file.name} bị lỗi ( ${result.message} )`, '', 5000);
          }
        });
      });
      if (flagFail === true) {
        result.code = -1;
        result.message = 'Quá trình tải tập tin không thành công';
      }
      return result;
    }
    return result;
  } catch (error) {
    console.log(error);
    return result;
  }
}

function onConnect() {
  const accessToken = localStorage.getItem('accessToken');
  if (!accessToken) {
    return false;
  }
  // return true;
  const socket = io('https://stream.vnhub.com', {
    query: { accessToken }
  });
  return socket;
}

function subscribe(props, key) {
  try {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      return false;
    }

    props.socketIo.on('message', (body) => {
      if (_.isEmpty(body) === false) {
        switch (key) {
          case 'NotifyInvoice': {
            props.dispatch({
              type: 'SOCKET_ORDER',
              payload: body.data
            });
            break;
          }

          case 'NotifyBalance': {
            props.dispatch({
              type: 'SOCKET_WALLET',
              payload: body.data
            });
            break;
          }

          case 'NotifyVoip': {
            props.dispatch({
              type: 'PUSH_SOCKET_CALL_LOG',
              payload: body.data
            });
            break;
          }

          default:
            break;
        }
      }
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

function serviceDetail(buildServices, serviceIdentifyCode) {
  let result = {};
  if (_.isEmpty(buildServices) === false) {
    buildServices.forEach((item) => {
      let service = {};
      const states = JSON.parse(_.get(item, 'settings.state', '[]'));
      const serviceType = _.get(item, 'settings.type', '');
      if (item.identifyCode === serviceIdentifyCode) {
        item.serviceType = serviceType;
        service = item;
      } else if (_.isEmpty(item.children) === false) {
        service = Private.getServiceChildren(item.children, states, serviceIdentifyCode, serviceType);
      }

      if (_.isEmpty(service) === false) {
        if (_.get(service, 'states', false) === false) {
          const stateList = [];
          _.forEach(states, (state) => {
            stateList.push({
              value: state.state,
              label: state.title,
              color: state.color,
              serviceType,
              state,
              service
            });
          });
          service.stateList = stateList;
          service.states = states;
        }
        result = service;
      }
    });
  }
  return result;
}

function serviceHandleDatas(buildServices, types = ['exit']) {
  try {
    const result = { list: [], setlectedItem: {} };
    if (_.isEmpty(buildServices) === false) {
      buildServices.forEach((item) => {
        const states = JSON.parse(_.get(item, 'settings.state', '[]'));
        const amount = Number(_.get(item, 'settings.amount', 0));
        const type = _.get(item, 'settings.type', 'exit');

        if (_.includes(types, type)) {
          const node = {
            text: item.title,
            states,
            amount,
            discount: item.discount,
            document: item.document,
            children: []
          };
          if (_.isEmpty(item.children) === false) {
            node.children = Private.listServiceHandleChildren([], item.children, states);
          }
          result.list.push(node);
        }
      });
    }
    result.list.unshift({ text: 'Tất cả dịch vụ', value: 'all' });
    return result;
  } catch (error) {
    return false;
  }
}

function buildListLocation(init) {
  const listLocation = [];
  if (init.area) {
    listLocation.push({ text: 'Tất cả khu vực', id: 'all' });
    const mapLocations = {};
    init.area.forEach((element) => {
      if (!mapLocations[element.tag]) {
        mapLocations[element.tag] = [];
      }
      mapLocations[element.tag].push({ text: element.value, id: element.key });
    });

    Object.keys(mapLocations).forEach((key) => {
      const location = {
        text: key,
        children: []
      };
      mapLocations[key].forEach((item) => {
        location.children.push(item);
      });
      listLocation.push(location);
    });
  }
  return listLocation;
}

function buildListCustomerType(init) {
  const listCustomerType = [];
  if (init.customerType) {
    _.forEach(init.customerType, (item) => {
      listCustomerType.push({
        value: item.key.toString(),
        label: item.text,
        color: item.color,
        children: item.children
      });
    });
  }
  return listCustomerType;
}

function buildListCustomerDenyType(childrens) {
  const listCustomerDenyType = [];
  if (childrens) {
    _.forEach(childrens, (children) => {
      listCustomerDenyType.push({
        value: children.key.toString(),
        label: children.text,
        color: children.color
      });
    });
  }
  return listCustomerDenyType;
}

function buildListCustomerSource(init) {
  const listCustomerSource = [];
  if (init.customerSource) {
    _.forEach(init.customerSource, (item) => {
      listCustomerSource.push({
        ...item,
        value: item.key.toString(),
        label: item.text
      });
    });
  }
  return listCustomerSource;
}

function buildListCountry(national) {
  const listCountry = [];
  if (national) {
    national.forEach((element) => {
      listCountry.push({
        ...element,
        value: element.value,
        label: element.value
      });
    });
  }
  return listCountry;
}

function buildListCountryArrival(init, isWorkingDayStatus = true) {
  const result = [{ value: 'all', label: 'Chọn quốc tịch', amount: 0 }];
  if (init.countryArrival) {
    init.countryArrival.forEach((element) => {
      if (element.isHidden === false) {
        if (isWorkingDayStatus === true) {
          result.push({
            ...element,
            value: element.code,
            label: element.title,
            note: element.note,
            amount: element.amount,
            isDifficult: element.isDifficult
          });
        } else if (element.namespace !== 'difficult' && element.namespace !== 'extremelyDifficult') {
          result.push({
            ...element,
            value: element.code,
            label: element.title,
            note: element.note,
            amount: element.amount,
            isDifficult: element.isDifficult
          });
        }
      }
    });
  }
  return result;
}

function buildListAirportArrival(data) {
  const result = [{ value: 'all', label: 'Chọn sân bay' }];
  if (_.isEmpty(data) === false) {
    data.forEach((element) => {
      result.push({
        ...element,
        value: element.key,
        label: element.title
      });
    });
  }
  return result;
}

function buildListProcessingTimeArrival(
  processingTime, isWorkingDayStatus = true,
  airportArrivals = false, stays = {}
) {
  const result = [];

  if (_.isEmpty(processingTime) === false) {
    if (isWorkingDayStatus === false) {
      const data = _.get(processingTime, 'dayWeekendAndHoliday', []);
      data.forEach((element) => {
        result.push({
          ...element,
          value: element.key,
          label: element.title
        });
      });
    } else {
      const data = _.get(processingTime, 'normal', []);

      data.forEach((element) => {
        result.push({
          ...element,
          value: element.key,
          label: element.title
        });
      });
    }

    if (_.isEmpty(airportArrivals) === false) {
      const resultFilterd = [];
      _.forEach(result, (item) => {
        if (_.isEmpty(item.supportReceiver) === false) {
          let isValid = false;
          _.forEach(item.supportReceiver, (supportReceiver) => {
            if (_.indexOf(airportArrivals, supportReceiver) >= 0) {
              isValid = true;
            }
          });
          if (isValid === true) {
            resultFilterd.push(item);
          }
        }
      });

      return resultFilterd;
    }
  }
  return result;
}

function buildListPurposeArrival(purposeArrivals, processingTimeArrival = {}) {
  const result = [{ value: 'all', label: 'Chọn mục thời gian lưu trú' }];
  if (_.isEmpty(purposeArrivals) === false) {
    const availableStays = _.get(processingTimeArrival, 'availableStay', false);
    if (_.isEmpty(availableStays) === false) {
      _.forEach(availableStays, (availableStay) => {
        const purposeArrival = _.find(purposeArrivals, { key: availableStay });
        if (_.isEmpty(purposeArrival) === false) {
          result.push({
            ...purposeArrival,
            value: purposeArrival.key,
            label: purposeArrival.title,
            amount: purposeArrival.amount,
            description: purposeArrival.titdescriptionle || ''
          });
        }
      });
    } else {
      purposeArrivals.forEach((purposeArrival) => {
        result.push({
          ...purposeArrival,
          value: purposeArrival.key,
          label: purposeArrival.title,
          amount: purposeArrival.amount,
          description: purposeArrival.titdescriptionle || ''
        });
      });
    }

    return result;
  }
  return result;
}

function buildSetting(settings, profile, serviceCode) {
  const setting = {};
  const namespace = _.get(profile, 'countryArrival.namespace', false);
  if (_.isEmpty(settings) === false) {
    _.forEach(settings, (v, k) => {
      const [key, type] = k.split('.');
      if (_.isUndefined(setting[key]) === true) {
        setting[key] = {};
        setting[key][type] = JSON.parse(v);
      } else {
        setting[key][type] = JSON.parse(v);
      }
    });
  }

  const result = _.clone(setting.default);
  if (_.isEmpty(namespace) === false) {
    const namespaceSettings = _.get(setting, namespace, false);
    if (_.isEmpty(namespaceSettings) === false) {
      _.forEach(namespaceSettings, (namespaceSetting, k) => {
        result[k] = namespaceSetting;
      });
    }
  }
  return result;
}

function buildListExtraServiceArrival(extraService) {
  const result = [];
  if (_.isEmpty(extraService) === false) {
    extraService.forEach((element) => {
      result.push({
        ...element,
        value: element.key,
        label: element.title,
        amount: element.amount,
        description: element.description || ''
      });
    });
  }
  return result;
}

function localStoreFilter(objID, objType) {
  let localStore = [];
  const localStoreFilterStr = localStorage.getItem('localStoreFilter');
  if (_.isEmpty(localStoreFilterStr) === false) {
    localStore = JSON.parse(localStoreFilterStr);
  }
  const filter = _.find(localStore, { objID, objType });
  return _.get(filter, 'query', false);
}

function localStorePageNum(objID, objType) {
  let localStore = [];
  const localStoreFilterStr = localStorage.getItem('localStoreFilter');
  if (_.isEmpty(localStoreFilterStr) === false) {
    localStore = JSON.parse(localStoreFilterStr);
  }
  const filter = _.find(localStore, { objID, objType });

  const query = _.get(filter, 'query', false);
  if (_.isEmpty(query) === true) {
    return 1;
  }
  const paging = _.get(query, 'paging', false);
  if (_.isEmpty(paging) === true) {
    return 1;
  }

  if (paging.start <= 0) {
    return 1;
  }
  return (paging.limit / paging.start) + 1;
}

function isStaff(props) {
  const { init } = props;
  if (_.get(init, 'accountInfo.group', '') === 'staff') {
    return true;
  }
  return false;
}

function isWorkingDay(momentTime = null) {
  moment.updateLocale('us', {
    holidays: [
      // '22/10/2020',
      '30/04/2020',
      '01/05/2020',
      '02/09/2020',
      '01/01/2021',
      '10/02/2021',
      '11/02/2021',
      '12/02/2021',
      '13/02/2021',
      '14/02/2021',
      '30/04/2021',
      '01/05/2021',
      '02/09/2021'
    ],
    holidayFormat: 'DD/MM/YYYY',
    workingWeekdays: [1, 2, 3, 4, 5]
  });
  let datetime = momentTime;
  if (datetime === null) {
    datetime = moment();
  }
  if (datetime.isHoliday() === false && datetime.isBusinessDay() === true) {
    return true;
  }
  return false;
}

function addWorkingDay(days = 0, hours = 0, minutes = 0) {
  try {
    moment.updateLocale('us', {
      holidays: [
        '30/04/2020',
        '01/05/2020',
        '02/09/2020',
        '01/01/2021',
        '10/02/2021',
        '11/02/2021',
        '12/02/2021',
        '13/02/2021',
        '14/02/2021',
        '30/04/2021',
        '01/05/2021',
        '02/09/2021'
      ],
      holidayFormat: 'DD/MM/YYYY',
      workingWeekdays: [1, 2, 3, 4, 5]
    });

    let plusDate = 0;
    if (days > 0) {
      plusDate += days;
    }
    if (hours > 0) {
      plusDate += (hours / 8);
    }
    if (minutes > 0) {
      plusDate += (minutes / 60 / 8);
    }
    return moment(moment(), 'DD-MM-YYYY HH:mm:ss').businessAdd(plusDate);
  } catch (error) {
    return false;
  }
}

export {
  isWorkingDay,
  addWorkingDay,
  buildSetting,
  buildListCountry,
  buildListCountryArrival,
  buildListAirportArrival,
  buildListProcessingTimeArrival,
  buildListPurposeArrival,
  buildListExtraServiceArrival,
  buildListLocation,
  buildListCustomerType,
  buildListCustomerDenyType,
  buildListCustomerSource,
  isStaff,
  onConnect,
  subscribe,
  checkScope,
  fileUpload,
  serviceDetail,
  localStoreFilter,
  localStorePageNum,
  serviceHandleDatas
};

