
/**
 * CRUD
 */
export const POST_UTILITY_SEND_SMS = 'POST_UTILITY_SEND_SMS';
export const POST_UTILITY_SEND_SMS_SUCCESS = 'POST_UTILITY_SEND_SMS_SUCCESS';
export const POST_UTILITY_SEND_SMS_FAIL = 'POST_UTILITY_SEND_SMS_FAIL';

export const POST_UTILITY_SEND_MAIL = 'POST_UTILITY_SEND_MAIL';
export const POST_UTILITY_SEND_MAIL_SUCCESS = 'POST_UTILITY_SEND_MAIL_SUCCESS';
export const POST_UTILITY_SEND_MAIL_FAIL = 'POST_UTILITY_SEND_MAIL_FAIL';

export const SEND_SMS_INFO = 'SEND_SMS_INFO';
export const SEND_SMS = 'SEND_SMS';

