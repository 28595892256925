import * as types from './arrivalOrders.types';

/**
 * 
 * @param {*} payload 
 * @param {*} callback 
 */

export function detailArrivalOrder(payload, callback) {
  return {
    type: types.POST_ARRIVAL_ORDER_DETAIL,
    payload,
    callback
  };
}

export function createArrivalOrder(payload, callback) {
  return {
    type: types.POST_ARRIVAL_ORDER,
    payload,
    callback
  };
}

export function modifyArrivalOrder(payload, callback) {
  return {
    type: types.PUT_ARRIVAL_ORDER,
    payload,
    callback
  };
}

export function modifyArrivalOrderState(payload, callback) {
  return {
    type: types.PUT_ARRIVAL_ORDER_STATE,
    payload,
    callback
  };
}

export function deleteArrivalOrder(payload, callback) {
  return {
    type: types.DELETE_ARRIVAL_ORDER,
    payload,
    callback
  };
}

