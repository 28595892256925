import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Images } from '../../../ui/assets/images';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        account: '',
        password: ''
      }
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onloadCallback = this.onloadCallback.bind(this);
    this.validateOnSubmit = this.validateOnSubmit.bind(this);
  }

  onChange(e) {
    try {
      const value = _.get(e, 'target.value');
      const name = _.get(e, 'target.name');
      const user = Object.assign({}, this.state.user);
      user[name] = value;
      this.setState({ user });
    } catch (error) {
      /**
       * 
       */
    }
  }

  validateOnSubmit() {
    const { user } = this.state;
    if (!user.account) {
      NotificationManager.error('Tài khoản không được để trống', '', 3000);
      return false;
    }
    if (!user.password) {
      NotificationManager.error('Mật khẩu không được để trống', '', 3000);
      return false;
    }
    return true;
  }
  
  onSubmit = async (e) => {
    try {
      e.preventDefault();
      const validate = this.validateOnSubmit();
      if (validate === true) {
        this.props.onSubmit(this.state.user);
      }
    } catch (error) {
      /**
       * 
       */
    }
  }

  onloadCallback(value) {
    const user = Object.assign({}, this.state.user);
    user.reCaptcha = value;
    this.setState({ user });
  }

  render() {
    console.log('test');
    const { user, rules } = this.state;
    return (
      <div>
        <div className="page-content">

          <div className="content-wrapper" style={{ minHeight: '100vh' }}>

            <div className="content d-flex justify-content-center align-items-center">

              <form 
                onSubmit={this.onSubmit}
                ref={(element) => { this.loginForm = element; }}
                
                className="login-form" 
              >
                <div className="card mb-0">
                  <div className="card-body">
                    
                    <div className="text-center mb-3">
                      {/* <i className="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1"></i> */}
                      <img style={{ marginBottom: 20, height: 45 }} src={Images.printLogo} /> 
                      <h5 style={{ marginBottom: 5 }} className="mb-0">Hệ Thống Quản Lý Du Lịch</h5>
                      <span className="d-block text-muted">Vui lòng đăng nhập</span>
                    </div>

                    <div className="form-group form-group-feedback form-group-feedback-left">
                      <input 
                        onChange={this.onChange}
                        name="account"
                        type="text" 
                        className="form-control" 
                        placeholder="Nhập số điện thoại" />
                      <div className="form-control-feedback">
                        <i className="icon-user text-muted"></i>
                      </div>
                    </div>

                    <div className="form-group form-group-feedback form-group-feedback-left">
                      <input 
                        onChange={this.onChange}
                        name="password" 
                        type="password" 
                        className="form-control" 
                        autoComplete="off"
                        placeholder="Nhập mật khẩu" />
                      <div className="form-control-feedback">
                        <i className="icon-lock2 text-muted"></i>
                      </div>
                    </div>

                    <div className="form-group" style={{ textAlign: 'center' }}>
                      <button style={{ marginBottom: 10 }} type="submit" className="btn btn-primary btn-block">
                        Đăng nhập <i className="icon-circle-right2 ml-2"></i>
                      </button>
                      <span className="d-block text-muted">Hotline: <a href={'tel:02877777979'}><i className="fa fa-phone-square"></i> 028.7777.79.79</a></span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate() {
    try {
      if (_.size(this.props.errorMessage) > 0) {
        const message = this.props.errorMessage;
        if (message.statusMessage === 'success') {
          NotificationManager.info(message.contentMessage);
        } else {
          NotificationManager.error(message.contentMessage);
        }
      }
    } catch (error) {
      /**
       * 
       */
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      userInfo: payload.generalReducer.userInfo,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(Login)
);
