import * as types from './services.types';

/**
 * 
 * @param {*} payload 
 * @param {*} callback 
 */
export function listService(payload, callback) {
  return {
    type: types.GET_BUILD_SERVICE,
    payload,
    callback
  };
}

export function detailService(payload, callback) {
  return {
    type: types.GET_SERVICE,
    payload,
    callback
  };
}

export function createService(payload, callback) {
  return {
    type: types.POST_SERVICE,
    payload,
    callback
  };
}

export function modifyService(payload, callback) {
  return {
    type: types.PUT_SERVICE,
    payload,
    callback
  };
}
export function deleteService(payload, callback) {
  return {
    type: types.DELETE_SERVICE,
    payload,
    callback
  };
}
