import { API_URL } from '../../configs/api.config';
import Request from '../middleware/request';

const coboPoboAPI = {
  /**
   * 
   */
  postCreate(args) {
    return Request.callAPI('post', `${API_URL}/CoboPobo`, { ...args });
  },

  putModify(args) {
    return Request.callAPI('put', `${API_URL}/CoboPobo/${args.id}`, { ...args.data });
  },

  postSearch(args) {
    return Request.callAPI('post', `${API_URL}/CoboPobo/Search`, { ...args });
  },

  postExport(args) {
    return Request.callAPI('post', `${API_URL}/CoboPobo/Export`, { ...args });
  }

};

export default coboPoboAPI;

