import * as types from './services.types';

const initialState = {
  buildServices: [],
  service: []
};

export default function services(state = initialState, action) {
  switch (action.type) {
    case types.GET_BUILD_SERVICE_SUCCESS: {
      return {
        ...state,
        buildServices: action.payload
      };
    }
    case types.GET_SERVICE_SUCCESS: {
      return {
        ...state,
        service: action.payload
      };
    }

    default:
      return state;
  }
}
