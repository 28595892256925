import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class SearchPermission extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header header-elements-sm-inline">
                <h6 className="card-title">Danh sách các quyền quản lý hệ thống</h6>
                <div className="header-elements">
                  <div className="header-elements">
                    <div className="align-self-md-center ml-md-3 mt-2 mt-md-0">
                      <Link to="/permission-create" className="btn bg-teal-400">
                        <i className="icon-add mr-2"></i> Thêm quyền
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table text-nowrap">
                  <thead>
                    <tr>
                      <th>Tên Quyền</th>
                      <th>Mô Tả</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="media-title font-weight-semibold"><span className="badge badge-mark border-primary mr-1"></span><a href="#">Quản Lý Nhân Viên</a></div><span className="text-muted">Manager_User</span></div>
                        </div>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="ml-3">
                            <div className="media-title font-weight-semibold"><a href="#" className="c-dark">Xóa Khoản Nhân Viên</a></div><span className="text-muted">Del_User</span></div>
                        </div>
                      </td>
                      <td></td>
                      <td><a href="#"><i className="icon-cross3 red"></i></a></td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="ml-3">
                            <div className="media-title font-weight-semibold"><a href="#" className="c-dark">Thêm Tài Khoản Nhân Viên</a></div><span className="text-muted">Add_User</span></div>
                        </div>
                      </td>
                      <td></td>
                      <td><a href="#"><i className="icon-cross3 red"></i></a></td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="ml-3">
                            <div className="media-title font-weight-semibold"><a href="#" className="c-dark">Sửa tài khoản nhân viên</a></div><span className="text-muted">Edit_User</span></div>
                        </div>
                      </td>
                      <td></td>
                      <td><a href="#"><i className="icon-cross3 red"></i></a></td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="media-title font-weight-semibold"><span className="badge badge-mark border-primary mr-1"></span><a href="#">Quản Lý Đối Tác</a></div><span className="text-muted">Manager_Agency</span></div>
                        </div>
                      </td>
                      <td>Chỉ thấy được đối tác do chính user tạo và user nó quản lý</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="ml-3">
                            <div className="media-title font-weight-semibold"><a href="#" className="c-dark">Thêm đối tác</a></div><span className="text-muted">Add_Agency</span></div>
                        </div>
                      </td>
                      <td></td>
                      <td><a href="#"><i className="icon-cross3 red"></i></a></td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="ml-3">
                            <div className="media-title font-weight-semibold"><a href="#" className="c-dark">Sửa đối tác</a></div><span className="text-muted">Edit_Agency</span></div>
                        </div>
                      </td>
                      <td>Chỉnh sửa thông tin trong vòng 24h,Chứng thực để active thì trong vòng 72h</td>
                      <td><a href="#"><i className="icon-cross3 red"></i></a></td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="media-title font-weight-semibold"><span className="badge badge-mark border-primary mr-1"></span><a href="#">Quyền mặt định</a></div><span className="text-muted">General_controller</span></div>
                        </div>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="ml-3">
                            <div className="media-title font-weight-semibold"><a href="#" className="c-dark">Ủy nhiệm xem</a></div><span className="text-muted">delegate_view</span></div>
                        </div>
                      </td>
                      <td>Có thể ủy nhiệm cho bất kỳ nhân viên nào.Chỉ được xem các danh sách được ủy nhiệm không được chỉnh sửa</td>
                      <td><a href="#"><i className="icon-cross3 red"></i></a></td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="ml-3">
                            <div className="media-title font-weight-semibold"><a href="#" className="c-dark">Ủy Nhiệm Xử Lý</a></div><span className="text-muted">delegate_control</span></div>
                        </div>
                      </td>
                      <td>Có thể ủy nhiệm cho bất kỳ nhân viên nào toàn quyền của người ủy nhiệm</td>
                      <td><a href="#"><i className="icon-cross3 red"></i></a></td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="ml-3">
                            <div className="media-title font-weight-semibold"><a href="#" className="c-dark">Quản Lý Ví</a></div><span className="text-muted">Wallet</span></div>
                        </div>
                      </td>
                      <td>Bất kỳ account nào cũng có thể sử dụng quyền này</td>
                      <td><a href="#"><i className="icon-cross3 red"></i></a></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    );
  }

  componentDidMount() {
    try {
      /**
       * 
       */
    } catch (error) {
      /**
       * 
       */
    }
  }

  componentWillUnmount() {
    try {
      /**
       * 
       */
    } catch (error) {
      /**
       * 
       */
    }
  }

  componentDidUpdate() {
    try {
      /**
       * 
       */
    } catch (error) {
      /**
       * 
       */
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      userInfo: payload.generalReducer.userInfo,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(SearchPermission)
);
