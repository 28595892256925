/* eslint-disable react/no-typos */
import React from 'react';
import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import moment from 'moment';
import Decimal from 'decimal.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingUsd, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { Images } from '../../../ui/assets/images';
import PagingContainer from '../../ultils/paging.container';
import { PAGE_SMALL_LIMIT } from '../../../configs/constants';

import {
  paging,
  isStaff
} from '../../../helpers';

const path = require('path');

const defaultState = {
  pageNum: 1,
  selectedFile: [],
  listBankAccount: [],
  defaultMethod: { value: 'cash', label: 'Tiền mặt' },
  defaultType: { value: 'balance', label: 'Thay đổi hạn mức tín dụng', display: [true, false, false] },
  methods: [
    { value: 'cash', label: 'Tiền mặt' },
    { value: 'transfer', label: 'Chuyển khoản' }
  ],
  amount: 0,
  type: 'deposit',
  bankAccountDefaultValue: {},
  model: {
    method: 'cash',
    bankAccount: '',
    deposit: 0,
    withdraw: 0,
    creditLimit: 0,
    description: '',
    files: []
  },
  searching: {
    paging: {
      start: 0,
      limit: PAGE_SMALL_LIMIT
    }
  }
};

class SearchWalletLiability extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
    // this.onSubmit = this.onSubmit.bind(this);
    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.setFiles = this.setFiles.bind(this);
    this.setListBankAccount = this.setListBankAccount.bind(this);
    this.callbackWallet = this.callbackWallet.bind(this);
  }

  callbackWallet() {
    try {
      const { wallet, socketBalance } = this.props;
      if (_.isEmpty(socketBalance) === false) {
        if (wallet.accountId === socketBalance.accountId && wallet !== socketBalance) {
          this.props.dispatch({
            type: 'POST_SEARCH_WALLET_DETAIL_SUCCESS',
            payload: socketBalance
          });

          NotificationManager.warning('Vừa có thao tác thay đổi số dư ví', '', 1500);
        }
      }
    } catch (error) {
      // console.log(error);
    }
  }

  setFiles(selectedFile) {
    try {
      this.setState({ selectedFile });
    } catch (error) {
      // console.log(error);
    }
  }

  onChangeHandle(key, e) {
    try {
      const { listBankAccount } = this.state;
      const model = Object.assign({}, this.state.model);
      const result = {};
      switch (key) {
        case 'creditLimit': {
          const value = _.get(e, 'target.rawValue', _.get(e, 'value', false));
          const number = Number(value);
          model.creditLimit = number >= 0 ? number : 0;
          break;
        }
        case 'amount': {
          const value = _.get(e, 'target.rawValue', _.get(e, 'value', false));
          const number = Number(value);
          result.amount = number >= 0 ? number : 0;
          break;
        }
        case 'bankAccount': {
          const value = _.get(e, 'target.value', _.get(e, 'value', false));
          result.bankAccountDefaultValue = e;
          model.bankAccount = value;
          break;
        }
        case 'type': {
          const isValid = _.find(this.props.types, { value: e.value });
          if (isValid) {
            result.defaultType = e;
            result.type = e.value;
            result.amount = 0;
            model.deposit = 0;
            model.creditLimit = 0;
            model.withdraw = 0;
            result.bankAccountDefaultValue = {};
            if (model.method === 'transfer') {
              result.bankAccountDefaultValue = listBankAccount[0];
              model.bankAccount = listBankAccount[0].value;
            }
            if (e.value === 'balance') {
              result.defaultMethod = { value: 'cash', label: 'Tiền mặt' };
              model.method = null;
              model.bankAccount = null;
            }
          }
          break;
        }

        case 'method': {
          const isValid = _.find(this.state.methods, { value: e.value });
          if (isValid) {
            result.defaultMethod = e;
            result.description = e.value;
            model.method = e.value;
            model.bankAccount = null;
            result.bankAccountDefaultValue = {};
            if (model.method === 'transfer') {
              result.bankAccountDefaultValue = listBankAccount[0];
              model.bankAccount = listBankAccount[0].value;
            }
          }
          break;
        }

        default:
          model[key] = _.get(e, 'target.value', _.get(e, 'value', false));
          break;
      }
      result.model = model;
      this.setState(result);
    } catch (error) {
      // console.log(error);
    }
  }

  onChangePage(key) {
    const searching = Object.assign({}, this.state.searching);
    let pageNum = this.state.pageNum;
    switch (key) {
      case 'next': {
        //
        pageNum += 1;
        break;
      }
      default: {
        pageNum -= 1;
        if (pageNum <= 1) {
          pageNum = 1;
        }
        break;
      }
    }
    searching.paging = paging(pageNum, PAGE_SMALL_LIMIT);
    this.props.getHistory(searching);
    this.setState({ pageNum });
  }

  renderHistory() {
    const { walletHistory, orders } = this.props;
    const { listBankAccount } = this.state;
    const isStaffStatus = isStaff(this.props);
    if (_.isEmpty(walletHistory) === true) {
      return (<tr></tr>);
    }

    return (walletHistory.map((item, i) => {
      const twCash = new Decimal(item.balance.after.cash);
      const twCredit = new Decimal(item.balance.after.credit);
      const twCreditLimit = new Decimal(item.balance.after.creditLimit);
      const estAmount = twCreditLimit.sub(twCredit).add(twCash);
      const method = _.get(item, 'referData.method', 'system');
      const methodTitle = _.get(_.find(this.state.methods, { value: method }), 'label', 'Vnhub');
      const files = _.get(item, 'referData.files', false);
      const bankAccountId = _.get(item, 'referData.bankAccount', false);
      const bankAccount = bankAccountId ? _.find(listBankAccount, { value: bankAccountId }) : {};
      const fileNames = [];

      if (_.isEmpty(files) === false) {
        _.forEach(files, (file) => {
          const link = `${this.props.downloadPath}${file}`;
          const basename = path.basename(link);
          fileNames.push({
            name: basename,
            path: link
          });
        });
        // 
      }
      const description = _.clone(item.description);
      let descriptionArr = [];
      let order = {};
      let orderPath = '';
      if (isStaffStatus === true && _.isEmpty(description) === false && _.isEmpty(orders) === false) {
        const matchItem = item.description.match(/(.+?)#(.+?) /);
        if (_.isEmpty(matchItem) === false) {
          const orderId = Number(matchItem[2]);
          order = _.find(orders, { id: orderId });
          if (orderId > 0 && _.isEmpty(order) === false) {
            descriptionArr = description.split(`#${order.id} `);
            orderPath = `/${order.type === 'ARRIVAL' ? 'order-immigration-modify' : 'order-modify'}/${order.customer.id}/${order.id}`;
          }
        }
      }
      const no = (this.state.pageNum - 1) * PAGE_SMALL_LIMIT;
      return (<tr key={`walletHistory_${i}`}>
        <td>{no + i + 1}</td>
        <td>{moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
        <td>{methodTitle}</td>
        <td>
          {/* {_.isEmpty(descriptionArr) === false 
            ? <span>{descriptionArr[0]} <Link
              to={orderPath} > #{order.id}
            </Link> {descriptionArr[1]} </span> 
            : description} */}
          <div dangerouslySetInnerHTML={{ __html: item.description }} />
        </td>
        <td style={{ textAlign: 'right' }} className={item.change === '-' ? 'text-danger' : ''}>
          {item.change === '-' ? Number(item.amount).toLocaleString() : ''}
        </td>
        <td style={{ textAlign: 'right' }} className={item.change === '+' ? 'text-success' : ''}>
          {item.change === '+' ? Number(item.amount).toLocaleString() : ''}
        </td>
        <td>{_.get(bankAccount, 'label', '')}</td>
        <td style={{ textAlign: 'right' }} >{Number(estAmount).toLocaleString()}</td>
        <td>
          <span className="form-text text-muted">
            {fileNames.map((file, j) => {
              return (<a key={`${file.name}_${j}`} href={file.path} style={{ paddingRight: 20 }} >
                <i style={{ paddingRight: 5 }} className="icon-file-text2"></i> {file.name}
              </a>);
            })}
          </span>
        </td>

      </tr>);
    }));
  }

  setListBankAccount() {
    const { init } = this.props;
    const bankAccounts = _.get(init, 'bankAccount', []);
    const listBankAccount = [];

    if (_.isEmpty(bankAccounts) === false) {
      _.forEach(bankAccounts, (item) => {
        listBankAccount.push({
          value: item.key,
          label: item.text,
          color: item.color
        });
      });
      const bankAccountDefaultValue = listBankAccount[0];
      this.setState({ listBankAccount, bankAccountDefaultValue });
    }
  }

  render() {
    const { init } = this.props;
    const creditLimit = _.get(init, 'walletInfo.detail.creditLimit', 0);
    return (
      <div>

        <div className="row" style={{ marginBottom: 20 }}>

          <div className="col-lg-3">
            <div className="card bg-slate-400" >
              <div className="card-body" style={{
                height: 102,
                lineHeight: '76px',
                verticalAlign: 'middle',
                display: 'inline-block',
                width: '100%',
                paddingRight: 0
              }}>
                <FontAwesomeIcon style={{
                  marginRight: 5, marginTop: 10, fontSize: 35, float: 'left'
                }} icon={faHandHoldingUsd} />
                <span style={{ fontSize: 18, fontWeight: '400' }}>
                  Nạp tiền thanh toán dịch vụ
                </span>
              </div>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="card bg-blue-400">
              <div className="card-body" style={{
                height: 102,
                paddingRight: 0
              }}>
                <div className="d-flex" style={{ padding: '7px 0px' }}>
                  <span style={{ fontSize: 18, fontWeight: '400' }}>
                    {_.get(init, 'walletInfo.amount', 0).toLocaleString()} đ
                  </span>
                  <div style={{ position: 'absolute', top: 10, right: 10 }}>
                    <FontAwesomeIcon
                      style={{ fontSize: 16 }}
                      data-tip="Số Tiền được phép sử dụng bao gồm cả hạn mức"
                      icon={faQuestionCircle} />
                  </div>
                </div>
                <div>
                  <div className="font-size-lg opacity-75">
                    Số dư khả dụng
                  </div>
                </div>
              </div>
            </div>
          </div>

          {creditLimit > 0 ? <div className="col-lg-3">
            <div className="card bg-pink-400" style={{
              margin: 0,
              borderTopRightRadius: '.1875rem',
              borderTopLeftRadius: '.1875rem',
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0
            }}>
              <div className="card-body" style={{ padding: '3px 1.25rem', height: 50 }}>
                <div className="d-flex">
                  <span style={{ fontSize: 16, fontWeight: '400' }}>
                    {_.get(init, 'walletInfo.detail.credit', 0).toLocaleString()} đ
                  </span>

                  <div style={{ position: 'absolute', top: 5, right: 5 }}>
                    <FontAwesomeIcon
                      style={{ fontSize: 16 }}
                      data-tip="Công nợ chưa thanh toán"
                      icon={faQuestionCircle} />
                  </div>
                </div>
                <div>
                  <div className="font-size-lg opacity-75">
                    Công nợ chưa thanh toán
                  </div>
                </div>
              </div>
            </div>

            <div className="card bg-teal-400" style={{
              margin: 0,
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: '.1875rem',
              borderBottomRightRadius: '.1875rem'
            }}>
              <div className="card-body" style={{ padding: '3px 1.25rem', height: 50 }}>
                <div className="d-flex">
                  <span style={{ fontSize: 16, fontWeight: '400' }}>
                    {_.get(init, 'walletInfo.detail.creditLimit', 0).toLocaleString()} đ
                  </span>

                  <div style={{ position: 'absolute', top: 5, right: 5 }}>
                    <FontAwesomeIcon
                      style={{ fontSize: 16 }}
                      data-tip="Được phép sử dụng số tiền này do Vnhub cấp hạn mức"
                      icon={faQuestionCircle} />
                  </div>
                </div>
                <div>
                  <div className="font-size-lg opacity-75">
                    Hạn mức công nợ
                  </div>
                </div>
              </div>
            </div>

          </div> : ''}

        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header header-elements-inline">
                <h5 className="card-title">Lịch sử ví</h5>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Thời gian</th>
                        <th>Phương thức</th>
                        <th>Mô tả</th>
                        <th>Rút</th>
                        <th>Nạp</th>
                        <th>Tài khoản ngân hàng</th>
                        <th>Số dư</th>
                        <th>Nội dung đính kèm</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.renderHistory()}
                    </tbody>
                  </table>

                  <PagingContainer
                    count={_.get(this.props, 'walletHistory', []).length}
                    pageNum={this.state.pageNum}
                    limit={PAGE_SMALL_LIMIT}
                    onClick={this.onChangePage.bind(this)}
                  />

                </div>

              </div>
            </div>
          </div>
        </div>

        <NotificationContainer />
        <ReactTooltip />
      </div>

    );
  }

  componentWillMount() {
    this.setListBankAccount();
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;

    if (oldProps.wallet !== newProps.wallet) {
      const model = Object.assign({}, this.state.model);
      model.creditLimit = _.get(newProps, 'wallet.detail.creditLimit', 0);
      model.files = [];
      this.props.getHistory(this.state.searching);
      this.setState({ model, selectedFile: [], resetForm: true });
    }

    if (oldProps.socketBalance !== newProps.socketBalance) {
      this.callbackWallet();
    }
    if (oldProps.walletHistory !== newProps.walletHistory) {
      const { walletHistory } = this.props;
      const isStaffStatus = isStaff(this.props);
      if (isStaffStatus === true) {
        const orderIds = [];
        _.forEach(walletHistory, (item) => {
          if (_.isEmpty(item.description) === false) {
            const matchItem = item.description.match(/(.+?)#(.+?) /);
            if (_.isEmpty(matchItem) === false) {
              const orderId = Number(matchItem[2]);
              if (orderId > 0) {
                orderIds.push(orderId);
              }
            }
          }
        });
        if (_.isEmpty(orderIds) === false) {
          this.props.getOrders({
            filters: {
              id: orderIds
            }
          });
        }
      }
    }
  }

  componentWillUnMount() {
    this.props.dispatch({
      type: 'SOCKET_WALLET',
      payload: []
    });
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      resetState: payload.apiReducer.resetState,
      account: payload.accountReducer.account,
      changeWalletSuccess: payload.walletReducer.changeWalletSuccess,
      socketBalance: payload.walletReducer.socketBalance,
      wallet: payload.walletReducer.wallet,
      walletHistory: payload.walletReducer.walletHistory,
      userInfo: payload.generalReducer.userInfo,
      init: payload.generalReducer.init,
      listScope: payload.generalReducer.listScope,
      uploadPath: payload.generalReducer.uploadPath,
      downloadPath: payload.generalReducer.downloadPath,
      orders: payload.orderReducer.orders,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(SearchWalletLiability)
);
