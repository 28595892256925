
/**
 * CRUD
 */

export const SOCKET_WALLET = 'SOCKET_WALLET';
export const POST_SEARCH_WALLET = 'POST_SEARCH_WALLET';
export const POST_SEARCH_WALLET_SUCCESS = 'POST_SEARCH_WALLET_SUCCESS';
export const POST_SEARCH_WALLET_FAIL = 'POST_SEARCH_WALLET_FAIL';

export const POST_SEARCH_WALLET_DETAIL = 'POST_SEARCH_WALLET_DETAIL';
export const POST_SEARCH_WALLET_DETAIL_SUCCESS = 'POST_SEARCH_WALLET_DETAIL_SUCCESS';
export const POST_SEARCH_WALLET_DETAIL_FAIL = 'POST_SEARCH_WALLET_DETAIL_FAIL';

export const POST_WALLET_HISTORY = 'POST_WALLET_HISTORY';
export const POST_WALLET_HISTORY_SUCCESS = 'POST_WALLET_HISTORY_SUCCESS';
export const POST_WALLET_HISTORY_FAIL = 'POST_WALLET_HISTORY_FAIL';

export const POST_WALLET_HISTORY_CREDIT = 'POST_WALLET_HISTORY_CREDIT';
export const POST_WALLET_HISTORY_CREDIT_SUCCESS = 'POST_WALLET_HISTORY_CREDIT_SUCCESS';
export const POST_WALLET_HISTORY_CREDIT_FAIL = 'POST_WALLET_HISTORY_CREDIT_FAIL';

export const PUT_WALLET_CHANGE = 'PUT_WALLET_CHANGE';
export const PUT_WALLET_CHANGE_SUCCESS = 'PUT_WALLET_CHANGE_SUCCESS';
export const PUT_WALLET_CHANGE_FAIL = 'PUT_WALLET_CHANGE_FAIL';
