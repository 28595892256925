import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { Images } from '../../ui/assets/images';
import * as GeneralActions from '../../redux/general/general.actions';
import * as LinkActions from '../../redux/links/link.actions';
import { API_URL } from '../../configs/api.config';

class Header extends React.Component {
  constructor(props) {
    super(props);
    const { i18nState } = this.props;
    this.state = {
      title: 'PASSPORT',
      width: 200,
      language: 'vi'
      
    };
    this.handleLogout = this.handleLogout.bind(this);
    this.callback = this.callback.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    
    this.toggleMenuMobile = this.toggleMenuMobile.bind(this);
    this.toggleDropdownUser = this.toggleDropdownUser.bind(this);

    this.handleLinkInfo = this.handleLinkInfo.bind(this);
    this.handleUnLink = this.handleUnLink.bind(this);
    this.unlinkCallback = this.unlinkCallback.bind(this);
  }

  handleLinkInfo() {
    const { linkAct } = this.props;
    linkAct.GetLinkInfo({
      telegram: {}
    });
  }

  handleLogout() {
    const { generalAct, userInfo } = this.props;
    const param = {
      accessToken: userInfo.accessToken
    };
    generalAct.postUserLogout(param, this.callback);
  }

  handleUnLink() {
    confirmAlert({
      title: 'Xác nhận hủy kết nối telegram',
      buttons: [
        {
          label: 'Đồng ý',
          onClick: () => {
            const { linkAct } = this.props;
            linkAct.DeactiveLink({}, this.unlinkCallback);
          }
        },
        { label: 'Hủy' }
      ]
    });
  }

  callback(status, data) {
    if (status) {
      NotificationManager.info('Đăng xuất thành công', '', 1000);
      setTimeout(() => {
        this.props.history.push('/login');
      }, 1500);
    } else {
      NotificationManager.info(data.message, '', 1000);
    }
  }

  unlinkCallback(status) {
    if (status) {
      this.handleLinkInfo();
      NotificationManager.info('Hủy kết nối telegram thành công', '', 1000);
    }
  }

  toggleMenuMobile() {
    this.props.toggleMenuMobile();
  }
   
  toggleMenu() {
    this.props.toggleMenu();
  }

  toggleDropdownUser(e) {
    this.props.toggleDropdownUser();
  }

  render() {
    const { init, linkInfo } = this.props;
    const linkStatus = _.get(linkInfo, 'telegram.linked', null);
    
    const dropdownUserClass = ['nav-item', 'dropdown', 'dropdown-user'];
    const dropdownUserChildClass = ['dropdown-menu', 'dropdown-menu-right'];
    if (this.props.dropdownUser === true) {
      dropdownUserClass.push('show');
      dropdownUserChildClass.push('show');
    }
    return (
      <div className="navbar navbar-expand-md navbar-dark fixed-top">
        <div className="navbar-brand">
          <Link to="/dashboard" className="d-inline-block">
            <img src={Images.logo} alt="" />
          </Link>
        </div>
        <div className="d-md-none">
          <button onClick={this.toggleMenuMobile} className="navbar-toggler sidebar-mobile-main-toggle" type="button">
            <i className="icon-paragraph-justify3"></i>
          </button>
        </div>

        <div className="collapse navbar-collapse" id="navbar-mobile">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a onClick={this.toggleMenu} href="#" className="navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block">
                <i className="icon-paragraph-justify3"></i>
              </a>
            </li>
          </ul>
          <span className="badge bg-success ml-md-3 mr-md-auto">
            {_.get(init, 'accountInfo.position', '') ? init.accountInfo.position : 'Achau'}
          </span>
          
          <ul className="navbar-nav">
            <li className={dropdownUserClass.join(' ')}>
              <a onClick={this.toggleDropdownUser} href="#" className="navbar-nav-link d-flex align-items-center dropdown-toggle" data-toggle="dropdown">
                <img src={`${API_URL}/Account/Avatar/${_.get(init, 'accountInfo.phone', '-1')}?t=${_.random(true)}`} width="32" height="32" className="rounded-circle mr-2" height="34" alt="" />
                <span>{_.get(init, 'accountInfo.title', '')}</span>
              </a>
              <div className={dropdownUserChildClass.join(' ')}>
                
                <Link to="/profile" className="dropdown-item">
                  <i className="icon-user"></i> Thông tin cá nhân
                </Link>
                {linkStatus === true ? <a onClick={this.handleUnLink} className="dropdown-item">
                  <i className="fa fa-telegram"></i> Hủy kết nối telegram
                </a> : '' }

                <a onClick={this.handleLogout} className="dropdown-item">
                  <i className="icon-switch2"></i> Thoát
                </a>
              </div>
            </li>
          </ul>
        </div>
        <NotificationContainer />
      </div>

    );
  }

  componentDidMount() {
    
  }
}

function mapDispatchToProps(dispatch) {
  return {
    linkAct: bindActionCreators(LinkActions, dispatch),
    generalAct: bindActionCreators(GeneralActions, dispatch),
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default connect((payload) => {
  return {
    linkInfo: payload.linkReducer.linkInfo,
    init: payload.generalReducer.init,
    userInfo: payload.generalReducer.userInfo,
    lang: payload.i18nState.lang
  };
}, mapDispatchToProps)(Header);
