import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import SearchDepartment from './searchDepartment';
import store from '../../../configs/store.config';

import * as DepartmentActions from '../../../redux/departments/departments.actions';
import * as BranchActions from '../../../redux/branchs/branchs.actions';
import {
  localStoreFilter
} from '../../../helpers';

class SearchDepartmentContainer extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = {};
    this.getDepartments = this.getDepartments.bind(this);
    this.remove = this.remove.bind(this);
    this.callBackRemove = this.callBackRemove.bind(this);
  }
  
  remove(id) {
    try {
      const { departmentAct } = this.props;
      departmentAct.deleteDepartment({ 
        id 
      }, this.callBackRemove);
    } catch (error) {
      // console.log('error', error);
    }
  }

  callBackRemove(status, data) {
    if (status) {
      NotificationManager.info('Xóa phòng ban thành công', '', 1500);
      this.getDepartments();
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }

  getDepartments(args) {
    try {
      const { departmentAct } = this.props;

      const filter = Object.assign({}, args.filter);
      const query = Object.assign({}, args);
      Object.keys(filter).forEach((key) => {
        if (!filter[key]) {
          delete filter[key];
        }
      });
      query.filter = filter;
      departmentAct.listDepartment(query);
      store.dispatch({
        type: 'LOCAL_STORE_FILTER',
        payload: {
          objID: 'SearchDepartment',
          objType: 'getDepartments',
          query
        }
      });
    } catch (error) {
      console.log('error', error);
    }
  }

  getListBranch(filter) {
    try {
      const { branchAct } = this.props;
      branchAct.searchBranchList(filter);
    } catch (error) {
      // console.log('error', error);
    }
  }

  render() {
    const { listScope } = this.props;
    return (
      <div>
        <SearchDepartment
          listScope={listScope}
          errorMessage={this.state.errorMessage}
          userInfo={this.userInfo}
          getDepartments={this.getDepartments}
          remove={this.remove}
        />
        <NotificationContainer />
      </div>
        
    );
  }

  componentWillMount() {
    try {
      /**
       * 
       */
      const departmentFilter = localStoreFilter('SearchDepartment', 'getDepartments');
      this.getDepartments(departmentFilter || {
        filter: {
          type: 'department'
        }
      });
      this.getListBranch({
        filter: {
          type: 'branch'
        }
      });
    } catch (error) {
      /**
       * 
       */
    }
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    departmentAct: bindActionCreators(DepartmentActions, dispatch),
    branchAct: bindActionCreators(BranchActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    localStoreFilter: rootState.generalReducer.localStoreFilter,
    listScope: rootState.generalReducer.listScope,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(SearchDepartmentContainer);
