import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import CreateBranch from './createBranch';
import * as BranchActions from '../../../redux/branchs/branchs.actions';

class CreateBranchContainer extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = {
      listTag: [],
      groupScope: {}
    };
    this.groupScope = this.groupScope.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.callback = this.callback.bind(this);
  }

  onSubmit = async (args) => {
    try {
      this.setState({
        errorMessage: {}
      });
      const { branchAct } = this.props;
      branchAct.createBranch(args, this.callback);
    } catch (error) {
      // console.log('error', error);
    }
  }

  callback(status, data) {
    if (status) {
      NotificationManager.info('Tạo chi nhánh thành công,đang chuyển về trang quản lý', '', 1000);
      setTimeout(() => {
        this.props.history.push('/branch');
      }, 1500);
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }

  groupScope() {
    const { listScope } = this.props;
    const groupScope = {};
    const listTag = {};
    listScope.forEach((element) => {
      element.tag.forEach((tag) => {
        if (_.isEmpty(groupScope[tag]) === true) {
          groupScope[tag] = [];
          listTag[tag] = true;
        }
        groupScope[tag].push({
          code: element.code,
          title: element.description,
          checked: false
        });
      });
    });
    this.setState({
      listTag: Object.keys(listTag),
      groupScope
    });
  }

  componentWillMount() {
    this.groupScope();
  }

  render() {
    const { groupScope, listTag } = this.state;
    return (
      <div>
        <CreateBranch
          errorMessage={this.state.errorMessage}
          userInfo={this.userInfo}
          onSubmit={this.onSubmit}
          groupScope={this.groupScope}
          groupScope={groupScope}
          listTag={listTag}
        />
        <NotificationContainer />
      </div>
        
    );
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    branchAct: bindActionCreators(BranchActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(CreateBranchContainer);
