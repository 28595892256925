
export const LOCAL_STORE_FILTER = 'LOCAL_STORE_FILTER';

export const POST_PROFILE_SEARCH = 'POST_PROFILE_SEARCH';
export const POST_PROFILE_SEARCH_SUCCESS = 'POST_PROFILE_SEARCH_SUCCESS';
export const POST_PROFILE_SEARCH_FAIL = 'POST_PROFILE_SEARCH_FAIL';

export const POST_ACCOUNT_INFO = 'POST_ACCOUNT_INFO';
export const POST_ACCOUNT_INFO_SUCCESS = 'POST_ACCOUNT_INFO_SUCCESS';
export const POST_ACCOUNT_INFO_FAIL = 'POST_ACCOUNT_INFO_FAIL';

export const POST_ACCOUNT_CHANGE_PASS = 'POST_ACCOUNT_CHANGE_PASS';
export const POST_ACCOUNT_CHANGE_PASS_SUCCESS = 'POST_ACCOUNT_CHANGE_PASS_SUCCESS';
export const POST_ACCOUNT_CHANGE_PASS_FAIL = 'POST_ACCOUNT_CHANGE_PASS_FAIL';

export const POST_ACCOUNT_LOGIN = 'POST_ACCOUNT_LOGIN';
export const POST_ACCOUNT_LOGIN_SUCCESS = 'POST_ACCOUNT_LOGIN_SUCCESS';
export const POST_ACCOUNT_LOGIN_FAIL = 'POST_ACCOUNT_LOGIN_FAIL';

export const POST_ACCOUNT_LOGOUT = 'POST_ACCOUNT_LOGOUT';
export const POST_ACCOUNT_LOGOUT_SUCCESS = 'POST_ACCOUNT_LOGOUT_SUCCESS';
export const POST_ACCOUNT_LOGOUT_FAIL = 'POST_ACCOUNT_LOGOUT_FAIL';

export const POST_ACCOUNT_REGISTER = 'POST_ACCOUNT_REGISTER';
export const POST_ACCOUNT_REGISTER_SUCCESS = 'POST_ACCOUNT_REGISTER_SUCCESS';
export const POST_ACCOUNT_REGISTER_FAIL = 'POST_ACCOUNT_REGISTER_FAIL';

export const POST_ACCOUNT_UPDATE_INFO = 'POST_ACCOUNT_UPDATE_INFO';
export const POST_ACCOUNT_UPDATE_INFO_SUCCESS = 'POST_ACCOUNT_UPDATE_INFO_SUCCESS';
export const POST_ACCOUNT_UPDATE_INFO_FAIL = 'POST_ACCOUNT_UPDATE_INFO_FAIL';

export const POST_ACCOUNT_MENU = 'POST_ACCOUNT_MENU';
export const POST_ACCOUNT_MENU_SUCCESS = 'POST_ACCOUNT_MENU_SUCCESS';
export const POST_ACCOUNT_MENU_FAIL = 'POST_ACCOUNT_MENU_FAIL';
/**
 * 
 */
export const GET_SCOPE = 'GET_SCOPE';
export const GET_SCOPE_SUCCESS = 'GET_SCOPE_SUCCESS';
export const GET_SCOPE_FAIL = 'GET_SCOPE_FAIL';
/**
 * 
 */
export const GET_INIT = 'GET_INIT';
export const GET_INIT_SUCCESS = 'GET_INIT_SUCCESS';
export const GET_INIT_FAIL = 'GET_INIT_FAIL';
/**
 * 
 */
export const PUT_COUNTRY_ARRIVAL = 'PUT_COUNTRY_ARRIVAL';
export const PUT_COUNTRY_ARRIVAL_SUCCESS = 'PUT_COUNTRY_ARRIVAL_SUCCESS';
export const PUT_COUNTRY_ARRIVAL_FAIL = 'PUT_COUNTRY_ARRIVAL_FAIL';

/**
 * 
 */
export const LOGOUT = 'LOGOUT';
export const ERROR_401 = 'ERROR_401';
