import * as types from './supports.types';

/**
 * 
 * @param {*} payload 
 * @param {*} callback 
 */

export function CreateCustomerSupport(payload, callback) {
  return {
    type: types.POST_CUSTOMER_SUPPORT,
    payload,
    callback
  };
}

export function ListCustomerSupportByCustomerType(payload, callback) {
  return {
    type: types.POST_SEARCH_CUSTOMER_SUPPORT_BY_CUSTOMER_TYPE,
    payload,
    callback
  };
}

export function ListCustomerSupport(payload, callback) {
  return {
    type: types.POST_SEARCH_CUSTOMER_SUPPORT,
    payload,
    callback
  };
}

export function CustomerSupportDetail(payload, callback) {
  return {
    type: types.POST_SEARCH_CUSTOMER_SUPPORT_DETAIL,
    payload,
    callback
  };
}

export function modifyCustomerSupport(payload, callback) {
  return {
    type: types.PUT_CUSTOMER_SUPPORT,
    payload,
    callback
  };
}
export function deleteCustomerSupport(payload, callback) {
  return {
    type: types.DELETE_CUSTOMER_SUPPORT,
    payload,
    callback
  };
}
