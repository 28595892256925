import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import SearchCoboPobo from './searchCoboPobo';
import * as CoboPoboActions from '../../../redux/coboPobo/coboPobo.actions';
import * as AgenciesActions from '../../../redux/agencies/agencies.actions';
import { PAGE_LIMIT, CALLLOG_MANAGE_SCOPE, SYSTEM_COBO_POBO } from '../../../configs/constants';
import store from '../../../configs/store.config';
import {
  serviceDetail, serviceHandleDatas, checkScope,
  buildListLocation, buildListCustomerType, buildListCustomerSource, localStoreFilter
} from '../../../helpers';

class SearchCoboPoboContainer extends React.Component {
  constructor(prop) {
    super(prop);

    if (checkScope(SYSTEM_COBO_POBO, this.props) === false) {
      NotificationManager.error('Chức năng không tồn tại', '', 1500);
      setTimeout(() => {
        this.props.history.push('/dashboard');
      }, 2000);
    }

    this.state = {
      searching: {},
      orders: [],
      listService: [],
      listAccountState: [],
      listMembers: [],
      downloadUrl: ''
    };
    this.getCoboPobos = this.getCoboPobos.bind(this);
    this.getMembers = this.getMembers.bind(this);
    this.listMembers = this.listMembers.bind(this);
    this.exportFile = this.exportFile.bind(this);
    this.callback = this.callback.bind(this);
  }

  getMembers(args) {
    try {
      const { agenciesAct } = this.props;
      agenciesAct.searchAgency(args);
    } catch (error) {
      console.log('error', error);
    }
  }

  exportFile(args) {
    try {
      const { coboPoboAct } = this.props;

      const filters = Object.assign({}, args.filters);
      const query = Object.assign({}, args);
      this.setState({
        downloadUrl: ''
      });
      query.filters = filters;
      coboPoboAct.exportFile(query, this.callback);
    } catch (error) {
      console.log('error', error);
    }
  }

  callback(status, data) {
    if (status) {
      this.setState({
        downloadUrl: _.get(data, 'downloadUrl', '')
      });
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }

  getCoboPobos(args) {
    try {
      const { coboPoboAct } = this.props;

      let filters = Object.assign({}, args.filters);
      const query = Object.assign({}, args);

      _.forEach(filters, (item, key) => {
        if (_.isObject(item) === true) {
          _.forEach(item, (subItem, key2) => {
            if (_.isEmpty(subItem) === true) {
              if (_.isNumber(subItem) === false) {
                delete filters[key][key2];
              }
            }
          });
        } else if (_.isEmpty(item) === true) {
          if (_.isNumber(item) === false) {
            delete filters[key];
          }
        }

        if (_.isEmpty(item) === true) {
          if (_.isNumber(item) === false) {
            delete filters[key];
          }
        }
      });

      if (_.isEmpty(filters) === true) {
        filters = { createdById: 604 };
      }
      if (filters.createdById === 'all') {
        delete filters.createdById;
      }
      query.filters = filters;
      coboPoboAct.search(query);

      store.dispatch({
        type: 'LOCAL_STORE_FILTER',
        payload: {
          objID: 'searchCoboPobo',
          objType: 'getSearchCoboPobo',
          query
        }
      });
    } catch (error) {
      console.log('error', error);
    }
  }

  listMembers() {
    const { agencies: accounts } = this.props;
    const listMember = [{
      label: 'Tất cả',
      value: 'all'
    }];
    try {
      if (_.isEmpty(accounts) === false) {
        accounts.forEach((item) => {
          listMember.push({ value: item.id, label: item.title, scopes: item.scopes });
        });
      }
      return listMember;
    } catch (error) {
      return listMember;
    }
  }

  render() {
    // const {
 
    // } = this.state;

    return (
      <div>
        <SearchCoboPobo
          getCoboPobos={this.getCoboPobos}
          listMembers={this.listMembers}
          exportFile={this.exportFile}
          downloadUrl={this.state.downloadUrl}
        />
        <NotificationContainer />
      </div>
        
    );
  }

  componentWillMount() {
    try {
      /**
       * 
       */
      //
      const filter = {
        filters: {
          group: ['agency', 'collaborator', 'supplier', 'staff', 'customer']
        },
        select: ['id', 'title', 'scopes'],
        paging: { start: 0, limit: 1000 }
      };
  
      this.getMembers(filter);
      const callLogsFilter = localStoreFilter('searchCoboPobo', 'getSearchCoboPobo');
      this.getCoboPobos(callLogsFilter || {
        filters: {},
        paging: { start: 0, limit: PAGE_LIMIT }
      });
    } catch (error) {
      // console.log(error);
    }
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    agenciesAct: bindActionCreators(AgenciesActions, dispatch),
    coboPoboAct: bindActionCreators(CoboPoboActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    agencies: rootState.agencyReducer.agencies,
    localStoreFilter: rootState.generalReducer.localStoreFilter,
    init: rootState.generalReducer.init,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(SearchCoboPoboContainer);
