import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import { connect } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Images } from '../../ui/assets/images';
import * as GeneralActions from '../../redux/general/general.actions';
import { API_URL } from '../../configs/api.config';

const icon = {
  zone_agency: 'icon-library2',
  zone_sale: 'icon-comment-discussion',
  document_customer: 'icon-file-presentation2',
  org: 'icon-home4',
  general: 'icon-cog4'
};
class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: true,
      menuInfo: [],
      menus: [],
      menuRouteMaps: {
        dashboard: '/dashboard',
        liability: '/liability',
        order: '/order',
        'order-immigration-create': '/order-immigration-create',
        order_view_process: '/order-process',
        customer: '/customer',
        agency: '/agency',
        staff: '/member',
        department: '/department',
        branch: '/branch',
        service: '/service',
        permission: '/permission',
        paymentVoucher: '/payment-voucher',
        coboPobo: '/cobo-pobo',
        exportData: '/export-data/general',
        call_log: '/call-log',
        sale_cus: '/sale-cus',
        wallet_customer: '/wallet-customer',
        'arrival-visa-price': '/arrival-visa-price'
      }
    };

    const passiveMenus = ['order'];
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.formatMenu = this.formatMenu.bind(this);
    this.toggleMenuMobile = this.toggleMenuMobile.bind(this);
    this.passiveMenu = this.passiveMenu.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.callback = this.callback.bind(this);
    const pathname = this.props.location.pathname;
    const pathCode = (_.invert(this.state.menuRouteMaps))[pathname];
    this.passiveMenu(passiveMenus, 'passiveMenu');
    this.passiveMenu([pathCode], 'activeMenu');
  }

  passiveMenu(data, type) {
    const formatMenu = this.formatMenu();
    if (_.isEmpty(formatMenu) === false && _.isEmpty(data) === false) {
      formatMenu.forEach((menu) => {
        if (data.indexOf(menu.id) >= 0) {
          switch (type) {
            case 'passiveMenu':
              
              this.props.dispatch({
                type: 'REDUX_SET_ORDER_MENU_INFO',
                val: menu
              });
              
              break;

            case 'activeMenu':
              this.props.dispatch({
                type: 'REDUX_SET_ACTIVE_MENU',
                val: menu
              });
              break;
            default:
              break;
          }
        }

        if (menu.haveChild === true) {
          this.passiveSubMenu(menu.items, data, type);
        }
      });
    }
  }

  passiveSubMenu(menus, data, type) {
    const result = [];
    try {
      if (_.isEmpty(menus) === false) {
        menus.forEach((menu) => {
          if (data.indexOf(menu.id) >= 0) {
            switch (type) {
              case 'passiveMenu':
                this.props.dispatch({
                  type: 'REDUX_SET_ORDER_MENU_INFO',
                  val: menu
                });
                break;
              case 'activeMenu':
                this.props.dispatch({
                  type: 'REDUX_SET_ACTIVE_MENU',
                  val: menu
                });
                break;
              default:
                break;
            }
          }
          if (menu.haveChild === true) {
            this.passiveSubMenu(menu.items, data, type);
          }
        });
      }
    } catch (error) {
      // console.log(error);
    }
  }

  formatMenu() {
    const { menuInfo } = this.props;
    const result = [];
    try {
      const { menuRouteMaps } = this.state;
      if (_.isEmpty(menuInfo) === false) {
        menuInfo.forEach((menu) => {
          if (menu.hidden === false) {
            const parentIds = [menu.code];
            const data = {
              id: menu.code,
              text: menu.title,
              icon: 'icon-menu',
              haveChild: false,
              parentIds: false,
              items: []
            };

            if (_.isEmpty(menu.children) === false) {
              data.haveChild = true;
              data.items = this.formatSubMenu(menu.children, data, parentIds, menuRouteMaps);
            }

            result.push(data);
          }
        });
      }
    } catch (error) {
      return result;
    }
    return result;
  }

  formatSubMenu(menus, parent, parentIds, menuRouteMaps) {
    const result = [];
    try {
      if (_.isEmpty(menus) === false) {
        menus.forEach((menu) => {
          if (menu.hidden === false) {
            const subParentIds = _.clone(parentIds);
            subParentIds.push(menu.code);
            const data = {
              id: menu.code,
              text: menu.title,
              icon: menu.icon,
              haveChild: false,
              isActive: false,
              isOpen: false,
              parentIds,
              url: menuRouteMaps[menu.code] ? menuRouteMaps[menu.code] : '',
              items: []
            };
            if (_.isEmpty(menu.children) === false) {
              delete data.url;
              data.haveChild = true;
              data.items = this.formatSubMenu(menu.children, data, subParentIds, menuRouteMaps);
            }

            result.push(data);
          }
        });
      }
    } catch (error) {
      // console.log(error);
    }
    return result;
  }

  onSetSidebarOpen(e) {
    e.preventDefault();
    const navClassName = e.currentTarget.getAttribute('class').split(' ');
    const index = _.indexOf(navClassName, 'nav-item-open');
    if (index === -1) {
      navClassName.push('nav-item-open');
    } else {
      delete navClassName[index];
    }
  }

  toggleMenuMobile() {
    this.props.toggleMenuMobile();
  }

  toggleMenu() {
    this.props.toggleMenu();
  }

  subitem(e) {
    this.props.dispatch({
      type: 'REDUX_SET_ACTIVE_MENU',
      val: e
    });
  }

  HTMLMenu(element, type, subMenu) {
    const { activeMenu } = this.props;
    if (type === 'header') {
      // return (
      //   <li key={element.text} className="nav-item-header">
      //     <div className="text-uppercase font-size-xs line-height-xs"></div>
      //     <i className="icon-menu" title={element.text}></i>
      //   </li>
      // );
    }
    if (type === 'itemWithoutSub') {
      const className = ['nav-link'];
      if (_.isEmpty(activeMenu) === false && element.parentId !== false) {
        if (activeMenu.id === element.id) {
          className.push('active');
        }
      }

      return (
        <li key={element.text} className="nav-item">
          <Link onClick={this.subitem.bind(this, element)} to={element.url} aria-current="page" className={className.join(' ')} activeclassname="active">
            <i className={element.icon}></i><span>{element.text}</span>
          </Link>
        </li>
      );
    }
    if (type === 'itemWithSub') {
      const className = ['nav-item', 'nav-item-submenu'];
      const childClass = ['nav', 'nav-group-sub', 'Sidebar-show'];
      // let style = {
      //   display: 'none'
      // };

      if (_.isEmpty(activeMenu) === false && element.parentIds !== false) {
        if (_.indexOf(activeMenu.parentIds, element.id) >= 0 || element.id === activeMenu.id) {
          // style = {
          //   display: 'block'
          // };
          className.push('nav-item-open');
        }
      }

      return (
        <li key={element.text} className={className.join(' ')}>
          
          {/* onClick={this.subitem.bind(this, element)}  */}
          <span className="nav-link" >
            <i className={_.get(icon, element.id, 'icon-cog3')}></i>
            <span>{element.text}</span>
          </span>
          {/* style={style} */}
          <ul className={childClass.join(' ')}>
            {subMenu}
          </ul>

        </li>
      );
    }
    if (type === 'subitem') {
      const className = ['nav-link'];
      if (_.isEmpty(activeMenu) === false && element.parentId !== false) {
        if (element.id === activeMenu.id) {
          className.push('active');
        }
      }

      return (
        <li key={element.text} className="nav-item">
          <Link onClick={this.subitem.bind(this, element)} to={element.url} aria-current="page" className={className.join(' ')} activeclassname="active">
            {element.text}
          </Link>

        </li>);
    }
    return ('');
  }

  renderSubMenu(items, menuArr, type) {
    const menu = menuArr || [];
    items.forEach((item) => {
      if (item.haveChild === false && type === 'subMenu') {
        menu.push(this.HTMLMenu(item, 'itemWithoutSub'));
      }
      if (item.haveChild === true && type === 'subMenu') {
        const subMenus = item.items;
        const subMenuItems = [];
        subMenus.forEach((subMenu) => {
          subMenuItems.push(this.HTMLMenu(subMenu, 'subitem'));
        });
        menu.push(this.HTMLMenu(item, 'itemWithSub', subMenuItems));
      }
    });
    return (menu);
  }

  renderSideMenu() {
    const { menuInfo } = this.props;
    const menus = this.formatMenu(menuInfo);

    if (_.isEmpty(menus) === true) {
      return ('');
    }

    const menuArr = [];
    menus.forEach((items) => {
      menuArr.push(this.HTMLMenu(items, 'header'));
      if (_.isEmpty(items.items) === false) {
        const subMenus = this.renderSubMenu(items.items, [], 'subMenu');
        subMenus.forEach((subMenu) => {
          menuArr.push(subMenu);
        });
      }
    });
    return (menuArr);
  }

  handleLogout() {
    const { generalAct, userInfo } = this.props;
    const param = {
      accessToken: userInfo.accessToken
    };
    generalAct.postUserLogout(param, this.callback);
  }

  callback(status, data) {
    if (status) {
      NotificationManager.info('Đăng xuất thành công', '', 1000);
      setTimeout(() => {
        this.props.history.push('/login');
      }, 1500);
    } else {
      NotificationManager.info(data.message, '', 1000);
    }
  }

  render() {
    const { init } = this.props;
    
    return (
      <div className="sidebar sidebar-dark sidebar-main sidebar-expand-md fix_screen">
        <div className="menu_bar sidebar-mobile-toggler text-center">

          <a onClick={this.toggleMenuMobile} className="sidebar-mobile-main-toggle">
            <i className="icon-arrow-left8"></i>
          </a>Navigation
          <a href="#" className="sidebar-mobile-expand">
            {/* <i className="icon-screen-full"></i>
            <i className="icon-screen-normal"></i> */}
          </a>
        </div>

        <div className="sidebar-content menu_bar">
          <div className="card-body">
            <div className="media">
              <div className="mr-3">
                <Link activeclassname="active" to="/dashboard">
                  <img src={`${API_URL}/Account/Avatar/${_.get(init, 'accountInfo.phone', '-1')}`} width="38" height="38" className="rounded-circle" alt="" />
                </Link>
              </div>
              <div className="media-body">
                <div className="media-title font-weight-semibold">
                  {_.get(init, 'accountInfo.title', '')}
                </div>
                <div className="font-size-xs opacity-50">
                  {_.get(init, 'accountInfo.position', '')}
                </div>
              </div>
            </div>
          </div>
          <div className="card card-sidebar-mobile">
            <ul className="nav nav-sidebar" data-nav-type="accordion">
              {this.renderSideMenu()}
              <li className="nav-item nav-item-submenu">
                <span className="nav-link"><i className="icon-cog4"></i><span>Tài khoản</span></span>
                <ul className="nav nav-group-sub Sidebar-show">
                  <li className="nav-item"><a aria-current="page" className="nav-link" activeclassname="active" href="/profile">Thông tin tài khoản</a></li>
                  <li className="nav-item"><a aria-current="page" className="nav-link" activeclassname="active" href="#" onClick={this.handleLogout}>Thoát</a></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

    );
  }

  componentDidMount() {
    this.passiveMenu();
    this.formatMenu();
  }
}

function mapDispatchToProps(dispatch) {
  return {
    generalAct: bindActionCreators(GeneralActions, dispatch),
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      menuInfo: payload.generalReducer.menuInfo,
      init: payload.generalReducer.init,
      userInfo: payload.generalReducer.userInfo,
      activeMenu: payload.generalReducer.activeMenu,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(Sidebar)
);
