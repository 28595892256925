import * as types from './callLogs.types';

/**
 * 
 * @param {*} payload 
 * @param {*} callback 
 */

export function listPickupCallLog(payload, callback) {
  return {
    type: types.POST_SEARCH_PICKUP_CALL_LOG,
    payload,
    callback
  };
}

export function listCallLog(payload, callback) {
  return {
    type: types.POST_SEARCH_CALL_LOG,
    payload,
    callback
  };
}

export function callLogDetail(payload, callback) {
  return {
    type: types.POST_SEARCH_CALL_LOG_DETAIL,
    payload,
    callback
  };
}

export function modifyCallLog(payload, callback) {
  return {
    type: types.PUT_CALL_LOG,
    payload,
    callback
  };
}

export function clickToCall(payload, callback) {
  return {
    type: types.POST_CLICK_TO_CALL,
    payload,
    callback
  };
}
