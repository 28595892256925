import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ReceiptOrder from './receiptOrder';
import * as AgencyActions from '../../../redux/agencies/agencies.actions';
import * as OrderActions from '../../../redux/orders/orders.actions';
import * as ServiceActions from '../../../redux/services/services.actions';
import { PAGE_MAX_LIMIT } from '../../../configs/constants';
import {
  serviceDetail
} from '../../../helpers';

class ReceiptOrderContainer extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = {};
    this.getServices = this.getServices.bind(this);
    this.getMembers = this.getMembers.bind(this);
    this.getOrders = this.getOrders.bind(this);
    //
    this.getServiceDetail = this.getServiceDetail.bind(this);
  }

  getServices() {
    try {
      const { serviceAct } = this.props;
      serviceAct.listService({});
    } catch (error) {
      console.log(error);
    }
  }

  getOrders() {
    try {
      const { orderAct } = this.props;
      const id = this.props.computedMatch.params.id.split(',');
      orderAct.searchOrder({ 
        filters: { id },
        paging: { start: 0, limit: PAGE_MAX_LIMIT } 
      }, this.callback);
    } catch (error) {
      console.log(error);
    }
  }

  getMembers() {
    try {
      const { agencyAct } = this.props;
      const id = this.props.computedMatch.params.customerId;
      agencyAct.detailAgency({ filters: { id } }, this.callback);
    } catch (error) {
      console.log('error', error);
    }
  }

  callback(status, data) {
    try {
      if (status === false || _.isEmpty(data) === true) {
        NotificationManager.error('Thông tin không hợp lệ', '', 1000);
        setTimeout(() => {
          this.props.history.push('/order');
        }, 1500);
      } 
    } catch (error) {
      console.log(error);
    }
  }
  
  render() {
    return (
      <div>
        <ReceiptOrder
          params={this.props.computedMatch.params}
          getServiceDetail={this.getServiceDetail}
        />
        <NotificationContainer />
      </div>
        
    );
  }

  getServiceDetail(serviceIdentifyCode) {
    try {
      const { buildServices } = this.props;
      if (_.isEmpty(buildServices) === false) {
        return serviceDetail(buildServices, serviceIdentifyCode);
      }
      return {};
    } catch (error) {
      return {};
    }
  }

  componentWillMount() {
    try {
      this.getServices();
      this.getMembers();
      this.getOrders();
    } catch (error) {
      console.log(error);
    }
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    serviceAct: bindActionCreators(ServiceActions, dispatch),
    orderAct: bindActionCreators(OrderActions, dispatch),
    agencyAct: bindActionCreators(AgencyActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    buildServices: rootState.serviceReducer.buildServices,
    agency: rootState.agencyReducer.agency,
    init: rootState.generalReducer.init,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(ReceiptOrderContainer);
