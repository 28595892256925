import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ModifyBranch from './modifyBranch';
import * as BranchActions from '../../../redux/branchs/branchs.actions';

class ModifyBranchContainer extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = {
      branchInfo: {},
      listTag: [],
      groupScope: {}
    };
    this.getDetailBranch = this.getDetailBranch.bind(this);
    this.callbackGetDetailBranch = this.callbackGetDetailBranch.bind(this);
    this.groupScope = this.groupScope.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.callback = this.callback.bind(this);

    this.getDetailBranch();
  }

  onSubmit = async (args) => {
    try {
      const { branchAct } = this.props;
      const query = Object.assign({}, args);
      const payload = {
        id: query.id,
        data: query
      };
      delete payload.data.id;
      branchAct.modifyBranch(payload, this.callback);
    } catch (error) {
      console.log(error);
      // console.log('error', error);
    }
  }

  callback(status, data) {
    if (status) {
      NotificationManager.info('Cập nhật chi nhánh thành công,đang chuyển về trang quản lý', '', 1000);
      setTimeout(() => {
        this.props.history.push('/branch');
      }, 1500);
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }

  getDetailBranch() {
    try {
      const { branchAct } = this.props;
      const id = this.props.computedMatch.params.id;
      branchAct.detailBranch({
        filter: { id }
      }, this.callbackGetDetailBranch);
    } catch (error) {
      // console.log('error', error);
    }
  }

  callbackGetDetailBranch(status, data) {
    try {
      if (!status || !data) {
        NotificationManager.error('Thông tin không hợp lệ', '', 1500);
        setTimeout(() => {
          this.props.history.push('/branch');
        }, 1500);
      } 
    } catch (error) {
      // console.log(error);
    }
  }

  groupScope(branchInfo) {
    const { listScope } = this.props;
    const selectedScopes = branchInfo.scopes;
    const groupScope = {};
    const listTag = {};
    listScope.forEach((element) => {
      element.tag.forEach((tag) => {
        if (_.isEmpty(groupScope[tag]) === true) {
          groupScope[tag] = [];
          listTag[tag] = true;
        }
        groupScope[tag].push({
          code: element.code,
          title: element.description,
          checked: selectedScopes.indexOf(element.code) >= 0
        });
      });
    });
    this.setState({
      listTag: Object.keys(listTag),
      groupScope
    });
  }

  render() {
    const { groupScope, listTag, branchInfo } = this.state;
    return (
      <div>
        <ModifyBranch
          errorMessage={this.state.errorMessage}
          userInfo={this.userInfo}
          onSubmit={this.onSubmit}
          groupScopeFunc={this.groupScope}
          branchInfo={branchInfo}
          groupScope={groupScope}
          listTag={listTag}
        />
        <NotificationContainer />
      </div>
        
    );
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    branchAct: bindActionCreators(BranchActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(ModifyBranchContainer);
