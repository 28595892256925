import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import PaymentVoucher from './paymentVoucher';
import * as OrderActions from '../../../redux/orders/orders.actions';
import * as ServiceActions from '../../../redux/services/services.actions';
import * as AgenciesActions from '../../../redux/agencies/agencies.actions';
import * as AccountActions from '../../../redux/accounts/accounts.actions';
import store from '../../../configs/store.config';

import { PAGE_MAX_LIMIT, PAGE_LIMIT, SYSTEM_SERVICE_SCOPE } from '../../../configs/constants';
import {
  serviceDetail, serviceHandleDatas, localStoreFilter, checkScope
} from '../../../helpers';

class PaymentVoucherContainer extends React.Component {
  constructor(prop) {
    super(prop);

    if (checkScope(SYSTEM_SERVICE_SCOPE, this.props) === false) {
      NotificationManager.error('Chức năng không tồn tại', '', 1500);
      setTimeout(() => {
        this.props.history.push('/dashboard');
      }, 2000);
    }

    this.state = {
      searching: {},
      orders: [],
      listService: [],
      listAccountState: [],
      listMember: []
    };

    this.getOrders = this.getOrders.bind(this);
    //
    this.listServiceHandleData = this.listServiceHandleData.bind(this);
    this.getServiceDetail = this.getServiceDetail.bind(this);
    //
    this.getMembers = this.getMembers.bind(this);
    this.listMembers = this.listMembers.bind(this);
  }

  /**
   * 
   */
  getMembers(args) {
    try {
      const { accountAct } = this.props;
      accountAct.searchAccount(args);
    } catch (error) {
      // console.log('error', error);
    }
  }

  listMembers() {
    const { accounts } = this.props;
    const listMember = [{
      label: 'Chọn người tạo',
      value: 'all'
    }];
    try {
      if (_.isEmpty(accounts) === false) {
        accounts.forEach((item) => {
          listMember.push({ value: item.identifyCode, label: item.title, scopes: item.scopes });
        });
      }
      return listMember;
    } catch (error) {
      return listMember;
    }
  }

  /**
   * 
   */

  getOrders(args) {
    try {
      const { orderAct } = this.props;
      const filters = Object.assign({}, args.filters);
      const query = Object.assign({}, args);
      Object.keys(filters).forEach((key) => {
        if (_.isObject(filters[key]) === true) {
          Object.keys(filters[key]).forEach((key2) => {
            if (_.isEmpty(filters[key][key2]) === true) {
              delete filters[key][key2];
            }
          });
        } else if (_.isEmpty(filters[key]) === true) {
          delete filters[key];
        }
        if (_.isEmpty(filters[key]) === true) {
          delete filters[key];
        }
      });
      query.filters = filters;
      orderAct.searchOrder(query);

      store.dispatch({
        type: 'LOCAL_STORE_FILTER',
        payload: {
          objID: 'paymentVoucher',
          objType: 'getOrders',
          query
        }
      });
    } catch (error) {
      console.log('error', error);
    }
  }

  getServiceDetail(serviceIdentifyCode) {
    try {
      const { buildServices } = this.props;
      if (_.isEmpty(buildServices) === false) {
        return serviceDetail(buildServices, serviceIdentifyCode);
      }
      return {};
    } catch (error) {
      return {};
    }
  }

  listServiceHandleData() {
    try {
      const result = { list: [], setlectedItem: {} };
      const { buildServices } = this.props;

      if (_.isEmpty(buildServices) === false) {
        return serviceHandleDatas(buildServices);
      }
      return result;
    } catch (error) {
      return false;
    }
  }

  render() {
    return (
      <div>
        <PaymentVoucher
          listParentIdentify={this.state.listParentIdentify}
          errorMessage={this.state.errorMessage}
          listMembers={this.listMembers}
          getServiceDetail={this.getServiceDetail}
          getOrders={this.getOrders}
          onSubmit={this.onSubmit}
          listServiceHandleData={this.listServiceHandleData}
          userInfo={this.userInfo}
          minimize={this.props.minimize || false}
          listMember={this.state.listMember}
          listAgency={this.state.listAgency}
        />
      </div>
        
    );
  }

  componentWillMount() {
    try {
      const { serviceAct } = this.props;
      serviceAct.listService({});
      
      const { agenciesAct } = this.props;
      agenciesAct.searchAgency({
        filters: {
          group: ['customer', 'agency', 'collaborator', 'supplier']
        }
      });

      this.getMembers({
        filters: {
          group: ['staff']
        },
        paging: {
          start: 0,
          limit: PAGE_MAX_LIMIT
        }

      });

      const orderFilter = localStoreFilter('paymentVoucher', 'getOrders');
       
      this.getOrders(orderFilter || {
        filters: {
          profile: {
            fullName: ''
          },
          id: '',
          serviceIdentifyCode: ''
        },
        paging: {
          start: 0,
          limit: PAGE_LIMIT
        }
      
      });
    } catch (error) {
      console.log(error);
    }
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    //
    serviceAct: bindActionCreators(ServiceActions, dispatch),
    orderAct: bindActionCreators(OrderActions, dispatch),
    agenciesAct: bindActionCreators(AgenciesActions, dispatch),
    accountAct: bindActionCreators(AccountActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    accounts: rootState.accountReducer.accounts,
    agencies: rootState.agencyReducer.agencies,
    orderTags: rootState.orderReducer.orderTags,
    orders: rootState.orderReducer.orders,
    buildServices: rootState.serviceReducer.buildServices,
    localStoreFilter: rootState.generalReducer.localStoreFilter,
    init: rootState.generalReducer.init,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(PaymentVoucherContainer);
