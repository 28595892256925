import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import CreateService from './createService';
import * as ServiceActions from '../../../redux/services/services.actions';
import { SYSTEM_SERVICE_SCOPE } from '../../../configs/constants';

import {
  checkScope
} from '../../../helpers';

class CreateServiceContainer extends React.Component {
  constructor(prop) {
    super(prop);

    if (checkScope(SYSTEM_SERVICE_SCOPE, this.props) === false) {
      NotificationManager.error('Chức năng không tồn tại', '', 1500);
      setTimeout(() => {
        this.props.history.push('/dashboard');
      }, 2000);
    }

    this.state = {
      listService: [],
      listParentIdentify: []
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.callback = this.callback.bind(this);
    //
    this.buildDiscount = this.buildDiscount.bind(this);
    this.getServices = this.getServices.bind(this);
    this.callbackGetService = this.callbackGetService.bind(this);
    
    this.listServiceHandleData = this.listServiceHandleData.bind(this);
  }

  getServices() {
    try {
      const { serviceAct } = this.props;
      serviceAct.listService({}, this.callbackGetService);
    } catch (error) {
      // console.log('error', error);
    }
  }
  
  callbackGetService(status, data) {
    try {
      if (status) {
        this.listServiceHandleData(data);
      } 
    } catch (error) {
      // console.log(error);
    }
  }

  listServiceHandleData(listService, setlectedItem = false) {
    try {
      const result = [{
        label: 'Danh mục chính',
        value: 'root',
        checked: setlectedItem === 'root'
      }];
      
      if (_.isEmpty(listService) === false) {
        listService.forEach((item) => {
          if (item.type === 'list') {
            const node = {
              label: item.title,
              value: item.identifyCode,
              checked: setlectedItem === item.identifyCode
            };
            if (_.isEmpty(item.children) === false) {
              node.children = this.listServiceHandleChildren([], item.children, setlectedItem);
            }
            result.push(node);
          }
        });
      }
      this.setState({ listParentIdentify: result, listService });
      return result;
    } catch (error) {
      return false;
    }
  }

  listServiceHandleChildren(parent, data, setlectedItem) {
    try {
      const result = Object.assign([], parent);
      if (_.isEmpty(data) === false) {
        data.forEach((item) => {
          if (item.type === 'list') {
            const node = {
              label: item.title,
              value: item.identifyCode,
              checked: setlectedItem === item.identifyCode
            };
            if (_.isEmpty(item.children) === false) {
              node.children = this.listServiceHandleChildren(parent, item.children, setlectedItem);
            }
            result.push(node);
          }
        });
        
        return result;
      }
      return [];
    } catch (error) {
      return [];
    }
  }

  onSubmit = async (args) => {
    try {
      this.setState({
        errorMessage: {}
      });
      const { serviceAct } = this.props;
      serviceAct.createService(args, this.callback);
    } catch (error) {
      // console.log('error', error);
    }
  }

  callback(status, data) {
    if (status) {
      NotificationManager.info('Tạo dịch vụ thành công,đang chuyển về trang quản lý', '', 1000);
      setTimeout(() => {
        this.props.history.push('/service');
      }, 1500);
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }

  buildDiscount() {
    const { init } = this.props;
    const listDiscount = [];
    if (init.discount) {
      init.discount.forEach((element) => {
        listDiscount.push({ value: element.key, label: element.title });
      });
    }
    return listDiscount;
  }

  render() {
    const { listService, listParentIdentify } = this.state;
    return (
      <div>
        <CreateService
          listParentIdentify={listParentIdentify}
          listService={listService}
          listServiceHandleData={this.listServiceHandleData}
          onSubmit={this.onSubmit}
          userInfo={this.userInfo}
          buildDiscount={this.buildDiscount}
        />
        <NotificationContainer/>
      </div>
        
    );
  }

  componentDidMount() {
    try {
      /**
       * 
       */
      this.getServices();
      this.listServiceHandleData();
    } catch (error) {
      /**
       * 
       */
    }
  }

  componentDidUpdate() {
    try {
      /**
       * 
       */
      
    } catch (error) {
      /**
       * 
       */
    }
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    serviceAct: bindActionCreators(ServiceActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    init: rootState.generalReducer.init,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(CreateServiceContainer);
