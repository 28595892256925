import _ from 'lodash';
import * as types from './callLogs.types';

const initialState = {
  showCallLogModal: false,
  callLogListSockets: [],
  callLogSockets: [],
  callLogNotification: {},
  hangup: false,
  pickup: false,
  callLogs: [],
  pickupCallLogs: [],
  callLog: {},
  callLogCount: 0
};

export default function callLogs(state = initialState, action) {
  switch (action.type) {
    case types.POST_SEARCH_CALL_LOG_SUCCESS: {
      return {
        ...state,
        callLogs: action.payload
      };
    }

    case types.POST_SEARCH_CALL_LOG_DETAIL_SUCCESS: {
      return {
        ...state,
        callLog: action.payload
      };
    }

    case types.POST_SEARCH_PICKUP_CALL_LOG_SUCCESS: {
      return {
        ...state,
        pickupCallLogs: action.payload
      };
    }
    case types.PUT_CALL_LOG_SUCCESS: {
      return {
        ...state
      };
    }

    /**
     * 
     */

    case types.PUT_CALL_LOGS_MODAL: {
      return {
        ...state,
        showCallLogModal: action.payload
      };
    }

    case types.BUSY_CALL_LOG: {
      return {
        ...state
      };
    }

    case types.SET_HANGUP: {
      state.hangup = action.payload;
      return {
        ...state
      };
    }

    case types.SET_PICKUP: {
      state.pickup = action.payload;
      return {
        ...state
      };
    }

    case types.GET_SOCKET_CALL_LOG: {
      let callLogListSockets = [];
      const callLogsStr = localStorage.getItem('callLogs');
      if (_.isEmpty(callLogsStr) === false) {
        callLogListSockets = JSON.parse(callLogsStr);
      }

      state.callLogListSockets = callLogListSockets;
      return {
        ...state
      };
    }

    case types.REMOVE_SOCKET_NOTIFICATION: {
      const callLogNotification = {};
      state.callLogNotification = callLogNotification;
      return {
        ...state
      };
    }

    case types.REMOVE_SOCKET_CALL_LOG: {
      let callLogListSockets = [];

      const callLogsStr = localStorage.getItem('callLogs');
      if (_.isEmpty(callLogsStr) === false) {
        callLogListSockets = JSON.parse(callLogsStr);
      }
      const index = _.findIndex(callLogListSockets, { callLogId: action.payload });
      const result = _.remove(callLogListSockets, (v, k) => {
        return index !== k;
      });

      state.callLogListSockets = result;
      localStorage.setItem('callLogs', JSON.stringify(result));
      
      return {
        ...state
      };
    }
    case types.PUSH_SOCKET_CALL_LOG: {
      const callState = _.get(action, 'payload.state', false);
      // const direction = _.get(action, 'payload.direction', false);

      if (callState === 'ANSWERED') {
        let callLogListSockets = [];
        const callLogSockets = _.clone(state.callLogSockets);
      
        const callLogsStr = localStorage.getItem('callLogs');
        if (_.isEmpty(callLogsStr) === false) {
          callLogListSockets = JSON.parse(callLogsStr);
        }

        state.callLogSockets.unshift(action.payload);
        state.callLogListSockets.unshift(action.payload);
      
        localStorage.setItem('callLogs', JSON.stringify(state.callLogListSockets));
        state.hangup = true;
        state.pickup = action.payload;
      } 
      if (callState === 'HANGUP') {
        state.hangup = true;
      }
      if (callState === 'RINGING') {
        state.callLogNotification = action.payload;
      }
      
      return {
        ...state
      };
    }

    default:
      return state;
  }
}
