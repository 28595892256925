import * as types from './exportData.types';

/**
 * 
 * @param {*} payload 
 * @param {*} callback 
 */

export function exportData(payload, callback) {
  return {
    type: types.EXPORT_DATA,
    payload,
    callback
  };
}

export function exportDataSummary(payload, callback) {
  return {
    type: types.EXPORT_DATA_SUMMARY,
    payload,
    callback
  };
}

export function exportSimple(payload, callback) {

}
