import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import CreateMember from './createMember';

import * as AccountActions from '../../../redux/accounts/accounts.actions';
import * as DepartmentActions from '../../../redux/departments/departments.actions';
import * as BranchActions from '../../../redux/branchs/branchs.actions';

class CreateMemberContainer extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = {
      listMember: [],
      listDelegate: [],
      listDepartment: [],
      listBranch: [],
      listTag: [],
      groupScope: {},
      disabledDepartment: true
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.callback = this.callback.bind(this);
    //
    this.groupScope = this.groupScope.bind(this);
    //
    this.getDepartments = this.getDepartments.bind(this);
    this.callbackDepartment = this.callbackDepartment.bind(this);
    //
    this.getBranchs = this.getBranchs.bind(this);
    this.callbackBranch = this.callbackBranch.bind(this);
    //
    this.getDelegates = this.getDelegates.bind(this);
    this.renderDelegate = this.renderDelegate.bind(this);

    //
    this.getMembers = this.getMembers.bind(this);
    this.getMember = this.getMember.bind(this);
    // this.callbackMember = this.callbackMember.bind(this);
  }

  onSubmit = async (args) => {
    try {
      const { accountAct } = this.props;
      delete args.rePassword;

      accountAct.createAccount(args, this.callback);
    } catch (error) {
      // console.log('error', error);
    }
  }

  callback(status, data) {
    if (status) {
      NotificationManager.info('Tạo tài khoản nhân viên thành công,đang chuyển về trang quản lý', '', 1000);
      setTimeout(() => {
        this.props.history.push('/member');
      }, 1500);
    } else {
      NotificationManager.error(data.message, '', 1500);
    }
  }
  /**
   * 
   */

  getDepartments(branchId) {
    try {
      const { departmentAct } = this.props;
      departmentAct.listDepartment({
        filter: {
          type: 'department',
          parentIdentifyCode: branchId
        }
      }, this.callbackDepartment);
    } catch (error) {
      // console.log('error', error);
    }
  }

  callbackDepartment(status, data) {
    try {
      if (status) {
        this.setState({ listDepartment: data, disabledDepartment: false, departmentCount: data.length });
      } 
    } catch (error) {
      // console.log(error);
    }
  }

  /**
   * 
   */
  getBranchs() {
    try {
      const { branchAct, userInfo } = this.props;
      branchAct.searchBranch({
        filter: {
          type: 'branch'
        }
      }, this.callbackBranch);
    } catch (error) {
      // console.log('error', error);
    }
  }

  callbackBranch(status, data) {
    try {
      if (status) {
        this.setState({ listBranch: data, branchCount: data.length });
      } 
    } catch (error) {
      // console.log(error);
    }
  }

  /**
   * 
   */
  getDelegates(args) {
    try {
      const { accountAct } = this.props;
      accountAct.searchByKeywordAccount(args);
    } catch (error) {
      // console.log('error', error);
    }
  }

  renderDelegate(data) {
    const result = [];
    try {
      if (_.isEmpty(data) === false) {
        _.forEach(data, (item) => {
          result.push({ value: item.identifyCode, label: item.title });
        });
      }
      return result;
    } catch (error) {
      return result;
      // console.log('error', error);
    }
  }
  
  /**
   * 
   */

  getMembers(args) {
    try {
      const { accountAct } = this.props;
      accountAct.searchAccount(args, this.callbackMember);
    } catch (error) {
      // console.log('error', error);
    }
  }

  getMember() {
    try {
      const { accounts } = this.props;
    
      if (_.isEmpty(accounts) === false) {
        const listMember = [{
          label: 'Không',
          value: 'root'
        }];
        const listDelegate = [];
        let memberInfo = {};
        const id = this.props.computedMatch.params.id;
        accounts.forEach((item) => {
          if (item.id.toString() !== id) {
            listMember.push({ value: item.identifyCode, label: item.title, scopes: item.scopes });
            listDelegate.push({ value: item.identifyCode, label: item.title, scopes: item.scopes });
          } else {
            memberInfo = item;
          }
        });
        return { memberInfo, listMember, listDelegate };
      } 
      
      return {};
    } catch (error) {
      return {};
    }
  }

  groupScope() {
    const { listScope } = this.props;
    const groupScope = {};
    const listTag = {};
    listScope.forEach((element) => {
      element.tag.forEach((tag) => {
        if (_.isEmpty(groupScope[tag]) === true) {
          groupScope[tag] = [];
          listTag[tag] = true;
        }
        groupScope[tag].push({
          code: element.code,
          title: element.description,
          checked: false
        });
      });
    });
    this.setState({
      listTag: Object.keys(listTag),
      groupScope
    });
  }
  
  /**
   * 
   */

  render() {
    const {
      listDepartment, disabledDepartment, listBranch, groupScope, listTag 
    } = this.state;
    return (
      <div>
        <CreateMember
          disabledDepartment={disabledDepartment}
          getMember={this.getMember}
          listDepartment={listDepartment}
          listBranch={listBranch}
          groupScope={groupScope}
          listTag={listTag}
          errorMessage={this.state.errorMessage}
          renderDelegate={this.renderDelegate}
          getDepartments={this.getDepartments}
          onSubmit={this.onSubmit}
          userInfo={this.userInfo}
          
        />
        <NotificationContainer />
      </div>
        
    );
  }
  
  componentWillMount() {
    try {
      const filter = {
        filters: {
          group: ['staff']
        }
      };
      this.groupScope();
      this.getMembers(filter);
      this.getDelegates(filter);
      this.getBranchs();
    } catch (error) {
      // console.log(error);
    }
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    //
    accountAct: bindActionCreators(AccountActions, dispatch),
    departmentAct: bindActionCreators(DepartmentActions, dispatch),
    branchAct: bindActionCreators(BranchActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    init: rootState.generalReducer.init,
    accounts: rootState.accountReducer.accounts,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(CreateMemberContainer);
