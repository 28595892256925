import React from 'react';
import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Cleave from 'cleave.js/react';
import { DelayInput } from 'react-delay-input';
import moment from 'moment';
import Decimal from 'decimal.js';
import { mode } from 'crypto-js';
import { Tabs, Tab } from 'react-bootstrap';
import { Images } from '../../../ui/assets/images';
import PagingContainer from '../../ultils/paging.container';
import UploadFileContainer from '../../ultils/uploadFile.container';
import { PAGE_LIMIT } from '../../../configs/constants';
import SelectStateContainer from '../../ultils/selectState.container';
import {
  paging,
  fileUpload,
  isStaff
} from '../../../helpers';

const path = require('path');

class SearchWallet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNum: 1,
      selectedFile: [],
      listBankAccount: [],
      defaultMethod: { value: 'cash', label: 'Tiền mặt' },
      defaultType: null,
      methods: [
        { value: 'cash', label: 'Tiền mặt' },
        { value: 'transfer', label: 'Chuyển khoản' }
      ],
      amount: 0,
      type: 'deposit',
      bankAccountDefaultValue: {},
      model: {
        method: 'cash',
        bankAccount: '',
        deposit: 0,
        withdraw: 0,
        creditLimit: 0,
        description: 'THAY ĐỔI HẠN MỨC TÍN DỤNG',
        files: []
      },
      searching: {
        paging: {
          start: 0,
          limit: PAGE_LIMIT
        }
      }
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.setFiles = this.setFiles.bind(this);
    this.setListBankAccount = this.setListBankAccount.bind(this);
    this.callbackWallet = this.callbackWallet.bind(this);
    /**
     * 
     */
  }

  callbackWallet() {
    try {
      const { wallet, socketBalance } = this.props;
      if (_.isEmpty(socketBalance) === false) {
        if (wallet.accountId === socketBalance.accountId && wallet !== socketBalance) {
          this.props.dispatch({
            type: 'POST_SEARCH_WALLET_DETAIL_SUCCESS',
            payload: socketBalance
          });

          NotificationManager.warning('Vừa có thao tác thay đổi số dư ví', '', 1500);
        }
      }
    } catch (error) {
      // console.log(error);
    }
  }

  onSubmit = async (e) => {
    try {
      e.preventDefault();
      // SHOW LOADING
      this.props.dispatch({ type: 'SHOW_LOADING' });
      const validate = this.validateOnSubmit();

      if (validate === true) {
        const { type, amount } = this.state;
        const model = Object.assign({}, this.state.model);
        if (type !== 'balance') {
          model[type] = Number(amount);
        }
        const files = await fileUpload(this.state.selectedFile, this.props);
        if (files.code === 1) {
          model.files = files.data;
        }

        this.props.onSubmit(model);
      }
      // HIDE LOADING
      // this.props.dispatch({ type: 'HIDE_LOADING' });
    } catch (error) {
      this.props.dispatch({ type: 'HIDE_LOADING' });
      // console.log(error);
    }
  }

  validateOnSubmit() {
    const { model, amount, type } = this.state;
    const { wallet } = this.props;
    if (_.isNumber(model.creditLimit) === false) {
      NotificationManager.error('Hạn mức không hợp lệ', '', 1500);
      return false;
    }

    if (wallet.detail.creditLimit <= model.credit) {
      NotificationManager.error('Hạn mức ví phải lớn hơn tín dụng', '', 1500);
      return false;
    }

    if (_.isNumber(amount) === false) {
      NotificationManager.error('Giá trị ví không hợp lệ', '', 1500);
      return false;
    }
    if (!type) {
      NotificationManager.error('Loại không được để trống', '', 1500);
      return false;
    }

    const isValidType = _.find(this.props.types, { value: type });
    if (_.isEmpty(isValidType) === true) {
      NotificationManager.error('Hình thức không hợp lệ', '', 1500);
      return false;
    }
    return true;
  }

  setFiles(selectedFile) {
    try {
      this.setState({ selectedFile });
    } catch (error) {
      // console.log(error);
    }
  }

  onChangeHandle(key, e) {
    try {
      const { listBankAccount } = this.state;
      const model = Object.assign({}, this.state.model);
      const result = {};
      switch (key) {
        case 'creditLimit': {
          const value = _.get(e, 'target.rawValue', _.get(e, 'value', false));
          const number = Number(value);
          model.creditLimit = number >= 0 ? number : 0;
          break;
        }
        case 'amount': {
          const value = _.get(e, 'target.rawValue', _.get(e, 'value', false));
          const number = Number(value);
          result.amount = number >= 0 ? number : 0;
          break;
        }
        case 'bankAccount': {
          const value = _.get(e, 'target.value', _.get(e, 'value', false));
          result.bankAccountDefaultValue = e;
          model.bankAccount = value;
          break;
        }
        case 'type': {
          const isValid = _.find(this.props.types, { value: e.value });
          if (isValid) {
            if (e.value === 'balance') {
              model.description = 'THAY ĐỔI HẠN MỨC TÍN DỤNG';
            } else {
              model.description = '';
            }
            result.defaultType = e;
            result.type = e.value;
            result.amount = 0;
            model.deposit = 0;
            model.creditLimit = 0;
            model.withdraw = 0;
            result.bankAccountDefaultValue = {};
            if (model.method === 'transfer') {
              result.bankAccountDefaultValue = listBankAccount[0];
              model.bankAccount = listBankAccount[0].value;
            }
            if (e.value === 'balance') {
              result.defaultMethod = { value: 'cash', label: 'Tiền mặt' };
              model.method = null;
              model.bankAccount = null;
            }
          }
          break;
        }

        case 'method': {
          const isValid = _.find(this.state.methods, { value: e.value });
          if (isValid) {
            result.defaultMethod = e;
            result.description = e.value;
            model.method = e.value;
            model.bankAccount = null;
            result.bankAccountDefaultValue = {};
            if (model.method === 'transfer') {
              result.bankAccountDefaultValue = listBankAccount[0];
              model.bankAccount = listBankAccount[0].value;
            }
          }
          break;
        }

        default:
          model[key] = _.get(e, 'target.value', _.get(e, 'value', false));
          break;
      }
      result.model = model;
      this.setState(result);
    } catch (error) {
      // console.log(error);
    }
  }

  onChangePage(key) {
    const searching = Object.assign({}, this.state.searching);
    let pageNum = this.state.pageNum;
    switch (key) {
      case 'next': {
        //
        pageNum += 1;
        break;
      }
      default: {
        pageNum -= 1;
        if (pageNum <= 1) {
          pageNum = 1;
        }
        break;
      }
    }
    searching.paging = paging(pageNum, PAGE_LIMIT);
    this.props.getHistory(searching);
    this.setState({ pageNum });
  }

  renderHistory(source) {
    const { orders } = this.props;
    let { walletHistory } = this.props;
    const { listBankAccount } = this.state;
    const isStaffStatus = isStaff(this.props);

    if (source === 'credit') {
      walletHistory = this.props.walletHistoryCredit;
    }

    if (_.isEmpty(walletHistory) === true) {
      return (<tr></tr>);
    }

    return (walletHistory.map((item, i) => {
      const twCash = new Decimal(item.balance.after.cash);
      const twCredit = new Decimal(item.balance.after.credit);
      const twCreditLimit = new Decimal(item.balance.after.creditLimit);
      const estAmount = twCreditLimit.sub(twCredit).add(twCash);
      const method = _.get(item, 'referData.method', 'system');
      const methodTitle = _.get(_.find(this.state.methods, { value: method }), 'label', 'Hệ thống');
      const files = _.get(item, 'referData.files', false);
      const bankAccountId = _.get(item, 'referData.bankAccount', false);
      const bankAccount = bankAccountId ? _.find(listBankAccount, { value: bankAccountId }) : {};
      const fileNames = [];

      if (_.isEmpty(files) === false) {
        _.forEach(files, (file) => {
          const link = `${this.props.downloadPath}${file}`;
          const basename = path.basename(link);
          fileNames.push({
            name: basename,
            path: link
          });
        });
        // 
      }

      const description = _.clone(item.description);
      const descriptionArr = [];
      const order = {};
      const orderPath = '';

      // if (isStaffStatus === true && _.isEmpty(description) === false && _.isEmpty(orders) === false) {
      //   const matchItem = item.description.match(/(.+?)#(.+?) /);

      //   if (_.isEmpty(matchItem) === false) {
      //     const orderId = Number(matchItem[2]);
      //     order = _.find(orders, { id: orderId });
      //     if (orderId > 0 && _.isEmpty(order) === false) {
      //       descriptionArr = description.split(`#${order.id} `);
      //       orderPath = `/${order.type === 'ARRIVAL' ? 'order-immigration-modify' : 'order-modify'}/${order.customer.id}/${order.id}`;
      //     }
      //   }
      // }

      const no = (this.state.pageNum - 1) * PAGE_LIMIT;
      return (<tr key={`walletHistory_${i}`}>
        <td>{no + i + 1}</td>
        <td>{moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
        <td>{methodTitle.replace('Hệ thống', 'Ví')}</td>
        {/* <td>
          {_.isEmpty(descriptionArr) === false 
            ? <span>{descriptionArr[0]} <Link
              to={orderPath} > #{order.id}
            </Link> {descriptionArr[1]} </span> 
            : <div dangerouslySetInnerHTML={{ __html: description }} />}
        </td> */}
        <td>
          <div dangerouslySetInnerHTML={{ __html: item.description }} />
        </td>
        <td style={{ textAlign: 'right' }} className={item.change === '-' ? 'text-danger' : ''}>
          {item.change === '-' ? Number(item.amount).toLocaleString() : ''}
        </td>
        <td style={{ textAlign: 'right' }} className={item.change === '+' ? 'text-success' : ''}>
          {item.change === '+' ? Number(item.amount).toLocaleString() : ''}
        </td>
        <td>{_.get(bankAccount, 'label', '')}</td>
        <td style={{ textAlign: 'right' }} >{Number(estAmount).toLocaleString()}</td>
        <td>
          <span className="form-text text-muted">
            {fileNames.map((file, j) => {
              return (<a target="blank" key={`${file.name}_${j}`} href={file.path} style={{ paddingRight: 20 }} >
                <i style={{ paddingRight: 5 }} className="icon-file-text2"></i> {file.name}
              </a>);
            })}
          </span>
        </td>

      </tr>);
    }));
  }

  setListBankAccount() {
    const { init } = this.props;
    const bankAccounts = _.get(init, 'bankAccount', []);
    const listBankAccount = [];

    if (_.isEmpty(bankAccounts) === false) {
      _.forEach(bankAccounts, (item) => {
        listBankAccount.push({
          value: item.key,
          label: item.text,
          color: item.color
        });
      });
      const bankAccountDefaultValue = listBankAccount[0];
      this.setState({ listBankAccount, bankAccountDefaultValue });
    }
  }

  render() {
    const {
      model, defaultType, defaultMethod, amount, type, listBankAccount, bankAccountDefaultValue
    } = this.state;
    const { wallet, account } = this.props;

    const displayType = defaultType || this.props.types[0];
    return (
      <div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card border-left-3 border-left-danger rounded-left-0">
              <div className="card-body">
                <div className="media">
                  <div className="mr-3">
                    <a href="#">
                      <img src={Images.profile} className="rounded-circle" width="42" height="42" alt="" />
                    </a>
                  </div>

                  <div className="media-body">
                    <h6 className="mb-0">{_.get(account, 'title', '')}</h6>
                    <span className="text-muted">
                      <i className="fa fa-phone-square"></i> {_.get(account, 'phone', '')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-6">
            <div className="card" style={{ height: 'calc(100% - 20px)' }}>
              <div className="card-header header-elements-inline">
                <h5 className="card-title">Thông tin ví</h5>
                <div className="header-elements">
                </div>
              </div>
              <div className="card-body ">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td style={{ padding: '21px 20px', width: 200 }}>Số dư khả dụng</td>
                        <td style={{ textAlign: 'right' }}>{_.get(wallet, 'amount', 0).toLocaleString()} đ</td>
                      </tr>
                      <tr>
                        <td style={{ padding: '21px 20px' }}>Tiền mặt</td>
                        <td style={{ textAlign: 'right' }}>{_.get(wallet, 'detail.cash', 0).toLocaleString()} đ</td>
                      </tr>
                      <tr>
                        <td style={{ padding: '21px 20px' }}>Công nợ chưa thanh toán</td>
                        <td style={{ textAlign: 'right' }}>{_.get(wallet, 'detail.credit', 0).toLocaleString()} đ</td>
                      </tr>
                      <tr>
                        <td style={{ padding: 20 }}>Hạn mức công nợ</td>
                        <td style={{ textAlign: 'right' }}>{_.get(wallet, 'detail.creditLimit', 0).toLocaleString()} đ</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="card" >
              <div className="card-header header-elements-inline">
                <h5 className="card-title">Cập nhật ví</h5>
                <div className="header-elements">
                </div>
              </div>
              <div className="card-body" >
                <form onSubmit={this.onSubmit} >
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <td>Hình thức</td>
                          <td>
                            <Select
                              value={displayType}
                              onChange={this.onChangeHandle.bind(this, 'type')}
                              options={this.props.types}
                              placeholder="Chọn hình thức thay đổi"
                            /></td>
                        </tr>
                        <tr style={displayType.display[0] === false ? { display: 'none' } : {}}>
                          <td style={{ width: 200 }}>Hạn mức</td>
                          <td >
                            <Cleave
                              value={_.get(model, 'creditLimit', 0)}
                              onChange={this.onChangeHandle.bind(this, 'creditLimit')}
                              className="form-control input-numeral"
                              placeholder="Nhập hạn mức tín dụng"
                              options={{
                                numeral: true,
                                numeralThousandsGroupStyle: 'thousand',
                                numeralDecimalScale: 0
                              }}
                            />
                          </td>
                        </tr>
                        <tr style={displayType.display[1] === false ? { display: 'none' } : {}}>
                          <td>Phương thức thanh toán</td>
                          <td>
                            <Select
                              value={defaultMethod}
                              styles={{
                                menuPortal: (base) => {
                                  const { zIndex, ...rest } = base; // remove zIndex from base by destructuring
                                  return { ...rest, zIndex: 9999 };
                                }
                              }}
                              menuPortalTarget={document.body}
                              onChange={this.onChangeHandle.bind(this, 'method')}
                              options={this.state.methods}
                              placeholder="Lý do thay đổi ví"
                            /></td>
                        </tr>

                        <tr style={displayType.display[2] === false ? { display: 'none' } : {}}>

                          <td>
                            {type === 'deposit' ? 'Số tiền nạp' : 'Số tiền rút'}
                          </td>
                          <td>
                            <Cleave
                              value={amount}
                              onChange={this.onChangeHandle.bind(this, 'amount')}
                              className="form-control input-numeral"
                              placeholder="Nhập giá trị"
                              options={{
                                numeral: true,
                                numeralThousandsGroupStyle: 'thousand',
                                numeralDecimalScale: 0
                              }}
                            />
                          </td>
                        </tr>
                        <tr style={_.get(this.state.model, 'method', false) !== 'transfer' ? { display: 'none' } : {}}>
                          <td>

                            {type === 'deposit' ? 'Tài Khoản nhận tiền' : 'Tài khoản chuyển tiền'}
                          </td>
                          <td>
                            <SelectStateContainer
                              value={bankAccountDefaultValue}
                              onChange={this.onChangeHandle.bind(this, 'bankAccount')}
                              options={listBankAccount}
                              placeholder="Chọn hình thức thay đổi"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Mô tả</td>
                          <td>
                            <DelayInput
                              value={model.description}
                              type="text"
                              className="form-control"
                              delayTimeout={500}
                              placeholder="Nhập nội dung thanh toán"
                              onChange={this.onChangeHandle.bind(this, 'description')}
                            />
                          </td>

                        </tr>
                        <tr>
                          <td>Nội dung đính kèm</td>
                          <td>
                            <UploadFileContainer
                              setFiles={this.setFiles.bind(this)}
                              resetForm={this.state.resetForm}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div style={{ paddingTop: 120 }} className="float-left">
                      <button onClick={(e) => {
                        e.preventDefault();
                        // this.props.history.go(-1);
                        this.context.router.history.goBack();
                      }} className="btn btn-light">
                        <i className="fa fa-arrow-circle-left"></i> Trở về
                      </button>
                    </div>
                    <div style={{ paddingTop: 120 }} className="float-right">
                      <button className="btn btn-primary">Cập nhật</button>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              {/* <div className="card-header header-elements-inline">
                <h5 className="card-title">Sao kê ví</h5>
              </div> */}
              <div className="card-body">
                <Tabs defaultActiveKey="tab1">
                  <Tab eventKey="tab1" title="Lịch sử ví">
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Thời gian</th>
                            <th>Nguồn tiền</th>
                            <th>Mô tả</th>
                            <th>Rút</th>
                            <th>Nạp</th>
                            <th>Tài khoản ngân hàng</th>
                            <th>Số dư</th>
                            <th>Nội dung đính kèm</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.renderHistory()}
                        </tbody>
                      </table>

                      <PagingContainer
                        count={_.get(this.props, 'walletHistory', []).length}
                        pageNum={this.state.pageNum}
                        limit={PAGE_LIMIT}
                        onClick={this.onChangePage.bind(this)}
                      />

                    </div>
                  </Tab>
                  {_.get(wallet, 'detail.credit', 0) > 0
                    ? <Tab eventKey="tab2" title="Công nợ">
                      <div className="table-responsive">
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Thời gian</th>
                              <th>Nguồn tiền</th>
                              <th>Mô tả</th>
                              <th>Rút</th>
                              <th>Nạp</th>
                              <th>Tài khoản ngân hàng</th>
                              <th>Số dư</th>
                              <th>Nội dung đính kèm</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.renderHistory('credit')}
                          </tbody>
                        </table>
                      </div>
                    </Tab>
                    : ''}
                </Tabs>
              </div>
            </div>
          </div>
        </div>

        <NotificationContainer />
      </div>

    );
  }

  componentWillMount() {
    this.setListBankAccount();
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;

    if (oldProps.wallet !== newProps.wallet) {
      const model = _.clone(this.state.model);
      const { defaultType } = this.state;
      const { types } = this.props;
      const type = defaultType || types[0];
      model.creditLimit = _.get(newProps, 'wallet.detail.creditLimit', 0);
      model.files = [];
      this.props.getHistory(this.state.searching);
      this.props.getHistoryCredit({
        filters: {
          onlyCredit: true
        }
      });
      this.setState({
        model,
        defaultType: type,
        type: type.value,
        selectedFile: [],
        resetForm: true
      });
    }

    if (oldProps.socketBalance !== newProps.socketBalance) {
      this.callbackWallet();
    }

    if (oldProps.walletHistory !== newProps.walletHistory) {
      const { walletHistory } = this.props;
      const isStaffStatus = isStaff(this.props);
      if (isStaffStatus === true) {
        const orderIds = [];
        _.forEach(walletHistory, (item) => {
          if (_.isEmpty(item.description) === false) {
            const matchItem = item.description.match(/(.+?)#(.+?) /);
            if (_.isEmpty(matchItem) === false) {
              const orderId = Number(matchItem[2]);
              if (orderId > 0) {
                orderIds.push(orderId);
              }
            }
          }
        });
        if (_.isEmpty(orderIds) === false) {
          this.props.getOrders({
            filters: {
              id: orderIds
            }
          });
        }
      }
    }
  }

  // eslint-disable-next-line react/no-typos
  componentWillUnMount() {
    this.props.dispatch({
      type: 'SOCKET_WALLET',
      payload: []
    });
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      resetState: payload.apiReducer.resetState,
      account: payload.accountReducer.account,
      changeWalletSuccess: payload.walletReducer.changeWalletSuccess,
      socketBalance: payload.walletReducer.socketBalance,
      wallet: payload.walletReducer.wallet,
      walletHistory: payload.walletReducer.walletHistory,
      walletHistoryCredit: payload.walletReducer.walletHistoryCredit,
      userInfo: payload.generalReducer.userInfo,
      init: payload.generalReducer.init,
      listScope: payload.generalReducer.listScope,
      uploadPath: payload.generalReducer.uploadPath,
      downloadPath: payload.generalReducer.downloadPath,
      orders: payload.orderReducer.orders,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(SearchWallet)
);
