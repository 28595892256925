import { API_URL } from '../../configs/api.config';
import Request from '../middleware/request';

const exportDataAPI = {
  /**
   * 
   */
  postExport(args) {
    return Request.callAPI('post', `${API_URL}/ExportData`, { ...args });
  },

  postExportSummary(args) {
    return Request.callAPI('post', `${API_URL}/ExportData/Summary`, { ...args });
  }

};

export default exportDataAPI;

