import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import SearchDiscount from './searchDiscount';

class SearchDiscountContainer extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = {};
  }

  render() {
    return (
      <div>
        <SearchDiscount
          errorMessage={this.state.errorMessage}
          userInfo={this.userInfo}
        />
          
      </div>
        
    );
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    //
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    localStoreFilter: rootState.generalReducer.localStoreFilter,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(SearchDiscountContainer);
