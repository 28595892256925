import * as types from './utilities.types';

/**
 * 
 * @param {*} payload 
 * @param {*} callback 
 */

export function SendSMS(payload, callback) {
  return {
    type: types.POST_UTILITY_SEND_SMS,
    payload,
    callback
  };
}

export function SendMail(payload, callback) {
  return {
    type: types.POST_UTILITY_SEND_MAIL,
    payload,
    callback
  };
}
