
/**
 * CRUD
 */

export const COBO_POBO_CREATE = 'COBO_POBO_CREATE';
export const COBO_POBO_SEARCH = 'COBO_POBO_SEARCH';
export const COBO_POBO_DETAIL = 'COBO_POBO_DETAIL';
export const COBO_POBO_MODIFY = 'COBO_POBO_MODIFY';
export const COBO_POBO_EXPORT_FILE = 'COBO_POBO_EXPORT_FILE';

export const COBO_POBO_CREATE_SUCCESS = 'COBO_POBO_CREATE_SUCCESS';
export const COBO_POBO_SEARCH_SUCCESS = 'COBO_POBO_SEARCH_SUCCESS';
export const COBO_POBO_DETAIL_SUCCESS = 'COBO_POBO_DETAIL_SUCCESS';
export const COBO_POBO_MODIFY_SUCCESS = 'COBO_POBO_MODIFY_SUCCESS';
export const COBO_POBO_EXPORT_FILE_SUCCESS = 'COBO_POBO_EXPORT_FILE_SUCCESS';

export const COBO_POBO_CREATE_FAIL = 'COBO_POBO_CREATE_FAIL';
export const COBO_POBO_SEARCH_FAIL = 'COBO_POBO_SEARCH_FAIL';
export const COBO_POBO_DETAIL_FAIL = 'COBO_POBO_DETAIL_FAIL';
export const COBO_POBO_MODIFY_FAIL = 'COBO_POBO_MODIFY_FAIL';
export const COBO_POBO_EXPORT_FILE_FAIL = 'COBO_POBO_EXPORT_FILE_FAIL';
 
