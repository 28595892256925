import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sha256 } from 'js-sha256';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import Login from './login';
import * as GeneralActions from '../../../redux/general/general.actions';

class LoginContainer extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = {};
    this.onSubmit = this.onSubmit.bind(this);
    this.callback = this.callback.bind(this);
  }

  /**
   * 
   */
  onSubmit = async (args) => {
    try {
      const { generalAct } = this.props;
      generalAct.postUserLogin({
        account: args.account,
        password: args.password.length > 50 ? args.password : sha256(args.password)
      }, this.callback);
    } catch (error) {
      // console.log('error', error);
    }
  }

  callback(status, data) {
    if (status) {
      const { generalAct } = this.props;
      
      localStorage.setItem('accessToken', data.accessToken);
      generalAct.postAccountMenu({ accessToken: data.accessToken });
      generalAct.getInit({ accessToken: data.accessToken });
      /**
       * Call Logs
       */
      // const callLogs = localStorage.getItem('callLogs');
      // if (callLogs) {
      //   this.props.dispatch({
      //     type: 'PUSH_SOCKET_CALL_LOG',
      //     payload: callLogs
      //   });
      // }

      NotificationManager.info('Đăng nhập thành công', '', 1000);
      setTimeout(() => {
        this.props.history.push('/');
      }, 1500);
    } else {
      NotificationManager.error(data.message, '', 3000);
    }
  }

  /**
   * 
   */

  render() {
    return (
      <div>
        <Login
          errorMessage={this.state.errorMessage}
          userInfo={this.userInfo}
          onSubmit={this.onSubmit}
        />
        <NotificationContainer />
      </div>
    );
  }
  
  componentDidMount() {
    try {
      const { userInfo } = this.props;
      const accessToken = _.get(userInfo, 'accessToken', false);
      if (accessToken !== false) {
        this.props.history.push({
          pathname: '/'
        });
      }
    } catch (error) {
      /**
       * 
       */
    }
  }
}
function mapsDispatchToProp(dispatch) {
  return {
    generalAct: bindActionCreators(GeneralActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(LoginContainer);
