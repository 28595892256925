import * as types from './agencies.types';

export function searchByKeywordAgency(payload, callback) {
  return {
    type: types.POST_SEARCH_BY_KEYWORD_AGENCY,
    payload,
    callback
  };
}

export function searchAgency(payload, callback) {
  return {
    type: types.POST_SEARCH_AGENCY,
    payload,
    callback
  };
}

export function detailAgency(payload, callback) {
  return {
    type: types.POST_AGENCY_DETAIL,
    payload,
    callback
  };
}

export function createAgency(payload, callback) {
  return {
    type: types.POST_AGENCY,
    payload,
    callback
  };
}

export function modifyAgency(payload, callback) {
  return {
    type: types.PUT_AGENCY,
    payload,
    callback
  };
}
