import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ArrivalVisaPrice from './arrivalVisaPrice';
import * as GeneralActions from '../../../redux/general/general.actions';
import { SYSTEM_SERVICE_SCOPE } from '../../../configs/constants';
import {
  checkScope
} from '../../../helpers';

class CreateBranchContainer extends React.Component {
  constructor(prop) {
    super(prop);

    if (checkScope(SYSTEM_SERVICE_SCOPE, this.props) === false) {
      NotificationManager.error('Chức năng không tồn tại', '', 1500);
      setTimeout(() => {
        this.props.history.push('/dashboard');
      }, 2000);
    }

    this.state = {};
  }

  onSubmit = async (args) => {
    try {
      const { generalAct } = this.props;
      generalAct.putCountryArrival({ countryArrival: args }, (status, data) => {
        if (status) {
          NotificationManager.info('Cập nhật giá tiền công văn nhập cảnh thành công', '', 1000);
          setTimeout(() => {
            this.props.history.push('/arrival-visa-price');
          }, 1500);
        } else {
          NotificationManager.error(data.message, '', 1500);
        }
      });
    } catch (error) {
      console.log('error', error);
    }
  }

  render() {
    return (
      <div>
        <ArrivalVisaPrice
          onSubmit={this.onSubmit}
        />
        <NotificationContainer />
      </div>

    );
  }

  componentWillMount() {

  }
}

function mapsDispatchToProp(dispatch) {
  return {
    generalAct: bindActionCreators(GeneralActions, dispatch)
  };
}

export default connect((rootState) => {
  return {
    listScope: rootState.generalReducer.listScope,
    userInfo: rootState.generalReducer.userInfo,
    lang: rootState.i18nState.lang
  };
}, mapsDispatchToProp)(CreateBranchContainer);
