import * as types from './accounts.types';

export function searchByKeywordAccount(payload, callback) {
  return {
    type: types.POST_SEARCH_BY_KEYWORD_ACCOUNT,
    payload,
    callback
  };
}

export function searchMember(payload, callback) {
  return {
    type: types.POST_SEARCH_MEMBER,
    payload,
    callback
  };
}

export function searchAccount(payload, callback) {
  return {
    type: types.POST_SEARCH_ACCOUNT,
    payload,
    callback
  };
}

export function detailAccount(payload, callback) {
  return {
    type: types.POST_ACCOUNT_DETAIL,
    payload,
    callback
  };
}

export function createAccount(payload, callback) {
  return {
    type: types.POST_ACCOUNT,
    payload,
    callback
  };
}

export function modifyAccount(payload, callback) {
  return {
    type: types.PUT_ACCOUNT,
    payload,
    callback
  };
}

export function modifyAccountProfile(payload, callback) {
  return {
    type: types.PUT_ACCOUNT_PROFILE,
    payload,
    callback
  };
}
