import { API_URL } from '../../configs/api.config';
import Request from '../middleware/request';

const callLogAPI = {
  /**
   * 
   */
  postSearchPickupCallLog(args) {
    return Request.callAPI('post', `${API_URL}/CallLog/Search`, { ...args });
  },
  postSearchCallLog(args) {
    return Request.callAPI('post', `${API_URL}/CallLog/Search`, { ...args });
  },
  putChangeCallLog(args) {
    return Request.callAPI('put', `${API_URL}/CallLog/${args.id}`, { ...args.data });
  },
  postClickToCall(args) {
    return Request.callAPI('post', `${API_URL}/CallLog/ClickToCall`, { ...args });
  }
};

export default callLogAPI;

