import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import { Tabs, Tab } from 'react-bootstrap';
import Select from 'react-select';
import Cleave from 'cleave.js/react';
import { DISCOUNT_TYPE } from '../../../configs/constants';

class CreateService extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      model: {
        title: '',
        description: '',
        parentIdentifyCode: '',
        type: '',
        settings: {},
        document: [],
        discount: [],
        isActive: true
      },
      listParentIdentify: [],
      settings: [],
      documents: [],
      discounts: []
    };

    this.addItemHandle = this.addItemHandle.bind(this);
    this.addDocumentHandle = this.addDocumentHandle.bind(this);
    this.addDiscountHandle = this.addDiscountHandle.bind(this);
    //
    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.onTreeDropdownChange = this.onTreeDropdownChange.bind(this);
  }

  onSubmit = async (e) => {
    try {
      e.preventDefault();
      const validate = this.validateOnSubmit();
      if (validate === true) {
        const {
          model, settings, documents, discounts
        } = this.state;
        /**
         * 
         */
        model.settings = {};
        settings.forEach((setting) => {
          model.settings[setting.key] = setting.value;
        });
        /**
         * 
         */
        model.document = [];
        documents.forEach((document) => {
          model.document.push({
            key: document.key,
            value: document.value
          });
        });
        /**
         * 
         */
        
        model.discount = [];
        discounts.forEach((discount) => {
          model.discount.push({
            level: discount.level,
            type: discount.type,
            value: discount.type === 'custom' ? discount.value : Number(discount.value)
          });
        });
        this.props.onSubmit(model);
      }
    } catch (error) {
      /**
       * 
       */
    }
  }

  validateOnSubmit() {
    const {
      model, settings, documents, discounts
    } = this.state;
    if (_.isEmpty(model.title) === true) {
      NotificationManager.error('Tên dịch vụ không được để trống', '', 1500);
      return false;
    }
    if (_.isEmpty(model.description) === true) {
      NotificationManager.error('Mô tả không được để trống', '', 1500);
      return false;
    }
    if (_.isEmpty(model.type) === true) {
      NotificationManager.error('Loại không được để trống', '', 1500);
      return false;
    }
    if (_.isEmpty(settings) === false) {
      let isValidSetting = true;
      let isDuplicate = false;
      const exist = [];
      settings.forEach((setting, index) => {
        if (_.isEmpty(setting.key) === true || _.isEmpty(setting.value) === true) {
          isValidSetting = false;
        }
        const isExist = _.find(exist, { key: setting.key });
        if (_.isEmpty(isExist) === false) {
          isDuplicate = true;
        }
        exist.push(setting);
      });

      if (isDuplicate === true) {
        NotificationManager.error('Key trạng thái đã tồn tại', '', 1500);
        return false;
      }

      if (isValidSetting === false) {
        NotificationManager.error('Trạng thái dịch vụ không hợp lệ', '', 1500);
        return false;
      }
    }

    if (_.isEmpty(documents) === false) {
      let isValidDocument = true;
      let isDuplicate = false;
      const exist = [];
      documents.forEach((document, index) => {
        if (_.isEmpty(document.key) === true || _.isEmpty(document.value) === true) {
          isValidDocument = false;
        }
        const isExist = _.find(exist, { key: document.key });
        if (_.isEmpty(isExist) === false) {
          isDuplicate = true;
        }
        exist.push(document);
      });

      if (isDuplicate === true) {
        NotificationManager.error('Key tài liệu đã tồn tại', '', 1500);
        return false;
      }

      if (isValidDocument === false) {
        NotificationManager.error('Tài liệu không hợp lệ', '', 1500);
        return false;
      }
    }

    if (_.isEmpty(discounts) === false) {
      let isValidSetting = true;
      let isDuplicate = false;
      const exist = [];
      discounts.forEach((discount) => {
        if (discount.type === 'custom') {
          if (_.isEmpty(discount.level) === true
          || _.isEmpty(discount.type) === true
          || _.isEmpty(discount.value) === true) {
            isValidSetting = false;
          }
        } else if (_.isEmpty(discount.level) === true
          || _.isEmpty(discount.type) === true
          || _.isNumber(discount.value) === false) {
          isValidSetting = false;
        }
        
        const isExist = _.find(exist, { level: discount.level });
        if (_.isEmpty(isExist) === false) {
          isDuplicate = true;
        }
        exist.push(discount);
      });

      if (isDuplicate === true) {
        NotificationManager.error('Cấp chiết khấu đã tồn tại', '', 1500);
        return false;
      }

      if (isValidSetting === false) {
        NotificationManager.error('Thông tin chiết khấu không hợp lệ', '', 1500);
        return false;
      }
    }

    return true;
  }

  onTreeDropdownChange(currentNode, selectedNodes) {
    const { listService } = this.props;
    const model = Object.assign([], this.state.model);
    model.parentIdentifyCode = selectedNodes[0].value;
    this.setState({ model });
    this.props.listServiceHandleData(listService, selectedNodes[0].value);
  }

  onChangeHandle(key, e) {
    const model = Object.assign([], this.state.model);
    model[key] = e.target.value;
    this.setState({ model });
  }

  onItemChangeHandle(key, index, e) {
    const settings = Object.assign([], this.state.settings);
    settings[index][key] = e.target.value;
    this.setState({ settings });
  }

  addItemHandle() {
    const settings = Object.assign([], this.state.settings);
    settings.push({ key: '', value: '' });
    this.setState({ settings });
  }

  removeItemHandle(index) {
    if (_.isNumber(index) === true) {
      const settings = Object.assign([], this.state.settings);
      delete settings[index];
      this.setState({ settings });
    }
  }

  itemRender() {
    const { settings } = this.state;
    if (_.isEmpty(settings) === false) {
      return (settings.map((item, i) => {
        return (<tr key={i}>
          <td>
            <input
              value={item.key}
              onChange={this.onItemChangeHandle.bind(this, 'key', i)}
              type="text"
              className="form-control "
              placeholder="Key trạng thái =>" />
          </td>
          <td>
            <input
              value={item.value}
              onChange={this.onItemChangeHandle.bind(this, 'value', i)}
              type="text"
              className="form-control "
              placeholder="Tên trạng thái" />
          </td>
          <td>
            <a href="#" onClick={this.removeItemHandle.bind(this, i)} >
              <i className="icon-cross3 red" />
            </a>
          </td>
        </tr>);
      }));
    }
    return (false);
  }

  /**
   * 
   * @param {*} key 
   * @param {*} index 
   * @param {*} e 
   */
  onDocumentChangeHandle(key, index, e) {
    const documents = Object.assign([], this.state.documents);
    documents[index][key] = e.target.value;
    this.setState({ documents });
  }

  addDocumentHandle() {
    const documents = Object.assign([], this.state.documents);
    documents.push({ key: '', value: '' });
    this.setState({ documents });
  }

  removeDocumentHandle(index) {
    if (_.isNumber(index) === true) {
      const documents = Object.assign([], this.state.documents);
      delete documents[index];
      this.setState({ documents });
    }
  }

  /**
   * 
   */
  onDiscountChangeHandle(key, index, e) {
    try {
      const discounts = Object.assign([], this.state.discounts);
      let checkExistLevel = false;
      if (key === 'level') {
        checkExistLevel = !!_.find(discounts, { level: e.value });
      }

      if (checkExistLevel === false) {
        switch (key) {
          case 'level':
          case 'type':
            discounts[index][key] = e.value;
            break;
          case 'value': {
            discounts[index][key] = Number(_.get(e, 'target.rawValue', _.get(e, 'value', 0)));
            if (discounts[index].type === 'custom') {
              discounts[index][key] = e.target.value;
            }
            
            break;
          }
          default:
            discounts[index][key] = e.target.value;
            break;
        }
        this.setState({ discounts });
      } else {
        NotificationManager.error('Cấp chiết khấu đã tồn tại', '', 1500);
      }
    } catch (error) {
      // console.log(error);
    }
  }

  addDiscountHandle() {
    const discounts = Object.assign([], this.state.discounts);
    discounts.push({ level: '', type: '', value: '' });
    this.setState({ discounts });
  }

  removeDiscountHandle(index) {
    if (_.isNumber(index) === true) {
      const discounts = Object.assign([], this.state.discounts);
      delete discounts[index];
      this.setState({ discounts });
    }
  }

  /**
   * 
   */

  documentRender() {
    const { documents } = this.state;
    if (_.isEmpty(documents) === false) {
      return (documents.map((item, i) => {
        return (<tr key={`documentRender_${i}`}>
          <td>
            <input
              value={item.key}
              onChange={this.onDocumentChangeHandle.bind(this, 'key', i)}
              type="text"
              className="form-control "
              placeholder="Tài liệu nhập cảnh hàn quốc" />
          </td>
          <td>
            <input
              value={item.value}
              onChange={this.onDocumentChangeHandle.bind(this, 'value', i)}
              type="text"
              className="form-control "
              placeholder="http://google.com" />
          </td>
          <td>
            <a href="#" onClick={this.removeDocumentHandle.bind(this, i)} >
              <i className="icon-cross3 red" />
            </a>
          </td>
        </tr>);
      }));
    }
    return (false);
  }

  discountRender() {
    const { discounts } = this.state;
    if (_.isEmpty(discounts) === false) {
      return (discounts.map((item, i) => {
        return (<tr key={`discountRender_${i}`}>
          <td>
            <Select
              onChange={this.onDiscountChangeHandle.bind(this, 'level', i)}
              options={this.props.buildDiscount()}
            />
          </td>
          <td>
            <Select
              onChange={this.onDiscountChangeHandle.bind(this, 'type', i)}
              options={DISCOUNT_TYPE}
            />
          </td>
          <td>
            {item.type === 'custom' ? <input
              value={item.value}
              onChange={this.onDiscountChangeHandle.bind(this, 'value', i)}
              className="form-control"
              placeholder="Nhập giá trị giảm"
            /> : <Cleave
              value={item.value}
              onChange={this.onDiscountChangeHandle.bind(this, 'value', i)}
              className="form-control input-numeral"
              placeholder="Nhập giá trị giảm"
              options={{
                numeral: true,
                numeralThousandsGroupStyle: 'thousand',
                numeralDecimalScale: 0
              }}
            />}

          </td>
          <td>
            <a href="#" onClick={this.removeDiscountHandle.bind(this, i)} >
              <i className="icon-cross3 red" />
            </a>
          </td>
        </tr>);
      }));
    }
    return (false);
  }

  renderBasicTab() {
    const { model } = this.state;
    const { listParentIdentify } = this.props;
    return (<form
      onSubmit={this.onSubmit}
    >
      <div className="form-group">
        <div className="row">
          <div className="col-md-4">
            <label>Tên dịch vụ</label>
            <input
              value={model.title}
              onChange={this.onChangeHandle.bind(this, 'title')}
              className="form-control"
              placeholder="Visa hàn quốc"
            />
          </div>
          <div className="col-md-4">
            <label>Mô tả</label>
            <input
              value={model.description}
              onChange={this.onChangeHandle.bind(this, 'description')}
              type="text"
              placeholder="Kinh doanh về visa"
              className="form-control"
            />
          </div>
          <div className="col-md-4">
            <label>Thuộc nhóm dịch vụ</label>
            <DropdownTreeSelect
              onChange={this.onTreeDropdownChange}
              data={listParentIdentify}
              simpleSelect
              mode="radioSelect"
              keepTreeOnSearch={true}
            />

          </div>
        </div>
      </div>
      <div className="form-group">
        <div className="row">
          <div className="col-md-4 ">
            <label>Loại</label>
            <select
              value={model.type}
              onChange={this.onChangeHandle.bind(this, 'type')}
              className="form-control form-control-select2 controller_set"
            >
              <option value="">-- Chọn loại --</option>
              <option value="list">Danh sách</option>
              <option value="item">Dịch vụ</option>
            </select>
          </div>
          <div className="col-md-8">
            <div className="form-group">
              <label className="d-block font-weight-semibold">Trạng thái dịch vụ</label>
              <table className="table_service table table-bordered">
                <tbody>
                  <tr className="bg-gray-light tr_add">
                    <th>
                      <button
                        type="button"
                        onClick={this.addItemHandle}
                        className="addItem" style={{ marginRight: 10 }}>+
                      </button>Thêm Trạng thái
                    </th>
                    <th>Tên Trạng thái </th>
                    <th width="30"></th>
                  </tr>
                  {this.itemRender()}

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="text-right">
        <button className="btn btn-primary">Lưu lại</button>
      </div>
    </form>);
  }

  renderDocumentTab() {
    return (<form
      onSubmit={this.onSubmit}
    >
      <div className="form-group">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label className="d-block font-weight-semibold">Tài liệu</label>
              <table className="table_service table table-bordered">
                <tbody>
                  <tr className="bg-gray-light tr_add">
                    <th>
                      <button
                        type="button"
                        onClick={this.addDocumentHandle}
                        className="addDocument" style={{ marginRight: 10 }}>+
                      </button>Thêm tài liệu
                    </th>
                    <th>Giá trị </th>
                    <th width="30"></th>
                  </tr>
                  {this.documentRender()}

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="text-right">
        <button className="btn btn-primary">Lưu lại</button>
      </div>
    </form>);
  }

  renderDiscountTab() {
    return (<form
      onSubmit={this.onSubmit}
    >
      <div className="form-group">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label className="d-block font-weight-semibold">Chiết khấu</label>
              <table className="table_service table table-bordered">
                <tbody>
                  <tr className="bg-gray-light tr_add">
                    <th>
                      <button
                        type="button"
                        onClick={this.addDiscountHandle}
                        className="addDocument" style={{ marginRight: 10 }}>+
                      </button>Thêm chiết khấu
                    </th>
                    <th>Loại </th>
                    <th>Giá trị giảm </th>
                    <th width="30"></th>
                  </tr>
                  {this.discountRender()}

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="text-right">
        <button className="btn btn-primary">Lưu lại</button>
      </div>
    </form>);
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header header-elements-inline">
                <h5 className="card-title">Thêm Dịch vụ</h5>
                <div className="header-elements"></div>
              </div>

              <div className="card-body">
                <Tabs defaultActiveKey="tab1" id="uncontrolled-tab-example">
                  <Tab eventKey="tab1" title="Cơ bản">
                    {this.renderBasicTab()}
                  </Tab>
                  <Tab eventKey="tab2" title="Tài liệu">
                    {this.renderDocumentTab()}
                  </Tab>
                  <Tab eventKey="tab3" title="Chiết khấu">
                    {this.renderDiscountTab()}
                  </Tab>
                </Tabs>

              </div>
            </div>
          </div>
        </div>

        <NotificationContainer />
      </div>

    );
  }

  componentDidMount() {
    try {
      /**
       * 
       */

    } catch (error) {
      /**
       * 
       */
    }
  }

  componentWillUnmount() {
    try {
      /**
       * 
       */
    } catch (error) {
      /**
       * 
       */
    }
  }

  componentDidUpdate(prevProps, prevState) {
    try {
      /**
       * 
       */
    } catch (error) {
      /**
       * 
       */
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      userInfo: payload.generalReducer.userInfo,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(CreateService)
);
