import _ from 'lodash';

import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import CreateCallLogContainer from '../../components/callLog/create/createCallLog.container';

class PageHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (<div>
      <CreateCallLogContainer />
    </div>);
  }

  componentDidMount() {
    // this.onClick();
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(PageHeader)
);
