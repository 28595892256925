import _ from 'lodash';

import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tabs, Tab } from 'react-bootstrap';
import Select from 'react-select';
import Select2 from 'react-select2-wrapper';
import { confirmAlert } from 'react-confirm-alert'; // Import
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactAudioPlayer from 'react-audio-player';
import moment from 'moment';
import InputMask from 'react-input-mask';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Images } from '../../../ui/assets/images';
import SelectStateContainer from '../../ultils/selectState.container';
import { PAGE_MAX_LIMIT } from '../../../configs/constants';

import 'moment-timezone';

import {
  formatDateTimeChatBox, formatDate2, 
  buildListCustomerDenyType, formatHourMinute,
  formatDateFilter, formatDateRange
} from '../../../helpers';

import store from '../../../configs/store.config';

const initCallLog = {
  callerName: '',
  callerRecordedUrl: '',
  information: {
    source: '',
    type: '',
    subType: '',
    isBussiness: false,
    service: '',
    area: '',
    note: ''
  }

};
class CreateCallLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      defaultPrioritySchedule: { value: 1, label: 'Cao' },
      defaultTypeSchedule: { value: 1, label: 'Call' },
      defaultRemindSchedule: { value: 1, label: '15 phút' },

      sourceDefaultValue: false,
      typeDefaultValue: false,
      denyTypeDefaultValue: false,
      isModify: true,
      focus: false,
      focusHistory: 0,
      showModal: false,
      showDenyOption: false,
      callerPhone: '',
      callerName: '',
      callerRecordedUrl: '',
      listCustomerDenyType: [],

      appointmentDateRangeTitle: 'Thời gian gọi',
      appointmentRanges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      },

      appointmentModel: {

      },
      scheduleModel: {
        
      },

      model: {
        callLogId: '',
        callerName: '',
        callerRecordedUrl: '',
        information: {
          source: '',
          type: '',
          subType: '',
          isBussiness: false,
          service: '',
          area: '',
          note: ''
        }
      },

      searchingOrder: {
        filters: {
          customer: {
            phone: ''
          }
        },
        paging: {
          start: 0,
          limit: 11
        }
      },

      searching: {
        filters: {
          callerPhone: ''
        },
        paging: {
          start: 0,
          limit: PAGE_MAX_LIMIT
        }
      },
      contries: [],
      parentIdentifies: []
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitSchedule = this.onSubmitSchedule.bind(this);
    this.renderOrder = this.renderOrder.bind(this);
    this.renderHistory = this.renderHistory.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.callLogsNotification = this.callLogsNotification.bind(this);
    this.callLogHandle = this.callLogHandle.bind(this);
    this.onAppointmentSubmit = this.onAppointmentSubmit.bind(this);
    this.handleAppointmentDatePicker = this.handleAppointmentDatePicker.bind(this);
  }

  handleAppointmentDatePicker(e, picker) {
    const appointmentDateRangeTitle = formatDateFilter(picker.startDate, picker.endDate);
    const date = formatDateRange(picker.startDate, picker.endDate);
    this.setState({ appointmentDateRangeTitle });
  }

  clickToCall(phone) {
    const { init } = this.props;
    const extension = _.get(init, 'accountInfo.voip.extension', false);

    this.props.clickToCall({
      extension,
      phone
    });
  }

  removeCallLog(index, isConfirm = true) {
    if (isConfirm === false) {
      if (index === this.state.focus) {
        this.handleCloseModal();
      }
      store.dispatch({
        type: 'REMOVE_SOCKET_CALL_LOG',
        payload: index
      });
    } else {
      confirmAlert({
        title: 'Xác nhận đóng lịch sử gọi',
        buttons: [
          {
            label: 'Đồng ý',
            onClick: () => {
              if (index === this.state.focus) {
                this.handleCloseModal();
              }
              store.dispatch({
                type: 'REMOVE_SOCKET_CALL_LOG',
                payload: index
              });
            }
          },
          { label: 'Hủy' }
        ]
      });
    }
  }

  inComingCall = (content, init) => toast(() => {
    return (<div>
      <div style={{ float: 'left' }}>
        <img src={Images.incoming} style={{ marginRight: 20, paddingLeft: 10, float: 'left' }} />
      </div>
      <div style={{ float: 'left' }}>
        <p className="text-green-800">{content.callerName || 'Khách hàng'} đang gọi bạn ...</p>
        <p >{content.callerPhone}</p>
      </div>
    </div>);
  });

  outGoingCall = (content, init) => toast(() => {
    return (<div>
      <div style={{ float: 'left' }}>
        <img src={Images.outgoing} style={{ marginRight: 20, paddingLeft: 10, float: 'left' }} />
      </div>
      <div style={{ float: 'left' }}>
        <p className="text-blue-800">Cuộc gọi đi</p>
        <p >{content.callerName || content.callerPhone}</p>
      </div>
    </div>);
  });

  callLogsNotification() {
    const { callLogNotification, init } = this.props;
    if (_.isEmpty(callLogNotification) === false) {
      switch (callLogNotification.direction) {
        case 'INCOMING':
          this.inComingCall(callLogNotification, init);
          break;

        default:
          this.outGoingCall(callLogNotification, init);
          break;
      }
      //
      setTimeout(() => {
        store.dispatch({
          type: 'REMOVE_SOCKET_NOTIFICATION'
        });
      }, 15000);
    }
  }

  onAppointmentSubmit = (e) => {
    e.preventDefault();
  }

  onSubmit = (closeDialog = false, e) => {
    try {
      e.preventDefault();
      // SHOW LOADING
      this.props.dispatch({ type: 'SHOW_LOADING' });
      const validate = this.validateOnSubmit();

      if (validate === true) {
        const listCustomerType = this.props.buildCustomerType();
        const listCustomerSource = this.props.buildCustomerSource();
        const model = Object.assign({}, this.state.model);
        if (_.isEmpty(model.information.source) === true) {
          model.information.source = listCustomerSource[0].value;
        }
        if (_.isEmpty(model.information.type) === true) {
          model.information.type = listCustomerType[0].value;
        }
        if (_.isEmpty(model.information.subType) === false) {
          model.information.type = `${model.information.type}-${model.information.subType}`;
        }
        this.props.onSubmit(model);
        if (closeDialog === true) {
          this.removeCallLog(this.state.focus, false);
        }
      }
      // HIDE LOADING
      this.props.dispatch({ type: 'HIDE_LOADING' });
    } catch (error) {
      this.props.dispatch({ type: 'HIDE_LOADING' });
    }
  }

  onSubmitSchedule = (closeDialog = false, e) => {
    try {
      e.preventDefault();
      // SHOW LOADING
      this.props.dispatch({ type: 'SHOW_LOADING' });
      const validate = this.validateScheduleOnSubmit();

      if (validate === true) {
        const model = Object.assign({}, this.state.scheduleModel);
        
        NotificationManager.error('Feature under development', '', 1500);
        // this.props.onSubmitSchedule(model);
      }
      // HIDE LOADING
      this.props.dispatch({ type: 'HIDE_LOADING' });
    } catch (error) {
      this.props.dispatch({ type: 'HIDE_LOADING' });
    }
  }

  validateOnSubmit() {
    const model = Object.assign({}, this.state.model);
    return true;
  }

  validateScheduleOnSubmit() {
    const model = Object.assign({}, this.state.scheduleModel);
    if (_.isEmpty(model.description) === true) {
      NotificationManager.error('Nội dung cuộc hẹn không được để trống', '', 1500);
      return false;
    }
    if (_.isEmpty(model.date) === true) {
      NotificationManager.error('Ngày hẹn không được để trống', '', 1500);
      return false;
    }
    if (_.isEmpty(model.hour) === true) {
      NotificationManager.error('Giờ hẹn không được để trống', '', 1500);
      return false;
    }
    if (_.isNumber(model.remind) === false) {
      NotificationManager.error('Nhắc nhở không được để trống', '', 1500);
      return false;
    }
    if (_.isNumber(model.priority) === false) {
      NotificationManager.error('Mức độ ưu tiên không được để trống', '', 1500);
      return false;
    }
    if (_.isNumber(model.type) === false) {
      NotificationManager.error('Loại lịch hẹn không được để trống', '', 1500);
      return false;
    }
    return true;
  }

  onChangeHandle(key, e) {
    try {
      const model = Object.assign({}, this.state.model);
      const result = {};
      switch (key) {
        case 'isBussiness': {
          model.information.isBussiness = _.get(e, 'target.checked', false);
          break;
        }
        case 'type': {
          const listCustomerType = this.props.buildCustomerType();
          const value = _.get(e, 'target.value', _.get(e, 'value', false));
          const type = _.find(listCustomerType, { value });
          result.showDenyOption = false;
          result.typeDefaultValue = e;
          model.information.type = value;
          model.information.subType = '';

          if (_.isEmpty(type.children) === false) {
            result.showDenyOption = true;

            result.listCustomerDenyType = buildListCustomerDenyType(type.children);
            result.denyTypeDefaultValue = result.listCustomerDenyType[0];
            model.information.subType = result.listCustomerDenyType[0].value;
          }
          break;
        }
        case 'subType': {
          model.information.subType = _.get(e, 'target.value', _.get(e, 'value', false));
          result.denyTypeDefaultValue = e;

          break;
        }

        case 'source': {
          model.information.source = _.get(e, 'target.value', _.get(e, 'value', false));
          result.sourceDefaultValue = e;

          break;
        }

        case 'callerName': {
          model.callerName = _.get(e, 'target.value', _.get(e, 'value', false));
          break;
        }
        default: {
          model.information[key] = _.get(e, 'target.value', _.get(e, 'value', false));
          break;
        }
      }
      result.model = model;
      this.setState(result);
    } catch (error) {
      // console.log(error);
    }
  }

  onChangeScheduleHandle(key, e) {
    try {
      const model = Object.assign({}, this.state.scheduleModel);
      const result = {};
      switch (key) {
        case 'date': {
          const value = _.get(e, 'target.value', _.get(e, 'value', false));
          const dateFormat = 'YYYY/MM/DD';
          const splittedDate = value.split('/');
          if (splittedDate.length === 3) {
            const date = moment(`${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`, dateFormat);
            result.birthdayDisplay = value;
            if (date.isValid() === true) {
              model[key] = date.format();
            }
          }
          break;
        }
        case 'hour': {
          model.hour = _.get(e, 'target.value', _.get(e, 'value', false));
          break;
        }
        case 'type': {
          model.type = _.get(e, 'target.value', _.get(e, 'value', false));
          break;
        }
        case 'priority': {
          model.priority = _.get(e, 'target.value', _.get(e, 'value', false));
          break;
        }
        case 'remind': {
          model.remind = _.get(e, 'target.value', _.get(e, 'value', false));
          break;
        }
        case 'description': {
          model.description = _.get(e, 'target.value', _.get(e, 'value', false));
          break;
        }
        case 'members': {
          const { accountSearchByKeyword } = this.props;
          const members = [];
          e.forEach((element) => {
            members.push(element.value);
          });
          model[key] = members;
          break;
        }
        default: {
          model[key] = _.get(e, 'target.value', _.get(e, 'value', false));
          break;
        }
      }
      result.scheduleModel = model;
      this.setState(result);
    } catch (error) {
      // console.log(error);
    }
  }
  
  renderActiveHistory() {
    const { pickupCallLogs } = this.props;
    const { model } = this.state;
    if (_.isEmpty(pickupCallLogs) === true) {
      return (<span></span>);
    }
    const index = _.findIndex(pickupCallLogs, { id: model.callLogId });
    const item = pickupCallLogs[index];

    if (_.isEmpty(item) === true) {
      return (<span></span>);
    }
    if (item.type === 'INCOMING') {
      return (<li >

        <div style={{ display: 'block' }} className="listen">
          <div className="menu-icon ion-ios-telephone bg-green">
            <i style={{ fontSize: 23, paddingTop: 8 }} className="fa fa-phone"></i>
          </div>
          <span className="text-green-400">
          Gặp <b>{_.get(item, 'account.title', '')}</b>
            <p style={{ fontSize: 11 }}>{formatDateTimeChatBox(item.createdAt)}</p>
          </span>
        </div>
      </li>);
    }

    if (item.type === 'OUTGOING') {
      return (<li >
        <div style={{ display: 'block' }} className="listen">
          <div className="menu-icon ion-ios-telephone bg-blue">
            <i style={{ fontSize: 23, paddingTop: 8 }} className="fa fa-phone"></i>
          </div>
          <span className="text-blue-400">
            <b>{item.account.title}</b>  Gọi đi
            <p style={{ fontSize: 11 }}>{formatDateTimeChatBox(item.createdAt)}</p>
          </span>
        </div>
      </li>);
    }

    if (item.type === 'MISS') {
      return (<li >
        <div style={{ display: 'block' }} className="listen">
          <div className="menu-icon ion-ios-telephone bg-red">
            <i style={{ fontSize: 23, paddingTop: 8 }} className="fa fa-phone"></i>
          </div>
          <span className="text-red-400">
            <b>Gọi nhỡ</b>
            <p style={{ fontSize: 11 }}>{formatDateTimeChatBox(item.createdAt)}</p>
          </span>
        </div>
      </li>);
    }
    
    return (<li ></li>);
  }

  renderHistory() {
    const { pickupCallLogs } = this.props;
    const { model } = this.state;
    if (_.isEmpty(pickupCallLogs) === true) {
      return (<span></span>);
    }
    const style = {
      cursor: 'pointer'
    };
    const styleBorder = {
      cursor: 'pointer',
      borderRight: '2px solid crimson'
    };

    return (pickupCallLogs.map((item, i) => {
      if (item.type === 'INCOMING') {
        return (<li style={item.id === model.callLogId ? styleBorder : style} key={`renderHistory_${i}`}
          onClick={this.handleOpenViewModal.bind(this, item, i)}
        >

          <div style={{ display: 'block' }} className="listen">
            <div className="menu-icon ion-ios-telephone bg-green">
              <i style={{ fontSize: 23, paddingTop: 8 }} className="fa fa-phone"></i>
            </div>
            <span className="text-green-400">
              Gặp <b>{_.get(item, 'account.title', '')}</b>
              <p style={{ fontSize: 11 }}>{formatDateTimeChatBox(item.createdAt)} ({formatHourMinute(item.createdAt)})</p>
            </span>
          </div>
        </li>);
      }
      
      if (item.type === 'OUTGOING') {
        return (<li style={item.id === model.callLogId ? styleBorder : style} key={`renderHistory_${i}`}
          onClick={this.handleOpenViewModal.bind(this, item, i)}
        >
          <div style={{ display: 'block' }} className="listen">
            <div className="menu-icon ion-ios-telephone bg-blue">
              <i style={{ fontSize: 23, paddingTop: 8 }} className="fa fa-phone"></i>
            </div>
            <span className="text-blue-400">
              <b>{item.account.title}</b>  Gọi đi
              <p style={{ fontSize: 11 }}>{formatDateTimeChatBox(item.createdAt)} ({formatHourMinute(item.createdAt)})</p>
            </span>
          </div>
        </li>);
      }
      if (item.type === 'MISS') {
        return (<li style={item.id === model.callLogId ? styleBorder : style} key={`renderHistory_${i}`}
          onClick={this.handleOpenViewModal.bind(this, item, i)}
        >
          <div style={{ display: 'block' }} className="listen">
            <div className="menu-icon ion-ios-telephone bg-red">
              <i style={{ fontSize: 23, paddingTop: 8 }} className="fa fa-phone"></i>
            </div>
            <span className="text-red-400">
              <b>Gọi nhỡ</b>
              <p style={{ fontSize: 11 }}>{formatDateTimeChatBox(item.createdAt)} ({formatHourMinute(item.createdAt)})</p>
            </span>
          </div>
        </li>);
      }
      return (<li style={i === 0 ? styleBorder : style} key={`renderHistory_${i}`}></li>);
    }));
  }

  renderOrder() {
    try {
      const { simpleOrders } = this.props;
      if (_.isEmpty(simpleOrders) === true) {
        return (<tr></tr>);
      }
      const data = simpleOrders.slice(0, 10);

      return (data.map((item, i) => {
        const service = this.props.getServiceDetail(item.serviceIdentifyCode);
        const depositAmount = _.sumBy(item.deposit, 'amount');
        let depositText = `Paid : ${depositAmount.toLocaleString()}`;
        if (depositAmount === item.amount) {
          depositText = 'Full Paid';
        }
        const defaultState = _.find(service.stateList, { value: item.state });
        const color = _.get(defaultState, 'color', '#ccc');

        return (<tr key={`renderSimpleOrder_${i}`}>
          <td>
            <div className="media">
              <div className="media-body align-self-center">
                #{item.id} - {_.get(service, 'title', '')}
                <div className="text-muted font-size-sm">
                  <span style={{
                    color,
                    backgroundColor: color
                  }} className="badge badge-mark mr-1"></span>
                  {_.get(defaultState, 'label', '')}
                </div>

              </div>
            </div>
          </td>
          <td style={{ textAlign: 'right' }}>
            <span style={{ fontWeight: 'bold' }}>
              {item.amount.toLocaleString()}</span>
            <br />
            <div className="text-muted font-size-sm">
              {depositText}
            </div>

          </td>
        </tr>);
      }));
    } catch (error) {
      // console.log(error);
      return (<span></span>);
    }
  }

  renderSupport() {
    const {
      parentIdentifies, callerPhone, callerName,
      model, typeDefaultValue,
      sourceDefaultValue, isModify,
      showDenyOption, denyTypeDefaultValue, listCustomerDenyType
    } = this.state;
    
    const listCustomerType = this.props.buildCustomerType();
    const listCustomerSource = this.props.buildCustomerSource();
    const callerRecordedUrl = _.get(model, 'callerRecordedUrl', '');
    // if (formatDate2(new Date()) !== formatDate2(model.createdAt) && callerRecordedUrl) {
    //   callerRecordedUrl = callerRecordedUrl.replace('.wav', '.ogg');
    // }
    
    return (<form>
      <div className="form-group">
        <div className="row">
          <div className="col-md-6">
            <div className="row" >
              <div className="col-md-5" >KH là doanh nghiệp</div>
              <div className="col-md-7">
                <input
                  disabled={!isModify}
                  style={{ height: '2.25003rem' }}
                  value={model.information.isBussiness}
                  onChange={this.onChangeHandle.bind(this, 'isBussiness')}
                  checked={model.information.isBussiness}
                  type="checkbox"

                  required="" />
              </div>

            </div>
          </div>
          <div className="col-md-6">
            
          </div>

        </div>
      </div>
      <div className="form-group">
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-5">Số điện thoại</div>
              <div className="col-md-7">
                <input
                  value={callerPhone}
                  disabled
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="row">
              <div className="col-md-5">Tên khách hàng</div>
              <div className="col-md-7">
                <input
                  disabled={!isModify}
                  value={model.callerName || callerName}
                  onChange={this.onChangeHandle.bind(this, 'callerName')}
                  placeholder="Nhập tên khách hàng"
                  type="text" className="form-control" required="" />
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="form-group">
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-5">Nguồn khách hàng</div>
              <div className="col-md-7">
                <Select
                  isDisabled={!isModify}
                  value={sourceDefaultValue || listCustomerSource[0]}
                  onChange={this.onChangeHandle.bind(this, 'source')}
                  options={listCustomerSource || []}
                />
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="row">
              <div className="col-md-5">Phân loại khách hàng</div>
              <div className="col-md-7">
                <SelectStateContainer
                  isDisabled={!isModify}
                  value={typeDefaultValue || listCustomerType[0]}
                  onChange={this.onChangeHandle.bind(this, 'type')}
                  options={listCustomerType || []}
                />
              </div>
            </div>
          </div>

        </div>

      </div>

      <div className="form-group">
        <div className="row">

          <div className="col-md-6">
            <div className="row">
              <div className="col-md-5">Email</div>
              <div className="col-md-7">
                <input
                  disabled={!isModify}
                  value={model.information.email}
                  onChange={this.onChangeHandle.bind(this, 'email')}
                  placeholder="Nhập email khách hàng"
                  type="text" className="form-control" required="" />
              </div>
            </div>
          </div>

          <div className="col-md-6"
            style={showDenyOption ? { display: 'block' } : { display: 'none' }}
          >
            <div className="row">
              <div className="col-md-5">Lý do từ chối</div>
              <div className="col-md-7">
                <SelectStateContainer
                  isDisabled={!isModify}
                  value={denyTypeDefaultValue || listCustomerDenyType[0]}
                  onChange={this.onChangeHandle.bind(this, 'subType')}
                  options={listCustomerDenyType || []}
                />
              </div>
            </div>
          </div>

        </div>

      </div>

      <div className="form-group">
        <div className="row">

          <div className="col-md-6">
            <div className="row">
              <div className="col-md-5">Yêu cầu dịch vụ</div>
              <div className="col-md-7">
                <Select2
                  disabled={!isModify}
                  value={model.information.service}
                  onChange={this.onChangeHandle.bind(this, 'service')}
                  style={{ width: '100%' }}
                  className="form-control select-search select2-hidden-accessible"
                  data={parentIdentifies}
                  options={{
                    placeholder: 'Chọn loại dịch vụ'
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="row">
              <div className="col-md-5">Thành phố</div>
              <div className="col-md-7">
                <Select2
                  disabled={!isModify}
                  value={model.information.area}
                  onChange={this.onChangeHandle.bind(this, 'area')}
                  style={{ width: '100%' }}
                  className="form-control select-search select2-hidden-accessible"
                  data={this.props.buildLocation()}
                  options={{
                    placeholder: 'Tất cả khu vực'
                  }}
                />
              </div>
            </div>
          </div>

        </div>

      </div>

      <div className="form-group">
        <div className="row">
          <textarea
            disabled={!isModify}
            value={model.information.note}
            onChange={this.onChangeHandle.bind(this, 'note')}
            rows="2"
            cols="4"
            placeholder="Nhập ghi chú."
            className="form-control"
          ></textarea>
        </div>
      </div>

    </form>);
  }

  renderAppointmentSchedule() {
    const { listMember } = this.props;      
    const scheduleConfig = this.props.getSchedule();
    return (<form onSubmit={this.onAppointmentSubmit}>

      <div className="form-group">
        <div className="row">
          <div className="col-md-12">
            <label>Nội dung cuộc hẹn <span className="text-danger">*</span></label>
            <input
              onChange={this.onChangeScheduleHandle.bind(this, 'description')}
              placeholder="Nội dung cuộc hẹn"
              type="text" 
              className="form-control" 
            />
          </div>
        </div>
      </div>    
      <div className="form-group">
        <div className="row">
          <div className="col-md-4">
            <label>Loại <span className="text-danger">*</span></label>
            <Select
              options={scheduleConfig.type}
              onChange={this.onChangeScheduleHandle.bind(this, 'type')}
              isSearchable
            />
          </div>
          <div className="col-md-4">
            <label>Mức độ ưu tiên <span className="text-danger">*</span></label>
            <Select
              options={scheduleConfig.priority}
              onChange={this.onChangeScheduleHandle.bind(this, 'priority')}
              isSearchable
            />
          </div>
          <div className="col-md-4">

          </div>
        </div>
      </div>

      <div className="form-group">
        <div className="row">
          <div className="col-md-4">
            <label>Ngày hẹn <span className="text-danger">*</span></label>
            <InputMask
              className="form-control"
              mask="99/99/9999"
              placeholder="Nhập ngày hẹn"
              onChange={this.onChangeScheduleHandle.bind(this, 'date')}
            />
          </div>
          <div className="col-md-4">
            <label>Giờ hẹn <span className="text-danger">*</span></label>
            <InputMask
              className="form-control"
              mask="99:99"
              placeholder="Nhập giờ hẹn"
              onChange={this.onChangeScheduleHandle.bind(this, 'hour')}
            />
          </div>
          <div className="col-md-4">
            <label>Nhắc nhở <span className="text-danger">*</span></label>
            <Select
              onChange={this.onChangeScheduleHandle.bind(this, 'remind')}
              options={scheduleConfig.remind}
              isSearchable
            />
          </div>
        </div>
      </div>

      <div className="form-group">
        <div className="row">
          <div className="col-md-12">
            <label>Người liên quan </label>
            <Select
              onChange={this.onChangeScheduleHandle.bind(this, 'members')}
              options={listMember}
              isSearchable
              isMulti
            />
          </div>
          
        </div>
      </div>

      <div className="form-group" style={{ textAlign: 'right' }}>
          
        <button onClick={this.onSubmitSchedule.bind(this, false)} className="btn btn-info">
          <i className="fa fa-calendar" style={{ marginRight: 5 }}></i> Đặt lịch hẹn
        </button>

      </div>
      
    </form>);
  }

  renderCallingLogDialog() {
    const {
      callerPhone, model, isModify 
    } = this.state;
    
    const { init, simpleOrders } = this.props;
    const extension = _.get(init, 'accountInfo.voip.extension', false);
    let callerRecordedUrl = _.get(model, 'callerRecordedUrl', '');
    if (formatDate2(new Date()) !== formatDate2(model.createdAt) && callerRecordedUrl) {
      callerRecordedUrl = callerRecordedUrl.replace('.wav', '.ogg');
    }

    let orderCount = simpleOrders.length || 0;
    if (orderCount > 10) {
      orderCount = `${orderCount}++`;
    }
    
    return (<div>
      <div className="row">
        <div className="col-xl-8">
          <div className="card ">
            <div className="card-body ">
              <Tabs defaultActiveKey="tab1">
                <Tab eventKey="tab1" title="Cập nhật hồ sơ">
                  {this.renderSupport()}
                </Tab>
                <Tab eventKey="tab2" title="Đặt lịch hẹn">
                  {this.renderAppointmentSchedule()}
                </Tab>
              </Tabs>

            </div>
          </div>
        </div>
        <div className="col-xl-4" >
          <div className="card" style={{ height: 'calc(100% - 20px)' }}>
            <div className="card-body " >
              <Tabs defaultActiveKey="tab4">
                <Tab eventKey="tab4" title="Call Logs" style={{ height: 342, overflow: 'scroll' }}>
                  <div className="tab-pane active">
                    <div style={{
                      paddingBottom: 10,
                      color: 'darkgrey',
                      fontWeight: 'bold'
                    }}>Đang chọn</div>
                  
                    <ul className="control-sidebar-menu">
                      {this.renderActiveHistory()}
                    </ul>
                    <div style={{
                      paddingBottom: 10,
                      color: 'darkgrey',
                      fontWeight: 'bold'
                    }}>Danh sách</div>
                    <ul className="control-sidebar-menu">
                      {this.renderHistory()}
                    </ul>
                  </div>
                </Tab>
                <Tab eventKey="tab5" title={`Đơn hàng (${orderCount.toString()})`}>
                  <div className="table-responsive" style={{ height: 342, overflow: 'scroll' }}>
                    <table className="table table-bordered table-striped text-nowrap">
                      <thead>
                        <tr>
                          <th>Dịch vụ</th>
                          <th>Tổng tiền</th>
                        </tr>
                      </thead>
                      <tbody >
                        {this.renderOrder()}
                      </tbody>
                    </table>
                  </div>
                  <Link
                    to="/order"
                    style={{ position: 'absolute', right: 10 }}
                    className="btn btn-info">More</Link>
                </Tab>

                <Tab eventKey="tab6" title={`Lịch hẹn (${orderCount.toString()})`}>
                  <div className="table-responsive" style={{ height: 342, overflow: 'scroll' }}>
                  Đang cập nhật
                  </div>
                
                </Tab>

              </Tabs>
            </div>
          </div>
        </div>

      </div>
      <div className="row" >
        <div className="col-xl-4" style={{ textAlign: 'left', paddingTop: 0 }}>
          <div className="row">
            <div className="col-md-12">
              {/* {extension ? <button 
                style={{ borderRadius: '50%', float: 'left', marginRight: 15 }} 
                className="btn btn-info"
                onClick={this.clickToCall.bind(this, callerPhone)} 
              >
                <i style={{ fontSize: 16 }} className="fa fa-phone"></i>
              </button> : ''} */}
              {model.callerRecordedUrl 
                ? <a 
                  style={{ borderRadius: '50%', float: 'left', marginRight: 15 }} 
                  className="btn btn-info"
                  href={model.callerRecordedUrl} 
                >
                  <i style={{ fontSize: 16 }} className="fa fa-download"></i>
                </a>  
                : ''}
              {model.callerRecordedUrl 
                ? <ReactAudioPlayer
                  style={{
                    height: '2.2rem',
                    width: 'calc(100% - 130px)'
                
                  }}
                  src={model.callerRecordedUrl}
                  autoPlay={false}
                  controls
                /> : ''}
            </div>
          </div>
        </div>

        <div className="col-xl-8" style={isModify 
          ? { display: 'block', textAlign: 'right', paddingTop: 0 } 
          : { display: 'none', textAlign: 'right', paddingTop: 0 }}
        >
          <button disabled={!isModify} onClick={this.onSubmit.bind(this, false)} className="btn btn-info">
            <i className="fa fa-save" style={{ marginRight: 5 }}></i> Lưu
          </button>

          <button 
            disabled={!isModify}
            style={{ marginLeft: 10 }} 
            onClick={this.onSubmit.bind(this, true)} 
            className="btn btn-success">
            <i className="fa fa-save" style={{ marginRight: 5 }}></i> Lưu & đóng
          </button>

          <button 
            disabled={!isModify} 
            style={{ marginLeft: 10 }}
            onClick={this.removeCallLog.bind(this, this.state.focus, false)} 
            className="btn btn-secondary">
            <i className="fa fa-recycle" style={{ marginRight: 5 }}></i> Đóng
          </button>

        </div>

      </div>
    </div>);
  }

  renderCallLogs() {
    return false;
    // nvb edit
    // const { callLogListSockets } = this.props;

    // if (_.isEmpty(callLogListSockets) === true) {
    //   return (<div></div>);
    // }
    
    // return (callLogListSockets.map((item, i) => {
    //   return (
    //     <div key={`renderCallLogs_${i}`} className="call-log-notification-container">
    //       <div className={this.state.focus === item.callLogId ? 'call-log-notification call-log-notification-warning' : 'call-log-notification'}>
    //         <i onClick={this.removeCallLog.bind(this, item.callLogId)} className="fa fa-remove call-log-remove"></i>
    //         <div onClick={this.handleOpenModal.bind(this, item, item.callLogId)} className="call-log-message">
    //           {item.callerName || item.callerPhone}
    //         </div>

    //       </div>

    //     </div>);
    // }));
  }

  handleOpenViewModal(item) {
    const { pickupCallLogs } = this.props;
    const listCustomerType = this.props.buildCustomerType();
    const listCustomerSource = this.props.buildCustomerSource();

    let sourceDefaultValue = false;
    let typeDefaultValue = false;
    
    const index = _.findIndex(pickupCallLogs, { id: item.id });
    if (item.isUpdated === false) {
      let type = false;
      let subType = false;
      let showDenyOption = false;
      let listCustomerDenyType = [];
      let denyTypeDefaultValue = {};
      if (item.information.source) {
        sourceDefaultValue = _.find(listCustomerSource, { value: item.information.source });
      }

      if (item.information.type) {
        [type, subType] = item.information.type.split('-');
        typeDefaultValue = _.find(listCustomerType, { value: item.information.type });

        if (_.get(typeDefaultValue, 'children', false)) {
          showDenyOption = true;
          listCustomerDenyType = buildListCustomerDenyType(typeDefaultValue.children);
          denyTypeDefaultValue = _.find(listCustomerDenyType, { value: subType }) || {};
        }
      }

      let callerRecordedUrl = _.get(item, 'callerRecordedUrl', '');
        
      if (formatDate2(new Date()) !== formatDate2(item.createdAt) && callerRecordedUrl) {
        callerRecordedUrl = callerRecordedUrl.replace('.wav', '.ogg');
      }

      this.setState({
        isModify: true,
        sourceDefaultValue,
        typeDefaultValue,

        denyTypeDefaultValue,
        listCustomerDenyType,

        focusHistory: index,
        model: {
          callLogId: item.id,
          callerName: item.callerName,
          callerRecordedUrl,
          information: {
            source: item.information.source || '',
            type,
            subType,
            isBussiness: item.information.isBussiness || false,
            service: item.information.service || '',
            area: item.information.area || '',
            note: item.information.note || '',
            email: item.information.email || ''
          }
        }
      });
    } else {
      this.setState({ focusHistory: index });
      this.props.callLogDetail({
        filters: { id: [item.id] }
      });
    }
  }

  handleOpenModal(e, index) {
    if (index !== this.state.focus) {
      this.handleCloseModal();
      const model = _.clone(initCallLog);
      const searching = _.clone(this.state.searching);
      const searchingOrder = _.clone(this.state.searchingOrder);
      model.callLogId = e.callLogId;
      
      /**
       * Get History
       */
      searching.filters.callerPhone = e.callerPhone;
      searchingOrder.filters.customer.phone = e.callerPhone;
      this.props.getCallLogs(searching);
      this.props.getOrders(searchingOrder);

      if (this.state.showModal === true) {
        store.dispatch({
          type: 'PUT_CALL_LOGS_MODAL',
          payload: true
        });

        setTimeout(() => {
          this.setState({
            showDenyOption: false,
            sourceDefaultValue: false,
            typeDefaultValue: false,
            denyTypeDefaultValue: false,
            isModify: true,
            model,
            callerPhone: e.callerPhone,
            callerName: e.callerName,
            callerRecordedUrl: e.callerRecordedUrl,
            focus: e.callLogId,
            focusHistory: 0
          });
        }, 100);
      } else {
        store.dispatch({
          type: 'PUT_CALL_LOGS_MODAL',
          payload: true
        });

        this.setState({
          showDenyOption: false,
          sourceDefaultValue: false,
          typeDefaultValue: false,
          denyTypeDefaultValue: false,
          isModify: true,
          model,
          callerPhone: e.callerPhone,
          callerName: e.callerName,
          callerRecordedUrl: e.callerRecordedUrl,
          focus: e.callLogId,
          focusHistory: 0
        });
      }
    }
  }

  handleCloseModal() {
    store.dispatch({
      type: 'PUT_CALL_LOGS_MODAL',
      payload: false
    });

    this.setState({
      showDenyOption: false,
      sourceDefaultValue: false,
      typeDefaultValue: false,
      denyTypeDefaultValue: false,
      focus: false,
      isModify: true,
      model: initCallLog
    });
  }

  render() {
    const {
      callerPhone, model, isModify 
    } = this.state;
    let showModal = this.state.showModal;
    if (!callerPhone) showModal = false;
    return (<div style={{ marginBottom: 45, paddingRight: 20 }} className="fixed-bottom">

      <Modal
        onRequestClose={this.handleCloseModal}
        style={{
          content: {
            top: 100, height: 570, borderRadius: 8, overflowX: 'scroll'
          },
          overlay: { zIndex: 1000, backgroundColor: 'rgba(0, 0, 0, 0.75)' }
        }}
        ariaHideApp={false}
        isOpen={showModal}
        contentLabel="Modal"
      >
        {this.renderCallingLogDialog()}
      </Modal>
      <div className=" call-log">
        {this.renderCallLogs()}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={30000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </div>);
  }

  componentDidMount(oldProps) {
    try {
      /**
       * 
       */
    } catch (error) {
      /**
       * 
       */
    }
  }

  callLogHandle(item, logType) {
    let sourceDefaultValue = false;
    let typeDefaultValue = false;

    const listCustomerType = this.props.buildCustomerType();
    const listCustomerSource = this.props.buildCustomerSource();

    let type = false;
    let subType = false;
    let showDenyOption = false;
    let listCustomerDenyType = [];
    let denyTypeDefaultValue = {};

    if (_.get(item, 'information.source', false)) {
      sourceDefaultValue = _.find(listCustomerSource, { value: item.information.source });
    }

    if (_.get(item, 'information.type', false)) {
      [type, subType] = item.information.type.split('-');
      
      typeDefaultValue = _.find(listCustomerType, { value: type });
      if (_.get(typeDefaultValue, 'children', false)) {
        showDenyOption = true;
        listCustomerDenyType = buildListCustomerDenyType(typeDefaultValue.children);
        denyTypeDefaultValue = _.find(listCustomerDenyType, { value: subType }) || {};
      }
    }

    let callerRecordedUrl = _.get(item, 'callerRecordedUrl', '');
        
    if (formatDate2(new Date()) !== formatDate2(item.createdAt) && callerRecordedUrl) {
      callerRecordedUrl = callerRecordedUrl.replace('.wav', '.ogg');
    }

    if (logType === 'callLog') {
      const information = _.get(item, 'information', {});
      this.setState({
        isModify: !item.isUpdated || false,
        showDenyOption,
        denyTypeDefaultValue,
        sourceDefaultValue,
        typeDefaultValue,
        listCustomerDenyType,
        callerPhone: item.callerPhone,
        model: {
          callLogId: item.id,
          callerName: item.callerName,
          callerPhone: item.callerPhone,
          callerRecordedUrl,
          information: {
            source: information.source || '',
            type,
            subType,
            isBussiness: information.isBussiness || false,
            service: information.service || '',
            area: information.area || '',
            note: information.note || '',
            email: information.email || ''
          }
        }
      });
    }
    if (logType === 'customerSupport') {
      const information = _.get(item, 'information', {});
      this.setState({
        isModify: false,
        showDenyOption,
        denyTypeDefaultValue,
        sourceDefaultValue,
        typeDefaultValue,
        listCustomerDenyType,
        callerPhone: item.listerPhone,
        model: {
          callLogId: item.id,
          callerName: item.listerName,
          callerPhone: item.listerPhone,
          callerRecordedUrl,
          information: {
            source: information.source || '',
            type,
            subType,
            isBussiness: information.isBussiness || false,
            service: information.service || '',
            area: information.area || '',
            note: information.note || ''
          }
        }
      });
    }
  }

  componentDidUpdate(oldProps) {
    try {
      /**
       * 
       */
      const newProps = this.props;

      if (oldProps.callLog !== newProps.callLog) {
        const item = newProps.callLog;
        this.callLogHandle(item, 'callLog');
      }

      if (oldProps.customerSupport !== newProps.customerSupport) {
        const item = newProps.customerSupport;
        this.callLogHandle(item, 'customerSupport');
      }

      if (oldProps.showCallLogModal !== newProps.showCallLogModal) {
        this.setState({ showModal: newProps.showCallLogModal });
      }
      
      if (oldProps.buildServices !== newProps.buildServices) {
        const parentIdentifies = this.props.listServiceHandleData();
        this.setState({ parentIdentifies: parentIdentifies.list });
      }
      if (oldProps.hangup !== newProps.hangup) {
        if (newProps.hangup === true) {
          toast.dismiss();

          setTimeout(() => {
            store.dispatch({
              type: 'SET_HANGUP',
              payload: false
            });
          }, 500);
        }
      }

      if (oldProps.pickup !== newProps.pickup) {
        if (newProps.pickup !== false) {
          if (this.state.showModal === false) {
            this.handleOpenModal(newProps.pickup, newProps.pickup.callLogId);
          }
          
          setTimeout(() => {
            store.dispatch({
              type: 'SET_PICKUP',
              payload: false
            });
          }, 500);
        }
      }
      if (oldProps.callLogNotification !== newProps.callLogNotification) {
        this.callLogsNotification();
      }
    } catch (error) {
      /**
       * 
       */
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      customerSupport: payload.supportReducer.customerSupport,
      callLog: payload.callLogReducer.callLog,
      pickup: payload.callLogReducer.pickup,
      hangup: payload.callLogReducer.hangup,
      showCallLogModal: payload.callLogReducer.showCallLogModal,
      callLogNotification: payload.callLogReducer.callLogNotification,
      callLogListSockets: payload.callLogReducer.callLogListSockets,
      callLogSockets: payload.callLogReducer.callLogSockets,
      simpleOrders: payload.orderReducer.simpleOrders,
      pickupCallLogs: payload.callLogReducer.pickupCallLogs,
      userInfo: payload.generalReducer.userInfo,
      buildServices: payload.serviceReducer.buildServices,
      init: payload.generalReducer.init,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(CreateCallLog)
);
