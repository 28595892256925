import React from 'react';
import _ from 'lodash';
import validator from 'validator';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select2 from 'react-select2-wrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Select from 'react-select';
import moment from 'moment';
import cx from 'classnames';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { DelayInput } from 'react-delay-input';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Autocomplete from '@celebryts/react-autocomplete-tags';
import InputMask from 'react-input-mask';
import { confirmAlert } from 'react-confirm-alert'; // Import
import SelectStateContainer from '../../ultils/selectState.container';
import { PAGE_LIMIT } from '../../../configs/constants';
import PagingContainer from '../../ultils/paging.container';
import UploadFileContainer from '../../ultils/uploadFile.container';

import {
  paging,
  formatDate2,
  formatDateRange,
  formatDateFilter,
  checkScope,
  changeAlias,
  localStoreFilter,
  localStorePageNum,
  formatDateFilter2,
  fileUpload,
  buildListCountryArrival
} from '../../../helpers';

class SearchOrder extends React.Component {
  constructor(props) {
    super(props);

    const ordersFilter = localStoreFilter('searchOrder', 'getOrders');
    const createdAt = _.get(ordersFilter, 'filters.estimatedAt');
    const dateRangeTitle = formatDateFilter2(_.get(createdAt, 'from', false), _.get(createdAt, 'to', false), 'Ngày dự kiến có KQ');

    this.state = {
      display: false,
      socket: 1,
      pageNum: localStorePageNum('searchOrder', 'getOrders'),
      customerTitle: '',
      showContextMenu: {
        display: false,
        style: {}
      },
      orders: [],
      listAgency: [],
      showEditBox: false,
      showImmigrationEditBox: false,
      supplierDefaultValue: { value: '', label: 'Đối tác xử lý' },
      dateDisplay: 'DD/MM/YYYY',
      visaDateDisplay: 'DD/MM/YYYY',
      ignoreState: ['Thành công', 'Hủy'],
      states: [],
      modifyState: false,
      suppliers: [],
      modifyStates: [],
      listParentIdentify: [],
      tagSuggestions: [],
      checkbox: {},
      listService: [],
      groups: [],
      serviceDefaultValue: 'all',
      // dateRangeTitle: 'Ngày dự kiến có KQ',
      dateRangeTitle,
      ranges: {
        'Clear date': [1, 1],
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      },
      model: {
        serviceIdentifyCode: [],
        invoiceId: [],
        state: '',
        estimatedAt: '',
        description: '',
        profile: {
          visa: {
            result: [],
            expiredAt: ''
          }
        }
      },

      searching: ordersFilter || {
        filters: {
          profile: {
            fullName: '',
            phone: ''
          },
          customer: {
            id: '',
            title: ''
          },
          apply: {
            identifyCode: ''
          },

          tag: [],
          serviceIdentifyCode: '',
          estimatedAt: {
            from: '',
            to: ''
          }
        },
        paging: {
          start: 0,
          limit: PAGE_LIMIT
        }
      },

      selectedFile: []

    };

    this.onTreeDropdownChange = this.onTreeDropdownChange.bind(this);
    this.handleDatePicker = this.handleDatePicker.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.renderOrder = this.renderOrder.bind(this);
    this.onSearchChangeHandle = this.onSearchChangeHandle.bind(this);
    this.onModifyChangeHandle = this.onModifyChangeHandle.bind(this);
    this.onChangeCheckBoxHandle = this.onChangeCheckBoxHandle.bind(this);
    this.checkFinishOrder = this.checkFinishOrder.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.callbackOrder = this.callbackOrder.bind(this);
    this.onSearchCustomerHandle = this.onSearchCustomerHandle.bind(this);
    this.setFiles = this.setFiles.bind(this);
    this.onUploadFile = this.onUploadFile.bind(this);
  }

  setFiles(selectedFile) {
    try {
      this.setState({ selectedFile });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Upload
   */

  onUploadFile(key, e) {
    try {
      const files = _.get(e, 'target.files', false);
      if (_.isEmpty(files) === false) {
        this.setState({ selectedFile: files });
        this.fileUpload(files, key);
      }
    } catch (error) {
      // console.log(error);
    }
  }

  fileUpload(files, type) {
    try {
      const { init, userInfo } = this.props;
      const data = new FormData();
      data.append('file', files[0]);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: userInfo.accessToken,
          accessToken: userInfo.accessToken
        }
      };

      axios.post(`${this.props.uploadPath}/upload-contract`, data, config).then((res) => {
        const code = _.get(res, 'data.code', false);
        if (code === 100) {
          const model = Object.assign({}, this.state.model);
          model.profile.files[0] = res.data.data.message.pathname;
          const selectedFileName = res.data.data.message.filename;
          this.setState({ model, selectedFileName });
        } else {
          const message = _.get(res, 'data.data.message.message', 'File không hợp lệ');
          NotificationManager.error(message, '', 3000);
        }
      });
    } catch (error) {
      // console.log(error);
    }
  }

  callbackOrder() {
    try {
      const { orders, socketOrder } = this.props;
      if (_.isEmpty(socketOrder) === false) {
        const index = _.findIndex(orders, { id: socketOrder.id });
        if (index >= 0) {
          orders[index] = socketOrder;
        } else {
          orders.unshift(socketOrder);
        }
        this.props.dispatch({
          type: 'POST_SEARCH_ORDER_SUCCESS',
          payload: orders
        });
        this.toggleRow();
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Searching
   */

  onSubmit = async (e) => {
    try {
      e.preventDefault();
      const validate = this.validateOnSubmit();
      if (validate === true) {
        const { checkbox, showImmigrationEditBox, showEditBox } = this.state;
        const model = Object.assign({}, this.state.model);
        model.invoiceId = [];
        _.forEach(checkbox, (item, key) => {
          if (item === true) {
            model.invoiceId.push(Number(key));
          }
        });

        const files = await fileUpload(this.state.selectedFile, this.props);
        if (files.code === 1) {
          model.profile.visa.result = files.data;
        }
        if (showImmigrationEditBox === true) {
          this.props.onSubmit(_.pick(model, ['serviceIdentifyCode', 'invoiceId', 'state', 'profile', 'description']));
        } else if (showEditBox === true) {
          this.props.onSubmit(_.pick(model, ['serviceIdentifyCode', 'invoiceId', 'state', 'estimatedAt', 'description']));
        }
        this.setState({
          model: {
            serviceIdentifyCode: [],
            invoiceId: [],
            state: '',
            estimatedAt: '',
            description: '',
            profile: {
              visa: {
                result: [],
                expiredAt: ''
              }
            }
          },
          dateDisplay: 'DD/MM/YYYY',
          visaDateDisplay: 'DD/MM/YYYY',
          selectedFile: [],
          modifyState: false,
          showEditBox: false,
          showImmigrationEditBox: false
        });

        //
      }
    } catch (error) {
      // console.log(error);
    }
  }

  validateOnSubmit() {
    const { model, checkbox, modifyState } = this.state;

    const stateIsFinish = _.get(_.find(modifyState.service.states, { state: model.state }), 'isFinish', false);

    if (stateIsFinish === true) {
      if (_.isEmpty(this.state.selectedFile) && _.isEmpty(_.get(model, 'profile.visa.result', []))) {
        NotificationManager.error('Chưa tải Kết quả visa lên', '', 1500);
        return false;
      }
      const expiredAt = _.get(model, 'profile.visa.expiredAt', false);
      if (!expiredAt) {
        NotificationManager.error('Chưa nhập ngày hết hạn visa', '', 1500);
        return false;
      }
    } 
    
    if (_.isEmpty(checkbox) === true) {
      NotificationManager.error('Thông tin không hợp lệ', '', 1500);
      return false;
    }

    if (_.isEmpty(model.state) === true) {
      NotificationManager.error('Trạng thái đơn hàng không được để trống', '', 1500);
      return false;
    }

    return true;
  }

  checkFinishOrder(data) {
    if (_.isEmpty(data) === true) {
      return true;
    }
    const defaultState = _.get(data, 'state', false);
    if (defaultState.isFinish === true) {
      return true;
    }
    return false;
  }

  /**
   * 
   */
  onChangePage(key) {
    const searching = Object.assign({}, this.state.searching);
    let pageNum = this.state.pageNum;
    switch (key) {
      case 'next': {
        //
        pageNum += 1;
        break;
      }
      default: {
        pageNum -= 1;
        if (pageNum <= 1) {
          pageNum = 1;
        }
        break;
      }
    }
    searching.paging = paging(pageNum, PAGE_LIMIT);
    this.props.getOrders(searching);
    this.setState({ searching, pageNum });
  }

  handleDatePicker(e, picker) {
    const searching = Object.assign({}, this.state.searching);
    const pageNum = 1;

    searching.filters.estimatedAt = {
      from: '',
      to: ''
    };
    if (picker.startDate.unix() > 0) {
      const dateRangeTitle = formatDateFilter(picker.startDate, picker.endDate);
      const date = formatDateRange(picker.startDate, picker.endDate);
      this.setState({ searching, dateRangeTitle, pageNum: 1 });
      searching.filters.estimatedAt.from = date.startDate;
      searching.filters.estimatedAt.to = date.endDate;
      searching.paging = paging(this.state.pageNum, PAGE_LIMIT);
      this.props.getOrders(searching);
    } else {
      this.setState({ searching, dateRangeTitle: 'Ngày dự kiến có KQ', pageNum: 1 });
      this.props.getOrders(searching);
    }
  }

  onTreeDropdownChange(currentNode, selectedNodes) {
    const searching = Object.assign({}, this.state.searching);
    const result = {};
    const value = selectedNodes[0].value;
    const states = selectedNodes[0].states;
    searching.filters.serviceIdentifyCode = selectedNodes[0].value;

    if (_.isEmpty(states) === false) {
      const listState = [];
      if (_.isEmpty(states) === false) {
        states.forEach((element) => {
          listState.push({
            label: element,
            value: element
          });
        });
        result.listState = listState;
        result.isDisable = false;
      }
    }
    result.searching = searching;
    this.props.getOrders(searching);
    result.listParentIdentify = this.props.listServiceHandleData(selectedNodes[0].value);
    this.setState(result);
  }

  onChangeCheckBoxHandle(e) {
    const { orders } = this.props;
    const model = Object.assign({}, this.state.model);
    const checkbox = Object.assign({}, this.state.checkbox);
    let modifyStates = [];
    let showEditBox = false;
    let showImmigrationEditBox = false;

    const value = _.get(e, 'target.value', _.get(e, 'value', false));

    const serviceGroup = {};
    if (value === 'all') {
      showEditBox = e.target.checked;
      orders.forEach((order) => {
        if (this.state.ignoreState.indexOf(order.state) < 0) {
          serviceGroup[order.serviceIdentifyCode] = true;
          checkbox[order.id] = e.target.checked;
        }
      });
    } else {
      checkbox[value] = e.target.checked;
      _.forEach(checkbox, (item, key) => {
        if (item === true) {
          const order = _.find(orders, { id: Number(key) });
          if (_.isEmpty(order) === false) {
            if (this.state.ignoreState.indexOf(order.state) < 0) {
              const orderType = _.get(order, 'type', 'EXIT');
              serviceGroup[order.serviceIdentifyCode] = true;
              showEditBox = true;
              showImmigrationEditBox = false;
              if (orderType === 'ARRIVAL') {
                showEditBox = false;
                showImmigrationEditBox = true;
              }
            }
          }
        }
      });
    }

    if (Object.keys(serviceGroup).length > 1) {
      showEditBox = false;
      showImmigrationEditBox = false;
      NotificationManager.error('Đang chọn nhiều hơn một loại dịch vụ', '', 1500);
    }
    const serviceIdentifyCode = serviceGroup ? Object.keys(serviceGroup)[0] : null;
    if (serviceIdentifyCode) {
      const service = this.props.getServiceDetail(serviceIdentifyCode);
      const hasOrderFinish = checkScope('order.finish', this.props);
      modifyStates = _.clone(service.stateList);
      if (hasOrderFinish === false) {
        _.remove(modifyStates, {
          value: 'DONE'
        });
      }
    }
    model.serviceIdentifyCode = serviceIdentifyCode;
    this.setState({
      checkbox, showEditBox, showImmigrationEditBox, modifyStates, model
    });
  }

  onStateChangeHandle(i, invoiceId, e) {
    const warning = _.get(e, 'state.warning', false);
    const searching = Object.assign({}, this.state.searching);
    if (warning) {
      confirmAlert({
        title: 'Xác nhận cập nhật trạng thái',
        message: warning,
        buttons: [
          {
            label: 'Đồng ý',
            onClick: () => {
              this.props.onSubmit({
                serviceIdentifyCode: e.service.identifyCode,
                invoiceId: [invoiceId],
                state: e.value
              }, searching);
              this.props.getOrders(searching);
            }
          },
          {
            label: 'Hủy'
          }
        ]
      });
    } else {
      this.props.onSubmit({
        serviceIdentifyCode: e.service.identifyCode,
        invoiceId: [invoiceId],
        state: e.value
      }, searching);
      // TODO: neverbestboy remove
      // this.props.getOrders(searching);
    }
  }

  onModifyChangeHandle(key, e) {
    const model = Object.assign({}, this.state.model);
    const value = _.get(e, 'target.value', _.get(e, 'value', false));
    const result = {};
    switch (key) {
      case 'modifyState': {
        const warning = _.get(e, 'state.warning', false);
        if (warning) {
          confirmAlert({
            title: 'Xác nhật cập nhật trạng thái',
            message: warning,
            buttons: [
              {
                label: 'Đồng ý',
                onClick: () => {
                  model.state = value;
                  result.modifyState = e;
                  result.model = model;
                  this.setState(result);
                }
              },
              {
                label: 'Hủy'
              }
            ]
          });
        } else {
          result.modifyState = e;
          model.state = value;
        }

        break;
      }
      case 'estimatedAt': {
        const dateFormat = 'YYYY/MM/DD';
        const splittedDate = value.split('/');
        if (splittedDate.length === 3) {
          const date = moment(`${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`, dateFormat);
          result.dateDisplay = value;
          if (date.isValid() === true) {
            model.estimatedAt = date.format();
          }
        }
        break;
      }

      case 'visaExpiredAt': {
        const dateFormat = 'YYYY/MM/DD';
        const splittedDate = value.split('/');
        if (splittedDate.length === 3) {
          const date = moment(`${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`, dateFormat);
          result.visaDateDisplay = value;
          if (date.isValid() === true) {
            model.profile.visa.expiredAt = date.format();
          }
        }
        break;
      }

      default:
        model[key] = _.get(e, 'target.value', _.get(e, 'value', false));
        break;
    }
    result.model = model;
    this.setState(result);
  }

  onSearchCustomerSubmit(e) {
    if (e) {
      const searching = Object.assign({}, this.state.searching);
      const showContextMenu = Object.assign({}, this.state.showContextMenu);
      const pageNum = 1;
      showContextMenu.display = false;
      searching.filters.customer = {
        identifyCode: e.value
      };
      searching.paging = paging(pageNum, PAGE_LIMIT);
      this.setState({ showContextMenu, searching, customerTitle: e.label });
      this.props.getOrders(searching);
    }
  }

  onSearchCustomerHandle(e) {
    try {
      const value = _.get(e, 'target.value', _.get(e, 'value', '')).trim();
      const searching = Object.assign({}, this.state.searching);
      const listAgency = [];
      const showContextMenu = {
        display: false,
        style: {}
      };

      if (value.length >= 2) {
        const agencies = Object.assign({}, this.props.agencies);
        const text = changeAlias(value.toLowerCase());

        const regex = new RegExp(`${text}`, 'g');
        _.forEach(agencies, (item) => {
          const title = changeAlias(item.title.toLowerCase());
          const match = title.match(regex);
          if (_.isEmpty(match) === false) {
            listAgency.push({ value: item.identifyCode, label: item.title });
          }
        });
        if (_.isEmpty(listAgency) === false) {
          showContextMenu.display = true;
        }
      } else if (value.length === 0) {
        searching.filters.customer = {};
        this.props.getOrders(searching);
      }
      this.setState({
        showContextMenu, searching, listAgency, customerTitle: value
      });
    } catch (error) {
      console.log(error);
    }
  }

  onSearchChangeHandle(key, e) {
    const searching = Object.assign({}, this.state.searching);
    const value = _.get(e, 'target.value', _.get(e, 'value', false));
    const result = {
      checkbox: {},
      pageNum: 1
    };
    switch (key) {
      case 'state': {
        searching.filters.state = '';
        if (value !== 'all') {
          searching.filters.state = e.state;
        }
        break;
      }
      case 'apply': {
        searching.filters.apply.identifyCode = '';
        if (value !== 'all') {
          searching.filters.apply.identifyCode = value;
        }
        break;
      }
      case 'phone': {
        const isNumber = validator.isNumeric(value);
        searching.filters.profile.fullName = '';
        searching.filters.profile.phone = '';
        searching.filters.id = '';

        if (isNumber === true) {
          if (value.length < 8) {
            searching.filters.id = [value.toString()];
          } else {
            searching.filters.profile.phone = value;
          }
        } else {
          searching.filters.profile.fullName = value;
        }

        break;
      }
      case 'tag': {
        const tag = _.get(e, 'label', false);
        searching.filters[key] = this.tags.state.tags;
        if (tag) {
          searching.filters[key].push(tag);
        }
        searching.filters[key] = _.uniq(searching.filters[key]);
        break;
      }
      case 'serviceIdentifyCode': {
        searching.filters.serviceIdentifyCode = '';
        if (value !== 'all') {
          searching.filters.serviceIdentifyCode = value;
        }
        break;
      }
      default: {
        searching.filters[key] = _.get(e, 'target.value', _.get(e, 'value', 0));
        break;
      }
    }

    searching.paging = paging(result.pageNum, PAGE_LIMIT);
    this.props.getOrders(searching);
    result.searching = searching;
    this.setState(result);
  }

  renderAgencyTitle(agency) {
    if (!agency) {
      return (<span>Khách lẻ</span>);
    }
    const titles = [
      { text: 'Đại lý', id: 'agency' },
      { text: 'Khách lẻ', id: 'customer' },
      { text: 'Ctv', id: 'collaborator' },
      { text: 'Nhà cung cấp', id: 'supplier' }
    ];

    const title = _.find(titles, { id: agency.group }, { text: 'Khách lẻ', id: 'customer' });

    return (<span>{agency.group === 'customer' ? title.text : `${title.text} ${agency.title}`}</span>);
  }

  toggleRow = () => {
    this.setState(prevstate => ({
      display: !prevstate.display
    }));

    setTimeout(() => {
      this.setState(prevstate => ({
        display: !prevstate.display
      }));
    }, 3000);
  };

  renderOrder() {
    try {
      const { checkbox, states } = this.state;
      const {
        orders, socketOrder, agencies, init 
      } = this.props;
      const countryArrivals = init.countryArrival;
      
      if (_.isEmpty(orders) === true || _.isEmpty(checkbox) === true) {
        return (<tr></tr>);
      }
      const result = [];
      let date = '';
      orders.forEach((element) => {
        const createdAt = formatDate2(element.createdAt);
        if (createdAt !== date) {
          date = createdAt;
          result.push({ createdAt });
        }
        result.push(element);
      });

      return (result.map((item, i) => {
        if (!item.id) {
          return (<tr key={i} className="table-active table-border-double">
            <td colSpan="11" className="font-weight-semibold">Đơn hàng ngày {item.createdAt}</td>
          </tr>);
        }

        const linkTo = item.type === 'ARRIVAL' ? `/order-immigration-modify/${item.customer.id}/${item.id}` : `/order-modify/${item.customer.id}/${item.id}`;
        const linkToReceipt = `/order-receipt/${item.customer.id}/${item.id}`;
        const estimatedAt = formatDate2(item.estimatedAt);
        //
        let process = item.process ? _.last(item.process) : {};
        _.forEach(item.process, (processItem) => {
          if (processItem.isPin === true) {
            process = processItem;
          }
        });

        const service = this.props.getServiceDetail(item.serviceIdentifyCode);
        const stateList = _.clone(service.stateList);
        const hasOrderFinish = checkScope('order.finish', this.props);
        if (hasOrderFinish === false) {
          _.remove(stateList, {
            value: 'DONE'
          });
        }
        const settingProcessingTime = _.get(service, 'settings', {});
        let processingTimeTitle = '';
        const countryArrival = _.find(countryArrivals, { code: item.profile.countryArrival });
        let objProcessingTime;
        try {
          objProcessingTime = JSON.parse(settingProcessingTime[`${_.get(countryArrival, 'namespace', 'default')}.processingTime`]);
        } catch (error) {
          objProcessingTime = {};
        }
        
        if (!_.isUndefined(objProcessingTime.dayWeekendAndHoliday)) {
          objProcessingTime.dayWeekendAndHoliday = [];
        }
        objProcessingTime = _.merge(objProcessingTime.normal, objProcessingTime.dayWeekendAndHoliday);
        const t = _.find(objProcessingTime, { key: item.processingTime });
        if (!_.isUndefined(t)) {
          processingTimeTitle = _.get(t, 'markdown', '');
        }
        const subServiceName = _.get(item, 'addon.subServiceName', false);
        const agency = _.find(agencies, { identifyCode: item.customerIdentifyCode });
        const checked = checkbox[item.id];
        const defaultState = _.find(service.stateList, { value: item.state });

        const isFinish = this.checkFinishOrder(defaultState);

        const depositAmount = _.sumBy(item.deposit, 'amount');
        const visaResult = _.get(item, 'profile.visa.result', []);
        const profileFiles = _.get(item, 'profile.files', []);
        let depositText = `Paid : ${depositAmount.toLocaleString()}`;
        if (depositAmount === item.amount) {
          depositText = 'Full Paid';
        }

        return (<tr key={i} className={
          socketOrder.id === item.id
            ? cx('toggler', { 'toggler--row': this.state.display })
            : ''
        }>

          <td>
            {isFinish === false ? <input
              onChange={this.onChangeCheckBoxHandle.bind(this)}
              checked={checked || false}
              value={item.id}
              style={{ transform: 'scale(1.5)' }}
              type="checkbox"
            /> : ''}

          </td>
          <td>
            {item.supplierId 
              ? <span style={{ color: '#2196f3' }}>{item.id}</span>
              : item.id
            }
            
          </td>
          <td>
            <div className="media">
              <div className="media-body align-self-center">
                <Link to={linkTo}>
                  {_.get(item, 'profile.fullName', '')}
                </Link> <span dangerouslySetInnerHTML={{ __html: processingTimeTitle }}></span>
                <div style={{
                  fontSize: 11,
                  textDecoration: 'none',
                  backgroundColor: 'transparent'
                }}>
                  {_.get(service, 'title', '')} {subServiceName ? `- ${subServiceName}` : ''}
                </div>
                {_.isEmpty(visaResult) === false ? <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faPaperclip} /> : ''}
                <div className="text-muted font-size-sm">
                  <span className="badge badge-mark border-primary mr-1"></span>
                  {this.renderAgencyTitle(agency)}
                </div>

              </div>
            </div>
          </td>
          <td style={{ textAlign: 'right' }}>
            <span style={{ fontWeight: 'bold' }}>
              {item.amount.toLocaleString()}</span>
            <br />
            <div className="text-muted font-size-sm">
              {depositText}
            </div>

          </td>
          <td >
            <SelectStateContainer
              isDisabled={isFinish}
              value={_.get(states, i, defaultState)}
              onChange={this.onStateChangeHandle.bind(this, i, item.id)}
              options={stateList}
            />
          </td>
          <td>
            {this.renderProcess(process)}
          </td>
          <td>{estimatedAt}</td>
          {/* <td>{item.apply.title}</td> */}
          {/* <td>
            {_.isEmpty(profileFiles) === false ? this.renderDownloadFile(profileFiles, 'profileFiles-') : ''}
          </td> */}
          <td className="text-center">
            <a onClick={() => window.open(linkToReceipt, '_blank')}
              value="redirect"
              className="btn btn-sm "
              style={{
                color: 'black',
                fontSize: 14,
                backgroundColor: 'white'
              }}
              title="In phiếu thu"
            ><i className="fa fa-print"></i>
            </a>

          </td>
        </tr>);
      }));
    } catch (error) {
      // console.log(error);
      return (<tr></tr>);
    }
  }

  renderDownloadFile(files, prefix = 'renderDownloadFile-') {
    try {
      const style = {
        backgroundColor: 'whitesmoke',
        paddingLeft: 20,
        marginBottom: 10,
        borderLeft: 'solid 3px darkgrey',
        height: '2.25003rem'
      };

      if (_.isEmpty(files) === true) {
        return (<div style={style} className="uniform-uploader" >
          <span className="form-text text-muted">None</span></div>);
      }
      const path = require('path');
      return (files.map((file, i) => {
        const link = `${this.props.downloadPath}${file}`;
        const fileExt = path.extname(link);
        const basename = path.basename(link);
        let fileExtClassName = '';
        switch (fileExt) {
          case '.docx':
          case '.doc':
            fileExtClassName = 'fa fa-file-word-o';
            break;

          default:
            fileExtClassName = 'fa fa-file-pdf-o';
            break;
        }
        return (
          <div className="uniform-uploader"
            key={_.uniqueId(prefix)}
            style={style}>
            <span className="form-text text-muted">
              <a target="_blank" href={link}>
                <i style={{ paddingRight: 10 }} className={fileExtClassName}></i>
                {basename}
              </a>
            </span>
          </div>
        );
      }));
    } catch (error) {
      return (<div></div>);
    }
  }

  renderProcess(process) {
    if (_.isEmpty(process) === true) {
      return (<span></span>);
    }
    return (<span>
      <div className="font-weight-bold bg-grey" style={{ minWidth: 300, maxWidth: '100%', paddingLeft: 10 }}>
        {process.account.title}
      </div>
      <p style={{ marginBottom: 0, whiteSpace: 'pre-line' }}>{process.note}</p>

    </span>);
  }

  renderStateMofidy() {
    const { orders } = this.props;
    const {
      model, checkbox, showEditBox, modifyStates, modifyState, dateDisplay
    } = this.state;

    if (showEditBox === false) {
      return (<div></div>);
    }

    const orderIds = [];
    const receiptCustomer = {};
    _.forEach(checkbox, (item, id) => {
      if (item === true) {
        const order = _.find(orders, { id: Number(id) });
        if (_.isEmpty(order) === false) {
          if (!receiptCustomer[order.customer.id]) {
            receiptCustomer[order.customer.id] = {
              customer: order.customer,
              orderIds: []
            };
          }
          receiptCustomer[order.customer.id].orderIds.push(order.id);
          orderIds.push(`#${order.id}`);
        }
      }
    });

    return (
      <div className="card">
        <div className="card-header bg-transparent header-elements-inline">
          <h5 className="card-title">Cập nhật tiến trình đơn hàng</h5>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-xl-12">
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-12">
                      <label>Mã đơn hàng : </label>
                      <label>{orderIds.join(', ')}</label>

                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">

                    <div className="col-md-6">
                      <label>Trạng thái đơn hàng</label>

                      <SelectStateContainer
                        value={modifyState}
                        onChange={this.onModifyChangeHandle.bind(this, 'modifyState')}
                        options={modifyStates}
                        placeholder="Trạng thái đơn hàng"
                      />

                    </div>
                    <div className="col-md-6">
                      <label>Ngày dự kiến có KQ</label>
                      <InputMask
                        value={dateDisplay}
                        className="form-control"
                        mask="99/99/9999"
                        onChange={this.onModifyChangeHandle.bind(this, 'estimatedAt')}
                      />
                    </div>
                  </div>
                </div>

                <div className="text-right">
                  <button className="btn btn-primary" style={{ marginLeft: 20 }}>
                    <i className="fa fa-save"></i> Lưu lại
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderImmigrationStateMofidy() {
    const { orders } = this.props;
    const {
      checkbox, showImmigrationEditBox, modifyStates, modifyState, visaDateDisplay
    } = this.state;

    if (showImmigrationEditBox === false) {
      return (<div></div>);
    }

    const orderIds = [];
    const receiptCustomer = {};
    _.forEach(checkbox, (item, id) => {
      if (item === true) {
        const order = _.find(orders, { id: Number(id) });
        if (_.isEmpty(order) === false) {
          if (!receiptCustomer[order.customer.id]) {
            receiptCustomer[order.customer.id] = {
              customer: order.customer,
              orderIds: []
            };
          }
          receiptCustomer[order.customer.id].orderIds.push(order.id);
          orderIds.push(`#${order.id}`);
        }
      }
    });

    return (
      <div className="card">
        <div className="card-header bg-transparent header-elements-inline">
          <h5 className="card-title">Cập nhật tiến trình đơn hàng</h5>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-xl-12">
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-12">
                      <label>Mã đơn hàng : </label>
                      <label>{orderIds.join(', ')}</label>

                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">

                    <div className="col-md-6">
                      <label>Trạng thái đơn hàng</label>

                      <SelectStateContainer
                        value={modifyState}
                        onChange={this.onModifyChangeHandle.bind(this, 'modifyState')}
                        options={modifyStates}
                        placeholder="Trạng thái đơn hàng"
                      />

                    </div>
                    <div className="col-md-6">
                      <label>Ngày hết hạn visa</label>
                      <InputMask
                        value={visaDateDisplay}
                        className="form-control"
                        mask="99/99/9999"
                        onChange={this.onModifyChangeHandle.bind(this, 'visaExpiredAt')}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-12">
                      <label>Kết quả visa</label>
                      <UploadFileContainer
                        setFiles={this.setFiles.bind(this)}
                      />
                      <span className="form-text text-muted">Accepted formats: doc, docx, pdf, bmp, png, gif, jpg, jpeg</span>
                    </div>
                  </div>
                </div>

                <div className="text-right">
                  <button className="btn btn-primary" style={{ marginLeft: 20 }}>
                    <i className="fa fa-save"></i> Lưu lại
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderReceiptPrint() {
    const { orders } = this.props;
    const { checkbox } = this.state;
    let showReceipt = false;
    const orderIds = [];
    const receiptCustomer = {};

    _.forEach(checkbox, (item, id) => {
      if (item === true) {
        showReceipt = true;
      }
    });
    if (showReceipt === false) {
      return (<div></div>);
    }

    _.forEach(checkbox, (item, id) => {
      if (item === true) {
        const order = _.find(orders, { id: Number(id) });
        if (_.isEmpty(order) === false) {
          if (!receiptCustomer[order.customer.id]) {
            receiptCustomer[order.customer.id] = {
              customer: order.customer,
              orderIds: []
            };
          }
          receiptCustomer[order.customer.id].orderIds.push(order.id);
          orderIds.push(`#${order.id}`);
        }
      }
    });

    return (
      <div className="card">
        <div className="card-header bg-transparent header-elements-inline">
          <h5 className="card-title">In phiếu thu</h5>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-xl-12">

              {this.renderReceipt(receiptCustomer)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderReceipt(receipts) {
    if (_.isEmpty(receipts) === true) {
      return (<span></span>);
    }
    const data = [];
    Object.keys(receipts).map((key) => {
      return data.push(receipts[key]);
    });
    return (<table style={{ marginTop: 20 }} className="table table-bordered table-striped text-nowrap">
      <thead>
        <tr>
          <th className="text-center">#</th>
          <th className="text-center">Khách hàng</th>
          <th className="text-center">Mã đơn hàng</th>
          <th className="text-center">Công cụ</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, i) => {
          const linkToReceipt = `/order-receipt/${item.customer.id}/${item.orderIds.join(',')}`;
          return (<tr key={`linkToReceipt_${i}`}>
            <td className="text-left">{i + 1}</td>
            <td className="text-left">{item.customer.title}</td>
            <td className="text-left">#{item.orderIds.join(' ,#')}</td>
            <td className="text-left">
              <a onClick={() => window.open(linkToReceipt, '_blank')} value="redirect" className="btn btn-info" style={{ color: 'white' }}>
                <i className="fa fa-print"></i> In phiếu thu
              </a>
            </td>
          </tr>);
        })}

      </tbody>
    </table>);
  }

  setStag(tag) {
    if (tag) {
      const searching = Object.assign({}, this.state.searching);
      searching.filters.tag = this.tags.state.tags;

      if (tag && !_.includes(searching.filters.tag, tag)) {
        searching.filters.tag.push(tag);
        this.setState({ searching });
        this.props.getOrders(searching);
      }
    }
  }

  onSuggestTagHandle(e) {
    const searching = e ? { filters: { tag: e } } : {};
    this.props.getTagSuggest(searching);
  }

  renderError() {
    const { callbackInvoice } = this.props;
    if (_.isEmpty(callbackInvoice) === true) {
      return (<div></div>);
    }
    const fails = _.get(callbackInvoice, 'fails', []);
    if (_.isEmpty(fails) === true) {
      return (<div></div>);
    }
    const mergeErrorMessage = {};
    _.forEach(fails, (item) => {
      if (!mergeErrorMessage[item.description]) {
        mergeErrorMessage[item.description] = {
          invoiceId: [],
          description: item.description
        };
      }
      mergeErrorMessage[item.description].invoiceId.push(`#${item.invoiceId}`);
    });
    return (<div className="alert alert-danger bg-white alert-styled-left alert-arrow-left alert-dismissible">
      <button type="button" className="close" data-dismiss="alert"></button>
      <h6 className="alert-heading font-weight-semibold mb-1">
        Cập nhật tiến độ đơn hàng thất bại
      </h6>
      {Object.values(mergeErrorMessage).map((item, i) => {
        return (<p key={`renderError_${i}`}>Đơn hàng {item.invoiceId.join(', ')} : {item.description}</p>);
      })}

    </div>);
  }

  handleCreateOrder(type) {
    const { init } = this.props;
    switch (type) {
      case 2:
        this.props.history.push(`/order-immigration-create/${_.get(init, 'accountInfo.id', '')}`);
        break;

      default:
        this.props.history.push('/agency');
        break;
    }
  }

  render() {
    const {
      ranges,
      dateRangeTitle,
      parentIdentifies,
      searching,
      tagSuggestions,
      showContextMenu,
      customerTitle,
      supplierDefaultValue,
      suppliers
    } = this.state;

    const { agencies } = this.props;

    const identifyCode = _.get(searching, 'filters.customer.identifyCode', false);
    let agencyTitle = '';
    if (_.isEmpty(identifyCode) === false && _.isEmpty(agencies) === false) {
      const agency = _.find(agencies, { identifyCode });
      if (_.isEmpty(agency) === false) {
        agencyTitle = agency.title;
      }
    }
    const listService = this.props.listServiceHandleData();

    let defaultState = _.get(listService, 'list.[0].states.[0]', {
      index: 0,
      state: 'all',
      value: 'all',
      label: 'Tất cả trạng thái',
      color: '#F3E0EC',
      isFinish: false,
      isRefund: false,
      isSettlement: false,
      isCancelByGuest: false,
      isOnCreate: true
    });

    let states = [];
    if (_.isEmpty(listService) === false) {
      const searchState = _.get(searching, 'filters.state', false);
      states = _.get(listService, 'list.[1].states', []);
      _.map(states, (item) => {
        item.label = item.title;
        item.value = item.state;
      });
      states.unshift({
        index: 0,
        state: 'all',
        value: 'all',
        label: 'Tất cả trạng thái',
        color: '#F3E0EC',
        isFinish: false,
        isRefund: false,
        isSettlement: false,
        isCancelByGuest: false,
        isOnCreate: true
      });
      defaultState = _.find(states, { state: searchState }) || defaultState;
    }

    let apply = { value: 'all', label: 'Lọc theo nhân viên' };
    if (_.isEmpty(searching.filters) === false) {
      const applyIdentifyCode = _.get(searching, 'filters.apply.identifyCode', false);
      if (applyIdentifyCode) {
        apply = _.find(this.props.listMembers(), { value: searching.filters.apply.identifyCode }) || { value: 'all', label: 'Lọc theo nhân viên' };
      }
    }

    let selectSupplier = { value: '', label: 'Lọc theo đối tác xử lý' };
    if (_.isEmpty(searching.filters) === false) {
      const supplierId = _.get(searching, 'filters.supplierId', false);
      if (supplierId) {
        selectSupplier = _.find(this.state.suppliers, { value: supplierId }) || { value: '', label: 'Lọc theo đối tác xử lý' };
      }
    }

    return (
      <div>

        <div className="alert alert-info bg-white alert-styled-left alert-arrow-left alert-dismissible">
          <button type="button" className="close" data-dismiss="alert"></button>
          <h6 className="alert-heading font-weight-semibold mb-1">
            Danh sách đơn hàng của bạn </h6>
          Luôn thường xuyên theo dõi và hỗ trợ khách hàng. Mỗi 1 cử chỉ thân thiện của bạn sẽ luôn ghi điểm trong mắt khách hàng.
        </div>

        {this.renderError()}

        <div className="card">
          <div className="card-header bg-transparent header-elements-inline">
            <h5 className="card-title">Quản lý đơn hàng</h5>
            <Link
              style={{ float: 'right' }}
              className="btn bg-teal-400"
              to="/agency" >
              <i className="icon-add mr-2"></i> Thêm mới
            </Link>

          </div>

          <div className="card-body">
            <div className="row">
              <div className="form-group col-md-3">
                <DelayInput
                  value={searching.filters.title || searching.filters.profile.phone || _.get(searching, 'filters.id[0]', _.get(searching, 'filters.profile.fullName', ''))}
                  type="text"
                  className="form-control"
                  minLength={0}
                  delayTimeout={300}
                  placeholder="Nhập tên, số điện thoại hoặc mã đơn hàng"
                  onChange={this.onSearchChangeHandle.bind(this, 'phone')}
                />

              </div>

              <div className="form-group col-md-3">
                <DelayInput
                  value={customerTitle || agencyTitle}
                  type="text"
                  className="form-control"
                  delayTimeout={300}
                  placeholder="Lọc theo người gửi"
                  onChange={this.onSearchCustomerHandle.bind(this)}
                />

                <div
                  className={showContextMenu.display ? 'dropdown-menu show' : 'dropdown-menu'}
                  style={{ left: 10, width: 'calc(100% - 20px)' }}
                >
                  {
                    this.state.listAgency.map((item, index) => (
                      <a
                        onClick={this.onSearchCustomerSubmit.bind(this, item)}
                        value={item}
                        key={`accounts_${index}`}
                        className="dropdown-item">
                        <div
                          className="media-title font-weight-semibold">
                          {item.label}
                        </div>

                      </a>
                    ))
                  }
                </div>

              </div>

              <div className="form-group col-md-6">
                <DateRangePicker
                  className="btn btn-light daterange-predefined"
                  onApply={this.handleDatePicker}
                  ranges={ranges}
                >
                  <button style={{ padding: '7px 15px' }}>
                    <i className="icon-calendar22 mr-2"></i>
                    <span> {dateRangeTitle} </span>
                  </button>
                </DateRangePicker>

              </div>

            </div>
            <div className="row">

              <div className="form-group col-md-3">
                <Select2
                  style={{ width: '100%' }}
                  onSelect={this.onSearchChangeHandle.bind(this, 'serviceIdentifyCode')}
                  className="form-control select-search select2-hidden-accessible"
                  value={searching.filters.serviceIdentifyCode}
                  data={parentIdentifies}
                  options={{
                    placeholder: 'Tất cả dịch vụ'
                  }}
                />
              </div>

              <div className="form-group col-md-3">
                <SelectStateContainer
                  value={defaultState}
                  onChange={this.onSearchChangeHandle.bind(this, 'state')}
                  options={states}
                />
              </div>

              <div className="form-group col-md-3">
                <Select
                  value={apply}
                  onChange={this.onSearchChangeHandle.bind(this, 'apply')}
                  options={this.props.listMembers()}
                  placeholder='Lọc theo nhân viên'
                />
              </div>
              {(suppliers.length > 1)
                ? <div className="col-md-3">
                  <div className="form-group">
                    <Select
                      value={selectSupplier}
                      onChange={this.onSearchChangeHandle.bind(this, 'supplierId')}
                      options={suppliers}
                    />
                  </div>
                </div>
                : '' }
            </div>
            <div className="row">
              <div className="form-group col-md-12">
                <label>Nhãn đơn hàng</label>
                <Autocomplete
                  placeholder='Tag...'
                  delay={500}
                  suggestions={tagSuggestions}
                  ref={(e) => {
                    this.tags = e;
                  }}
                  tags={searching.filters.tag}
                  onChange={this.onSuggestTagHandle.bind(this)}
                  onDelete={this.onSearchChangeHandle.bind(this, 'tag')}
                  onAdd={this.onSearchChangeHandle.bind(this, 'tag')}
                />
              </div>
            </div>
          </div>
          <div className="table-responsive" style={{ paddingBottom: 150 }}>
            <table className="table table-bordered table-striped text-nowrap">
              <thead>
                <tr>
                  <th>
                    <input
                      onChange={this.onChangeCheckBoxHandle}
                      value="all"
                      style={{ transform: 'scale(1.5)' }}
                      type="checkbox"
                    />
                  </th>
                  <th>Mã ĐH</th>
                  <th>Khách hàng</th>
                  <th>Tổng tiền</th>
                  <th>Trạng thái đơn hàng</th>
                  <th>Tiến trình</th>
                  <th>Ngày dự kiến có KQ</th>
                  {/* <th>Nhân viên xử lý</th> */}
                  {/* <th>Tài liệu</th> */}
                  <th>Công cụ</th>
                </tr>
              </thead>
              <tbody >
                {this.renderOrder()}
              </tbody>
            </table>

            <PagingContainer
              count={_.get(this.props, 'orders', []).length}
              pageNum={this.state.pageNum}
              limit={PAGE_LIMIT}
              onClick={this.onChangePage.bind(this)}
            />
          </div>
        </div>

        {this.renderStateMofidy()}
        {this.renderImmigrationStateMofidy()}
        {this.renderReceiptPrint()}
        <NotificationContainer />
      </div>

    );
  }

  componentDidUpdate(oldProps) {
    try {
      /**
       * 
       */
      const newProps = this.props;

      if (oldProps.buildServices !== newProps.buildServices) {
        const parentIdentifies = this.props.listServiceHandleData();
        this.setState({ parentIdentifies: parentIdentifies.list });
      }

      if (oldProps.orders !== newProps.orders) {
        const checkbox = {};
        newProps.orders.forEach((order) => {
          checkbox[order.id] = false;
        });
        this.setState({ checkbox });
      }
      if (oldProps.orderTags !== newProps.orderTags) {
        const tagSuggestions = this.props.buildGetTagSuggest();
        this.setState({ tagSuggestions });
      }

      if (oldProps.suppliers !== newProps.suppliers) {
        const objSupplier = [];
        objSupplier.push(
          { value: '', label: 'Tất cả' },
        );
        newProps.suppliers.map((v) => {
          objSupplier.push(
            { value: v.id, label: v.title }
          );
          return true;
        });
        this.setState({
          suppliers: objSupplier
        });
      }

      if (oldProps.socketOrder !== newProps.socketOrder) {
        this.callbackOrder();
      }
    } catch (error) {
      // console.log(error);
      /**
       * 
       */
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      suppliers: payload.supplierReducer.suppliers,
      localStoreFilter: payload.generalReducer.localStoreFilter,
      socketOrder: payload.orderReducer.socketOrder,
      listScope: payload.generalReducer.listScope,
      agencies: payload.agencyReducer.agencies,
      orderTags: payload.orderReducer.orderTags,
      orders: payload.orderReducer.orders,
      accounts: payload.accountReducer.accounts,
      buildServices: payload.serviceReducer.buildServices,
      init: payload.generalReducer.init,
      uploadPath: payload.generalReducer.uploadPath,
      userInfo: payload.generalReducer.userInfo,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(SearchOrder)
);
