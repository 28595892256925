import _ from 'lodash';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import Select2 from 'react-select2-wrapper';
import InputMask from 'react-input-mask';
import Cleave from 'cleave.js/react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Moment from 'moment';
import UploadFileContainer from '../../ultils/uploadFile.container';
import SelectStateContainer from '../../ultils/selectState.container';
import {
  fileUpload, formatDate
} from '../../../helpers';

class CreateCobo extends React.Component {
  constructor(props) {
    super(props);
    this.input = {};
    this.state = {
      uploadAccept: '',
      isPaidSupplier: false,
      uploadAllowString: 'Accepted formats: doc, docx, pdf, bmp, png, gif, jpg, jpeg, zip, rar, xls, xlxs',
      disabledDepartment: true,
      selectedFile: [],
      departmentTitle: '',
      typeOfSubmit: false,
      branchTitle: '',
      birthdayDisplay: '',
      bankAccountDefaultValue: {},
      listBankAccount: [],
      joinedDisplay: '',
      uploading: false,
      allowance: [{ key: '', value: '' }],
      displayIssuedAt: formatDate(new Date()),
      model: {
        method: 'POBO',
        type: '',
        title: '',
        amount: '',
        bankAccount: '',
        description: '',
        issuedAt: new Date(),
        documents: []
      },
      discounts: []
    };

    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.setListBankAccount = this.setListBankAccount.bind(this);
    this.setFiles = this.setFiles.bind(this);
  }

  componentWillMount() {
    this.setListBankAccount();
  }

  setListBankAccount() {
    const { init } = this.props;
    const bankAccounts = _.get(init, 'bankAccount', []);
    const listBankAccount = [];

    if (_.isEmpty(bankAccounts) === false) {
      _.forEach(bankAccounts, (item) => {
        listBankAccount.push({
          value: item.key,
          label: item.text,
          color: item.color
        });
      });
      const bankAccountDefaultValue = listBankAccount[0];
      this.setState({ listBankAccount, bankAccountDefaultValue });
    }
  }

  validateOnSubmit(model) {
    if (!model.type) {
      NotificationManager.error('Danh mục không được để trống', '', 1500);
      return false;
    }
    if (!model.amount || model.amount < 1) {
      NotificationManager.error('Số tiền không được để trống và phải lớn hơn 0', '', 1500);
      return false;
    }
    if (!model.bankAccount) {
      NotificationManager.error('Tài khoản nhận không được để trống', '', 1500);
      return false;
    }
    if (!model.issuedAt) {
      NotificationManager.error('Ngày tạo nhận không được để trống', '', 1500);
      return false;
    }
    if (!model.sourceId) {
      NotificationManager.error('Ví chi tiền không được để trống', '', 1500);
      return false;
    }
    return true;
  }

  onChangeHandle(key, e) {
    try {
      const { buildServices, wallet, agency } = this.props;
      const {
        isDisable, genders, parentIdentifies, delegates, suppliers
      } = this.state;

      const model = Object.assign({}, this.state.model);
      const result = {};

      if (_.isArray(e) === true) {
        // switch (key) {
        // }
      }
      if (_.isArray(e) === false) {
        switch (key) {
          case 'issuedAt': {
            const value = _.get(e, 'target.value', _.get(e, 'value', false));
            const dateFormat = 'YYYY/MM/DD HH:mm:ss';
            const splittedDate = value.split('/');

            if (splittedDate.length === 3 && splittedDate[2].length === 4) {
              const date = Moment(`${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]} 12:00:00`, dateFormat);
              result.displayIssuedAt = value;
              if (date.isValid() === true) {
                if (key === 'issuedAt') {
                  model.issuedAt = date.format();
                }
              }
            }

            break;
          }
          case 'amount': {
            const amount = _.get(e, 'target.rawValue', _.get(e, 'value', 0));
            model[key] = Number(amount);
            break;
          }
          case 'type':
            model[key] = _.get(e, 'target.value', _.get(e, 'value', false));
            // if (model[key] === 'cong-no-doi-tac') {
            //   result.isPaidSupplier = true;
            //   result.uploadAllowString = '<span style="color:red">Vui lòng upload đúng file công nợ đối tác</span>';
            //   result.uploadAccept = '.xlsx';
            // } else {
            result.uploadAllowString = 'Accepted formats: doc, docx, pdf, bmp, png, gif, jpg, jpeg, zip, rar, xls, xlxs';
            result.isPaidSupplier = false;
            result.uploadAccept = '';
            // }
            break;
          case 'sourceId': {
            model[key] = _.find(this.props.listMembers(), { value: _.get(e, 'target.value', _.get(e, 'value', false)) });
            break;
          }
          default:
            model[key] = _.get(e, 'target.value', _.get(e, 'value', false));
            break;
        }
      }
      result.model = model;

      this.setState(result);
    } catch (error) {
      console.log(error);
    }
  }

  async validateInputOnSubmit() {
    return new Promise((resolve, reject) => {
      const input = this.input;
      let flag = true;
      _.forEach(input, (field) => {
        if (flag === false) return false;
        if (field.isActive === true) {
          if (field.required === true) {
            if (_.get(field, 'e.value', '') === '' || _.get(field, 'e.value', '') === '__/__/____') {
              NotificationManager.error(`${field.title} không được để trống!`, '', 1500);
              field.e.focus();
              flag = false;
            }
          }
        }
        return true;
      });
      resolve(flag);
    });
  }

  submit(key, e) {
    this.setState({ typeOfSubmit: key });
  }

  onSubmit = async (e) => {
    try {
      e.preventDefault();
      if (this.state.isPaidSupplier === true) {
        if (_.get(this.state, 'selectedFile.documents', []).length > 1) {
          NotificationManager.error('Chỉ được phép tải duy nhất một tập tin Công nợ đối tác. Vui lòng kiểm tra lại', '', 1500);
          return false;
        }
      }
      const model = Object.assign({}, this.state.model);
      const validateInputOnSubmit = await this.validateInputOnSubmit();
      if (validateInputOnSubmit === false) return false;
      const validate = this.validateOnSubmit(model);
      if (validate === false) return false;
      // SHOW LOADING
      this.props.dispatch({ type: 'SHOW_LOADING' });
      if (this.state.selectedFile) {
        const files = await fileUpload(this.state.selectedFile.documents, this.props);
        if (files.code === 1) {
          model.documents = files.data;
        }
      }

      this.props.onSubmit(model, this.state.typeOfSubmit);
      this.props.dispatch({ type: 'HIDE_LOADING' });
    } catch (error) {
      console.log(error);
      // HIDE LOADING
      this.props.dispatch({ type: 'HIDE_LOADING' });
    } finally {
      this.props.dispatch({ type: 'HIDE_LOADING' });
    }
    return true;
  }

  setFiles(file, field) {
    try {
      if (_.isEmpty(file) === false) {
        const selectedFile = Object.assign({}, this.state.selectedFile);
        selectedFile[field] = file;
        this.setState({ selectedFile });
      }
    } catch (error) {
      // console.log(error);
    }
  }

  render() {
    const {
      model,
      listBankAccount,
      displayIssuedAt
    } = this.state;
    const { init, coboPoboCreate } = this.props;
    const result = _.get(coboPoboCreate, 'result', []);
    const coboType = _.get(init, 'coboPobo.type.pobo', []);
    const bankAccount = _.get(init, 'bankAccount', []);
    const createdBy = { value: '', label: 'Chọn ví chi tiền' };
    return (
      <div>
        <div className="page-header page-header-light">
          <div className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="text-center d-lg-none w-100">
              <button type="button" className="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-second">
                <i className="icon-menu7 mr-2"></i>Profile navigation</button>
            </div>
          </div>
          <div className="content">
            <div className="d-flex align-items-start flex-column flex-md-row">
              <div className="tab-content w-100 overflow-auto order-2 order-md-1">

                <div className="card">
                  <div className="card-header header-elements-inline">
                    <h5 className="card-title">Phiếu Chi Tiền</h5>
                    <div className="header-elements">
                    </div>
                  </div>
                  <div className="card-body">
                    {result.length > 0 
                      ? <div> 
                        <div className="row">
                          <div className="col-xl-12">
                            <div
                              style={{ borderColor: '#dfdfdf' }}
                              className="alert alert-info bg-white alert-styled-left alert-arrow-left alert-dismissible">
                              <h6 style={{ fontSize: 12 }} className="alert-heading mb-1">
                                Thêm phiếu chi {coboPoboCreate.title} thành công
                              </h6>
                              <div className="font-weight-semibold ">Hệ thống đã tự động cập nhật vào số tiền đã thanh toán cho đối tác theo danh sách dưới đây</div>
                            </div>
                          </div>
                        </div>
                        <div className="table-responsive" style={{ paddingBottom: 200 }}>
                          <table className="table table-bordered table-striped text-nowrap">
                            <thead>
                              <tr>
                                <th style={{ textAlign: 'center' }}>ID</th>
                                <th style={{ textAlign: 'center' }}>Tên khách</th>
                                <th style={{ textAlign: 'center' }}>Số tiền thanh toán cho đối tác</th>
                                <th style={{ textAlign: 'center' }}>Dịch vụ</th>
                                <th style={{ textAlign: 'center' }}>Công ty bảo lãnh</th>
                              </tr>
                            </thead>
                            <tbody>
                              {result.map((item, i) => {
                                const linkTo = item.type === 'ARRIVAL' ? `/order-immigration-modify/${item.customerId}/${item.invoiceId}` : `/order-modify/${item.customerId}/${item.invoiceId}`;
                                return <tr>
                                  <td><a target='_blank' href={linkTo}>{item.invoiceId}</a></td>
                                  <td>{item.profileName}</td>
                                  <td>{item.paidSupplierAmount}</td>
                                  <td>{item.serviceName}</td>
                                  <td>{item.guaranteeCompany}</td>
                                </tr>;
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      : <form onSubmit={this.onSubmit} >
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-6">
                              <label>Danh mục <span className="text-danger">*</span></label>
                            
                              <Select2
                                value={model.type}
                                style={{ width: '100%' }}
                                name="type"
                                onChange={this.onChangeHandle.bind(this, 'type')}
                                data={coboType}
                                className="form-control select-search select2-hidden-accessible"
                                options={{
                                  placeholder: 'Chọn danh mục chi'
                                }}
                              />
                            
                            </div>
                            <div className="col-md-6">
                              <label>Tiêu đề <span className="text-danger">*</span></label>
                              <input
                                value={model.title}
                                ref={(input) => {
                                  this.input.fullName = {
                                    title: 'Tiêu đề', isActive: true, required: true, e: input
                                  }; 
                                }} 
                                onChange={this.onChangeHandle.bind(this, 'title')}
                                type="text"
                                className="form-control" />
                            </div>
                          
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                          
                            <div className="col-md-6">
                              <label>Số tiền
                                <span className="text-danger"> * </span>

                              </label>

                              <div className="form-group">
                                <div className="input-group">
                                  <span className="input-group-prepend">
                                    <span className="input-group-text">
                                VND
                                    </span>
                                  </span>
                                  <div className="multiselect-native-select">
                                    <Cleave
                                      value={model.amount}
                                      onChange={this.onChangeHandle.bind(this, 'amount')}
                                      className="form-control input-numeral"
                                      placeholder="Số tiền"
                                      options={{
                                        numeral: true,
                                        numeralThousandsGroupStyle: 'thousand',
                                        numeralDecimalScale: 0
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <label>Tài khoản chi <span className="text-danger">*</span></label>
                              <SelectStateContainer
                                onChange={this.onChangeHandle.bind(this, 'bankAccount')}
                                options={listBankAccount}
                                placeholder="Chọn tài khoản chi"
                              />
                            </div>

                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-6">
                              <label>Chi tiền từ ví  <span className="text-danger">*</span></label>
                              <div className="form-group">
                                <Select
                                  value={model.sourceId || createdBy}
                                  onChange={this.onChangeHandle.bind(this, 'sourceId')}
                                  options={this.props.listMembers()}
                                  placeholder='Chọn ví chi tiền'
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <label>Tài liệu đính kèm</label>
                              <UploadFileContainer
                                accept={this.state.uploadAccept}
                                setFiles={this.setFiles.bind(this)}
                                name="documents"
                              />
                              <span className="form-text text-muted" dangerouslySetInnerHTML={{ __html: this.state.uploadAllowString }}></span>
                            </div>
                          
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-12">
                              <label>Nội dung</label>
                              <textarea 
                                // value={model.salary.description} 
                                onChange={this.onChangeHandle.bind(this, 'description')} 
                                rows="4" 
                                cols="4"
                                placeholder="Nhập nội dung chi tiền" 
                                className="form-control"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="text-right">
                          <button onClick={this.submit.bind(this, 'add')} className="btn btn-primary">
                            <i className="fa fa-save"></i> Thêm phiếu chi
                          </button>

                          <button onClick={this.submit.bind(this, 'addNew')} value="addNew" className="btn btn-success" style={{ marginLeft: 20 }}>
                            <i className="fa fa-save"></i> Lưu & tạo mới
                          </button>
                        </div>
                      </form>

                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotificationContainer />
      </div>
     
    );
  }

  componentDidMount() {
    try {
      /**
       * 
       */
    } catch (error) {
      /**
       * 
       */
    }
  }

  componentWillUnmount() {
    try {
      /**
       * 
       */
    } catch (error) {
      /**
       * 
       */
    }
  }

  componentDidUpdate() {
    try {
      /**
       * 
       */
    } catch (error) {
      /**
       * 
       */
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    console.log(payload);
    return {
      init: payload.generalReducer.init,
      coboPoboCreate: payload.coboPoboReducer.coboPoboCreate,
      userInfo: payload.generalReducer.userInfo,
      lang: payload.i18nState.lang,
      uploadPath: payload.generalReducer.uploadPath
    };
  }, mapDispatchToProps)(CreateCobo)
);
