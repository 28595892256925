import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import BodyClassName from 'react-body-classname';

import { NotificationContainer, NotificationManager } from 'react-notifications';

import Modal from 'react-modal';
import PageHeader from './PageHeader';
import Header from './Header';
import SideBar from './SideBar';
import CallingBar from './CallingBar';
import * as UtilityActions from '../../redux/utilities/utilities.actions';
import * as GeneralActions from '../../redux/general/general.actions';
import * as ServiceActions from '../../redux/services/services.actions';

import Loading from '../loading/Loading';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-dropdown-tree-select/dist/styles.css';
import '../../ui/receipt/css/index.css';

class Home extends React.Component {
  static contextTypes = {
    t: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);
    this.props.dispatch({ type: 'SHOW_LOADING' });
    this.state = {
      title: 'PASSPORT',
      width: 200,
      collapsed: false,
      showToggle: true,
      selectedMenu: null,
      dropdownUser: false,
      menus: [],
      bodyClass: ['navbar-top']
      // bodyClass: ['navbar-top', 'sidebar-xs']

    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleMenuMobile = this.toggleMenuMobile.bind(this);
    this.toggleDropdownUser = this.toggleDropdownUser.bind(this);
    this.getInit = this.getInit.bind(this);
    this.getScope = this.getScope.bind(this);
    this.getServices = this.getServices.bind(this);
    //
    this.sendSMS = this.sendSMS.bind(this);
    this.callbackSendSMS = this.callbackSendSMS.bind(this);

    /**
     * TODO
     */
    this.getServices();
    this.getInit();
    this.getScope();
  }

  sendSMS = async () => {
    try {
      const { utilityAct, sendSmsInfo } = this.props;

      if (_.isEmpty(sendSmsInfo) === false) {
        const content = {
          create: `Tai Khoan dang nhap danh cho ban tai vnHub cua ban la user: ${sendSmsInfo.phone}  , mat khau: ${sendSmsInfo.password} .  Truy cap https://admin.vnhub.com de su dung. `,
          modify: `Thong tin dang nhap moi cua ban tai vnHub la user: ${sendSmsInfo.phone}  , mat khau: ${sendSmsInfo.password} .  Truy cap https://admin.vnhub.com de su dung. `
        };
        const args = {
          phone: sendSmsInfo.phone,
          content: content[sendSmsInfo.type]
        };

        utilityAct.SendSMS(args, this.callbackSendSMS);
      }
    } catch (error) {
      // console.log('error', error);
    }
  }

  callbackSendSMS(status, data) {
    if (status) {
      NotificationManager.info('Gởi tin nhắn thành công', '', 1000);
    } else {
      NotificationManager.error(data.message, '', 1500);
    }

    this.props.dispatch({
      type: 'SEND_SMS',
      payload: {}
    });
  }

  getInit() {
    try {
      const accessToken = _.get(this.props, 'userInfo.accessToken', false);

      if (_.isEmpty(accessToken) === false) {
        const { generalAct } = this.props;
        generalAct.getInit({ accessToken });
      }
    } catch (error) {
      // console.log('error', error);
    }
  }

  getScope() {
    try {
      const { generalAct } = this.props;
      generalAct.getScopes();
    } catch (error) {
      // console.log('error', error);
    }
  }

  /**
   * getServices
   */
  getServices() {
    try {
      const { serviceAct } = this.props;
      serviceAct.listService({});
    } catch (error) {
      // console.log('error', error);
    }
  }

  toggleMenuMobile() {
    const bodyClass = Object.assign([], this.state.bodyClass);
    const index = _.indexOf(bodyClass, 'sidebar-mobile-main');
    if (index === -1) {
      bodyClass.push('sidebar-mobile-main');
    } else {
      delete bodyClass[index];
    }
    this.setState({ bodyClass });
  }

  toggleMenu() {
    const bodyClass = Object.assign([], this.state.bodyClass);
    const index = _.indexOf(bodyClass, 'sidebar-xs');
    if (index === -1) {
      bodyClass.push('sidebar-xs');
    } else {
      delete bodyClass[index];
    }
    this.setState({ bodyClass });
  }

  BodyClass = () => (
    <BodyClassName className={this.state.bodyClass.join(' ')}></BodyClassName>
  );

  toggleDropdownUser(status) {
    if (status === 'hide') {
      this.setState({ dropdownUser: false });
    } else {
      this.setState({ dropdownUser: this.state.dropdownUser === true ? this.state.dropdownUser = false : true });
    }
  }

  render() {
    const Component = this.props.component;
    const route = this.props.route  //eslint-disable-line
    return (
      <div>
        <Loading />
        {this.BodyClass()}
        <Header
          history={this.props.history}
          toggleMenu={this.toggleMenu}
          toggleMenuMobile={this.toggleMenuMobile}
          toggleDropdownUser={this.toggleDropdownUser}
          dropdownUser={this.state.dropdownUser}
          collapsed={this.state.collapsed}
        />
        <div className="page-content" onClick={this.toggleDropdownUser.bind(this, 'hide')}>
          <div className="sidebar sidebar-dark sidebar-main sidebar-expand-md">
            <SideBar
              activeMenu={this.props.activeMenu}
              history={this.props.history}
              toggleMenu={this.toggleMenu}
              menus={this.menus}
              toggleMenuMobile={this.toggleMenuMobile}
            />
          </div>
          <div className="content-wrapper">
            <PageHeader
              activeMenu={this.props.activeMenu}
              history={this.props.history}
            />
            <div className="content">
              <Component route={route} {...this.props} />
            </div>

            <div className="navbar navbar-expand-lg navbar-light">
              <div className="text-center d-lg-none w-100">
                <button type="button" className="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-footer"><i className="icon-unfold mr-2"></i>Footer</button>
              </div>

              <div className="navbar-collapse collapse" id="navbar-footer"><span className="navbar-text">© 2003 - 2020. <a href="https://vnhub.com">Vnhub.com – Travel Network by Tân Văn Lang – Newsky – Á Châu</a> - 028.7777.79.79</span>
                <ul className="navbar-nav ml-lg-auto">
                  <li className="nav-item">
                    <a href="https://achau.net" className="navbar-nav-link font-weight-semibold">
                      <span className="text-pink-400">
                        <i className="icon-phone mr-2"></i>
                        0987.22.77.44
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <CallingBar />
        <NotificationContainer />
      </div>
    );
  }

  componentDidMount() {
    const { userInfo } = this.props;
    if (_.isEmpty(userInfo)) {
      this.props.history.push({
        pathname: '/login'
      });
    }

    this.props.dispatch({ type: 'HIDE_LOADING' });
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.sendSmsInfo !== newProps.sendSmsInfo) {
      this.sendSMS();
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    utilityAct: bindActionCreators(UtilityActions, dispatch),
    generalAct: bindActionCreators(GeneralActions, dispatch),
    serviceAct: bindActionCreators(ServiceActions, dispatch),
    dispatch: bindActionCreators(dispatch, dispatch)
  };
}

export default withRouter(
  connect((payload) => {
    return {
      sendSmsInfo: payload.utilityReducer.sendSmsInfo,
      listScope: payload.generalReducer.listScope,
      userInfo: payload.generalReducer.userInfo,
      activeMenu: payload.generalReducer.activeMenu,
      lang: payload.i18nState.lang
    };
  }, mapDispatchToProps)(Home)
);
