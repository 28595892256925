import { API_URL } from '../../configs/api.config';
import Request from '../middleware/request';

const orderAPI = {
  /**
   * 
   */
  postCreateOrder(args) {
    return Request.callAPI('post', `${API_URL}/Invoice`, { ...args });
  },
  putModifyOrder(args) {
    return Request.callAPI('put', `${API_URL}/Invoice/${args.id}`, { ...args.data });
  },
  putModifyOrderState(args) {
    return Request.callAPI('put', `${API_URL}/Invoice/State`, { ...args });
  },
  putModifyOrderProcess(args) {
    return Request.callAPI('put', `${API_URL}/Invoice/Process/${args.id}`, { ...args.data });
  },
  putModifyOrderSpin(args) {
    return Request.callAPI('put', `${API_URL}/Invoice/Process/Pin/${args.invoiceId}/${args.processId}`, { ...args.data });
  },

  postSearchOrder(args) {
    return Request.callAPI('post', `${API_URL}/Invoice/Search`, { ...args });
  },
  postOrderTagSearch(args) {
    return Request.callAPI('post', `${API_URL}/Invoice/Tag/Search`, { ...args });
  }
};

export default orderAPI;

